import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	container: {
		alignItems: "center",
		backgroundColor: "var(--chip-background-color)",
		border: "2px solid transparent",
		color: "var(--chip-text-color)",
		display: "inline-flex",
		flexShrink: 0,
		gap: "4px",
		minWidth: 0,
		"&$large": {
			borderRadius: "18px",
			height: "34px",
			minWidth: "60px",
			padding: "2px 12px",
			"& $icon": {
				fontSize: "16px"
			}
		},
		"&$regular": {
			borderRadius: "16px",
			height: "26px",
			minWidth: "60px",
			padding: "2px 12px",
			"& $icon": {
				fontSize: "16px"
			}
		},
		"&$small": {
			borderRadius: "10px",
			height: "18px",
			padding: "2px 8px",
			"& $icon": {
				fontSize: "12px"
			}
		},
		"&$clickable:hover:not($disabled)": {
			backgroundColor: "var(--chip-background-color-hover)"
		},
		"&$selected": {
			borderColor: "var(--chip-border-color-selected)"
		},
		"&$disabled": {
			backgroundColor: "var(--chip-background-color-disabled)",
			color: "var(--chip-text-color-disabled)"
		}
	},
	large: {},
	regular: {},
	small: {},
	icon: {
		alignItems: "center",
		display: "flex"
	},
	selected: {},
	disabled: {},
	clickable: {}
});
