import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	cell: {
		alignItems: "center",
		display: "flex",
		gap: "8px",
		height: "52px",
		justifyContent: "flex-start",
		padding: "0 20px"
	},
	header: {
		extend: "cell",
		height: "40px"
	},
	requestsTooltip: {
		display: "flex",
		flexDirection: "column",
		gap: "8px"
	}
});
