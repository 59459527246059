import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	container: {
		alignItems: "flex-start",
		backgroundColor: "var(--color-white)",
		borderRadius: "6px",
		boxShadow: "0px 8px 7px 7px rgba(0, 0, 0, 0.20)",
		display: "inline-flex",
		flexDirection: "column",
		maxHeight: "378px"
	},
	header: {
		alignItems: "flex-start",
		alignSelf: "stretch",
		backgroundColor: "var(--color-purple-50)",
		borderBottom: "1px solid var(--color-gray-500)",
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		padding: "var(--spacing-x4, 16px) var(--spacing-x6, 24px) var(--spacing-x3, 12px) var(--spacing-x6, 24px)"
	},
	body: {
		alignItems: "flex-start",
		display: "flex",
		flexDirection: "column",
		gap: "var(--spacing-x2, 8px)",
		overflowY: "auto",
		padding: "var(--spacing-x5, 20px)"
	}
});
