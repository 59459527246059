import React, { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useFetchedState } from "hooks/useFetchedState";
import { getUserPastTickets } from "api/user";
import { Typography } from "components/ui/New/Typography";
import { Chip } from "components/ui/Chip";
import { getTicket, renewTicket } from "api/tickets";
import { useTicketUpdatesContext } from "context/ticketUpdatesContext";
import { LoadingDots } from "components/ui/LoadingDots";
import { useUser } from "hooks/useUser";
import { RequestBar } from "components/common/RequestBar";
import { Section } from "components/ui/Section";
import { RequestList } from "components/common/RequestList";
import { DESC } from "hooks/usePagination";
import { routes } from "routes/routes";
import { LinkButton } from "components/ui/Button";

const SHOWN_REQUESTS_SIZE = 5;
const SUBSCRIPTION_ID = "[PAST_REQUESTS_SECTION_UPDATED_REQUESTS]";

const usePastRequests = () => {
	const getPastRequests = useCallback(() => {
		return getUserPastTickets({
			pagination: { perPage: SHOWN_REQUESTS_SIZE },
			sort: { fields: "ticketNumber", order: DESC }
		});
	}, []);
	const { data, fetchingState, isLoading, loadData, retryAction } = useFetchedState(getPastRequests);

	useEffect(() => {
		loadData();
	}, [loadData]);

	return { data, fetchingState, isLoading, reloadData: retryAction };
};

export const PastRequestsSection: FC<TProps> = ({ className, innerRef }) => {
	const { t } = useTranslation();
	const { data, fetchingState, isLoading, reloadData } = usePastRequests();
	const { notifyTicketUpdate, subscribeTicketUpdates, unsubscribeTicketUpdates } = useTicketUpdatesContext();
	const { user } = useUser();

	const onUpdatedRequest = useCallback(() => {
		reloadData();
	}, [reloadData]);

	useEffect(() => {
		subscribeTicketUpdates(SUBSCRIPTION_ID, onUpdatedRequest);
		return () => unsubscribeTicketUpdates(SUBSCRIPTION_ID);
	}, [onUpdatedRequest, subscribeTicketUpdates, unsubscribeTicketUpdates]);

	const requests = useMemo(() => {
		if (!data) return [];
		return data.result.toArray();
	}, [data]);

	const onRenew = useCallback(
		async (requestId: string) => {
			await renewTicket(requestId);
			const updatedTicket = await getTicket(requestId);
			notifyTicketUpdate(updatedTicket);
		},
		[notifyTicketUpdate]
	);

	const title = useMemo(() => {
		const pastRequestsTextTitle = t("pages.requests.pastRequestsSection.title");
		return (
			<>
				<Typography variant="body_sb">{pastRequestsTextTitle}</Typography>
				<Chip size="tiny">{Number(data?.pagination.totalResults || 0)}</Chip>
			</>
		);
	}, [data?.pagination.totalResults, t]);

	const requestBars = useMemo(() => {
		if (isLoading) return <LoadingDots center />;
		if (!requests.length) return null;
		return requests.map(request => {
			const renew = request.receiverId === user?.id && request.status === "revoked" ? onRenew : undefined;
			return <RequestBar key={request.id} onRenew={renew} request={request} type="past" openSidebarOnClick />;
		});
	}, [isLoading, requests, user?.id, onRenew]);

	if (fetchingState === "Loaded" && requests.length === 0) return null;

	return (
		<Section
			className={className}
			innerRef={innerRef}
			title={title}
			titleActions={
				<LinkButton size="medium" variant="secondary" to={routes.pastRequests.main}>
					{t("buttons.seeAll")}
				</LinkButton>
			}>
			<RequestList shownSize={requests.length >= SHOWN_REQUESTS_SIZE ? SHOWN_REQUESTS_SIZE : requests.length}>
				{requestBars}
			</RequestList>
		</Section>
	);
};
