import constate from "constate";
import { TBreakpointState as TBpState, useBreakpoint } from "hooks/useBreakpoint";
import { useMemo } from "react";
import { Bp } from "utils/breakpoints";

export type TBreakpointState = TBpState;
export type TBreakpointsState = {
	state: {
		FIRST: TBreakpointState;
		SECOND: TBreakpointState;
		THIRD: TBreakpointState;
		FOURTH: TBreakpointState;
		FIFTH: TBreakpointState;
		SIXTH: TBreakpointState;
		currentBp: Bp;
	};
};

const useBreakpoints = (): TBreakpointsState => {
	const FIRST = useBreakpoint(Bp.FIRST);
	const SECOND = useBreakpoint(Bp.SECOND);
	const THIRD = useBreakpoint(Bp.THIRD);
	const FOURTH = useBreakpoint(Bp.FOURTH);
	const FIFTH = useBreakpoint(Bp.FIFTH);
	const SIXTH = useBreakpoint(Bp.SIXTH);

	const currentBp = useMemo(() => {
		const isExact = [FIRST, SECOND, THIRD, FOURTH, FIFTH, SIXTH].find(bp => bp[1])!;
		return isExact?.[2] || Bp.SIXTH;
	}, [FIRST, SECOND, THIRD, FOURTH, FIFTH, SIXTH]);

	return { state: { FIRST, SECOND, THIRD, FOURTH, FIFTH, SIXTH, currentBp } };
};

export const [BreakpointsProvider, useBreakpointsContext] = constate(useBreakpoints);
