import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	chip: {
		display: "flex",
		alignItems: "center"
	},
	select: {
		fontSize: "var(--typography-tiny-font-size)",
		marginLeft: "0.5rem",
		width: "27rem"
	},
	title: {
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
		gap: "0.5rem",
		width: "100%"
	},
	titleActions: {
		display: "flex",
		alignItems: "center",
		gap: "0.5rem"
	},
	entitiesContainer: {
		display: "flex",
		backgroundColor: "var(--surface-content-background-color)",
		padding: "0.5em",
		boxShadow: "0px 0px 0px 1px rgb(0 0 0 / 30%)",
		alignItems: "center",
		whiteSpace: "pre",
		borderRadius: "var(--border-radius-sharp)",
		"& > *:first-child": {
			marginRight: "0.5rem"
		}
	},
	labelContainer: {
		maxWidth: "6rem",
		minWidth: "fit-content"
	}
});
