import { apiReq } from "utils/apiReq";
import { PrerequisitePermissionModel } from "models/PrerequisitePermissionModel";
import { TAssetTypes, TClauseCreation } from "utils/prerequisitePermissions";
import { PrerequisitePermissionClauseModel } from "models/PrerequisitePermissionClauseModel";
import { PrerequisitePermissionRoleModel } from "models/PrerequisitePermissionRoleModel";

export const fetchPermission = async (id: string) => {
	const { data } = await apiReq("GET", `/v1/prerequisitePermissions/${id}`);
	return PrerequisitePermissionModel.fromServerData(data);
};

export const createPermission = async (
	data: { clauses: TClauseCreation[] },
	id: string,
	type: "integrations" | "integrationResources" | "integrationResourceRoles"
) => {
	const { data: createdPermission } = await apiReq("POST", `/v1/${type}/${id}/addPrerequisitePermission`, data);

	return PrerequisitePermissionModel.fromServerData(createdPermission);
};

export const removePermission = async (prerequisitePermissionId: string, id: string, type: TAssetTypes) => {
	await apiReq("DELETE", `/v1/${type}/${id}/deletePrerequisitePermission`);
};

export const createClause = async (data: TClauseCreation, prerequisitePermissionId: string) => {
	const { data: createdClause } = await apiReq(
		"POST",
		`/v1/prerequisitePermissions/${prerequisitePermissionId}/clauses`,
		data
	);

	return PrerequisitePermissionClauseModel.fromServerData(createdClause);
};

export const removePermissionClause = async (prerequisitePermissionClauseId: string) => {
	const { data: createdClause } = await apiReq(
		"DELETE",
		`/v1/prerequisitePermissionClauses/${prerequisitePermissionClauseId}`
	);

	return PrerequisitePermissionClauseModel.fromServerData(createdClause);
};

export const createPermissionRole = async (
	integrationResourceRoleId: string,
	prerequisitePermissionClauseId: string
) => {
	const { data: updatedClause } = await apiReq(
		"POST",
		`/v1/prerequisitePermissionClauses/${prerequisitePermissionClauseId}/roles`,
		{ integrationResourceRoleId }
	);

	return PrerequisitePermissionClauseModel.fromServerData(updatedClause);
};

export const setPermissionRoleDefault = async (
	prerequisitePermissionRoleId: string,
	prerequisitePermissionClauseId: string
) => {
	const { data: updatedClause } = await apiReq(
		"PUT",
		`/v1/prerequisitePermissionClauses/${prerequisitePermissionClauseId}/roles/${prerequisitePermissionRoleId}`
	);

	return PrerequisitePermissionClauseModel.fromServerData(updatedClause);
};

export const removePermissionRole = async (prerequisitePermissionRoleId: string) => {
	const { data: createdRole } = await apiReq(
		"DELETE",
		`/v1/prerequisitePermissionRoles/${prerequisitePermissionRoleId}`
	);

	return PrerequisitePermissionRoleModel.fromServerData(createdRole);
};
