import React from "react";
import { Chip } from "components/ui/Chip";
import { Tooltip } from "components/ui/Tooltip";
import { ChevronDownIcon } from "components/ui/Icons/ChevronDownIcon";
import { Typography } from "components/ui/New/Typography";
import { TooltipOnOverflow } from "components/ui/New/TooltipOnOverflow";
import { useStyles } from "./styles";

type TProps = {
	tags: string[];
};

export const RoleBarTag: FC<TProps> = ({ className, tags, innerRef }) => {
	const classes = useStyles();

	if (tags.length === 0) {
		return null;
	}

	if (tags.length === 1) {
		return (
			<Chip size="tiny" innerRef={innerRef} className={className}>
				<TooltipOnOverflow textVariant="text_reg" content={tags.at(0)!} />
			</Chip>
		);
	}

	return (
		<Tooltip
			trigger="click"
			placement="bottom"
			content={
				<div className={classes.container}>
					{tags.map(tag => (
						<Chip size="tiny" key={tag}>
							<TooltipOnOverflow textVariant="text_reg" content={tag} />
						</Chip>
					))}
				</div>
			}>
			<Chip size="tiny" className={className} suffixIcon={<ChevronDownIcon size={16} />}>
				<Typography variant="text_reg">{tags.length + "+"}</Typography>
			</Chip>
		</Tooltip>
	);
};
