import React from "react";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import capitalize from "lodash/capitalize";
import { dayjs } from "utils/dayjs";
import enUS from "./translations/en-US.json";

export const currentLanguage = "en-US";
export const defaultDateFormat = "MMM Do, YYYY";
export const boldComponent = <b />;

i18n.use(initReactI18next).init({
	resources: {
		"en-US": enUS
	},
	lng: currentLanguage,
	fallbackLng: "en-US",
	interpolation: {
		escapeValue: false // react is already safe from xss
	}
});

export const formatDate = (value: unknown, _lng?: string, options?: Record<string, unknown>): string => {
	if (value instanceof Date || dayjs.isDayjs(value)) {
		try {
			let format = defaultDateFormat;
			if (options?.format === "with_time") {
				format = "MMM Do, YYYY, h:mm A";
			}
			if (options?.format === "only_time") {
				format = "h:mm A";
			}

			return dayjs(value).format(format);
		} catch (error) {
			console.error(error);
			return value.toLocaleString();
		}
	}
	return value as string;
};

const formatCapitalize = (value: unknown, _lng?: string, _options?: Record<string, unknown>): string => {
	if (typeof value === "string") {
		return value && capitalize(value);
	}
	return value as string;
};

const formatDefaults = (value: unknown, _lng?: string, options?: Record<string, unknown>): string => {
	const val = value as string;
	if (options?.default) {
		return val || (options.default as string);
	}
	return val;
};

i18n.services.formatter?.add("date", formatDate);
i18n.services.formatter?.add("capitalize", formatCapitalize);
i18n.services.formatter?.add("d", formatDefaults);

declare module "i18next" {
	interface CustomTypeOptions {
		defaultNS: "translation";
		resources: {
			translation: (typeof enUS)["translation"];
		};
	}
}
