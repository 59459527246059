import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	bundleItemForm: {
		display: "flex",
		gap: "1.2rem",
		padding: "1.1rem",
		alignItems: "center"
	},
	deleteButtonContainer: {
		"&$deleteButtonContainer$deleteButtonContainer": {
			backgroundColor: "var(--color-white)",
			padding: "0.7rem 0.85rem"
		}
	},
	deleteButtonPadding: {
		paddingTop: "1.5rem"
	},
	input: { "& .box": { backgroundColor: "var(--color-white)" } },
	title: {
		fontSize: "var(--typography-standard-font-size)",
		paddingBottom: "0.5rem"
	},
	titleContainer: {
		alignItems: "center",
		display: "flex",
		justifyContent: "space-between"
	},
	addResourceButtonContainer: {
		display: "flex"
	},
	inputValue: {
		whiteSpace: "nowrap",
		textOverflow: "ellipsis",
		overflow: "hidden"
	}
});
