import { EventEmitter } from "events";
import React from "react";
import { useIsBeta } from "context/betaContext";
import { TicketSidebar } from "components/common/TicketSidebar";
import { RequestDetails as BetaRequestDetails } from "./RequestDetails";
export * from "./types";

export const RequestDetails: FC<{ resetEventEmitter?: EventEmitter }> = ({ resetEventEmitter }) => {
	const isBeta = useIsBeta();
	const SidebarComponent = isBeta ? BetaRequestDetails : TicketSidebar;

	return <SidebarComponent resetEventEmitter={resetEventEmitter} />;
};
