import { useMemo } from "react";
import get from "lodash/get";
import { Breakpoint, BREAKPOINTS, TBreakpointOption } from "utils/breakpoints";
import { useMediaQuery } from "./useMediaQuery";

const MAX_WINDOW_SIZE = 99999;

export type TBreakpointState = [isBiggerThan: boolean, isExact: boolean, breakingPoint: Breakpoint];

/**
 * Returns an array of two booleans indicating whether the viewport is bigger than or equal to the given breakpoint,
 * and whether it's smaller than the next breakpoint (or the maximum window size if there's no next breakpoint).
 * @param {Breakpoint | number | string } breakpoint - The breakpoint to check against.
 * @returns {[boolean, boolean, Breakpoint]} An array of two booleans: the first indicates whether the viewport is bigger than or equal to the given breakpoint,
 * and the second indicates whether it's also smaller than the next breakpoint (or the maximum window size if there's no next breakpoint).
 * @throws An error if the given breakpoint is not a valid option.
 */
export const useBreakpoint = (breakpoint: TBreakpointOption): TBreakpointState => {
	if (
		!Object.prototype.hasOwnProperty.call(BREAKPOINTS, breakpoint) ||
		!Object.prototype.hasOwnProperty.call(Breakpoint, breakpoint)
	) {
		throw new Error(`Invalid breakpoint option: ${breakpoint}`);
	}
	const breakpointAsNumber = typeof breakpoint === "string" ? Breakpoint[breakpoint] : breakpoint;
	const nextBreakpointAsNumber = breakpointAsNumber - 1;
	const breakpointValue = get(BREAKPOINTS, breakpointAsNumber);
	const nextBreakpointValue = get(BREAKPOINTS, nextBreakpointAsNumber, undefined) as number | undefined;
	const isBiggerThan = useMediaQuery(`(min-width: ${breakpointValue}px)`);
	const isUnderNext = useMediaQuery(`(max-width: ${nextBreakpointValue || MAX_WINDOW_SIZE}px)`);
	const isExact = isBiggerThan && (isUnderNext || nextBreakpointValue === undefined);

	return useMemo(() => [isBiggerThan, isExact, breakpointAsNumber], [isBiggerThan, isExact, breakpointAsNumber]);
};
