import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	appIcon: {
		backgroundPosition: "center",
		backgroundRepeat: "no-repeat",
		backgroundSize: "contain",
		borderRadius: "var(--border-radius-round)",
		height: "2.5rem",
		overflow: "hidden",
		width: "2.5rem"
	},
	auditLog: {
		display: "flex",
		flexDirection: "row",
		width: "98%",
		marginLeft: "1%",
		marginRight: "1%"
	},
	date: {
		color: "var(--text-color)",
		marginBottom: "0.25rem",
		opacity: "0.6"
	},
	images: {
		alignItems: "center",
		alignSelf: "start",
		display: "flex",
		flexDirection: "row",
		flexShrink: "0",
		width: "5rem",
		justifyContent: "center"
	},
	integrationImage: {
		border: "var(--surface-block-border)",
		borderRadius: "var(--border-radius-semi-rounded)",
		height: "2.5rem",
		position: "relative",
		width: "2.5rem"
	},
	message: {
		color: "var(--text-color)",
		fontSize: "1.125em"
	},
	textContainer: {
		display: "flex",
		flex: "1",
		flexDirection: "column",
		marginInlineStart: "2.25rem",
		overflow: "hidden"
	},
	userImage: {
		marginInlineStart: "-0.5rem",
		zIndex: "10"
	}
});
