import { useTranslation } from "react-i18next";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Modal } from "components/ui/Modal";
import { Form } from "components/ui/Form";
import { Input } from "components/ui/Input";
import { useOpenGlobalErrorModal } from "hooks/useGlobalError";
import { adjustConfiguration, checkConfig } from "api/directoryConfigurations";
import { getNameValidators } from "utils/validationUtils";
import { TitleBody } from "components/ui/TitleBody";
import { useStyles } from "./styles";
import {
	GoogleConfigurationForm,
	OktaConfigurationForm,
	JumpCloudConfigurationForm,
	OneLoginConfigurationForm,
	AzureConfigurationForm
} from "./Forms";
import type {
	IConfigForm,
	TConfigurationCreateBody,
	TConfigurationUpdateBody,
	TConfigurationSchema,
	TDirectory
} from "utils/directoryConfiguration";

interface IProps {
	directory: TDirectory;
	onClose: () => void;
	isOpen: boolean;
	onCreate: (data: TConfigurationCreateBody) => Promise<void>;
	onEdit: (id: string, data: TConfigurationUpdateBody) => Promise<unknown>;
	existingConfigurationId?: string;
}

const DIRECTORY_FORMS: Map<TDirectory, FC<IConfigForm>> = new Map([
	["google", GoogleConfigurationForm],
	["okta", OktaConfigurationForm],
	["azureAD", AzureConfigurationForm],
	["jumpcloud", JumpCloudConfigurationForm],
	["onelogin", OneLoginConfigurationForm]
]);

export const DirectoryConfigurationModal: FC<IProps> = ({
	isOpen = false,
	directory,
	onClose,
	onCreate,
	onEdit,
	existingConfigurationId
}) => {
	const classes = useStyles();
	const { t } = useTranslation();
	const [chosenDirectory, setChosenDirectory] = useState<TDirectory>(directory);
	const openGlobalErrorModal = useOpenGlobalErrorModal();

	const [displayName, setDisplayName] = useState("");
	const [enableSave, setEnableSave] = useState(false);
	const [error, setError] = useState<string>();
	const [shouldReset, setShouldReset] = useState(false);

	useEffect(() => {
		setEnableSave(!!existingConfigurationId);
	}, [existingConfigurationId]);

	const handleClose = useCallback(() => {
		onClose && onClose();
		setDisplayName("");
		setError("");
	}, [onClose]);

	const validators = useMemo(() => getNameValidators(t("pages.settings.directoryConfiguration.name")), [t]);

	const onNameError = useCallback((errors: string[] | null) => {
		setEnableSave(!errors?.length);
	}, []);

	const inEditMode = !!existingConfigurationId;

	const submit = useCallback(
		async (
			configuration: TConfigurationSchema,
			isDirectManagerSource?: boolean,
			agentToken?: string | null,
			toSnakeCase = true
		) => {
			setError("");
			try {
				const adjustedConfig = adjustConfiguration(configuration, toSnakeCase);
				const { isValid, message } = await checkConfig(chosenDirectory, adjustedConfig, agentToken);
				if (isValid && enableSave) {
					await (inEditMode
						? onEdit(existingConfigurationId, {
								agentToken,
								directoryConfiguration: {
									directory: chosenDirectory,
									configuration: adjustedConfig
								}
							})
						: onCreate({
								displayName,
								agentToken,
								directoryConfiguration: {
									directory: chosenDirectory,
									configuration: adjustedConfig,
									isDirectManagerSource
								}
							}));
					handleClose();
				} else {
					setError(message);
				}
			} catch (err) {
				openGlobalErrorModal(err as Error);
			}
		},
		[
			chosenDirectory,
			enableSave,
			onCreate,
			onEdit,
			inEditMode,
			existingConfigurationId,
			displayName,
			handleClose,
			openGlobalErrorModal
		]
	);

	const DirectoryForm = useMemo(() => DIRECTORY_FORMS.get(chosenDirectory) || null, [chosenDirectory]);

	useEffect(() => {
		if (!chosenDirectory || (directory && directory !== chosenDirectory)) {
			setChosenDirectory(directory);
		}
	}, [chosenDirectory, directory]);

	useEffect(() => {
		setShouldReset(!isOpen);
	}, [isOpen]);

	return (
		<Modal
			isOpen={isOpen}
			onClose={handleClose}
			content={
				<div className={classes.modalContent}>
					<TitleBody
						title={t(`pages.settings.directoryConfiguration.title`, {
							directoryType: t(`pages.settings.directoryConfiguration.${chosenDirectory}.name`)
						})}
					/>
					<Form className={classes.form}>
						{DirectoryForm && (
							<>
								{!inEditMode && (
									<Form.Field>
										<Input
											label={t("pages.settings.directoryConfiguration.name")}
											value={displayName}
											onValueChange={setDisplayName}
											validators={validators}
											onError={onNameError}
											fullWidth
											isRequired
										/>
									</Form.Field>
								)}
								<DirectoryForm
									inEditMode={inEditMode}
									onSave={submit}
									error={error}
									enableSave={enableSave}
									shouldReset={shouldReset}
								/>
							</>
						)}
					</Form>
				</div>
			}
		/>
	);
};
