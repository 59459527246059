import { Record } from "immutable";

type TTicketTargetType = "bundle" | "role";

const defaults = {
	id: "",
	targetId: "",
	type: "" as TTicketTargetType
};

export class TicketTargetModel extends Record<typeof defaults>(defaults) {
	static fromServerData(data: unknown): TicketTargetModel {
		return new TicketTargetModel(data as typeof defaults);
	}
}
