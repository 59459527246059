import { List } from "immutable";
import { getPaginatedSearchQueryString } from "utils/searchUtils";
import { DirectoryGroupModel } from "../models/DirectoryGroupModel";
import { apiReq } from "../utils/apiReq";

export async function getDirectoryGroups(includeDeleted = false, query?: string): Promise<List<DirectoryGroupModel>> {
	const searchQs = getPaginatedSearchQueryString({ search: { query } });
	const { data } = await apiReq(
		"GET",
		`/v1/directoryGroups?includeDeleted=${includeDeleted}${searchQs ? `&${searchQs}` : ""}`
	);

	return List<DirectoryGroupModel>(data.map((item: unknown) => DirectoryGroupModel.fromServerData(item)));
}
