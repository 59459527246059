import React, { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Checkbox } from "components/ui/Checkbox";
import { Typography } from "components/ui/Typography";
import { Tooltip } from "components/ui/Tooltip";
import { Map } from "immutable";
import { useStyles } from "./styles";
import type { TAccessReviewTemplateReviewers } from "models/AccessReviewTemplateModel";

interface IProps {
	onChange?: (values: TAccessReviewTemplateReviewers[]) => void;
	values: TAccessReviewTemplateReviewers[];
}

interface IReviewerTypeOption {
	description: string;
	isSelected?: boolean;
	label: string;
	value: TAccessReviewTemplateReviewers;
}

export const ReviewerType: FC<IProps> = ({ onChange, values }) => {
	const { t } = useTranslation();
	const classes = useStyles();
	const valuesAsMap = useMemo(
		() => Map({ manager: values.includes("manager"), resourceOwner: values.includes("resourceOwner") }),
		[values]
	);
	const options: IReviewerTypeOption[] = useMemo(
		() => [
			{
				value: "manager",
				label: t("pages.accessReviewTemplate.reviewerTypes.manager"),
				description: t("pages.accessReviewTemplate.reviewerTypes.managerDescription"),
				isSelected: valuesAsMap.get("manager")
			},
			{
				value: "resourceOwner",
				label: t("pages.accessReviewTemplate.reviewerTypes.resourceOwner"),
				description: t("pages.accessReviewTemplate.reviewerTypes.resourceOwnerDescription"),
				isSelected: valuesAsMap.get("resourceOwner")
			}
		],
		[valuesAsMap, t]
	);

	const onCheckboxChange = useCallback(
		(_: unknown, newValue: TAccessReviewTemplateReviewers) => {
			if (onChange) {
				onChange(valuesAsMap.get(newValue) ? values.filter(value => value !== newValue) : [...values, newValue]);
			}
		},
		[onChange, values, valuesAsMap]
	);

	useEffect(() => {
		if (values.length === 0) {
			onChange && onChange(["manager"]);
		}
	}, [values, onChange]);

	return (
		<>
			<Typography variant="h3">{t("pages.accessReviewTemplate.reviewerTypes.label")}</Typography>
			<Tooltip content={t("pages.accessReviewTemplate.reviewerTypes.atLeastOneRequired")}>
				<div className={classes.container}>
					{options.map(option => (
						<Checkbox
							description={option.description}
							disabled={values.length === 1 && option.isSelected}
							key={option.value}
							label={option.label}
							onClick={onCheckboxChange}
							selected={option.isSelected}
							value={option.value}
						/>
					))}
				</div>
			</Tooltip>
		</>
	);
};
