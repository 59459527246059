import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	actionButton: {
		"&$actionButton$actionButton": {
			"&:hover": {
				cursor: "pointer"
			},
			border: "1px solid var(--color-grey-light)",
			color: "var(--color-grey-dark)",
			fontSize: "1rem",
			height: "2rem",
			width: "2rem"
		},
		"&$approveButton$approveButton": {
			color: "var(--color-green-dark)",
			backgroundColor: "var(--color-green-10)",
			"&:active": {
				backgroundColor: "var(--color-green)",
				color: "var(--color-almost-white)"
			},
			"&:hover": {
				backgroundColor: "var(--color-green)",
				color: "var(--color-almost-white)"
			}
		},
		"&$declineButton$declineButton": {
			color: "var(--color-red-dark)",
			backgroundColor: "var(--color-red-10)",
			"&:active": {
				backgroundColor: "var(--color-red)",
				color: "var(--color-almost-white)"
			},
			"&:hover": {
				backgroundColor: "var(--color-red)",
				color: "var(--color-almost-white)"
			}
		},
		"&$viewTicketButton$viewTicketButton": {
			color: "var(--color-black)",
			backgroundColor: "var(--color-almost-white)",
			"&:active": {
				color: "var(--color-blue)"
			},
			"&:hover": {
				color: "var(--color-blue)"
			}
		}
	},
	approveButton: {},
	declineButton: {},
	actionButtons: {
		alignItems: "center",
		justifyContent: "center",
		display: "flex",
		flexDirection: "row",
		flexWrap: "wrap",
		gap: "0.5rem",
		height: "100%",
		overflowWrap: "inherit",
		width: "100%"
	},
	adminActionsContainer: {
		alignItems: "center",
		display: "flex",
		height: "100%",
		paddingRight: 0,
		width: "100%"
	},
	approvedStatusChip: {
		backgroundColor: "rgba(34, 227, 114, 0.1)"
	},
	container: {
		backgroundColor: "var(--color-white)",
		borderRadius: "var(--border-radius-semi-rounded)",
		height: "100%",
		overflow: "hidden"
	},
	viewTicketButton: {
		alignItems: "center",
		color: "var(--color-grey-dark)",
		display: "inline-flex",
		justifyContent: "space-around"
	},
	loader: {
		margin: "auto"
	}
});
