import React, { MutableRefObject, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import classNames from "classnames";
import { TicketModel } from "models/TicketModel";
import { notEmpty } from "utils/comparison";
import { useStyles } from "./styles";
import { RequestedBy } from "./components/RequestedBy";
import { RequestPermissions } from "./components/RequestPermissions";
import { RequestedDuration } from "./components/RequestedDuration";
import { RequestJustification } from "./components/RequestJustification";
import { RequestSummary } from "./components/RequestSummary";
import { RequestApprovalActions } from "./components/RequestApprovalActions";
import { RequestApprovers } from "./components/RequestApprovers";
import { RequestUsersPendingActions } from "./components/RequestUsersPendingActions";
import { RequestPastActions } from "./components/RequestPastActions";
import type { Require } from "utils/types";

type TBaseProps = {
	request: TicketModel;
	onClick?: (request: TicketModel) => Promise<void> | void;
	openSidebarOnClick?: boolean;
	short?: boolean;
};

type TAwaitingResponseProps = TBaseProps & {
	type: "awaitingResponse";
	onApprovalResponse: (response: "approve" | "deny") => void;
};

type TUserPendingProps = TBaseProps & {
	type: "userPending";
	onCancel?: (requestId: string) => void | Promise<void>;
};

type TPastProps = TBaseProps & {
	type: "past";
	onRenew?: (requestId: string) => void | Promise<void>;
};

type TProps = TAwaitingResponseProps | TUserPendingProps | TPastProps;

const RequestBar: FC<TProps> = props => {
	const { request, className, innerRef, short = false } = props;
	const [urlSearchParams] = useSearchParams();
	const [selected, setSelected] = useState(false);
	const containerRef = useRef<HTMLDivElement | null>(null);
	const navigate = useNavigate();
	const classes = useStyles();

	const isWaitingForApprovers = useMemo(() => {
		return props.type === "userPending" && request.status === "waitingForApproval";
	}, [props.type, request.status]);

	const actions = useMemo(() => {
		switch (props.type) {
			case "awaitingResponse":
				return <RequestApprovalActions respond={props.onApprovalResponse} />;
			case "userPending":
				return props.onCancel && isWaitingForApprovers ? (
					<RequestUsersPendingActions requestId={request.id} cancel={props.onCancel} />
				) : null;
			case "past":
				return props.onRenew ? <RequestPastActions requestId={request.id} renew={props.onRenew} /> : null;
		}
	}, [props, request.id, isWaitingForApprovers]);

	const approverJustificationClassName = useMemo(() => {
		if (isWaitingForApprovers) return classes.withApprovers;
		return short ? classes.shortJustification : classes.longJustification;
	}, [classes.longJustification, classes.shortJustification, classes.withApprovers, isWaitingForApprovers, short]);

	const roles = useMemo(() => {
		return (
			request.ticketPermissions
				?.map(permission => permission.integrationResourceRole)
				.filter(notEmpty)
				.toArray() || []
		);
	}, [request.ticketPermissions]);

	const onRequestClick = useCallback(async () => {
		if (props.onClick) await props.onClick(request);
		if (props.openSidebarOnClick) {
			setSelected(true);
			navigate(`?ticketId=${request.id}`);
		}
	}, [navigate, props, request]);

	useEffect(() => {
		if (urlSearchParams.get("ticketId") !== request.id) {
			setSelected(false);
		}
	}, [request.id, urlSearchParams]);

	return (
		<div
			onClick={onRequestClick}
			className={classNames(
				classes.container,
				{
					[classes.clickable]: !!(props.onClick || props.openSidebarOnClick),
					[classes.selected]: selected || urlSearchParams.get("ticketId") === request.id
				},
				className
			)}
			ref={element => {
				if (innerRef) {
					if (typeof innerRef === "function") {
						innerRef(element);
					} else {
						(innerRef as MutableRefObject<HTMLDivElement | null>).current = element;
					}
				}
				containerRef.current = element;
			}}>
			<RequestedBy requestedBy={request.receiverId} onBehalf={request.receiverId !== request.creatorId} />
			<RequestPermissions roles={roles} />
			<RequestedDuration duration={request.duration} fixedWidth />
			<div className={classNames(classes.approversJustification, approverJustificationClassName)}>
				<RequestJustification
					className={classes.grow}
					justification={request.comments?.first()?.content || ""}
					ticketingIntegrationTicket={request.ticketingIntegrationTicket}
				/>
				{isWaitingForApprovers ? (
					<RequestApprovers request={request as Require<TicketModel, "approvalRequests">} />
				) : null}
			</div>
			<RequestSummary
				toApprove={props.type === "awaitingResponse" && request.status === "waitingForApproval"}
				showStatus={props.type === "past" || request.status === "waitingForIT"}
				request={request}
				actions={actions}
			/>
		</div>
	);
};

const memoizedComponent = React.memo(RequestBar);
export { memoizedComponent as RequestBar };
