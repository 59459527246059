const styles = {
	"@keyframes fade-in": {
		"0%, 50%": {
			opacity: "0"
		},

		"100%": {
			opacity: "1"
		}
	},

	"@keyframes fade-out": {
		"0%": {
			opacity: "1"
		},

		"50%, 100%": {
			opacity: "0"
		}
	},
	transitionable: {
		"&.animate": {
			'&[data-animation="fade"]': {
				"&.in": {
					animation: "var(--animation-duration) var(--transition-easing) forwards $fade-in",
					zIndex: "10"
				},

				"&.out": {
					animation: "var(--animation-duration) var(--transition-easing) forwards $fade-out",
					zIndex: "5"
				},

				"&.reverse": {
					"&.in": {
						animation: "var(--animation-duration) var(--transition-easing-reversed) forwards reverse $fade-out"
					},

					"&.out": {
						animation: "var(--animation-duration) var(--transition-easing-reversed) forwards reverse $fade-in"
					}
				}
			}
		}
	}
};

export { styles };
