import { useUserContext } from "context/userContext";
import { useCallback, useEffect, useState } from "react";
import useMountState from "./useMountState";

export const useUser = () => {
	const {
		state: { user, error },
		actions: { loadUser: contextLoadUser, clearUser }
	} = useUserContext();

	const { ifMounted } = useMountState();
	const [loading, setLoading] = useState(false);

	const loadUser = useCallback(async () => {
		setLoading(true);
		await contextLoadUser();
		ifMounted(() => setLoading(false));
	}, [contextLoadUser, ifMounted]);

	useEffect(() => {
		if (!user && !error) {
			loadUser();
		}
	}, [error, loadUser, user]);

	return {
		clearUser,
		reloadUser: loadUser,
		error,
		loading,
		user
	};
};
