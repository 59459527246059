import React, { useMemo } from "react";
import { Typography } from "components/ui/Typography";
import { useTranslation } from "react-i18next";
import { useStyles } from "./styles";
import { PaginationButton } from "./components/PaginationButton";

export const PageSelect: FC<{
	currentPageNumber: number;
	pagesCount: number;
	pagesShownAmount: number;
	changePage: (page: number) => void;
}> = ({ currentPageNumber, pagesCount, pagesShownAmount, changePage }) => {
	const { t } = useTranslation();
	const classes = useStyles();

	const shownPages = useMemo(() => {
		const directionPagesShownAmount = Math.floor(pagesShownAmount / 2);
		const firstPageShown = Math.max(
			2,
			Math.min(currentPageNumber - directionPagesShownAmount, pagesCount - directionPagesShownAmount - 2)
		);
		const lastPageShown = firstPageShown + pagesShownAmount - 1;

		const pageNumbers = new Array(pagesShownAmount)
			.fill(0)
			.map((_, i) => firstPageShown + i)
			.filter(num => num < pagesCount) as Array<number | null>;

		if (firstPageShown > 2) {
			pageNumbers.unshift(null);
		}
		pageNumbers.unshift(1);

		if (lastPageShown < pagesCount - 1) {
			pageNumbers.push(null);
		}
		pageNumbers.push(pagesCount);
		return pageNumbers;
	}, [currentPageNumber, pagesShownAmount, pagesCount]);

	return (
		<div className={classes.pagination}>
			<PaginationButton onClick={() => changePage(currentPageNumber - 1)} disabled={currentPageNumber <= 1}>
				<Typography>{t("pagination.back")}</Typography>
			</PaginationButton>
			<div className={classes.paginationPages}>
				{shownPages.map((pageNumber, i) => {
					if (pageNumber === null) {
						return (
							<Typography key={"break-" + (i + 1)} className={classes.break}>
								...
							</Typography>
						);
					}
					return (
						<PaginationButton
							key={"page-" + pageNumber}
							onClick={() => changePage(pageNumber)}
							selected={currentPageNumber === pageNumber}
							variant="circle">
							<Typography>{pageNumber}</Typography>
						</PaginationButton>
					);
				})}
			</div>
			<PaginationButton onClick={() => changePage(currentPageNumber + 1)} disabled={currentPageNumber >= pagesCount}>
				<Typography>{t("pagination.next")}</Typography>
			</PaginationButton>
		</div>
	);
};
