import { useEffect } from "react";
import { useDirectoryGroupsContext } from "context/directoryGroupsContext";

export const useDirectoryGroups = () => {
	const {
		state: { directoryGroups },
		actions: { loadDirectoryGroups }
	} = useDirectoryGroupsContext();

	useEffect(() => {
		loadDirectoryGroups();
	}, [loadDirectoryGroups]);

	return directoryGroups;
};
