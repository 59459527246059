import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	container: {
		display: "inline-flex",
		gap: "0.5rem",
		flexDirection: "column"
	},
	inputsContainer: {
		display: "inline-flex",
		gap: "0.5rem",
		alignItems: "end"
	},
	yearInput: {
		display: "inline-block",
		width: "5.25rem"
	},
	monthInput: {
		display: "inline-block",
		width: "9rem"
	},
	dayInput: {
		display: "inline-block",
		width: "4rem"
	},
	hint: {
		"&.error": {
			color: "var(--color-red-dark)"
		},
		color: "var(--color-grey-dark)"
	},
	textContainer: {
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
		gap: "0.5rem"
	}
});
