import React, { useCallback, useMemo } from "react";
import { matchSorter } from "match-sorter";
import { useTranslation } from "react-i18next";
import { IRenderChipParams, MultipleSelect } from "components/ui/MultipleSelect";
import { Chip } from "components/ui/Chip";
import { useOnCallIntegrationSchedules } from "hooks/useOnCallIntegrationSchedules";
import { OnCallIntegrationScheduleModel } from "models/OnCallIntegrationScheduleModel";
import { useCompany } from "hooks/useCompany";
import { OnCallIntegrationScheduleLabel } from "components/common/OnCallIntegrationScheduleLabel";
import { useRankedSort } from "hooks/useRankedSort";
import { useStyles } from "./styles";

const ANY_SCHEDULES_ID = "anySchedules";

const EMPTY_LABEL_TRANSLATION = "flow.noOnCalls";
interface IProps {
	chipClassName?: string;
	disabled?: boolean;
	label?: string;
	onChangeScheduleIds: (scheduleIds: string[] | null) => void;
	onChangeToAnySchedules: () => void;
	values: string[];
	isAnySchedules: boolean;
}

const getOptionLabel = ({ name }: OnCallIntegrationScheduleModel) => name;

const SORT_OPTIONS = {
	keys: [{ key: (item: OnCallIntegrationScheduleModel) => item.name, threshold: matchSorter.rankings.MATCHES }]
};

const renderChip = ({
	noBorder,
	option,
	onRemove,
	componentKey,
	stretch,
	onClick
}: IRenderChipParams<OnCallIntegrationScheduleModel>) => (
	<Chip
		key={componentKey}
		onDelete={onRemove}
		noBorder={noBorder}
		stretch={stretch}
		size="small"
		onClick={onClick}
		multiLine>
		<OnCallIntegrationScheduleLabel
			value={option.name}
			onCallType={option.type || undefined}
			isDeleted={option.isDeleted}
		/>
	</Chip>
);

const renderOptionBase = (option: OnCallIntegrationScheduleModel, className: string) => (
	<OnCallIntegrationScheduleLabel
		className={className}
		value={option.name}
		onCallType={option.type || undefined}
		isDeleted={option.isDeleted}
	/>
);

const optionIsDisabled = (option: OnCallIntegrationScheduleModel) => option.isDeleted;

export const OnCallIntegrationSchedulesSelectInput: FC<IProps> = ({
	values,
	onChangeScheduleIds,
	onChangeToAnySchedules,
	className,
	label,
	disabled,
	isAnySchedules
}) => {
	const onCallIntegrationSchedules = useOnCallIntegrationSchedules();
	const { sort, onInputChange } = useRankedSort(SORT_OPTIONS);
	const company = useCompany();
	const classes = useStyles();
	const { t } = useTranslation();
	const options = useMemo(() => {
		const schedules =
			onCallIntegrationSchedules
				?.valueSeq()
				.toArray()
				.filter(schedule => !schedule.isDeleted || values.includes(schedule.id)) || [];
		schedules.push(
			new OnCallIntegrationScheduleModel({
				id: ANY_SCHEDULES_ID,
				name: t("flow.anyOnCallIntegrationSchedulesOption"),
				onCallIntegrationId: ""
			})
		);
		return schedules;
	}, [onCallIntegrationSchedules, t, values]);

	const handleChange = useCallback(
		(newValues: OnCallIntegrationScheduleModel[] | null) => {
			if (newValues?.[newValues.length - 1]?.id === ANY_SCHEDULES_ID) {
				onChangeToAnySchedules();
				return;
			}
			onChangeScheduleIds(newValues?.map(({ id }) => id)?.filter(id => id !== ANY_SCHEDULES_ID) || null);
		},
		[onChangeToAnySchedules, onChangeScheduleIds]
	);

	const selectedOptions = useMemo(
		() =>
			options?.filter(option => (option.id === ANY_SCHEDULES_ID ? isAnySchedules : values.includes(option.id))) || [],
		[options, values, isAnySchedules]
	);
	const renderOption = useCallback(
		(option: OnCallIntegrationScheduleModel) => renderOptionBase(option, classes.option),
		[classes]
	);

	if (!company?.integratedToOnCalls.size) {
		return null;
	}

	const loading = !onCallIntegrationSchedules;

	return (
		<MultipleSelect
			className={className}
			disabled={loading || disabled}
			onInputChange={onInputChange}
			getOptionLabel={getOptionLabel}
			label={label}
			loading={loading}
			onChange={handleChange}
			options={options}
			placeholder={values.length === 0 ? t(EMPTY_LABEL_TRANSLATION) : undefined}
			renderChip={renderChip}
			renderOption={renderOption}
			isOptionDisabled={optionIsDisabled}
			sort={sort}
			value={selectedOptions}
		/>
	);
};
