import { IntegrationModel } from "models/IntegrationModel";
import React, { useCallback } from "react";
import { IRenderChipParams, MultipleSelect } from "components/ui/MultipleSelect";
import { useSelectSearchProps } from "hooks/useSelectSearchProps";
import { OptionWithIcon } from "components/common/OptionWithIcon";
import { useTranslation } from "react-i18next";
import { getIntegrations } from "api/accessRequestForm";
import { useIntegrations } from "hooks/useIntegrations";
import { MultiSelectChip } from "../../../MultiSelectChip";

const LIMIT = 100;

interface IProps {
	setIntegrations: (value: IntegrationModel[] | null) => void;
	integrations: IntegrationModel[] | null;
	userId: string;
}

const getLabel = (option: IntegrationModel) => option.name;
const equality = (option: IntegrationModel, value: IntegrationModel) => option.id === value.id;

export const IntegrationsInput: FC<IProps> = ({ userId, setIntegrations, integrations }) => {
	const { t } = useTranslation(undefined, { keyPrefix: "pages.newTicket.newTicketForm.integrationsInput" });

	const globalIntegrations = useIntegrations();

	const renderChip = useCallback(
		({ option, componentKey, ...rest }: IRenderChipParams<IntegrationModel>) => {
			const integration = globalIntegrations?.get(option.id);
			return (
				<MultiSelectChip
					{...rest}
					key={componentKey}
					content={option.name}
					imageUrl={integration?.imageUrl || undefined}
				/>
			);
		},
		[globalIntegrations]
	);

	const renderOption = useCallback(
		(option: IntegrationModel) => {
			const integration = globalIntegrations?.get(option.id);
			return <OptionWithIcon imageUrl={integration?.imageUrl}>{option.name}</OptionWithIcon>;
		},
		[globalIntegrations]
	);

	const fetchIntegrations = useCallback(
		async (query: string) => {
			const response = await getIntegrations({ userId, search: query, perPage: LIMIT });
			return response.result.toArray();
		},
		[userId]
	);

	const { selectProps } = useSelectSearchProps(fetchIntegrations, integrations, true);

	return (
		<MultipleSelect
			{...selectProps}
			fullWidth
			getOptionLabel={getLabel}
			label={t("label")}
			limit={LIMIT}
			onChange={setIntegrations}
			isOptionEqualToValue={equality}
			placeholder={t("placeholder")}
			required
			renderChip={renderChip}
			renderOption={renderOption}
			sort={null}
			filter={null}
			value={integrations}
		/>
	);
};
