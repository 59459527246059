import React, { useMemo } from "react";
import { TooltipOnOverflow } from "components/ui/New/TooltipOnOverflow";
import { BundleModel } from "models/BundleModel";
import { BundleIcon } from "components/ui/Icons/BundleIcon";
import { Card, TCardProps } from "../Card";
import { ResourceAndRoleCountCardBody, TResourceAndRoleCountCardBodyProps } from "../ResourceAndRoleCountCardBody";

export type TBundleCardProps = Omit<TCardProps, "header" | "content"> &
	TResourceAndRoleCountCardBodyProps & {
		bundle: BundleModel;
	};

export const BundleCard: FC<TBundleCardProps> = props => {
	const { bundle, resourcesCount, resourcesLabel, rolesCount, rolesLabel, size, ...cardProps } = props;
	const isLarge = size === "large";

	const headerContent = useMemo(() => {
		if (bundle) {
			return (
				<>
					<BundleIcon size={isLarge ? 40 : 28} />
					<TooltipOnOverflow textVariant={isLarge ? "title_sb" : "body_sb"} content={bundle.name} />
				</>
			);
		}
		return null;
	}, [bundle, isLarge]);

	return (
		<Card
			size={size}
			content={
				<ResourceAndRoleCountCardBody
					resourcesCount={resourcesCount}
					resourcesLabel={resourcesLabel}
					rolesCount={rolesCount}
					rolesLabel={rolesLabel}
					size={size}
				/>
			}
			header={headerContent}
			{...cardProps}
		/>
	);
};
