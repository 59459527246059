import React, { useCallback, useMemo } from "react";
import classNames from "classnames";
import { RoleBar, TRoleBarColumn } from "components/common/RoleBar";
import { RoleBarBundle } from "components/common/RoleBar/components/RoleBarBundle";
import {
	ROLE_BAR_INTEGRATION_WIDTH,
	RoleBarIntegration
} from "components/common/RoleBar/components/RoleBarIntegration";
import { ROLE_BAR_RESOURCE_WIDTH, RoleBarResource } from "components/common/RoleBar/components/RoleBarResource";
import { ROLE_BAR_ROLE_WIDTH, RoleBarRole } from "components/common/RoleBar/components/RoleBarRole";
import { notEmpty } from "utils/comparison";
import { useIntegrations } from "hooks/useIntegrations";
import { useBundles } from "hooks/useBundles";
import { IconButton } from "components/ui/IconButton";
import { CloseIcon } from "components/ui/Icons/CloseIcon";
import { useNewRequestGrantMethods } from "components/pages/NewRequestPage/newRequestDataContext";
import { BundleIcon } from "components/ui/Icons/BundleIcon";
import { TitleTooltip } from "components/ui/TitleTooltip";
import { Typography } from "components/ui/New/Typography";
import { Chip } from "components/ui/New/Chip";
import { useStyles } from "./styles";
import type { TBundleRequestTarget, TRequestTarget, TRoleRequestTarget } from "components/pages/NewRequestPage/types";
import type { IntegrationResourceRoleModel } from "models/IntegrationResourceRoleModel";
import type { IntegrationResourceModel } from "models/IntegrationResourceModel";
import type { IntegrationModel } from "models/IntegrationModel";

const EMPTY_BUNDLE_COLUMN: TRoleBarColumn = {
	content: <RoleBarBundle />
};

const getRoleBarColumns = (
	role: IntegrationResourceRoleModel,
	resource: IntegrationResourceModel,
	integration: IntegrationModel,
	indirectAccess?: React.ReactNode
) => {
	return [
		{
			content: <RoleBarIntegration name={integration.name} imageUrl={integration.imageUrl} />,
			width: ROLE_BAR_INTEGRATION_WIDTH
		},
		{
			content: (
				<RoleBarResource
					name={resource.displayName}
					euid={resource.euid}
					description={resource.description || undefined}
					tags={resource.calculatedTags}
					type={resource.type}
				/>
			),
			width: ROLE_BAR_RESOURCE_WIDTH
		},
		{
			content: <RoleBarRole name={role.name} indirect={indirectAccess} />,
			width: ROLE_BAR_ROLE_WIDTH
		}
	] as TRoleBarColumn[];
};

type TTargetProps = {
	target: TRequestTarget;
	hasBundles?: boolean;
	removeTarget: (id: string) => void;
	chooseActor?: (id: string) => void;
};

export const RequestTarget: FC<TTargetProps> = ({
	target,
	hasBundles = false,
	removeTarget,
	chooseActor,
	innerRef,
	className
}) => {
	const integrations = useIntegrations();
	const bundles = useBundles();
	const classes = useStyles();
	const { data: grantMethods } = useNewRequestGrantMethods();

	const getRoleRoleBar = useCallback(
		(roleTarget: TRoleRequestTarget): TRoleBarColumn[] => {
			if (!bundles || !integrations) return [];
			const role = roleTarget.fullTarget;
			const resource = role.integrationResource;
			const integration = integrations.get(resource.integrationId);
			if (!integration) return [];
			let indirectAccess: React.ReactNode | undefined = undefined;
			if (roleTarget.grantMethodId !== roleTarget.id) {
				const targetGrantMethods = grantMethods.get(roleTarget.id);
				const grantMethod = targetGrantMethods?.grantMethods.get(roleTarget.grantMethodId || "");
				if (grantMethod) {
					const grantMethodResource = grantMethod.integrationResource;
					const grantMethodIntegration = integrations.get(grantMethodResource?.integrationId || "");
					if (grantMethodIntegration && grantMethodResource) {
						const grantMethodColumns = getRoleBarColumns(grantMethod, grantMethodResource, grantMethodIntegration);
						indirectAccess = <RoleBar columns={grantMethodColumns} noInteraction />;
					}
				}
			}
			const roleColumns = getRoleBarColumns(role, resource, integration, indirectAccess);
			return hasBundles ? [EMPTY_BUNDLE_COLUMN, ...roleColumns] : roleColumns;
		},
		[bundles, grantMethods, hasBundles, integrations]
	);

	const getBundleRoleBar = useCallback(
		(target: TBundleRequestTarget): TRoleBarColumn[] => {
			if (!bundles || !integrations) return [];
			const bundle = target.fullTarget;
			const roles = bundle.bundleItems.map(bundleItem => bundleItem.integrationResourceRole);
			const resources = roles.map(role => role.integrationResource);
			const uniqueResourcesIds = resources
				.map(resource => resource?.id)
				.filter(notEmpty)
				.toSet();
			const integrationIds = resources
				.map(resource => resource?.integrationId)
				.filter(notEmpty)
				.toSet();

			const tooltipRolesColumns = roles
				.map(role => {
					const resource = role.integrationResource;
					const integration = integrations.get(resource?.integrationId || "");
					if (!integration || !resource) return null;
					return { columns: getRoleBarColumns(role, resource, integration), id: role.id };
				})
				.filter(notEmpty)
				.toArray();

			const tooltipContent = {
				header: (
					<div className={classes.bundleTooltipHeader}>
						<BundleIcon size={24} />
						<Typography variant="body_sb">{bundle.name}</Typography>
						<Chip size="small">{roles.size}</Chip>
					</div>
				),
				body: tooltipRolesColumns.map(roleBar => <RoleBar key={roleBar.id} columns={roleBar.columns} noInteraction />)
			};

			const bundleColumns = [
				{
					content: <RoleBarBundle name={bundle.name} description={bundle.description} tags={bundle.tags} />
				},
				{
					content: (
						<TitleTooltip header={tooltipContent.header} body={tooltipContent.body}>
							<RoleBarIntegration amount={integrationIds.size} />
						</TitleTooltip>
					),
					width: ROLE_BAR_INTEGRATION_WIDTH
				},
				{
					content: (
						<TitleTooltip header={tooltipContent.header} body={tooltipContent.body}>
							<RoleBarResource amount={uniqueResourcesIds.size} />
						</TitleTooltip>
					),
					width: ROLE_BAR_RESOURCE_WIDTH
				},
				{
					content: (
						<TitleTooltip header={tooltipContent.header} body={tooltipContent.body}>
							<RoleBarRole amount={roles.size} />
						</TitleTooltip>
					),
					width: ROLE_BAR_ROLE_WIDTH
				}
			] as TRoleBarColumn[];

			return bundleColumns;
		},
		[bundles, classes.bundleTooltipHeader, integrations]
	);

	const columns = useMemo(() => {
		if (target.type === "role") {
			return getRoleRoleBar(target);
		} else {
			return getBundleRoleBar(target);
		}
	}, [getBundleRoleBar, getRoleRoleBar, target]);

	const onChooseActorClick = useCallback(() => {
		chooseActor?.(target.id);
	}, [chooseActor, target.id]);

	const onRemoveTargetClick = useCallback(() => {
		removeTarget(target.id);
	}, [removeTarget, target.id]);

	return (
		<div className={classNames(classes.targetRow, className)} ref={innerRef}>
			<RoleBar
				selected
				className={classNames({ [classes.chooseActorWrap]: !!chooseActor })}
				noInteraction={!!chooseActor}
				onClick={chooseActor ? onChooseActorClick : undefined}
				columns={columns}
			/>
			<IconButton size="medium" onClick={onRemoveTargetClick}>
				<CloseIcon />
			</IconButton>
		</div>
	);
};
