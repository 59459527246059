import React, { useMemo } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { Section } from "components/ui/Section";
import { Typography } from "components/ui/New/Typography";
import { Chip } from "components/ui/New/Chip";
import { RequestCart } from "components/common/RequestCart";
import { useStyles } from "./styles";
import { useAutoSelectGrantMethods, useRequestCartData } from "./selectedTargetsSection.hooks";

export const SelectedTargetsSection: FC = ({ className, innerRef }) => {
	const { t } = useTranslation("translation", { keyPrefix: "pages.newRequest.selectRolesStep.selectedTargetsSection" });
	const classes = useStyles();

	useAutoSelectGrantMethods();
	const {
		cart,
		cartItems,
		changeGrantMethod,
		getFetchRoleGrantMethods,
		getRoleTotalGrantMethods,
		removeAppFromCart,
		removeResource,
		removeRole,
		roleToGrantMethods
	} = useRequestCartData();

	const title = useMemo(() => {
		return (
			<>
				<Typography variant="body_sb">{t("title")}</Typography>
				<Chip size="small">{cart.size}</Chip>
			</>
		);
	}, [cart.size, t]);

	return (
		<Section className={classNames(classes.container, className)} innerRef={innerRef} title={title} noDivider>
			<RequestCart
				cart={cart}
				cartItems={cartItems}
				changeGrantMethod={changeGrantMethod}
				fetchRoleGrantMethods={getFetchRoleGrantMethods}
				getRoleTotalGrantMethods={getRoleTotalGrantMethods}
				onRemoveResource={removeResource}
				onRemoveRole={removeRole}
				removeAppFromCart={removeAppFromCart}
				roleToGrantMethods={roleToGrantMethods}
			/>
		</Section>
	);
};
