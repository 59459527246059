import React, { useCallback, useMemo, useState } from "react";
import classNames from "classnames";
import random from "lodash/random";
import { useTranslation } from "react-i18next";
import { useBreakpoint } from "hooks/useBreakpoint";
import { BP } from "utils/breakpoints";
import { Tooltip } from "components/ui/Tooltip";
import { IndirectAccessIcon } from "components/ui/Icons/IndirectAccessIcon";
import { Typography } from "components/ui/New/Typography";
import { DirectAccessIcon } from "components/ui/Icons/DirectAccessIcon";
import { RoleBar, TRoleBarColumn } from "components/common/RoleBar";
import {
	ROLE_BAR_INTEGRATION_WIDTH,
	RoleBarIntegration
} from "components/common/RoleBar/components/RoleBarIntegration";
import { ROLE_BAR_RESOURCE_WIDTH, RoleBarResource } from "components/common/RoleBar/components/RoleBarResource";
import { ROLE_BAR_ROLE_WIDTH, RoleBarRole } from "components/common/RoleBar/components/RoleBarRole";
import { IconButton } from "components/ui/IconButton";
import { CloseIcon } from "components/ui/Icons/CloseIcon";
import { ExtraOptionsButton, TSelectClickableOption } from "components/common/ExtraOptionsButton";
import useIsOpenState from "hooks/useIsOpenState";
import { Modal } from "components/ui/Modal";
import { Button } from "components/ui/Button";
import { TitleBody } from "components/ui/TitleBody";
import { RoleIcon } from "components/ui/Icons/RoleIcon";
import { PaginatedVirtualList } from "components/ui/VirtualList";
import { ROLE_BAR_HEIGHT_PX } from "components/common/RoleBar/styles";
import { useIntegrations } from "hooks/useIntegrations";
import { TooltipOnOverflow } from "components/ui/New/TooltipOnOverflow";
import { SPACING_X1 } from "globalStylesVariables";
import { Skeleton } from "components/ui/Skeleton";
import { RolesTitle } from "../helperComponents";
import { useStyles } from "./styles";
import type { TCartResourceCardProps } from "../../types";
import type { IntegrationResourceRoleModel } from "models/IntegrationResourceRoleModel";
import type { TIconProps } from "components/ui/Icon";

type TRoleRowProps = Omit<TCartResourceCardProps, "resource" | "selectedRoles" | "onRemoveResource"> & {
	role: IntegrationResourceRoleModel;
	grantMethodRoleId: string;
};

type TIndirectAccessModalProps = Omit<TRoleRowProps, "onRemoveRole"> & {
	close: () => void;
	isOpen: boolean;
};

const getRoleKey = (role?: IntegrationResourceRoleModel) => role?.id || `loading-${random(true)}`;

type TIndirectAccessModalSectionProps = {
	Icon: FC<TIconProps>;
	header: string;
	roles: IntegrationResourceRoleModel[];
	selectedId?: string;
	setSelected?: (id: string) => void;
	fetchPage?: (page: number) => void;
	perPage?: number;
	totalGrantMethods?: number;
	noInteraction?: boolean;
};

const IndirectAccessModalSection: FC<TIndirectAccessModalSectionProps> = ({
	Icon,
	header,
	roles,
	selectedId,
	setSelected,
	fetchPage,
	perPage = 100,
	totalGrantMethods,
	noInteraction = false
}) => {
	const integrations = useIntegrations();
	const classes = useStyles();
	const getRoleBar = useCallback(
		(role?: IntegrationResourceRoleModel) => {
			if (!role) return <Skeleton height={ROLE_BAR_HEIGHT_PX} />;
			const resource = role.integrationResource;
			const integration = integrations?.get(resource?.integrationId || "");
			if (!integration || !resource) return null;
			const columns: TRoleBarColumn[] = [
				{
					content: <RoleBarIntegration name={integration.name} imageUrl={integration.imageUrl} />,
					width: ROLE_BAR_INTEGRATION_WIDTH
				},
				{
					content: (
						<RoleBarResource
							name={resource.displayName}
							euid={resource.euid}
							description={resource.description || undefined}
							tags={resource.calculatedTags}
							type={resource.type}
						/>
					),
					width: ROLE_BAR_RESOURCE_WIDTH
				},
				{
					content: <RoleBarRole name={role.name} />,
					width: ROLE_BAR_ROLE_WIDTH
				}
			];
			const isSelected = selectedId ? role.id === selectedId : undefined;
			const select = setSelected ? () => setSelected(role.id) : undefined;
			return (
				<RoleBar key={role.id} columns={columns} selected={isSelected} onClick={select} noInteraction={noInteraction} />
			);
		},
		[integrations, noInteraction, selectedId, setSelected]
	);

	const roleList = useMemo(() => {
		if (!fetchPage || !totalGrantMethods) return roles.map(getRoleBar);
		return (
			<PaginatedVirtualList
				fetchPage={fetchPage}
				getKey={getRoleKey}
				itemHeight={ROLE_BAR_HEIGHT_PX}
				gapSize={SPACING_X1 * 2}
				itemRenderer={getRoleBar}
				items={roles}
				perPage={perPage}
				totalItems={totalGrantMethods}
				height={Math.min(totalGrantMethods, 6) * (ROLE_BAR_HEIGHT_PX + SPACING_X1 * 2)}
			/>
		);
	}, [fetchPage, getRoleBar, perPage, roles, totalGrantMethods]);

	return (
		<div className={classes.modalSection}>
			<div className={classNames(classes.modalSectionHeader, classes.modalIconText)}>
				<Icon size={24} />
				<Typography variant="body_sb">{header}</Typography>
			</div>
			{roleList}
		</div>
	);
};

const IndirectAccessModal: FC<TIndirectAccessModalProps> = ({
	changeGrantMethod,
	close,
	isOpen,
	role,
	roleToGrantMethods,
	grantMethodRoleId,
	fetchRoleGrantMethods,
	getRoleTotalGrantMethods,
	perPage
}) => {
	const { t } = useTranslation();
	const classes = useStyles();
	const [selectedGrantMethodRoleId, setSelectedGrantMethodRoleId] = useState(grantMethodRoleId);
	const apply = useCallback(() => {
		changeGrantMethod(role.id, selectedGrantMethodRoleId);
		close();
	}, [changeGrantMethod, close, role.id, selectedGrantMethodRoleId]);
	const hasDirectAccessOption = useMemo(() => {
		const possibleGrantMethods = roleToGrantMethods.get(role.id) || [];
		return possibleGrantMethods.some(({ id }) => id === role.id);
	}, [role.id, roleToGrantMethods]);

	const directAccessRoles = useMemo(() => [role], [role]);
	const indirectAccessRoles = useMemo(() => {
		const possibleGrantMethods = roleToGrantMethods.get(role.id)?.toArray() || [];
		return possibleGrantMethods.filter(({ id }) => id !== role.id);
	}, [role.id, roleToGrantMethods]);

	const fetchMoreGrantMethods = useCallback(
		(page: number) => fetchRoleGrantMethods(role.id)(page),
		[fetchRoleGrantMethods, role.id]
	);

	const totalGrantMethods = useMemo(() => {
		return getRoleTotalGrantMethods(role.id);
	}, [getRoleTotalGrantMethods, role.id]);

	return (
		<Modal
			isOpen={isOpen}
			onClose={close}
			content={
				<div className={classes.modalContent}>
					<TitleBody
						size="large"
						className={classes.modalSectionHeader}
						title={t("common.resourceCard.cart.indirectAccessModal.title")}
						body={t("common.resourceCard.cart.indirectAccessModal.body")}
					/>
					<IndirectAccessModalSection
						Icon={RoleIcon}
						header={t("common.resourceCard.cart.indirectAccessModal.requestedRole")}
						roles={directAccessRoles}
						noInteraction
					/>
					{hasDirectAccessOption ? (
						<IndirectAccessModalSection
							Icon={DirectAccessIcon}
							header={t("common.resourceCard.cart.indirectAccessModal.directAccess")}
							roles={directAccessRoles}
							selectedId={selectedGrantMethodRoleId}
							setSelected={setSelectedGrantMethodRoleId}
						/>
					) : null}
					<IndirectAccessModalSection
						Icon={IndirectAccessIcon}
						header={t("common.resourceCard.cart.indirectAccessModal.indirectAccess")}
						roles={indirectAccessRoles}
						selectedId={selectedGrantMethodRoleId}
						setSelected={setSelectedGrantMethodRoleId}
						fetchPage={fetchMoreGrantMethods}
						perPage={perPage}
						totalGrantMethods={hasDirectAccessOption ? totalGrantMethods - 1 : totalGrantMethods}
					/>
				</div>
			}
			actions={
				<>
					<Button size="medium" onClick={close} variant="secondary">
						{t("buttons.cancel")}
					</Button>
					<Button size="medium" onClick={apply} disabled={grantMethodRoleId === selectedGrantMethodRoleId}>
						{t("buttons.apply")}
					</Button>
				</>
			}
		/>
	);
};

const IndirectAccessFromRole: FC<{ role: IntegrationResourceRoleModel }> = ({ role }) => {
	const { t } = useTranslation();
	const classes = useStyles();
	const [isBigger] = useBreakpoint(BP.FOURTH);
	const content = useMemo(() => {
		if (!isBigger) return null;
		const resource = role.integrationResource;
		const integration = resource?.integration;
		if (!integration) return null;

		const roleBarColumns: TRoleBarColumn[] = [
			{
				content: <RoleBarIntegration name={integration.name} imageUrl={integration.imageUrl} />,
				width: ROLE_BAR_INTEGRATION_WIDTH
			},
			{
				content: <RoleBarResource name={resource.displayName} euid={resource.euid} />,
				width: ROLE_BAR_RESOURCE_WIDTH
			},
			{
				content: <RoleBarRole name={role.name} />,
				width: ROLE_BAR_ROLE_WIDTH
			}
		];
		return (
			<div className={classes.tooltipContent}>
				<Typography variant="body_sb" noWrap>
					{t("common.resourceCard.cart.indirectAccessFrom")}
				</Typography>
				<RoleBar columns={roleBarColumns} />
			</div>
		);
	}, [classes.tooltipContent, isBigger, role.integrationResource, role.name, t]);

	return (
		<Tooltip content={content}>
			<IndirectAccessIcon size={32} />
		</Tooltip>
	);
};

const RoleRow: FC<TRoleRowProps> = ({
	changeGrantMethod,
	grantMethodRoleId,
	onRemoveRole,
	role,
	roleToGrantMethods,
	fetchRoleGrantMethods,
	getRoleTotalGrantMethods,
	perPage
}) => {
	const { t } = useTranslation();
	const classes = useStyles();
	const { isOpen, open, close } = useIsOpenState();

	const possibleGrantMethods = roleToGrantMethods.get(role.id)?.toArray() || [];
	const grantMethodRole = possibleGrantMethods.find(({ id }) => id === grantMethodRoleId);
	const hasMultipleAccessOptions = possibleGrantMethods.length > 1;
	const isIndirectAccess = grantMethodRoleId !== role.id;
	const icon = useMemo(() => {
		if (!grantMethodRole) return null;
		if (!isIndirectAccess && !hasMultipleAccessOptions) return null;
		return isIndirectAccess ? <IndirectAccessFromRole role={grantMethodRole} /> : <DirectAccessIcon size={20} />;
	}, [grantMethodRole, hasMultipleAccessOptions, isIndirectAccess]);

	const removeRole = useCallback(() => onRemoveRole(role.id), [onRemoveRole, role.id]);

	const actions = useMemo(() => {
		if (!hasMultipleAccessOptions) {
			return (
				<IconButton size="small" onClick={removeRole}>
					<CloseIcon />
				</IconButton>
			);
		}
		const extraOptions = new Map<string, TSelectClickableOption<IntegrationResourceRoleModel>>([
			[
				"changeGrantMethod",
				{
					label: t("common.resourceCard.cart.selectIndirectPath"),
					value: "changeGrantMethod",
					Icon: <IndirectAccessIcon size={32} />,
					onClick: () => open(),
					size: "medium"
				}
			],
			[
				"removeRole",
				{
					label: t("common.resourceCard.cart.removeRole"),
					value: "removeRole",
					Icon: <CloseIcon size={32} />,
					onClick: () => removeRole(),
					size: "medium"
				}
			]
		]);
		return <ExtraOptionsButton noBorder extraOptions={extraOptions} item={role} />;
	}, [hasMultipleAccessOptions, open, removeRole, role, t]);

	return (
		<>
			<IndirectAccessModal
				changeGrantMethod={changeGrantMethod}
				close={close}
				fetchRoleGrantMethods={fetchRoleGrantMethods}
				getRoleTotalGrantMethods={getRoleTotalGrantMethods}
				grantMethodRoleId={grantMethodRoleId}
				isOpen={isOpen}
				perPage={perPage}
				role={role}
				roleToGrantMethods={roleToGrantMethods}
			/>
			<div className={classNames(classes.roleRow, { [classes.withIcon]: !!icon })}>
				{icon}
				<TooltipOnOverflow textVariant="text_reg" content={role.name} />
				{actions}
			</div>
		</>
	);
};

export const CartResourceCardContent: FC<Omit<TCartResourceCardProps, "resource">> = ({
	className,
	innerRef,
	selectedRoles,
	...restProps
}) => {
	const classes = useStyles();
	const rows = useMemo(() => {
		return selectedRoles
			.valueSeq()
			.map(({ role, grantMethodRoleId }) => (
				<RoleRow key={role.id} role={role} grantMethodRoleId={grantMethodRoleId} {...restProps} />
			));
	}, [restProps, selectedRoles]);

	return (
		<div className={classNames(classes.container, className)} ref={innerRef}>
			<RolesTitle chipContent={selectedRoles.size} />
			<div className={classes.rolesList}>{rows}</div>
		</div>
	);
};
