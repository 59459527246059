import React, { useCallback, useEffect, useRef, useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Input } from "components/ui/Input";
import { useWorkflowEditorContext } from "context/workflowEditorContext";
import { useApprovalAlgorithmsContext } from "context/approvalAlgorithmsContext";
import { ApprovalAlgorithmModel } from "models/ApprovalAlgorithmModel";
import { Button } from "components/ui/Button";
import { WorkflowsIcon } from "components/ui/Icons/WorkflowsIcon";
import { Title } from "components/ui/Title";
import { Tooltip } from "components/ui/Tooltip";
import { removeRedundantSpaces } from "utils/removeRedundantSpaces";
import { getNameValidators } from "utils/validationUtils";
import { useOpenGlobalErrorModal } from "hooks/useGlobalError";
import useIsOpenState from "hooks/useIsOpenState";
import { AreYouSureModal } from "components/common/AreYouSureModal";
import { useStyles } from "./styles";

interface IReadonlyProps {
	algorithm: ApprovalAlgorithmModel;
	onEdit?: (algorithmId: string) => void;
	withDelete?: true;
	useContext?: never;
}

interface IEditProps {
	useContext: true;
	algorithm?: never;
	onEdit?: never;
	onDelete?: never;
}

type TProps = IReadonlyProps | IEditProps;

const HiddenNameInput: FC<{
	algorithmName: string;
	isValid: boolean;
	onNameChange: (value: string) => void;
}> = ({ algorithmName, innerRef, onNameChange, isValid }) => {
	const [visible, setVisible] = useState(false);
	const inputRef = useRef<HTMLInputElement | null>(null);
	const setShowInput = useCallback((isVisible: boolean) => {
		if (inputRef?.current && inputRef.current === document.activeElement) {
			return;
		}
		setVisible(isVisible);
	}, []);
	const { t } = useTranslation();

	const onClick = useCallback(() => setShowInput(true), [setShowInput]);

	const onHide = useCallback(() => {
		if (isValid) {
			onNameChange(removeRedundantSpaces(algorithmName));
			setShowInput(false);
		}
	}, [algorithmName, isValid, onNameChange, setShowInput]);

	const classes = useStyles();

	useEffect(() => {
		!isValid && setVisible(true);
	}, [isValid]);

	const validators = useMemo(() => getNameValidators(t("shared.name")), [t]);

	return (
		<div ref={innerRef as React.Ref<HTMLDivElement>}>
			<div className={classes.hiddenNameInputContainer} onClick={onClick}>
				{visible ? (
					<Input
						autoFocus
						className={classes.hiddenNameInput}
						inputRef={inputRef}
						onBlur={onHide}
						onValueChange={onNameChange}
						placeholder={t("common.approvalAlgorithm.name")}
						type="text"
						validators={validators}
						value={algorithmName || ""}
						variant="line"
					/>
				) : (
					<span className={classes.algorithmName}>{algorithmName}</span>
				)}
			</div>
		</div>
	);
};

export const AlgorithmTitle: FC<TProps> = ({ useContext, algorithm, onEdit }) => {
	return useContext ? <TitleWithContext /> : <TitleWithProps algorithm={algorithm} onEdit={onEdit} withDelete />;
};

const TitleWithContext: FC = () => {
	const { t } = useTranslation();
	const classes = useStyles();
	const {
		state: { name, isNameValid },
		actions: { addNewRuleFlow, setName }
	} = useWorkflowEditorContext();

	const onNameChange = useCallback(
		(newName: string) => {
			setName(newName);
		},
		[setName]
	);

	const onAddRuleFlow = useCallback(() => addNewRuleFlow(), [addNewRuleFlow]);

	return (
		<Title variant="h3" noBorder className={classes.titleContainer}>
			<div className={classes.title}>
				<WorkflowsIcon />
				<Tooltip content={t("common.approvalAlgorithm.editName")}>
					<HiddenNameInput algorithmName={name || ""} isValid={isNameValid} onNameChange={onNameChange} />
				</Tooltip>
			</div>
			<Button variant="secondary" size="medium" onClick={onAddRuleFlow}>
				{t("flow.addRule")}
			</Button>
		</Title>
	);
};

const TitleWithProps: FC<IReadonlyProps> = ({ algorithm, onEdit, withDelete = false }) => {
	const classes = useStyles();
	const { t } = useTranslation();
	const { isOpen: areYouSureIsOpen, open: openAreYouSureModal, close: closeAreYouSureModal } = useIsOpenState();
	const openGlobalErrorModal = useOpenGlobalErrorModal();
	const editWorkflow = useCallback(() => {
		onEdit && onEdit(algorithm.id);
	}, [algorithm, onEdit]);

	const {
		actions: { deleteApprovalAlgorithm }
	} = useApprovalAlgorithmsContext();

	const deleteWithErrorHandle = useCallback(async () => {
		try {
			await deleteApprovalAlgorithm(algorithm.id);
		} catch (err) {
			openGlobalErrorModal(err as Error);
		} finally {
			closeAreYouSureModal();
		}
	}, [algorithm.id, closeAreYouSureModal, deleteApprovalAlgorithm, openGlobalErrorModal]);

	return (
		<>
			{areYouSureIsOpen && (
				<AreYouSureModal
					isOpen
					onClose={closeAreYouSureModal}
					content={t("pages.workflows.deleteWorkflow.modalContent")}
					onAction={deleteWithErrorHandle}
				/>
			)}
			<Title variant="h3" noBorder className={classes.titleContainer}>
				<div className={classes.title}>
					<WorkflowsIcon />
					<span>{algorithm.name}</span>
				</div>
				<div className={classes.actions}>
					{onEdit && (
						<Button size="medium" variant="secondary" onClick={editWorkflow}>
							{t("common.approvalAlgorithm.editWorkflow")}
						</Button>
					)}
					{withDelete && (
						<Button size="medium" onClick={openAreYouSureModal}>
							{t("buttons.delete")}
						</Button>
					)}
				</div>
			</Title>
		</>
	);
};
