import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	container: {
		alignSelf: "flex-start",
		alignItems: "flex-start",
		backgroundColor: "var(--color-white)",
		borderRadius: "8px 8px 32px 32px",
		display: "flex",
		flexDirection: "column",
		gap: "20px",
		paddingBottom: "8px",
		justifyContent: "center",
		minHeight: "158px",
		minWidth: "280px",
		maxWidth: "500px",
		overflow: "hidden",
		"&$regular": {
			border: "3px solid var(--color-grey-400)",
			paddingBottom: "24px",
			"&:hover": {
				backgroundColor: "var(--color-blue-100)"
			},
			"& > $header": {
				borderBottom: "3px solid var(--color-grey-400)"
			},
			"&$selected": {
				borderColor: "var(--color-blue-500)",
				"& > $header": {
					borderColor: "var(--color-blue-500)"
				}
			}
		},
		"&$expanded": {
			border: "4px solid var(--color-blue-500)",
			paddingBottom: "8px",
			"&:hover > $header": {
				backgroundColor: "var(--color-purple-50)"
			},
			"& > $header": {
				borderBottom: "4px solid var(--color-blue-500)"
			},
			"&$selected > $header": {
				backgroundColor: "var(--color-blue-500)",
				color: "var(--color-white)"
			}
		},
		"&$cart": {
			border: "4px solid var(--color-blue-500)",
			paddingBottom: "8px",
			"& > $header": {
				backgroundColor: "var(--color-blue-500)",
				borderBottom: "4px solid var(--color-blue-500)",
				color: "var(--color-white)"
			}
		}
	},
	header: {
		display: "grid",
		height: "88px",
		minHeight: "88px",
		gridTemplateRows: "20px 1fr",
		gridTemplateColumns: "100%",
		width: "100%"
	},
	headerActions: {
		display: "flex",
		alignItems: "flex-start",
		justifyContent: "flex-end",
		width: "100%"
	},
	headerResource: {
		display: "flex",
		padding: "0px 16px",
		flexDirection: "column",
		alignItems: "flex-start",
		gap: "12px",
		width: "100%"
	},
	headerResourceName: {
		display: "flex",
		alignItems: "flex-start",
		gap: "4px",
		alignSelf: "stretch"
	},
	rolesTitleContainer: {
		alignItems: "center",
		display: "flex",
		gap: "8px",
		padding: "0px 16px"
	},
	rolesTitle: {
		alignItems: "center",
		display: "flex",
		gap: "4px"
	},
	body: {
		alignItems: "flex-start",
		display: "flex",
		flexDirection: "column",
		gap: "20px",
		justifyContent: "center",
		minHeight: "21px",
		transitions: "height 0.5s ease-in-out",
		width: "100%",
		willChange: "height"
	},
	clamp: {
		display: "-webkit-box",
		"-webkit-line-clamp": 2,
		"-webkit-box-orient": "vertical",
		overflow: "hidden"
	},
	chipContainer: { "& > div": { maxWidth: "100px" } },
	clickable: {
		cursor: "pointer"
	},
	cart: {},
	expanded: {},
	regular: {},
	selected: {}
});
