import React, { useMemo } from "react";
import { Modal } from "components/ui/Modal";
import { useTranslation } from "react-i18next";
import { Typography } from "components/ui/Typography";
import { TitleBody } from "components/ui/TitleBody";
import { useStyles } from "./styles";
import type { PolicyConflictsModel } from "models/PolicyConflictsModel";

interface IProps {
	conflicts: PolicyConflictsModel | null;
	closeModal: () => void;
	isOpen: boolean;
}

const TRANSLATION_PREFIX = "pages.policies.conflicts";

export const PolicyConflictsModal: FC<IProps> = ({ conflicts, closeModal, isOpen }) => {
	const classes = useStyles();
	const { t } = useTranslation();

	const actorlessRolesContent = useMemo(() => {
		return conflicts && conflicts.actorlessRoles.length ? (
			<div>
				<Typography>
					{t(`${TRANSLATION_PREFIX}.actorlessRoles`, { rolesCount: conflicts!.actorlessRoles.length })}
				</Typography>
				<ul>
					{conflicts!.actorlessRoles.map(role => (
						<li key={role.roleId}>
							<Typography variant="small">
								{role.roleName}
								{role.bundleName ? ` (${role.bundleName})` : ""}
							</Typography>
						</li>
					))}
				</ul>
			</div>
		) : null;
	}, [conflicts, t]);

	const roleDupesContent = useMemo(() => {
		return conflicts && Object.keys(conflicts.roleDuplications).length ? (
			<div>
				<Typography>
					{t(`${TRANSLATION_PREFIX}.roleDuplications`, { count: Object.keys(conflicts!.roleDuplications).length })}
				</Typography>
				<ul>
					{Object.values(conflicts!.roleDuplications).map(
						({ integrationName, resourceName, takenRole, ignoredRoles }) => (
							<li key={takenRole.roleId}>
								<Typography variant="small">
									{t(`${TRANSLATION_PREFIX}.roleDuplication`, {
										integrationName,
										resourceName,
										roleName: takenRole.roleName,
										count: ignoredRoles.length,
										ignoredRolesCount: ignoredRoles.length
									})}
								</Typography>
							</li>
						)
					)}
				</ul>
			</div>
		) : null;
	}, [conflicts, t]);

	return (
		<Modal
			isOpen={isOpen}
			onClose={closeModal}
			content={
				<div className={classes.modalContent}>
					<TitleBody size="large" title={t(`${TRANSLATION_PREFIX}.title`)} />
					{actorlessRolesContent}
					{roleDupesContent}
				</div>
			}
		/>
	);
};
