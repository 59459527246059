import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	image: {
		height: "1rem",
		width: "1rem"
	},
	bundleChip: {
		display: "flex",
		flexDirection: "column",
		padding: "0.2rem",
		gap: "0.2rem"
	},
	roleTitle: {
		fontWeight: "bold",
		"&.deleted": {
			"&::after": {
				color: "var(--color-red)",
				fontWeight: "normal",
				content: "'*'"
			}
		}
	}
});
