import { createUseStyles } from "react-jss";

export const REQUEST_DURATION_WIDTH = 152;
export const MIN_REQUEST_DURATION_WIDTH = 100;

export const useStyles = createUseStyles({
	container: {
		alignItems: "flex-start",
		display: "flex",
		flexDirection: "column",
		gap: "8px",
		justifyContent: "flex-start",
		width: ({ fixedWidth }: { fixedWidth: boolean }) => (fixedWidth ? `${REQUEST_DURATION_WIDTH}px` : "fit-content"),
		maxWidth: `${REQUEST_DURATION_WIDTH}px`,
		minWidth: `${MIN_REQUEST_DURATION_WIDTH}px`
	},
	duration: {
		alignItems: "center",
		display: "flex",
		flexWrap: "nowrap",
		gap: "4px"
	}
});
