import { createUseStyles } from "react-jss";
import { BP_MEDIA_QUERIES } from "utils/breakpoints";

export const useStyles = createUseStyles({
	tiles: {
		display: "grid",
		[BP_MEDIA_QUERIES.FOURTH]: {
			gridTemplateColumns: "repeat(3, 1fr)"
		},
		[BP_MEDIA_QUERIES.THIRD]: {
			gridTemplateColumns: "repeat(4, 1fr)"
		},
		[BP_MEDIA_QUERIES.SECOND]: {
			gridTemplateColumns: "repeat(5, 1fr)"
		},
		[BP_MEDIA_QUERIES.FIRST]: {
			gridTemplateColumns: "repeat(6, 1fr)"
		},
		gridTemplateColumns: "repeat(2, 1fr)",
		gap: "16px",
		overflowX: "auto"
	},
	emptyState: {
		alignItems: "center",
		backgroundColor: "var(--color-purple-50)",
		borderRadius: "16px",
		display: "flex",
		justifyContent: "center",
		minHeight: "400px"
	},
	emptyStateLabel: {
		color: "var(--color-grey-700)"
	}
});
