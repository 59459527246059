import React from "react";
import { Typography } from "components/ui/Typography";
import { useTranslation } from "react-i18next";
import { UserWithEmail } from "../UserWithEmail";
import { useStyles } from "./styles";
import type { UserModel } from "models/UserModel";

export const InheritOwnerOption: FC<{ user: UserModel; emphasis: "text" | "user"; wrap?: boolean }> = ({
	user,
	emphasis,
	wrap = false
}) => {
	const classes = useStyles();
	const { t } = useTranslation();

	const textVariant = emphasis === "user" ? "small" : "standard";
	return (
		<div className={classes.inheritOwnerOption}>
			<Typography variant={textVariant}>{t("shared.ownerInherit")}</Typography>
			<UserWithEmail user={user} wrap={wrap} />
		</div>
	);
};
