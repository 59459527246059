import {
	getUserEntitlements,
	getUserEntitlementsAccounts,
	getUserEntitlementsIntegrations,
	getUserEntitlementsResourceTypes,
	getUserEntitlementsResources,
	getUserEntitlementsRoles
} from "api/userEntitlements";
import { useFetchedState } from "hooks/useFetchedState";
import { useLoadingState } from "hooks/useLoadingState";
import { List } from "immutable";
import { UserEntitlementModel } from "models/UserEntitlementModel";
import { useCallback, useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";

// Entities hooks

export const useUserEntitlementsIntegrations = () => {
	const {
		data: integrations,
		loadData: loadIntegrations,
		isLoading
	} = useFetchedState(getUserEntitlementsIntegrations);

	useEffect(() => {
		loadIntegrations();
	}, [loadIntegrations]);

	return { integrations, isLoading };
};

export const useUserEntitlementsResources = (
	integrationId: string | null,
	resourceType?: string | null,
	accountId?: string | null
) => {
	const fetchCallback = useCallback(
		async () =>
			integrationId
				? getUserEntitlementsResources(integrationId, resourceType ?? undefined, accountId ?? undefined)
				: null,
		[accountId, integrationId, resourceType]
	);
	const { data: resources, loadData: loadResources, isLoading, setData: setResources } = useFetchedState(fetchCallback);

	useEffect(() => {
		if (integrationId) {
			loadResources();
		} else {
			setResources(null);
		}
	}, [integrationId, loadResources, setResources]);

	return { resources, isLoading };
};

export const useUserEntitlementsResourceTypes = (integrationId: string | null, accountId?: string | null) => {
	const fetchCallback = useCallback(
		async () => (integrationId ? getUserEntitlementsResourceTypes(integrationId, accountId ?? undefined) : null),
		[accountId, integrationId]
	);
	const {
		data: resourceTypes,
		loadData: loadResourceTypes,
		isLoading,
		setData: setResourceTypes
	} = useFetchedState(fetchCallback);

	useEffect(() => {
		if (integrationId) {
			loadResourceTypes();
		} else {
			setResourceTypes(null);
		}
	}, [integrationId, loadResourceTypes, setResourceTypes]);

	return { resourceTypes, isLoading };
};

export const useUserEntitlementsRoles = (resourceId: string | null, accountId?: string | null) => {
	const fetchCallback = useCallback(
		async () => (resourceId ? getUserEntitlementsRoles(resourceId, accountId ?? undefined) : null),
		[accountId, resourceId]
	);
	const { data: roles, loadData: loadRoles, isLoading, setData: setRoles } = useFetchedState(fetchCallback);

	useEffect(() => {
		if (resourceId) {
			loadRoles();
		} else {
			setRoles(null);
		}
	}, [resourceId, loadRoles, setRoles]);

	return { roles, isLoading };
};

export const useUserEntitlementsAccounts = (integrationId: string | null) => {
	const fetchCallback = useCallback(
		async () => (integrationId ? getUserEntitlementsAccounts(integrationId) : null),
		[integrationId]
	);
	const { data: accounts, loadData: loadAccounts, isLoading, setData: setAccounts } = useFetchedState(fetchCallback);

	useEffect(() => {
		if (integrationId) {
			loadAccounts();
		} else {
			setAccounts(null);
		}
	}, [integrationId, loadAccounts, setAccounts]);

	return { accounts, isLoading };
};

// Form hooks

export type TMyPermissionsForm = {
	accountId: string;
	integrationId: string;
	resourceId: string;
	resourceType: string;
	roleId: string;
};

const getFormData = (searchParams: URLSearchParams): TMyPermissionsForm => ({
	accountId: searchParams.get("accountId") || "",
	integrationId: searchParams.get("integrationId") || "",
	resourceId: searchParams.get("resourceId") || "",
	resourceType: searchParams.get("resourceType") || "",
	roleId: searchParams.get("roleId") || ""
});

const usePermissionsForm = () => {
	const [searchParams, setSearchParams] = useSearchParams();
	const searchParamsRef = useRef(searchParams);

	const [form, setForm] = useState<TMyPermissionsForm>(getFormData(searchParams));

	const setFormValue = useCallback(
		(key: keyof TMyPermissionsForm, value: string | undefined) => {
			setForm(prevForm => ({
				...prevForm,
				[key]: value
			}));
			const newSearchParams = new URLSearchParams(searchParamsRef.current);
			if (value && value.length) {
				newSearchParams.set(key, value);
			} else {
				newSearchParams.delete(key);
			}
			if (newSearchParams.toString() !== searchParamsRef.current.toString()) {
				setSearchParams(newSearchParams);
				searchParamsRef.current = newSearchParams;
			}
		},
		[setSearchParams]
	);
	return { form, setFormValue };
};

export const useUserPermissions = () => {
	const { form, setFormValue } = usePermissionsForm();
	const [permissions, setPermissions] = useState<List<UserEntitlementModel>>();
	const { withLoader, isLoading } = useLoadingState();

	const sendEntitlementsRequests = useCallback(async () => {
		const data = await getUserEntitlements(form);
		setPermissions(data);
	}, [form]);

	useEffect(() => {
		withLoader(sendEntitlementsRequests());
	}, [sendEntitlementsRequests, withLoader]);

	return { permissions, setFormValue, isLoading, form };
};
