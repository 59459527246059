import React from "react";
import { EntityNode } from "../EntityNode";

type TProps = {
	actions?: React.ReactNode;
	content: React.ReactNode;
	icon?: React.ReactNode;
	onClick?: () => void;
	selected?: boolean;
};

const BORDER_RADIUS = 12;

export const AccountNode: FC<TProps> = props => <EntityNode {...props} color="green" borderRadius={BORDER_RADIUS} />;
