import React, { useCallback, useEffect, useMemo, useState } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { PageTemplate } from "components/templates/PageTemplate";
import { useAccessReviewTemplatesContext } from "context/accessReviewTemplatesContext";
import { Typography } from "components/ui/Typography";
import { LinkButton } from "components/ui/Button";
import { AddIcon } from "components/ui/Icons/AddIcon";
import { Table } from "components/ui/Table";
import { useUsers } from "hooks/useUsers";
import { DeleteIcon } from "components/ui/Icons/DeleteIcon";
import { Tooltip } from "components/ui/Tooltip";
import { EditIcon } from "components/ui/Icons/EditIcon";
import { useNavigate } from "react-router-dom";
import { AreYouSureModal } from "components/common/AreYouSureModal";
import useIsOpenState from "hooks/useIsOpenState";
import { Loading } from "components/ui/Loading";
import { GrantedIcon } from "components/ui/Icons/GrantedIcon";
import { CloseIcon } from "components/ui/Icons/CloseIcon";
import { User } from "components/pages/AccessReviewDashboardPage/components/AccessReview/components/User";
import { Breadcrumbs } from "components/common/Breadcrumbs";
import { useStyles } from "./styles";
import type { AccessReviewTemplateModel } from "models/AccessReviewTemplateModel";

interface ITemplatesTableProps {
	accessReviewTemplates: Immutable.List<AccessReviewTemplateModel>;
	headers: string[];
	onDeleteTemplate: (templateId: string) => void;
}

const TemplatesTable: FC<ITemplatesTableProps> = ({ accessReviewTemplates, headers, onDeleteTemplate }) => {
	const { t } = useTranslation();
	const classes = useStyles();
	const navigate = useNavigate();
	const users = useUsers();
	return (
		<div className={classes.templates}>
			<Table outline gridColumns="1.5fr 1.5fr 1.25fr 1fr 1.5fr 1.5fr 1.25fr 1fr 10rem" className={classes.table}>
				<Table.Row>
					{headers.map(title => (
						<Table.Header key={title}>
							<Typography>{title}</Typography>
						</Table.Header>
					))}
				</Table.Row>
				{accessReviewTemplates.map(template => (
					<Table.Row key={template.id}>
						<Table.Cell>
							<Typography>{template.name}</Typography>
						</Table.Cell>
						<Table.Cell className={classes.descriptionCell}>
							<Typography className={classes.description}>
								{template.description || t("common.accessReview.noDescription")}
							</Typography>
						</Table.Cell>
						<Table.Cell>
							<Typography>
								{template.reviewerTypes.map(type => t(`pages.accessReviewTemplates.reviewerTypes.${type}`)).join(", ")}
							</Typography>
						</Table.Cell>
						<Table.Cell>
							<div className={classes.centeredIconContainer}>
								{template.includeUserless ? (
									<GrantedIcon color="var(--color-green)" />
								) : (
									<CloseIcon color="var(--color-red)" />
								)}
							</div>
						</Table.Cell>
						<Table.Cell>
							{t(`pages.accessReviewTemplates.revoke.${template.immediateRevoke ? "immediate" : "regular"}`)}
						</Table.Cell>
						<Table.Cell>
							<User user={users?.get(template.creatorId)} />
						</Table.Cell>
						<Table.Cell>
							<Typography>
								{t("dateTime.date", {
									date: template.createdAt
								})}
							</Typography>
						</Table.Cell>
						<Table.Cell>
							<Typography>{template.accessReviewsCount}</Typography>
						</Table.Cell>
						<Table.Cell className={classes.actions}>
							<Tooltip content={t("buttons.edit")}>
								<EditIcon className={classes.action} onClick={() => navigate(`edit/${template.id}`)} />
							</Tooltip>
							<Tooltip content={t("buttons.delete")}>
								<DeleteIcon className={classes.action} onClick={() => onDeleteTemplate(template.id)} />
							</Tooltip>
						</Table.Cell>
					</Table.Row>
				))}
			</Table>
		</div>
	);
};

export const AccessReviewTemplatesPage: FC = ({ className }) => {
	const { t } = useTranslation();
	const classes = useStyles();
	const areYouSureModal = useIsOpenState();
	const [templateIdToDelete, setTemplateIdToDelete] = useState<string | null>(null);

	const {
		state: { accessReviewTemplates },
		actions: { loadAccessReviewTemplates, deleteAccessReviewTemplate }
	} = useAccessReviewTemplatesContext();

	const [isTemplatesLoading, setIsTemplatesLoading] = useState(false);

	useEffect(() => {
		if (!accessReviewTemplates) {
			setIsTemplatesLoading(true);
			loadAccessReviewTemplates().finally(() => setIsTemplatesLoading(false));
		}
	}, [accessReviewTemplates, loadAccessReviewTemplates]);

	const headers: string[] = [
		t("pages.accessReviewTemplates.templatesTable.template"),
		t("common.accessReview.description"),
		t("pages.accessReviewTemplates.reviewerTypes.label"),
		t("pages.accessReviewTemplates.includeUserless"),
		t("pages.accessReviewTemplates.revokeProcess"),
		t("pages.accessReviewTemplates.templatesTable.createdBy"),
		t("pages.accessReviewTemplates.templatesTable.dateCreated"),
		t("pages.accessReviewTemplates.templatesTable.reviewsUsed"),
		""
	];

	const openDeleteTemplateModal = useCallback(
		(templateId: string) => {
			setTemplateIdToDelete(templateId);
			areYouSureModal.open();
		},
		[areYouSureModal]
	);
	const deleteTemplate = useCallback(async () => {
		if (templateIdToDelete) {
			await deleteAccessReviewTemplate(templateIdToDelete);
			setTemplateIdToDelete(null);
			areYouSureModal.close();
		}
	}, [areYouSureModal, deleteAccessReviewTemplate, templateIdToDelete]);

	const breadcrumbs = useMemo(() => {
		return [
			{
				title: t("navigation.accessReview"),
				url: "/accessReview"
			},
			{
				title: t("pages.accessReview.manageTemplates")
			}
		];
	}, [t]);

	return (
		<PageTemplate subPage className={classNames(className)}>
			<AreYouSureModal isOpen={areYouSureModal.isOpen} onClose={areYouSureModal.close} onAction={deleteTemplate} />

			<PageTemplate.Title className={classes.header}>
				<Breadcrumbs parts={breadcrumbs} />
			</PageTemplate.Title>
			<PageTemplate.Content className={classes.content}>
				<div className={classes.headerTitle}>
					<Typography variant="h2">{t("pages.accessReviewTemplates.title")}</Typography>
					<LinkButton size="medium" variant="secondary" to="create" prefix={<AddIcon />}>
						{t("pages.accessReviewTemplates.newTemplate")}
					</LinkButton>
				</div>
				<Loading
					center
					loading={isTemplatesLoading}
					data={{ accessReviewTemplates, headers, onDeleteTemplate: openDeleteTemplateModal }}
					require
					render={TemplatesTable}
				/>
			</PageTemplate.Content>
		</PageTemplate>
	);
};
