import { IntegrationActorModel } from "models/IntegrationActorModel";
import { apiReq } from "utils/apiReq";
import { List } from "immutable";
import { getPaginatedSearchQueryString } from "utils/searchUtils";

export async function getIntegrationActors(
	integrationId: string,
	query?: string
): Promise<List<IntegrationActorModel>> {
	const searchQs = getPaginatedSearchQueryString({ search: { query } });
	const { data } = await apiReq("GET", `/v1/integrations/${integrationId}/actors?${searchQs}`);

	return List(data.map((actor: unknown) => IntegrationActorModel.fromServerData(actor)));
}
