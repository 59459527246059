import constate from "constate";
import { useCallback, useEffect, useState } from "react";
import { logrocketLogError } from "utils/logrocket";

const useGlobalError = () => {
	const [error, setError] = useState<Error | null>(null);
	const [modalOpen, setModalOpen] = useState(false);
	const [retryAction, setRetryAction] = useState<(() => void) | undefined>(undefined);

	const openErrorModal = useCallback((error: Error, retryAction?: () => void) => {
		setError(error);
		setRetryAction(() => retryAction);
		setModalOpen(true);
	}, []);

	const closeErrorModal = useCallback(() => {
		setModalOpen(false);
	}, []);

	useEffect(() => {
		if (!error) return;
		logrocketLogError(error, { location: "GlobalError" });
	}, [error]);

	return { state: { error, modalOpen }, actions: { openErrorModal, closeErrorModal, retryAction } };
};

export const [GlobalErrorProvider, useGlobalErrorContext] = constate(useGlobalError);
