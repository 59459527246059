import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import sortBy from "lodash/sortBy";
import { Typography } from "components/ui/New/Typography";
import { Select, TSelectVariant } from "components/ui/Select";
import { UserCard } from "components/common/UserCard";
import { useUsers } from "hooks/useUsers";
import { useStyles } from "./styles";
import type { UserModel } from "models/UserModel";

const getLabel = (user: UserModel) => user.fullName;
const equality = (option?: UserModel, value?: UserModel) => option?.id === value?.id;
const renderOption = (option: UserModel) => <UserCard user={option} />;
const renderLabel = (option: UserModel) => <Typography variant="text_title_sb">{option.fullName}</Typography>;
const sortOptions = (options: UserModel[]) => sortBy(options, getLabel);

const TRANSLATION_PREFIX = "common.userSelectInput";

export interface IUserSelectProps {
	variant?: TSelectVariant;
	onChange: (value: UserModel | null) => void;
	value: UserModel | null;
	options?: UserModel[];
	placeholder?: string;
	label?: React.ReactNode;
	renderLabel?: (option: UserModel) => React.ReactNode;
	fullWidth?: boolean;
	loading?: boolean;
	slimPadding?: boolean;
	noBackground?: boolean;
}

export const UserSelect: FC<IUserSelectProps> = ({
	value,
	onChange,
	className,
	innerRef,
	options: propOptions,
	renderLabel: propRenderLabel,
	loading,
	slimPadding,
	label,
	noBackground,
	placeholder: propsPlaceholder,
	fullWidth,
	variant
}) => {
	const classes = useStyles();
	const { t } = useTranslation();
	const users = useUsers(false);

	const options = useMemo(() => {
		if (propOptions) return propOptions;
		return users
			? users
					.toList()
					.filter(user => user.fullName.length)
					.toArray()
			: [];
	}, [propOptions, users]);

	const handleValueChange = useCallback(
		(user: UserModel | null) => {
			onChange(user);
		},
		[onChange]
	);

	const placeholder = propsPlaceholder ?? t(`${TRANSLATION_PREFIX}.placeholder`);

	return (
		<div className={classNames(classes.container, className)} ref={innerRef}>
			<Select
				fullWidth={fullWidth}
				variant={variant}
				slimPadding={slimPadding}
				getOptionLabel={getLabel}
				isOptionEqualToValue={equality}
				label={label}
				loading={loading}
				options={options}
				placeholder={placeholder}
				renderLabel={propRenderLabel ?? renderLabel}
				renderOption={renderOption}
				sort={sortOptions}
				value={value}
				noBackground={noBackground}
				onChange={handleValueChange}
			/>
		</div>
	);
};
