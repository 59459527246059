import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	requestsLogPage: {},
	headerContainer: {
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
		paddingRight: "2.25rem"
	},
	tableContainer: {
		borderRadius: "var(--border-radius-sharp)",
		backgroundColor: "var(--surface-block-background-color)",
		overflow: "hidden",
		height: "100%"
	},
	listActions: {
		display: "inline-flex",
		flexDirection: "row",
		gap: "0.25rem"
	}
});
