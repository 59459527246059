import React, { useMemo } from "react";
import classNames from "classnames";
import { Map } from "immutable";
import { useTranslation } from "react-i18next";

import { UserCard } from "components/common/UserCard";
import { Chip } from "components/ui/Chip";
import { GrantedIcon } from "components/ui/Icons/GrantedIcon";
import { MaintainerCard } from "./MaintainersCard";
import { OwnerCard } from "./OwnersCard";
import { DirectoryGroupCard, GroupCard, OnCallGroupCard } from "./GroupCard";
import { ApproverWebhookChip } from "./ApproverWebhookChip";

import { useStyles } from "./styles";

import type { TicketApprovalEntityModel } from "models/TicketApprovalEntityModel";
import type { TTicketApprovalResponseType } from "models/TicketApprovalResponseModel";
import type { TEntityNodeColor } from "components/common/Nodes/EntityNode";
import type { TFullApprovalRequest } from "components/common/RequestDetails/types";

interface IProps {
	responsesByUserId: Map<string, TTicketApprovalResponseType>;
	entity: TicketApprovalEntityModel;
	request?: TFullApprovalRequest;
}

export const ApprovalStepEntity: FC<IProps> = ({ entity, responsesByUserId, request }) => {
	const classes = useStyles();
	const { t } = useTranslation("translation", { keyPrefix: "common.requestDetails.approvalProcess.step" });
	const approverIds = useMemo(() => entity.approverIds, [entity]);

	const { responderIds, status, disabled } = useMemo(() => {
		const responderIds = approverIds.filter(id => responsesByUserId.has(id));
		const responses = responderIds.size ? responderIds.map(id => responsesByUserId.get(id)!) : undefined;

		if (!responses?.size)
			return { status: "pending" as const, disabled: request?.status === "approved" && request?.operator === "or" };

		if (responses.includes("decline") || responses.includes("adminDecline"))
			return { status: "declined" as const, responderIds, disabled: false };
		return { status: "approved" as const, responderIds, disabled: false };
	}, [approverIds, request?.operator, request?.status, responsesByUserId]);

	const cardClass = classNames(classes.cardContainer, { [classes.disabled]: disabled });
	let color: TEntityNodeColor = "purple";
	const userId = entity.approverIds.get(0) ?? "";

	switch (entity.type) {
		case "Automatic":
			return (
				<Chip className={classes.groupTooltipChip} suffixIcon={<GrantedIcon />}>
					{t("automaticApproval")}
				</Chip>
			);
		case "User":
		case "DirectManager":
			if (status === "approved") color = "green";
			if (status === "declined") color = "red";
			return <UserCard user={userId} className={cardClass} color={color} selected={responderIds?.includes(userId)} />;
		case "DirectoryGroup":
			return (
				<DirectoryGroupCard
					status={status}
					className={cardClass}
					id={entity.identifier!}
					responderIds={responderIds}
					name={entity.displayName}
					approverIds={approverIds}
				/>
			);
		case "OnCallIntegrationSchedule":
			return (
				<OnCallGroupCard
					status={status}
					className={cardClass}
					id={entity.identifier!}
					responderIds={responderIds}
					name={entity.displayName}
					approverIds={approverIds}
				/>
			);
		case "IntegrationMaintainer":
		case "ResourceMaintainer":
			return (
				<MaintainerCard
					approverIds={approverIds}
					status={status}
					responderIds={responderIds}
					className={cardClass}
					type={entity.type}
				/>
			);
		case "IntegrationOwner":
		case "ResourceOwner":
			return (
				<OwnerCard
					approverIds={approverIds}
					status={status}
					responderIds={responderIds}
					className={cardClass}
					type={entity.type}
				/>
			);
		case "Webhook":
			return entity.approverIds.size ? (
				<GroupCard
					className={cardClass}
					approverIds={approverIds}
					status={status}
					responderIds={responderIds}
					name={entity.displayName}
				/>
			) : (
				<ApproverWebhookChip status={status} displayName={entity.displayName} />
			);
		default:
			return (
				<GroupCard
					className={cardClass}
					approverIds={approverIds}
					status={status}
					responderIds={responderIds}
					name={entity.displayName}
				/>
			);
	}
};
