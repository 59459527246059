import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	approverChip: {
		whiteSpace: "pre-wrap"
	},
	approverStatusContainer: {
		display: "flex",
		flexDirection: "row",
		flexWrap: "wrap",
		gap: "0.5rem"
	},
	approversList: {
		display: "flex",
		flexDirection: "column",
		gap: "0.2rem"
	},
	basicCell: {
		display: "flex",
		justifyContent: "center"
	},
	cell: {
		alignItems: "center",
		display: "flex",
		height: "100%"
	},
	workflowTooltip: {
		"&$workflowTooltip$workflowTooltip": {
			maxWidth: "65rem"
		}
	},
	column: {
		alignItems: "normal",
		display: "flex",
		flexDirection: "column",
		height: "100%",
		justifyContent: "center",
		overflowX: "hidden"
	},
	approvalRequest: {
		alignItems: "normal",
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		"&:not(:last-child)": {
			borderBottom: "1px solid var(--color-grey-light)",
			paddingBottom: "4px"
		}
	},
	approvalRequests: {
		gap: "4px"
	},
	creationDate: {
		alignItems: "start",
		display: "flex",
		flexDirection: "column",
		justifyContent: "center"
	},
	justificationCell: {
		background: "var(--color-purple-5)",
		borderRadius: "var(--border-radius-sharp)",
		color: "var(--color-grey-dark)",
		fontSize: "0.9rem",
		padding: "0.25rem",
		wordBreak: "break-word",
		width: "fit-content"
	},
	requestedBy: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "start",
		alignItems: "start",
		gap: "0.2rem"
	},
	requestedFor: {
		display: "flex",
		gap: "0.5rem",
		overflow: "hidden"
	},
	resource: {
		"&$singleRow": {
			"& > $resourceLogoContainer": {
				alignItems: "center"
			},
			alignItems: "center"
		},
		display: "flex",
		flexDirection: "row",
		gap: "0.5rem"
	},
	resourceLogo: {
		height: "2rem",
		width: "2rem"
	},
	resourceLogoContainer: {
		alignItems: "start",
		display: "flex",
		height: "100%",
		justifyContent: "start"
	},
	singleApprover: {
		alignItems: "center",
		display: "flex",
		flexDirection: "row",
		flexWrap: "wrap",
		gap: "0.25rem"
	},
	singleRow: {},
	statusContainer: {
		alignItems: "center",
		justifyContent: "center",
		display: "flex",
		height: "100%"
	},
	statusIcon: {
		fontSize: "1.5em"
	},
	targetContainer: {
		display: "flex",
		flexDirection: "column",
		wordBreak: "break-word",
		gap: "0.25rem",
		height: "100%",
		justifyContent: "center"
	},
	subText: {
		color: "#5F6066"
	},
	ticketingIntegrationTicketChip: {
		borderTop: "1px solid var(--color-grey-light)"
	},
	thirdPartyTicketDurationCell: {
		flexDirection: "column",
		justifyContent: "space-around",
		alignItems: "start"
	},
	regularDurationCell: {
		margin: "0 auto"
	}
});
