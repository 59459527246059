import { Record, List } from "immutable";
import type { TOnCallType } from "./OnCallIntegrationScheduleModel";
import type { TTicketDuration } from "utils/durationsOptions";
import type { THRType } from "api/companyExternalIntegrations";
import type { TTicketingIntegrationType } from "models/TicketingIntegrationTicketModel";

export type TDayOfTheWeek = 0 | 1 | 2 | 3 | 4 | 5 | 6;
export type TRoutineReminderSchedule = { daysOfTheWeek: TDayOfTheWeek[]; hour: number; minute: number };

type TCompanyServerData = Omit<
	typeof defaults,
	"integratedToHRs" | "integratedToTicketingIntegrations" | "allowedDurations"
> & {
	integratedToHRs: THRType[];
	integratedToTicketingIntegrations: TTicketingIntegrationType[];
	allowedDurations: TTicketDuration[];
};

type TTicketingIntegration = "jira";

const defaults = {
	allowedDurations: List<TTicketDuration>(),
	createdAt: new Date(0),
	enableAccessReviews: false,
	enableUserAttributes: false,
	forceAccessReviewsImmediateRevoke: false,
	id: "",
	integratedToHRs: List<THRType>(),
	integratedToOnCalls: List<TOnCallType>(),
	integratedToSlack: false,
	integratedToTeams: false,
	integratedToTicketingIntegrations: List<TTicketingIntegrationType>(),
	name: "",
	pendingAccessReportsReminderSchedule: null as TRoutineReminderSchedule | null,
	pendingRequestsReminderSchedule: null as TRoutineReminderSchedule | null,
	syncLimitInMinutes: 0,
	ticketingIntegration: null as TTicketingIntegration | null,
	timeZone: null as string | null
};

export class CompanyModel extends Record<typeof defaults>(defaults) {
	static fromServerData(data: TCompanyServerData) {
		return new CompanyModel({
			...data,
			allowedDurations: List(data.allowedDurations),
			createdAt: new Date(data.createdAt),
			integratedToHRs: List(data.integratedToHRs),
			integratedToTicketingIntegrations: List(data.integratedToTicketingIntegrations),
			integratedToOnCalls: List(data.integratedToOnCalls)
		});
	}
}
