import React from "react";
import { UserCard } from "components/common/UserCard";

import { TooltipOnOverflow } from "components/ui/New/TooltipOnOverflow";
import { Chip } from "components/ui/Chip";
import { Typography } from "components/ui/New/Typography";
import { TitleTooltip } from "components/ui/TitleTooltip";
import { useStyles } from "./styles";

import type { List } from "immutable";
import type { TEntityNodeColor } from "components/common/Nodes/EntityNode";

export type TStatus = "approved" | "declined" | "pending";

interface IProps {
	title: React.ReactNode;
	approverIds: List<string>;
	responderIds?: List<string>;
	status: TStatus;
}

export const GroupTooltipContent: FC<IProps> = ({ title, approverIds, responderIds, status, children }) => {
	const classes = useStyles();
	return (
		<TitleTooltip
			className={classes.groupTooltip}
			header={
				<div className={classes.groupTooltipTitle}>
					{typeof title === "string" ? <TooltipOnOverflow textVariant="body_sb" content={title} /> : title}
					<Chip size="tiny">
						<Typography variant="text_tny_reg">{approverIds.size}</Typography>
					</Chip>
				</div>
			}
			body={
				<div className={classes.approversListContainer}>
					{approverIds.map(approverId => {
						const responded = responderIds?.includes(approverId);
						let color: TEntityNodeColor = "purple";
						if (responded) color = status === "approved" ? "green" : "red";

						return (
							<UserCard
								key={approverId}
								user={approverId}
								color={color}
								selected={responded}
								className={classes.cardContainer}
							/>
						);
					})}
				</div>
			}>
			{React.isValidElement(children) ? children : <div>{children}</div>}
		</TitleTooltip>
	);
};
