import React, { useCallback, useMemo, useState } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { TitleBody } from "components/ui/TitleBody";
import { Button } from "components/ui/Button";
import { IconPrefix } from "components/ui/IconPrefix";
import { getIntegrationIcon } from "components/common/IntegrationImage";
import { useNewRequestFormContext } from "components/pages/NewRequestPage/newRequestFormContext";
import { Modal } from "components/ui/Modal";
import { Input } from "components/ui/Input";
import { AccountNode } from "components/common/Nodes/AccountNode";
import { TooltipOnOverflow } from "components/ui/New/TooltipOnOverflow";
import { useStyles } from "./styles";
import type { IntegrationModel } from "models/IntegrationModel";
import type { IntegrationActorModel } from "models/IntegrationActorModel";

type TProps = {
	close: () => void;
	isOpen: boolean;
	multipleActorIntegration: IntegrationModel | null;
};

const Integration: FC<{ integration?: IntegrationModel }> = ({ integration }) => {
	if (!integration) return null;
	return <IconPrefix size="huge" semibold Icon={getIntegrationIcon(integration)} content={integration.name} />;
};

const TRANSLATION_PREFIX = "pages.newRequest.summaryStep.chooseActorModal";

export const ChooseActorModal: FC<TProps> = ({ className, innerRef, multipleActorIntegration, isOpen, close }) => {
	const { t } = useTranslation();
	const classes = useStyles();
	const [selectedIntegrationActor, setSelectedIntegrationActor] = useState<IntegrationActorModel | null>(null);
	const [query, setQuery] = useState("");

	const {
		state: { fullReceiverUser },
		actions: { addReceiverIntegrationActor }
	} = useNewRequestFormContext();

	const setIntegrationActor = useCallback(() => {
		if (!selectedIntegrationActor || !multipleActorIntegration) return;
		addReceiverIntegrationActor(multipleActorIntegration.id, selectedIntegrationActor.id);
		close();
	}, [addReceiverIntegrationActor, close, multipleActorIntegration, selectedIntegrationActor]);

	const currentIntegrationUserActors = useMemo(() => {
		if (!fullReceiverUser || !fullReceiverUser.integrationActors || !multipleActorIntegration) return null;
		return fullReceiverUser.integrationActors
			.filter(actor => actor.integrationId === multipleActorIntegration.id)
			.filter(actor => actor.displayName.toLowerCase().includes(query.toLowerCase()));
	}, [fullReceiverUser, multipleActorIntegration, query]);

	const modalContent = useMemo(() => {
		if (!currentIntegrationUserActors || !multipleActorIntegration) return null;
		return (
			<div className={classes.content}>
				<TitleBody title={t(`${TRANSLATION_PREFIX}.title`)} body={t(`${TRANSLATION_PREFIX}.content`)} />
				<Integration integration={multipleActorIntegration} />
				<div className={classes.inputAndList}>
					<Input value={query} placeholder={t(`${TRANSLATION_PREFIX}.inputPlaceholder`)} onValueChange={setQuery} />
					<div className={classes.actorList}>
						{currentIntegrationUserActors.map(actor => {
							const selectActor = () => setSelectedIntegrationActor(actor);
							return (
								<AccountNode
									key={actor.id}
									content={<TooltipOnOverflow textVariant="text_sm_sb" content={actor.displayName} />}
									selected={selectedIntegrationActor?.id === actor.id}
									onClick={selectActor}
								/>
							);
						})}
					</div>
				</div>
			</div>
		);
	}, [currentIntegrationUserActors, classes, t, multipleActorIntegration, query, selectedIntegrationActor?.id]);

	const modalActions = useMemo(() => {
		return (
			<>
				<Button variant="secondary" size="medium" onClick={close}>
					{t("buttons.cancel")}
				</Button>
				<Button size="medium" onClick={setIntegrationActor} disabled={!selectedIntegrationActor}>
					{t(`${TRANSLATION_PREFIX}.assignAccount`)}
				</Button>
			</>
		);
	}, [close, selectedIntegrationActor, setIntegrationActor, t]);

	if (!modalContent) return null;
	return (
		<Modal
			actions={modalActions}
			className={classNames(classes.modal, className)}
			content={modalContent}
			innerRef={innerRef}
			isOpen={isOpen}
			onClose={close}
		/>
	);
};
