// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./Poppins-Light.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./Poppins-LightItalic.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("./Poppins-Regular.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("./Poppins-Italic.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_4___ = new URL("./Poppins-Medium.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_5___ = new URL("./Poppins-MediumItalic.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_6___ = new URL("./Poppins-SemiBold.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_7___ = new URL("./Poppins-SemiBoldItalic.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_8___ = new URL("./Poppins-Bold.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_9___ = new URL("./Poppins-BoldItalic.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
var ___CSS_LOADER_URL_REPLACEMENT_6___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_6___);
var ___CSS_LOADER_URL_REPLACEMENT_7___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_7___);
var ___CSS_LOADER_URL_REPLACEMENT_8___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_8___);
var ___CSS_LOADER_URL_REPLACEMENT_9___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_9___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
	font-family: Poppins;
	src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format("truetype");
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: Poppins;
	src: url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format("truetype");
	font-weight: 300;
	font-style: italic;
}

@font-face {
	font-family: Poppins;
	src: url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format("truetype");
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: Poppins;
	src: url(${___CSS_LOADER_URL_REPLACEMENT_3___}) format("truetype");
	font-weight: 400;
	font-style: italic;
}

@font-face {
	font-family: Poppins;
	src: url(${___CSS_LOADER_URL_REPLACEMENT_4___}) format("truetype");
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: Poppins;
	src: url(${___CSS_LOADER_URL_REPLACEMENT_5___}) format("truetype");
	font-weight: 500;
	font-style: italic;
}

@font-face {
	font-family: Poppins;
	src: url(${___CSS_LOADER_URL_REPLACEMENT_6___}) format("truetype");
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: Poppins;
	src: url(${___CSS_LOADER_URL_REPLACEMENT_7___}) format("truetype");
	font-weight: 600;
	font-style: italic;
}

@font-face {
	font-family: Poppins;
	src: url(${___CSS_LOADER_URL_REPLACEMENT_8___}) format("truetype");
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: Poppins;
	src: url(${___CSS_LOADER_URL_REPLACEMENT_9___}) format("truetype");
	font-weight: 700;
	font-style: italic;
}
`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
