import React, { useCallback, useMemo, useRef } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { Map, List } from "immutable";
import { useIsOverflowed } from "hooks/useIsOverflowed";
import { IntegrationModel } from "models/IntegrationModel";
import { ResourceCard } from "components/common/ResourceCard";
import { BundleModel } from "models/BundleModel";
import { useStyles } from "./styles";
import type { IntegrationResourceRoleModel } from "models/IntegrationResourceRoleModel";
import type { IntegrationResourceModel } from "models/IntegrationResourceModel";
import type { TCartCardProps, TCartItem, TResourceCartProps } from "../../RequestCart";

type TRoleGrantMethod = { role: IntegrationResourceRoleModel; grantMethodRoleId: string };

type TResourceSelectedRoles = {
	resource: IntegrationResourceModel;
	selectedRoles: Map<string, TRoleGrantMethod>;
};

type TRequestCartItemProps = TResourceSelectedRoles &
	TCartCardProps & {
		onRemoveResource: (resourceId: string) => void;
	};

const RequestCartItem: FC<TRequestCartItemProps> = ({
	resource,
	selectedRoles,
	className,
	innerRef,
	onRemoveResource,
	...resourceCartCardProps
}) => {
	const removeResource = useCallback(() => {
		onRemoveResource(resource.id);
	}, [onRemoveResource, resource.id]);

	return (
		<ResourceCard
			resource={resource}
			selectedRoles={selectedRoles}
			onRemoveResource={removeResource}
			{...resourceCartCardProps}
		/>
	);
};

type TRequestCartItemsProps = Omit<TResourceCartProps, "removeAppFromCart"> & { selectedApp?: string };

export const RequestCartItems: FC<TRequestCartItemsProps> = ({
	cart,
	cartItems,
	className,
	innerRef,
	selectedApp,
	...resourceCartCardProps
}) => {
	const { t } = useTranslation();
	const classes = useStyles();

	const cartItemsContainerRef = useRef<HTMLDivElement>(null);
	const { overflowedY } = useIsOverflowed(cartItemsContainerRef);

	const selectedCartItems: TResourceSelectedRoles[] = useMemo(() => {
		if (!selectedApp || !(cart.get(selectedApp) instanceof IntegrationModel)) return [];
		const rolesToGrantMethods = cartItems.get(selectedApp || "") || List<TCartItem>();
		// resourceId -> roleId -> role, grant method
		return rolesToGrantMethods
			.reduce((acc, item) => {
				const { resource, role, grantMethodRoleId } = item;
				const resourceSelectedRoles = acc.get(resource.id) || {
					resource,
					selectedRoles: Map<string, TRoleGrantMethod>()
				};
				const selectedRoles = resourceSelectedRoles.selectedRoles.set(role.id, {
					role,
					grantMethodRoleId: grantMethodRoleId || ""
				});
				return acc.set(resource.id, { ...resourceSelectedRoles, selectedRoles });
			}, Map<string, TResourceSelectedRoles>())
			.toList()
			.toArray();
	}, [cart, cartItems, selectedApp]);

	const { showBundleMessage, showEmptyCartMessage } = useMemo(() => {
		const showBundleMessage = selectedApp && cart.get(selectedApp) instanceof BundleModel;
		const showEmptyCartMessage = !cart.size;
		return {
			showBundleMessage,
			showEmptyCartMessage
		};
	}, [cart, selectedApp]);

	if (showBundleMessage)
		return <div className={classes.bundlesRoles}>{t("common.requestCart.bundleCartUnsupported")}</div>;
	if (showEmptyCartMessage) return <div className={classes.emptyCart}>{t("common.requestCart.emptyCart")}</div>;
	if (!selectedCartItems.length) return null;
	return (
		<div
			className={classNames(classes.cartItemsContainer, { [classes.scrollable]: overflowedY }, className)}
			ref={innerRef}>
			<div className={classes.cartItems} ref={cartItemsContainerRef}>
				{selectedCartItems.map(({ resource, selectedRoles }) => (
					<RequestCartItem
						key={resource.id}
						resource={resource}
						selectedRoles={selectedRoles}
						{...resourceCartCardProps}
					/>
				))}
			</div>
		</div>
	);
};
