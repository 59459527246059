import React, { useMemo } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { Typography } from "components/ui/New/Typography";
import { RequestedBy } from "components/common/RequestBar/components/RequestedBy";
import { RequestedDuration } from "components/common/RequestBar/components/RequestedDuration";
import { RequestJustification } from "components/common/RequestBar/components/RequestJustification";
import { RequestDetailsStatus } from "./RequestDetailsStatus";
import { useStyles } from "./styles";
import type { TFullTicket } from "components/common/RequestDetails";

export const GeneralInfo: FC<{ ticket: TFullTicket }> = ({ ticket }) => {
	const { t } = useTranslation("translation", { keyPrefix: "common" });
	const classes = useStyles();

	const justification = useMemo(() => {
		const sortedComments = ticket.comments?.sort((a, b) => (a.createdAt > b.createdAt ? 1 : -1));
		return sortedComments?.first()?.content || "";
	}, [ticket.comments]);

	return (
		<div className={classes.generalInfo}>
			<Typography variant="text_reg">{t("requestDetails.generalInfo.title")}</Typography>
			<div className={classes.container}>
				<div className={classNames(classes.column, classes.requesterColumn)}>
					{ticket.receiverId !== ticket.creatorId && (
						<RequestedBy
							className={classNames(classes.requester, classes.gap)}
							requestedBy={ticket.receiverId}
							onBehalf
						/>
					)}
					<RequestedBy requestedBy={ticket.creatorId} className={classNames(classes.requester, classes.gap)} />
				</div>
				<RequestedDuration className={classes.gap} duration={ticket.duration} />
				<RequestJustification
					className={classNames(classes.gap, classes.justificationColumn)}
					textVariant="text_reg"
					justification={justification}
					ticketingIntegrationTicket={ticket.ticketingIntegrationTicket}
				/>
				<div className={classNames(classes.column, classes.statusColumn)}>
					<RequestDetailsStatus ticket={ticket} />
				</div>
			</div>
		</div>
	);
};
