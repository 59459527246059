import { useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { Map, List } from "immutable";
import { useBundles } from "hooks/useBundles";
import { useIntegrationResourceRoles } from "hooks/useIntegrationResourceRoles";
import { toMapBy } from "utils/toMapBy";
import { notEmpty } from "utils/comparison";
import { urlFieldsParser } from "../../../utils/urlFormDataUtils";
import type { TBundleOption, TPrePopulatedFormData, TRoleOption } from "../types";
import type { TTicketDuration } from "utils/durationsOptions";

export const useURLFormData = (): TPrePopulatedFormData | null => {
	const [searchParams] = useSearchParams();

	const bundles = useBundles();
	const urlFormData = useMemo(() => {
		return urlFieldsParser(searchParams);
	}, [searchParams]);

	const uniqueRoleIds = useMemo(
		() =>
			urlFormData?.roles
				? (Array.from(new Set(urlFormData.roles.flatMap(role => [role.id, role.through]) || [])).filter(
						Boolean
					) as string[])
				: [],
		[urlFormData?.roles]
	);

	const roles = useIntegrationResourceRoles(uniqueRoleIds);
	const rolesById = useMemo(() => toMapBy(roles ?? [], role => role.id), [roles]);

	const data = useMemo((): TPrePopulatedFormData | null => {
		if (!roles || !bundles || !urlFormData) return null;

		const bundleOptions = urlFormData.bundleIds
			? (urlFormData.bundleIds
					.map(bundleId => (bundles.has(bundleId) ? { type: "bundle", value: bundles.get(bundleId)! } : null))
					.filter(notEmpty) as TBundleOption[])
			: [];

		const roleOptions = urlFormData.roles
			? (urlFormData.roles
					.map(role =>
						rolesById.has(role.id)
							? {
									type: "role" as const,
									value: rolesById.get(role.id)!,
									through: role.through ? rolesById.get(role.through) : undefined
								}
							: null
					)
					.filter(notEmpty) as TRoleOption[])
			: [];

		const grantMethods = Map(
			urlFormData.roles?.map(({ id, through }) => [id, through ? rolesById.get(through) ?? null : null])
		);

		return {
			duration: urlFormData.duration as TTicketDuration,
			justification: urlFormData.justification,
			ticketingIntegrationTicketId: urlFormData.ticketingIntegrationTicketId,
			targets: List([...roleOptions, ...bundleOptions]),
			grantMethods
		};
	}, [bundles, roles, rolesById, urlFormData]);

	return data;
};
