import React, { useCallback, useEffect, useState } from "react";
import { app as TeamsApp, authentication as TeamsAuthentication } from "@microsoft/teams-js";
import { Typography } from "components/ui/Typography";
import { LoadingSpinner } from "components/ui/LoadingSpinner";
import { useTranslation } from "react-i18next";
import { signInWithTeams } from "api/signIn";
import { useLoadingState } from "hooks/useLoadingState";
import { useAuthContext } from "context/authContext";
import { useUser } from "hooks/useUser";
import { useStyles } from "./styles";

export const TeamsAuthWall: FC = ({ children }) => {
	const { isLoggedIn, signIn } = useAuthContext();
	const [loaded, setLoaded] = useState(false);
	const { isLoading, withLoader } = useLoadingState();
	const [inTeams, setInTeams] = useState(false);
	const { user } = useUser();
	const { t } = useTranslation();
	const classes = useStyles();

	const init = useCallback(async () => {
		try {
			await TeamsApp.initialize();
			await TeamsApp.getContext();
			setInTeams(true);
			const teamsToken = await TeamsAuthentication.getAuthToken({ resources: [], silent: true });
			const token = await signInWithTeams(teamsToken);
			if (token) signIn(token);
		} catch (e) {
			TeamsApp.notifyFailure({ message: "Failed to authorize", reason: TeamsApp.FailedReason.AuthFailed });
			setInTeams(false);
		} finally {
			setLoaded(true);
		}
	}, [signIn, setInTeams]);

	useEffect(() => {
		if (!loaded && !isLoading) {
			withLoader(init());
		}
	}, [loaded, init, isLoading, withLoader]);

	if (loaded && !inTeams) {
		return (
			<div className={classes.teamsLayout}>
				<Typography>{t("errors.request.unauthorized")}</Typography>
			</div>
		);
	}

	if (!isLoggedIn || !user) {
		return (
			<div className={classes.teamsLayout}>
				<LoadingSpinner />
			</div>
		);
	}

	return <>{children}</>;
};
