import { SMALL_CARD_FIXED_WIDTH } from "components/common/Cards/Card/styles";
import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	arrow: {
		alignItems: "center",
		background: "var(--color-grey-200)",
		display: "inline-flex",
		flexShrink: 0,
		gap: "var(--spacing-x1)",
		height: "152px",
		justifyContent: "center",
		paddingBottom: "var(--spacing-x4)",
		width: "36px",
		"&:hover": {
			background: "var(--color-grey-400)"
		}
	},
	arrowContainer: {
		background: "var(--color-white)",
		position: "absolute",
		opacity: 0,
		transition: "opacity 0.2s ease-in-out",
		willChange: "opacity"
	},
	arrowContainerLeft: {
		extend: "arrowContainer",
		left: 0
	},
	arrowContainerRight: {
		extend: "arrowContainer",
		right: 0
	},
	arrowLeft: {
		extend: "arrow",
		borderRadius: "var(--spacing-x2) 0 0 var(--spacing-x2)",
		boxShadow: "4px 0px 2px 0px rgba(0, 0, 0, 0.13)"
	},
	arrowRight: {
		extend: "arrow",
		borderRadius: "0 var(--spacing-x2) var(--spacing-x2) 0",
		boxShadow: "-4px 0px 2px 0px rgba(0, 0, 0, 0.13)"
	},
	expandHeader: {
		height: "100%"
	},
	hasSelected: {},
	headers: {
		alignItems: "start",
		display: "grid",
		gap: "var(--spacing-x4)",
		gridTemplateColumns: `repeat(auto-fill, ${SMALL_CARD_FIXED_WIDTH}px)`,
		height: "152px",
		msOverflowStyle: "none",
		overflowX: "auto",
		scrollbarWidth: "none",
		width: "100%",
		"&::-webkit-scrollbar": {
			display: "none"
		},
		"& > *": {
			gridRow: 1
		}
	},
	headersContainer: {
		position: "relative",
		width: "100%",
		"&:not($hasSelected)": {
			"&:hover > $arrowContainerLeft, &:hover > $arrowContainerRight": {
				opacity: 1
			}
		}
	}
});
