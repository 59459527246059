import classNames from "classnames";
import { Typography } from "components/ui/Typography";
import React from "react";
import { Link } from "../Link";
import { useStyles } from "./styles";

interface IProps {
	ticketId: string;
	ticketNumber: number;
	bold?: boolean;
}
export const TicketNumberWithLink: FC<IProps> = ({ bold = false, ticketId, ticketNumber, className }) => {
	const classes = useStyles();

	return (
		<div className={classNames(classes.content, className)}>
			<Link noDecoration to={`?ticketId=${ticketId}`}>
				<Typography relative component="span" className={classNames(classes.ticketNumber, { [classes.bold]: bold })}>
					#{ticketNumber}
				</Typography>
			</Link>
		</div>
	);
};
