import classNames from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";
import { Chip } from "components/ui/Chip";
import { useStyles } from "./styles";

export const OperatorBlock: FC<{ operator: TOperator }> = ({ operator, className }) => {
	const { t } = useTranslation();
	const classes = useStyles();
	return (
		<Chip className={classNames(classes.operatorBlock, className)} size="small">
			{t(`flow.${operator}`)}
		</Chip>
	);
};
