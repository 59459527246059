import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Section } from "components/ui/Section";
import { VirtualRequestList } from "components/common/RequestList";
import { Typography } from "components/ui/New/Typography";
import { Chip } from "components/ui/Chip";
import { LoadingDots } from "components/ui/LoadingDots";
import { RequestBar } from "components/common/RequestBar";
import { getUserTickets } from "api/user";
import { cancelTicket, getTicket } from "api/tickets";
import { useTicketUpdatesContext } from "context/ticketUpdatesContext";
import { TicketModel } from "models/TicketModel";
import { DESC, usePagination } from "hooks/usePagination";
import { Skeleton } from "components/ui/Skeleton";
import { REQUEST_BAR_HEIGHT } from "components/common/RequestBar/styles";
import random from "lodash/random";

const LIST_SIZE = 10;
const SHOWN_SIZE = 3;

const SORT_FIELDS = ["ticketNumber"];
const SEARCH_BODY = { status: ["waitingForApproval", "waitingForIT", "permissionInProgress"] };

export const PendingRequestsSection: FC = ({ className, innerRef }) => {
	const { t } = useTranslation();
	const { itemsForVirtualTable, isLoading, totalResults, getPage } = usePagination(
		getUserTickets,
		LIST_SIZE,
		DESC,
		SORT_FIELDS,
		undefined,
		undefined,
		SEARCH_BODY
	);
	const { notifyTicketUpdate } = useTicketUpdatesContext();
	const [filteredTickets, setFilteredTickets] = useState<string[]>([]);

	const requests = useMemo(() => {
		if (!itemsForVirtualTable) return [];
		return itemsForVirtualTable.filter(request => !filteredTickets.includes(request?.id || ""));
	}, [filteredTickets, itemsForVirtualTable]);

	const onCancel = useCallback(
		async (requestId: string) => {
			await cancelTicket(requestId);
			const updatedRequest = await getTicket(requestId);
			notifyTicketUpdate(updatedRequest);
			setFilteredTickets(prev => [...prev, requestId]);
		},
		[notifyTicketUpdate]
	);

	const title = useMemo(() => {
		const pendingRequestsTextTitle = t("pages.requests.pendingRequestsSection.title");
		return (
			<>
				<Typography variant="body_sb">{pendingRequestsTextTitle}</Typography>
				<Chip size="tiny">{totalResults - filteredTickets.length}</Chip>
			</>
		);
	}, [t, totalResults, filteredTickets.length]);

	const renderRequest = useCallback(
		(request?: TicketModel) => {
			if (!request) return <Skeleton key={`loading-${random(true)}`} height={REQUEST_BAR_HEIGHT} />;
			return (
				<RequestBar key={request.id} onCancel={onCancel} request={request} type="userPending" openSidebarOnClick />
			);
		},
		[onCancel]
	);

	if (totalResults === 0) {
		return isLoading ? <LoadingDots center /> : null;
	}

	return (
		<Section className={className} innerRef={innerRef} title={title}>
			<VirtualRequestList
				fetchPage={getPage}
				perPage={LIST_SIZE}
				requestRenderer={renderRequest}
				requests={requests}
				totalRequests={totalResults}
				shownSize={SHOWN_SIZE}
			/>
		</Section>
	);
};
