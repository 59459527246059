import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { List, Set } from "immutable";
import { IntegrationResourceRoleModel } from "models/IntegrationResourceRoleModel";
import { IsNullError } from "utils/errors/isNullError";
import { useIntegrations } from "hooks/useIntegrations";
import { notEmpty } from "utils/comparison";
import { IntegrationModel } from "models/IntegrationModel";
import useIsOpenState from "hooks/useIsOpenState";
import { getMissingActorIntegrationIds, createNewTicket } from "api/tickets";
import { useLoadingState } from "hooks/useLoadingState";
import { escapeJson } from "utils/formatStrings";
import { useNewRequestFormContext } from "../../newRequestFormContext";
import { useNewRequestGrantMethods } from "../../newRequestDataContext";
import type ApiError from "utils/errors/apiError";

const SECOND_IN_MS = 1000;

const getIntegrationId = (role: IntegrationResourceRoleModel) => {
	const virtualizedRole = role.virtualizedRole;
	if ((virtualizedRole && !virtualizedRole.integrationResource) || !role.integrationResource) {
		throw IsNullError.from({
			location: "NewRequestPage.SummaryStep.getIntegrationId",
			parentObject: {
				name: "role",
				value: (virtualizedRole ?? role).toJS()
			},
			requestedProperty: "integrationResource"
		});
	}
	return virtualizedRole ? virtualizedRole.integrationResource!.integrationId : role.integrationResource!.integrationId;
};

const useTargetIntegrations = () => {
	const {
		state: { requestTargets, receiverUser }
	} = useNewRequestFormContext();

	const integrations = useIntegrations();
	const { data: grantMethodsData, fetch } = useNewRequestGrantMethods();

	const targetToIntegrations = useMemo(() => {
		if (!integrations)
			return List<{
				targetId: string;
				integrations: List<IntegrationModel>;
			}>();
		const targetToIntegrationIds = requestTargets
			.map(target => {
				if (target.type === "role") {
					const roleToCheck =
						target.grantMethodId === null || target.grantMethodId === target.id
							? target.fullTarget
							: grantMethodsData.get(target.id)?.grantMethods.get(target.grantMethodId);
					if (!roleToCheck) {
						if (!receiverUser) return null;
						fetch({ roleId: target.id, userId: receiverUser.id });
						return null;
					}
					return { targetId: target.id, integrationIds: Set([getIntegrationId(roleToCheck)]) };
				} else {
					return {
						targetId: target.id,
						integrationIds: target.fullTarget.bundleItems
							.map(bundleItem => getIntegrationId(bundleItem.integrationResourceRole))
							.toSet()
					};
				}
			})
			.filter(notEmpty);
		return targetToIntegrationIds.map(({ targetId, integrationIds }) => ({
			targetId,
			integrations: integrationIds
				.map(id => integrations.get(id))
				.filter(notEmpty)
				.toList()
		}));
	}, [fetch, grantMethodsData, integrations, receiverUser, requestTargets]);

	const targetIntegrations = useMemo(() => {
		const { targetIntegrations } = targetToIntegrations.reduce(
			(acc, { integrations }) => {
				if (integrations.size === 0) return acc;
				integrations.forEach(integration => {
					if (acc.addedIntegrations.has(integration.id)) return;
					acc.addedIntegrations = acc.addedIntegrations.add(integration.id);
					acc.targetIntegrations = acc.targetIntegrations.push(integration);
				});
				return acc;
			},
			{ addedIntegrations: Set<string>(), targetIntegrations: List<IntegrationModel>() }
		);
		return targetIntegrations;
	}, [targetToIntegrations]);

	return { targetIntegrations, targetToIntegrations };
};

const useNewRequestIntegrationActors = () => {
	const {
		state: { fullReceiverUser, receiverIntegrationActors, requestTargets },
		actions: { addReceiverIntegrationActor }
	} = useNewRequestFormContext();
	const integrations = useIntegrations();

	const { targetIntegrations, targetToIntegrations } = useTargetIntegrations();

	const [apiMissingIntegrationActors, setApiMissingIntegrationActors] = useState(List<IntegrationModel>());

	useEffect(() => {
		const getApiMissingIntegrationActors = async () => {
			if (!fullReceiverUser || requestTargets.size === 0 || !integrations) return;
			const missingActors = await getMissingActorIntegrationIds(
				requestTargets
					.map(target => ({
						id: target.type === "role" ? target.grantMethodId || target.id : target.id,
						type: target.type
					}))
					.toArray(),
				fullReceiverUser.id
			);
			setApiMissingIntegrationActors(
				List(missingActors.map(integrationId => integrations.get(integrationId)).filter(notEmpty))
			);
		};

		getApiMissingIntegrationActors();
	}, [fullReceiverUser, integrations, requestTargets]);

	const { missingActorsIntegrations, multipleActorsIntegrations } = useMemo(() => {
		if (!fullReceiverUser || targetIntegrations.size === 0)
			return { missingActorsIntegrations: null, multipleActorsIntegrations: null };
		return targetIntegrations.reduce(
			(acc, integration) => {
				if (receiverIntegrationActors.has(integration.id)) return acc;
				const integrationActors = fullReceiverUser.integrationActors!.filter(
					actor => actor.integrationId === integration.id
				);
				if (integrationActors.size === 1) {
					addReceiverIntegrationActor(integration.id, integrationActors.first()!.id);
				} else if (integrationActors.size === 0 && !integration.canCreateActors) {
					if (
						!acc.missingActorsIntegrations.find(
							missingActorIntegration => missingActorIntegration.id === integration.id
						)
					) {
						acc.missingActorsIntegrations = acc.missingActorsIntegrations.push(integration);
					}
				} else if (integrationActors.size > 1) {
					if (
						!acc.multipleActorsIntegrations.find(
							multipleActorIntegration => multipleActorIntegration.id === integration.id
						)
					)
						acc.multipleActorsIntegrations = acc.multipleActorsIntegrations.push(integration);
				}
				return acc;
			},
			{ missingActorsIntegrations: apiMissingIntegrationActors, multipleActorsIntegrations: List<IntegrationModel>() }
		);
	}, [
		addReceiverIntegrationActor,
		apiMissingIntegrationActors,
		fullReceiverUser,
		receiverIntegrationActors,
		targetIntegrations
	]);

	const isIntegrationActorsValid = useCallback(() => {
		if (
			!fullReceiverUser ||
			targetIntegrations.size === 0 ||
			!missingActorsIntegrations ||
			missingActorsIntegrations.size > 0 ||
			!multipleActorsIntegrations ||
			multipleActorsIntegrations.size > 0
		) {
			return false;
		}
		return true;
	}, [fullReceiverUser, missingActorsIntegrations, multipleActorsIntegrations, targetIntegrations.size]);

	const multipleActorsTargets = useMemo(() => {
		if (!multipleActorsIntegrations) return Set<string>();
		return multipleActorsIntegrations
			.map(
				integration =>
					targetToIntegrations.find(
						target => !!target.integrations.find(targetIntegration => targetIntegration.id === integration.id)
					)?.targetId
			)
			.filter(notEmpty)
			.toSet();
	}, [multipleActorsIntegrations, targetToIntegrations]);

	// This will return the first integration id that has multiple actors
	const getMultipleIntegrationIdByTarget = useCallback(
		(targetId: string) => {
			const integrations = targetToIntegrations.find(target => target.targetId === targetId)?.integrations;
			if (!integrations) return null;
			return (
				integrations.find(
					integration =>
						!!multipleActorsIntegrations?.find(
							multipleActorIntegration => multipleActorIntegration.id === integration.id
						)
				)?.id || null
			);
		},
		[multipleActorsIntegrations, targetToIntegrations]
	);

	return {
		isIntegrationActorsValid,
		missingActorsIntegrations,
		multipleActorsIntegrations,
		multipleActorsTargets,
		getMultipleIntegrationIdByTarget
	};
};

/* 
	This hook is for managing the state of the choose actor modals
 	There could be multiple, the first time they open should be after onSubmit click for the wizard
	When the first modal is closed, the next one should open, and so on
	If the actors problem is not dealt with, the modals should be opened manually by clicking on the relevant request target
*/
const useChooseActorModals = (
	multipleActorsIntegrations: List<IntegrationModel> | null,
	getTargetIntegrationId: (targetId: string) => string | null
) => {
	const chooseActorModal = useIsOpenState();
	const [shownModals, setShownModals] = useState(Set<string>());
	const passedFirstShowingRef = useRef(false);

	const [modalToShow, setModalToShow] = useState<string | null>(null);

	const showNext = useCallback(() => {
		chooseActorModal.close();
		const nextModal = multipleActorsIntegrations?.find(integration => !shownModals.has(integration.id));
		if (nextModal && !passedFirstShowingRef.current) {
			setModalToShow(nextModal.id);
			setTimeout(() => {
				setShownModals(current => current.add(nextModal.id));
				chooseActorModal.open();
			}, SECOND_IN_MS);
		} else {
			passedFirstShowingRef.current = true;
		}
	}, [chooseActorModal, multipleActorsIntegrations, shownModals]);

	const open = useCallback(
		(id?: string) => {
			if (id) {
				if (multipleActorsIntegrations?.find(integration => integration.id === id)) {
					setModalToShow(id);
					chooseActorModal.open();
				} else {
					const integrationId = getTargetIntegrationId(id);
					if (!integrationId || !multipleActorsIntegrations?.find(integration => integration.id === integrationId)) {
						return;
					}
					setModalToShow(integrationId);
					chooseActorModal.open();
				}
			} else {
				showNext();
			}
		},
		[chooseActorModal, getTargetIntegrationId, multipleActorsIntegrations, showNext]
	);

	return useMemo(
		() => ({ open, modalToShow, onClose: showNext, isOpen: chooseActorModal.isOpen, passedFirstShowingRef }),
		[chooseActorModal.isOpen, modalToShow, open, showNext]
	);
};

export const useHandleNewRequestIntegrationActors = () => {
	const [actorModalsShown, setActorModalsShown] = useState(false);

	const {
		isIntegrationActorsValid,
		missingActorsIntegrations,
		multipleActorsIntegrations,
		multipleActorsTargets,
		getMultipleIntegrationIdByTarget
	} = useNewRequestIntegrationActors();
	const intervalRef = useRef<number>();

	const missingActorsModal = useIsOpenState();
	const chooseActorModal = useChooseActorModals(multipleActorsIntegrations, getMultipleIntegrationIdByTarget);

	const delayedOpenMissingActorModal = useCallback(() => {
		if (chooseActorModal.passedFirstShowingRef.current) {
			clearInterval(intervalRef.current);
			intervalRef.current = undefined;
			missingActorsModal.open();
			setActorModalsShown(true);
		}
	}, [chooseActorModal, missingActorsModal]);

	const showMissingAndChoiceModals = useCallback(() => {
		if (multipleActorsIntegrations) {
			chooseActorModal.open();
			if (missingActorsIntegrations?.size) {
				intervalRef.current = window.setInterval(delayedOpenMissingActorModal, SECOND_IN_MS);
			} else {
				setActorModalsShown(true);
			}
		} else if (missingActorsIntegrations) {
			missingActorsModal.open();
			setActorModalsShown(true);
		}
	}, [
		chooseActorModal,
		delayedOpenMissingActorModal,
		missingActorsIntegrations,
		missingActorsModal,
		multipleActorsIntegrations
	]);

	return {
		actorModalsShown,
		chooseActorModal,
		isIntegrationActorsValid,
		missingActorsIntegrations,
		missingActorsModal,
		multipleActorsIntegrations,
		multipleActorsTargets,
		showMissingAndChoiceModals
	};
};

export const useNewRequestSubmit = () => {
	const { isLoading: submitIsLoading, withLoader: withSubmitLoader } = useLoadingState();
	const [error, setError] = useState<"ACTOR_ERROR" | "UNKNOWN_ERROR" | undefined>();
	const [success, setSuccess] = useState(false);

	const {
		state: {
			isFormValid,
			duration,
			justification,
			receiverIntegrationActors,
			receiverUser,
			requestTargets,
			ticketingIntegrationTicketId
		}
	} = useNewRequestFormContext();

	const onSubmit = useCallback(async () => {
		if (!isFormValid) return;
		setSuccess(false);
		setError(undefined);
		try {
			const normalizedTargets = requestTargets
				.map(target => {
					return {
						type: target.type,
						id: target.type === "role" ? target.grantMethodId || target.id : target.id
					};
				})
				.toArray();
			await withSubmitLoader(
				createNewTicket({
					comment: escapeJson(justification),
					ticketingIntegrationTicketId: ticketingIntegrationTicketId ?? undefined,
					// isFormValid validates that the following are not empty
					duration: duration!,
					receiverId: receiverUser!.id,
					receiverIntegrationActorIds: receiverIntegrationActors.toJS(),
					targets: normalizedTargets
				})
			);
			setSuccess(true);
		} catch (error) {
			const apiError = error as ApiError;
			if (
				apiError.errorId &&
				apiError.params &&
				apiError.errorId === "user.actor.notFound" &&
				apiError.params.integrationId
			) {
				setError("ACTOR_ERROR");
			} else {
				setError("UNKNOWN_ERROR");
			}
		}
	}, [
		duration,
		isFormValid,
		justification,
		receiverIntegrationActors,
		receiverUser,
		requestTargets,
		ticketingIntegrationTicketId,
		withSubmitLoader
	]);

	return { error, onSubmit, submitIsLoading, success };
};
