import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	baseCard: {
		alignItems: "center",
		backgroundColor: "var(--color-white)",
		border: "3px solid var(--color-grey-400)",
		borderRadius: "12px",
		display: "flex",
		justifyContent: "center",
		minWidth: 0,
		overflow: "hidden",
		"&$disabled": {
			color: "var(--color-grey-500)",
			pointerEvents: "none"
		},
		"&:hover": {
			backgroundColor: "var(--color-blue-100)"
		},
		"&$selected": {
			borderColor: "var(--color-blue-500)",
			borderWidth: "4px"
		},
		"&$large": {
			height: "68px",
			padding: "var(--spacing-x2, 8px) var(--spacing-x6, 24px)"
		},
		"&$medium": {
			height: "52px",
			padding: "var(--Spacing-spacing-x2, 8px) var(--Spacing-spacing-x5, 20px)"
		}
	},
	large: {},
	medium: {},
	selected: {},
	disabled: {}
});
