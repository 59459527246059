import React, { useCallback, useEffect, useMemo, useState } from "react";
import { addTicketingIntegration, getMergeLinkToken } from "api/companyExternalIntegrations";
import { useMergeLink } from "@mergeapi/react-merge-link";
import { useTranslation } from "react-i18next";
import { List } from "immutable";
import { useCompanyContext } from "context/companyContext";
import { useLoadingState } from "hooks/useLoadingState";
import { useWithGlobalErrorHandler } from "hooks/useWithGlobalErrorHandler";
import { IntegrationRow } from "components/pages/SettingsPage/components/Integrations/components/IntegrationRow";
import { getTicketingIntegrationLogoByType } from "utils/ticketingIntegrationLogos";
import { useOpenGlobalErrorModal } from "hooks/useGlobalError";
import { Button } from "components/ui/Button";
import type { TTicketingIntegrationType } from "models/TicketingIntegrationTicketModel";

type TTicketingIntegrationProps = {
	integration: TTicketingIntegrationType;
	companyIntegrations: List<TTicketingIntegrationType>;
	onAfterIntegrate: (integration: TTicketingIntegrationType) => void;
};

export const TicketingIntegration: FC<TTicketingIntegrationProps> = ({
	integration,
	companyIntegrations,
	onAfterIntegrate: afterIntegrate
}) => {
	const { t } = useTranslation();

	const isIntegrated = useMemo(() => companyIntegrations.includes(integration), [companyIntegrations, integration]);
	const [uuid, setUuid] = useState<string | null>(null);
	const [linkToken, setLinkToken] = useState("");
	const [hasOpenedSinceLastClick, setHasOpenedSinceLastClick] = useState(false);
	const { withLoader, isLoading } = useLoadingState();
	const withGlobalErrorHandler = useWithGlobalErrorHandler();
	const openGlobalErrorModal = useOpenGlobalErrorModal();

	const {
		actions: { removeTicketingIntegration }
	} = useCompanyContext();

	const onMergeSuccess = useCallback(
		async (publicToken: string) => {
			if (!uuid) return;

			const integrate = async () => {
				try {
					await addTicketingIntegration(integration, publicToken, uuid);
					afterIntegrate(integration);
				} catch (error) {
					openGlobalErrorModal(error as Error);
				}
			};

			await withGlobalErrorHandler(withLoader(integrate()));
		},
		[afterIntegrate, integration, uuid, withGlobalErrorHandler, withLoader, openGlobalErrorModal]
	);

	const { open, isReady } = useMergeLink({ linkToken, onSuccess: onMergeSuccess });

	useEffect(() => {
		if (isReady && linkToken && !isIntegrated && !hasOpenedSinceLastClick) {
			open();
			setHasOpenedSinceLastClick(true);
		}
	}, [open, isReady, linkToken, isIntegrated, hasOpenedSinceLastClick]);

	const connect = useCallback(async () => {
		if (!isIntegrated) {
			const mergeToken = await getMergeLinkToken(integration);
			setUuid(mergeToken.uuid);
			setLinkToken(mergeToken.linkToken);
			setHasOpenedSinceLastClick(false);
		}
	}, [integration, isIntegrated]);

	const disconnect = useCallback(async () => {
		if (isIntegrated) {
			await withGlobalErrorHandler(withLoader(removeTicketingIntegration(integration)));
		}
	}, [integration, isIntegrated, removeTicketingIntegration, withGlobalErrorHandler, withLoader]);

	const actions = useMemo(() => {
		return isIntegrated ? (
			<Button variant="text" onClick={disconnect} size="small" disabled={isLoading}>
				{t("pages.settings.integrations.disconnect")}
			</Button>
		) : (
			<Button variant="text" onClick={connect} size="small" disabled={isLoading}>
				{t("pages.settings.integrations.connect")}
			</Button>
		);
	}, [connect, disconnect, isIntegrated, isLoading, t]);

	return (
		<IntegrationRow
			isIntegrated={isIntegrated}
			SystemLogo={getTicketingIntegrationLogoByType(integration)}
			integration={t(`pages.settings.ticketingIntegrations.${integration}`)}
			actions={actions}
		/>
	);
};
