import React, { useCallback } from "react";
import { Button } from "components/ui/Button";
import { Modal } from "components/ui/Modal";
import { useTranslation } from "react-i18next";
import { changeProfile } from "api/user";
import { uniq } from "lodash";
import { useUpdateUser } from "hooks/useUpdateUser";
import { useUser } from "hooks/useUser";
import { useIntegrationActors } from "hooks/useIntegrationActors";
import { IntegrationActorModel } from "models/IntegrationActorModel";
import { IntegrationModel } from "models/IntegrationModel";
import { useLoadingState } from "hooks/useLoadingState";
import { IntegrationActorSelectInput } from "components/common/UserIntegrationActorsSelect";
import { TitleBody } from "components/ui/TitleBody";
import { useStyles } from "./styles";

interface IFillMissingActorsModalProps {
	missingIntegrations: IntegrationModel[];
	onSubmit: () => void;
	onClose: () => void;
	isOpen: boolean;
}

export const FillMissingActorsModal: FC<IFillMissingActorsModalProps> = ({
	missingIntegrations,
	onSubmit,
	onClose,
	isOpen
}) => {
	const classes = useStyles();
	const { t } = useTranslation();
	const { user } = useUser();
	const updateUser = useUpdateUser();
	const { isLoading, withLoader } = useLoadingState();

	const { integrationActorsOptions, integrationActors, unfilledIntegrations } =
		useIntegrationActors<IntegrationActorModel>(missingIntegrations, undefined);

	const fillMissingActors = useCallback(async () => {
		const newIntegrationActors = uniq([
			...(integrationActors || []),
			...(user?.integrationActors?.map(actor => actor.id) || [])
		]);
		const newUser = await withLoader(
			changeProfile({
				integrationActors: newIntegrationActors
			})
		);
		updateUser(newUser);
		onSubmit();
	}, [updateUser, onSubmit, withLoader, integrationActors, user]);

	return (
		<Modal
			onClose={onClose}
			isOpen={isOpen}
			actions={
				<Button size="medium" onClick={fillMissingActors} disabled={unfilledIntegrations} loading={isLoading}>
					{t("pages.newTicket.newTicketForm.missingActorsModal.submit")}
				</Button>
			}
			content={
				<div className={classes.modalContent}>
					<TitleBody size="large" title={t("pages.newTicket.newTicketForm.missingActorsModal.title")} />
					{integrationActorsOptions.map(({ integrationId, label, onChange, sortBy, value }) => (
						<IntegrationActorSelectInput
							key={integrationId}
							optionClassName={classes.option}
							integrationId={integrationId}
							label={label}
							onChange={onChange}
							sortBy={sortBy}
							value={value}
						/>
					))}
				</div>
			}
		/>
	);
};
