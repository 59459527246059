import { Table } from "components/ui/Table";
import { Typography } from "components/ui/Typography";
import { Token } from "components/common/Token";
import React, { useCallback, useEffect, useState } from "react";
import { ApiTokenModel } from "models/ApiTokenModel";
import { PersonalAccessTokenModel } from "models/PersonalAccessTokenModel";
import { useTranslation } from "react-i18next";
import { List } from "immutable";

type TTokens = ApiTokenModel | PersonalAccessTokenModel;

interface IProps {
	tokens: List<TTokens> | undefined;
	onReset: () => void;
	onCreate: (name: string, duration: number | null) => Promise<TTokens | undefined | void>;
	onEdit: (id: string, name: string) => Promise<TTokens | undefined | void>;
	onDelete: (id: string) => void;
	newRow?: boolean;
}

export const TokenWithExpirationTable: FC<IProps> = ({
	tokens,
	onReset,
	onCreate,
	onEdit,
	onDelete,
	newRow = false
}) => {
	const { t } = useTranslation();

	const [newToken, setNewToken] = useState<PersonalAccessTokenModel | null>(null);
	const [tokenIdToEdit, setTokenIdToEdit] = useState<string | null>(null);

	const isNameTaken = useCallback((name: string) => Boolean(tokens?.some(token => token.name === name)), [tokens]);

	const resetInputs = useCallback(() => {
		setNewToken(null);
		setTokenIdToEdit(null);
		onReset();
	}, [onReset]);

	const setTokenToEdit = useCallback(
		(tokenId: string) => {
			resetInputs();
			setTokenIdToEdit(tokenId);
		},
		[resetInputs]
	);

	useEffect(() => {
		setNewToken(newRow ? new PersonalAccessTokenModel({ name: "", expiresAt: null }) : null);
	}, [newRow]);

	return (
		<Table gridColumns="repeat(3, 1fr) 15rem">
			<Table.Row>
				<Table.Header>
					<Typography variant="small">{t("pages.settings.tokens.name")}</Typography>
				</Table.Header>
				<Table.Header>
					<Typography variant="small">{t("pages.settings.tokens.expiryDate")}</Typography>
				</Table.Header>
				<Table.Header>
					<Typography variant="small">{t("pages.settings.tokens.tokenTitle")}</Typography>
				</Table.Header>
			</Table.Row>
			{newToken && (
				<Token
					token={newToken}
					create
					onCancel={resetInputs}
					afterSave={resetInputs}
					isNameTaken={isNameTaken}
					onCreate={onCreate}
					withExpiration
				/>
			)}
			{tokens?.map(token => (
				<Token
					key={token.id}
					token={token}
					onDelete={onDelete}
					onCancel={resetInputs}
					afterSave={resetInputs}
					edit={tokenIdToEdit === token.id}
					enterEdit={setTokenToEdit}
					isNameTaken={isNameTaken}
					onEdit={onEdit}
					withExpiration
				/>
			))}
		</Table>
	);
};
