import React, { useCallback, useEffect, useMemo } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { getResolvedText, i18nText } from "utils/i18n";
import ApiError, { getErrorTexts } from "utils/errors/apiError";
import { Modal, UnWrappedModal } from "components/ui/Modal";
import { Button } from "components/ui/Button";
import { EntitleError } from "utils/errors/entitleError";
import { TitleBody } from "../TitleBody";
import { useStyles } from "./styles";

type TProps = {
	className?: string;
	closeText?: i18nText;
	error: EntitleError | Error | null;
	isOpen: boolean;
	onClose(): void;
	onRetry?(): void;
	retryText?: i18nText;
	shouldCloseOnRetry?: boolean;
	useUnwrapped?: boolean;
};

export const ErrorModal: FC<TProps> = ({
	className,
	closeText = i18nText("buttons.dismiss"),
	error,
	isOpen,
	onClose,
	onRetry,
	useUnwrapped = false,
	retryText = i18nText("buttons.tryAgain"),
	shouldCloseOnRetry = false
}) => {
	const { t } = useTranslation();
	const classes = useStyles();

	const handleRetry = useCallback(() => {
		if (!onRetry) return;
		onRetry();
		if (shouldCloseOnRetry) onClose();
	}, [onClose, onRetry, shouldCloseOnRetry]);

	const { title, message } = useMemo(() => {
		if (error instanceof ApiError || !(error instanceof EntitleError)) {
			return getErrorTexts(error);
		}
		return error.getTexts();
	}, [error]);

	useEffect(() => {
		if (isOpen) {
			(document.activeElement as HTMLElement)?.blur();
		}
	}, [isOpen]);

	const modalProps = useMemo(() => {
		return {
			isOpen,
			className: classNames(classes.modal, className),
			onClose,
			content: <TitleBody size="large" className={classes.content} title={title} body={message} />,
			actions: (
				<>
					<Button size="medium" onClick={onClose} variant={onRetry ? "secondary" : "primary"}>
						{getResolvedText(t, closeText)}
					</Button>

					{onRetry && (
						<Button size="medium" onClick={handleRetry} variant="primary">
							{getResolvedText(t, retryText)}
						</Button>
					)}
				</>
			)
		};
	}, [
		className,
		classes.content,
		classes.modal,
		closeText,
		handleRetry,
		isOpen,
		message,
		onClose,
		onRetry,
		retryText,
		t,
		title
	]);

	return useUnwrapped ? <UnWrappedModal {...modalProps} parentElement={document.body} /> : <Modal {...modalProps} />;
};
