import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { editIntegrationResource, TIntegrationResourceBody } from "api/integrationResources";
import { IntegrationResourceModel } from "models/IntegrationResourceModel";
import { Button } from "components/ui/Button";
import { Separator } from "components/ui/Separator";
import useErrorModalState from "hooks/useErrorModalState";
import { ErrorModal } from "components/ui/ErrorModal";
import { List } from "immutable";
import { TTicketDuration } from "utils/durationsOptions";
import { useCompany } from "hooks/useCompany";
import { useIntegrations } from "hooks/useIntegrations";
import { ResourceHeader } from "components/common/ResourceHeader";
import { useStyles } from "./styles";
import { ResourceSettingsPopup } from "./ResourceSettingsPopup";
import type { TFormData } from "./ResourceSettingsPopup/useSettingsPopupState";
import type { UserModel } from "models/UserModel";

interface IProps {
	adapterless: boolean;
	childrenCount?: number;
	inherited?: boolean;
	integrationResource: IntegrationResourceModel;
	onDeleteButtonClick?: () => void;
	onSave: (data: Partial<TIntegrationResourceBody>) => Promise<void>;
	owner: UserModel | null;
	type?: string | undefined;
}

export const ResourceHeaderBlock: FC<IProps> = ({
	adapterless,
	childrenCount,
	inherited = false,
	integrationResource,
	onDeleteButtonClick,
	onSave: propOnSave,
	owner: propOwner,
	type = undefined
}) => {
	const classes = useStyles();
	const { t } = useTranslation();
	const company = useCompany();
	const integrations = useIntegrations();
	const integration = integrations?.get(integrationResource.integrationId || "");
	const {
		allowAsGrantMethod,
		allowsRequests,
		tags,
		description,
		userDefinedDescription,
		userDefinedTags,
		allowedDurations: resourceAllowedDurations,
		type: resourceType,
		displayName
	} = useMemo(() => integrationResource.toObject(), [integrationResource]);

	const [resourceName, setResourceName] = useState(displayName);
	const resourceTags = useMemo(() => [...(tags || []), ...(userDefinedTags || [])], [tags, userDefinedTags]);
	const allowedDurations = useMemo(() => {
		if (resourceAllowedDurations) {
			return resourceAllowedDurations;
		} else if (integration?.allowedDurations) {
			return integration.allowedDurations;
		} else if (company?.allowedDurations) {
			return company.allowedDurations;
		}
		return List<TTicketDuration>();
	}, [company, integration, resourceAllowedDurations]);
	const { errorModalSetError, errorModalIsOpen, errorModalError, errorModalRetry, errorModalClose } =
		useErrorModalState();

	const onUpdateResource = useCallback(
		async (resourceData: Partial<TIntegrationResourceBody & { id: string }>) => {
			try {
				await editIntegrationResource(resourceData);
			} catch (e) {
				errorModalSetError(e as Error);
			}
		},
		[errorModalSetError]
	);

	const onResourceRename = useCallback(
		async (newName: string) => {
			setResourceName(newName);
			await onUpdateResource({ id: integrationResource.id, displayName: newName });
		},
		[integrationResource.id, onUpdateResource]
	);

	const onDelete = useCallback(() => {
		if (integrationResource.id && onDeleteButtonClick) {
			onDeleteButtonClick();
		}
	}, [integrationResource.id, onDeleteButtonClick]);

	const onSave = useCallback(
		async (formData: TFormData) => {
			await propOnSave({
				id: integrationResource.id,
				displayName: formData.displayName,
				allowAsGrantMethod: formData.allowAsGrantMethod,
				allowsRequests: formData.allowsRequests,
				allowedDurations: formData.allowedDurations || null,
				ownerUserId: formData.owner?.id || null,
				type: formData.resourceType || undefined,
				description: formData.description,
				tags: formData.tags?.filter(tag =>
					integrationResource?.tags ? !integrationResource?.tags?.includes(tag) : true
				),
				...(type && { type: formData.resourceType })
			});
		},
		[integrationResource, propOnSave, type]
	);

	useEffect(() => {
		setResourceName(displayName);
	}, [displayName]);

	return (
		<div className={classes.header}>
			<div className={classes.resourceHeaderBlock}>
				<div className={classes.resourceHeaderSection}>
					<ResourceHeader
						childrenCount={childrenCount}
						description={userDefinedDescription || description}
						inheritOwner={inherited}
						name={resourceName}
						onRename={adapterless ? onResourceRename : undefined}
						ownerId={propOwner?.id || undefined}
						requestsDisabled={!allowsRequests}
						integrationId={integrationResource.integrationId}
						resourceType="resource"
						showOwner
						tags={resourceTags}
						type={resourceType}
					/>
				</div>
				<div className={classes.resourceHeaderBlock}>
					<ErrorModal
						isOpen={errorModalIsOpen}
						error={errorModalError}
						onRetry={errorModalRetry}
						onClose={errorModalClose}
					/>
					<ResourceSettingsPopup
						{...{
							adapterless,
							integrationResource,
							onSave,
							type,
							integration,
							initialFormData: {
								allowAsGrantMethod,
								allowedDurations,
								overrideAllowedDurations: !!integrationResource.allowedDurations,
								allowsRequests,
								owner: propOwner,
								description: userDefinedDescription,
								tags: resourceTags,
								resourceType,
								displayName: resourceName
							}
						}}
					/>
					{onDeleteButtonClick && (
						<>
							<Separator />
							<Button size="small" variant="text" onClick={onDelete}>
								{t("buttons.delete")}
							</Button>
						</>
					)}
				</div>
			</div>
		</div>
	);
};
