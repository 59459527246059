import React from "react";
import { Chips } from "../../MultipleSelect/components/Chips";
import { IRenderChipParams } from "../../MultipleSelect";
import { useStyles } from "./styles";

interface IPrefixOptions<T> {
	getOptionLabel: (option: T) => string;
	multiLine?: boolean;
	noCollapse?: boolean;
	onRemove?: (option: T) => void;
	prefixIcon?: JSX.Element;
	renderChip?: (params: IRenderChipParams<T>) => JSX.Element;
	values: T[];
	readonlyValues?: T[];
	chipsLimit?: number;
}

export const Prefix = <T,>(options: TProps<IPrefixOptions<T>>) => {
	const {
		getOptionLabel,
		multiLine,
		noCollapse = false,
		onRemove,
		prefixIcon = null,
		renderChip,
		values,
		readonlyValues,
		chipsLimit = 20
	} = options;
	const classes = useStyles();

	return (
		<>
			{prefixIcon}
			<Chips
				getOptionLabel={getOptionLabel}
				multiLine={multiLine}
				noCollapse={noCollapse}
				onRemove={onRemove}
				ChipElement={renderChip}
				values={values}
				readonlyValues={readonlyValues}
				collapsedValuesClassName={classes.collapsed}
				limit={chipsLimit}
			/>
		</>
	);
};
