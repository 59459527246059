import React, { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { AreYouSureModal } from "components/common/AreYouSureModal";
import { useNewRequestFormContext } from "../../newRequestFormContext";

type TProps = {
	isOpen: boolean;
	close: () => void;
};

export const ExitNewRequestModal: FC<TProps> = ({ className, innerRef, isOpen, close }) => {
	const { t } = useTranslation("translation", { keyPrefix: "pages.newRequest.exitNewRequestModal" });

	const {
		state: { requestTargets }
	} = useNewRequestFormContext();

	const navigate = useNavigate();

	const goBack = useCallback(() => {
		close();
		navigate(-1);
	}, [close, navigate]);

	useEffect(() => {
		if (isOpen && requestTargets.size === 0) {
			goBack();
		}
	}, [goBack, isOpen, requestTargets.size]);

	if (requestTargets.size === 0) return null;
	return (
		<AreYouSureModal
			className={className}
			innerRef={innerRef}
			isOpen={isOpen}
			onClose={close}
			onCancel={goBack}
			onAction={close}
			actionLabel={t("stayLabel")}
			closeLabel={t("cancelLabel")}
			content={t("content")}
		/>
	);
};
