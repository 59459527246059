import React, { useCallback, useEffect, useState } from "react";
import { matchSorter } from "match-sorter";
import { useTranslation } from "react-i18next";
import { useOpenGlobalErrorModal } from "hooks/useGlobalError";
import { Table } from "components/ui/Table";
import { Button } from "components/ui/Button";
import classNames from "classnames";
import { TAdminUser, UserModel } from "models/UserModel";
import { Select } from "components/ui/Select";
import { UserWithEmail } from "components/common/UserWithEmail";
import { useUser } from "hooks/useUser";
import { useRankedSort } from "hooks/useRankedSort";
import { useLoadingState } from "hooks/useLoadingState";
import { useUsers } from "hooks/useUsers";
import { useStyles } from "./styles";

interface IProps {
	admin?: TAdminUser;
	create?: boolean;
	createHandler?: (userId: string) => Promise<void>;
	removeHandler?: (userId: string) => Promise<void>;
	closeInput?: () => void;
}

const getLabel = (user: UserModel) => user.fullName;
const equality = (option: UserModel, value: UserModel) => option.id === value.id;
const renderOption = (option: UserModel) => <UserWithEmail user={option} />;
const renderLabel = (option: UserModel) => (option ? <UserWithEmail user={option} /> : undefined);

const SORT_OPTIONS = {
	keys: [{ key: (item: UserModel) => item.fullName, threshold: matchSorter.rankings.MATCHES }]
};

export const AdminRow: FC<IProps> = ({ admin, create = false, createHandler, removeHandler, closeInput }) => {
	const classes = useStyles();
	const { t } = useTranslation();
	const [selectedUser, setSelectedUser] = useState<UserModel | null>(null);
	const [userOptions, setUserOptions] = useState<UserModel[] | null>(null);
	const { withLoader, isLoading } = useLoadingState();
	const { sort, onInputChange } = useRankedSort<UserModel>(SORT_OPTIONS);

	const { user } = useUser();
	const users = useUsers(false);

	const handleError = useOpenGlobalErrorModal();

	const onAction = useCallback(async () => {
		try {
			if (create && createHandler) {
				selectedUser && (await withLoader(createHandler(selectedUser.id)));
			} else if (removeHandler) {
				admin && (await withLoader(removeHandler(admin.id)));
			}
		} catch (e) {
			handleError(e as Error);
		}
	}, [create, createHandler, removeHandler, selectedUser, withLoader, admin, handleError]);

	useEffect(() => {
		create && setUserOptions(users?.toList().toArray() || null);
	}, [create, users]);

	return (
		<Table.Row>
			<Table.Cell className={classNames({ [classes.create]: create })}>
				{create ? (
					<Select
						className={classes.selectInput}
						variant="inline"
						onInputChange={onInputChange}
						loading={userOptions === null}
						options={userOptions || []}
						value={selectedUser}
						onChange={setSelectedUser}
						renderLabel={renderLabel}
						renderOption={renderOption}
						isOptionEqualToValue={equality}
						sort={sort}
						getOptionLabel={getLabel}
					/>
				) : (
					admin && <UserWithEmail user={admin} />
				)}
			</Table.Cell>
			<Table.Cell className={classNames(classes.actionsContainer, { [classes.create]: create })}>
				{create && closeInput ? (
					<>
						<Button disabled={isLoading || !selectedUser} onClick={onAction} variant="text" size="small">
							{t("buttons.save")}
						</Button>
						<Button disabled={isLoading} onClick={closeInput} variant="text" size="small">
							{t("buttons.cancel")}
						</Button>
					</>
				) : (
					admin?.id !== user?.id && (
						<Button disabled={isLoading} onClick={onAction} variant="text" size="small">
							{t("buttons.remove")}
						</Button>
					)
				)}
			</Table.Cell>
		</Table.Row>
	);
};
