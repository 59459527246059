import React from "react";
import classNames from "classnames";
import { Typography } from "components/ui/New/Typography";
import { Chip } from "components/ui/Chip";
import { DateWithDiff } from "../DateWithDiff";
import { useStyles } from "./styles";

type TProps = {
	prefix?: React.ReactNode;
	number?: number;
	date: Date;
};

export const RequestDateAndNumber: FC<TProps> = ({ className, innerRef, prefix = null, number, date }) => {
	const classes = useStyles();

	return (
		<div className={classNames(classes.container, className)} ref={innerRef}>
			<div className={classes.icon}>{prefix}</div>
			<DateWithDiff date={date} />
			<Chip size="tiny" className={classes.number}>
				<Typography variant="text_sm_reg">{number}</Typography>
			</Chip>
		</div>
	);
};
