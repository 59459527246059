import React, { useCallback, useMemo } from "react";
import classNames from "classnames";
import { Typography } from "../Typography";
import { useStyles } from "./styles";

interface IToggleOption<T> {
	value: T;
	label: string | JSX.Element;
}

type TPossibleValue = number | string | { id: string | number; [key: string]: unknown };

export type TBinaryToggleOptions<T> = [IToggleOption<T>, IToggleOption<T>];
interface IBinaryToggleProps<T> extends TProps {
	disabled?: boolean;
	even?: boolean;
	fontSize?: "standard" | "small" | "tiny";
	onChange?: (value: T) => void;
	options: TBinaryToggleOptions<T>;
	value: T;
}

export function BinaryToggle<T extends TPossibleValue>({
	className,
	disabled,
	even = false,
	fontSize = "standard",
	onChange,
	options,
	value
}: IBinaryToggleProps<T>) {
	const [optionOne, optionTwo] = options;
	const classes = useStyles();
	const isOneSelected = useMemo(() => value === optionOne.value, [optionOne.value, value]);
	const onToggle = useCallback(() => {
		if (!disabled && onChange) {
			onChange(isOneSelected ? optionTwo.value : optionOne.value);
		}
	}, [disabled, isOneSelected, onChange, optionOne.value, optionTwo.value]);

	return (
		<Typography
			variant={fontSize}
			className={classNames(classes.binaryToggleContainer, { [classes.even]: even }, className)}>
			<div
				onClick={isOneSelected ? undefined : onToggle}
				className={classNames(classes.binaryToggleOption, { disabled, selected: isOneSelected })}>
				{optionOne.label}
			</div>
			<div
				onClick={!isOneSelected ? undefined : onToggle}
				className={classNames(classes.binaryToggleOption, { disabled, selected: !isOneSelected })}>
				{optionTwo.label}
			</div>
		</Typography>
	);
}
