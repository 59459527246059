import merge from "lodash/merge";
import { Styles } from "react-jss";
import { styles as fade } from "./fadeAnimation.styles";
import { styles as fadeFromBottom } from "./fadeMovedFromBottomAnimation.styles";
import { styles as fadeFromCenter } from "./fadeMovedFromCenterAnimation.styles";
import { styles as fadeFromEnd } from "./fadeMovedFromEndAnimation.styles";
import { styles as moveFromSide } from "./moveFromSideAnimation.styles";
import { styles as noAnimation } from "./noneAnimation.styles";

// using merge and not safe merge because we want the key to contain special characters - "[], ()"
export const animations: Styles<"transitionable"> = merge(
	fade,
	fadeFromBottom,
	fadeFromCenter,
	fadeFromEnd,
	moveFromSide,
	noAnimation
);
