import React, { createContext, ReactElement, useEffect, useRef, useState } from "react";
import { Routes, Location, useLocation } from "react-router-dom";
import { List } from "immutable";
import requestRenderFrame, { RenderFrameHandle } from "utils/requestRenderFrame";

const unmountTimeout = 1000 * 1.5;
const renderFrameTimeout = 1000 * 0.5;

export type TSwitchAnimation =
	| "move-from-side"
	| "fade-move-from-bottom"
	| "fade-move-from-end"
	| "fade-move-from-center"
	| "fade"
	| "none";
type TTransitionAnimationContextParams = {
	animation: TSwitchAnimation;
	animate: boolean;
	reverse: boolean;
	movement: "in" | "out" | "standstill";
};
export const TransitionAnimationContext = createContext<TTransitionAnimationContextParams>({
	animation: "fade-move-from-center",
	animate: false,
	reverse: false,
	movement: "standstill"
});

export const SwitchTransitions: FC<TSwitchTransitionsProps> = ({
	children,
	animation = "fade-move-from-center",
	reverse = false
}) => {
	const location = useLocation();
	const [renderLocations, setRenderLocations] = useState<
		List<{
			location: Location;
			addTime: number;
			contextState: TTransitionAnimationContextParams;
		}>
	>(() => List());
	const unmountTimeoutsRef = useRef<number[]>([]);

	useEffect(() => {
		// on the first time, add the location without animating it
		if (renderLocations.size === 0) {
			setRenderLocations(
				List([
					{
						location,
						addTime: Date.now(),
						contextState: {
							animation,
							animate: false,
							reverse,
							movement: "standstill"
						} as TTransitionAnimationContextParams
					}
				])
			);
		} else {
			// when there are already some locations in `renderLocations`,
			// animate the entering of the new location and the exiting of the previous location.
			// but only if they have a different pathnames
			// first, add new location to the dom and prepare the new and previous locations for an animation
			const lastItem = renderLocations.last();
			const samePathname = lastItem?.location.pathname === location.pathname;

			if (lastItem != null && !samePathname) {
				const outItem = {
					...lastItem,
					contextState: {
						animation,
						animate: false,
						reverse,
						movement: "out"
					} as TTransitionAnimationContextParams
				};
				const inItem = {
					location,
					addTime: Date.now(),
					contextState: {
						animation,
						animate: false,
						reverse,
						movement: "in"
					} as TTransitionAnimationContextParams
				};

				// we should clear the previous transitions of the same location if they exist
				renderLocations.forEach((renderLocation, index) => {
					if (renderLocation.location.pathname === location.pathname) {
						window.clearTimeout(unmountTimeoutsRef.current.at(index));
						unmountTimeoutsRef.current.splice(index, 1);
					}
				});

				setRenderLocations(
					renderLocations
						.slice(0, -1)
						.filter(renderLocation => renderLocation.location.pathname !== location.pathname)
						.push(outItem, inItem)
				);
			}

			const scheduleUnmount = () => {
				if (!samePathname) {
					unmountTimeoutsRef.current.push(
						window.setTimeout(() => {
							setRenderLocations(renderLocations => renderLocations.shift());
							unmountTimeoutsRef.current.shift();
						}, unmountTimeout)
					);
				}
			};

			// wait until the items are added to the DOM and rendered
			let renderFrame: RenderFrameHandle | null = requestRenderFrame(() => {
				// trigger the transition animations
				setRenderLocations(renderLocations => {
					const oldOutItem = renderLocations.get(-2);
					const oldInItem = renderLocations.last();
					if (renderLocations.size < 2 || !oldOutItem || !oldInItem) {
						if (!oldInItem) return renderLocations;
						return renderLocations.setIn([-1], { ...oldInItem, location });
					}
					const outItem = {
						...oldOutItem,
						contextState: {
							animation,
							animate: true,
							reverse,
							movement: "out"
						} as TTransitionAnimationContextParams
					};

					const inItem = {
						...oldInItem,
						contextState: {
							animation,
							animate: true,
							reverse,
							movement: "in"
						} as TTransitionAnimationContextParams
					};

					return renderLocations.slice(0, -2).push(outItem, inItem);
				});

				renderFrame = null;
				scheduleUnmount();
			}, renderFrameTimeout);

			return () => {
				if (renderFrame != null) {
					renderFrame.destroy();
					scheduleUnmount();
				}
			};
		}

		return () => void 0;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location.key]);

	useEffect(() => {
		const current = unmountTimeoutsRef.current;
		return () => {
			while (current.length > 0) {
				window.clearTimeout(current.shift());
			}
		};
	}, []);

	return (
		<>
			{renderLocations.map<ReactElement>(renderLocation => {
				return (
					<TransitionAnimationContext.Provider
						key={renderLocation.location.pathname}
						value={renderLocation.contextState}>
						<Routes location={renderLocation.location}>{children}</Routes>
					</TransitionAnimationContext.Provider>
				);
			})}
		</>
	);
};

type TSwitchTransitionsProps = {
	animation?: TSwitchAnimation;
	reverse?: boolean;
};
