import React from "react";
import { TextUser } from "components/common/TextUser";
import { UserModel } from "models/UserModel";
import { useTranslation } from "react-i18next";

export const AuditLogUser: FC<{ user?: UserModel }> = ({ user, className }) => {
	const { t } = useTranslation();
	// if there is no user we assume its API user
	if (!user) return <b>{t("pages.auditLog.apiUser")}</b>;

	return <TextUser user={user} className={className} />;
};
