import { EventEmitter } from "events";
import React, { useCallback, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useTicketUpdatesContext } from "context/ticketUpdatesContext";
import { useTicket } from "hooks/useTicket";
import { useTranslation } from "react-i18next";
import { addTicketComment } from "api/ticketComments";
import { TicketCommentModel } from "models/TicketCommentModel";
import { useSystemOverlays } from "context/overlaysContext";
import { LoadingDots } from "components/ui/LoadingDots";
import { Typography } from "components/ui/New/Typography";
import { BP } from "utils/breakpoints";
import { Divider } from "components/ui/Divider";
import { DraggableSidebar, RESIZER_WIDTH_PX } from "components/common/DraggableSidebar";
import { RequestDateAndNumber } from "components/common/RequestDateAndNumber";
import { ACTIVITY_WIDTH_PX, MIN_DETAILS_WIDTH_PX, useStyles } from "./styles";
import { TicketActivity } from "./components/TicketActivity";
import { GeneralInfo } from "./components/GeneralInfo";
import { RequestDetailsRequests } from "./components/Requests/Requests";
import { ApprovalProcess } from "./components/ApprovalProcess";
import { RequestDetailsFooter } from "./RequestDetailsFooter";
import type { TFullTicket } from "./types";

const APPROVED_TICKET_STATUSES = new Set<TFullTicket["status"]>(["approved", "permissionInProgress", "waitingForIT"]);

const MAX_FIXED_WIDTH_PX = BP.FIRST;

const calculateMaxWidth = () => {
	const maxWidth = document.body.clientWidth * 0.95;
	return Math.min(maxWidth, MAX_FIXED_WIDTH_PX);
};

const MIN_FULL_DETAILS_WIDTH_PX = 760 + RESIZER_WIDTH_PX;
const MIN_WIDTH_PX = ACTIVITY_WIDTH_PX + MIN_DETAILS_WIDTH_PX + RESIZER_WIDTH_PX;

export const RequestDetails: FC<{ resetEventEmitter?: EventEmitter }> = ({ resetEventEmitter }) => {
	const [searchParams, setSearchParams] = useSearchParams();
	const systemOverlays = useSystemOverlays();
	const [withActivity, setWithActivity] = useState(document.body.clientWidth > BP.FOURTH);
	const [maxWidth, setMaxWidth] = useState(calculateMaxWidth());
	const ticketId = searchParams.get("ticketId") || undefined;
	const { ticket: remoteTicket, loading, setTicket, reloadTicket } = useTicket(ticketId || "");
	const ticket = remoteTicket as TFullTicket | null;
	const { notifyTicketUpdate, subscribeTicketUpdates, unsubscribeTicketUpdates } = useTicketUpdatesContext();

	const closeSidebar = useCallback(() => {
		searchParams.delete("ticketId");
		setSearchParams(searchParams);
	}, [searchParams, setSearchParams]);

	const classes = useStyles();
	const { t } = useTranslation();

	const reloadTicketHandler = useCallback(async () => {
		const updatedTicket = await reloadTicket();
		notifyTicketUpdate(updatedTicket);
	}, [notifyTicketUpdate, reloadTicket]);

	useEffect(() => {
		subscribeTicketUpdates("requestDetails", updatedTicket => {
			if (ticketId === updatedTicket.id) {
				reloadTicket();
			}
		});
		return () => {
			unsubscribeTicketUpdates("requestDetails");
		};
	}, [reloadTicket, subscribeTicketUpdates, ticketId, unsubscribeTicketUpdates]);

	const addComment = useCallback(
		async (comment: string, reload: boolean) => {
			if (!ticket || !comment) return;
			const newComment = await addTicketComment(ticket.id, comment);
			if (ticket.comments) {
				setTicket(ticket.set("comments", ticket.comments.unshift(newComment)));
			}
			if (reload) await reloadTicketHandler();
		},
		[reloadTicketHandler, setTicket, ticket]
	);

	const updateComment = useCallback(
		(comment: TicketCommentModel) => {
			if (!ticket) return;
			if (ticket.comments) {
				const ticketIndex = ticket.comments.findIndex(({ id }) => id === comment.id);
				let updatedTicket = ticket.set("comments", ticket.comments.set(ticketIndex, comment));
				if (ticketIndex === 0) {
					updatedTicket = updatedTicket.set("justification", comment.content);
					notifyTicketUpdate(updatedTicket);
				}
				setTicket(updatedTicket);
			}
		},
		[setTicket, ticket, notifyTicketUpdate]
	);

	useEffect(() => {
		const observer = new ResizeObserver(mutations => {
			if (mutations.at(0)?.target.clientWidth) {
				setMaxWidth(calculateMaxWidth());
				setWithActivity(document.body.clientWidth > BP.FOURTH);
			}
		});
		systemOverlays && observer.observe(systemOverlays);

		return () => {
			observer.disconnect();
		};
	}, [systemOverlays]);

	useEffect(() => {
		resetEventEmitter?.on("reset", reloadTicketHandler);

		return () => {
			resetEventEmitter?.off("reset", reloadTicketHandler);
		};
	}, [resetEventEmitter, reloadTicketHandler]);

	return (
		<DraggableSidebar
			className={classes.sidebarContent}
			maxWidth={maxWidth}
			initialWidth={withActivity ? MIN_FULL_DETAILS_WIDTH_PX + ACTIVITY_WIDTH_PX : MIN_FULL_DETAILS_WIDTH_PX}
			minWidth={withActivity ? MIN_WIDTH_PX : MIN_WIDTH_PX - ACTIVITY_WIDTH_PX}
			open={Boolean(ticketId)}
			closeSidebar={closeSidebar}>
			<div className={classes.requestDetailsSidebar}>
				{loading ? (
					<LoadingDots center />
				) : ticket ? (
					<div className={classes.detailsContainer}>
						<div className={classes.requestData}>
							<div className={classes.requestTitleContainer}>
								<Typography variant="title_sb">{t("common.requestDetails.title")}</Typography>
								<RequestDateAndNumber date={ticket.createdAt} number={ticket.number} />
							</div>
							<GeneralInfo ticket={ticket} />
							<Divider horizontal full className={classes.divider} />
							{(ticket.ticketPermissions.size || 0) > 0 ? (
								<>
									<RequestDetailsRequests
										ticketTargets={ticket.targets}
										ticketPermissions={ticket.ticketPermissions}
										ticketApproved={APPROVED_TICKET_STATUSES.has(ticket.status)}
									/>
									<Divider horizontal full className={classes.divider} />
									<ApprovalProcess
										approvalRequests={ticket.approvalRequests}
										approvalResponses={ticket.ticketApprovalResponses}
									/>
								</>
							) : null}
						</div>
						<RequestDetailsFooter ticket={ticket} reloadTicket={reloadTicketHandler} />
					</div>
				) : null}
				{withActivity && (
					<TicketActivity
						className={classes.ticketActivityContainer}
						addComment={addComment}
						ticket={ticket}
						updateComment={updateComment}
					/>
				)}
			</div>
		</DraggableSidebar>
	);
};
