import React, { useCallback, useEffect, useMemo } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { PageTemplate } from "components/templates/PageTemplate";
import { Typography } from "components/ui/Typography";
import { useNavigate, useParams } from "react-router-dom";
import { useAccessReviewResource } from "hooks/useAccessReviewResource";
import { useAccessReviewReport } from "hooks/useAccessReviewReport";
import { ResourcePermissionsReviewTable } from "components/common/ResourcePermissionsReviewTable";
import { Loading } from "components/ui/Loading";
import { useAccessReviewsContext } from "context/accessReviewsContext";
import { Breadcrumbs } from "components/common/Breadcrumbs";
import { RequestDetails } from "components/common/RequestDetails";
import { useStyles } from "./styles";

export const AdminResourceAccessReviewPage: FC = ({ className }) => {
	const { t } = useTranslation();
	const classes = useStyles();
	const { resourceId } = useParams<{ resourceId: string }>();
	const navigate = useNavigate();
	const { accessReviewResource, isGetLoading: resourceLoading } = useAccessReviewResource(resourceId);
	const { accessReviewReport, getActionLoading: reportLoading } = useAccessReviewReport(
		accessReviewResource?.accessReviewReportId || ""
	);

	const {
		state: { accessReviews, isLoadLoading: accessReviewsLoading },
		actions: { loadAccessReviews }
	} = useAccessReviewsContext();

	const currentAccessReview = accessReviews?.get(accessReviewReport?.accessReviewId || "");

	const sortedResources = useMemo(() => accessReviewReport?.sortedResources || [], [accessReviewReport]);

	const onChangeResource = useCallback(
		(id: string) => {
			if (id !== resourceId) {
				navigate(`/accessReview/resources/${id}`);
			}
		},
		[navigate, resourceId]
	);

	useEffect(() => {
		if (!accessReviews && !accessReviewsLoading) {
			loadAccessReviews();
		}
	}, [accessReviews, accessReviewsLoading, loadAccessReviews]);

	const getContent = useCallback(() => {
		if (currentAccessReview && accessReviewResource && accessReviewReport) {
			return (
				<div className={classes.permissionList}>
					<ResourcePermissionsReviewTable
						resource={accessReviewResource}
						report={accessReviewReport}
						onChangeResource={onChangeResource}
						sortedResources={sortedResources}
						isAdmin
						immediateRevoke={currentAccessReview.immediateRevoke}
					/>
				</div>
			);
		}
		return (
			<Typography className={classes.center} variant="h2">
				{t("pages.adminResourceReview.resourceNotFound")}
			</Typography>
		);
	}, [accessReviewReport, accessReviewResource, classes, currentAccessReview, onChangeResource, sortedResources, t]);

	const breadcrumbs = useMemo(() => {
		if (!accessReviewReport || !accessReviewResource?.integrationResource) return [];

		return [
			{
				title: t("navigation.accessReview"),
				url: "/accessReview"
			},
			{
				title: t("common.accessReview.reportFrom", { date: accessReviewReport.createdAt }),
				url: `/accessReview/reports/${accessReviewReport.id}`
			},
			{
				title: accessReviewResource?.integrationResource.displayName
			}
		];
	}, [accessReviewReport, accessReviewResource?.integrationResource, t]);

	return (
		<PageTemplate className={classNames(className)} subPage>
			<RequestDetails />
			<PageTemplate.Title className={classes.header}>
				<Breadcrumbs parts={breadcrumbs} />
			</PageTemplate.Title>
			<PageTemplate.Content className={classes.content}>
				<Loading loading={resourceLoading || reportLoading} render={getContent} />
			</PageTemplate.Content>
		</PageTemplate>
	);
};
