import classNames from "classnames";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import sortBy from "lodash/sortBy";
import { Typography } from "components/ui/Typography";
import { IntegrationResourceRoleModel } from "models/IntegrationResourceRoleModel";
import { useIntegrations } from "hooks/useIntegrations";
import { useApplications } from "hooks/useApplications";
import { OptionWithIcon } from "../OptionWithIcon";
import { useStyles } from "./styles";
import { RoleLabelTags } from "./components/RoleLabelTags";

interface IProps {
	imageClassName?: string;
	option: IntegrationResourceRoleModel;
	query?: string;
	simple?: boolean;
	showDescription?: boolean;
	showPermissions?: boolean;
	tagMaxLength?: number;
	tagsShown?: number;
	withDirectToParent?: string;
	withImage?: boolean;
	fullData?: boolean;
}

export const RoleLabel: FC<IProps> = ({
	className,
	imageClassName,
	option,
	query = "",
	showDescription = false,
	showPermissions = false,
	simple = false,
	tagMaxLength = 10,
	tagsShown = 0,
	withDirectToParent,
	withImage = false,
	fullData = true
}) => {
	const { t } = useTranslation();
	const classes = useStyles();
	const integrations = useIntegrations(true);
	const applications = useApplications();

	const permissions = useMemo(() => {
		if (!showPermissions || !option.permissions || !option.permissions.length) return null;

		const permissionLabel = sortBy(option.permissions, permission => permission !== query).at(0)!;

		const permissionElements = [
			permissionLabel.search(query) > 0 ? <b key={permissionLabel}>{permissionLabel}</b> : permissionLabel
		];

		const extraPermissionsCount = option.permissions.length - 1;
		if (extraPermissionsCount > 0) {
			permissionElements.push(t("common.roleLabel.more", { count: extraPermissionsCount }));
		}

		return (
			<div>
				<Typography variant="tiny">{permissionElements}</Typography>
			</div>
		);
	}, [option.permissions, query, showPermissions, t]);

	const roleName = useMemo(() => {
		if (withDirectToParent === option.id) return t("shared.direct");

		const resource = option.integrationResource;
		if (!resource) return option.name;

		const integration = integrations?.get(resource.integrationId);
		if (!integration) return option.name;

		const roleTitle = fullData ? `${resource.displayName} - ${option.name}` : option.name;

		return (
			<div className={classes.roleLabel}>
				<Typography component="div" className={classes.roleTitle}>
					{roleTitle}
				</Typography>
				{fullData ? (
					<Typography variant="tiny" component="div">
						{integration.name}
					</Typography>
				) : undefined}
			</div>
		);
	}, [
		withDirectToParent,
		option.id,
		option.integrationResource,
		option.name,
		t,
		integrations,
		fullData,
		classes.roleLabel,
		classes.roleTitle
	]);

	const description = useMemo(() => {
		const selectedDescription = option.integrationResource?.calculatedDescription;
		if (!showDescription || selectedDescription) return null;
		return <Typography variant="small">{selectedDescription}</Typography>;
	}, [option.integrationResource, showDescription]);

	const imageUrl = useMemo(() => {
		const integrationId = option.integrationResource?.integrationId;
		if (!integrationId || !integrations) return null;
		const integration = integrations.get(integrationId);
		if (!integration) return null;
		if (integration.imageUrl) return integration.imageUrl;
		if (!applications || !integration.applicationId) return null;
		const application = applications.get(integration.applicationId);
		if (!application) return null;
		return application.imageUrl;
	}, [applications, integrations, option.integrationResource?.integrationId]);

	const allTags = useMemo(() => option.integrationResource?.calculatedTags || [], [option]);

	const RoleContainer = useMemo(
		() => (
			<Typography component="div" className={classNames(className, classes.roleContainer)}>
				{roleName}
				{description}
				{permissions}
				<RoleLabelTags tags={allTags} tagMaxLength={tagMaxLength} tagsShown={tagsShown} query={query} />
			</Typography>
		),
		[className, classes.roleContainer, roleName, description, permissions, allTags, tagMaxLength, tagsShown, query]
	);

	if (!withImage && (withDirectToParent === option.id || simple)) return RoleContainer;

	return (
		<OptionWithIcon imageUrl={imageUrl} imageClassName={imageClassName}>
			{RoleContainer}
		</OptionWithIcon>
	);
};
