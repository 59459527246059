import { List } from "immutable";
import { useCallback, useEffect, useState } from "react";
import { TTicketDuration } from "utils/durationsOptions";

export type TRoleFormData = {
	name: string;
	allowAsGrantMethod: boolean;
	allowsRequests: boolean;
	allowedDurations?: List<TTicketDuration>;
	overrideAllowedDurations?: boolean;
};

type TStateArgs = {
	propAllowAsGrantMethod: boolean;
	propAllowsRequests: boolean;
	propAllowedDurations?: List<TTicketDuration>;
	propOnSave: (formData: TRoleFormData) => Promise<void>;
	propName: string;
	propOverrideAllowedDurations?: boolean;
};

export const useSettingsPopupState = ({
	propAllowAsGrantMethod,
	propAllowedDurations,
	propAllowsRequests,
	propOnSave,
	propName,
	propOverrideAllowedDurations = false
}: TStateArgs) => {
	const [allowAsGrantMethod, setAllowAsGrantMethod] = useState(propAllowAsGrantMethod);
	const [allowsRequests, setAllowsRequests] = useState(propAllowsRequests);
	const [overrideAllowedDurations, setOverrideAllowedDurations] = useState(propOverrideAllowedDurations);
	const [allowedDurations, setAllowedDurations] = useState(propAllowedDurations || List<TTicketDuration>());
	const [name, setName] = useState(propName);

	useEffect(() => {
		propAllowedDurations && setAllowedDurations(propAllowedDurations);
	}, [propAllowedDurations]);

	const toggleAllowsRequests = useCallback(() => setAllowsRequests(curr => !curr), []);
	const toggleAllowAsGrantMethod = useCallback(() => setAllowAsGrantMethod(curr => !curr), []);

	const onClose = useCallback(async () => {
		setName(propName);
		setAllowedDurations(propAllowedDurations || List<TTicketDuration>());
		setOverrideAllowedDurations(propOverrideAllowedDurations);
		setAllowsRequests(propAllowsRequests);
		setAllowAsGrantMethod(propAllowAsGrantMethod);
	}, [propAllowAsGrantMethod, propAllowedDurations, propAllowsRequests, propName, propOverrideAllowedDurations]);

	const onSave = useCallback(async () => {
		await propOnSave({
			allowAsGrantMethod,
			allowedDurations: overrideAllowedDurations ? allowedDurations : undefined,
			allowsRequests,
			name
		});
	}, [propOnSave, allowAsGrantMethod, overrideAllowedDurations, allowedDurations, allowsRequests, name]);

	return {
		state: {
			allowAsGrantMethod,
			allowsRequests,
			overrideAllowedDurations,
			allowedDurations,
			name,
			toggleAllowAsGrantMethod,
			toggleAllowsRequests,
			setOverrideAllowedDurations,
			setAllowedDurations,
			onSave,
			onClose,
			setName
		}
	};
};
