import { Map, List } from "immutable";
import { useUsersContext } from "context/usersContext";
import { UserModel } from "models/UserModel";
import { useEffect, useMemo } from "react";
import { useUsers } from "./useUsers";

export const useMultipleUsers = (userIds: List<string>) => {
	const {
		state: { fullUsersState },
		actions: { loadUser }
	} = useUsersContext();
	const users = useUsers();
	useEffect(() => {
		for (const id of userIds) {
			const userShouldNotBeLoaded = fullUsersState?.get(id)?.get("loading") || fullUsersState?.get(id)?.get("hadError");
			if (!users?.has(id) && !userShouldNotBeLoaded) {
				loadUser(id);
			}
		}
	}, [userIds, loadUser, users, fullUsersState]);

	const usersList = useMemo(
		() => userIds.map(id => users?.get(id)).filter(Boolean) as List<UserModel>,
		[users, userIds]
	);
	const usersMap = useMemo(() => Map(usersList.map(item => [item.id, item])), [usersList]);
	return { usersList, usersMap };
};
