import React, { SyntheticEvent, useCallback, useEffect, useMemo, useState } from "react";
import classNames from "classnames";
import { useControlled } from "hooks/useControlled";
import { IBaseInputProps } from "components/ui/Input";
import { notEmpty } from "utils/comparison";
import { HelperText } from "utils/inputsHelpers";
import { Typography } from "../Typography";
import { ResizableInput } from "../ResizableInput";
import { Prefix } from "./Prefix";
import { useStyles } from "./styles";

type TTargetValue = { target: { value: string } };

interface IRenderChipParams<T> {
	noBorder?: boolean;
	option: T;
	onRemove?: () => void;
	componentKey: string;
}

interface IProps {
	defaultValue?: string[]; // default: null. default values.
	disabled?: boolean; // default: false. set to true to disable the input.
	errors?: string[]; // default: null. array of error messages.
	fullWidth?: boolean; // default: false. make input grow as wide as it has.
	hint?: string; // default: null. hint to display below the input
	inputValue?: string; // default: undefined. set input value from props (Controlled)
	label?: string | JSX.Element; // default: "". label for input.
	noCollapse?: boolean; // default: false. set to true to disable collapsing of chips.
	onChange?: (value: string[]) => void; // default: undefined. callback on value change.
	onInputChange?: (event: (React.SyntheticEvent & TTargetValue) | TTargetValue) => void; // default: undefined. callback on input value change.
	placeholder?: string; // default: "". placeholder for the input.
	prefixIcon?: JSX.Element | null; // default: null. icon to display before input.
	renderChip?: (prams: IRenderChipParams<string>) => JSX.Element; // default: null. render function for each chip.
	value?: string[]; // default: undefined. set value from props (Controlled).
	variant?: "box" | "line"; // default: "box". variant of input.
	separate?: boolean; // default: false. separates values display from input
	prefixClassName?: string; // default: "". className for Prefix component
	required?: boolean; // default: false. displays * to indicate field is required
	readonlyValues?: string[];
	chipsLimit?: number;
}

const stopPropagation = (event: React.MouseEvent) => event.stopPropagation();

export function MultipleInput(props: TProps<IProps & Omit<IBaseInputProps, keyof IProps>>) {
	const {
		className,
		defaultValue = undefined,
		disabled = false,
		errors = null,
		fullWidth = false,
		hint,
		inputValue: propInputValue,
		label = "",
		noCollapse = false,
		onChange: propOnChange,
		onInputChange: propOnInputChange,
		placeholder,
		prefixIcon: propPrefixIcon = null,
		renderChip: propRenderChip,
		value: propValue,
		variant = "box",
		id,
		separate,
		prefixClassName,
		required,
		readonlyValues,
		chipsLimit,
		validators,
		onError
	} = props;

	const [errorMessages, setErrorMessages] = useState(errors);
	const [value, setValue] = useControlled<string[]>({
		controlled: propValue,
		default: defaultValue
	});
	const [inputValue, setInputValue] = useControlled<string>({ controlled: propInputValue, default: "" });
	const classes = useStyles({ fullWidth });

	const resetInputValue = useCallback(
		(event?: React.SyntheticEvent | null) => {
			if (inputValue === "") {
				return;
			}
			setInputValue("");

			if (propOnInputChange) {
				propOnInputChange(event ? { ...event, target: { ...event.target, value: "" } } : { target: { value: "" } });
			}
		},
		[inputValue, propOnInputChange, setInputValue]
	);

	const handleValue = useCallback(
		(newValue: string[]) => {
			if (value?.length === newValue?.length && value?.every((val, i) => val === newValue?.[Number(i)])) {
				return;
			}
			if (propOnChange) {
				propOnChange(newValue);
			}
			setValue(newValue);
		},
		[propOnChange, setValue, value]
	);

	const addNewValue = useCallback(
		(event: SyntheticEvent) => {
			const newValue = inputValue?.trim() || "";
			const newValues = value?.slice() || [];

			if (!newValues.some(valueItem => newValue === valueItem)) {
				newValues.push(newValue);
			}
			resetInputValue(event);
			handleValue(newValues);
		},
		[handleValue, inputValue, resetInputValue, value]
	);

	const validate = useCallback(
		(value: string) => {
			const errors = validators?.map(validator => validator(value)).filter(notEmpty) || [];
			return errors?.length ? errors : undefined;
		},
		[validators]
	);

	const resetError = useCallback(() => {
		setErrorMessages(null);
		onError && onError(null);
	}, [onError]);

	const handleInputChange = useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			const newInputValue = event.target.value;

			if (newInputValue !== inputValue) {
				const errors = validate(newInputValue);
				if (errors) {
					setErrorMessages(errors);
					onError && onError(errors);
				} else {
					resetError();
				}
				setInputValue(newInputValue);
				if (propOnInputChange) {
					propOnInputChange(event);
				}
			}
		},
		[inputValue, onError, propOnInputChange, resetError, setInputValue, validate]
	);

	const isError = useMemo(() => {
		return errorMessages ? errorMessages.length > 0 && errorMessages.every(Boolean) : false;
	}, [errorMessages]);

	const onBlur = useCallback(() => {
		resetInputValue();
		resetError();
	}, [resetError, resetInputValue]);

	useEffect(() => {
		resetInputValue(null);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [value]);

	const removeChip = useCallback(
		(option: string) => {
			const newValue = value?.filter(val => val !== option) || [];
			handleValue(newValue);
		},
		[handleValue, value]
	);

	const onKeyDown = useCallback(
		(event: React.KeyboardEvent<HTMLInputElement>) => {
			const value = inputValue?.trim();
			if (event.key === "Enter" && value) {
				const errors = validators?.map(validator => validator(value)).filter(notEmpty);
				if (!errors?.length) {
					addNewValue(event);
				} else {
					onError && onError(errors?.length ? errors : null);
				}
			}
		},
		[addNewValue, inputValue, onError, validators]
	);

	const prefix = useMemo(
		() =>
			value && (
				<Prefix
					getOptionLabel={v => v}
					values={value}
					prefixIcon={propPrefixIcon || undefined}
					noCollapse={noCollapse}
					onRemove={removeChip}
					renderChip={propRenderChip}
					className={prefixClassName}
					readonlyValues={readonlyValues}
					chipsLimit={chipsLimit}
					multiLine
				/>
			),
		[noCollapse, prefixClassName, propPrefixIcon, propRenderChip, removeChip, value, readonlyValues, chipsLimit]
	);

	return (
		<div className={classNames(classes.input, className)} id={id}>
			<Typography
				component="div"
				className={classNames(classes.inputPrefix, prefixClassName)}
				onClick={stopPropagation}>
				<ResizableInput
					disabled={disabled}
					errors={errors || undefined}
					fullWidth={fullWidth}
					hint={hint}
					inputContainerClassName={separate ? undefined : classes.inputContainer}
					label={label}
					onChange={handleInputChange}
					placeholder={value?.length ? undefined : placeholder}
					value={inputValue}
					variant={variant}
					onKeyDown={onKeyDown}
					prefix={separate ? null : prefix}
					isRequired={required}
					onBlur={onBlur}
				/>
				{separate ? prefix : null}
			</Typography>
			<HelperText isError={isError} hint={hint} errorMessages={errorMessages} className={classes.hint} />
		</div>
	);
}
