import classNames from "classnames";
import { useControlled } from "hooks/useControlled";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { IBaseInputProps, Input } from "../Input";
import { useStyles } from "./styles";

interface IProps extends IBaseInputProps {
	minInputWidth?: string;
}
export const ResizableInput: FC<IProps> = ({ minInputWidth, ...inputProps }) => {
	const {
		onKeyUp: propOnKeyUp,
		prefix: propPrefix,
		inputRef: propInputRef,
		inputContainerClassName: propInputContainerClassName,
		placeholder: propPlaceholder,
		value: propValue
	} = inputProps;

	const inputPlaceholder = useMemo(() => propPlaceholder || "", [propPlaceholder]);
	const resizerRef = useRef<HTMLSpanElement>(null);
	const inputRef = useRef(null);
	const [resizingSpanText, setResizingSpanText] = useControlled({
		controlled: propValue || inputPlaceholder,
		default: inputPlaceholder
	});
	const [inputWidth, setInputWidth] = useState(0);
	const classes = useStyles({ inputWidth: inputWidth, minInputWidth });
	const resizer = resizerRef.current;

	const onInputKeyUp = useCallback(
		(event: React.KeyboardEvent<HTMLInputElement>) => {
			propOnKeyUp && propOnKeyUp(event);
			setResizingSpanText(event.currentTarget.value || inputPlaceholder);
		},
		[propOnKeyUp, inputPlaceholder, setResizingSpanText]
	);

	useEffect(() => {
		const newWidthSize = resizer?.scrollWidth || 0;
		if (inputWidth !== newWidthSize) setInputWidth(newWidthSize);
	}, [resizingSpanText, resizer, inputWidth]);

	const prefix = useMemo(() => {
		return (
			<>
				{propPrefix}
				<span className={classes.resizingSpan} ref={resizerRef}>
					{resizingSpanText}
				</span>
			</>
		);
	}, [propPrefix, classes.resizingSpan, resizingSpanText]);

	return (
		<Input
			{...{
				...inputProps,
				inputRef: propInputRef || inputRef,
				prefix,
				onKeyUp: onInputKeyUp,
				inputContainerClassName: classNames(propInputContainerClassName, classes.inputContainer)
			}}
		/>
	);
};
