import React from "react";
import { makeIcon } from "components/ui/Icon";

export const DirectAccessIcon = makeIcon(
	<svg viewBox="0 0 24 24" fill="none">
		<path
			d="M3 11.588C3 11.3047 3.09583 11.0672 3.2875 10.8755C3.47917 10.6838 3.71667 10.588 4 10.588L9.3 10.588H15L17.175 10.588L16.3 9.688C16.1167 9.50467 16.025 9.27133 16.025 8.988C16.025 8.70467 16.1167 8.47133 16.3 8.288C16.5 8.088 16.7375 7.99217 17.0125 8.0005C17.2875 8.00883 17.5167 8.10467 17.7 8.288L20.3 10.888C20.4 10.988 20.4708 11.0963 20.5125 11.213C20.5542 11.3297 20.575 11.4547 20.575 11.588C20.575 11.7213 20.5542 11.8463 20.5125 11.963C20.4708 12.0797 20.4 12.188 20.3 12.288L17.7 14.888C17.5167 15.0713 17.2833 15.163 17 15.163C16.7167 15.163 16.4833 15.0713 16.3 14.888C16.1 14.688 16 14.4505 16 14.1755C16 13.9005 16.0917 13.6713 16.275 13.488L17.175 12.588L4 12.588C3.71667 12.588 3.47917 12.4922 3.2875 12.3005C3.09583 12.1088 3 11.8713 3 11.588Z"
			fill="currentColor"
		/>
	</svg>
);
