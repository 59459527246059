import { List, Map } from "immutable";
import partition from "lodash/partition";
import { useUser } from "hooks/useUser";
import { IntegrationResourceRoleModel } from "models/IntegrationResourceRoleModel";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useSingleUser } from "hooks/useSingleUser";
import { getCommentValidators } from "utils/validationUtils";
import { useIntegrations } from "hooks/useIntegrations";
import { notEmpty } from "utils/comparison";
import { getBackingIntegrationId, getUserIntegrationActor } from "../../../utils/missingActorsUtils";
import type { UserModel } from "models/UserModel";
import type { IntegrationActorModel } from "models/IntegrationActorModel";
import type { TRoleOption, TNewTicketOption } from "../types";
import type { TTicketDuration } from "utils/durationsOptions";

type TIntegrationActorsMap = Map<string, IntegrationActorModel | IntegrationActorModel[]>;

const isRoleConflicted = (
	role: IntegrationResourceRoleModel,
	selectedSingleRoleResourceIds: Set<string>,
	selectedRoleIds: Set<string>
) => !selectedRoleIds.has(role.id) && selectedSingleRoleResourceIds.has(role.integrationResourceId);

export const useNewTicketForm = (receiverUser: UserModel | null) => {
	const integrations = useIntegrations();
	const { user } = useUser();
	const { user: fullReceiverUser, isLoading: fullUserIsLoading } = useSingleUser(receiverUser?.id, true);

	const [selectedItems, setSelectedItems] = useState<List<TNewTicketOption>>(List());
	const [grantMethods, setGrantMethods] = useState<Map<string, IntegrationResourceRoleModel | null>>(Map());
	const [justification, setJustification] = useState("");
	const [connectToThirdParty, setConnectToThirdParty] = useState(false);
	const [ticketingIntegrationTicketId, setTicketingIntegrationTicketId] = useState<string | null>(null);
	const [duration, setDuration] = useState<TTicketDuration | null>();
	const [isValid, setIsValid] = useState<boolean>(false);
	const [receiverIntegrationActorIds, setReceiverIntegrationActorIds] = useState<Map<string, string | null>>(Map());
	const [workflowDisplay, setWorkflowDisplay] = useState(false);
	const justificationValidators = useMemo(() => getCommentValidators("justification"), []);

	const rolesWithMissingGrantMethods = useMemo(() => {
		return selectedItems.filter(item => item.type === "role" && !grantMethods.get(item.value.id)) as List<TRoleOption>;
	}, [grantMethods, selectedItems]);

	const setGrantMethod = useCallback((roleId: string, grantMethod: IntegrationResourceRoleModel | null) => {
		setGrantMethods(prev => prev.set(roleId, grantMethod));
	}, []);

	useEffect(() => {
		setGrantMethods(current =>
			Map(
				selectedItems
					.filter(({ type }) => type === "role")
					.map(({ value }) => [value.id, current.get(value.id) ?? null])
			)
		);
	}, [selectedItems]);

	useEffect(() => {
		setWorkflowDisplay(!!(duration && selectedItems.size && receiverUser));
	}, [setWorkflowDisplay, duration, selectedItems, receiverUser]);

	const integrationActors: TIntegrationActorsMap | null = useMemo(() => {
		if (fullUserIsLoading || !selectedItems || !integrations) return null;
		const selectedUser = fullReceiverUser ?? user;
		let integrationActorsMap: TIntegrationActorsMap = Map();

		for (const option of selectedItems) {
			if (option.type === "role") {
				const integrationId = getBackingIntegrationId(option.value) || "";
				if (!integrationActorsMap.has(option.value.id)) {
					const integrationActor = selectedUser ? getUserIntegrationActor(selectedUser, integrationId) : undefined;
					integrationActorsMap = integrationActorsMap.set(integrationId, integrationActor || []);
				}
			} else if (option.value.bundleItems) {
				for (const bundleItem of option.value.bundleItems) {
					const integrationId = getBackingIntegrationId(bundleItem.integrationResourceRole) || "";
					if (!integrationActorsMap.has(integrationId)) {
						const integrationActor = selectedUser ? getUserIntegrationActor(selectedUser, integrationId) : undefined;
						integrationActorsMap = integrationActorsMap.set(integrationId, integrationActor || []);
					}
				}
			}
		}

		return integrationActorsMap.filterNot(item => Array.isArray(item) && item.length === 0);
	}, [fullUserIsLoading, selectedItems, integrations, fullReceiverUser, user]);

	const setIntegrationActor = useCallback((integrationId: string, integrationActor: IntegrationActorModel) => {
		setReceiverIntegrationActorIds(prev => prev.set(integrationId, integrationActor.id));
	}, []);

	const [multipleActorsOptionIntegrationIds, singleActorOptionIntegrationIds] = useMemo(() => {
		if (!integrationActors || !integrationActors.size || !integrations) return [[], []];
		const [multipleActorsOptionIntegrations, singleActorOptionIntegrations] = partition(
			integrationActors.entrySeq().toArray(),
			([_, value]) => Array.isArray(value) && value.length > 1
		);
		return [multipleActorsOptionIntegrations.map(([id]) => id), singleActorOptionIntegrations.map(([id]) => id)];
	}, [integrationActors, integrations]);

	useEffect(() => {
		if (
			selectedItems.size &&
			!rolesWithMissingGrantMethods.size &&
			duration &&
			justification &&
			receiverUser &&
			!fullUserIsLoading &&
			(!connectToThirdParty || ticketingIntegrationTicketId)
		) {
			if (justificationValidators.some(validate => validate(justification))) {
				setIsValid(false);
			} else if (multipleActorsOptionIntegrationIds.length > 0 && receiverIntegrationActorIds) {
				setIsValid(
					multipleActorsOptionIntegrationIds.every(integrationId => receiverIntegrationActorIds.has(integrationId))
				);
			} else {
				setIsValid(true);
			}
		} else {
			setIsValid(false);
		}
	}, [
		connectToThirdParty,
		duration,
		fullUserIsLoading,
		justification,
		justificationValidators,
		rolesWithMissingGrantMethods,
		multipleActorsOptionIntegrationIds,
		receiverIntegrationActorIds,
		receiverUser,
		selectedItems,
		ticketingIntegrationTicketId
	]);

	useEffect(() => {
		if (integrationActors) {
			setReceiverIntegrationActorIds(previous =>
				previous
					.filter((_, key) => multipleActorsOptionIntegrationIds.includes(key))
					.merge(
						Map(
							singleActorOptionIntegrationIds
								.filter(integrationActors.has.bind(integrationActors))
								.map(id => [id, (integrationActors.get(id)! as IntegrationActorModel).id])
						)
					)
			);
		} else {
			setReceiverIntegrationActorIds(Map());
		}
	}, [
		integrationActors,
		setReceiverIntegrationActorIds,
		singleActorOptionIntegrationIds,
		multipleActorsOptionIntegrationIds
	]);

	const getIsItemConflicted = useCallback(
		(excludedSelectedItemId?: string) => {
			const selectedRoles = selectedItems
				.filter(item => item.value.id !== excludedSelectedItemId)
				.toArray()
				.flatMap(item => {
					if (item.type === "bundle") {
						return item.value.bundleItems.map(bundleItem => bundleItem.integrationResourceRole).toArray();
					} else {
						return grantMethods.get(item.value.id);
					}
				})
				.filter(notEmpty);

			const selectedRoleIds = new Set(selectedRoles.map(role => role.id));

			const selectedSingleRoleResourceIds = new Set(
				selectedRoles
					.map(role => {
						const relevantRole = role.virtualizedRole ?? role;
						return relevantRole.integrationResource?.multirole ? null : relevantRole.integrationResourceId;
					})
					.filter(notEmpty)
			);

			return (item: TNewTicketOption) => {
				if (item.type === "bundle") {
					return item.value.bundleItems.some(({ integrationResourceRole }) =>
						isRoleConflicted(integrationResourceRole, selectedSingleRoleResourceIds, selectedRoleIds)
					);
				} else {
					return isRoleConflicted(item.value, selectedSingleRoleResourceIds, selectedRoleIds);
				}
			};
		},
		[grantMethods, selectedItems]
	);

	const reset = useCallback(() => {
		setSelectedItems(List());
		setDuration(null);
		setTicketingIntegrationTicketId(null);
		setJustification("");
		setWorkflowDisplay(false);
		setConnectToThirdParty(false);
	}, []);

	return {
		state: {
			selectedItems,
			justification,
			duration,
			ticketingIntegrationTicketId,
			integrationActors,
			receiverIntegrationActorIds,
			multipleActorsOptionIntegrationIds,
			isValid,
			workflowDisplay,
			rolesWithMissingGrantMethods,
			connectToThirdParty,
			grantMethods
		},
		actions: {
			reset,
			justificationValidators,
			setIntegrationActor,
			setSelectedItems,
			setJustification,
			setDuration,
			setGrantMethod,
			setGrantMethods,
			setTicketingIntegrationTicketId,
			setWorkflowDisplay,
			setConnectToThirdParty,
			getIsItemConflicted
		}
	};
};
