import { createUseStyles } from "react-jss";

export const ROLE_BAR_HEIGHT_PX = 52;

export const useStyles = createUseStyles({
	outerContainer: {
		height: "100%",
		width: "100%"
	},
	container: {
		alignItems: "center",
		background: "transparent",
		borderRadius: "8px",
		display: "grid",
		gap: "28px",
		height: `${ROLE_BAR_HEIGHT_PX}px`,
		justifyContent: "flex-start",
		minWidth: 0,
		padding: "12px 4px 12px 20px",
		"&:hover:not($noInteraction)": {
			background: "var(--color-purple-50)"
		},
		"&$clickable": {
			cursor: "pointer",
			"&:active": {
				background: "var(--color-purple-100)"
			}
		},
		"&$selected": {
			background: "var(--color-purple-100)"
		}
	},
	clickable: {},
	noInteraction: {},
	selected: {},
	column: {
		alignItems: "center",
		display: "flex",
		gap: "28px",
		justifyContent: "space-between",
		height: "28px"
	}
});
