import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	header: {
		display: "flex",
		flexDirection: "column",
		gap: "0.8rem",
		padding: "1.45rem",
		boxShadow: "0px 1px 0px rgba(0, 0, 0, 0.08)"
	},
	headerStatus: {
		alignItems: "center",
		display: "flex",
		gap: "0.45rem",
		"& > *:first-child": {
			height: "100%"
		}
	},
	headerTitle: {
		alignItems: "center",
		display: "flex",
		flexWrap: "wrap",
		whiteSpace: "pre-wrap"
	},
	ticketPermissionsData: {
		gap: "0.5rem",
		display: "flex",
		flexWrap: "wrap",
		borderTop: "1px solid var(--color-grey-light)",
		padding: "0.2rem 0"
	},
	headerTop: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between"
	},
	headerTopLeft: {
		alignItems: "center",
		display: "flex",
		flexDirection: "row",
		gap: "0.55rem"
	},
	headerTopRightIcon: {
		"&:hover": {
			color: "var(--color-grey-dark)"
		},
		color: "var(--color-grey)",
		fontSize: "1.5rem"
	},
	headerTopTicketCreationDate: {
		color: "var(--color-grey-dark)"
	},
	headerTopTicketNumber: {
		backgroundColor: "var(--color-pink-background)",
		padding: "0.15rem 0.3rem"
	},
	pendingApprovers: {
		display: "flex",
		flexWrap: "wrap",
		flexDirection: "row",
		gap: "0.5rem",
		alignItems: "center"
	},
	approver: {
		height: "1.5rem"
	},
	ticketingIntegrationTicketChip: {
		display: "flex",
		alignItems: "center",
		justifyContent: "start",
		gap: "0.2rem",
		"& div": {
			maxWidth: "240px"
		}
	}
});
