import constate from "constate";
import { List, Map } from "immutable";
import { UserModel } from "models/UserModel";
import { useCallback, useMemo, useState } from "react";
import { useUser } from "hooks/useUser";
import { safeMerge } from "utils/security";
import { useSingleUser } from "hooks/useSingleUser";
import type { TTicketDuration } from "utils/durationsOptions";
import type { TRequestTarget } from "./types";

const useNewRequestForm = () => {
	const { user: currentUser } = useUser();
	const [receiverUser, setReceiverUser] = useState<UserModel | null>(currentUser);
	const { user: fullReceiverUser } = useSingleUser(receiverUser?.id, true);
	const [justification, setJustification] = useState("");
	const [ticketingIntegrationTicketId, setTicketingIntegrationTicketId] = useState<string | null>(null);
	const [duration, setDuration] = useState<TTicketDuration | null>();
	const [requestTargets, setRequestTargets] = useState(List<TRequestTarget>());
	const [receiverIntegrationActors, setReceiverIntegrationActors] = useState(Map<string, string>());

	const changeReceiverUser = useCallback(
		(user: UserModel | null) => {
			setReceiverUser(user ?? currentUser);
			setJustification("");
			setTicketingIntegrationTicketId(null);
			setDuration(null);
			setRequestTargets(List());
		},
		[currentUser]
	);

	const changeJustification = useCallback((justification: string) => {
		setJustification(justification);
	}, []);

	const changeTicketingIntegrationTicketId = useCallback((ticketId: string | null) => {
		setTicketingIntegrationTicketId(ticketId);
	}, []);

	const changeDuration = useCallback((duration: TTicketDuration | null) => {
		setDuration(duration);
	}, []);

	const addTarget = useCallback((target: TRequestTarget) => {
		setRequestTargets(current => current.push(target));
		setDuration(null);
	}, []);

	const removeTarget = useCallback((targetId: string) => {
		let remaining = 0;
		setRequestTargets(current => {
			const remaningTargets = current.filter(target => target.id !== targetId);
			remaining = remaningTargets.size;
			return remaningTargets;
		});
		if (!remaining) {
			setDuration(null);
		}
	}, []);

	const updateTarget = useCallback((targetId: string, updateFields: Partial<TRequestTarget>) => {
		setRequestTargets(current => {
			const targetIndex = current.findIndex(target => target.id === targetId);
			if (targetIndex === -1) return current;
			const currentTarget = current.get(targetIndex)!;
			const mergedTarget = safeMerge(currentTarget, updateFields) as TRequestTarget;
			return current.set(targetIndex, mergedTarget);
		});
		setDuration(null);
	}, []);

	const addReceiverIntegrationActor = useCallback((integrationId: string, actorId: string) => {
		setReceiverIntegrationActors(current => current.set(integrationId, actorId));
	}, []);

	const isFormValid = useMemo(() => {
		return !!fullReceiverUser && !!justification && !!duration && !!requestTargets.size;
	}, [duration, fullReceiverUser, justification, requestTargets.size]);

	return useMemo(
		() => ({
			state: {
				currentUser,
				duration,
				fullReceiverUser,
				isFormValid,
				justification,
				receiverIntegrationActors,
				receiverUser,
				requestTargets,
				ticketingIntegrationTicketId
			},
			actions: {
				addReceiverIntegrationActor,
				addTarget,
				changeDuration,
				changeJustification,
				changeReceiverUser,
				changeTicketingIntegrationTicketId,
				removeTarget,
				updateTarget
			}
		}),
		[
			addReceiverIntegrationActor,
			addTarget,
			changeDuration,
			changeJustification,
			changeReceiverUser,
			changeTicketingIntegrationTicketId,
			currentUser,
			duration,
			fullReceiverUser,
			isFormValid,
			justification,
			receiverIntegrationActors,
			receiverUser,
			removeTarget,
			requestTargets,
			ticketingIntegrationTicketId,
			updateTarget
		]
	);
};

export const [NewRequestFormProvider, useNewRequestFormContext] = constate(useNewRequestForm);
