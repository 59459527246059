import React, { useCallback, useMemo, useState } from "react";
import { Map } from "immutable";
import { useControlled } from "hooks/useControlled";
import { Typography } from "components/ui/Typography";
import classNames from "classnames";
import { useStyles } from "./styles";

interface IProps {
	activeKey?: string;
	defaultActiveKey?: string;
	onChange?: (key: string) => void;
	order?: string[];
}

interface ITabProps {
	disabled?: boolean;
	key: string;
	tab: React.ReactNode;
	suffix?: React.ReactNode;
}

interface IChildTab {
	disabled?: boolean;
	header: React.ReactNode;
	key: string;
	node: React.ReactElement<ITabProps, string | React.JSXElementConstructor<ITabProps>>;
	suffix?: React.ReactNode;
}

const childIsTab = (child: React.ReactNode): child is React.ReactElement<ITabProps> => {
	return child && React.isValidElement(child) && child.key && child.props.tab;
};

const parseTabChildren = (children: React.ReactNode): IChildTab[] => {
	return (
		React.Children.map(children, child => {
			if (childIsTab(child)) {
				return {
					key: String(child.key),
					node: child,
					header: child.props.tab,
					disabled: child.props.disabled,
					suffix: child.props.suffix
				};
			}
			console.warn("Child is not a valid tab.");
			return null;
		})?.filter(Boolean) || []
	);
};

type TTabs = FC<IProps> & { Tab: FC<ITabProps> };

export const Tabs: TTabs = props => {
	const {
		activeKey: propActiveKey,
		className,
		children,
		defaultActiveKey,
		onChange: propOnChange,
		order: propOrder
	} = props;
	const classes = useStyles();

	const tabs: IChildTab[] = useMemo(() => parseTabChildren(children), [children]);

	const order = useMemo(() => {
		return propOrder || tabs?.map(tab => tab.key) || [];
	}, [tabs, propOrder]);

	const [activeKey, setActiveKey] = useControlled<string>({
		controlled: propActiveKey,
		default: defaultActiveKey || order[0]
	});

	const [suffixes, setSuffixes] = useState(Map<string, React.ReactNode>());

	const onChange = useCallback(
		(newKey: string) => {
			if (!newKey) return;

			if (!order.includes(newKey)) {
				console.warn(`Tab with key ${newKey} does not exist.`);
				return;
			}

			setActiveKey(newKey);
			propOnChange && propOnChange(newKey);
		},
		[order, propOnChange, setActiveKey]
	);

	const findTab = useCallback(
		(key: string | null) => {
			if (!key) {
				console.warn(`key does not exist.`);
				return null;
			}
			const tab = tabs.find(t => t.key === key);
			if (!tab) {
				console.warn(`Tab with key ${key} does not exist.`);
				return null;
			}
			return tab;
		},
		[tabs]
	);

	const headerTabs = useMemo(
		() =>
			order.map(key => {
				const headerItem = findTab(key);
				if (!headerItem || !headerItem.header) return null;
				if (headerItem.suffix) setSuffixes(current => current.set(key, headerItem.suffix));
				const changeKey = headerItem.disabled ? undefined : () => onChange(key);
				return (
					<Typography
						relative
						className={classNames(classes.tabHeaderItem, {
							[classes.active]: activeKey === key,
							[classes.disabled]: headerItem.disabled
						})}
						key={`${key}-header`}
						onClick={changeKey}>
						{headerItem.header}
					</Typography>
				);
			}),
		[activeKey, classes, findTab, onChange, order]
	);

	const activeTab = useMemo(() => findTab(activeKey || null), [activeKey, findTab]);

	const activeSuffix = suffixes.get(activeKey || "");

	return (
		<div className={classNames(classes.tabs, className)}>
			<div className={classes.tabHeader}>
				<div className={classes.tabHeaderItems}>{headerTabs}</div>
				{activeSuffix ? <div className={classes.suffix}>{activeSuffix}</div> : null}
			</div>
			<div className={classes.tabBody}>{activeTab && activeTab.node && React.cloneElement(activeTab.node)}</div>
		</div>
	);
};

const Tab: FC<ITabProps> = ({ children, key, className, innerRef, id }) => {
	const classes = useStyles();
	return (
		<div
			id={id}
			ref={innerRef as React.Ref<HTMLDivElement>}
			key={key}
			className={classNames(classes.tabPane, className)}>
			{children}
		</div>
	);
};

Tabs.Tab = Tab;
