import partition from "lodash/partition";
import React, { useMemo } from "react";
import { DirectoryGroup } from "components/common/DirectoryGroup";
import { List } from "components/ui/List";
import { boldComponent } from "i18n";
import { PolicyAuditLogModel } from "models/auditLogs";
import { Trans, useTranslation } from "react-i18next";
import { OnCallIntegrationScheduleLabel } from "components/common/OnCallIntegrationScheduleLabel";
import { AuditLogUser } from "../AuditLogUser";
import { useStyles } from "./styles";
import type { TFunction } from "i18next";
import type { TAuditLogContentComponent } from "./AuditLogContent.types";

const TRANSLATION_PREFIX = "pages.auditLog.auditLogList.policy";

type TRoleData = {
	roleName: string;
	resourceName: string;
	integrationName: string;
};

type TUpdateInfo = {
	added?: string[];
	removed?: string[];
};
type TUpdateRoleInfo = {
	added?: TRoleData[];
	removed?: TRoleData[];
};

type TGroup = { id: string; type: "schedule" | "group"; name: string };

const ADDED_ACTIONS = ["PolicyCreated", "PolicyUpdatedData"];
const REMOVED_ACTIONS = ["PolicyDeleted", "PolicyUpdatedData"];

function checkData(type: "added" | "removed", auditLog: PolicyAuditLogModel, values?: string[] | TRoleData[]) {
	if (!auditLog?.action || !values?.length) return false;
	const actions = type === "added" ? ADDED_ACTIONS : REMOVED_ACTIONS;
	return actions.includes(auditLog.action);
}

function getRolesData(roles: TRoleData[], action: "added" | "removed", t: TFunction) {
	return roles!.map(role => (
		<Trans
			t={t}
			values={{
				roleName: role.roleName || t("common.unknown.role"),
				resourceName: role.resourceName || t("common.unknown.resource"),
				integrationName: role.integrationName || t("common.unknown.integration")
			}}
			i18nKey={`${TRANSLATION_PREFIX}.${action}.roles` as const}
			components={{ bold: boldComponent }}
			key={role.roleName}
		/>
	));
}
function getBundlesData(bundles: string[], action: "added" | "removed", t: TFunction) {
	return bundles.map(bundle => (
		<Trans
			t={t}
			values={{ bundleName: bundle || t("common.unknown.bundle") }}
			i18nKey={`${TRANSLATION_PREFIX}.${action}.bundles` as const}
			components={{ bold: boldComponent }}
			key={bundle}
		/>
	));
}
function getDirectoryGroupsData(directoryGroups: string[], action: "added" | "removed", t: TFunction) {
	return directoryGroups.map(directoryGroup => {
		const directoryGroupName = <DirectoryGroup value={directoryGroup} />;
		return (
			<Trans
				t={t}
				i18nKey={`${TRANSLATION_PREFIX}.${action}.directoryGroups` as const}
				components={{ bold: boldComponent, directoryGroupName }}
				key={directoryGroup}
			/>
		);
	});
}
function getOnCallIntegrationSchedulesData(
	onCallIntegrationSchedules: string[],
	action: "added" | "removed",
	t: TFunction
) {
	return onCallIntegrationSchedules.map(onCallIntegrationSchedule => {
		const onCallIntegrationScheduleName = <OnCallIntegrationScheduleLabel value={onCallIntegrationSchedule} />;
		return (
			<Trans
				t={t}
				i18nKey={`${TRANSLATION_PREFIX}.${action}.onCallIntegrationSchedules` as const}
				components={{ bold: boldComponent, onCallIntegrationScheduleName }}
				key={onCallIntegrationSchedule}
			/>
		);
	});
}
function updatedDataList(
	roles: TUpdateRoleInfo | null,
	bundles: TUpdateInfo | null,
	directoryGroups: TUpdateInfo | null,
	onCallIntegrationSchedules: TUpdateInfo | null,
	auditLog: PolicyAuditLogModel,
	t: TFunction
) {
	let allData: (JSX.Element | string)[] = [];
	if (checkData("added", auditLog, roles?.added)) {
		allData = allData.concat(getRolesData(roles!.added!, "added", t));
	}
	if (checkData("added", auditLog, bundles?.added)) {
		allData = allData.concat(getBundlesData(bundles!.added!, "added", t));
	}
	if (checkData("added", auditLog, directoryGroups?.added)) {
		allData = allData.concat(getDirectoryGroupsData(directoryGroups!.added!, "added", t));
	}
	if (checkData("added", auditLog, onCallIntegrationSchedules?.added)) {
		allData = allData.concat(getOnCallIntegrationSchedulesData(onCallIntegrationSchedules!.added!, "added", t));
	}
	if (checkData("removed", auditLog, roles?.removed)) {
		allData = allData.concat(getRolesData(roles!.removed!, "removed", t));
	}
	if (checkData("removed", auditLog, bundles?.removed)) {
		allData = allData.concat(getBundlesData(bundles!.removed!, "removed", t));
	}
	if (checkData("removed", auditLog, directoryGroups?.removed)) {
		allData = allData.concat(getDirectoryGroupsData(directoryGroups!.removed!, "removed", t));
	}
	if (checkData("removed", auditLog, onCallIntegrationSchedules?.removed)) {
		allData = allData.concat(getOnCallIntegrationSchedulesData(onCallIntegrationSchedules!.removed!, "removed", t));
	}
	return <List items={allData} />;
}

function getChangedData(log: PolicyAuditLogModel) {
	if (log.action === "PolicyCreated") {
		const groups = log.data?.get("groups") as TGroup[];
		const [directoryGroups, onCallIntegrationSchedules] = partition(groups, group => group.type === "group").map(
			groups => groups.map(({ name }) => name)
		);
		return {
			addedRoles: log.data?.get("roles") as TRoleData[],
			deletedRoles: undefined,
			addedBundles: (log.data?.get("bundles") as { name: string }[])?.map(({ name }) => name),
			deletedBundles: undefined,
			addedDirectoryGroups: directoryGroups,
			deletedDirectoryGroups: undefined,
			addedOnCallIntegrationSchedules: onCallIntegrationSchedules,
			deletedOnCallIntegrationSchedules: undefined
		};
	}

	if (log.action === "PolicyDeleted") {
		const roles = log.data?.get("roles") as TUpdateRoleInfo;
		const directoryGroups = log.data?.get("directoryGroups") as TUpdateInfo;
		const onCallIntegrationSchedules = log.data?.get("onCallIntegrationSchedules") as TUpdateInfo;
		const bundles = log.data?.get("bundles") as TUpdateInfo;
		return {
			addedRoles: roles.added,
			deletedRoles: roles.removed,
			addedBundles: bundles.added,
			deletedBundles: bundles.removed,
			addedDirectoryGroups: directoryGroups.added,
			deletedDirectoryGroups: directoryGroups.removed,
			addedOnCallIntegrationSchedules: onCallIntegrationSchedules.added,
			deletedOnCallIntegrationSchedules: onCallIntegrationSchedules.removed
		};
	}

	if (log.action === "PolicyUpdatedData") {
		const addedGroups = (log.data?.get("addedGroups") as TGroup[] | undefined) ?? [];
		const deletedGroups = (log.data?.get("deletedGroups") as TGroup[] | undefined) ?? [];
		const [addedDirectoryGroups, addedOnCallIntegrationSchedules] = partition(
			addedGroups,
			group => group.type === "group"
		).map(groups => groups.map(({ name }) => name));
		const [deletedDirectoryGroups, deletedOnCallIntegrationSchedules] = partition(
			deletedGroups,
			group => group.type === "group"
		).map(groups => groups.map(({ name }) => name));

		return {
			addedDirectoryGroups,
			deletedDirectoryGroups,
			addedOnCallIntegrationSchedules,
			deletedOnCallIntegrationSchedules,
			addedRoles: log.data?.get("addedRoles") as TRoleData[],
			deletedRoles: log.data?.get("deletedRoles") as TRoleData[],
			addedBundles: (log.data?.get("addedBundles") as { id: string; name: string }[] | undefined)?.map(
				({ name }) => name
			),
			deletedBundles: (log.data?.get("deletedBundles") as { id: string; name: string }[] | undefined)?.map(
				({ name }) => name
			)
		};
	}

	return {};
}

export const PolicyAuditLogContent: TAuditLogContentComponent<PolicyAuditLogModel> = ({ auditLog, logUser }) => {
	const { t } = useTranslation();
	const classes = useStyles();
	const policyNumber = (auditLog.data && auditLog.data?.get("number")) ?? 0;
	const sortOrder = (auditLog.action === "PolicyUpdatedOrder" && auditLog.data?.get("toSortOrder")) ?? 0;

	const dataList = useMemo(() => {
		const {
			addedBundles,
			addedDirectoryGroups,
			addedOnCallIntegrationSchedules,
			addedRoles,
			deletedBundles,
			deletedDirectoryGroups,
			deletedOnCallIntegrationSchedules,
			deletedRoles
		} = getChangedData(auditLog);
		return updatedDataList(
			{ added: addedRoles, removed: deletedRoles },
			{ added: addedBundles, removed: deletedBundles },
			{ added: addedDirectoryGroups, removed: deletedDirectoryGroups },
			{ added: addedOnCallIntegrationSchedules, removed: deletedOnCallIntegrationSchedules },
			auditLog,
			t
		);
	}, [auditLog, t]);

	const components = useMemo(
		() => ({ bold: boldComponent, user: <AuditLogUser user={logUser} className={classes.userText} />, dataList }),
		[classes.userText, dataList, logUser]
	);

	const values = useMemo(() => ({ policyNumber, sortOrder }), [policyNumber, sortOrder]);

	return (
		<Trans
			t={t}
			values={values}
			components={components}
			i18nKey={`${TRANSLATION_PREFIX}.${auditLog.action}` as const}
		/>
	);
};
