import React, { useState, useCallback, useEffect, RefObject, useRef, useMemo } from "react";
import classNames from "classnames";
import { Typography } from "components/ui/Typography";
import { useControlled } from "hooks/useControlled";
import { HelperText, Suffix } from "utils/inputsHelpers";
import { useStyles } from "./styles";

export interface IInputState {
	dirty: boolean;
	focused: boolean;
	touched: boolean;
}
export interface IBaseInputProps {
	autoFocus?: boolean;
	defaultValue?: string;
	dirty?: boolean;
	disabled?: boolean;
	error?: boolean;
	errors?: string[];
	focused?: boolean;
	fullWidth?: boolean;
	hint?: string;
	inputContainerClassName?: string;
	inputRef?: React.Ref<HTMLInputElement>;
	isRequired?: boolean;
	label?: React.ReactNode;
	noBackground?: boolean;
	noBorder?: boolean;
	noPadding?: boolean;
	onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
	onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
	onClick?: (event: React.MouseEvent<HTMLInputElement>) => void;
	onError?: (errors: string[] | null) => void;
	onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
	onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
	onKeyUp?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
	onMouseDown?: (event: React.MouseEvent<HTMLInputElement>) => void;
	onStateChange?: (state: IInputState) => void;
	onValueChange?: (value: string) => void;
	placeholder?: string;
	prefix?: React.ReactNode;
	readonly?: boolean;
	renderInput?: (
		props: React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>
	) => React.ReactNode;
	showInput?: boolean;
	slimPadding?: boolean;
	small?: boolean;
	suffix?: React.ReactNode;
	touched?: boolean;
	type?: "text" | "password" | "email" | "number" | "tel" | "url";
	validators?: Array<(value: string) => string | null>;
	value?: string | null;
	variant?: "line" | "box" | "inline";
}
export const Input: FC<IBaseInputProps> = ({
	autoFocus = false,
	className,
	defaultValue = "",
	dirty = false,
	disabled,
	errors: userErrors = null,
	focused = false,
	fullWidth = false,
	hint,
	id,
	innerRef,
	inputContainerClassName,
	inputRef: propInputRef,
	isRequired,
	label,
	noBackground = false,
	noBorder = false,
	noPadding = false,
	onBlur: userOnBlur,
	onChange: userOnChange,
	onClick: userOnClick,
	onError: userOnError,
	onFocus: userOnFocus,
	onKeyDown: userOnKeyDown,
	onKeyUp: userOnKeyUp,
	onMouseDown: userOnMouseDown,
	onStateChange: userOnStateChange,
	onValueChange: userOnValueChange,
	placeholder,
	prefix = null,
	readonly = false,
	renderInput = null,
	showInput = true,
	slimPadding = false,
	small,
	suffix = null,
	touched = false,
	type = "text",
	validators = null,
	value: userValue,
	variant = "box"
}) => {
	const classes = useStyles({ fullWidth, slimPadding });
	const [inputValue, setInputValue] = useControlled<string>({
		controlled: userValue,
		default: defaultValue
	});
	const [isDirty, setIsDirty] = useState(dirty);
	const [isTouched, setIsTouched] = useState(touched);
	const [isFocused, setIsFocused] = useState(focused);
	const [isError, setIsError] = useState(userErrors ? userErrors.length > 0 && userErrors.every(Boolean) : undefined);
	const [errorMessages, setErrorMessages] = useState(userErrors);
	const fallbackRef = useRef(null);
	const inputRef = useMemo(() => propInputRef || fallbackRef, [propInputRef]);

	useEffect(() => {
		setIsError(userErrors ? userErrors.length > 0 && userErrors.every(Boolean) : undefined);
		setErrorMessages(userErrors);
	}, [userErrors]);

	const handleStateChange = useCallback(() => {
		if (userOnStateChange) {
			userOnStateChange({
				dirty: isDirty,
				focused: isFocused,
				touched: isTouched
			});
		}
	}, [isDirty, isTouched, isFocused, userOnStateChange]);

	useEffect(() => handleStateChange, [handleStateChange]);

	const validate = useCallback(
		(value: string) => {
			const errors =
				validators?.map(validator => validator(value)).filter((err): err is string => typeof err === "string") || [];
			return errors?.length ? errors : undefined;
		},
		[validators]
	);

	const onFocus = useCallback(
		(event?: React.FocusEvent<HTMLInputElement>) => {
			setIsFocused(true);
			setIsTouched(true);
			if (userOnFocus && event) userOnFocus(event);
		},
		[userOnFocus]
	);

	const onInputContainerFocus = useCallback(() => {
		const element = (inputRef as RefObject<HTMLInputElement>)?.current;
		if (element) {
			element.focus();
		}
	}, [inputRef]);

	const onBlur = useCallback(
		(event: React.FocusEvent<HTMLInputElement>) => {
			setIsFocused(false);
			if (userOnBlur) userOnBlur(event);
		},
		[userOnBlur]
	);

	const onChange = useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			const newInputValue = event.target.value;
			const errors = validate(newInputValue);
			setIsDirty(true);
			if (errors) {
				setIsError(true);
				setErrorMessages(errors);
				userOnError && userOnError(errors);
			} else {
				setIsError(false);
				setErrorMessages(null);
				userOnError && userOnError(null);
			}

			setInputValue(newInputValue);
			if (userOnValueChange) {
				userOnValueChange(newInputValue);
			}
			if (userOnChange) {
				userOnChange(event);
			}
		},
		[validate, setInputValue, userOnValueChange, userOnChange, userOnError]
	);

	useEffect(() => {
		setIsFocused(focused);
		setIsTouched(touched);
		setIsDirty(dirty);
	}, [focused, dirty, touched]);

	return (
		<div className={classNames(classes.input, className)} ref={innerRef as React.Ref<HTMLDivElement>}>
			{label && (
				<Typography variant="small" relative className={classes.label}>
					{label}
					{isRequired ? "*" : ""}
				</Typography>
			)}
			<div
				className={classNames(classes.inputContainer, inputContainerClassName, variant, {
					disabled,
					error: isError,
					focused: isFocused && !disabled,
					prefix: !!prefix && showInput,
					small,
					[classes.noBorder]: noBorder,
					[classes.noPadding]: noPadding,
					[classes.noBackground]: noBackground
				})}
				onFocus={onInputContainerFocus}
				onClick={onInputContainerFocus}>
				{prefix}
				{renderInput ? (
					renderInput({
						id,
						autoFocus,
						className: classNames(classes.inputHTMLComponent, { hide: !showInput, small }),
						disabled,
						onFocus,
						onBlur,
						onChange,
						onKeyDown: userOnKeyDown,
						onKeyUp: userOnKeyUp,
						onMouseDown: userOnMouseDown,
						ref: inputRef,
						required: isRequired,
						spellCheck: false,
						type: type === "password" ? "password" : "text",
						value: inputValue || ""
					})
				) : (
					<input
						id={id}
						autoFocus={autoFocus}
						className={classNames(classes.inputHTMLComponent, { hide: !showInput, small })}
						disabled={disabled}
						onBlur={onBlur}
						onChange={onChange}
						onFocus={onFocus}
						onKeyDown={userOnKeyDown}
						onKeyUp={userOnKeyUp}
						onMouseDown={userOnMouseDown}
						placeholder={placeholder}
						readOnly={readonly}
						ref={inputRef}
						required={isRequired}
						spellCheck={false}
						type={type === "password" ? "password" : "text"}
						value={inputValue || ""}
					/>
				)}
				<Suffix suffix={suffix} isError={isError} errorClassName={classes.errorIcon} />
			</div>
			<HelperText isError={isError} hint={hint} errorMessages={errorMessages} className={classes.hint} />
		</div>
	);
};
