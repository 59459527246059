import addFormats, { FormatName } from "ajv-formats";
import Ajv from "ajv";

const formats: FormatName[] = [
	"date-time",
	"time",
	"date",
	"email",
	"hostname",
	"ipv4",
	"ipv6",
	"uri",
	"uri-reference",
	"uuid",
	"uri-template",
	"json-pointer",
	"relative-json-pointer",
	"regex"
];

export const generateAjv = () =>
	addFormats(new Ajv({}), { formats }).addKeyword("kind").addKeyword("modifier").addKeyword("name");
