import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { UserOutlineIcon } from "components/ui/Icons/UserOutlineIcon";
import { PendingIcon } from "components/ui/Icons/PendingIcon";
import { ApprovalAlgorithmRuleModel } from "models/ApprovalAlgorithmRuleModel";
import { useDirectoryGroups } from "hooks/useDirectoryGroups";
import { useOnCallIntegrationSchedules } from "hooks/useOnCallIntegrationSchedules";
import { TOnCallType } from "models/OnCallIntegrationScheduleModel";
import { CalendarIcon } from "components/ui/Icons/CalendarIcon";
import { ApprovalAlgorithmEntity } from "../ApprovalAlgorithmEntity";
import { FlowBlock } from "../FlowBlock";
import { useStyles } from "./styles";

interface IProps {
	rule: ApprovalAlgorithmRuleModel;
}

export const AccessRequest: FC<IProps> = ({ rule, className }) => {
	const { t } = useTranslation();
	const classes = useStyles();
	const directoryGroups = useDirectoryGroups();
	const onCallIntegrationSchedules = useOnCallIntegrationSchedules();

	const groupNames: {
		name: string;
		tooltip?: string;
		type: "directoryGroup";
		isDeleted?: boolean;
	}[] = useMemo(() => {
		return (
			directoryGroups
				?.filter(({ id }) => (rule?.directoryGroupIds || []).includes(id))
				.map(({ name, email, isDeleted }) => ({
					name,
					tooltip: email || undefined,
					isDeleted,
					type: "directoryGroup" as const
				}))
				.toArray() || []
		);
	}, [directoryGroups, rule?.directoryGroupIds]);

	const schedules: {
		name: string;
		isDeleted?: boolean;
		onCallScheduleType?: TOnCallType;
		type: "onCallIntegrationSchedule";
	}[] = useMemo(() => {
		return rule?.anyOnCallIntegrationSchedules
			? [
					{
						name: t("flow.anyOnCallIntegrationSchedulesOption"),
						isDeleted: false,
						type: "onCallIntegrationSchedule" as const
					}
				]
			: onCallIntegrationSchedules
					?.valueSeq()
					?.filter(({ id }) => rule?.onCallIntegrationScheduleIds?.includes(id))
					.map(({ name, isDeleted, type }) => ({
						name,
						isDeleted,
						onCallScheduleType: type,
						type: "onCallIntegrationSchedule" as const
					}))
					.toArray() || [];
	}, [onCallIntegrationSchedules, rule?.onCallIntegrationScheduleIds, rule?.anyOnCallIntegrationSchedules, t]);

	const requester = groupNames && groupNames.length > 0 ? groupNames : t("flow.anyUser");

	return (
		<FlowBlock label={t("flow.accessRequest")} className={className}>
			<div className={classes.accessRequestContainer}>
				<UserOutlineIcon />
				{t("flow.inGroup")}
				<ApprovalAlgorithmEntity value={requester} />
			</div>
			{onCallIntegrationSchedules ? (
				<div className={classes.accessRequestContainer}>
					<CalendarIcon />
					{t("flow.onCallIntegrationSchedule")}
					<ApprovalAlgorithmEntity value={schedules?.length ? schedules : t("flow.noOnCalls")} />
				</div>
			) : null}
			<div className={classes.accessRequestContainer}>
				<PendingIcon />
				{t("flow.underDuration")}
				<ApprovalAlgorithmEntity
					value={rule.duration ? (t(`common.durations.${rule.duration}`) as string) : t("flow.anyDuration")}
				/>
			</div>
		</FlowBlock>
	);
};
