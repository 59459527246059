import React, { useMemo } from "react";
import { useDirectoryGroupIcon } from "hooks/useDirectoryGroupIcon";

import { useDirectoryGroups } from "hooks/useDirectoryGroups";
import { Typography } from "components/ui/New/Typography";
import { TooltipOnOverflow } from "components/ui/New/TooltipOnOverflow";
import { useOnCallIntegrationSchedules } from "hooks/useOnCallIntegrationSchedules";
import { ON_CALL_INTEGRATIONS_LOGOS } from "utils/onCall";
import { EntityNode, type TEntityNodeColor } from "components/common/Nodes/EntityNode";
import { GroupIcon } from "components/ui/Icons/GroupIcon";
import { GroupTooltipContent, TStatus } from "./GroupTooltipContent";
import { useStyles } from "./styles";

import type { List } from "immutable";

interface IProps {
	name: string;
	status: TStatus;
	responderIds?: List<string>;
	approverIds: List<string>;
}

const DEFAULT_COLOR = "magenta" as TEntityNodeColor;
const COLOR_BY_STATUS = new Map<TStatus, TEntityNodeColor>([
	["approved", "green"],
	["declined", "red"],
	["pending", "magenta"]
]);

export const GroupCard: FC<Omit<IProps, "id"> & { icon?: React.ReactNode }> = ({
	name,
	status,
	approverIds,
	icon,
	responderIds
}) => {
	const classes = useStyles();

	const groupColor = COLOR_BY_STATUS.get(status) ?? DEFAULT_COLOR;

	return (
		<GroupTooltipContent
			approverIds={approverIds}
			responderIds={responderIds}
			status={status}
			title={<TooltipOnOverflow textVariant="text_sm_sb" content={name} />}>
			<EntityNode
				content={name}
				icon={icon ?? <GroupIcon className={classes.icon} />}
				className={classes.cardContainer}
				selected={status !== "pending"}
				color={groupColor}
			/>
		</GroupTooltipContent>
	);
};

export const DirectoryGroupCard: FC<IProps & { id: string }> = ({ name, status, approverIds, responderIds, id }) => {
	const directoryGroups = useDirectoryGroups();
	const directoryGroup = useMemo(() => directoryGroups?.find(group => group.id === id), [directoryGroups, id]);
	const { name: directoryGroupName, Icon } = useDirectoryGroupIcon(name);
	const classes = useStyles();

	const icon = useMemo(
		() => (Icon ? <Icon className={classes.icon} /> : <GroupIcon className={classes.icon} />),
		[Icon, classes.icon]
	);
	const email = directoryGroup?.email;

	const content = useMemo(() => {
		return (
			<div className={classes.directoryNameWithEmail}>
				<Typography variant="text_sm_sb">{directoryGroupName}</Typography>
				{Boolean(email) && <Typography variant="text_tny_reg">{email}</Typography>}
			</div>
		);
	}, [classes.directoryNameWithEmail, email, directoryGroupName]);

	const groupColor = COLOR_BY_STATUS.get(status) ?? DEFAULT_COLOR;

	return (
		<GroupTooltipContent
			approverIds={approverIds}
			responderIds={responderIds}
			status={status}
			title={
				<div className={classes.directoryGroupTooltipTitle}>
					<TooltipOnOverflow textVariant="body_sb" content={directoryGroupName} />
					{Boolean(email) && <TooltipOnOverflow textVariant="text_sm_reg" content={email} />}
				</div>
			}>
			<EntityNode
				content={content}
				icon={icon}
				className={classes.cardContainer}
				selected={status !== "pending"}
				color={groupColor}
			/>
		</GroupTooltipContent>
	);
};

export const OnCallGroupCard: FC<IProps & { id: string }> = ({ id, ...props }) => {
	const classes = useStyles();
	const onCallScheduleGroups = useOnCallIntegrationSchedules();
	const schedule = onCallScheduleGroups?.get(id);
	const IconComponent = schedule?.type && ON_CALL_INTEGRATIONS_LOGOS.get(schedule.type);

	const icon = useMemo(
		() => (IconComponent ? <IconComponent className={classes.icon} /> : null),
		[IconComponent, classes.icon]
	);

	return <GroupCard {...props} icon={icon} />;
};
