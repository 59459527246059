import React, { useCallback, useMemo } from "react";
import classNames from "classnames";
import { LoadingDots } from "components/ui/LoadingDots";
import { Typography } from "components/ui/Typography";
import { useAccessReviewReport } from "hooks/useAccessReviewReport";
import { AccessReviewResourceModel } from "models/AccessReviewResourceModel";
import { useTranslation } from "react-i18next";
import { DoneCircleIcon } from "components/ui/Icons/DoneCircleIcon";
import { CloseCircleIcon } from "components/ui/Icons/CloseCircleIcon";
import { Table } from "components/ui/Table";
import { PageSelect } from "components/ui/PageSelect";
import { useIntegrations } from "hooks/useIntegrations";
import { ASC, usePagination } from "hooks/usePagination";
import { getResources } from "api/accessReviewsReport";
import { IPaginatedSearchOptions } from "utils/searchUtils";
import { ResourceName } from "../ResourceName";
import { useStyles } from "./styles";

interface IProps {
	reportId: string;
	onViewClick: (resource: AccessReviewResourceModel | null) => void;
	showTitle?: boolean;
}

const PAGE_SIZE = 9;

export const ResourcesReviewTable: FC<IProps> = ({ className, id, innerRef, reportId, onViewClick, showTitle }) => {
	const classes = useStyles();
	const { t } = useTranslation();
	const { accessReviewReport, getActionLoading } = useAccessReviewReport(reportId);
	const integrations = useIntegrations();

	const fetchResources = useCallback(
		(paginationOptions: IPaginatedSearchOptions) => {
			return getResources(reportId, paginationOptions);
		},
		[reportId]
	);

	const { totalPages, currentPageNumber, page, changePage } = usePagination(
		fetchResources,
		PAGE_SIZE,
		ASC,
		undefined,
		null
	);

	const sortedResources = useMemo(
		() =>
			page
				?.sortBy(
					({ integrationResourceId, integrationResource }) => integrationResource?.integrationId + integrationResourceId
				)
				.toArray() || [],
		[page]
	);

	const headers: string[] = [
		t("common.resourcesReviewTable.columns.resource"),
		t("common.resourcesReviewTable.columns.numberOfEntitlements"),
		t("common.resourcesReviewTable.columns.numberOfApproved"),
		t("common.resourcesReviewTable.columns.numberOfDenied"),
		t("common.resourcesReviewTable.columns.status")
	];

	if (!accessReviewReport || !integrations) {
		return getActionLoading ? <LoadingDots center /> : null;
	}
	if (!sortedResources || sortedResources.length === 0) {
		return null;
	}
	return (
		<div className={classNames(classes.container, className)} id={id} ref={innerRef}>
			{showTitle && (
				<Typography variant="h3">
					{t("common.resourcesReviewTable.title", { count: sortedResources.length })}
				</Typography>
			)}
			<>
				<Table outline gridColumns="3fr repeat(4, 2fr)" overflow>
					<Table.Row>
						{headers.map(title => (
							<Table.Header key={title}>
								<Typography>{title}</Typography>
							</Table.Header>
						))}
					</Table.Row>
					{sortedResources.map(resource => {
						const integration = integrations.get(resource.integrationResource?.integrationId || "");
						const amountApproved = resource.statistics?.permissionsStatus?.approved;
						const amountDenied = resource.statistics?.permissionsStatus?.denied;
						const onClick = () => onViewClick(resource);
						return (
							<Table.Row key={resource.id} onClick={onClick}>
								<Table.Cell>
									<ResourceName
										imageUrl={integration?.imageUrl}
										integrationName={integration?.name}
										name={resource.integrationResource?.displayName}
										deleted={resource.integrationResource?.isDeleted}
									/>
								</Table.Cell>
								<Table.Cell>
									<Typography>{resource.permissionsSize}</Typography>
								</Table.Cell>
								<Table.Cell>
									<Typography className={classes.statistics}>
										{amountApproved ? (
											<>
												<DoneCircleIcon />
												{amountApproved}
											</>
										) : (
											"-"
										)}
									</Typography>
								</Table.Cell>
								<Table.Cell>
									<Typography className={classes.statistics}>
										{amountDenied ? (
											<>
												<CloseCircleIcon />
												{amountDenied}
											</>
										) : (
											"-"
										)}
									</Typography>
								</Table.Cell>
								<Table.Cell>
									<Typography>
										{t(`common.resourcesReviewTable.resourceStatuses.${resource.status || "pending"}`)}
									</Typography>
								</Table.Cell>
							</Table.Row>
						);
					})}
				</Table>
				{totalPages > 1 && (
					<PageSelect
						pagesCount={totalPages}
						pagesShownAmount={3}
						currentPageNumber={currentPageNumber}
						changePage={num => changePage(num - 1)}
					/>
				)}
			</>
		</div>
	);
};
