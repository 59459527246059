/* eslint-disable import/order */
import "./utils/devtools/wdyr";
import "./utils/devtools/useWhatChanged";
import React from "react";
import { createRoot } from "react-dom/client";
import App from "components/App";
import { logrocketInit } from "utils/logrocket";

import "./i18n";
import "./index.css";

// support HMR
// @ts-ignore
if (typeof module != "undefined" && module && module.hot && module.hot.accept) {
	// @ts-ignore
	module.hot.accept();
}

logrocketInit();
const rootElement = document.getElementById("app");
createRoot(rootElement!).render(React.createElement(App));
