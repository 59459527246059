import React, { useCallback, useEffect, useMemo, useState } from "react";
import classNames from "classnames";
import xor from "lodash/xor";
import { Trans, useTranslation } from "react-i18next";
import { PageTemplate } from "components/templates/PageTemplate";
import { useAccessReviewTemplatesContext } from "context/accessReviewTemplatesContext";
import { AccessReviewTemplateProvider, useAccessReviewTemplateContext } from "context/accessReviewTemplateContext";
import { LoadingDots } from "components/ui/LoadingDots";
import {
	AccessReviewTemplateModel,
	TAccessReviewTemplateReviewers,
	TFilterType,
	TFullTAccessReviewTemplateModel
} from "models/AccessReviewTemplateModel";
import { List } from "immutable";
import { RadioInputGroup } from "components/ui/RadioInputGroup";
import { Form } from "components/ui/Form";
import { Input } from "components/ui/Input";
import { Button } from "components/ui/Button";
import { AccessReviewTemplateIntegrationRuleModel } from "models/AccessReviewTemplateIntegrationRuleModel";
import { AccessReviewTemplateResourceRuleModel } from "models/AccessReviewTemplateResourceRuleModel";
import { Typography } from "components/ui/Typography";
import { useNavigate, useParams } from "react-router-dom";
import { EditableInput } from "components/common/EditableInput";
import { GrantedIcon } from "components/ui/Icons/GrantedIcon";
import { Separator } from "components/ui/Separator";
import { useUsers } from "hooks/useUsers";
import { useUser } from "hooks/useUser";
import { TextUser } from "components/common/TextUser";
import { useIntegrations } from "hooks/useIntegrations";
import { DeleteIcon } from "components/ui/Icons/DeleteIcon";
import { Checkbox } from "components/ui/Checkbox";
import useIsOpenState from "hooks/useIsOpenState";
import { AreYouSureModal } from "components/common/AreYouSureModal";
import { useOpenGlobalErrorModal } from "hooks/useGlobalError";
import { TextAreaInput } from "components/ui/TextAreaInput";
import { DESCRIPTION_MAX_LENGTH } from "utils/accessReview";
import { ImmediateRevokeCheckbox } from "components/common/ImmediateRevokeCheckbox";
import { GroupsSelectInput } from "components/common/GroupsSelectInput";
import { useCompany } from "hooks/useCompany";
import { Breadcrumbs } from "components/common/Breadcrumbs";
import { useStyles } from "./styles";
import { TreeNode } from "./components/TreeNode";
import { ReviewerType } from "./components/ReviewerType";

const ROOT_PATH: string[] = [];

const AccessReviewTemplatePage: FC = ({ className }) => {
	const { t } = useTranslation();
	const classes = useStyles();

	const defaultTemplate = useMemo(
		() =>
			new AccessReviewTemplateModel({
				groupFilterType: "exclude",
				groupIds: [],
				includeUserless: true,
				integrationFilterType: "exclude",
				integrationIds: [],
				integrationRules: List<AccessReviewTemplateIntegrationRuleModel>(),
				name: t("pages.accessReviewTemplate.newTemplate"),
				resourceRules: List<AccessReviewTemplateResourceRuleModel>(),
				reviewerTypes: ["manager", "resourceOwner"],
				immediateRevoke: false
			}) as TFullTAccessReviewTemplateModel,
		[t]
	);

	const params = useParams<{ templateId: string }>();
	const navigate = useNavigate();
	const areYouSureModal = useIsOpenState();
	const openErrorModal = useOpenGlobalErrorModal();

	const {
		state: { fullAccessReviewTemplates },
		actions: {
			createAccessReviewTemplate,
			editAccessReviewTemplate,
			deleteAccessReviewTemplate,
			getAccessReviewTemplate,
			loadAccessReviewTemplates
		}
	} = useAccessReviewTemplatesContext();
	const {
		state,
		actions: { setInitialState }
	} = useAccessReviewTemplateContext();
	const users = useUsers();
	const { user } = useUser();
	const integrations = useIntegrations();
	const company = useCompany();

	const originalTemplate = params.templateId ? fullAccessReviewTemplates.get(params.templateId) : null;

	const [isTemplateLoading, setIsTemplateLoading] = useState(false);
	const [isSubmitLoading, setIsSubmitLoading] = useState(false);
	const [isDeleting, setIsDeleting] = useState(false);
	const [currentTemplate, setCurrentTemplate] = useState<AccessReviewTemplateModel>(
		originalTemplate || defaultTemplate
	);
	const withResources = currentTemplate.reviewerTypes.includes("resourceOwner");
	const disableImmediateRevokeOverride = useMemo(
		() => !!(company && company.forceAccessReviewsImmediateRevoke),
		[company]
	);

	useEffect(() => {
		if (params.templateId && !isTemplateLoading) {
			const newCurrentTemplate = fullAccessReviewTemplates.get(params.templateId) || null;

			if (!newCurrentTemplate && !isDeleting) {
				setIsTemplateLoading(true);

				getAccessReviewTemplate(params.templateId)
					.catch(error => openErrorModal(error as Error))
					.finally(() => setIsTemplateLoading(false));
			}
		}
	}, [
		defaultTemplate,
		fullAccessReviewTemplates,
		getAccessReviewTemplate,
		isDeleting,
		isTemplateLoading,
		openErrorModal,
		params.templateId,
		setInitialState
	]);

	useEffect(() => {
		if (params.templateId) {
			const newCurrentTemplate = fullAccessReviewTemplates.get(params.templateId);
			if (newCurrentTemplate) {
				setCurrentTemplate(newCurrentTemplate);
				setInitialState(
					newCurrentTemplate.integrationFilterType,
					newCurrentTemplate.integrationIds,
					newCurrentTemplate.integrationRules,
					newCurrentTemplate.resourceRules
				);
			}
		}
	}, [params.templateId, fullAccessReviewTemplates, setInitialState]);

	useEffect(() => {
		if (disableImmediateRevokeOverride && !params.templateId)
			setCurrentTemplate(current => current.set("immediateRevoke", true));
	}, [disableImmediateRevokeOverride, company, params.templateId]);

	const groupFilterTypeOptions = useMemo(
		() => [
			{ value: "exclude" as TFilterType, label: t("pages.accessReviewTemplate.excludeGroups") },
			{ value: "include" as TFilterType, label: t("pages.accessReviewTemplate.includeGroups") }
		],
		[t]
	);

	const rename = useCallback(async (name: string) => {
		setCurrentTemplate(current => current.set("name", name));
	}, []);

	const updateDescription = useCallback(async (description: string) => {
		setCurrentTemplate(current => current.set("description", description));
	}, []);

	const changeImmediateRevoke = useCallback(async (immediateRevoke: boolean) => {
		setCurrentTemplate(current => current.set("immediateRevoke", immediateRevoke));
	}, []);

	const submit = useCallback(async () => {
		const currentTemplateWithRules = new AccessReviewTemplateModel({
			description: currentTemplate.description,
			groupFilterType: currentTemplate.groupFilterType,
			groupIds: currentTemplate.groupIds,
			id: currentTemplate.id,
			includeUserless: currentTemplate.includeUserless,
			integrationFilterType: state.integrationsFilterType,
			integrationIds: state.integrationIds.toArray(),
			integrationRules: state.integrationRules
				.valueSeq()
				.map(rule => AccessReviewTemplateIntegrationRuleModel.fromServerData(rule))
				.toList(),
			name: currentTemplate.name,
			resourceRules: state.resourceRules
				.valueSeq()
				.flatMap(integrationResourceRules =>
					integrationResourceRules.valueSeq().map(rule => AccessReviewTemplateResourceRuleModel.fromServerData(rule))
				)
				.toList(),
			reviewerTypes: currentTemplate.reviewerTypes,
			immediateRevoke: currentTemplate.immediateRevoke
		});
		setIsSubmitLoading(true);
		try {
			if (currentTemplateWithRules.id) {
				await editAccessReviewTemplate(currentTemplateWithRules.id, currentTemplateWithRules);
			} else {
				await createAccessReviewTemplate(currentTemplateWithRules);
				await loadAccessReviewTemplates();
				navigate("/accessReview/templates");
			}
		} catch (error) {
			openErrorModal(error as Error);
		} finally {
			setIsSubmitLoading(false);
		}
	}, [
		currentTemplate,
		state,
		editAccessReviewTemplate,
		createAccessReviewTemplate,
		loadAccessReviewTemplates,
		navigate,
		openErrorModal
	]);

	const deleteTemplate = useCallback(async () => {
		setIsDeleting(true);
		await deleteAccessReviewTemplate(currentTemplate.id);
		areYouSureModal.close();
		navigate("/accessReview/templates");
	}, [areYouSureModal, currentTemplate.id, deleteAccessReviewTemplate, navigate]);

	const creator = currentTemplate.creatorId ? users?.get(currentTemplate.creatorId) : user;

	const hasChanged = useMemo(() => {
		if (!originalTemplate) {
			return true;
		}

		if (
			currentTemplate.name !== originalTemplate.name ||
			currentTemplate.description !== originalTemplate.description ||
			currentTemplate.groupFilterType !== originalTemplate.groupFilterType ||
			state.integrationsFilterType !== originalTemplate.integrationFilterType ||
			currentTemplate.groupIds.length !== originalTemplate.groupIds.length ||
			state.integrationIds.count() !== originalTemplate.integrationIds.length ||
			currentTemplate.immediateRevoke !== originalTemplate.immediateRevoke ||
			currentTemplate.includeUserless !== originalTemplate.includeUserless ||
			xor(currentTemplate.groupIds, originalTemplate.groupIds).length > 0 ||
			xor(state.integrationIds.toArray(), originalTemplate.integrationIds).length > 0 ||
			xor(currentTemplate.reviewerTypes, originalTemplate.reviewerTypes).length > 0
		) {
			return true;
		}

		if (
			state.integrationRules.count() !== originalTemplate.integrationRules.count() ||
			originalTemplate.integrationRules.some(rule => {
				const currentRule = state.integrationRules.get(rule.integrationId);
				return (
					!currentRule ||
					rule.filterType !== currentRule.filterType ||
					rule.resourceIds.count() !== currentRule.resourceIds.count() ||
					rule.resourceIds.some(id => !currentRule.resourceIds.includes(id))
				);
			})
		) {
			return true;
		}

		const resourceRulesCount = state.resourceRules
			.valueSeq()
			.flatMap(integrationResourceRules => integrationResourceRules.valueSeq())
			.count();
		if (
			resourceRulesCount !== originalTemplate.resourceRules.count() ||
			originalTemplate.resourceRules.some(rule => {
				const currentRule = state.resourceRules.get(rule.integrationId)?.get(rule.integrationResourceId);
				return (
					!currentRule ||
					rule.filterType !== currentRule.filterType ||
					rule.roleIds.count() !== currentRule.roleIds.count() ||
					rule.roleIds.some(id => !currentRule.roleIds.includes(id))
				);
			})
		) {
			return true;
		}

		return false;
	}, [currentTemplate, originalTemplate, state]);

	const changeTemplateGroupFilterType = useCallback(
		(groupFilterType: TFilterType) => setCurrentTemplate(current => current.set("groupFilterType", groupFilterType)),
		[]
	);
	const changeTemplateGroupIds = useCallback(
		(groupIds: string[] | null) => setCurrentTemplate(current => current.set("groupIds", groupIds || [])),
		[]
	);

	const changeTemplateReviewerTypes = useCallback((newReviewerTypes: TAccessReviewTemplateReviewers[]) => {
		setCurrentTemplate(current => current.set("reviewerTypes", newReviewerTypes));
		if (!newReviewerTypes.includes("resourceOwner")) {
			setCurrentTemplate(current => current.set("includeUserless", false));
		}
	}, []);

	const toggleTemplateIncludeUserless = useCallback(
		() => setCurrentTemplate(current => current.set("includeUserless", !current.includeUserless)),
		[]
	);

	const breadcrumbs = useMemo(() => {
		const currentPart = params.templateId ? originalTemplate?.name : t("pages.accessReviewTemplates.templateCreation");
		if (!currentPart) return [];
		return [
			{
				title: t("navigation.accessReview"),
				url: "/accessReview"
			},
			{
				title: t("pages.accessReview.manageTemplates"),
				url: "/accessReview/templates"
			},
			{
				title: currentPart
			}
		];
	}, [originalTemplate?.name, params.templateId, t]);

	return (
		<PageTemplate subPage className={classNames(className)}>
			<AreYouSureModal isOpen={areYouSureModal.isOpen} onClose={areYouSureModal.close} onAction={deleteTemplate} />

			<PageTemplate.Title className={classes.header}>
				<div className={classes.navigation}>
					<Breadcrumbs parts={breadcrumbs} />
				</div>
				{currentTemplate?.id && (
					<Button variant="secondary" prefix={<DeleteIcon />} loading={isDeleting} onClick={areYouSureModal.open}>
						{t("buttons.delete")}
					</Button>
				)}
			</PageTemplate.Title>
			<PageTemplate.Content className={classes.content}>
				<div className={classes.title}>
					<div className={classes.titleContent}>
						{currentTemplate.id ? (
							<>
								<EditableInput value={currentTemplate.name} onEdit={rename} resourceType="template" inputType="name" />
								<Separator />
								<Typography>
									{t("dateTime.date", { date: currentTemplate.createdAt })}
									{", "}
									<Trans
										i18nKey="pages.accessReviewTemplate.by"
										components={{
											user: creator ? <TextUser user={creator} className={classes.createdBy} /> : <LoadingDots inline />
										}}
									/>
								</Typography>
							</>
						) : (
							<Typography variant="h2">{t("pages.accessReviewTemplates.templateCreation")}</Typography>
						)}
					</div>
					<div className={classes.titleContent}>
						<Button
							disabled={!hasChanged || isDeleting}
							loading={isSubmitLoading}
							onClick={submit}
							size="medium"
							prefix={<GrantedIcon />}>
							{t("buttons.save")}
						</Button>
					</div>
				</div>
				{!isTemplateLoading ? (
					<Form className={classes.templateForm}>
						{!currentTemplate.id && (
							<Form.Field>
								<Input
									className={classes.groupSelect}
									label={t("pages.accessReviewTemplate.enterTemplateName")}
									value={currentTemplate.name}
									onValueChange={rename}
								/>
							</Form.Field>
						)}
						<Form.Field>
							<TextAreaInput
								className={classes.groupSelect}
								label={
									currentTemplate.id
										? t("pages.accessReviewTemplate.description")
										: t("pages.accessReviewTemplate.enterTemplateDescription")
								}
								maxLength={DESCRIPTION_MAX_LENGTH}
								value={currentTemplate.description}
								onValueChange={updateDescription}
							/>
						</Form.Field>
						<Form.Field>
							<ReviewerType values={currentTemplate.reviewerTypes} onChange={changeTemplateReviewerTypes} />
						</Form.Field>
						<Form.Field>
							<Typography variant="h3">{t("pages.accessReviewTemplate.includeUserless.title")}</Typography>
							<Checkbox
								description={t("pages.accessReviewTemplate.includeUserless.description")}
								disabled={!withResources}
								label={t("pages.accessReviewTemplate.includeUserless.label")}
								onClick={toggleTemplateIncludeUserless}
								selected={currentTemplate.includeUserless}
							/>
						</Form.Field>
						<Form.Field>
							<Typography variant="h3">{t("pages.accessReviewTemplate.immediateRevoke")}</Typography>
							<ImmediateRevokeCheckbox
								onChange={changeImmediateRevoke}
								value={currentTemplate.immediateRevoke}
								disabled={disableImmediateRevokeOverride}
							/>
						</Form.Field>
						<Form.Field>
							<Typography variant="h3">{t("pages.accessReviewTemplate.toReview")}</Typography>
							<RadioInputGroup
								className={classes.groupFilterTypeRadio}
								options={groupFilterTypeOptions}
								selectedValue={currentTemplate.groupFilterType}
								onChange={changeTemplateGroupFilterType}
							/>
							<GroupsSelectInput
								className={classes.groupSelect}
								values={currentTemplate.groupIds}
								onChange={changeTemplateGroupIds}
								label={t("pages.accessReviewTemplate.selectReviewGroups", { context: currentTemplate.groupFilterType })}
							/>
						</Form.Field>
						<Form.Field>
							<Typography variant="h3">{t("pages.accessReviewTemplate.rolesToReview")}</Typography>
							<Typography variant="small" className={classes.label}>
								{t("pages.accessReviewTemplate.selectRoles")}
							</Typography>
							<TreeNode
								path={ROOT_PATH}
								id={ROOT_PATH.join(".")}
								label={t("pages.accessReviewTemplate.allIntegrations")}
								perPage={10}
								isLeaf={false}
								searchable={false}
								totalChildren={integrations?.count() || 0}
								totalChildrenArray={[integrations?.count() || 0]}
							/>
						</Form.Field>
					</Form>
				) : (
					<LoadingDots center />
				)}
			</PageTemplate.Content>
		</PageTemplate>
	);
};

const AccessReviewTemplatePageWithProvider: FC = ({ ...componentProps }) => {
	return (
		<AccessReviewTemplateProvider>
			<AccessReviewTemplatePage {...componentProps} />
		</AccessReviewTemplateProvider>
	);
};

export { AccessReviewTemplatePageWithProvider as AccessReviewTemplatePage };
