import { apiReq } from "utils/apiReq";
import { AuditLogsWebhookModel } from "models/AuditLogsWebhookModel";
import { List } from "immutable";

export async function getAllWebhooks() {
	const { data } = await apiReq("GET", "/v1/auditLogsWebhooks");

	return List<AuditLogsWebhookModel>(data.map(AuditLogsWebhookModel.fromServerData));
}

export const createWebhook = async (params: {
	url: string;
	headers: Record<string, unknown> | null;
	additionalAuditLogParams: Record<string, unknown> | null;
}) => {
	const { data: createdWebhook } = await apiReq("POST", "/v1/auditLogsWebhooks", params);
	return AuditLogsWebhookModel.fromServerData(createdWebhook);
};

export const deleteWebhook = async (id: string) => {
	return apiReq("DELETE", `/v1/auditLogsWebhooks/${id}`);
};

export const enableWebhook = async (id: string) => {
	return apiReq("PUT", `/v1/auditLogsWebhooks/${id}/enable`);
};
