import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	prefixIcons: {
		display: "flex",
		flexDirection: "row",
		gap: "0.5rem",
		alignItems: "center"
	}
});
