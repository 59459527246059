import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { MultipleSelect } from "components/ui/MultipleSelect";
import { getBundleCategories } from "api/accessRequestForm";
import { useSelectSearchProps } from "hooks/useSelectSearchProps";
import { useStyles } from "./styles";

// multi select does not works well with falsy values (empty string) so we convert it to this
const NO_CATEGORY_OPTION_KEY = "[NO_CATEGORY]";
const LIMIT = 100;

interface IProps {
	setBundleCategories: (value: string[] | null) => void;
	bundleCategories: string[] | null;
	userId: string;
}

const calculateOption = (category: string) => category || NO_CATEGORY_OPTION_KEY;

const sort = (options: string[]) => {
	const a = options.splice(options.indexOf(NO_CATEGORY_OPTION_KEY), 1);
	options.unshift(...a);
	return options;
};

export const BundleCategoriesInput: FC<IProps> = ({ setBundleCategories, bundleCategories, userId }) => {
	const { t } = useTranslation(undefined, { keyPrefix: "pages.newTicket.newTicketForm.bundleCategoriesInput" });
	const classes = useStyles();

	const fetchBundleCategories = useCallback(
		async (query: string) => {
			const response = await getBundleCategories({ userId, search: query, perPage: LIMIT });
			return response.result.toArray().map(calculateOption);
		},
		[userId]
	);

	const { selectProps } = useSelectSearchProps(fetchBundleCategories, bundleCategories, true);

	const renderOption = useCallback(
		(option: string) => {
			if (option === NO_CATEGORY_OPTION_KEY) {
				return <span className={classes.noCategory}>{t("option.noCategory")}</span>;
			}

			return <>{option}</>;
		},
		[t, classes]
	);

	const getOptionLabel = useCallback(
		(option: string) => (option === NO_CATEGORY_OPTION_KEY ? t("option.noCategory") : option),
		[t]
	);

	const onChange = useCallback(
		(categories: string[] | null) => {
			if (categories !== null) {
				categories = categories.map(category => (category === NO_CATEGORY_OPTION_KEY ? "" : category));
			}
			setBundleCategories(categories);
		},
		[setBundleCategories]
	);

	const value = useMemo(() => bundleCategories?.map(calculateOption), [bundleCategories]);

	return (
		<MultipleSelect
			{...selectProps}
			chipsLimit={3}
			fullWidth
			label={t("label")}
			onChange={onChange}
			placeholder={t("placeholder")}
			value={value}
			filter={null}
			limit={LIMIT}
			renderOption={renderOption}
			getOptionLabel={getOptionLabel}
			sort={sort}
		/>
	);
};
