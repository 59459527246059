export abstract class EntitleError extends Error {
	readonly title: string | null;

	constructor(message?: string, title?: string) {
		super(message);
		this.title = title || null;
	}

	getTexts(): TErrorTexts {
		return {
			title: this.title,
			message: this.message
		};
	}
}

export type TErrorTexts = {
	title: string | null;
	message: string | null;
};
