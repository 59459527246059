import React from "react";
import { BundleIcon } from "components/ui/Icons/BundleIcon";
import { useIntegrations } from "hooks/useIntegrations";
import { IRenderChipParams } from "components/ui/MultipleSelect";
import { MultiSelectChip } from "../../../MultiSelectChip";
import type { TNewTicketOption } from "components/pages/NewTicketPage/components/NewTicketForm/types";

export const SearchChip: FC<IRenderChipParams<TNewTicketOption>> = ({ option, componentKey, ...rest }) => {
	const integrations = useIntegrations();
	const integration = option.type === "role" ? integrations?.get(option.value.integrationResource.integrationId) : null;
	const name =
		option.type === "role"
			? `${option.value.integrationResource.displayName} - ${option.value.name}`
			: option.value.name;

	let iconProps = {};
	if (option.type === "role") {
		iconProps = {
			imageUrl: integration?.imageUrl
		};
	} else if (option.type === "bundle") {
		iconProps = {
			icon: <BundleIcon />
		};
	}

	return <MultiSelectChip key={componentKey} {...rest} content={name} {...iconProps} />;
};
