import React, { useMemo } from "react";
import { MultipleInput } from "components/ui/MultipleInput";
import { getTagValidators } from "utils/validationUtils";
import { useTranslation } from "react-i18next";

interface IProps {
	label: string;
	values: string[];
	onChange: (value: string[]) => void;
	readonlyValues?: string[];
}

export const TagsInput: FC<IProps> = ({ label, values, onChange, readonlyValues }) => {
	const { t } = useTranslation();
	const validators = useMemo(() => getTagValidators(t("shared.tag")), [t]);

	return (
		<MultipleInput
			fullWidth
			label={label}
			value={values || []}
			onChange={onChange}
			readonlyValues={readonlyValues || undefined}
			validators={validators}
		/>
	);
};
