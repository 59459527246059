import classNames from "classnames";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import sortBy from "lodash/sortBy";
import { Block } from "components/ui/Block";
import { ITargetData } from "api/tickets";
import { TicketModel } from "models/TicketModel";
import { ApprovalFlowSimulation } from "components/common/ApprovalFlowSimulation";
import { Tabs } from "components/ui/Tabs";
import { useCompany } from "hooks/useCompany";
import { Form } from "components/ui/Form";
import { useNewTicketFormContext } from "../../newTicketContext";
import { useURLFormData } from "./hooks/useURLFormData";
import { GrantMethodField } from "./components/GrantMethodField";
import { NewTicketByBundleForm } from "./components/NewTicketByBundleForm";
import { NewTicketByResourceForm } from "./components/NewTicketByResourceForm";
import { useStyles } from "./styles";
import { NewTicketBySearchForm } from "./components/NewTicketBySearchForm";
import { NewTicketFormActions } from "./components/NewTicketFormActions";
import { ConnectTo3rdPartyTicketField } from "./components/ConnectTo3rdPartyTicketField";
import { DurationField } from "./components/DurationField";
import { BehalfOfField } from "./components/BehalfOfField";
import { IntegrationActorField } from "./components/IntegrationActorField";
import { JustificationField } from "./components/JustificationField";
import { useOriginalTicketData } from "./hooks/useOriginalTicketData";
import type { Require } from "utils/types";
import type { TRoleOption, TFormData, TNewTicketFormType } from "./types";

export type TTicketCreation = {
	duration: number;
	targets: ITargetData[];
	receiverIntegrationActorIds: Record<string, string | null>;
	ticketingIntegrationTicketId?: string;
	receiverId: string;
};

const TRANSLATION_PREFIX = "pages.newTicket.newTicketForm";

const TABS: [TNewTicketFormType, FC][] = [
	["search", NewTicketBySearchForm],
	["bundle", NewTicketByBundleForm],
	["resource", NewTicketByResourceForm]
];

const createFormDataHash = (formData: Partial<TFormData>) => {
	const targetsSorted = sortBy(
		formData.targets?.map(
			target =>
				`${target.type}-${target.value.id}${
					target.type === "role" ? `-${(formData.grantMethods?.get(target.value.id) ?? target.value).id}` : ""
				}`
		) || []
	);

	return [formData.duration, formData.receiverUser?.id, targetsSorted].join("_");
};

export const NewTicketForm: FC = () => {
	const urlFormData = useURLFormData();
	const originalTicketData = useOriginalTicketData();
	const {
		state: { receiverUser, formState, formType },
		actions: { getTicketPreview, formActions, setFormType, prePopulateForm }
	} = useNewTicketFormContext();

	const { t } = useTranslation();
	const classes = useStyles();
	const company = useCompany();
	const [ticketPreview, setTicketPreview] = useState<TicketModel>();
	const [currentFormDataHash, setCurrentFormDataHash] = useState<string | null>(null);

	const [prePopulatedForm, setPrePopulatedForm] = useState(false);

	useEffect(() => {
		if (!prePopulatedForm) {
			if (originalTicketData) {
				prePopulateForm(originalTicketData);
				setPrePopulatedForm(true);
			}
			if (urlFormData) {
				prePopulateForm(urlFormData);
				setPrePopulatedForm(true);
			}
		}
	}, [formActions, urlFormData, prePopulatedForm, originalTicketData, prePopulateForm]);

	useEffect(() => {
		const formData = {
			duration: formState.duration ?? undefined,
			targets: formState.selectedItems.toArray(),
			receiverUser,
			receiverIntegrationActorIds: formState.receiverIntegrationActorIds,
			grantMethods: formState.grantMethods
		};
		const newHash = createFormDataHash(formData);
		if (newHash === currentFormDataHash) return;

		const reloadTicketPreview = async () => {
			if (typeof formState.duration === "number" && formState.selectedItems.size > 0 && receiverUser) {
				const ticket = await getTicketPreview(formData as Require<TFormData, "duration">);
				ticket && setTicketPreview(ticket);
				setCurrentFormDataHash(newHash);
			}
		};

		if (!formState.workflowDisplay) setTicketPreview(undefined);
		else reloadTicketPreview();
	}, [
		formState.workflowDisplay,
		getTicketPreview,
		formState.selectedItems,
		formState.duration,
		receiverUser,
		formState.receiverIntegrationActorIds,
		currentFormDataHash,
		formState.grantMethods
	]);

	const enableThirdPartyTickets = useMemo(
		() => (company?.integratedToTicketingIntegrations && company.integratedToTicketingIntegrations.size > 0) || false,
		[company]
	);

	const onChangeTab = useCallback(
		(key: string) => {
			formActions.reset();
			setFormType(key as TNewTicketFormType);
		},
		[formActions, setFormType]
	);

	return (
		<Block className={classNames(classes.newTicketForm)}>
			<Tabs
				className={classNames(classes.formContainer, classes.wideForm, classes.tabs)}
				activeKey={formType}
				onChange={onChangeTab}>
				{TABS.map(([tab, PartialFormComponent]) => {
					return (
						<Tabs.Tab key={tab} tab={t(`${TRANSLATION_PREFIX}.formType.${tab}`)} className={classes.tab}>
							<Form>
								<BehalfOfField />
								<PartialFormComponent />
								{formState.selectedItems
									.filter(({ type }) => type === "role")
									.map(option => <GrantMethodField key={option.value.id} parent={(option as TRoleOption).value} />)
									.toArray()}

								{formState.integrationActors !== null && formState.multipleActorsOptionIntegrationIds
									? formState.multipleActorsOptionIntegrationIds.map(integrationId => (
											<IntegrationActorField key={integrationId} integrationId={integrationId} />
										))
									: null}
								<DurationField />
								<JustificationField />
								{enableThirdPartyTickets && <ConnectTo3rdPartyTicketField />}
								<NewTicketFormActions />
							</Form>
						</Tabs.Tab>
					);
				})}
			</Tabs>
			{formState.workflowDisplay && ticketPreview && (
				<ApprovalFlowSimulation ticket={ticketPreview} withStatus={false} />
			)}
		</Block>
	);
};
