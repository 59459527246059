import { Record } from "immutable";
import { DirectoryGroupModel } from "./DirectoryGroupModel";
import { IntegrationResourceModel } from "./IntegrationResourceModel";
import { UserModel } from "./UserModel";

type TIntegrationResourceMaintainerType = "user" | "directoryGroup";

const defaults = {
	entity: null as DirectoryGroupModel | null,
	entityId: "",
	id: "",
	integrationResource: null as IntegrationResourceModel | null,
	integrationResourceId: "",
	type: "user" as TIntegrationResourceMaintainerType
};

export class IntegrationResourceMaintainerModel extends Record<typeof defaults>(defaults) {
	static fromServerData(data: unknown): IntegrationResourceMaintainerModel {
		const { entity, entityId, id, integrationResource, integrationResourceId, type } = data as typeof defaults;
		return new IntegrationResourceMaintainerModel({
			entity: type === "user" || !entity ? null : DirectoryGroupModel.fromServerData(entity),
			entityId,
			id,
			integrationResource: integrationResource ? IntegrationResourceModel.fromServerData(integrationResource) : null,
			integrationResourceId,
			type
		});
	}

	static fromEntity(
		entity: UserModel | DirectoryGroupModel,
		integrationResourceId: string
	): IntegrationResourceMaintainerModel {
		return new IntegrationResourceMaintainerModel({
			entity: null,
			entityId: entity.id,
			id: "",
			integrationResource: null,
			integrationResourceId,
			type: entity instanceof UserModel ? "user" : "directoryGroup"
		});
	}
}
