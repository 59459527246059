import { ApprovalFlowModel } from "models/ApprovalFlowModel";
import { apiReq } from "utils/apiReq";
import { Map } from "immutable";

export const getApprovalFlows = async (): Promise<Map<string, ApprovalFlowModel>> => {
	const { data } = await apiReq("GET", "/v1/approvalFlows");

	return Map(
		data
			.map((approvalFlow: unknown) => ApprovalFlowModel.fromServerData(approvalFlow))
			.map((approvalFlow: ApprovalFlowModel) => [approvalFlow.id, approvalFlow])
	);
};

export const createApprovalFlow = async (approvalFlow: ApprovalFlowModel) => {
	const schema = approvalFlow.toServerData();
	const { id: _, ...body } = schema;
	const { data: newApprovalFlow } = await apiReq("POST", "/v1/approvalFlows", body);

	return ApprovalFlowModel.fromServerData(newApprovalFlow);
};

export const updateApprovalFlow = async (approvalFlow: ApprovalFlowModel) => {
	const schema = approvalFlow.toServerData();
	const { id, ...body } = schema;
	const { data: updatedApprovalFlow } = await apiReq("PUT", `/v1/approvalFlows/${id}`, body);

	return ApprovalFlowModel.fromServerData(updatedApprovalFlow);
};
