import { EventEmitter } from "events";
import React, { useCallback, useMemo, useEffect } from "react";
import classNames from "classnames";
import { useSearchParams } from "react-router-dom";
import { LoadingSpinner } from "components/ui/LoadingSpinner";
import { addTicketComment } from "api/ticketComments";
import { useTranslation } from "react-i18next";
import { Typography } from "components/ui/Typography";
import { TicketCommentModel } from "models/TicketCommentModel";
import { useTicket } from "hooks/useTicket";
import { useTicketUpdatesContext } from "context/ticketUpdatesContext";
import { createPortal } from "react-dom";
import { CloseIcon } from "components/ui/Icons/CloseIcon";
import { usePageOverlays } from "context/overlaysContext";
import { TicketActivity } from "../TicketActivity";
import { useStyles } from "./styles";
import { TicketSidebarHeader } from "./components/TicketSidebarHeader";

export const TicketSidebar: FC<{ resetEventEmitter?: EventEmitter }> = ({ resetEventEmitter }) => {
	const [searchParams, setSearchParams] = useSearchParams();
	const pageOverlays = usePageOverlays();
	const ticketId = useMemo(
		() => (pageOverlays ? searchParams.get("ticketId") || undefined : undefined),
		[pageOverlays, searchParams]
	);
	const { ticket, loading, notFound, setTicket, reloadTicket } = useTicket(ticketId || "");
	const { notifyTicketUpdate } = useTicketUpdatesContext();

	const closeSidebar = useCallback(() => {
		searchParams.delete("ticketId");
		setSearchParams(searchParams);
	}, [searchParams, setSearchParams]);

	const classes = useStyles();
	const { t } = useTranslation();

	const addComment = useCallback(
		async (comment: string) => {
			if (!ticket || !comment) return;
			const newComment = await addTicketComment(ticket.id, comment);
			if (ticket.comments) {
				setTicket(ticket.set("comments", ticket.comments.unshift(newComment)));
			}
		},
		[setTicket, ticket]
	);

	const updateComment = useCallback(
		(comment: TicketCommentModel) => {
			if (!ticket) return;
			if (ticket.comments) {
				const ticketIndex = ticket.comments.findIndex(({ id }) => id === comment.id);
				let updatedTicket = ticket.set("comments", ticket.comments.set(ticketIndex, comment));
				if (ticketIndex === 0) {
					updatedTicket = updatedTicket.set("justification", comment.content);
					notifyTicketUpdate(updatedTicket);
				}
				setTicket(updatedTicket);
			}
		},
		[setTicket, ticket, notifyTicketUpdate]
	);

	const reloadTicketHandler = useCallback(async () => {
		const updatedTicket = await reloadTicket();
		notifyTicketUpdate(updatedTicket);
		return updatedTicket;
	}, [notifyTicketUpdate, reloadTicket]);

	useEffect(() => {
		resetEventEmitter?.on("reset", reloadTicketHandler);

		return () => {
			resetEventEmitter?.off("reset", reloadTicketHandler);
		};
	}, [resetEventEmitter, reloadTicketHandler]);

	if (!pageOverlays) return null;

	return createPortal(
		<div className={classNames(classes.container, { [classes.open]: Boolean(ticketId) })}>
			<div className={classes.dimmer} />
			<div className={classes.sidebar}>
				{ticket && (
					<>
						<TicketSidebarHeader ticket={ticket} onClose={closeSidebar} />
						<TicketActivity
							addComment={addComment}
							ticket={ticket}
							updateComment={updateComment}
							reloadTicket={reloadTicketHandler}
						/>
					</>
				)}

				{loading && <LoadingSpinner className={classes.loader} />}
				{notFound && (
					<div className={classes.emptyTicketContainer}>
						<CloseIcon className={classes.closeIcon} onClick={closeSidebar} />
						<Typography className={classes.notFound} variant="h3">
							{t("common.ticketSidebar.notFound")}
						</Typography>
					</div>
				)}
			</div>
		</div>,
		pageOverlays
	);
};
