import React, {
	ButtonHTMLAttributes,
	MouseEventHandler,
	MouseEvent,
	ReactNode,
	useCallback,
	useMemo,
	useRef
} from "react";
import classNames from "classnames";
import { TTypographyVariant, Typography } from "components/ui/New/Typography";
import { LoadingSpinner } from "components/ui/LoadingSpinner";
import { Link } from "components/common/Link";
import { useIsOverflowed } from "hooks/useIsOverflowed";
import { Tooltip } from "../Tooltip";
import { ExportIcon } from "../Icons/ExportIcon";
import { useStyles } from "./styles";

type TButtonVariant = "primary" | "secondary" | "text";
type TButtonSize = "small" | "medium" | "large";

type TBaseProps = {
	disabled?: boolean;
	loading?: boolean;
	onClick?: MouseEventHandler;
	prefix?: ReactNode;
	size?: TButtonSize;
	suffix?: ReactNode;
	tooltip?: ReactNode;
	overflowTooltip?: boolean;
	type?: ButtonHTMLAttributes<HTMLButtonElement>["type"];
	variant?: TButtonVariant;
};

type TLinkProps = TBaseProps & {
	target?: string;
	to: string;
};

type TLinkComponentProps = TLinkProps & {
	component: "link";
};

type TButtonComponentProps = TBaseProps & {
	component: "button";
};

export type TBaseButtonProps = TButtonComponentProps | TLinkComponentProps;

const useButtonClasses = (props: {
	className?: string;
	disabled?: boolean;
	loading?: boolean;
	size: TButtonSize;
	variant: TButtonVariant;
}) => {
	const { size, variant, loading, disabled, className } = props;
	const classes = useStyles();
	const sizeClassName = useMemo(() => {
		switch (size) {
			case "large":
				return classes.large;
			case "medium":
				return classes.medium;
			case "small":
				return classes.small;
		}
	}, [classes.large, classes.medium, classes.small, size]);

	const variantClassName = useMemo(() => {
		switch (variant) {
			case "primary":
				return classes.primary;
			case "secondary":
				return classes.secondary;
			case "text":
				return classes.text;
		}
	}, [classes.primary, classes.secondary, classes.text, variant]);

	const buttonClassName = useMemo(() => {
		return classNames(
			classes.button,
			sizeClassName,
			variantClassName,
			{ [classes.disabled]: disabled, [classes.loading]: loading },
			className
		);
	}, [className, classes, disabled, loading, sizeClassName, variantClassName]);

	return { buttonClassName, sizeClassName, variantClassName };
};

const BaseButton: FC<TBaseButtonProps> = props => {
	const {
		children,
		className,
		component,
		disabled = false,
		innerRef,
		loading = false,
		onClick,
		prefix,
		size = "large",
		suffix,
		variant = "primary"
	} = props;
	const classes = useStyles();

	const { buttonClassName } = useButtonClasses({ className, disabled, size, variant });
	const textRef = useRef<HTMLDivElement>(null);
	const { overflowedX } = useIsOverflowed(textRef);

	const textVariant: TTypographyVariant = useMemo(() => {
		switch (size) {
			case "large":
				return "title_med";
			case "medium":
				return "text_title_sb";
			case "small":
				return "text_sm_sb";
		}
	}, [size]);

	const content = useMemo(() => {
		return (
			<>
				{prefix ? <div className={classes.prefixOrSuffix}>{prefix}</div> : null}
				<Typography noWrap className={classes.innerText} variant={textVariant} innerRef={textRef}>
					{children}
				</Typography>
				{loading || suffix ? (
					<div className={classes.prefixOrSuffix}>{loading ? <LoadingSpinner inline /> : suffix || null}</div>
				) : null}
			</>
		);
	}, [children, classes.innerText, classes.prefixOrSuffix, loading, prefix, suffix, textVariant]);

	const handleClick = useCallback(
		(event: MouseEvent) => {
			event.stopPropagation();
			onClick?.(event);
		},
		[onClick]
	);

	const tooltipContent = useMemo(() => {
		if (props.tooltip) return props.tooltip;
		if (overflowedX) return children;
		return null;
	}, [children, props.tooltip, overflowedX]);

	if (component === "button") {
		return (
			<Tooltip content={tooltipContent}>
				<button
					className={buttonClassName}
					ref={innerRef}
					onClick={!disabled && !loading ? handleClick : undefined}
					type={props.type}>
					{content}
				</button>
			</Tooltip>
		);
	} else {
		return (
			<Tooltip content={tooltipContent}>
				<Link
					className={buttonClassName}
					to={props.to}
					target={props.target}
					onClick={!disabled && !loading ? handleClick : undefined}
					noDecoration>
					{content}
				</Link>
			</Tooltip>
		);
	}
};

export const Button: FC<TBaseProps> = props => {
	return <BaseButton component="button" {...props} />;
};
export const LinkButton: FC<TLinkProps> = props => {
	return <BaseButton component="link" {...props} suffix={props.to.includes("http") ? <ExportIcon /> : props.suffix} />;
};
