import React, { useCallback, useEffect, useMemo } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { Modal } from "components/ui/Modal";
import { Button } from "components/ui/Button";
import { RefreshIcon } from "components/ui/Icons/RefreshIcon";
import { useNavigate } from "react-router-dom";
import { LoadingSpinner } from "components/ui/LoadingSpinner";
import { Typography } from "components/ui/New/Typography";
import { GrantedIcon } from "components/ui/Icons/GrantedIcon";
import { TitleBody } from "components/ui/TitleBody";
import { useStyles } from "./styles";

type TProps = {
	close: () => void;
	isOpen: boolean;
	success?: boolean;
	error?: string;
	retry: () => void;
};

const TRANSLATION_PREFIX = "pages.newRequest.summaryStep.createRequestModal";
const SUCCESS_TIMEOUT_IN_MS = 2000;

export const CreateRequestModal: FC<TProps> = ({ className, innerRef, success, error, retry, isOpen }) => {
	const { t } = useTranslation();
	const classes = useStyles();
	const navigate = useNavigate();

	const goBack = useCallback(() => {
		close();
		navigate(-1);
	}, [navigate]);

	const modalContent = useMemo(() => {
		if (success) {
			return (
				<div className={classes.successContent}>
					<GrantedIcon size={80} />
					<Typography variant="title_sb">{t(`${TRANSLATION_PREFIX}.success`)}</Typography>
				</div>
			);
		}
		if (error) {
			return (
				<div className={classes.errorContent}>
					<GrantedIcon size={60} className={classes.errorIcon} />
					<TitleBody title={t(`${TRANSLATION_PREFIX}.error.title`)} body={t(`${TRANSLATION_PREFIX}.error.content`)} />
				</div>
			);
		}

		return (
			<div className={classes.loadingContent}>
				<LoadingSpinner size={80} />
				<Typography variant="title_sb">{t(`${TRANSLATION_PREFIX}.loading`)}</Typography>
			</div>
		);
	}, [classes.errorContent, classes.errorIcon, classes.loadingContent, classes.successContent, error, success, t]);

	const modalActions = useMemo(() => {
		if (!error) return null;
		return (
			<>
				<Button variant="secondary" size="medium" onClick={goBack}>
					{t(`${TRANSLATION_PREFIX}.error.leaveRequest`)}
				</Button>
				<Button size="medium" onClick={retry} suffix={<RefreshIcon size={20} />}>
					{t("buttons.retry")}
				</Button>
			</>
		);
	}, [error, goBack, retry, t]);

	useEffect(() => {
		if (!success) return;

		const timeout = setTimeout(() => {
			close();
			goBack();
		}, SUCCESS_TIMEOUT_IN_MS);
		return () => clearTimeout(timeout);
	}, [goBack, success]);

	return (
		<Modal
			actions={modalActions}
			className={classNames(classes.modal, className)}
			content={modalContent}
			innerRef={innerRef}
			isOpen={isOpen}
			noTopCloseButton
			onClose={close}
		/>
	);
};
