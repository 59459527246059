import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	errorIcon: {
		color: "var(--color-red-dark)",
		position: "absolute",
		right: "1rem"
	},
	hint: {
		"&.error": {
			color: "var(--color-red-dark)"
		},
		color: "var(--color-grey-dark)",
		display: "block",
		marginTop: "0.55rem"
	},
	input: {
		gap: "0.5rem",
		"&$fullWidth": {
			width: "100%",
			wordBreak: "break-word"
		}
	},
	inputHTMLComponent: {
		"&:disabled": {
			color: "var(--color-grey-dark)"
		},
		backgroundColor: "transparent",
		border: "none",
		fontFamily: "inherit",
		letterSpacing: "inherit",
		minHeight: "inherit",
		height: "100%",
		outline: "none",
		resize: "none",
		width: "100%"
	},
	label: {
		color: "var(--color-grey-dark)",
		display: "block",
		marginBottom: "0.45rem"
	},
	textarea: {
		"&.disabled": {
			backgroundColor: "var(--color-grey-background)"
		},
		"&.error": {
			borderColor: "var(--color-red)"
		},
		"&.focused": {
			backgroundColor: "var(--color-purple-5)",
			borderColor: "var(--color-purple-underline)"
		},
		"&.prefix > *:first-child": {
			paddingRight: "0.55rem"
		},
		"&:hover:not(.focused):not(.disabled):not(.error)": {
			backgroundColor: "var(--color-almost-white)",
			borderColor: "var(--color-purple-underline)"
		},
		"&.autoResize": {
			maxHeight: "50vh",
			minHeight: "1.7em",
			"& $inputHTMLComponent": {
				height: "auto"
			},
			"&$rounded": {
				borderRadius: "var(--border-radius-semi-rounded)"
			}
		},
		alignItems: "baseline",
		border: "1px solid var(--color-grey-light)",
		display: "flex",
		flexDirection: "row",
		fontSize: "var(--typography-standard-font-size)",
		fontWeight: "var(--typography-font-weight-regular)",
		justifyContent: "center",
		lineHeight: "1.1em",
		minHeight: "10rem",
		padding: "var(--spacing-x2, 8px) var(--spacing-x3, 12px)",
		transitionDuration: "0.2s",
		transitionProperty: "background-color, border-color",
		transitionTimingFunction: "ease-in-out",
		width: "100%",
		position: "relative",
		willChange: "background-color"
	},
	checkButton: {
		fontFamily: "Poppins",
		width: "fit-content",
		alignSelf: "end",
		position: "absolute",
		right: "0.7rem",
		paddingBottom: 0,
		marginBottom: 0,
		bottom: 0
	},
	rounded: {},
	fullWidth: {}
});
