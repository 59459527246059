import React from "react";
import { Typography } from "components/ui/Typography";
import classNames from "classnames";
import { CloseCircleIcon } from "components/ui/Icons/CloseCircleIcon";

export const Suffix: FC<{ isError?: boolean; suffix: React.ReactNode; errorClassName?: string }> = ({
	isError,
	suffix,
	errorClassName
}) => {
	if (suffix) return suffix;
	if (isError) return <CloseCircleIcon className={classNames(errorClassName)} />;
	return null;
};

export const HelperText: FC<{ isError?: boolean; hint?: string; errorMessages: string[] | null }> = ({
	isError,
	hint,
	errorMessages,
	className
}) => {
	if (!isError) {
		if (!hint) return null;
		return (
			<Typography variant="small" className={className}>
				{hint}
			</Typography>
		);
	}
	const errors = errorMessages?.map((errorMessage, index) => (
		<Typography variant="small" className={classNames(className, "error")} key={index + errorMessage}>
			{errorMessage}
		</Typography>
	));
	return <>{errors}</>;
};
