import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	input: {
		width: (props: { fullWidth: boolean }) => (props?.fullWidth ? "100%" : "22rem")
	},
	inputContainer: {
		"&$inputContainer$inputContainer": {
			padding: "0.55rem",
			gap: "0.25rem",
			flexWrap: "wrap",
			justifyContent: "left",
			"& > *:first-child": {
				marginRight: "0"
			}
		}
	},
	inputPrefix: {
		display: "flex",
		flexWrap: "wrap",
		width: "100%",
		flex: "1 1 auto",
		gap: "0.25rem"
	},
	hint: {
		"&.error": {
			color: "var(--color-red-dark)"
		},
		color: "var(--color-grey-dark)",
		display: "block",
		marginTop: "0.55rem"
	}
});
