import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	resizingSpan: {
		padding: "0 1rem",
		visibility: "hidden",
		position: "absolute",
		lineHeight: "1.4rem"
	},
	inputContainer: {
		"& input": {
			width: ({ inputWidth, minInputWidth = "2rem" }: { inputWidth: number; minInputWidth?: string }) =>
				inputWidth ? `max(${inputWidth}px, ${minInputWidth})` : minInputWidth
		}
	}
});
