import classNames from "classnames";
import { Chip } from "components/ui/Chip";
import { Tooltip } from "components/ui/Tooltip";
import { Typography } from "components/ui/Typography";
import React, { useCallback, useMemo } from "react";
import { getOptionKey } from "utils/selectUtils";
import { IRenderChipParams, TSize } from "../..";
import { useStyles } from "./styles";

interface IChipsOptions<T> {
	getOptionLabel: (option: T) => string;
	multiLine?: boolean;
	noCollapse?: boolean;
	onClick?: (event: React.MouseEvent) => void;
	onRemove?: (option: T) => void;
	ChipElement?: FC<IRenderChipParams<T>>;
	LimitChip?: FC<IRenderChipParams<string>>;
	values: T[] | null;
	limit?: number;
	readonly?: boolean;
	collapsedChipsValuesClassName?: string;
	readonlyValues?: T[];
	size?: TSize;
	collapsedValuesClassName?: string;
	tooltipClassName?: string;
	disableTooltip?: boolean;
}

export const Chips = <T,>(options: TProps<IChipsOptions<T>>) => {
	const {
		getOptionLabel,
		multiLine,
		noCollapse = false,
		onClick,
		onRemove,
		ChipElement,
		values,
		limit = 2,
		readonly = false,
		LimitChip,
		readonlyValues,
		size = "small",
		collapsedValuesClassName,
		tooltipClassName,
		disableTooltip = false
	} = options;
	const classes = useStyles();

	const renderChip = useCallback(
		(option: T, handleRemoveChip: ((option: T) => void) | undefined, noBorder?: boolean, stretch?: boolean) => {
			const removeChip = handleRemoveChip ? () => handleRemoveChip(option) : undefined;
			const key = getOptionKey(option, getOptionLabel) || "";
			const readonlyChip = readonly || readonlyValues?.includes(option);
			const className = classNames(classes.chip, readonlyChip ? classes.readonlyChip : "");
			return ChipElement ? ( // We are handling this function as component because it may have hooks, and if we treat it as function it will fire hooks exception
				<ChipElement
					className={className}
					componentKey={key}
					key={key}
					stretch={stretch}
					noBorder={noBorder}
					option={option}
					onRemove={removeChip}
					onClick={onClick}
				/>
			) : (
				<Chip
					className={className}
					key={key}
					multiLine={multiLine}
					noBorder={noBorder}
					onClick={onClick}
					onDelete={readonlyChip ? undefined : removeChip}
					size={size}
					stretch={stretch}>
					{getOptionLabel(option)}
				</Chip>
			);
		},
		[
			getOptionLabel,
			readonly,
			readonlyValues,
			ChipElement,
			onClick,
			classes.chip,
			classes.readonlyChip,
			multiLine,
			size
		]
	);

	const renderPlusChip = useCallback(() => {
		const key = "plusChip";
		return LimitChip ? (
			<LimitChip
				componentKey={key}
				key={key}
				{...{
					option: values ? `+${values.length - limit}` : "",
					onClick
				}}
			/>
		) : (
			<Chip className={classes.chip} key={key} onClick={onClick} size={size}>
				<Typography variant={size}>+{values ? values.length - limit : 0}</Typography>
			</Chip>
		);
	}, [LimitChip, values, limit, onClick, classes.chip, size]);

	const chips = useMemo(() => {
		if (!values) {
			return null;
		}
		return values.slice(0, noCollapse ? undefined : limit).map(value => renderChip(value, onRemove));
	}, [limit, noCollapse, onRemove, renderChip, values]);

	return (
		<>
			{chips}
			{!(values && values.length > limit) ? null : !noCollapse && !disableTooltip ? (
				<Tooltip
					className={tooltipClassName}
					trigger="click"
					content={
						<CollapsedValues
							values={values.slice(limit)}
							renderChip={renderChip}
							onRemove={onRemove}
							stretchChips
							readonly
							className={collapsedValuesClassName}
						/>
					}>
					{renderPlusChip()}
				</Tooltip>
			) : (
				renderPlusChip()
			)}
		</>
	);
};

interface ICollapsedValuesProps<T> {
	onRemove?: (option: T) => void;
	renderChip: (
		option: T,
		onRemove?: (option: T) => void,
		noBorder?: boolean,
		stretch?: boolean
	) => string | JSX.Element;
	values: T[];
	stretchChips?: boolean;
	readonly?: boolean;
	className?: string;
}

const CollapsedValues = <T,>(props: ICollapsedValuesProps<T>) => {
	const { onRemove, renderChip, values, stretchChips, className } = props;
	const classes = useStyles();
	const chips = useMemo(
		() => values.map(value => renderChip(value, onRemove, true, stretchChips)),
		[onRemove, renderChip, stretchChips, values]
	);
	return (
		<Typography component="div" className={classNames(classes.collapsedValues, className)}>
			{chips}
		</Typography>
	);
};
