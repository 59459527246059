import React, { useCallback, useState } from "react";
import { IntegrationResourceModel } from "models/IntegrationResourceModel";
import { useUsers } from "hooks/useUsers";
import { Table } from "components/ui/Table";
import { Link } from "components/common/Link";
import { Unknown } from "components/ui/Unknown";
import { UserModel } from "models/UserModel";
import { useTranslation } from "react-i18next";
import { TFullIntegrationResourceModel, editIntegrationResource } from "api/integrationResources";
import { ApprovalAlgorithmModel } from "models/ApprovalAlgorithmModel";
import { WorkflowInlineSelect } from "components/common/WorkflowInlineSelect/WorkflowInlineSelect";
import { useOpenGlobalErrorModal } from "hooks/useGlobalError";
import { useStyles } from "./styles";
import { OwnerInlineSelect } from "./OwnerInlineSelect";

export const ResourceRow: FC<{
	resource: IntegrationResourceModel;
	onResourceChanged: (resource: Partial<TFullIntegrationResourceModel> & { id: string }) => void;
}> = ({ resource, onResourceChanged }) => {
	const { t } = useTranslation();
	const classes = useStyles();
	const users = useUsers();
	const [ownerId, setOwnerId] = useState(resource.ownerId);
	const [approvalAlgorithmId, setApprovalAlgorithmId] = useState<string | null>(resource.approvalAlgorithmId);
	const openGlobalErrorModal = useOpenGlobalErrorModal();

	const changeOwner = useCallback(
		async (owner: UserModel | null) => {
			const newOwnerId = owner?.id || null;
			setOwnerId(newOwnerId);
			try {
				await editIntegrationResource({ id: resource.id, ownerUserId: newOwnerId });
			} catch (e) {
				setOwnerId(resource.ownerId);
				openGlobalErrorModal(new Error(t("errors.integration.ownerChange", { resourceName: resource.displayName })));
			}
			onResourceChanged({ id: resource.id, ownerId: newOwnerId });
		},
		[resource.id, onResourceChanged, resource.ownerId, resource.displayName, t, openGlobalErrorModal]
	);

	const changeApprovalAlgorithm = useCallback(
		async (value: ApprovalAlgorithmModel | undefined) => {
			setApprovalAlgorithmId(value?.id ?? null);
			try {
				await editIntegrationResource({ id: resource.id, approvalAlgorithmId: value?.id ?? null });
			} catch (e) {
				setApprovalAlgorithmId(resource.approvalAlgorithmId);
				openGlobalErrorModal(new Error(t("errors.integration.workflowChange", { resourceName: resource.displayName })));
			}
			onResourceChanged({ id: resource.id, approvalAlgorithmId: value?.id });
		},
		[onResourceChanged, resource.id, resource.approvalAlgorithmId, resource.displayName, t, openGlobalErrorModal]
	);

	const resourceType = resource.type;

	return (
		<Table.Row key={resource.id}>
			<Table.Cell>
				<Link className={classes.link} noDecoration to={`resources/${resource.id}`}>
					{resource.displayName} ({resource.rolesCount})
				</Link>
			</Table.Cell>
			<Table.Cell>
				<Unknown unknown={!users || !users.size}>
					<OwnerInlineSelect ownerId={ownerId} setOwner={changeOwner} integrationId={resource.integrationId} />
				</Unknown>
			</Table.Cell>
			<Table.Cell>
				<WorkflowInlineSelect
					approvalAlgorithmId={approvalAlgorithmId}
					inheritTranslationPath="pages.integration.resource.inherit"
					setApprovalAlgorithm={changeApprovalAlgorithm}
				/>
			</Table.Cell>
			<Table.Cell>{resourceType || t("pages.integration.noType")}</Table.Cell>
		</Table.Row>
	);
};
