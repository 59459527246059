enum Breakpoint {
	FIRST = 1, // Largest
	SECOND = 2,
	THIRD = 3,
	FOURTH = 4,
	FIFTH = 5,
	SIXTH = 6 // Smallest
}

type TBreakpointKeys = keyof typeof Breakpoint;
export type TBreakpointOption = TBreakpointKeys | Breakpoint;

// Change here if any breakpoint size changes
const FIRST_BREAKPOINT = 1871;
const SECOND_BREAKPOINT = 1758;
const THIRD_BREAKPOINT = 1394;
const FOURTH_BREAKPOINT = 1131;
const FIFTH_BREAKPOINT = 751;
const SIXTH_BREAKPOINT = 571;

/*
    Access using:
    BREAKPOINTS[Breakpoint.FIRST] / BREAKPOINTS[1]  / BREAKPOINTS.FIRST / BREAKPOINTS["FIRST"]
    or
    BP[Bp.FIRST] / BP[1] / BP.FIRST / BP["FIRST"]
*/
const BREAKPOINTS: Record<Breakpoint | TBreakpointKeys, number> = {
	[Breakpoint.FIRST]: FIRST_BREAKPOINT,
	FIRST: FIRST_BREAKPOINT,
	[Breakpoint.SECOND]: SECOND_BREAKPOINT,
	SECOND: SECOND_BREAKPOINT,
	[Breakpoint.THIRD]: THIRD_BREAKPOINT,
	THIRD: THIRD_BREAKPOINT,
	[Breakpoint.FOURTH]: FOURTH_BREAKPOINT,
	FOURTH: FOURTH_BREAKPOINT,
	[Breakpoint.FIFTH]: FIFTH_BREAKPOINT,
	FIFTH: FIFTH_BREAKPOINT,
	[Breakpoint.SIXTH]: SIXTH_BREAKPOINT,
	SIXTH: SIXTH_BREAKPOINT
};

const BREAKPOINTS_MEDIA_QUERIES: Record<Breakpoint | TBreakpointKeys, string> = {
	[Breakpoint.FIRST]: `@media (min-width: ${FIRST_BREAKPOINT}px)`,
	FIRST: `@media (min-width: ${FIRST_BREAKPOINT}px)`,
	[Breakpoint.SECOND]: `@media (min-width: ${SECOND_BREAKPOINT}px)`,
	SECOND: `@media (min-width: ${SECOND_BREAKPOINT}px)`,
	[Breakpoint.THIRD]: `@media (min-width: ${THIRD_BREAKPOINT}px)`,
	THIRD: `@media (min-width: ${THIRD_BREAKPOINT}px)`,
	[Breakpoint.FOURTH]: `@media (min-width: ${FOURTH_BREAKPOINT}px)`,
	FOURTH: `@media (min-width: ${FOURTH_BREAKPOINT}px)`,
	[Breakpoint.FIFTH]: `@media (min-width: ${FIFTH_BREAKPOINT}px)`,
	FIFTH: `@media (min-width: ${FIFTH_BREAKPOINT}px)`,
	[Breakpoint.SIXTH]: `@media (min-width: ${SIXTH_BREAKPOINT}px)`,
	SIXTH: `@media (min-width: ${SIXTH_BREAKPOINT}px)`
};

export {
	Breakpoint,
	Breakpoint as Bp,
	BREAKPOINTS,
	BREAKPOINTS as BP,
	BREAKPOINTS_MEDIA_QUERIES,
	BREAKPOINTS_MEDIA_QUERIES as BP_MEDIA_QUERIES
};
