import { useMemo } from "react";
import { TBreakpointState, TBreakpointsState, useBreakpointsContext } from "context/breakpointsContext";
import { Breakpoint, type TBreakpointOption } from "utils/breakpoints";
import { requirePropertyOf } from "utils/security";

const getEnumKeyByValue = (value: number) =>
	Object.keys(Breakpoint)[Object.values(Breakpoint).indexOf(value)] as keyof typeof Breakpoint;

export function useBreakpoints(option: TBreakpointOption): TBreakpointState;
export function useBreakpoints(): TBreakpointsState["state"];
export function useBreakpoints(option?: TBreakpointOption) {
	const { state } = useBreakpointsContext();
	const breakpoint = useMemo(() => {
		if (!option) return state;
		const breakpointAsName = typeof option === "number" ? getEnumKeyByValue(option) : option;
		return requirePropertyOf(state, breakpointAsName);
	}, [option, state]);

	return breakpoint;
}
