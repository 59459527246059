import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	accessRequestContainer: {
		display: "flex",
		backgroundColor: "var(--surface-content-background-color)",
		padding: "0.5em",
		boxShadow: "0px 0px 0px 1px rgb(0 0 0 / 30%)",
		alignItems: "center",
		whiteSpace: "pre",
		borderRadius: "var(--border-radius-sharp)"
	},
	fieldTitle: {
		display: "flex",
		width: "6.5rem",
		flexShrink: 0,
		alignItems: "center",
		wordBreak: "break-word",
		whiteSpace: "pre",
		"& > *:first-child": {
			marginRight: "0.5rem"
		}
	},
	input: {
		flexShrink: 0,
		flexGrow: 1
	}
});
