import { apiReq } from "../utils/apiReq";

export async function signInWithTeams(teamsToken: string) {
	const {
		data: { token }
	} = await apiReq("POST", "/v1/signIn/teams", { token: teamsToken });

	return token as string;
}

export async function validateDomain(domain: string) {
	const { data } = await apiReq("GET", `/v1/signIn/domain/${domain}`);

	return data;
}
