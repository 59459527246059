import { useRef } from "react";
import { useLocation, Location } from "react-router-dom";

/**
 * Keep track of the current location changes in order to provide the previous rendered location that's different
 * from the current rendered one
 * @param {location|null} currentLocation
 * @returns {Location|null}
 */
export default function usePreviousLocation(currentLocation: Location | null = null): Location | null {
	const currentLocationHook = useLocation();
	const _currentLocation = currentLocation ?? currentLocationHook;

	const lastRenderLocationRef = useRef<Location | null>(null);
	const lastLocationRef = useRef<Location | null>(null);

	if (lastRenderLocationRef.current != null && lastRenderLocationRef.current?.key !== _currentLocation.key)
		lastLocationRef.current = lastRenderLocationRef.current;

	lastRenderLocationRef.current = _currentLocation;

	return lastLocationRef.current;
}
