import React from "react";
import { ErrorBoundary } from "react-error-boundary";
import { AccessReviewReportsProvider } from "context/accessReviewReportsContext";
import { AccessReviewResourcesProvider } from "context/accessReviewResourcesContext";
import { AccessReviewSubordinatesProvider } from "context/accessReviewSubordinatesContext";
import { AccessReviewTemplatesProvider } from "context/accessReviewTemplatesContext";
import { AccessReviewsProvider } from "context/accessReviewsContext";
import { AdminsProvider } from "context/adminsContext";
import { ApiTokensProvider } from "context/apiTokensContext";
import { ApprovalAlgorithmsProvider } from "context/approvalAlgorithmsContext";
import { ApprovalFlowsProvider } from "context/approvalFlowContext";
import { BundlesProvider } from "context/bundlesContext";
import { CompanyProvider } from "context/companyContext";
import { DirectoryGroupsProvider } from "context/directoryGroupsContext";
import { GlobalErrorProvider } from "context/globalErrorContext";
import { IntegrationsProvider } from "context/integrationsContext";
import { OnCallIntegrationSchedulesProvider } from "context/onCallIntegrationSchedulesContext";
import { Provider } from "context/Provider";
import { RedirectModalProvider } from "context/redirectModalContext";
import { TasksProvider } from "context/tasksContext";
import { TicketUpdatesProvider } from "context/ticketUpdatesContext";
import { UserProvider } from "context/userContext";
import { UsersProvider } from "context/usersContext";
import { AgentTokensProvider } from "context/agentTokensContext";
import { ApplicationsProvider } from "context/applicationsContext";
import { AccessReportResourcesProvider } from "context/accessReportResourcesContext";
import { DirectoryConfigurationsProvider } from "context/directoryConfigurationsContext";
import { OverlaysProvider } from "context/overlaysContext";
import { PoliciesProvider } from "context/policiesContext";
import { AuthProvider } from "context/authContext";
import { AuditLogsWebhooksProvider } from "context/auditLogsWebhooksContext";
import { PersonalAccessTokensProvider } from "context/personalAccessTokensContext";
import { IdentityProvidersProvider } from "context/identityProvidersContext";
import { BetaProvider } from "context/betaContext";
import { BreakpointsProvider } from "context/breakpointsContext";
import { ApprovalFlowsWebhookProvider } from "context/approvalFlowsWebhooksContext";
import { SideNavProvider } from "context/sideNavContext";
import { DefaultErrorBoundary } from "./components/ErrorBoundary";

export const Providers: FC = ({ children }) => {
	return (
		<BreakpointsProvider>
			<ErrorBoundary FallbackComponent={DefaultErrorBoundary}>
				<GlobalErrorProvider>
					<AuthProvider>
						<Provider
							// The order does matter! do not change
							providers={[
								OverlaysProvider,
								ApplicationsProvider,
								UsersProvider,
								UserProvider,
								CompanyProvider,
								AdminsProvider,
								IntegrationsProvider,
								AccessReviewReportsProvider,
								AccessReviewResourcesProvider,
								AccessReviewSubordinatesProvider,
								AccessReviewTemplatesProvider,
								AccessReviewsProvider,
								ApiTokensProvider,
								ApprovalAlgorithmsProvider,
								ApprovalFlowsProvider,
								ApprovalFlowsWebhookProvider,
								BundlesProvider,
								DirectoryConfigurationsProvider,
								DirectoryGroupsProvider,
								RedirectModalProvider,
								UserProvider,
								AgentTokensProvider,
								ApiTokensProvider,
								OnCallIntegrationSchedulesProvider,
								PoliciesProvider,
								CompanyProvider,
								TasksProvider,
								TicketUpdatesProvider,
								AccessReportResourcesProvider,
								AuditLogsWebhooksProvider,
								PersonalAccessTokensProvider,
								IdentityProvidersProvider,
								BetaProvider,
								SideNavProvider
							]}>
							<>{children}</>
						</Provider>
					</AuthProvider>
				</GlobalErrorProvider>
			</ErrorBoundary>
		</BreakpointsProvider>
	);
};
