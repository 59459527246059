import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { LoadingSpinner } from "components/ui/LoadingSpinner";
import { ResourcesIcon } from "components/ui/Icons/ResourcesIcon";
import { AddIcon } from "components/ui/Icons/AddIcon";
import { Typography } from "components/ui/Typography";
import { useApprovalAlgorithms } from "hooks/useApprovalAlgorithms";
import { IntegrationResourceModel } from "models/IntegrationResourceModel";
import { IPaginatedSearchOptions } from "utils/searchUtils";
import { DESC, usePagination } from "hooks/usePagination";
import { LoadingDots } from "components/ui/LoadingDots";
import { CommonAccordion } from "components/common/CommonAccordion";
import { getIntegrationResourceRoles } from "api/integrationResourceRoles";
import { Button } from "components/ui/Button";
import useIsOpenState from "hooks/useIsOpenState";
import { Table } from "components/ui/Table";
import { PageSelect } from "components/ui/PageSelect";
import { SortableTableHeader } from "components/common/SortableTableHeader";
import { SortTableProvider, useSortTableContext } from "context/sortingTableContext";
import { RoleRow } from "./RoleRow";
import { useStyles } from "./styles";
import { CreateRoleFormModal } from "./CreateRoleFormModal";

interface IProps {
	integrationResource: IntegrationResourceModel;
	onChange?: () => Promise<void>;
	manual: boolean;
	virtual: boolean;
}

const RolesBlockContent: FC<IProps> = ({ integrationResource, onChange, manual, virtual }) => {
	const {
		state: { sortFields, sortOrder }
	} = useSortTableContext();

	const fetchRoles = useCallback(
		(paginationOptions: IPaginatedSearchOptions) =>
			getIntegrationResourceRoles(integrationResource.id, paginationOptions, false),
		[integrationResource.id]
	);

	const [expanded, setExpanded] = useState(false);
	const { open, isOpen, close } = useIsOpenState();
	const { t } = useTranslation();
	const classes = useStyles();
	const approvalAlgorithms = useApprovalAlgorithms();

	const { getPage, totalPages, items, currentPageNumber, page, setCurrentPageNumber, setPartialItem } = usePagination(
		fetchRoles,
		10,
		sortOrder,
		sortFields,
		null,
		false
	);

	useEffect(() => {
		if (currentPageNumber > totalPages) getPage(totalPages > 0 ? totalPages : currentPageNumber);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [totalPages]);

	const currentPageItems = useMemo(() => {
		return page?.toArray() || [];
	}, [page]);

	const changePage = useCallback(
		(pageIndex: number) => {
			setCurrentPageNumber(pageIndex + 1);
		},
		[setCurrentPageNumber]
	);

	const handleOnAdd = useCallback(
		(event: React.MouseEvent) => {
			event.stopPropagation();
			open();
		},
		[open]
	);

	const onSave = useCallback(async () => {
		onChange && (await onChange());
	}, [onChange]);

	if (!(approvalAlgorithms && items)) {
		return <LoadingSpinner className={classes.spinner} />;
	}

	return (
		<CommonAccordion
			title={
				<div className={classes.header}>
					<Typography variant="h3" className={classes.title} prefixIcon={<ResourcesIcon />}>
						{t("pages.integration.resource.roles")}
						<Typography relative variant="small">{`(${integrationResource.rolesCount || 0})`}</Typography>
					</Typography>
					{(manual || virtual) && (
						<Button variant="secondary" size="small" prefix={<AddIcon />} onClick={handleOnAdd}>
							{t("buttons.add")}
						</Button>
					)}
				</div>
			}
			expanded={expanded}
			onChange={setExpanded}>
			<CreateRoleFormModal resource={integrationResource} onSubmit={onSave} isOpen={isOpen} onClose={close} />
			{items && items.size && currentPageItems ? (
				<>
					<Table gridColumns="repeat(3, 2fr) 1fr 1fr">
						<Table.Row>
							<SortableTableHeader
								className={classes.headerCell}
								title={t("pages.integration.resource.role.name")}
								field="name"
							/>
							<SortableTableHeader
								className={classes.headerCell}
								title={t("pages.integration.resource.role.workflow")}
								field="approvalAlgorithm"
							/>
							<SortableTableHeader
								className={classes.headerCell}
								title={t("pages.integration.resource.role.allowedDurations")}
							/>
							<SortableTableHeader
								className={classes.headerCell}
								title={t("pages.integration.resource.role.allowsRequests")}
							/>
							<SortableTableHeader
								className={classes.headerCell}
								title={t("pages.integration.resource.role.allowAsGrantMethod")}
							/>
						</Table.Row>
						{currentPageItems.map(role => {
							return (
								<RoleRow
									key={role.id}
									role={role}
									integrationResource={integrationResource}
									onRoleChanged={setPartialItem}
								/>
							);
						})}
					</Table>
					{totalPages > 1 && (
						<PageSelect
							pagesCount={totalPages}
							pagesShownAmount={3}
							currentPageNumber={currentPageNumber}
							changePage={num => changePage(num - 1)}
						/>
					)}
				</>
			) : (
				<LoadingDots />
			)}
		</CommonAccordion>
	);
};

const MemoizedRolesBlockContent = React.memo(RolesBlockContent);

export const RolesBlock: FC<IProps> = props => (
	<SortTableProvider defaultSortField="id" defaultSortOrder={DESC} secondaryField="id">
		<MemoizedRolesBlockContent {...props} />
	</SortTableProvider>
);
