import { TicketingIntegrationTicketModel } from "models/TicketingIntegrationTicketModel";
import { apiReq } from "utils/apiReq";

export async function getTicketingIntegrationTicket(id: string) {
	const { data } = await apiReq("GET", `/v1/ticketingIntegrationTickets/${id}`, null);
	return TicketingIntegrationTicketModel.fromServerData(data as Record<string, unknown>);
}

export async function searchTicketingIntegrationTickets(
	search: string,
	idsToExclude?: string[],
	abortSignal?: AbortSignal
) {
	const idsToExcludeQuery = idsToExclude ? `&${idsToExclude.map(id => `idsToExclude=${id}`).join("&")}` : "";
	const { data } = await apiReq(
		"GET",
		`/v1/ticketingIntegrationTickets/search?search=${encodeURIComponent(search)}${idsToExcludeQuery}`,
		null,
		{ abortSignal }
	);
	return (data as Record<string, unknown>[]).map(ticket => TicketingIntegrationTicketModel.fromServerData(ticket));
}
