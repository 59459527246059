import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	container: {
		alignItems: "flex-start",
		display: "grid",
		gridTemplateColumns: "1fr auto 1fr",
		gap: "8px"
	},
	number: {
		justifySelf: "flex-end"
	},
	icon: {
		justifySelf: "center"
	}
});
