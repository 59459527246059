import React, { useMemo } from "react";
import classNames from "classnames";
import { TooltipOnOverflow } from "../TooltipOnOverflow";
import { useStyles } from "./styles";
import type { TTypographyVariant } from "../Typography";

type TProps = {
	disabled?: boolean;
	onClick?: () => void;
	prefix?: React.ReactNode;
	selected?: boolean;
	size?: "small" | "regular" | "large";
	suffix?: React.ReactNode;
};

export const Chip: FC<TProps> = ({
	children,
	className,
	disabled = false,
	innerRef,
	onClick,
	prefix,
	selected = false,
	size = "regular",
	suffix
}) => {
	const classes = useStyles();

	const isActualSelected = useMemo(() => selected && !disabled, [disabled, selected]);

	const sizeClassName = useMemo(() => {
		switch (size) {
			case "small":
				return classes.small;
			case "regular":
				return classes.regular;
			case "large":
				return classes.large;
		}
	}, [classes.large, classes.regular, classes.small, size]);

	const typographyVariant: TTypographyVariant = useMemo(() => {
		switch (size) {
			case "small":
				return isActualSelected ? "text_tny_sb" : "text_tny_reg";
			case "regular":
				return isActualSelected ? "text_sm_sb" : "text_sm_reg";
			case "large":
				return isActualSelected ? "text_title_sb" : "text_reg";
		}
	}, [size, isActualSelected]);

	return (
		<div
			className={classNames(
				classes.container,
				sizeClassName,
				{
					[classes.selected]: isActualSelected,
					[classes.disabled]: disabled,
					[classes.clickable]: !!onClick
				},
				className
			)}
			ref={innerRef}
			onClick={onClick}>
			{prefix ? <div className={classes.icon}>{prefix}</div> : null}
			<TooltipOnOverflow textVariant={typographyVariant} content={children} />
			{suffix ? <div className={classes.icon}>{suffix}</div> : null}
		</div>
	);
};
