import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	modal: {
		maxWidth: "380px"
	},
	content: {
		alignItems: "center",
		display: "flex",
		flexDirection: "column",
		gap: "var(--spacing-x5, 20px)",
		justifyContent: "center",
		width: "340px"
	},
	loadingContent: {
		extend: "content",
		height: "264px"
	},
	successContent: {
		extend: "loadingContent",
		color: "var(--color-green-400)"
	},
	errorContent: {
		extend: "content",
		height: "228px"
	},
	errorIcon: {
		color: "var(--color-red-700)"
	}
});
