import React from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { TicketingIntegrationTicketModel } from "models/TicketingIntegrationTicketModel";
import { TicketingIntegrationTicketChip } from "components/common/TicketingIntegrationTicketChip";
import { TooltipOnOverflow } from "components/ui/New/TooltipOnOverflow";
import { MultiLineEllipsis } from "components/common/MultiLineEllipsis";
import { useStyles } from "./styles";

import type { TTypographyVariant } from "components/ui/New/Typography";

const MAX_LINES = 6;
const MAX_LINES_WITH_TICKETING_TICKET = 5;

type TProps = {
	justification: string;
	ticketingIntegrationTicket?: TicketingIntegrationTicketModel | null;
	textVariant?: TTypographyVariant;
};

export const RequestJustification: FC<TProps> = ({
	className,
	innerRef,
	justification,
	ticketingIntegrationTicket,
	textVariant = "text_sm_reg"
}) => {
	const { t } = useTranslation();
	const classes = useStyles();

	return (
		<div className={classNames(classes.container, className)} ref={innerRef}>
			<TooltipOnOverflow textVariant="text_sm_sb" content={t("common.requestBar.requestJustification.title")} />
			<MultiLineEllipsis
				tooltipOnOverflow
				textVariant={textVariant}
				tooltipPlacement="bottom"
				text={justification}
				maxLines={ticketingIntegrationTicket ? MAX_LINES_WITH_TICKETING_TICKET : MAX_LINES}
			/>
			{ticketingIntegrationTicket ? (
				<TicketingIntegrationTicketChip ticketingIntegrationTicket={ticketingIntegrationTicket} />
			) : null}
		</div>
	);
};
