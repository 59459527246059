import { DoneCircleIcon } from "components/ui/Icons/DoneCircleIcon";
import { PendingIcon } from "components/ui/Icons/PendingIcon";
import { CloseCircleIcon } from "components/ui/Icons/CloseCircleIcon";
import { TTicketStatus } from "models/TicketModel";
import { TTicketPermissionStatus } from "models/TicketPermissionModel";

export const getTicketPermissionStatusIcon = (status: TTicketPermissionStatus, ticketStatus: TTicketStatus) => {
	if (status === "granted") return DoneCircleIcon.Green;
	if (status === "failed" || status === "revoked" || ticketStatus === "rejected") return CloseCircleIcon.Red;
	if (status === "pending") return PendingIcon;
	return null;
};
