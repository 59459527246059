import React, { useCallback, useMemo } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { PageTemplate } from "components/templates/PageTemplate";
import { Button } from "components/ui/Button";
import { VirtualScroll } from "components/ui/VirtualScroll";
import { exportAuditLogsToCSV } from "api/auditLogs";
import { Select } from "components/ui/Select";
import { Typography } from "components/ui/Typography";
import { TTimezone } from "utils/systemAuditLogTimezone";
import { LoadingDots } from "components/ui/LoadingDots";
import { RadioInputGroup } from "components/ui/RadioInputGroup";
import { RequestDetails } from "components/common/RequestDetails";
import { useStyles } from "./styles";
import { AuditLog } from "./components/AuditLog";
import { AuditLogsProvider, getDateOptionLabel, useAuditLogsContext } from "./auditLogsContext";
import type { TAuditLogModel } from "models/auditLogs";

const TRANSLATION_PREFIX = "pages.auditLog.";

interface IToggleOption {
	value: TTimezone;
	label: string;
}

const AUDIT_LOG_ESTIMATED_HEIGHT = 90;

const renderAuditLog = (auditLog: TAuditLogModel) => <AuditLog key={auditLog?.id} auditLog={auditLog} />;

const PageContent = () => {
	const classes = useStyles();

	const {
		state: { auditLogs, isLoadingAuditLogs, isLoadingIntegrations, pagination },
		actions: { getNextPage }
	} = useAuditLogsContext();

	const showAuditLogs = (auditLogs.length > 0 || !isLoadingAuditLogs) && !isLoadingIntegrations;

	return (
		<PageTemplate.Content className={classNames(classes.auditLogsPageContent, classes.fullWidth, classes.noPadding)}>
			{showAuditLogs && (
				<div className={classes.listContainer}>
					<VirtualScroll
						estimateSize={AUDIT_LOG_ESTIMATED_HEIGHT}
						rows={auditLogs}
						renderRow={renderAuditLog}
						canFetchMore={pagination.lastPageNumber < pagination.totalPages}
						fetchMore={getNextPage}
					/>
				</div>
			)}
			{(isLoadingAuditLogs || isLoadingIntegrations) && <LoadingDots className={classes.spinner} />}
		</PageTemplate.Content>
	);
};

const PageTitle = React.memo(function Title() {
	const { t } = useTranslation();
	const classes = useStyles();

	const {
		state: { auditLogs, timezone, dates, selectedDate, isLoadingDates, pagination },
		actions: { selectDate, setTimezone }
	} = useAuditLogsContext();

	const currentTimezoneToggleOptions: [IToggleOption, IToggleOption] = useMemo(
		() => [
			{ value: "local", label: t(`${TRANSLATION_PREFIX}timezoneToggle.local` as const) },
			{ value: "global", label: t(`${TRANSLATION_PREFIX}timezoneToggle.global` as const) }
		],
		[t]
	);

	const exportToCSV = useCallback(() => {
		selectedDate && exportAuditLogsToCSV(selectedDate, timezone);
	}, [selectedDate, timezone]);

	return (
		<PageTemplate.Title className={classes.headerContainer}>
			<div className={classes.title}>
				{t("pages.auditLog.title")}
				{selectedDate && (
					<Typography className={classes.dateText} variant="standard">
						{t(`${TRANSLATION_PREFIX}showingLogsFrom`, {
							count: auditLogs.length,
							total: pagination.totalAuditLogs,
							date: selectedDate
						})}
					</Typography>
				)}
			</div>
			<div className={classes.inputsContainer}>
				<RadioInputGroup
					title={t(`${TRANSLATION_PREFIX}timezoneToggle.title` as const)}
					className={classes.timezoneToggle}
					options={currentTimezoneToggleOptions}
					selectedValue={timezone}
					onChange={setTimezone}
				/>
				<Select
					className={classes.dateSelectInput}
					disabled={!dates || dates.length === 0}
					getOptionLabel={getDateOptionLabel}
					loading={isLoadingDates}
					onChange={selectDate}
					options={dates || []}
					placeholder={t(`${TRANSLATION_PREFIX}selectDate` as const)}
					value={selectedDate}
					required
				/>
				<Button size="medium" variant="secondary" onClick={exportToCSV}>
					{t("buttons.export")}
				</Button>
			</div>
		</PageTemplate.Title>
	);
});

export const AuditLogsPage: FC = ({ className }) => {
	return (
		<PageTemplate className={classNames("auditLogsPage", className)}>
			<RequestDetails />
			<AuditLogsProvider>
				<PageTitle />
				<PageContent />
			</AuditLogsProvider>
		</PageTemplate>
	);
};
