import React from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { DurationIcon } from "components/ui/Icons/DurationIcon";
import { Typography } from "components/ui/New/Typography";
import { TooltipOnOverflow } from "components/ui/New/TooltipOnOverflow";
import { useStyles } from "./styles";
import type { TTicketDuration } from "utils/durationsOptions";

type TProps = {
	duration: TTicketDuration;
	fixedWidth?: boolean;
};

export const RequestedDuration: FC<TProps> = ({ className, duration, innerRef, fixedWidth = false }) => {
	const { t } = useTranslation();
	const classes = useStyles({ fixedWidth });

	return (
		<div className={classNames(classes.container, className)} ref={innerRef}>
			<TooltipOnOverflow textVariant="text_sm_sb" content={t("common.requestBar.requestedDuration.title")} />
			<div className={classes.duration}>
				<DurationIcon size={20} />
				<Typography variant="title_sb">{t(`common.durations.${duration}`)}</Typography>
			</div>
		</div>
	);
};
