import partition from "lodash/partition";
import pickBy from "lodash/pickBy";
import { PartialOrNullWithOneRequired } from "utils/types";

import type { TFormData } from "../components/NewTicketForm/types";

interface INewTicketUrlParams {
	bundleIds?: string[];
	roles?: { id: string; through?: string }[];
	duration?: number;
	justification?: string;
	ticketingIntegrationTicketId?: string;
}

const legacyFieldsParser = (urlParams: URLSearchParams): INewTicketUrlParams | null => {
	const roleId = urlParams.get("roleId") ?? null;
	const bundleId = urlParams.get("bundleId") ?? null;
	if (!roleId && !bundleId) return null;

	return {
		duration: parseInt(urlParams.get("duration") || "0"),
		justification: urlParams.get("justification") ?? "",
		ticketingIntegrationTicketId: urlParams.get("ticketingIntegrationTicketId") ?? "",
		roles: roleId ? [{ id: roleId, through: urlParams.get("grantMethodId") ?? undefined }] : undefined,
		bundleIds: bundleId ? [bundleId] : undefined
	};
};

export const urlFieldsParser = (urlParams: URLSearchParams): INewTicketUrlParams | null => {
	const legacyData = legacyFieldsParser(urlParams);
	if (legacyData) return legacyData;

	const dataBase64 = urlParams.get("data");
	if (!dataBase64) return null;

	const data = dataBase64 ? JSON.parse(Buffer.from(dataBase64, "base64").toString("utf-8")) : {};
	const roles = data.roleIds;

	const bundleIds = data.bundleIds;

	return {
		duration: parseInt(data.duration || "0"),
		justification: data.justification ?? "",
		ticketingIntegrationTicketId: data.ticketingIntegrationTicketId,
		roles,
		bundleIds
	};
};

const urlFieldsCreator = (formData: PartialOrNullWithOneRequired<TFormData, "targets" | "grantMethods">) => {
	const { targets, grantMethods } = formData;
	if (!targets || !grantMethods) return null;

	const [roleTargets, bundleTargets] = partition(targets, target => target.type === "role");

	return {
		ticketingIntegrationTicketId: formData.ticketingIntegrationTicketId,
		duration: formData.duration?.toString(),
		justification: formData.justification,
		roleIds: roleTargets.length
			? roleTargets.map(target => ({
					id: target.value.id,
					through: grantMethods.get(target.value.id)?.id,
					type: "role"
				}))
			: undefined,
		bundleIds: bundleTargets.length ? bundleTargets.map(target => target.value.id) : undefined
	};
};

export const getUrlParams = (
	formData: Pick<TFormData, "duration" | "grantMethods" | "justification" | "targets" | "ticketingIntegrationTicketId">
) => {
	const fields = urlFieldsCreator(formData);
	if (!fields) return;

	const dataStr = JSON.stringify(pickBy(fields, Boolean));
	return new URLSearchParams({ data: Buffer.from(dataStr).toString("base64") }).toString();
};
