import React, { useCallback, useMemo } from "react";
import { matchSorter } from "match-sorter";
import { useDirectoryGroups } from "hooks/useDirectoryGroups";
import { TextWithDirectoryIcon } from "components/common/TextWithDirectoryIcon";
import { IRenderChipParams, MultipleSelect } from "components/ui/MultipleSelect";
import { DirectoryGroupModel } from "models/DirectoryGroupModel";
import { Typography } from "components/ui/Typography";
import { EmailTooltip } from "components/common/EmailTooltip";
import { DirectoryGroup } from "components/common/DirectoryGroup";
import { Chip } from "components/ui/Chip";
import { getNameAndIcon } from "utils/directoryGroups";
import { useRankedSort } from "hooks/useRankedSort";
import { OnCallIntegrationScheduleModel } from "models/OnCallIntegrationScheduleModel";
import { OnCallIntegrationScheduleLabel } from "components/common/OnCallIntegrationScheduleLabel";
import { useOnCallIntegrationSchedules } from "hooks/useOnCallIntegrationSchedules";
import { useCompany } from "hooks/useCompany";
import { useStyles } from "./styles";

type TGroupOrScheduleType = "group" | "schedule";
type TGroupOrScheduleIds = { type: TGroupOrScheduleType; id: string };
type TGroupOrSchedule = DirectoryGroupModel | OnCallIntegrationScheduleModel;

interface IProps {
	chipClassName?: string;
	disabled?: boolean;
	fullWidth?: boolean;
	label?: string;
	onChange: (ids: TGroupOrScheduleIds[] | null) => void;
	placeholder?: string;
	values: string[];
}

const getOptionLabel = ({ name }: TGroupOrSchedule) => name;

const isGroup = (group: TGroupOrSchedule): group is DirectoryGroupModel => group instanceof DirectoryGroupModel;

const renderChip = ({
	noBorder,
	option,
	onRemove,
	componentKey: key,
	stretch,
	onClick
}: IRenderChipParams<TGroupOrSchedule>) => {
	if (!option) return <></>;
	if (isGroup(option)) {
		return (
			<EmailTooltip key={key} email={option.email || undefined} isDeleted={option.isDeleted}>
				<Chip onDelete={onRemove} noBorder={noBorder} stretch={stretch} size="small" onClick={onClick} multiLine>
					<DirectoryGroup value={option.name} isDeleted={option.isDeleted} />
				</Chip>
			</EmailTooltip>
		);
	} else {
		return (
			<Chip
				key={key}
				onDelete={onRemove}
				noBorder={noBorder}
				stretch={stretch}
				size="small"
				onClick={onClick}
				multiLine>
				<OnCallIntegrationScheduleLabel value={option.name} onCallType={option.type} isDeleted={option.isDeleted} />
			</Chip>
		);
	}
};

const renderOptionBase = (option: TGroupOrSchedule, className: string) => {
	if (!option) return <></>;
	return (
		<div className={className}>
			{isGroup(option) ? (
				<>
					<TextWithDirectoryIcon value={option.name} isDeleted={option.isDeleted} />
					<Typography variant="tiny">{option.email}</Typography>
				</>
			) : (
				<OnCallIntegrationScheduleLabel value={option.name} onCallType={option.type} isDeleted={option.isDeleted} />
			)}
		</div>
	);
};

const SORT_OPTIONS = {
	keys: [
		{
			key: (item: TGroupOrSchedule) => (isGroup(item) ? getNameAndIcon(item.name ?? "").name : item.name),
			threshold: matchSorter.rankings.MATCHES
		}
	]
};

export const GroupsAndSchedulesSelectInput: FC<IProps> = ({
	values,
	onChange,
	className,
	label,
	disabled,
	fullWidth,
	placeholder
}) => {
	const directoryGroups = useDirectoryGroups();
	const onCallIntegrationSchedules = useOnCallIntegrationSchedules();
	const company = useCompany();
	const { sort, onInputChange } = useRankedSort<TGroupOrSchedule>(SORT_OPTIONS);
	const classes = useStyles();

	const schedulesLoadedOrNotNeeded = useMemo(() => {
		if (!company) return false;
		return company.integratedToOnCalls.size > 0 ? !!onCallIntegrationSchedules : true;
	}, [company, onCallIntegrationSchedules]);

	const isLoading = useMemo(
		() => !directoryGroups?.size || !schedulesLoadedOrNotNeeded,
		[directoryGroups, schedulesLoadedOrNotNeeded]
	);

	const options: TGroupOrSchedule[] = useMemo(() => {
		if (isLoading) return [];
		const optionFilter = (item: TGroupOrSchedule) => {
			return !item.isDeleted || values.includes(item.id);
		};
		return (directoryGroups!.filter(optionFilter).toArray() as TGroupOrSchedule[]).concat(
			onCallIntegrationSchedules?.valueSeq().filter(optionFilter).toArray() || []
		);
	}, [isLoading, directoryGroups, onCallIntegrationSchedules, values]);

	const handleChange = useCallback(
		(newValues: TGroupOrSchedule[] | null) => {
			onChange(
				newValues
					? newValues.map<TGroupOrScheduleIds>(value => ({ id: value.id, type: isGroup(value) ? "group" : "schedule" }))
					: null
			);
		},
		[onChange]
	);

	const selectedOptions = useMemo(() => {
		return options.filter(option => values.includes(option.id)) || [];
	}, [options, values]);

	const renderOption = useCallback((option: TGroupOrSchedule) => renderOptionBase(option, classes.option), [classes]);
	return (
		<MultipleSelect
			fullWidth={fullWidth}
			className={className}
			disabled={isLoading || disabled}
			onInputChange={onInputChange}
			getOptionLabel={getOptionLabel}
			label={label}
			loading={isLoading}
			onChange={handleChange}
			options={options}
			placeholder={placeholder}
			renderChip={renderChip}
			renderOption={renderOption}
			sort={sort}
			value={selectedOptions}
		/>
	);
};
