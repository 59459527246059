import React, { useCallback, useMemo } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { List, Map } from "immutable";
import { IntegrationModel } from "models/IntegrationModel";
import { GrantedIcon } from "components/ui/Icons/GrantedIcon";
import { CloseIcon } from "components/ui/Icons/CloseIcon";
import { FlagIcon } from "components/ui/Icons/FlagIcon";
import { Checkbox } from "components/ui/Checkbox";
import { Table } from "components/ui/Table";
import { Tooltip } from "components/ui/Tooltip";
import { Typography } from "components/ui/Typography";
import { TextUser } from "components/common/TextUser";
import { Unknown } from "components/ui/Unknown";
import { useIntegrations } from "hooks/useIntegrations";
import { STATUS_DICT, TPermissionStatusOptions } from "utils/accessReview";
import { AccessReviewPermissionCommentCell } from "components/common/AccessReviewPermissionCommentCell";
import { useMultipleUsers } from "hooks/useMultipleUsers";
import { TResourcePermission, useResourcePermissionsReviewContext } from "context/resourcePermissionReviewContext";
import { TicketNumberWithLink } from "components/common/TicketNumberWithLink";
import useIsOpenState from "hooks/useIsOpenState";
import { ImmediateRevokeModal } from "components/common/ImmediateRevokeModal";
import { useStyles } from "./styles";
import type { TAccessReviewPermissionStatus } from "models/AccessReviewPermissionModel";

const TRANSLATION_PREFIX = "common.resourcePermissionsReviewTable";

interface INameCellProps {
	changeCheckbox?: (id: string, isSelected: boolean) => void;
	getIsSelected?: (id: string) => boolean;
	integration?: IntegrationModel;
	permission: TResourcePermission;
	readonly?: boolean;
}

const NameCell: FC<INameCellProps> = ({ permission, getIsSelected, changeCheckbox, readonly, integration }) => {
	const classes = useStyles();
	const isSelected = useMemo(
		() => (getIsSelected ? getIsSelected(permission.resourcePermissionId) : false),
		[getIsSelected, permission.resourcePermissionId]
	);

	const onClick = useCallback(() => {
		changeCheckbox && changeCheckbox(permission.resourcePermissionId, !isSelected);
	}, [changeCheckbox, isSelected, permission.resourcePermissionId]);

	if (!permission) return null;
	const content = <Typography>{permission.roleName}</Typography>;

	return (
		<div className={classes.tableCell}>
			{readonly ? content : <Checkbox label={content} selected={isSelected} onClick={onClick} />}
		</div>
	);
};

interface IActionsCellProps {
	changeStatus?: (permissionId: string, status: Exclude<TPermissionStatusOptions, "pending">) => void;
	removeFromList?: (permissionId: string) => void;
	getPermissionStatus?: (permissionId: string) => TAccessReviewPermissionStatus;
	permission: TResourcePermission;
	immediateRevoke: boolean;
	disabled: boolean;
}

const ActionCell: FC<IActionsCellProps> = ({
	permission,
	getPermissionStatus,
	removeFromList,
	changeStatus,
	immediateRevoke,
	disabled = false
}) => {
	const { t } = useTranslation();
	const classes = useStyles();
	const { isOpen: areYouSureModalIsOpen, open: openAreYouSureModal, close: closeAreYouSureModal } = useIsOpenState();

	const status = getPermissionStatus ? getPermissionStatus(permission.resourcePermissionId) : "pending";
	const disableActions = immediateRevoke && status === "denied";

	const setNewStatus = useCallback(
		(newStatus: Exclude<TPermissionStatusOptions, "pending">) => {
			if (STATUS_DICT.get(newStatus) === status) {
				return;
			}
			changeStatus && changeStatus(permission.resourcePermissionId, newStatus);
		},
		[changeStatus, permission.resourcePermissionId, status]
	);

	const onApprove = useCallback(() => !disabled && setNewStatus("approve"), [disabled, setNewStatus]);
	const onDeny = useCallback(() => !disabled && setNewStatus("deny"), [disabled, setNewStatus]);
	const onFlag = useCallback(() => !disabled && setNewStatus("flag"), [disabled, setNewStatus]);

	const revokePermission = useCallback(async () => {
		if (immediateRevoke) {
			await onDeny();
			removeFromList && removeFromList(permission.resourcePermissionId);
		}
		closeAreYouSureModal();
	}, [closeAreYouSureModal, immediateRevoke, onDeny, permission, removeFromList]);

	return (
		<>
			{immediateRevoke && (
				<ImmediateRevokeModal
					onAction={revokePermission}
					onClose={closeAreYouSureModal}
					isOpen={areYouSureModalIsOpen}
				/>
			)}
			<div className={classNames(classes.tableCell, classes.actionsContainer)}>
				<Tooltip content={t(`${TRANSLATION_PREFIX}.approve`)}>
					<div
						className={classNames(classes.button, classes.approveButton, {
							[classes.selected]: status === "approved",
							[classes.disabled]: disableActions
						})}
						onClick={onApprove}>
						<GrantedIcon />
					</div>
				</Tooltip>
				<Tooltip content={t(`${TRANSLATION_PREFIX}.deny`)}>
					<div
						className={classNames(classes.button, classes.denyButton, {
							[classes.selected]: status === "denied",
							[classes.disabled]: disableActions
						})}
						onClick={immediateRevoke ? openAreYouSureModal : onDeny}>
						<CloseIcon />
					</div>
				</Tooltip>
				<Tooltip content={t(`${TRANSLATION_PREFIX}.flag`)}>
					<div
						className={classNames(classes.button, classes.flagButton, {
							[classes.selected]: status === "flagged",
							[classes.disabled]: disableActions
						})}
						onClick={onFlag}>
						<FlagIcon />
					</div>
				</Tooltip>
			</div>
		</>
	);
};

export const PermissionTableRow: FC<{
	permission: TResourcePermission;
	permissionExpirations: Map<string, string>;
	immediateRevoke: boolean;
	changeCheckbox?: (permissionId: string, checked: boolean) => void;
	removeCheckbox?: (permissionId: string) => void;
	getIsSelected?: (permissionId: string) => boolean;
	readonly?: boolean;
}> = ({
	permission,
	permissionExpirations,
	changeCheckbox,
	removeCheckbox,
	getIsSelected,
	readonly,
	immediateRevoke
}) => {
	const classes = useStyles();
	const { t } = useTranslation();
	const {
		actions: { setSinglePermission, getPermissionStatus, setComment },
		state: { resource }
	} = useResourcePermissionsReviewContext();
	const integrations = useIntegrations();
	const integration = integrations?.get(resource?.integrationResource?.integrationId || "");
	const integrationActorUsersIds = permission.integrationActorPermission?.integrationActor?.userIds || List<string>();
	const { usersList: integrationActorUsers } = useMultipleUsers(integrationActorUsersIds);
	const disableRow = useMemo(
		() => permission.status === "denied" && immediateRevoke,
		[immediateRevoke, permission.status]
	);

	const onCommentChange = useCallback(
		async (permissionId: string, comment: string) => {
			if (readonly) return;
			await setComment([permissionId], comment);
		},
		[readonly, setComment]
	);

	return (
		<Table.Row key={permission.resourcePermissionId}>
			<Table.Cell>
				<NameCell
					permission={permission}
					changeCheckbox={changeCheckbox}
					getIsSelected={getIsSelected}
					readonly={readonly || disableRow}
					integration={integration}
				/>
			</Table.Cell>
			<Table.Cell>
				<Typography>{permission.actorName}</Typography>
			</Table.Cell>
			<Table.Cell>
				<Typography>
					{integrationActorUsers.map((user, index, list) => (
						<React.Fragment key={user.id}>
							<TextUser user={user} />
							{index < list?.size - 1 ? ", " : ""}
						</React.Fragment>
					))}
				</Typography>
			</Table.Cell>
			<Table.Cell className={classes.ticketsCell}>
				{permission.integrationActorPermission?.ticketPermissions?.map(({ ticket }) =>
					ticket ? <TicketNumberWithLink key={ticket.id} ticketId={ticket.id} ticketNumber={ticket.number} /> : null
				)}
			</Table.Cell>
			<Table.Cell>
				<Typography>{permissionExpirations.get(permission.resourcePermissionId)}</Typography>
			</Table.Cell>
			<Table.Cell>
				<Typography>
					<Unknown unknown={!permission?.integrationActorPermission?.integrationActor?.lastUsed}>
						{t("dateTime.date", { date: permission.integrationActorPermission!.integrationActor!.lastUsed })}
					</Unknown>
				</Typography>
			</Table.Cell>
			<Table.Cell noPadding>
				<AccessReviewPermissionCommentCell
					readonly={readonly}
					comments={permission.comments}
					onSubmit={comment => onCommentChange(permission.id, comment)}
				/>
			</Table.Cell>
			<Table.Cell>
				{readonly ? (
					<Typography>
						{t(`common.accessReview.statuses.permissions.${getPermissionStatus(permission.resourcePermissionId)}`)}
					</Typography>
				) : (
					<ActionCell
						permission={permission}
						changeStatus={setSinglePermission}
						getPermissionStatus={getPermissionStatus}
						immediateRevoke={immediateRevoke}
						removeFromList={removeCheckbox}
						disabled={disableRow}
					/>
				)}
			</Table.Cell>
		</Table.Row>
	);
};
