import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { AreYouSureModal } from "components/common/AreYouSureModal";

type TProps = {
	isOpen: boolean;
	close: () => void;
};

export const RemoveAllTargetsModal: FC<TProps> = ({ className, innerRef, isOpen, close }) => {
	const { t } = useTranslation("translation", { keyPrefix: "pages.newRequest.summaryStep.removeAllTargetsModal" });

	const navigate = useNavigate();

	const goBack = useCallback(() => {
		close();
		navigate(-1);
	}, [close, navigate]);

	return (
		<AreYouSureModal
			className={className}
			innerRef={innerRef}
			isOpen={isOpen}
			onClose={close}
			onCancel={goBack}
			onAction={close}
			actionLabel={t("actionLabel")}
			closeLabel={t("cancelLabel")}
			content={t("content")}
		/>
	);
};
