import React, { useMemo } from "react";
import classNames from "classnames";
import { TooltipOnOverflow } from "components/ui/TooltipOnOverflow";
import { useStyles } from "./styles";

type TProps =
	| {
			content: React.ReactNode;
			actions?: React.ReactNode;
			selected?: boolean;
	  }
	| {
			header: React.ReactNode;
			content: React.ReactNode;
			selected?: boolean;
	  };

export const IntegrationNode: FC<TProps> = props => {
	const classes = useStyles();
	const { className, innerRef, selected } = props;
	const displayElement = useMemo(() => {
		if ("header" in props) {
			const { header, content } = props;
			return (
				<>
					<div className={classes.integrationHeaderContainer}>{header}</div>
					{content}
				</>
			);
		} else {
			const { content, actions } = props;
			const isStringContent = typeof content === "string";
			const hasActions = !!actions;
			const contentElement = isStringContent ? <TooltipOnOverflow textVariant="standard" content={content} /> : content;
			return (
				<div className={classes.contentContainer}>
					{hasActions ? (
						<div className={classes.contentWithActions}>
							<div className={classes.alignedContent}>{contentElement}</div>
							<div className={classes.alignedContent}>{actions}</div>
						</div>
					) : (
						<div className={classes.alignedContent}>
							{isStringContent ? <TooltipOnOverflow textVariant="standard" content={content} /> : content}
						</div>
					)}
				</div>
			);
		}
	}, [classes, props]);

	if (!displayElement) return null;
	return (
		<div className={classNames(classes.container, { [classes.selected]: selected }, className)} ref={innerRef}>
			{displayElement}
		</div>
	);
};
