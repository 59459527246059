import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	ticketingIntegrationTicketChip: {
		maxWidth: "240px",
		fontSize: "var(--typography-tiny-font-size)",
		" & svg": {
			height: "var(--typography-tiny-font-size)",
			width: "var(--typography-tiny-font-size)"
		},
		"&$ticketingIntegrationTicketChip$ticketingIntegrationTicketChip": {
			padding: "2px"
		}
	},
	ticketingIntegrationTicketChipContent: {
		textOverflow: "ellipsis",
		overflow: "hidden",
		whiteSpace: "nowrap"
	}
});
