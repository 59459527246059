import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Select, ISelectProps } from "components/ui/Select";
import { useUsers } from "hooks/useUsers";
import { UserModel } from "models/UserModel";
import { useIntegrations } from "hooks/useIntegrations";
import { InheritOwnerOption } from "components/common/InheritOwnerOption";
import { UserWithEmail } from "components/common/UserWithEmail";
import { UserOption } from "components/common/UserOption";

interface IProps {
	autoAssigned?: boolean;
	integrationId?: string;
	ownerId: string | null;
	required?: boolean;
	selectInputProps?: Partial<TProps<ISelectProps<TOwnerOption>>>;
	setOwner: (owner: UserModel | null) => void | Promise<void>;
	showLabel?: boolean;
}

type TOwnerOption = {
	user: UserModel;
	optionValue: string | null;
};

export const OwnerSelect: FC<IProps> = ({
	autoAssigned,
	integrationId,
	ownerId,
	required = true,
	selectInputProps,
	setOwner,
	showLabel = true
}) => {
	const { t } = useTranslation();
	const users = useUsers(false);
	const integrations = useIntegrations();
	const integration = integrations?.get(integrationId || "");

	const userId = useMemo(() => ownerId || integration?.ownerId, [integration, ownerId]);
	const user = useMemo(() => (userId ? users?.get(userId) : undefined), [users, userId]);

	const owner: TOwnerOption | null = useMemo(() => (user ? { user, optionValue: ownerId } : null), [user, ownerId]);
	const ownerOptions: TOwnerOption[] = useMemo(() => {
		const userOptions: TOwnerOption[] =
			users
				?.toList()
				.toArray()
				.map(usr => {
					return {
						user: usr,
						optionValue: usr.id
					};
				}) || [];
		const integrationOwner = integration?.ownerId ? users?.get(integration.ownerId) : undefined;
		const defaultOptions: TOwnerOption[] = integrationOwner
			? [
					{
						user: integrationOwner,
						optionValue: null
					}
				]
			: [];
		return defaultOptions.concat(userOptions);
	}, [integration, users]);
	const onOwnerChange = useCallback(
		(option: TOwnerOption | null) => {
			if (!users) return;
			setOwner(users.find(({ id }) => id === option?.optionValue) || null);
		},
		[setOwner, users]
	);

	return (
		<Select
			{...selectInputProps}
			disabled={ownerOptions?.length === 0}
			fullWidth
			getOptionKey={option => `${option.user.id}-${option.optionValue}`}
			getOptionLabel={getFullNameLabel}
			hint={autoAssigned ? t("pages.integrationResource.ownerAutoAssigned") : undefined}
			isOptionEqualToValue={userEquality}
			label={showLabel ? t("pages.integration.owner") : undefined}
			loading={!users}
			onChange={onOwnerChange}
			options={ownerOptions}
			renderLabel={renderLabel}
			renderOption={renderUserOption}
			required={required}
			value={owner || null}
		/>
	);
};

const renderUserOption = (option: TOwnerOption) =>
	option.optionValue === null ? (
		<InheritOwnerOption emphasis="text" user={option.user} />
	) : (
		<UserWithEmail user={option.user} />
	);
const userEquality = (option: TOwnerOption, value: TOwnerOption) => option.optionValue === value.optionValue;
const getFullNameLabel = (option: TOwnerOption) => option.user.fullName;
const renderLabel = (option: TOwnerOption | null) => {
	return option ? (
		option.optionValue ? (
			<UserOption option={option.user} noEmail />
		) : (
			<InheritOwnerOption emphasis="user" user={option.user} />
		)
	) : (
		<></>
	);
};
