import React, { useMemo } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { PageTitleContent } from "components/templates/PageTitleContentTemplate";
import { useUser } from "hooks/useUser";
import { useCompany } from "hooks/useCompany";
import { LinkButton } from "components/ui/Button";
import { ReportIcon } from "components/ui/Icons/ReportIcon";
import { TaskIcon } from "components/ui/Icons/TaskIcon";
import { AddIcon } from "components/ui/Icons/AddIcon";
import { routes } from "routes/routes";
import { useTasks } from "hooks/useTasks";
import { RequestDetails } from "components/common/RequestDetails";
import { MyPermissionsSection } from "./components/MyPermissionsSection";
import { PastRequestsSection } from "./components/PastRequestsSection";
import { PendingRequestsSection } from "./components/PendingRequestsSection";
import { AwaitingApprovalSection } from "./components/AwaitingApprovalSection";

export const RequestsPage: FC = ({ className, innerRef }) => {
	const { t } = useTranslation();
	const { user } = useUser();
	const company = useCompany();
	const tasks = useTasks();

	const showTasksButton = useMemo(() => {
		if (!user || user.isAdmin || !tasks.tasks) return false;
		return tasks.tasks.some(task => task.assignedUserId === user.id && task.status !== "done");
	}, [tasks, user]);

	const showAccessReviewButton = useMemo(() => {
		if (!company || !user || user.isAdmin) return false;
		return Boolean(user.hasAccessReviewReports && company.enableAccessReviews);
	}, [company, user]);

	return (
		<PageTitleContent innerRef={innerRef} className={classNames(className)}>
			<RequestDetails />
			<PageTitleContent.Header>
				<PageTitleContent.Header.Bottom>
					<PageTitleContent.Header.Title title={t("pages.requests.title")} />
					<PageTitleContent.Header.Actions>
						{showTasksButton ? (
							<LinkButton size="large" variant="text" prefix={<TaskIcon />} to={routes.tasks.main}>
								{t("pages.requests.tasks")}
							</LinkButton>
						) : null}
						{showAccessReviewButton ? (
							<LinkButton size="large" variant="text" prefix={<ReportIcon />} to={routes.accessReport.main}>
								{t("pages.requests.accessReview")}
							</LinkButton>
						) : null}
						<LinkButton size="large" prefix={<AddIcon />} to={routes.newRequest.main}>
							{t("buttons.newRequest")}
						</LinkButton>
					</PageTitleContent.Header.Actions>
				</PageTitleContent.Header.Bottom>
			</PageTitleContent.Header>
			<PageTitleContent.Body>
				<AwaitingApprovalSection />
				<PendingRequestsSection />
				<MyPermissionsSection />
				<PastRequestsSection />
			</PageTitleContent.Body>
		</PageTitleContent>
	);
};
