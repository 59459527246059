import React, { useMemo } from "react";
import classNames from "classnames";
import { IconPrefix } from "components/ui/IconPrefix";
import { Tooltip } from "components/ui/Tooltip";
import { TitleBody } from "components/ui/TitleBody";
import { useTranslation } from "react-i18next";
import { Checkbox } from "components/ui/Checkbox";
import { IntegrationIcon } from "components/ui/Icons/IntegrationIcon";
import { Typography } from "components/ui/New/Typography";
import { RoleBarTag } from "../RoleBarTag";
import { useStyles } from "./styles";

type TWithCheckbox = {
	checkboxValue: boolean;
	onToggle: () => void;
};

type TWithoutCheckbox = {
	checkboxValue?: never;
	onToggle?: never;
};

type TSingleIntegrationProps = (TWithCheckbox | TWithoutCheckbox) & {
	name: string;
	imageUrl?: string | null;

	amount?: never;
};

type TMultipleIntegrationProps = {
	amount: number;

	name?: never;
};

type TProps = (TSingleIntegrationProps | TMultipleIntegrationProps) & {
	tags?: string[];
};

export const RoleBarIntegration: FC<TProps> = ({ className, innerRef, tags, ...restProps }) => {
	const classes = useStyles();
	const { t } = useTranslation("translation", { keyPrefix: "common.roleBar.roleBarIntegration" });
	const isAmount = "amount" in restProps;
	const amount = restProps.amount || 0;

	const tooltipContent = useMemo(() => {
		return (
			<div className={classes.tooltipContainer}>
				<TitleBody size="small" title={isAmount ? t("amount") : t("name")} body={isAmount ? amount : restProps.name} />
			</div>
		);
	}, [amount, classes.tooltipContainer, isAmount, restProps.name, t]);
	const checkbox = useMemo(() => {
		if (isAmount || !restProps.onToggle) return null;
		return <Checkbox selected={restProps.checkboxValue} onClick={restProps.onToggle} />;
	}, [isAmount, restProps]);

	return (
		<div
			className={classNames(classes.container, { [classes.withCheckbox]: "onToggle" in restProps }, className)}
			ref={innerRef}>
			{checkbox}
			<div className={classes.innerContainer}>
				<Tooltip content={tooltipContent}>
					{"imageUrl" in restProps && restProps.imageUrl ? (
						<div className={classes.integrationWithImage}>
							<img src={restProps.imageUrl} />
							<Typography noWrap variant="body_reg">
								{restProps.name}
							</Typography>
						</div>
					) : (
						<IconPrefix Icon={IntegrationIcon} content={isAmount ? amount : restProps.name} />
					)}
				</Tooltip>
				{tags ? <RoleBarTag tags={tags} /> : null}
			</div>
		</div>
	);
};
