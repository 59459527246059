import { TOP_NAV_HEIGHT_PX } from "components/App/components/Layout/components/TopNav/styles";
import { createUseStyles } from "react-jss";
import { BP_MEDIA_QUERIES } from "utils/breakpoints";

export const useStyles = createUseStyles({
	container: {
		alignItems: "flex-start",
		backgroundColor: "var(--color-blue-100)",
		border: "3px solid var(--color-grey-400)",
		borderRadius: "12px 12px 40px 40px",
		display: "flex",
		flexDirection: "column",
		justifyContent: "flex-start",
		maxHeight: `calc(90vh - ${TOP_NAV_HEIGHT_PX}px)`,
		overflow: "hidden",
		width: "100%"
	},
	containerHeader: {
		alignItems: "flex-start",
		display: "flex",
		flexDirection: "column",
		justifyContent: "flex-start",
		width: "100%"
	},
	headerTop: {
		alignItems: "center",
		alignSelf: "stretch",
		borderBottom: "3px solid var(--color-grey-400)",
		display: "flex",
		gap: "var(--spacing-x3, 12px)",
		height: "100px",
		padding: "var(--spacing-x3, 12px) var(--spacing-x3, 12px) var(--spacing-x3, 12px) var(--spacing-x7, 28px)"
	},
	headerTopTitleDescription: {
		alignItems: "center",
		alignSelf: "stretch",
		display: "flex",
		flex: "1 0 0",
		gap: "var(--spacing-x8, 32px)"
	},
	headerTopActions: {
		alignItems: "flex-start",
		alignSelf: "stretch",
		display: "flex",
		justifyContent: "flex-end"
	},
	headerBottom: {
		alignItems: "center",
		alignSelf: "stretch",
		display: "flex",
		justifyContent: "space-between",
		padding: "var(--spacing-x3, 12px) var(--spacing-x4, 16px) var(--spacing-x3, 12px) var(--spacing-x7, 28px)"
	},
	headerBottomInfo: {
		alignItems: "center",
		display: "flex",
		gap: "var(--spacing-x8, 32px)"
	},
	headerBottomActions: {
		alignItems: "flex-end",
		display: "flex",
		gap: "var(--spacing-x2, 8px)",
		justifyContent: "center"
	},
	headerBottomInfoCountLine: {
		alignItems: "center",
		display: "flex",
		gap: "var(--spacing-x2, 8px)"
	},
	containerBody: {
		display: "grid",
		gap: "var(--spacing-x4)",
		gridAutoRows: "max-content",
		gridTemplateColumns: "repeat(2, 1fr)",
		minHeight: "192px",
		overflowY: "auto",
		padding: "var(--spacing-x4, 16px)",
		width: "100%",
		[BP_MEDIA_QUERIES.FOURTH]: {
			gridTemplateColumns: "repeat(3, 1fr)"
		},
		[BP_MEDIA_QUERIES.THIRD]: {
			gridTemplateColumns: "repeat(4, 1fr)"
		},
		[BP_MEDIA_QUERIES.SECOND]: {
			gridTemplateColumns: "repeat(5, 1fr)"
		},
		[BP_MEDIA_QUERIES.FIRST]: {
			gridTemplateColumns: "repeat(6, 1fr)"
		}
	},
	searchInput: {
		background: "var(--color-white)"
	},
	clickable: {
		cursor: "pointer"
	}
});
