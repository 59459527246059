import { useEffect, useState } from "react";

import { useOnMount } from "./useOnMount";

/**
 * Reactive media query hook that returns the truthy value of the media query.
 *
 * @param {string} query
 * @returns {boolean} boolean value of the query
 *
 */
export function useMediaQuery(query: string): boolean {
	const [matches, setMatches] = useState(false);

	useOnMount(() => {
		setMatches(window.matchMedia(query).matches);
	});

	useEffect(() => {
		const mediaQuery = window.matchMedia(query);
		const handler = (event: MediaQueryListEvent) => {
			setMatches(event.matches);
		};

		if (mediaQuery.addEventListener) {
			mediaQuery.addEventListener("change", handler);
			// Old Browsers support
		} else if (mediaQuery.addListener) {
			mediaQuery.addListener(handler);
		}
		return () => {
			if (mediaQuery.removeEventListener) {
				mediaQuery.removeEventListener("change", handler);
				// Old Browsers support
			} else if (mediaQuery.removeListener) {
				mediaQuery.removeListener(handler);
			}
		};
	}, [query]);

	return matches;
}
