import classNames from "classnames";
import _groupBy from "lodash/groupBy";
import { useControlled } from "hooks/useControlled";
import { useOnClickOutside } from "hooks/useOnClickOutside";
import { SAME_WIDTH_MODIFIER, useTooltip } from "hooks/useTooltip";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Typography } from "components/ui/Typography";
import {
	cleanString,
	getGroups,
	getLabel,
	getOptionKey,
	getSuffix,
	sortOptions,
	TRenderOption
} from "utils/selectUtils";
import { SelectItem } from "components/ui/Select/components/SelectItem";
import uniqueId from "lodash/uniqueId";
import { basicSort } from "utils/sortUtils";
import { ResizableInput } from "../ResizableInput";
import { Chips } from "./components/Chips";
import { useStyles } from "./styles";
import type { IBaseInputProps } from "components/ui/Input";

export type TTargetValue = { target: { value: string } };
export type TSize = "tiny" | "small" | "standard";

export interface IRenderChipParams<T> {
	componentKey: string;
	className?: string;
	noBorder?: boolean;
	onClick?: (event: React.MouseEvent) => void;
	onRemove?: () => void;
	option: T;
	stretch?: boolean;
}

interface IProps<T> {
	chipsLimit?: number; // default: 2. limit the number of chips shown
	debug?: boolean; // default: false. set to true to make select stay open (development only).
	defaultValue?: T[]; // default: null. default selected value.
	disabled?: boolean; // default: false. set to true to disable the select.
	errors?: string[]; // default: null. array of error messages.
	filter?: ((options: T[], inputValue: string) => T[]) | null; // default: by label. set the function you want to use to filter options by, use null for no filtering.
	fullWidth?: boolean; // default: false. make input grow as wide as it has.
	getOptionLabel?: (option: T) => string; // default: option.label || String(option) || "". how to get label by option.
	getQuery?: (value: string) => string;
	groupBy?: (option: T) => string; // default: null. set the function you want to use to group options by.
	hint?: string; // default: null. hint to display below the input
	inputValue?: string; // default: undefined. set input value from props (Controlled)
	isOptionDisabled?: (option: T) => boolean; // default: false, set the function you want to use to disable options.
	isOptionEqualToValue?: (option: T, value: T) => boolean; // default: ===, equality comparator for options.
	label?: string | JSX.Element; // default: "". label for select.
	limit?: number; // default: 30. limit the number of options displayed.
	loading?: boolean; // default: false. show loading indicator.
	multiLine?: boolean; // default: false. chip should be multiLine
	noCollapse?: boolean; // default: false. set to true to disable collapsing of chips.
	noResultsText?: string; // default: "No options found". text to display when no results found.
	onChange?: (value: T[] | null) => void; // default: undefined. callback on value change.
	onInputChange?: (event: (React.ChangeEvent<HTMLInputElement> & TTargetValue) | TTargetValue) => void; // default: undefined. callback on input value change.
	options: T[]; // required. options to display.
	placeholder?: string; // default: "". placeholder for the select.
	renderChip?: (params: IRenderChipParams<T>) => JSX.Element; // default: null. render function for each chip.
	renderOption?: TRenderOption<T>; // default: undefined. how to render option.
	required?: boolean; // default: false. set to true to make input required.
	slimPadding?: boolean; // default: false. set true slims the paddings of the input
	sort?: ((options: T[]) => T[]) | null; // default: undefined. set undefined for default sort. set to null if the options need no sort. set to function if options need to be sorted by the function.
	suffix?: JSX.Element; // default: undefined. icon to show after input.
	validators?: Array<(value: string) => string | null>;
	value?: T[] | null; // default: undefined. set value from props (Controlled).
	variant?: "box" | "line"; // default: "box". variant of select.
	small?: boolean; // default false, use small input
	onFocus?: () => void;
	hideOptionsList?: boolean; // default false
}

function MultipleSelect<T>(props: TProps<IProps<T> & Omit<IBaseInputProps, keyof IProps<T>>>) {
	const {
		chipsLimit = 2,
		className,
		debug,
		defaultValue = null,
		disabled = false,
		errors = null,
		filter,
		fullWidth = false,
		getOptionLabel: propGetOptionLabel = getLabel,
		getQuery = option => option,
		groupBy,
		hint = null,
		id: propId,
		inputValue: propInputValue,
		isOptionDisabled: propIsOptionDisabled,
		isOptionEqualToValue = (option, currentValue) => option === currentValue,
		label = "",
		limit = 30,
		loading = false,
		noCollapse = false,
		noResultsText,
		multiLine = false,
		onChange: propOnChange,
		onFocus: propOnFocus,
		onInputChange: propOnInputChange,
		options: propOptions,
		placeholder,
		renderChip: propRenderChip,
		renderOption: propRenderOption,
		required = false,
		slimPadding,
		sort = sortOptions,
		suffix: propSuffix,
		validators = [],
		value: propValue,
		variant = "box",
		small,
		hideOptionsList = false
	} = props;

	const [id] = useState(() => propId || uniqueId());
	const selectRef = useRef<HTMLDivElement>(null);
	const inputRef = useRef<HTMLInputElement>(null);
	const inputContainerRef = useRef<HTMLDivElement>(null);
	const [value, setValue] = useControlled<T[] | null>({
		controlled: propValue,
		default: defaultValue
	});
	const [inputValue, setInputValue] = useControlled<string>({ controlled: propInputValue, default: "" });
	const [open, setOpen] = useState(false);
	const [highlightedIndex, setHighlightedIndex] = useState<number>(value && value.length > 0 ? -1 : 0);
	const [isDirty, setIsDirty] = useState(false);
	const [isTouched, setIsTouched] = useState(false);
	const [isFocused, setIsFocused] = useState(false);
	const [errorMessages, setErrorMessages] = useControlled<string[] | null>({
		controlled: errors,
		default: undefined
	});
	const classes = useStyles({ fullWidth });
	const {
		visible,
		setTooltipRef,
		getTooltipProps,
		setTriggerRef,
		popperProps: { update: updatePopper }
	} = useTooltip({
		visible: open,
		offset: [0, 6],
		placement: "bottom",
		popperOptions: {
			modifiers: [SAME_WIDTH_MODIFIER]
		}
	});

	const handleOpen = useCallback(() => setOpen(true), []);
	const handleClose = useCallback(() => {
		if (!debug) {
			setOpen(false);
			setHighlightedIndex(value ? -1 : 0);
		}
	}, [debug, value]);
	useOnClickOutside(selectRef, handleClose);

	const options = useMemo(() => {
		const newOptions = sort !== null ? (sort(propOptions) as T[]) || propOptions : propOptions;

		if (groupBy) {
			const groups = _groupBy(newOptions, groupBy);
			const groupMap = new Map(Object.entries(groups));
			const sortedGroupNames = basicSort(Object.keys(groups), []);
			return sortedGroupNames.flatMap(groupName => groupMap.get(groupName) || []);
		}

		return newOptions;
	}, [groupBy, propOptions, sort]);

	const getOptionLabel = useCallback(
		(option: T) => {
			if (!option) return "";
			const optionLabel = propGetOptionLabel(option);
			return typeof optionLabel === "string" ? optionLabel : "";
		},
		[propGetOptionLabel]
	);

	const renderOption = useCallback(
		(option: T, index: number) => {
			if (propRenderOption) {
				return propRenderOption(option, index);
			}
			return <>{getOptionLabel(option)}</>;
		},
		[getOptionLabel, propRenderOption]
	);

	const resetInputValue = useCallback(
		(event?: React.SyntheticEvent | null) => {
			if (inputValue === "") {
				return;
			}
			setInputValue("");
			if (propOnInputChange) {
				propOnInputChange(event ? { ...event, target: { ...event.target, value: "" } } : { target: { value: "" } });
			}
			updatePopper?.();
		},
		[inputValue, propOnInputChange, setInputValue, updatePopper]
	);

	const validate = useCallback(
		(toValidate: string) => {
			return validators?.map(validator => validator(toValidate) || "").filter(validator => validator !== "");
		},
		[validators]
	);

	const handleValue = useCallback(
		(newValue: T[] | null) => {
			if (value?.length === newValue?.length && value?.every((val, i) => val === newValue?.[Number(i)])) {
				return;
			}
			if (propOnChange) {
				propOnChange(newValue);
			}
			setValue(newValue);
		},
		[propOnChange, setValue, value]
	);

	const selectNewValue = useCallback(
		(event: React.SyntheticEvent, newValue: T) => {
			const newValueArray = value?.slice() || [];
			const itemIndex = newValueArray.findIndex(valueItem => isOptionEqualToValue(newValue, valueItem));

			if (itemIndex === -1) {
				newValueArray.push(newValue);
			} else {
				newValueArray.splice(itemIndex, 1);
			}
			resetInputValue(event);
			handleValue(newValueArray);
			handleClose();
		},
		[handleClose, handleValue, isOptionEqualToValue, resetInputValue, value]
	);

	const handleFocus = useCallback(
		(event?: React.FocusEvent<HTMLInputElement>) => {
			if (propOnFocus && event) {
				propOnFocus();
			}

			if (open) return;

			setIsFocused(true);
			setIsTouched(true);

			handleOpen();
		},
		[propOnFocus, open, handleOpen]
	);

	const handleBlur = useCallback(
		(event: React.SyntheticEvent) => {
			resetInputValue(event);
			setIsFocused(false);

			if (!open) return;

			handleClose();
		},
		[handleClose, open, resetInputValue]
	);

	const handleInputChange = useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			const newInputValue = event.target.value;
			if (newInputValue !== inputValue) {
				setInputValue(newInputValue);
				if (propOnInputChange) {
					propOnInputChange(event);
				}
			}

			const validationErrors = validate(newInputValue);
			setIsDirty(true);
			setErrorMessages(validationErrors ?? null);

			if (newInputValue) {
				handleOpen();
			}
			updatePopper?.();
		},
		[handleOpen, inputValue, propOnInputChange, setErrorMessages, setInputValue, validate, updatePopper]
	);

	const handleMouseDown = useCallback(
		(event: React.MouseEvent<HTMLDivElement>) => {
			const target = event.target as HTMLElement;
			if (target !== selectRef.current && target !== inputRef.current) {
				event.preventDefault();
			}

			if (target === inputContainerRef.current) {
				handleFocus();
			}
		},
		[handleFocus]
	);

	const handleInputMouseDown = useCallback(() => {
		if (inputValue === "" || !open) {
			if (open) {
				handleClose();
			} else {
				handleOpen();
			}
		}
	}, [handleClose, handleOpen, inputValue, open]);

	const onClick = useCallback(() => {
		inputRef.current?.focus();
	}, []);

	const handleClear = useCallback(
		(event: React.SyntheticEvent) => {
			handleValue([]);
			resetInputValue(event);
			event.stopPropagation();
		},
		[handleValue, resetInputValue]
	);

	const filteredOptions = useMemo(() => {
		if (filter === null) {
			return options.slice(0, limit);
		}
		const cleanInputValue = inputValue ? cleanString(getQuery(inputValue)) : inputValue;
		if (cleanInputValue && cleanInputValue.length > 0) {
			if (filter) {
				return filter(options, cleanInputValue).slice(0, limit);
			}
			if (groupBy) {
				return options
					.filter(
						option =>
							cleanString(groupBy(option)).includes(cleanInputValue) ||
							cleanString(getOptionLabel(option)).includes(cleanInputValue)
					)
					.slice(0, limit);
			}
			return options.filter(option => cleanString(getOptionLabel(option)).includes(cleanInputValue)).slice(0, limit);
		}
		return options?.slice(0, limit);
	}, [filter, getQuery, inputValue, options, limit, groupBy, getOptionLabel]);

	const onKeyDown = useCallback(
		(event: React.KeyboardEvent<HTMLInputElement>) => {
			if (event.key === "ArrowUp") {
				setHighlightedIndex(Math.max(highlightedIndex - 1, 0));
				event.preventDefault();
			} else if (event.key === "ArrowDown") {
				setHighlightedIndex(Math.min(highlightedIndex + 1, limit - 1, filteredOptions.length - 1));
				event.preventDefault();
			} else if (event.key === "Enter") {
				if (highlightedIndex === -1) {
					return;
				}
				const newValue = filteredOptions.at(highlightedIndex);
				if (newValue) {
					selectNewValue(event, newValue);
				}
			}
		},
		[highlightedIndex, limit, filteredOptions, selectNewValue]
	);

	const isOptionDisabled = useCallback(
		(option: T) => {
			if (propIsOptionDisabled) {
				return propIsOptionDisabled(option);
			}
			return false;
		},
		[propIsOptionDisabled]
	);

	const suffixIcon = useMemo(() => {
		const hasInputValue = inputValue ? inputValue.length > 0 : false;
		const hasValue = value ? value.length > 0 : false;
		const canClear = (hasInputValue || hasValue) && !required;
		return getSuffix({
			disabled,
			handleClear,
			handleClose,
			handleOpen,
			loading,
			open,
			showClear: canClear,
			suffixClassName: classes.suffix,
			suffixClearClassName: classes.suffixClear,
			suffix: propSuffix
		});
	}, [
		classes.suffix,
		classes.suffixClear,
		disabled,
		handleClear,
		handleClose,
		handleOpen,
		inputValue,
		loading,
		open,
		propSuffix,
		required,
		value
	]);

	const removeChip = useCallback(
		(option: T) => {
			const newValue = value?.filter(val => !isOptionEqualToValue(val, option)) || [];
			handleValue(newValue);
		},
		[handleValue, isOptionEqualToValue, value]
	);

	const chips = useMemo(
		() => (
			<Chips
				getOptionLabel={getOptionLabel}
				noCollapse={noCollapse}
				onRemove={removeChip}
				ChipElement={propRenderChip}
				values={value || null}
				limit={chipsLimit}
				multiLine={multiLine}
			/>
		),
		[getOptionLabel, noCollapse, removeChip, propRenderChip, value, chipsLimit, multiLine]
	);

	const renderInput = useCallback(
		(props: React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>) => {
			const valuePresent = value?.length;
			return (
				<div className={classes.inputChipsContainer} ref={inputContainerRef}>
					{chips}
					<input
						{...props}
						id={id}
						className={classNames(classes.inputHTMLComponent, { hide: !isFocused && valuePresent })}
						disabled={disabled}
						onBlur={handleBlur}
						onChange={handleInputChange}
						onFocus={handleFocus}
						onKeyDown={onKeyDown}
						onMouseDown={handleInputMouseDown}
						placeholder={valuePresent ? undefined : placeholder}
						ref={inputRef}
						required={required}
						spellCheck={false}
						value={inputValue || ""}
						autoComplete="off"
					/>
				</div>
			);
		},
		[
			value?.length,
			classes,
			id,
			isFocused,
			disabled,
			handleBlur,
			handleInputChange,
			handleFocus,
			onKeyDown,
			handleInputMouseDown,
			placeholder,
			required,
			inputValue,
			chips
		]
	);

	useEffect(() => {
		resetInputValue(null);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [value]);

	const SelectList = useMemo(() => {
		if (!visible || disabled || hideOptionsList) return null;
		return (
			<div
				ref={setTooltipRef}
				{...getTooltipProps()}
				className={classNames(classes.selectItemsContainer, classes.maxHeight)}>
				<SelectItemList
					filteredOptions={filteredOptions}
					getOptionLabel={propGetOptionLabel}
					groupBy={groupBy}
					hasMore={options.length > limit && filteredOptions.length === limit}
					isOptionDisabled={isOptionDisabled}
					isOptionEqualToValue={isOptionEqualToValue}
					onSelect={selectNewValue}
					renderOption={renderOption}
					value={value}
					highlightedIndex={highlightedIndex}
					onHighlight={setHighlightedIndex}
					noResultsText={noResultsText}
				/>
			</div>
		);
	}, [
		classes.maxHeight,
		classes.selectItemsContainer,
		disabled,
		filteredOptions,
		getTooltipProps,
		groupBy,
		highlightedIndex,
		isOptionDisabled,
		isOptionEqualToValue,
		limit,
		options?.length,
		propGetOptionLabel,
		renderOption,
		selectNewValue,
		setTooltipRef,
		value,
		visible,
		hideOptionsList,
		noResultsText
	]);

	return (
		<div
			className={classNames(classes.select, className)}
			ref={selectRef}
			onMouseDown={handleMouseDown}
			onClick={onClick}>
			<ResizableInput
				className={classes.input}
				disabled={disabled}
				errors={errorMessages || undefined}
				fullWidth
				hint={hint || undefined}
				innerRef={setTriggerRef}
				inputContainerClassName={classes.inputContainer}
				inputRef={inputRef}
				label={label}
				onBlur={handleBlur}
				onChange={handleInputChange}
				onFocus={handleFocus}
				onMouseDown={handleInputMouseDown}
				onKeyDown={onKeyDown}
				placeholder={value?.length ? undefined : placeholder}
				renderInput={renderInput}
				isRequired={required}
				suffix={suffixIcon}
				value={inputValue}
				variant={variant}
				slimPadding={slimPadding}
				focused={isFocused}
				touched={isTouched}
				dirty={isDirty}
				small={small}
			/>
			{SelectList}
		</div>
	);
}

interface ISelectItemListProps<T> {
	noResultsText?: string;
	filteredOptions: T[];
	getOptionLabel?: (option: T) => string;
	groupBy?: (option: T) => string;
	hasMore: boolean;
	isOptionDisabled?: (option: T) => boolean;
	isOptionEqualToValue?: (option: T, value: T) => boolean;
	onSelect: (event: React.SyntheticEvent, value: T) => void;
	renderOption: TRenderOption<T>;
	value?: T[] | null;
	highlightedIndex?: number | null;
	onHighlight?: (index: number) => void;
}

function SelectItemList<T>(props: TProps<ISelectItemListProps<T>>) {
	const {
		filteredOptions,
		getOptionLabel,
		groupBy,
		isOptionDisabled,
		isOptionEqualToValue,
		onSelect,
		hasMore,
		renderOption,
		value,
		highlightedIndex,
		onHighlight,
		noResultsText
	} = props;
	const classes = useStyles();
	const getClassName = useCallback(
		(option: T) => {
			if (isOptionEqualToValue && option && value && value.some(opt => isOptionEqualToValue(option, opt))) {
				return "selected";
			}
			return "";
		},
		[isOptionEqualToValue, value]
	);
	const { t } = useTranslation();

	const searchForMore = useMemo(
		() =>
			hasMore ? (
				<Typography className={classes.searchForMore} variant="small">
					{t("common.select.searchForMore")}
				</Typography>
			) : null,
		[classes.searchForMore, hasMore, t]
	);

	const groups = useMemo(
		() => (groupBy && filteredOptions && filteredOptions.length ? getGroups(filteredOptions, groupBy) : null),
		[filteredOptions, groupBy]
	);

	if (!filteredOptions?.length) {
		return <Typography className={classes.noOptions}>{noResultsText ?? t("common.select.noOptionsFound")}</Typography>;
	}

	if (groups) {
		const groupNames: string[] = [];
		for (const groupName of groups.keys()) {
			groupNames.push(groupName);
		}
		let itemIndex = -1;
		return (
			<div className={classes.maxHeight}>
				{groupNames.map(groupName => {
					const options = groups.get(groupName);
					const header = groupName && (groupName.endsWith(":") ? groupName : groupName + ":");
					return (
						<div key={header} className={classes.groupContainer}>
							{header && (
								<Typography variant="small" className={classes.groupLabel}>
									{header}
								</Typography>
							)}
							{options?.map(option => (
								<SelectItem
									className={getClassName(option)}
									disabled={isOptionDisabled && isOptionDisabled(option)}
									index={++itemIndex}
									key={getOptionLabel ? getOptionKey(option, getOptionLabel) : null}
									onSelect={onSelect}
									renderOption={renderOption}
									value={option}
									onHover={onHighlight}
									highlighted={highlightedIndex === itemIndex}
									isWithinGroup={!!groupName}
								/>
							))}
						</div>
					);
				})}
				{searchForMore}
			</div>
		);
	}
	return (
		<div className={classes.maxHeight}>
			{filteredOptions.map((option, index) => (
				<SelectItem
					className={getClassName(option)}
					disabled={isOptionDisabled && isOptionDisabled(option)}
					index={index}
					key={getOptionLabel ? getOptionKey(option, getOptionLabel) : null}
					onSelect={onSelect}
					renderOption={renderOption}
					value={option}
					onHover={onHighlight}
					highlighted={highlightedIndex === index}
				/>
			))}
			{searchForMore}
		</div>
	);
}

const MemoizedComponent = React.memo(MultipleSelect) as typeof MultipleSelect;

export { MemoizedComponent as MultipleSelect };
