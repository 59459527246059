import React, { useContext } from "react";
import classNames from "classnames";
import { TransitionAnimationContext } from "../../";
import { useStyles } from "./styles";

export const Transitionable: FC<{ id: string }> = ({ className, children, ...props }) => {
	const { animate, movement, reverse, animation } = useContext(TransitionAnimationContext);
	const classes = useStyles();

	return (
		<div
			className={classNames(
				className,
				classes.transitionable,
				{ animate, reverse },
				movement === "in" && "in",
				movement === "out" && "out"
			)}
			data-animation={animation}
			{...props}>
			{children}
		</div>
	);
};
