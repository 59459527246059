import { Record } from "immutable";
import { DirectoryGroupModel } from "./DirectoryGroupModel";
import { IntegrationModel } from "./IntegrationModel";
import { UserModel } from "./UserModel";

type TIntegrationMaintainerType = "user" | "directoryGroup";

const defaults = {
	entity: null as DirectoryGroupModel | null,
	entityId: "",
	id: "",
	integration: null as IntegrationModel | null,
	integrationId: "",
	type: "user" as TIntegrationMaintainerType
};

export class IntegrationMaintainerModel extends Record<typeof defaults>(defaults) {
	static fromServerData(data: unknown): IntegrationMaintainerModel {
		const { entity, entityId, id, integration, integrationId, type } = data as typeof defaults;
		return new IntegrationMaintainerModel({
			entity: type === "user" || !entity ? null : DirectoryGroupModel.fromServerData(entity),
			entityId,
			id,
			integration: integration ? IntegrationModel.fromServerData(integration) : null,
			integrationId,
			type
		});
	}

	static fromEntity(entity: UserModel | DirectoryGroupModel, integrationId: string): IntegrationMaintainerModel {
		return new IntegrationMaintainerModel({
			entity: null,
			entityId: entity.id,
			id: "",
			integration: null,
			integrationId,
			type: entity instanceof UserModel ? "user" : "directoryGroup"
		});
	}
}
