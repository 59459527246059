import { t } from "i18next";
import { removeRedundantSpaces } from "./removeRedundantSpaces";

const getValidators = (fieldName: string, minLength?: number, maxLength?: number, optional = false) => {
	const minLengthValidator = (val: string) => {
		if (!minLength) return null;
		if (optional && !val.length) return null;
		const cleanedVal = removeRedundantSpaces(val);
		if (cleanedVal.length >= minLength) return null;
		return t("common.textValidation.HelpMinimumValueText", { field: fieldName, minValue: minLength });
	};

	const maxLengthValidator = (val: string) => {
		if (!maxLength) return null;
		const cleanedVal = removeRedundantSpaces(val);
		if (cleanedVal.length <= maxLength) return null;
		return t("common.textValidation.HelpMaximumValueText", { field: fieldName, maxValue: maxLength });
	};
	return [minLengthValidator, maxLengthValidator];
};

export const getNameValidators = (field: string) => getValidators(field, 2, 50);
export const getDescriptionValidators = (field: string, optional = false) =>
	getValidators(field, undefined, 2048, optional);
export const getTagValidators = (field: string) => getValidators(field, 2, 50);
export const getCommentValidators = (field: string) => getValidators(field, undefined, 2048);
export const getTypeValidators = (field: string, optional = false) => getValidators(field, 2, 50, optional);
export const getCategoryValidators = (field: string) => getValidators(field, 2, 50);
