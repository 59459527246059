import React from "react";
import { WebhookChip, TWebhookChipColor } from "components/common/WebhookChip";
import type { TStatus } from "./GroupTooltipContent";

interface IProps {
	status: TStatus;
	displayName: string;
}

export const ApproverWebhookChip: FC<IProps> = ({ status, displayName }) => {
	let color: TWebhookChipColor = "default";
	if (status === "approved") color = "green";
	if (status === "declined") color = "red";

	return <WebhookChip displayName={displayName} color={color} />;
};
