import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import random from "lodash/random";
import { useTranslation } from "react-i18next";
import { Set } from "immutable";
import { Section } from "components/ui/Section";
import { Typography } from "components/ui/New/Typography";
import { Chip } from "components/ui/Chip";
import { getUserPendingTickets } from "api/user";
import { LoadingDots } from "components/ui/LoadingDots";
import { VirtualRequestList } from "components/common/RequestList";
import { RequestBar } from "components/common/RequestBar";
import { TicketModel } from "models/TicketModel";
import { DESC, usePagination } from "hooks/usePagination";
import { Skeleton } from "components/ui/Skeleton";
import { REQUEST_BAR_HEIGHT } from "components/common/RequestBar/styles";
import { useTicketUpdatesContext } from "context/ticketUpdatesContext";
import { GrantedIcon } from "components/ui/Icons/GrantedIcon";
import { CloseIcon } from "components/ui/Icons/CloseIcon";
import { respondToTicket } from "api/tickets";
import { useStyles } from "./styles";

const LIST_SIZE = 10;
const SHOWN_SIZE = 3;

const SORT_FIELDS = ["ticketNumber"];
type TOverlayState = "approved" | "denied" | "inactive";

const AnimatedRequestBar: FC<{ request: TicketModel; onRequestRemoved: (requestId: string) => void }> = ({
	request,
	onRequestRemoved
}) => {
	const { t } = useTranslation();
	const classes = useStyles();
	const containerRef = useRef<HTMLDivElement | null>(null);
	const [overlayState, setOverlayState] = useState<TOverlayState>("inactive");
	const { notifyTicketUpdate } = useTicketUpdatesContext();

	const overlayContent = useMemo(() => {
		if (overlayState === "inactive") return null;
		return (
			<>
				<Typography variant="title_sb">{t(`common.requestBar.overlay.${overlayState}`)}</Typography>
				{overlayState === "approved" ? <GrantedIcon size={52} /> : <CloseIcon size={52} />}
			</>
		);
	}, [overlayState, t]);

	const animationClass = useMemo(() => {
		switch (overlayState) {
			case "approved":
				return classes.approvedAnimation;
			case "denied":
				return classes.deniedAnimation;
			default:
				return "";
		}
	}, [classes, overlayState]);

	const onApprovalResponse = useCallback(
		async (response: "approve" | "deny") => {
			const updatedRequest = await respondToTicket(request.id, response === "approve");
			notifyTicketUpdate(updatedRequest);

			setOverlayState(response === "approve" ? "approved" : "denied");
		},
		[notifyTicketUpdate, request.id]
	);

	useEffect(() => {
		const currentRefDiv = containerRef.current;
		if (!currentRefDiv) return;
		const animationEndHandler = () => {
			setOverlayState("inactive");
			onRequestRemoved(request.id);
		};
		currentRefDiv.addEventListener("animationend", animationEndHandler);
		return () => currentRefDiv.removeEventListener("animationend", animationEndHandler);
	}, [onRequestRemoved, request.id]);

	return (
		<div ref={containerRef} className={animationClass}>
			<RequestBar
				key={request.id}
				onApprovalResponse={onApprovalResponse}
				openSidebarOnClick
				request={request}
				type="awaitingResponse"
			/>
			<div className={classes.animationOverlay}>
				<div className={classes.overlayText}>{overlayContent}</div>
			</div>
		</div>
	);
};

export const AwaitingApprovalSection: FC = ({ className, innerRef }) => {
	const { t } = useTranslation();
	const { itemsForVirtualTable, isLoading, totalResults, getPage } = usePagination(
		getUserPendingTickets,
		LIST_SIZE,
		DESC,
		SORT_FIELDS
	);
	const [respondedIds, setRespondedIds] = useState(Set<string>());

	const requests = useMemo(() => {
		if (!itemsForVirtualTable) return [];
		return itemsForVirtualTable.filter(request => (request ? !respondedIds.has(request.id) : true));
	}, [itemsForVirtualTable, respondedIds]);

	const totalAmount = useMemo(
		() => totalResults - ((itemsForVirtualTable?.length || 0) - requests.length),
		[totalResults, itemsForVirtualTable, requests]
	);

	const onRequestRemoved = useCallback((requestId: string) => {
		setRespondedIds(prev => prev.add(requestId));
	}, []);

	const title = useMemo(() => {
		const awaitingApprovalTextTitle = t("pages.requests.awaitingApprovalSection.title");
		return (
			<>
				<Typography variant="body_sb">{awaitingApprovalTextTitle}</Typography>
				<Chip size="tiny">{totalAmount}</Chip>
			</>
		);
	}, [t, totalAmount]);

	const renderRequest = useCallback(
		(request?: TicketModel) => {
			if (!request) return <Skeleton key={`loading-${random(true)}`} height={REQUEST_BAR_HEIGHT} />;
			return <AnimatedRequestBar key={request.id} onRequestRemoved={onRequestRemoved} request={request} />;
		},
		[onRequestRemoved]
	);

	if (totalAmount === 0) {
		return isLoading ? <LoadingDots center /> : null;
	}
	return (
		<Section className={className} innerRef={innerRef} title={title}>
			<VirtualRequestList
				fetchPage={getPage}
				perPage={LIST_SIZE}
				requestRenderer={renderRequest}
				requests={requests}
				totalRequests={totalAmount}
				shownSize={SHOWN_SIZE}
			/>
		</Section>
	);
};
