import { List, Map } from "immutable";
import { UserEntitlementModel } from "models/UserEntitlementModel";
import { TAdminUser, TUserRole, UserModel } from "../models/UserModel";
import { apiReq } from "../utils/apiReq";

interface IGetUsersOptions {
	withDeleted?: boolean;
}

export async function getUsers(options: IGetUsersOptions = {}): Promise<Map<string, UserModel>> {
	const qs = options.withDeleted !== undefined ? `?withDeleted=${options.withDeleted}` : "";
	const { data } = await apiReq("GET", "/v1/users" + qs);
	return Map(
		data.map((user: unknown) => {
			const model = UserModel.fromServerData(user);
			return [model.id, model];
		})
	);
}

export async function getAdmins(): Promise<Map<string, TAdminUser>> {
	const { data } = await apiReq("GET", "/v1/users/admins");
	return Map(
		data.map((user: unknown) => {
			const model = UserModel.fromServerData(user);
			return [model.id, model];
		})
	);
}

export async function getUserEntitlements(id: string): Promise<List<UserEntitlementModel>> {
	const { data } = await apiReq("GET", `/v1/users/${id}/entitlements`);
	return List(data.map((permission: unknown) => UserEntitlementModel.fromServerData(permission)));
}

export async function changeUsersRole(userId: string, role: TUserRole): Promise<UserModel> {
	const { data } = await apiReq("PUT", "/v1/users/" + userId, { role });

	return UserModel.fromServerData(data);
}

export async function getUser(id: string): Promise<UserModel> {
	const { data } = await apiReq("GET", `/v1/users/${id}`);

	return UserModel.fromServerData(data);
}
