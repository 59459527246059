import { ApplicationModel } from "models/ApplicationModel";
import { apiReq } from "utils/apiReq";
import { Map } from "immutable";

export async function getApplications() {
	const { data } = await apiReq("GET", "/v1/applications");

	return Map<string, ApplicationModel>(
		data
			.map((application: unknown) => ApplicationModel.fromServerData(application))
			.map((application: ApplicationModel) => [application.id, application])
	);
}
