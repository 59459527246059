const styles = {
	"@keyframes fade-move-from-bottom-in": {
		"0%": {
			opacity: "0",
			transform: "translateY(10%)"
		},

		"100%": {
			opacity: "1",
			transform: "translateY(0%)"
		}
	},

	"@keyframes fade-move-from-bottom-out": {
		"0%": {
			opacity: "1",
			transform: "translateY(0%)"
		},

		"100%": {
			opacity: "0",
			transform: "translateY(-10%)"
		}
	},
	transitionable: {
		"&.animate": {
			'&[data-animation="fade-move-from-bottom"]': {
				"&.in": {
					animation: "var(--animation-duration) var(--transition-easing) forwards $fade-move-from-bottom-in",
					zIndex: "10"
				},

				"&.out": {
					animation: "var(--animation-duration) var(--transition-easing) forwards $fade-move-from-bottom-out",
					zIndex: "5"
				},

				"&.reverse": {
					"&.in": {
						animation:
							"var(--animation-duration) var(--transition-easing-reversed) forwards reverse $fade-move-from-bottom-out"
					},

					"&.out": {
						animation:
							"var(--animation-duration) var(--transition-easing-reversed) forwards reverse $fade-move-from-bottom-in"
					}
				}
			}
		}
	}
};

export { styles };
