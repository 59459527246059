import { apiReq } from "utils/apiReq";
import { Map } from "immutable";
import { AgentTokenModel } from "models/AgentTokenModel";

export async function getAgentTokens(): Promise<Map<string, AgentTokenModel>> {
	const { data } = await apiReq("GET", "/v1/agentTokens");

	return Map<string, AgentTokenModel>(
		data.map((token: unknown) => {
			const model = AgentTokenModel.fromServerData(token);

			return [model.id, model];
		})
	);
}

export const createAgentToken = async (name: string) => {
	const { data: createdToken } = await apiReq("POST", "/v1/agentTokens", { name });
	return AgentTokenModel.fromServerData(createdToken);
};

export const updateAgentTokenName = async (id: string, name: string) => {
	const { data: token } = await apiReq("PUT", `/v1/agentTokens/${id}`, { name });

	return AgentTokenModel.fromServerData(token);
};

export const deleteAgentToken = async (id: string) => {
	return apiReq("DELETE", `/v1/agentTokens/${id}`);
};
