import React, { useMemo } from "react";
import classNames from "classnames";
import { TooltipOnOverflow } from "components/ui/TooltipOnOverflow";
import { UserFilledIcon } from "components/ui/Icons/UserFilledIcon";
import { Tooltip } from "components/ui/Tooltip";
import { TNodeColor, useStyles } from "./styles";

type TProps = {
	actions?: React.ReactNode;
	borderRadius?: number;
	color: TNodeColor;
	content: React.ReactNode;
	fixedWidth?: boolean;
	icon?: React.ReactNode;
	iconClassName?: string;
	isDeleted?: boolean;
	onClick?: () => void;
	selected?: boolean;
	tooltipContent?: React.ReactNode;
};

const EntityNodeComponent: FC<TProps> = ({
	actions,
	borderRadius,
	className,
	color,
	content,
	fixedWidth,
	icon,
	iconClassName,
	innerRef,
	isDeleted,
	onClick,
	selected,
	tooltipContent
}) => {
	const classes = useStyles({ theme: { color, borderRadius } });
	const hasActions = !!actions;

	const mainContent = useMemo(() => {
		const contentContainer = (
			<div className={classes.contentContainer}>
				<div className={classNames({ [classes.contentWithActions]: hasActions })}>
					<div className={classes.alignedContent}>
						{typeof content === "string" ? <TooltipOnOverflow textVariant="standard" content={content} /> : content}
					</div>
					{hasActions && <div className={classes.alignedContent}>{actions}</div>}
				</div>
			</div>
		);
		return tooltipContent ? (
			<Tooltip content={tooltipContent} className={classes.tooltip}>
				{contentContainer}
			</Tooltip>
		) : (
			contentContainer
		);
	}, [actions, classes, content, hasActions, tooltipContent]);

	return (
		<div
			className={classNames(
				classes.container,
				{
					[classes.selected]: selected,
					[classes.deleted]: isDeleted,
					[classes.fixedWidth]: fixedWidth,
					[classes.clickable]: !!onClick
				},
				className
			)}
			ref={innerRef}
			onClick={onClick}>
			<div className={classNames(classes.iconContainer, iconClassName)}>
				{icon || <UserFilledIcon className={classes.icon} />}
			</div>
			{mainContent}
		</div>
	);
};

export const EntityNode = React.memo(EntityNodeComponent);
