import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Table } from "components/ui/Table";
import { UserModel } from "models/UserModel";
import { DirectoryGroupModel } from "models/DirectoryGroupModel";
import { Button } from "components/ui/Button";
import { Select } from "components/ui/Select";
import { UserWithEmail } from "components/common/UserWithEmail";
import { DirectoryGroupWithEmail } from "components/common/DirectoryGroupWithEmail";
import { directoryIconRegex } from "utils/directoryGroups";
import { useStyles } from "./styles";
import type { List, Map } from "immutable";
import type { IntegrationMaintainerModel } from "models/IntegrationMaintainerModel";
import type { IntegrationResourceMaintainerModel } from "models/IntegrationResourceMaintainerModel";

export type TMaintainer = IntegrationMaintainerModel | IntegrationResourceMaintainerModel;

interface IBaseProps {
	users: Map<string, UserModel>;
	directoryGroups: List<DirectoryGroupModel>;
}

interface IDisplayProps<T> extends IBaseProps {
	maintainer: T;
	onRemove: (maintainer: T) => Promise<void>;
}

interface ICreateProps extends IBaseProps {
	disabledIds: string[];
	cancel: () => void;
	onSubmit: (maintainer: UserModel | DirectoryGroupModel) => Promise<void>;
}

type TMaintainerProps<T extends TMaintainer> = IDisplayProps<T> | ICreateProps;

const UserMaintainer: React.FC<{ user: UserModel }> = ({ user }) => {
	return <UserWithEmail user={user} />;
};

const DirectoryGroupMaintainer: React.FC<{ group: DirectoryGroupModel }> = ({ group }) => {
	return <DirectoryGroupWithEmail directoryGroup={group} />;
};

function DisplayMaintainerRow<T extends TMaintainer>({
	maintainer,
	onRemove,
	users,
	directoryGroups
}: IDisplayProps<T>) {
	const [isLoading, setIsLoading] = useState(false);
	const { t } = useTranslation();
	const classes = useStyles();

	const entity: DirectoryGroupModel | UserModel | undefined = useMemo(() => {
		if (maintainer.entity) return maintainer.entity;
		if (maintainer.type === "directoryGroup") {
			return directoryGroups.find(group => group.id === maintainer.entityId);
		}
		return users.get(maintainer.entityId);
	}, [maintainer, directoryGroups, users]);
	const onClick = useCallback(async () => {
		setIsLoading(true);
		try {
			await onRemove(maintainer);
		} finally {
			setIsLoading(false);
		}
	}, [maintainer, onRemove]);
	if (!entity) return null;
	return (
		<Table.Row>
			<Table.Cell>
				<div>
					{entity instanceof DirectoryGroupModel ? (
						<DirectoryGroupMaintainer group={entity} />
					) : (
						<UserMaintainer user={entity} />
					)}
				</div>
			</Table.Cell>
			<Table.Cell className={classes.actionsCell}>
				<Button size="small" variant="text" disabled={isLoading} loading={isLoading} onClick={onClick}>
					{t("buttons.remove")}
				</Button>
			</Table.Cell>
		</Table.Row>
	);
}

interface IMaintainerOption {
	value: DirectoryGroupModel | UserModel;
	label: string;
}

const isOptionsEqual = (option1: IMaintainerOption, option2: IMaintainerOption) => {
	return option1.value.id === option2.value.id;
};

const renderOption = (option: IMaintainerOption) => {
	const entity = option.value;

	return entity instanceof DirectoryGroupModel ? (
		<DirectoryGroupMaintainer group={entity} />
	) : (
		<UserMaintainer user={entity} />
	);
};

const getOptionLabel = (option: IMaintainerOption) => {
	const matches = directoryIconRegex.exec(option.label);
	if (!matches) return option.label;
	return matches[2];
};

const CreateMaintainerRow: React.FC<ICreateProps> = ({ cancel, disabledIds, onSubmit, users, directoryGroups }) => {
	const { t } = useTranslation();
	const classes = useStyles();
	const [isLoading, setIsLoading] = useState(false);
	const [selected, setSelected] = useState<IMaintainerOption | null>(null);
	const save = useCallback(async () => {
		if (!selected) return;
		setIsLoading(true);
		try {
			await onSubmit(selected?.value);
		} finally {
			setIsLoading(false);
		}
	}, [onSubmit, selected]);

	const options: IMaintainerOption[] = useMemo(() => {
		return users
			.toList()
			.map(user => ({
				value: user,
				label: user.fullName
			}))
			.concat(
				directoryGroups
					.filter(dg => !dg.isDeleted)
					.map(group => ({
						value: group,
						label: group.name
					}))
			)
			.filter(option => !disabledIds.includes(option.value.id) && !option.value.isDeleted)
			.toArray();
	}, [directoryGroups, disabledIds, users]);

	const getKey = useCallback((option: IMaintainerOption) => option.value.id, []);

	return (
		<Table.Row>
			<Table.Cell className={classes.creationCell}>
				<Select
					renderLabel={renderOption}
					getOptionKey={getKey}
					getOptionLabel={getOptionLabel}
					isOptionEqualToValue={isOptionsEqual}
					onChange={setSelected}
					options={options}
					renderOption={renderOption}
					variant="inline"
					fullWidth
				/>
			</Table.Cell>
			<Table.Cell className={classes.actionsCell}>
				<Button size="small" variant="text" disabled={!selected || isLoading} loading={isLoading} onClick={save}>
					{t("buttons.save")}
				</Button>
				<Button size="small" variant="text" disabled={isLoading} onClick={cancel}>
					{t("buttons.cancel")}
				</Button>
			</Table.Cell>
		</Table.Row>
	);
};

const isDisplayProps = <T extends TMaintainer>(props: TMaintainerProps<T>): props is IDisplayProps<T> => {
	return "maintainer" in props;
};

export function Maintainer<T extends TMaintainer>(props: TMaintainerProps<T>) {
	if (isDisplayProps(props)) {
		return <DisplayMaintainerRow {...props} />;
	}
	return <CreateMaintainerRow {...props} />;
}
