import React, { useMemo } from "react";
import classNames from "classnames";
import { Map } from "immutable";
import { Typography, type TTypographyVariant } from "../New/Typography";
import { useStyles } from "./styles";
import type { TIconProps } from "../Icon";

export type TIconPrefixSize = "tiny" | "small" | "medium" | "large" | "huge";

type TProps = {
	Icon: FC<TIconProps>;
	content: string | number;
	size?: TIconPrefixSize;
	semibold?: boolean;
	disabled?: boolean;
};

const ICON_SIZE_MAP = Map<TIconPrefixSize, number>({
	tiny: 16,
	small: 16,
	medium: 20,
	large: 24,
	huge: 28
});

const REGULAR_TEXT_VARIANT_MAP = Map<TIconPrefixSize, TTypographyVariant>({
	tiny: "text_sm_reg",
	small: "text_reg",
	medium: "body_reg",
	large: "title_med",
	huge: "title_med"
});

const SEMIBOLD_TEXT_VARIANT_MAP = Map<TIconPrefixSize, TTypographyVariant>({
	tiny: "text_sm_sb",
	small: "text_title_sb",
	medium: "body_sb",
	large: "title_sb",
	huge: "h2_sb"
});

export const IconPrefix: FC<TProps> = ({
	className,
	content,
	Icon,
	innerRef,
	size = "medium",
	semibold,
	disabled = false
}) => {
	const classes = useStyles({ size, disabled });

	const [iconSize, textVariant]: [number, TTypographyVariant] = useMemo(() => {
		const variantMap = semibold ? SEMIBOLD_TEXT_VARIANT_MAP : REGULAR_TEXT_VARIANT_MAP;
		return [ICON_SIZE_MAP.get(size, 20), variantMap.get(size, "body_reg")];
	}, [size, semibold]);

	return (
		<div className={classNames(classes.container, className)} ref={innerRef}>
			<Icon size={iconSize} />
			<Typography noWrap variant={textVariant}>
				{content}
			</Typography>
		</div>
	);
};
