import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { usePageContext } from "context/pageContext";
import { useUser } from "hooks/useUser";
import { useLoadingState } from "hooks/useLoadingState";
import { respondToTicket, adminRespondToTicket, renewTicket as apiRenewTicket } from "api/tickets";
import { Button } from "components/ui/Button";
import { CloseIcon } from "components/ui/Icons/CloseIcon";
import { GrantedIcon } from "components/ui/Icons/GrantedIcon";
import { TicketExtraOptionsButton } from "components/common/TicketExtraOptionsButton";
import { RefreshIcon } from "components/ui/Icons/RefreshIcon";
import { useOpenGlobalErrorModal } from "hooks/useGlobalError";
import { checkUserIsApprover, shouldAllowAdminApprove } from "./utils";
import { useStyles } from "./styles";
import type { TFullTicket } from "./types";

const RENEWAL_STATUSES = ["granted", "revoked"];

export const RequestDetailsFooter: FC<{ ticket: TFullTicket; reloadTicket: () => Promise<void> }> = ({
	ticket,
	reloadTicket
}) => {
	const { user } = useUser();
	const classes = useStyles();
	const { t } = useTranslation();
	const { scrollableId: pageId } = usePageContext();
	const { isLoading, withLoader } = useLoadingState();
	const [renewed, setRenewed] = useState(false);
	const openGlobalErrorModal = useOpenGlobalErrorModal();

	const { hasApproverActions, hasReceiverActions, allowAdminApprove, isApprover } = useMemo(() => {
		if (!ticket || !user) return { hasApproverActions: false, allowAdminApprove: false, isApprover: false };

		const allowAdminApprove = shouldAllowAdminApprove(user?.isAdmin ?? false, pageId);
		const isApprover = checkUserIsApprover(ticket, user);
		const isReceiver = ticket.receiverId === user.id;

		return {
			isApprover,
			allowAdminApprove,
			hasApproverActions: (isApprover || allowAdminApprove) && ticket.status === "waitingForApproval",
			hasReceiverActions: isReceiver && RENEWAL_STATUSES.includes(ticket.status)
		};
	}, [pageId, ticket, user]);

	const updateApprovalStatus = useCallback(
		async (approved: boolean) => {
			if (!hasApproverActions) return;
			const respondAction = allowAdminApprove ? adminRespondToTicket : respondToTicket;
			await withLoader(respondAction(ticket.id, approved));
			await reloadTicket();
		},
		[hasApproverActions, allowAdminApprove, withLoader, ticket.id, reloadTicket]
	);

	const renewTicket = useCallback(async () => {
		try {
			if (!hasReceiverActions) return;
			await withLoader(apiRenewTicket(ticket.id));
			setRenewed(true);
		} catch (e) {
			openGlobalErrorModal(e as Error);
		}
	}, [hasReceiverActions, openGlobalErrorModal, ticket.id, withLoader]);

	const setApproved = useCallback(async () => {
		updateApprovalStatus(true);
	}, [updateApprovalStatus]);

	const setDeclined = useCallback(async () => {
		updateApprovalStatus(false);
	}, [updateApprovalStatus]);

	if (!hasApproverActions && !hasReceiverActions) return null;

	return (
		<div className={classes.bottomBar}>
			{hasApproverActions && (
				<>
					{isApprover && <TicketExtraOptionsButton position="top-end" ticket={ticket} />}
					<Button
						size="medium"
						variant="secondary"
						loading={isLoading}
						disabled={isLoading}
						onClick={setDeclined}
						suffix={<CloseIcon />}
						tooltip={allowAdminApprove ? t("common.ticketActions.adminDecline") : undefined}>
						{t("common.ticketSidebar.decline")}
					</Button>
					<Button
						size="medium"
						loading={isLoading}
						disabled={isLoading}
						onClick={setApproved}
						suffix={<GrantedIcon />}
						tooltip={allowAdminApprove ? t("common.ticketActions.adminApprove") : undefined}>
						{t("common.ticketSidebar.approve")}
					</Button>
				</>
			)}
			{hasReceiverActions && (
				<Button
					size="medium"
					variant="secondary"
					loading={isLoading}
					disabled={isLoading || renewed}
					onClick={renewTicket}
					suffix={renewed ? <GrantedIcon /> : <RefreshIcon />}>
					{renewed ? t("common.ticketSidebar.renewed") : t("common.ticketSidebar.renew")}
				</Button>
			)}
		</div>
	);
};
