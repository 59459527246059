import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	container: {
		display: "flex",
		flexWrap: "wrap"
	},
	reminderForm: {
		display: "flex",
		flexDirection: "column",
		gap: "1.5rem"
	},
	weekdayContainer: {
		display: "flex",
		flexDirection: "row",
		"& > *": {
			marginBottom: "0",
			"&:first-child": {
				marginLeft: "0"
			}
		}
	},
	timeContainer: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		gap: "3px"
	},
	timeInput: {
		width: "3.75rem"
	},
	label: {
		color: "var(--color-grey-dark)",
		display: "flex",
		gap: "0.5rem"
	},
	weekdayLabel: {
		alignItems: "start"
	},
	timeField: {
		flexDirection: "row",
		"&$timeField$timeField": {
			marginTop: "0"
		}
	},
	formActions: {
		"&$formActions$formActions": {
			marginTop: "0"
		}
	}
});
