import { BetaContext } from "context/betaContext";
import { useOnMount } from "hooks/useOnMount";
import React from "react";

export const BetaTriggerPage: React.FC = () => {
	const {
		actions: { setBeta }
	} = BetaContext();

	useOnMount(() => {
		setBeta(true);
	});

	return null;
};
