import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	button: {
		backgroundColor: "var(--color-purple-10)",
		"&:hover": {
			backgroundColor: "var(--color-purple-500)",
			color: "var(--color-white)"
		},
		"&:active": {
			backgroundColor: "var(--color-grey-700)"
		},
		flexWrap: "nowrap",
		overflow: "hidden",
		justifyContent: "flex-start"
	},
	buttonContent: {
		whiteSpace: "nowrap",
		overflow: "hidden",
		position: "relative",
		minWidth: "calc(var(--side-nav-width) - 48px)",
		display: "flex",
		gap: 12,
		alignItems: "center",
		justifyContent: "flex-start"
	},
	section: {
		justifyItems: "flex-start",
		width: "width: 100%",
		display: "flex",
		flexDirection: "column",
		padding: "0 20px 20px 20px"
	}
});
