import { Select } from "components/ui/Select";
import React, { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { API_TOKEN_DURATION_OPTIONS, TApiTokenDuration } from "utils/tokenDurations";
import useMountState from "hooks/useMountState";
import { toNumber } from "utils/commonUtils";

interface IProps {
	onChange: (value: number | null) => void;
	value: TApiTokenDuration | number | string | null;
}

export const TokenDurationSelectInput: FC<IProps> = ({ onChange, className, value }) => {
	const { t } = useTranslation();
	const { ifMounted } = useMountState();

	const getLabel = useCallback((option: TApiTokenDuration) => t(`common.durations.${option}`), [t]);
	const handleChange = useCallback(
		(option: TApiTokenDuration | string | null) => {
			if (option) {
				onChange(toNumber(option));
			}
		},
		[onChange]
	);

	const options = useMemo(() => API_TOKEN_DURATION_OPTIONS.sort((a, b) => a - b) || [], []);

	const duration = useMemo(
		() => API_TOKEN_DURATION_OPTIONS.find(duration => duration === toNumber(value)) || null,
		[value]
	);

	useEffect(() => {
		if (options.length === 1) {
			ifMounted(() => handleChange(options[0]));
		}
	}, [handleChange, ifMounted, options]);

	return (
		<Select
			disabled={options.length === 1}
			className={className}
			fullWidth
			getOptionLabel={getLabel}
			onChange={handleChange}
			options={options}
			placeholder={t("pages.settings.tokens.durationPlaceholder")}
			required
			value={duration}
			variant="inline"
		/>
	);
};
