import { createUseStyles } from "react-jss";

const HEIGHT_PX = 160;

export const useStyles = createUseStyles({
	container: {
		marginTop: "var(--spacing-x4, 16px)",
		alignItems: "start",
		display: "grid",
		gridTemplateColumns: `minmax(160px, 220px) max-content minmax(min-content, auto) min-content`,
		flexShrink: 0,
		gap: "var(--spacing-x6, 24px)",
		justifyContent: "stretch",
		maxWidth: "100%",
		overflow: "hidden",
		position: "relative",
		maxHeight: `${HEIGHT_PX}px`
	},
	justificationColumn: {
		minWidth: "min-content",
		width: "auto"
	},
	column: {
		height: "100%",
		display: "flex",
		flexDirection: "column",
		justifyContent: "flex-start",
		flexGrow: 1,
		flexShrink: 1
	},
	gap: {
		gap: "var(--spacing-x1, 4px)"
	},
	generalInfo: {
		maxWidth: "100%",
		overflowX: "hidden"
	},
	requesterColumn: {
		gap: "var(--spacing-x3, 12px)",
		"& $requester": {
			gap: "var(--spacing-x1, 4px)",
			flexGrow: 0
		}
	},
	requester: {},
	statusContainer: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		backgroundColor: "var(--color-grey-600)",
		gap: "var(--spacing-x2, 8px)",
		padding: "0 var(--spacing-x4, 16px)",
		height: "56px",
		color: "var(--color-white)",
		borderRadius: "8px",
		width: "max-content",
		minWidth: "110px",
		maxWidth: "216px"
	},
	successStatus: {
		backgroundColor: "var(--color-green-400)"
	},
	expiredStatus: {
		backgroundColor: "var(--color-grey-700)"
	},
	failureStatus: {
		backgroundColor: "var(--color-red-700)"
	},
	statusTitle: {
		display: "flex",
		alignItems: "center",
		justifyContent: "flex-start",
		gap: "var(--spacing-x2, 8px)"
	},
	statusColumn: {
		gap: "var(--spacing-x3, 12px)"
	},
	extraDetails: {
		display: "flex",
		gap: "var(--spacing-x1, 4px)",
		justifyContent: "flex-start",
		alignItems: "flex-start",
		"& svg": {
			fontsize: "16px"
		}
	}
});
