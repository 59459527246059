import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	treeNodeHeader: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "start",
		gap: "0.5rem"
	},
	nodeName: {
		display: "flex",
		alignItems: "center",
		fontSize: "1.1rem"
	},
	loadMore: {
		marginTop: "0.5rem"
	},
	treeNodeContent: {
		marginLeft: "2rem"
	},
	treeNodeSearch: {
		margin: "0.5rem 0"
	},
	pageSelection: {
		display: "flex"
	},
	invisible: {
		opacity: 0
	}
});
