import { useTranslation } from "react-i18next";
import React, { useCallback, useMemo, useState } from "react";
import classNames from "classnames";
import { AuditLogsWebhookModel } from "models/AuditLogsWebhookModel";
import { useLoadingState } from "hooks/useLoadingState";
import { Table } from "components/ui/Table";
import { Button } from "components/ui/Button";
import { Input } from "components/ui/Input";
import { LoadingSpinner } from "components/ui/LoadingSpinner";
import { JSONTextArea } from "components/common/JSONTextArea";
import { ErrorObject } from "ajv";
import { notEmpty } from "utils/comparison";
import { useOpenGlobalErrorModal } from "hooks/useGlobalError";
import { Entity } from "components/ui/New/Entity";
import { useStyles } from "./style";

interface ICreateProps {
	create: boolean;
	createHandler: (params: {
		url: string;
		headers: Record<string, unknown> | null;
		additionalAuditLogParams: Record<string, unknown> | null;
	}) => Promise<void>;
	closeInput: () => void;
	webhook?: never;
	removeHandler?: never;
	enableHandler?: never;
}

interface IViewProps {
	webhook: AuditLogsWebhookModel;
	removeHandler: (webhookId: string) => void;
	enableHandler?: (webhookId: string) => Promise<void>;
	create?: never;
	closeInput?: never;
	createHandler?: never;
}

const AuditLogsWebhookButtons: FC<{
	isLoading: boolean;
	create: boolean;
	onCancel?: () => void;
	isValid?: string | false;
	enabled?: boolean;
	onCreate?: () => Promise<void>;
	onRemove?: () => void;
	onEnable?: () => Promise<void>;
}> = React.memo(function Buttons({ create, isLoading, isValid, onCreate, onRemove, onEnable, onCancel, enabled }) {
	const { t } = useTranslation();
	if (create) {
		return (
			<>
				<Button disabled={isLoading || !isValid} onClick={onCreate} variant="text" size="small">
					{t("buttons.save")}
				</Button>
				<Button disabled={isLoading} onClick={onCancel} variant="text" size="small">
					{t("buttons.cancel")}
				</Button>
			</>
		);
	}
	return (
		<>
			{!enabled ? (
				<Button disabled={isLoading} onClick={onEnable} variant="text" size="small">
					{t("buttons.reactivate")}
				</Button>
			) : null}
			<Button disabled={isLoading} onClick={onRemove} variant="text" size="small">
				{t("buttons.remove")}
			</Button>
		</>
	);
});

export const AuditLogsWebhook: FC<IViewProps | ICreateProps> = ({
	create = false,
	createHandler,
	enableHandler,
	webhook,
	removeHandler,
	closeInput
}) => {
	const classes = useStyles();
	const { t } = useTranslation();
	const openGlobalErrorModal = useOpenGlobalErrorModal();
	const { withLoader, isLoading } = useLoadingState();
	const [webhookUrl, setWebhookUrl] = useState<string>(webhook?.url ?? "");
	const [headers, setHeaders] = useState<Record<string, unknown> | null>(webhook?.headers || null);
	const [additionalAuditLogParams, setAdditionalAuditLogParams] = useState<Record<string, unknown> | null>(
		webhook?.additionalAuditLogParams || null
	);
	const [urlErrors, setUrlErrors] = useState<string[]>();
	const [headersErrors, setHeadersErrors] = useState<string[]>();
	const [additionalAuditLogParamsErrors, setAdditionalAuditLogParamsErrors] = useState<string[]>();

	const isValid = useMemo(
		() => !urlErrors?.length && !headersErrors?.length && !additionalAuditLogParamsErrors?.length && webhookUrl,
		[additionalAuditLogParamsErrors, headersErrors, urlErrors, webhookUrl]
	);

	const onUrlError = useCallback((errors: string[] | null) => {
		setUrlErrors(errors ?? undefined);
	}, []);

	const onHeadersError = useCallback((errors: Partial<ErrorObject>[] | null) => {
		setHeadersErrors(errors?.map(err => err.message).filter(notEmpty) ?? undefined);
	}, []);

	const onAdditionalAuditLogParamsError = useCallback((errors: Partial<ErrorObject>[] | null) => {
		setAdditionalAuditLogParamsErrors(errors?.map(err => err.message).filter(notEmpty) ?? undefined);
	}, []);

	const onCreate = useCallback(async () => {
		if (!isValid || !createHandler) {
			return;
		}

		try {
			await withLoader(createHandler({ url: webhookUrl, headers, additionalAuditLogParams }));
			closeInput && closeInput();
		} catch (e) {
			openGlobalErrorModal(e as Error);
		}
	}, [
		isValid,
		createHandler,
		withLoader,
		webhookUrl,
		headers,
		additionalAuditLogParams,
		closeInput,
		openGlobalErrorModal
	]);

	const onRemove = useCallback(() => {
		removeHandler && removeHandler(webhook.id);
	}, [removeHandler, webhook]);

	const onEnable = useCallback(async () => {
		enableHandler && (await enableHandler(webhook.id));
	}, [enableHandler, webhook]);

	const urlValidator = useCallback(
		(url: string) => {
			try {
				new URL(url);
				return null;
			} catch (_e) {
				return t("pages.settings.auditLogsWebhooks.invalidUrl");
			}
		},
		[t]
	);
	const urlValidityChecks = useMemo(() => [urlValidator], [urlValidator]);

	return (
		<Table.Row>
			<Table.Cell className={classes.fieldContainer}>
				{create ? (
					<Input
						autoFocus
						fullWidth
						slimPadding
						errors={urlErrors}
						onError={onUrlError}
						onValueChange={setWebhookUrl}
						suffix={isLoading ? <LoadingSpinner inline /> : null}
						disabled={isLoading}
						validators={urlValidityChecks}
						value={webhookUrl}
					/>
				) : (
					<Entity content={webhookUrl} isDeleted={!webhook?.enabled} />
				)}
			</Table.Cell>
			<Table.Cell className={classes.fieldContainer}>
				<JSONTextArea
					className={classes.field}
					value={headers}
					errors={headersErrors}
					onError={onHeadersError}
					disabled={!create || isLoading}
					onChange={setHeaders}
				/>
			</Table.Cell>
			<Table.Cell className={classes.fieldContainer}>
				<JSONTextArea
					className={classes.field}
					errors={additionalAuditLogParamsErrors}
					onError={onAdditionalAuditLogParamsError}
					value={additionalAuditLogParams}
					disabled={!create || isLoading}
					onChange={setAdditionalAuditLogParams}
				/>
			</Table.Cell>
			<Table.Cell className={classNames(classes.actionsContainer, { [classes.create]: create })}>
				<AuditLogsWebhookButtons
					create={create}
					enabled={webhook?.enabled ?? false}
					isLoading={isLoading}
					isValid={isValid}
					onCancel={closeInput}
					onCreate={onCreate}
					onEnable={onEnable}
					onRemove={onRemove}
				/>
			</Table.Cell>
		</Table.Row>
	);
};
