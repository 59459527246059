import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { DirectoryGroupTooltip } from "components/common/DirectoryGroupTooltip";
import { useDirectoryGroups } from "hooks/useDirectoryGroups";
import { LoadingDots } from "components/ui/LoadingDots";
import { ChevronDownIcon } from "components/ui/Icons/ChevronDownIcon";
import { ChevronUpIcon } from "components/ui/Icons/ChevronUpIcon";
import { Table } from "components/ui/Table";
import { useLoadingState } from "hooks/useLoadingState";
import { Button } from "components/ui/Button";
import { useIntegrationResourceRoles } from "hooks/useIntegrationResourceRoles";
import { Chip } from "components/ui/Chip";
import { useOnCallIntegrationSchedules } from "hooks/useOnCallIntegrationSchedules";
import { useCompany } from "hooks/useCompany";
import { OnCallIntegrationScheduleLabel } from "components/common/OnCallIntegrationScheduleLabel";
import { RolesList } from "./components/RolesList";
import { BundlesList } from "./components/BundlesList";
import { useStyles } from "./styles";
import type { DirectoryGroupModel } from "models/DirectoryGroupModel";
import type { PolicyModel } from "models/PolicyModel";
import type { OnCallIntegrationScheduleModel } from "models/OnCallIntegrationScheduleModel";

interface IProps {
	policy: PolicyModel;
	deletePolicy: (policyId: string) => void;
	movePolicyUp?: (policy: PolicyModel) => Promise<{ ok: boolean } | undefined>;
	movePolicyDown?: (policy: PolicyModel) => Promise<{ ok: boolean } | undefined>;
	openEditPolicyModal: (policy: PolicyModel) => void;
}

export const PolicyRow: FC<IProps> = ({ policy, openEditPolicyModal, movePolicyDown, movePolicyUp, deletePolicy }) => {
	const { t } = useTranslation();
	const classes = useStyles();
	const directoryGroups = useDirectoryGroups();
	const company = useCompany();
	const onCallIntegrationSchedules = useOnCallIntegrationSchedules();
	const { isLoading, withLoader } = useLoadingState();
	const roles = useIntegrationResourceRoles(policy.integrationResourceRoleIds, true);

	const schedulesLoadedOrNotNeeded = useMemo(() => {
		if (!company) return false;
		return company.integratedToOnCalls.size > 0 ? !!onCallIntegrationSchedules : true;
	}, [company, onCallIntegrationSchedules]);

	const policyDirectoryGroups = useMemo(() => {
		const groups = policy.directoryGroupIds.map(id => directoryGroups?.find(group => group.id === id)).filter(Boolean);
		return groups as DirectoryGroupModel[];
	}, [policy.directoryGroupIds, directoryGroups]);

	const policyOnCallIntegrationSchedules = useMemo(() => {
		const schedules = policy.onCallIntegrationScheduleIds
			.map(id => onCallIntegrationSchedules?.find(schedule => schedule.id === id))
			.filter(Boolean);
		return schedules as OnCallIntegrationScheduleModel[];
	}, [onCallIntegrationSchedules, policy.onCallIntegrationScheduleIds]);

	const openEditModalHandler = useCallback(() => {
		openEditPolicyModal(policy);
	}, [policy, openEditPolicyModal]);

	const deletePolicyHandler = useCallback(() => {
		deletePolicy(policy.id);
	}, [policy, deletePolicy]);

	const movePolicyUpHandler = useCallback(() => {
		if (movePolicyUp && !isLoading) {
			withLoader(movePolicyUp(policy));
		}
	}, [isLoading, movePolicyUp, policy, withLoader]);

	const movePolicyDownHandler = useCallback(() => {
		if (movePolicyDown && !isLoading) {
			withLoader(movePolicyDown(policy));
		}
	}, [isLoading, movePolicyDown, policy, withLoader]);

	return (
		<Table.Row key={policy.id} className={classes.policyRowContainer}>
			<Table.Cell className={classes.policyRowActions}>
				<ChevronDownIcon
					onClick={movePolicyDown ? movePolicyDownHandler : undefined}
					disabled={!movePolicyDown || isLoading}
				/>
				<ChevronUpIcon onClick={movePolicyUp ? movePolicyUpHandler : undefined} disabled={!movePolicyUp || isLoading} />
			</Table.Cell>
			<Table.Cell className={classes.policyRowActions}>{policy.number}</Table.Cell>
			<Table.Cell className={classes.policyRowList}>
				{directoryGroups && schedulesLoadedOrNotNeeded ? (
					<>
						{policyDirectoryGroups.map((group, index) => (
							<Chip key={group.id + index}>
								<DirectoryGroupTooltip
									groupEmail={group.email ?? undefined}
									groupName={group.name}
									isDeleted={group.isDeleted}
									groupId={group.id}
								/>
							</Chip>
						))}
						{policyOnCallIntegrationSchedules.map((schedule, index) => (
							<Chip key={schedule.id + index}>
								<OnCallIntegrationScheduleLabel
									value={schedule.name}
									onCallType={schedule.type}
									isDeleted={schedule.isDeleted}
								/>
							</Chip>
						))}
					</>
				) : (
					<LoadingDots center />
				)}
			</Table.Cell>
			<Table.Cell className={classes.policyRowList}>
				<BundlesList bundleIds={policy.bundleIds} />
			</Table.Cell>
			<Table.Cell className={classes.policyRowList}>
				{roles !== null ? <RolesList roles={roles} /> : <LoadingDots center />}
			</Table.Cell>
			<Table.Cell className={classes.policyRowActions}>
				<Button size="small" variant="text" onClick={openEditModalHandler}>
					{t("buttons.edit")}
				</Button>
				<Button size="small" variant="text" onClick={deletePolicyHandler}>
					{t("buttons.delete")}
				</Button>
			</Table.Cell>
		</Table.Row>
	);
};
