/**
 * Request an animation frame with a timeout fallback, so the callback would be called even
 * when the page is not visible on the user's screen
 * @param {function} callback
 * @param {number|null} animationFrameTimeout - if an animation frame is not given within this time,
 * consider the render frame as resolved anyway
 * @returns {{destroy: function}}
 */
export default function requestRenderFrame(
	callback: () => void,
	animationFrameTimeout: number | null = 500
): RenderFrameHandle {
	const timeoutHandle =
		animationFrameTimeout == null
			? null
			: setTimeout(() => {
					cancelAnimationFrame(animationFrameHandle);
					callback();
				}, animationFrameTimeout);

	let animationFrameHandle = requestAnimationFrame(() => {
		animationFrameHandle = requestAnimationFrame(() => {
			if (timeoutHandle) clearTimeout(timeoutHandle);
			callback();
		});
	});

	return {
		destroy() {
			cancelAnimationFrame(animationFrameHandle);
			if (timeoutHandle) clearTimeout(timeoutHandle);
		}
	};
}

export type RenderFrameHandle = { destroy(): void };
