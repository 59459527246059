import { List, Record } from "immutable";
import { ApplicationModel } from "models/ApplicationModel";
import { ApprovalAlgorithmModel } from "models/ApprovalAlgorithmModel";
import { IntegrationMaintainerModel } from "./IntegrationMaintainerModel";
import type { TTicketDuration } from "utils/durationsOptions";

const defaults = {
	actorless: false,
	adapterless: false,
	agentTokenId: null as string | null,
	allowAsGrantMethod: true,
	allowedDurations: null as List<TTicketDuration> | null,
	allowsRequests: true,
	application: null as ApplicationModel | null,
	applicationId: null as string | null,
	autoAssignRecommendedResourceMaintainers: false,
	autoAssignRecommendedResourceOwner: false,
	canCreateActors: false as boolean,
	canEditPermissions: true as boolean,
	configuration: null as { [key: string]: string } | null,
	defaultAllowAsGrantMethod: false as boolean,
	defaultAllowsRequests: false as boolean,
	defaultApprovalAlgorithm: new ApprovalAlgorithmModel(),
	id: "",
	imageUrl: null as string | null,
	isDeleted: false,
	lastFullSync: null as Date | null,
	lastResourcesSync: null as Date | null,
	maintainers: null as List<IntegrationMaintainerModel> | null,
	manual: false,
	name: "",
	nextSyncTime: new Date(),
	notifyAboutExternalPermissions: false,
	oauthConfiguration: false as boolean,
	ownerId: "",
	prerequisitePermissionId: null as string | null,
	readonly: false,
	resourcesCount: 0,
	syncLimitInMinutes: 0,
	virtual: false
};

export class IntegrationModel extends Record<typeof defaults>(defaults) {
	static fromServerData(data: unknown) {
		const {
			adapterless,
			allowAsGrantMethod,
			allowedDurations,
			allowsRequests,
			agentTokenId,
			application,
			applicationId,
			autoAssignRecommendedResourceMaintainers,
			autoAssignRecommendedResourceOwner,
			canCreateActors,
			canEditPermissions,
			configuration,
			defaultAllowAsGrantMethod,
			defaultAllowsRequests,
			defaultApprovalAlgorithm,
			id,
			imageUrl,
			isDeleted,
			lastFullSync,
			lastResourcesSync,
			maintainers,
			manual,
			name,
			notifyAboutExternalPermissions,
			nextSyncTime,
			oauthConfiguration,
			ownerId,
			prerequisitePermissionId,
			readonly,
			resourcesCount,
			syncLimitInMinutes,
			virtual
		} = data as typeof defaults;
		const modelApplication = application ? ApplicationModel.fromServerData(application) : null;
		return new IntegrationModel({
			adapterless,
			allowAsGrantMethod,
			allowedDurations: allowedDurations ? List(allowedDurations) : null,
			allowsRequests,
			agentTokenId: agentTokenId || null,
			application: modelApplication,
			applicationId,
			autoAssignRecommendedResourceMaintainers,
			autoAssignRecommendedResourceOwner,
			canCreateActors,
			canEditPermissions,
			configuration,
			defaultAllowAsGrantMethod,
			defaultAllowsRequests,
			defaultApprovalAlgorithm: defaultApprovalAlgorithm
				? ApprovalAlgorithmModel.fromServerData(defaultApprovalAlgorithm)
				: undefined,
			id,
			imageUrl: imageUrl || null,
			isDeleted,
			lastFullSync: lastFullSync ? new Date(lastFullSync) : null,
			lastResourcesSync: lastResourcesSync ? new Date(lastResourcesSync) : null,
			maintainers: maintainers
				? List(maintainers.map(maintainer => IntegrationMaintainerModel.fromServerData(maintainer)))
				: null,
			manual,
			name,
			notifyAboutExternalPermissions,
			nextSyncTime: new Date(nextSyncTime),
			oauthConfiguration,
			ownerId,
			prerequisitePermissionId,
			readonly,
			resourcesCount: Number(resourcesCount),
			syncLimitInMinutes,
			virtual,
			actorless: canCreateActors && !canEditPermissions
		});
	}
}
