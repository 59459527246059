import React, { useCallback } from "react";
import { Trans, useTranslation } from "react-i18next";
import { AreYouSureModal } from "components/common/AreYouSureModal";
import { Button } from "components/ui/Button";
import { GrantedIcon } from "components/ui/Icons/GrantedIcon";
import { DeleteIcon } from "components/ui/Icons/DeleteIcon";
import { ViewIcon } from "components/ui/Icons/ViewIcon";
import { Typography } from "components/ui/Typography";
import { useAccessReviewsContext } from "context/accessReviewsContext";
import { useSingleUser } from "hooks/useSingleUser";
import { useOpenGlobalErrorModal } from "hooks/useGlobalError";
import useIsOpenState from "hooks/useIsOpenState";
import { AccessReviewModel } from "models/AccessReviewModel";
import { User } from "../User";
import { useStyles } from "./styles";

const TRANSLATION_PREFIX = "pages.accessReview.dashboard";

interface IProps {
	accessReview: AccessReviewModel;
	changeToLatest: () => void;
}

export const SummaryHeader: FC<IProps> = ({ accessReview, changeToLatest }) => {
	const classes = useStyles();
	const { t } = useTranslation();
	const deleteAreYouSureModal = useIsOpenState();
	const doneAreYouSureModal = useIsOpenState();
	const activateAreYouSureModal = useIsOpenState();
	const openErrorModal = useOpenGlobalErrorModal();

	const {
		state: { isActivateLoading, isDeleteLoading, isFinishLoading },
		actions: { activateAccessReview, deleteAccessReview, finishAccessReview }
	} = useAccessReviewsContext();

	const { user: accessReviewCreator } = useSingleUser(accessReview.creatorId, false);

	const onDelete = useCallback(async () => {
		try {
			await deleteAccessReview(accessReview.id);
			deleteAreYouSureModal.close();
		} catch (error) {
			openErrorModal(error as Error);
		}
	}, [accessReview.id, deleteAccessReview, deleteAreYouSureModal, openErrorModal]);

	const onFinish = useCallback(async () => {
		try {
			await finishAccessReview(accessReview.id);
			doneAreYouSureModal.close();
		} catch (error) {
			openErrorModal(error as Error);
		}
	}, [accessReview.id, doneAreYouSureModal, finishAccessReview, openErrorModal]);

	const onActivate = useCallback(async () => {
		try {
			await activateAccessReview(accessReview.id);
			activateAreYouSureModal.close();
		} catch (error) {
			openErrorModal(error as Error);
		}
	}, [accessReview.id, activateAccessReview, activateAreYouSureModal, openErrorModal]);

	const title = t(`${TRANSLATION_PREFIX}.title`);

	return (
		<div className={classes.header}>
			<AreYouSureModal
				isOpen={deleteAreYouSureModal.isOpen}
				onClose={deleteAreYouSureModal.close}
				onAction={onDelete}
			/>
			<AreYouSureModal
				title={t("pages.accessReview.complete.title")}
				content={
					<Trans
						i18nKey={"pages.accessReview.complete.description"}
						components={{ bold: <b /> }}
						context={accessReview?.immediateRevoke ? "revoke" : undefined}
					/>
				}
				actionLabel={t("buttons.done")}
				isOpen={doneAreYouSureModal.isOpen}
				onClose={doneAreYouSureModal.close}
				onAction={onFinish}
			/>
			<AreYouSureModal
				title={t("pages.accessReview.activate.title")}
				content={<div className={classes.wrapContent}>{t("pages.accessReview.activate.description")}</div>}
				actionLabel={t("buttons.activate")}
				isOpen={activateAreYouSureModal.isOpen}
				onClose={activateAreYouSureModal.close}
				onAction={onActivate}
			/>
			<Typography variant="h3">{`${title} - ${accessReview.name}`}</Typography>
			{accessReview && accessReviewCreator && (
				<Typography variant="small" className={classes.headerMetadata}>
					<Trans
						i18nKey={`${TRANSLATION_PREFIX}.metadata` as const}
						values={{ date: accessReview.createdAt }}
						components={{
							user: <User minimized user={accessReviewCreator} />
						}}
					/>
				</Typography>
			)}
			{accessReview.permissionCount && accessReview.permissionCount > 0 && (
				<div className={classes.headerButtons}>
					<Button
						variant="secondary"
						size="medium"
						prefix={<DeleteIcon />}
						onClick={deleteAreYouSureModal.open}
						loading={isDeleteLoading}
						disabled={isDeleteLoading}>
						{t(`${TRANSLATION_PREFIX}.deleteReview`)}
					</Button>
					<Button variant="secondary" size="medium" prefix={<ViewIcon />} onClick={changeToLatest}>
						{t(`${TRANSLATION_PREFIX}.viewReview`)}
					</Button>
					{["active", "done"].includes(accessReview.status) ? (
						<Button
							variant={accessReview.status === "done" ? "text" : "secondary"}
							size="medium"
							prefix={<GrantedIcon />}
							disabled={accessReview.status === "done"}
							loading={isFinishLoading}
							onClick={doneAreYouSureModal.open}>
							{t("buttons.done")}
						</Button>
					) : (
						<Button
							variant="secondary"
							size="medium"
							loading={isActivateLoading}
							onClick={activateAreYouSureModal.open}>
							{t("buttons.activate")}
						</Button>
					)}
				</div>
			)}
		</div>
	);
};
