import React from "react";
import { useTranslation } from "react-i18next";
import { Typography } from "components/ui/New/Typography";
import { ManualApprovalIcon } from "components/ui/Icons/ManualApprovalIcon";
import { ExpiredIcon } from "components/ui/Icons/ExpiredIcon";
import { NoAccessCircleIcon } from "components/ui/Icons/NoAccessCircleIcon";
import { DeniedIcon } from "components/ui/Icons/DeniedIcon";
import { CancelCircleIcon } from "components/ui/Icons/CancelCircleIcon";
import { GrantedIcon } from "components/ui/Icons/GrantedIcon";
import { PendingApprovalIcon } from "components/ui/Icons/PendingApprovalIcon";
import { IconButton } from "components/ui/IconButton";
import { PendingIcon } from "components/ui/Icons/PendingIcon";
import { ErrorIcon } from "components/ui/Icons/ErrorIcon";
import { useStyles } from "./styles";

type TProps = {
	size?: "tiny" | "small" | "medium" | "large" | "huge";
};

const PendingManualFix: FC<TProps> = ({ size = "medium" }) => {
	const { t } = useTranslation();
	const classes = useStyles();
	const tooltipContent = (
		<div className={classes.statusTooltip}>
			<ManualApprovalIcon />
			<Typography variant="text_sm_sb">{t("common.requestStatus.statuses.waitingForIT")}</Typography>
		</div>
	);
	return (
		<IconButton size={size}>
			<ManualApprovalIcon tooltip={tooltipContent} />
		</IconButton>
	);
};

export const PendingManualFixStatus = React.memo(PendingManualFix);

const Expired: FC<TProps> = ({ size = "medium" }) => {
	const { t } = useTranslation();
	const classes = useStyles();
	const tooltipContent = (
		<div className={classes.statusTooltip}>
			<ExpiredIcon />
			<Typography variant="text_sm_sb">{t("common.requestStatus.statuses.expired")}</Typography>
		</div>
	);
	return (
		<IconButton size={size}>
			<NoAccessCircleIcon tooltip={tooltipContent} />
		</IconButton>
	);
};

export const ExpiredStatus = React.memo(Expired);

const Denied: FC<TProps> = ({ size = "medium" }) => {
	const { t } = useTranslation();
	const classes = useStyles();
	const tooltipContent = (
		<div className={classes.statusTooltip}>
			<DeniedIcon />
			<Typography variant="text_sm_sb">{t("common.requestStatus.statuses.denied")}</Typography>
		</div>
	);
	return (
		<IconButton size={size}>
			<NoAccessCircleIcon tooltip={tooltipContent} />
		</IconButton>
	);
};

export const DeniedStatus = React.memo(Denied);

const AdminDenied: FC<TProps> = ({ size = "medium" }) => {
	const { t } = useTranslation();
	const classes = useStyles();
	const tooltipContent = (
		<div className={classes.statusTooltip}>
			<DeniedIcon />
			<Typography variant="text_sm_sb">{t("common.requestStatus.statuses.adminDenied")}</Typography>
		</div>
	);
	return (
		<IconButton size={size}>
			<NoAccessCircleIcon tooltip={tooltipContent} />
		</IconButton>
	);
};

export const AdminDeniedStatus = React.memo(AdminDenied);

const Revoked: FC<TProps> = ({ size = "medium" }) => {
	const { t } = useTranslation();
	const classes = useStyles();
	const tooltipContent = (
		<div className={classes.statusTooltip}>
			<CancelCircleIcon />
			<Typography variant="text_sm_sb">{t("common.requestStatus.statuses.revoked")}</Typography>
		</div>
	);
	return (
		<IconButton size={size}>
			<NoAccessCircleIcon tooltip={tooltipContent} />
		</IconButton>
	);
};

export const RevokedStatus = React.memo(Revoked);

const Failed: FC<TProps> = ({ size = "medium" }) => {
	const { t } = useTranslation();
	const classes = useStyles();
	const tooltipContent = (
		<div className={classes.statusTooltip}>
			<ErrorIcon />
			<Typography variant="text_sm_sb">{t("common.requestStatus.statuses.failed.label")} -</Typography>
			<Typography variant="text_sm_reg">{t("common.requestStatus.statuses.failed.contactAdmin")}</Typography>
		</div>
	);
	return (
		<IconButton size={size}>
			<NoAccessCircleIcon tooltip={tooltipContent} />
		</IconButton>
	);
};

export const FailedStatus = React.memo(Failed);

const Cancelled: FC<TProps> = ({ size = "medium" }) => {
	const { t } = useTranslation();
	const classes = useStyles();
	const tooltipContent = (
		<div className={classes.statusTooltip}>
			<CancelCircleIcon />
			<Typography variant="text_sm_sb">{t("common.requestStatus.statuses.cancelled")}</Typography>
		</div>
	);
	return (
		<IconButton size={size}>
			<NoAccessCircleIcon tooltip={tooltipContent} />
		</IconButton>
	);
};

export const CancelledStatus = React.memo(Cancelled);

const Granted: FC<TProps> = ({ size = "medium" }) => {
	const { t } = useTranslation();
	const classes = useStyles();
	const tooltipContent = (
		<div className={classes.statusTooltip}>
			<GrantedIcon />
			<Typography variant="text_sm_sb">{t("common.requestStatus.statuses.granted")}</Typography>
		</div>
	);
	return (
		<IconButton size={size}>
			<GrantedIcon tooltip={tooltipContent} />
		</IconButton>
	);
};

export const GrantedStatus = React.memo(Granted);

const PendingApproval: FC<TProps> = ({ size = "medium" }) => {
	const { t } = useTranslation();
	const classes = useStyles();
	const tooltipContent = (
		<div className={classes.statusTooltip}>
			<PendingApprovalIcon />
			<Typography variant="text_sm_sb">{t("common.requestStatus.statuses.pendingApproval")}</Typography>
		</div>
	);
	return (
		<IconButton size={size}>
			<PendingApprovalIcon tooltip={tooltipContent} />
		</IconButton>
	);
};

export const PendingApprovalStatus = React.memo(PendingApproval);

const PermissionInProgress: FC<TProps> = ({ size = "medium" }) => {
	const { t } = useTranslation();
	const classes = useStyles();
	const tooltipContent = (
		<div className={classes.statusTooltip}>
			<PendingIcon />
			<Typography variant="text_sm_sb">{t("common.requestStatus.statuses.permissionInProgress")}</Typography>
		</div>
	);
	return (
		<IconButton size={size}>
			<GrantedIcon tooltip={tooltipContent} />
		</IconButton>
	);
};

export const PermissionInProgressStatus = React.memo(PermissionInProgress);
