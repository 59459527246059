import React, { useMemo } from "react";
import classNames from "classnames";
import { IntegrationNode } from "components/common/Nodes/IntegrationNode";
import { ResourceNode } from "components/common/Nodes/ResourceNode";
import { RoleNode } from "components/common/Nodes/RoleNode";
import { useIntegrations } from "hooks/useIntegrations";
import { IntegrationImage } from "components/common/IntegrationImage";
import { TooltipOnOverflow } from "components/ui/New/TooltipOnOverflow";
import { ResourcesIcon } from "components/ui/Icons/ResourcesIcon";
import { Typography } from "components/ui/New/Typography";
import { useBreakpoints } from "hooks/useBreakpoints";
import { Bp } from "utils/breakpoints";
import { useIntegrationResourceRole } from "hooks/useIntegrationResourceRole";
import { useIntegrationResource } from "hooks/useIntegrationResource";
import { ResourceDetailsTooltip } from "components/common/ResourceDetailsTooltip";
import { useStyles } from "./styles";
import type { IntegrationModel } from "models/IntegrationModel";
import type { IntegrationResourceModel } from "models/IntegrationResourceModel";
import type { IntegrationResourceRoleModel } from "models/IntegrationResourceRoleModel";

type TFullRole = Omit<IntegrationResourceRoleModel, "integrationResource"> & {
	integrationResource: IntegrationResourceModel;
};

type TModelCardProps = {
	role: TFullRole;
};

type TIdCardProps = {
	role: string;
};

type TProps = {
	roles: string[] | IntegrationResourceRoleModel[] | TFullRole[];
};

const SkeletonText: FC = () => {
	const classes = useStyles({});
	return <div className={classes.skeletonText} />;
};

const SkeletonIcon: FC = () => {
	const classes = useStyles({});
	return <div className={classes.skeletonIcon} />;
};

const isFullRole = (role: IntegrationResourceRoleModel): role is TFullRole => {
	return !!role.integrationResource;
};

const useRoleResourceIntegration = (id: string) => {
	const { role } = useIntegrationResourceRole(id, true);
	const { resource } = useIntegrationResource(role?.integrationResourceId || "", false, true);
	const integrations = useIntegrations(true);
	const integration = integrations?.get(resource?.integrationId || "");
	if (!role || !resource || !integration) {
		return null;
	}
	return { role, resource, integration };
};

const useCardContent = (
	role?: IntegrationResourceRoleModel | null,
	resource?: IntegrationResourceModel | null,
	integration?: IntegrationModel | null
) => {
	const classes = useStyles({});
	const header = useMemo(() => {
		return (
			<>
				{integration ? <IntegrationImage integration={integration} /> : <SkeletonIcon />}
				{integration ? <TooltipOnOverflow textVariant="text_sm_sb" content={integration.name} /> : <SkeletonText />}
			</>
		);
	}, [integration]);
	const content = useMemo(() => {
		const resourceContent = resource ? (
			<ResourceDetailsTooltip
				description={resource.description}
				euid={resource.euid}
				name={resource.displayName}
				type={resource.type}>
				<div className={classes.resourceInformation}>
					<ResourcesIcon />
					<TooltipOnOverflow textVariant="text_sm_reg" content={resource.displayName} />
				</div>
			</ResourceDetailsTooltip>
		) : (
			<>
				<SkeletonIcon />
				<SkeletonText />
			</>
		);

		const roleContent = role ? <TooltipOnOverflow textVariant="text_title_sb" content={role.name} /> : <SkeletonText />;
		return (
			<>
				<ResourceNode content={resourceContent} />
				<RoleNode content={roleContent} />
			</>
		);
	}, [classes.resourceInformation, resource, role]);

	return { header, content };
};

const PermissionCardById: FC<TIdCardProps> = ({ className, innerRef, role }) => {
	const classes = useStyles({});
	const modelsData = useRoleResourceIntegration(role);
	const { header, content } = useCardContent(modelsData?.role, modelsData?.resource, modelsData?.integration);

	const isLoading = useMemo(
		() => !modelsData || !modelsData.role || !modelsData.resource || !modelsData.integration,
		[modelsData]
	);

	return (
		<IntegrationNode
			className={classNames(className, { [classes.nodeSkeleton]: isLoading })}
			innerRef={innerRef}
			header={header}
			content={content}
		/>
	);
};

const PermissionCardByModel: FC<TModelCardProps> = ({ className, innerRef, role }) => {
	const classes = useStyles({});
	const integrations = useIntegrations(true);
	const integration = integrations?.get(role.integrationResource.integrationId);
	const { header, content } = useCardContent(role, role.integrationResource, integration);

	const isLoading = useMemo(() => !role || !role.integrationResource || !integration, [role, integration]);
	return (
		<IntegrationNode
			className={classNames(className, { [classes.nodeSkeleton]: isLoading })}
			innerRef={innerRef}
			header={header}
			content={content}
		/>
	);
};

const DEFAULT_SHOWN = 1;
const SHOWN_MAX = 2;

const RequestPermissions: FC<TProps> = ({ className, innerRef, roles }) => {
	const [isBiggerThan] = useBreakpoints(Bp.SECOND);
	const shown = isBiggerThan ? SHOWN_MAX : DEFAULT_SHOWN;
	const classes = useStyles({ nodesAmount: shown });

	const mappedCards: JSX.Element[] = useMemo(() => {
		return roles.slice(0, shown).map(role => {
			const id = typeof role === "string" ? role : role.id;
			if (typeof role === "string" || !isFullRole(role)) {
				return <PermissionCardById key={id} role={id} />;
			}
			return <PermissionCardByModel key={id} role={role} />;
		});
	}, [roles, shown]);

	return (
		<div className={classNames(classes.container, className)} ref={innerRef}>
			{mappedCards}
			{shown < roles.length ? <Typography variant="text_title_sb">{roles.length - shown}+</Typography> : null}
		</div>
	);
};

const memoizedRequestPermissions = React.memo(RequestPermissions);
export { memoizedRequestPermissions as RequestPermissions };
