import { useCallback, useState } from "react";

export const useAbortController = <T>() => {
	const [abortController, setAbortController] = useState<AbortController | null>(null);

	const abortWrapper = useCallback(
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		async (callback: (...args: any[]) => Promise<T>) => {
			if (abortController) abortController.abort();
			const newAbortController = new AbortController();
			setAbortController(newAbortController);

			const result = await callback(newAbortController.signal);

			setAbortController(null);
			return result;
		},
		[abortController]
	);

	const removeAbortController = useCallback(() => setAbortController(null), []);

	return { abortWrapper, removeAbortController };
};
