import classNames from "classnames";
import { Avatar } from "components/common/Avatar";
import { Block } from "components/ui/Block";
import { Button } from "components/ui/Button";
import { LoadingSpinner } from "components/ui/LoadingSpinner";
import { Typography } from "components/ui/Typography";
import { TaskModel, TTaskType } from "models/TaskModel";
import React, { useCallback, useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "components/common/Link";
import { Entity } from "components/common/Entity";
import { useCompany } from "hooks/useCompany";
import { useUsers } from "hooks/useUsers";
import { useSingleUser } from "hooks/useSingleUser";
import { UserTooltip } from "components/common/UserTooltip";
import { TicketNumberWithLink } from "components/common/TicketNumberWithLink";
import { useLoadingState } from "hooks/useLoadingState";
import { useStyles } from "./styles";
import type { Map as ImmutableMap } from "immutable";
import type { IntegrationModel } from "models/IntegrationModel";

const TRANSLATION_PREFIX = "pages.tasks.taskCard";

interface IProps {
	buttonText?: string;
	index?: number;
	integrations: ImmutableMap<string, IntegrationModel>;
	onButtonClick?: (id: string) => Promise<void>;
	order?: number;
	task: TaskModel;
}

const ApprovalTaskBody: FC<{ task: TaskModel; integrations: ImmutableMap<string, IntegrationModel> }> = ({
	task,
	integrations
}) => {
	const classes = useStyles();
	const { t } = useTranslation();
	const users = useUsers();
	const company = useCompany();

	const integrationId = task.integrationResourceRole?.integrationResource?.integrationId || null;
	const integration = integrations && integrationId ? integrations.get(integrationId) : null;
	const actorFirstUser = task.ticketPermission?.ticket?.receiverId
		? users?.get(task.ticketPermission?.ticket?.receiverId)
		: (task?.integrationActor?.userIds?.first() && users?.get(task?.integrationActor?.userIds?.first())) || null;
	const actorIntegrationActorResourceRole = task?.integrationResourceRole;
	const taskTicket = task.ticketPermission?.ticket;

	return integration && task ? (
		<div className={classes.approvalTaskBody}>
			<div className={classes.cardRow}>
				<Typography className={classes.sectionHeader}>{t(`${TRANSLATION_PREFIX}.fields.for`)}</Typography>
				{actorFirstUser && (
					<div>
						<UserTooltip user={actorFirstUser}>
							<Entity content={actorFirstUser.fullName} isDeleted={actorFirstUser.isDeleted} />
						</UserTooltip>
					</div>
				)}
			</div>
			<div className={classes.cardRow}>
				<Typography className={classes.sectionHeader}>{t(`${TRANSLATION_PREFIX}.fields.role.label`)}</Typography>
				<Typography>
					{task.type === "revert" && task.oldIntegrationResourceRole ? (
						<Trans
							i18nKey={`${TRANSLATION_PREFIX}.fields.role.value`}
							values={{
								role: actorIntegrationActorResourceRole?.name,
								oldRole: task.oldIntegrationResourceRole.name
							}}
							components={{ bold: <b /> }}
						/>
					) : (
						actorIntegrationActorResourceRole?.name
					)}
				</Typography>
			</div>
			<div className={classes.cardRow}>
				<Typography className={classes.sectionHeader}>{t(`${TRANSLATION_PREFIX}.fields.resource`)}</Typography>
				<Typography className={classes.sectionLabel}>
					{actorIntegrationActorResourceRole?.integrationResource?.displayName}
				</Typography>
			</div>
			<div className={classes.cardRow}>
				<Typography className={classes.sectionHeader}>{t(`${TRANSLATION_PREFIX}.fields.integration`)}</Typography>
				<Typography className={classes.sectionLabel}>{integration.name}</Typography>
			</div>
			{taskTicket?.id && (
				<div className={classes.cardRow}>
					<Typography className={classes.sectionHeader}>{t(`${TRANSLATION_PREFIX}.fields.requestNumber`)}</Typography>
					<TicketNumberWithLink key={taskTicket.id} ticketId={taskTicket.id} ticketNumber={taskTicket.number} />
				</div>
			)}
			{task.url && company?.ticketingIntegration ? (
				<div className={classes.cardRow}>
					<Typography className={classes.sectionHeader}>
						{t(`${TRANSLATION_PREFIX}.fields.${company.ticketingIntegration}`)}
					</Typography>
					<Link noDecoration to={task.url} rel="noreferrer noopener">
						{task.url.split("/").pop()}
					</Link>
				</div>
			) : null}
		</div>
	) : (
		<LoadingSpinner />
	);
};

const TaskBodyByType = new Map<
	TTaskType,
	FC<{ task: TaskModel; integrations: ImmutableMap<string, IntegrationModel> }>
>();

TaskBodyByType.set("grant", ApprovalTaskBody);
TaskBodyByType.set("revert", ApprovalTaskBody);
TaskBodyByType.set("revoke", ApprovalTaskBody);

export const TaskCard: FC<IProps> = ({ task, onButtonClick, buttonText, integrations }) => {
	const classes = useStyles();
	const { t } = useTranslation();
	const { isLoading, withLoader } = useLoadingState();

	const onClick = useCallback(async () => {
		if (onButtonClick) {
			await withLoader(onButtonClick(task.id));
		}
	}, [onButtonClick, task.id, withLoader]);

	const Body = useMemo(() => (task ? TaskBodyByType.get(task.type) : null), [task]);
	const { user: taskAssignedUser } = useSingleUser(task.assignedUserId, false);
	if (!Body) {
		return null;
	}

	return (
		<Block className={classNames(classes.taskCard, classes[task.status])}>
			<div className={classes.cardHeader}>
				<Typography variant="h3">{t(`${TRANSLATION_PREFIX}.title.${task.type}`)}</Typography>
				<div className={classes.assignee}>
					{taskAssignedUser ? (
						<UserTooltip user={taskAssignedUser}>
							<Avatar user={taskAssignedUser} />
						</UserTooltip>
					) : null}
				</div>
			</div>
			<div className={classes.cardBody}>{<Body task={task} integrations={integrations} />}</div>
			{buttonText && onClick && (
				<div className={classes.cardFooter}>
					<Button variant="secondary" size="small" onClick={onClick} loading={isLoading}>
						{buttonText}
					</Button>
				</div>
			)}
		</Block>
	);
};
