import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	image: {
		height: "32px",
		width: "32px",
		borderRadius: "50%"
	},
	userNameWithEmail: {
		display: "flex",
		flexDirection: "column",
		minWidth: 0,
		justifyContent: "center",
		alignItems: "flex-start"
	}
});
