import React, { useCallback, useEffect, useMemo, useState } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { PageTemplate } from "components/templates/PageTemplate";
import { Loading } from "components/ui/Loading";
import { SubordinatesReviewTable } from "components/common/SubordinatesReviewTable";
import { Typography } from "components/ui/Typography";
import { useAccessReviewReportState } from "hooks/useAccessReviewReportState";
import { AccessReviewSubordinateModel } from "models/AccessReviewSubordinateModel";
import { useNavigate, useParams } from "react-router-dom";
import { List } from "immutable";
import { useAccessReviews } from "hooks/useAccessReviews";
import { Select } from "components/ui/Select";
import { AccessReviewReportModel } from "models/AccessReviewReportModel";
import { CloseCircleIcon } from "components/ui/Icons/CloseCircleIcon";
import { IEntitlementsStatistics, reduceStatistics } from "utils/accessReview";
import { GrantedIcon } from "components/ui/Icons/GrantedIcon";
import { InProgressIcon } from "components/ui/Icons/InProgressIcon";
import { PendingIcon } from "components/ui/Icons/PendingIcon";
import { Tabs } from "components/ui/Tabs";
import { ResourcesReviewTable } from "components/common/ResourcesReviewTable";
import { useAccessReviewsContext } from "context/accessReviewsContext";
import { DoneCircleIcon } from "components/ui/Icons/DoneCircleIcon";
import { useStyles } from "./styles";
import type { AccessReviewResourceModel } from "models/AccessReviewResourceModel";

const TRANSLATION_PREFIX = "common.accessReview.statuses";

interface IContentProps {
	accessReviewReport?: AccessReviewReportModel;
	chosenReportId?: string;
	onViewResource: (resource: AccessReviewResourceModel | null) => void;
	onViewSubordinate: (subordinate: AccessReviewSubordinateModel | null) => void;
	reports?: List<AccessReviewReportModel>;
}

const AccessReportPageContent: FC<IContentProps> = ({
	accessReviewReport,
	chosenReportId,
	className,
	id,
	innerRef,
	onViewResource,
	onViewSubordinate,
	reports
}) => {
	const { t } = useTranslation();
	const classes = useStyles();
	const defaultTab = accessReviewReport?.sortedSubordinates?.length ? "subordinates" : "resources";

	return (
		<>
			{!reports?.size && (
				<div className={classes.noReports}>
					<Typography variant="h2">{t("pages.accessReport.noReviews.title")}</Typography>
					<Typography variant="h3">{t("pages.accessReport.noReviews.message")}</Typography>
				</div>
			)}
			{accessReviewReport && chosenReportId && (
				<div className={classNames(classes.page, className)} id={id} ref={innerRef}>
					<Tabs defaultActiveKey={defaultTab}>
						<Tabs.Tab
							disabled={!accessReviewReport.sortedSubordinates?.length}
							key="subordinates"
							tab={t("common.subordinatesReviewTable.title", {
								count: accessReviewReport.sortedSubordinates?.length
							})}>
							<SubordinatesReviewTable reportId={chosenReportId} onViewClick={onViewSubordinate} />
						</Tabs.Tab>
						<Tabs.Tab
							disabled={!accessReviewReport.sortedResources?.length}
							key="resources"
							tab={t("common.resourcesReviewTable.title", {
								count: accessReviewReport.sortedResources?.length
							})}>
							<ResourcesReviewTable reportId={chosenReportId} onViewClick={onViewResource} />
						</Tabs.Tab>
					</Tabs>
				</div>
			)}
		</>
	);
};

export const AccessReportPage: FC = ({ className }) => {
	const { t } = useTranslation();
	const classes = useStyles();
	const { accessReviews } = useAccessReviews();
	const {
		state: { latestAccessReviewId }
	} = useAccessReviewsContext();

	const [chosenReportId, setChosenReportId] = useState<string>();
	const { accessReviewId } = useParams<{ accessReviewId: string }>();

	const navigate = useNavigate();

	const { accessReviewReport, loading, reports, getAccessReviewReport } = useAccessReviewReportState(
		chosenReportId || ""
	);

	useEffect(() => {
		if (chosenReportId) {
			getAccessReviewReport();
		}
	}, [chosenReportId, getAccessReviewReport]);

	const sortedReports = useMemo(
		// Sort by createdAt descending
		() =>
			reports
				?.sortBy(
					r => r.createdAt,
					(a, b) => b.valueOf() - a.valueOf()
				)
				.toArray() || [],
		[reports]
	);

	const selectedReportValue = useMemo(() => {
		return sortedReports?.find(r => r.id === chosenReportId) || null;
	}, [chosenReportId, sortedReports]);

	const mergedStatistics: IEntitlementsStatistics | null = useMemo(() => {
		if (!accessReviewReport) {
			return null;
		}
		// this will reduce together all the statistics of all the subordinates to one object
		const permissionsStatuses: IEntitlementsStatistics[] =
			[accessReviewReport.statistics?.permissionsStatus].filter((item): item is IEntitlementsStatistics =>
				Boolean(item)
			) || [];
		return reduceStatistics(permissionsStatuses);
	}, [accessReviewReport]);

	const onViewSubordinate = useCallback(
		(subordinate: AccessReviewSubordinateModel | null) => {
			if (subordinate && subordinate.id) {
				navigate(`/accessReport/${accessReviewId || latestAccessReviewId}/subordinates/${subordinate.id}`);
			}
		},
		[accessReviewId, latestAccessReviewId, navigate]
	);

	const onViewResource = useCallback(
		(resource: AccessReviewResourceModel | null) => {
			if (resource && resource.id) {
				navigate(`/accessReport/${accessReviewId || latestAccessReviewId}/resources/${resource.id}`);
			}
		},
		[accessReviewId, latestAccessReviewId, navigate]
	);

	const isLatestReport = useCallback(
		(report: AccessReviewReportModel) => {
			return report.accessReviewId === latestAccessReviewId;
		},
		[latestAccessReviewId]
	);

	const getOptionLabel: (report: AccessReviewReportModel) => string = useCallback(
		report => {
			const isLatest = isLatestReport(report);
			const accessReview = accessReviews?.get(report.accessReviewId);
			const options = { date: report.createdAt, name: accessReview?.name };
			return isLatest ? t("pages.accessReview.latestName", options) : t("pages.accessReview.name", options);
		},
		[accessReviews, isLatestReport, t]
	);

	const renderOption = useCallback(
		(report: AccessReviewReportModel) => {
			const label = getOptionLabel(report);
			return (
				<div className={classes.option}>
					<Typography>{label}</Typography>
				</div>
			);
		},
		[classes.option, getOptionLabel]
	);

	const switchReport = useCallback(
		(report: AccessReviewReportModel | null) => {
			if (report) {
				navigate(`/accessReport/${report.accessReviewId}`);
			}
		},
		[navigate]
	);

	useEffect(() => {
		let newChosenReportId: string | undefined;

		if (sortedReports && sortedReports.length > 0 && !accessReviewId) {
			newChosenReportId = sortedReports.at(0)!.id;
		} else if (sortedReports && sortedReports.length > 0 && accessReviewId) {
			const chosenReport = sortedReports.find(report => report.accessReviewId === accessReviewId);
			newChosenReportId = chosenReport ? chosenReport.id : sortedReports.at(0)!.id;
		}
		setChosenReportId(newChosenReportId);
	}, [accessReviewId, sortedReports]);

	const [statusClassName, StatusIcon, status] = useMemo(() => {
		const accessReview = accessReviewReport?.accessReviewId
			? accessReviews?.get(accessReviewReport.accessReviewId)
			: undefined;

		const calculatedStatus = accessReview?.status === "done" ? "done" : accessReviewReport?.calculatedStatus;

		switch (calculatedStatus) {
			case "done":
				return [classes.statusDone, GrantedIcon, calculatedStatus];
			case "inProgress":
				return [classes.statusInProgress, InProgressIcon, calculatedStatus];
			case "pending":
				return [classes.statusPending, PendingIcon, calculatedStatus];
		}
		return [null, null];
	}, [accessReviewReport, accessReviews, classes]);

	const contentProps = useMemo(
		() => ({ accessReviewReport, chosenReportId, onViewResource, onViewSubordinate, reports }) as IContentProps,
		[accessReviewReport, chosenReportId, onViewResource, onViewSubordinate, reports]
	);

	return (
		<PageTemplate className={classNames(className)}>
			<PageTemplate.Title className={classes.header}>
				<div className={classes.headerTitle}>
					{t("pages.accessReport.title")}
					{accessReviewReport && (
						<>
							<Typography>
								{t("common.accessReview.entitlements", { count: accessReviewReport.permissionsSize })}
							</Typography>
							{mergedStatistics && (
								<>
									<Typography className={classes.statistics}>
										<DoneCircleIcon.Green />
										{`${mergedStatistics.approved} ${t(`${TRANSLATION_PREFIX}.permissions.approved`)}`}
									</Typography>
									<Typography className={classes.statistics}>
										<CloseCircleIcon.Red />
										{`${mergedStatistics.denied} ${t(`${TRANSLATION_PREFIX}.permissions.denied`)}`}
									</Typography>
								</>
							)}
							<Typography className={classNames(classes.statusChip, statusClassName)}>
								{StatusIcon && <StatusIcon />}
								{status && t(`${TRANSLATION_PREFIX}.subordinates.${status}`)}
							</Typography>
						</>
					)}
				</div>
				<div className={classes.actions}>
					<Select
						getOptionLabel={getOptionLabel}
						onChange={switchReport}
						options={sortedReports}
						renderOption={renderOption}
						required
						value={selectedReportValue}
						fullWidth
					/>
				</div>
			</PageTemplate.Title>
			<PageTemplate.Content className={classes.content}>
				<Loading loading={loading} data={contentProps} render={AccessReportPageContent} />
			</PageTemplate.Content>
		</PageTemplate>
	);
};
