import React from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { IconPrefix } from "components/ui/IconPrefix";
import { useNewRequestFormContext } from "components/pages/NewRequestPage/newRequestFormContext";
import { DurationIcon } from "components/ui/Icons/DurationIcon";
import { Typography } from "components/ui/New/Typography";
import { Section } from "components/ui/Section";
import { WarningIcon } from "components/ui/Icons/WarningIcon";
import { EditButton } from "../EditButton";
import { useStyles } from "./styles";

const TRANSLATION_PREFIX = "pages.newRequest.summaryStep.accessDurationSection";

export const AccessDurationSection: FC = ({ className, innerRef }) => {
	const { t } = useTranslation();
	const classes = useStyles();

	const {
		state: { duration }
	} = useNewRequestFormContext();

	return (
		<Section
			className={classNames(className)}
			title={<Typography variant="body_sb">{t(`${TRANSLATION_PREFIX}.title`)}</Typography>}
			innerRef={innerRef}>
			<div className={classes.container}>
				{duration ? (
					<IconPrefix
						size="huge"
						semibold
						Icon={DurationIcon}
						content={t(`common.durations.${duration}`, { context: duration !== -1 ? "lower" : undefined })}
					/>
				) : (
					<IconPrefix size="medium" Icon={WarningIcon} content={t(`${TRANSLATION_PREFIX}.empty`)} />
				)}
				<EditButton stepIndex={1} />
			</div>
		</Section>
	);
};
