import classNames from "classnames";
import { Button } from "components/ui/Button";
import { Modal } from "components/ui/Modal";
import { useLoadingState } from "hooks/useLoadingState";
import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useUsers } from "hooks/useUsers";
import { LoadingSpinner } from "components/ui/LoadingSpinner";
import { Select } from "components/ui/Select";
import { UserModel } from "models/UserModel";
import { sortByFullName } from "utils/sortUtils";
import { TitleBody } from "components/ui/TitleBody";
import { UserOption } from "../UserOption";
import { UserWithEmail } from "../UserWithEmail";
import { useStyles } from "./styles";

interface IProps {
	isOpen: boolean;
	value?: UserModel;
	disabledUsers?: string[];
	placeholder?: string;
	title?: string;
	onClose: () => void;
	onSave: (userId: string) => Promise<void>;
}

const getLabel = (user: UserModel) => user.fullName;
const equality = (option: UserModel, value: UserModel) => option.id === value.id;
const renderOption = (option: UserModel) => <UserWithEmail user={option} />;
const renderLabel = (option: UserModel) => (option ? <UserOption option={option} noEmail /> : <></>);

export const ChooseUserModal: FC<IProps> = ({
	className,
	value = null,
	isOpen,
	disabledUsers,
	placeholder,
	title,
	onClose,
	onSave
}) => {
	const users = useUsers(false);
	const [user, setUser] = useState<UserModel | null>(value);
	const { withLoader, isLoading } = useLoadingState();
	const { t } = useTranslation();
	const classes = useStyles();

	const onUserChange = useCallback((selectedUser: UserModel | null) => {
		if (selectedUser) {
			setUser(selectedUser);
		}
	}, []);

	const handleOnClose = useCallback(() => {
		setUser(value);
		onClose();
	}, [onClose, value]);

	const onClickSave = useCallback(async () => {
		if (user && !isLoading) {
			await withLoader(onSave(user.id));
			onClose();
		}
	}, [user, isLoading, onSave, withLoader, onClose]);

	const options = useMemo(() => {
		return (
			users
				?.toList()
				?.toArray()
				?.filter(u => !disabledUsers?.includes(u.id)) || []
		);
	}, [disabledUsers, users]);

	return (
		<Modal
			isOpen={isOpen}
			onClose={handleOnClose}
			className={classNames(classes.modal, className)}
			actions={
				<Button size="medium" onClick={onClickSave} disabled={isLoading} loading={isLoading}>
					{t("buttons.save")}
				</Button>
			}
			content={
				<div className={classes.modalContent}>
					<TitleBody size="large" title={title || t("common.chooseUserModal.title")} />
					{options && options.length > 0 ? (
						<Select
							placeholder={placeholder || t("common.chooseUserModal.placeholder")}
							fullWidth
							getOptionLabel={getLabel}
							isOptionEqualToValue={equality}
							onChange={onUserChange}
							options={options}
							renderLabel={renderLabel}
							limit={8}
							renderOption={renderOption}
							required
							sort={sortByFullName}
							value={user}
						/>
					) : (
						<LoadingSpinner />
					)}
				</div>
			}
		/>
	);
};
