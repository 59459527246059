import React, { useEffect, useMemo } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { Button } from "components/ui/Button";
import { ArrowLeftIcon } from "components/ui/Icons/ArrowLeftIcon";
import { ArrowRightIcon } from "components/ui/Icons/ArrowRightIcon";
import { Typography } from "components/ui/New/Typography";
import { useStyles } from "./styles";
import { StepperProvider, TStepperContextProps, useStepperContext } from "./stepperContext";
import type { Require } from "utils/types";

const Stepper: FC = ({ className }) => {
	const { t } = useTranslation();
	const {
		state: { steps, canContinue, activeStepIndex, footerActions, stepperContainerRef, footerActionsLabels },
		actions: { prevStep, nextStep, onComplete }
	} = useStepperContext();
	const classes = useStyles();
	const { back, complete, next } = footerActionsLabels || {};

	const activeStep = useMemo(() => {
		return steps.find(step => step.index === activeStepIndex)?.step || null;
	}, [activeStepIndex, steps]);

	const backAction = useMemo(() => {
		if (activeStepIndex === 0) return null;
		if (typeof footerActions?.back === "undefined")
			return (
				<Button variant="secondary" size="large" prefix={<ArrowLeftIcon />} onClick={prevStep}>
					{back || t("buttons.back")}
				</Button>
			);
		return footerActions.back;
	}, [activeStepIndex, footerActions.back, back, prevStep, t]);

	const completeAction = useMemo(() => {
		if (typeof footerActions?.complete === "undefined")
			return (
				<Button variant="primary" size="large" suffix={<ArrowRightIcon />} onClick={onComplete} disabled={!canContinue}>
					{complete || t("buttons.done")}
				</Button>
			);
		return footerActions.complete;
	}, [canContinue, footerActions.complete, complete, onComplete, t]);

	const nextAction = useMemo(() => {
		if (typeof footerActions?.next === "undefined")
			return (
				<Button variant="primary" size="large" suffix={<ArrowRightIcon />} onClick={nextStep} disabled={!canContinue}>
					{next || t("buttons.next")}
				</Button>
			);
		return footerActions.next;
	}, [canContinue, footerActions.next, next, nextStep, t]);

	const isFinalStep = activeStepIndex === steps.length - 1;

	useEffect(() => {
		if (stepperContainerRef.current) {
			stepperContainerRef.current.scrollTo({ top: 0, behavior: "instant" });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps -- we want to scroll every time activeStepIndex changes
	}, [activeStepIndex]);

	if (steps.length === 0) return null;
	return (
		<>
			<div className={classNames(classes.container, className)} ref={stepperContainerRef}>
				<div className={classes.steps}>{activeStep}</div>
			</div>
			<div className={classes.footer}>
				<div className={classes.footerLeft} />
				<div className={classes.footerCenter}>
					<Typography variant="title_med">
						{t("common.stepper.activeStep", { active: activeStepIndex + 1, total: steps.length })}
					</Typography>
				</div>
				<div className={classes.footerRight}>
					{backAction}
					{isFinalStep ? completeAction : nextAction}
				</div>
			</div>
		</>
	);
};

const StepperWithContext: FC<TProps & Require<TStepperContextProps, "steps">> = props => {
	const { steps, activeStepIndex, canContinue, ...stepperProps } = props;
	return (
		<StepperProvider steps={steps} key={steps.length} activeStepIndex={activeStepIndex} canContinue={canContinue}>
			<Stepper {...stepperProps} />
		</StepperProvider>
	);
};

export { StepperWithContext as Stepper };
