import { useCallback, useMemo, useState } from "react";

export default function useIsOpenState(defaultState = false): TIsOpenState {
	const [isOpen, setIsOpenState] = useState(defaultState);

	const open = useCallback(() => {
		setIsOpenState(true);
	}, []);

	const close = useCallback(() => {
		setIsOpenState(false);
	}, []);

	const setIsOpen = useCallback((isOpen: boolean) => {
		setIsOpenState(isOpen);
	}, []);

	return useMemo(
		() => ({
			open,
			close,
			setIsOpen,
			isOpen
		}),
		[close, isOpen, open, setIsOpen]
	);
}

export type TIsOpenState = {
	open(): void;
	close(): void;
	isOpen: boolean;
	setIsOpen(isOpen: boolean): void;
};
