import { createUseStyles } from "react-jss";
import { BP_MEDIA_QUERIES } from "utils/breakpoints";

export const useStyles = createUseStyles({
	range: {
		display: "flex",
		flexWrap: "wrap",
		flexDirection: "row",
		gap: "1rem",
		[BP_MEDIA_QUERIES.FOURTH]: {
			flexWrap: "noWrap"
		}
	},
	select: {
		flexGrow: 1
	}
});
