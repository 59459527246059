import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	operatorBlock: {
		alignSelf: "center",
		borderRadius: "var(--border-radius-semi-rounded)",
		cursor: "default",
		display: "flex",
		height: "fit-content",
		justifyContent: "center",
		padding: "0.27rem 0.57rem"
	}
});
