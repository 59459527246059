import React, { useCallback } from "react";
import classNames from "classnames";
import { CloseIcon } from "../Icons/CloseIcon";
import { TSize } from "../MultipleSelect";
import { useStyles } from "./styles";

interface IBaseChipProps {
	noBorder?: boolean;
	onClick?: (event: React.MouseEvent) => void;
	prefixIcon?: JSX.Element;
	size?: TSize;
	visualOnly?: boolean;
	multiLine?: boolean;
	stretch?: boolean;
	contentClassName?: string;
}
interface IRemovableChipProps extends IBaseChipProps {
	onDelete?: () => void;
	suffixIcon?: never;
}
interface IChipProps extends IBaseChipProps {
	onDelete?: never;
	suffixIcon?: JSX.Element;
}

type TChipProps = IRemovableChipProps | IChipProps;

export const Chip: FC<TChipProps> = ({
	children,
	className,
	contentClassName,
	innerRef,
	noBorder = false,
	stretch = false,
	onClick: propsOnClick,
	onDelete,
	prefixIcon = null,
	size = "standard",
	suffixIcon = null,
	visualOnly = false,
	multiLine = false
}) => {
	const classes = useStyles();
	const removeIcon = onDelete ? <CloseIcon size="small" className={classes.removeIcon} onClick={onDelete} /> : null;

	const onClick = useCallback(
		(event: React.MouseEvent) => {
			event.stopPropagation();
			propsOnClick && propsOnClick(event);
		},
		[propsOnClick]
	);

	return (
		<div
			className={classNames(classes.chip, size, className, {
				prefix: !!prefixIcon,
				suffix: !!suffixIcon || !!removeIcon,
				visual: visualOnly,
				clickable: !!propsOnClick,
				stretch,
				noBorder
			})}
			onClick={onClick}
			ref={innerRef as React.Ref<HTMLDivElement>}>
			{prefixIcon}
			<div className={classNames(classes.content, { [classes.multiLine]: multiLine }, contentClassName)}>
				{React.Children.map(children, child => child)}
			</div>
			{removeIcon || suffixIcon}
		</div>
	);
};

// ---- Usage Examples ----
// Chip
// <Chip>Lorem ipsum</Chip>

// Chip with prefix icon
// <Chip prefixIcon={<EditIcon />}>Lorem ipsum</Chip>

// Chip with suffix icon
// <Chip suffixIcon={<EditIcon />}>Lorem ipsum</Chip>

// Chip with prefix and suffix icon (removable)
// <Chip prefixIcon={<EditIcon />} onDelete={() => alert("deleted!")}>
// 	Lorem ipsum
// </Chip>

// Small Chip
// <Chip size="small">Lorem ipsum</Chip>

// Approval Status Chip
// <ApprovalStatusChip
// 	approvalStatus="approved"
// 	approverName="Lorem ipsum"
// 	approverType="user"
// 	entityIcon={
// 		<Avatar imageUrl="https://starcasm.net/wp-content/uploads/2014/03/Tyler_the_Creator_mugshot-392x490.jpg" />
// 	}
// />

// Directory Group Chip
// <DirectoryGroup value="[okta] group-name" />
