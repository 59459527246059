import React, { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { PageTitleContent } from "components/templates/PageTitleContentTemplate";
import { IconButton } from "components/ui/IconButton";
import { CloseIcon } from "components/ui/Icons/CloseIcon";
import { useStepperContext } from "components/common/Stepper/stepperContext";
import { GrantedIcon } from "components/ui/Icons/GrantedIcon";
import { Button } from "components/ui/Button";
import useIsOpenState from "hooks/useIsOpenState";
import { RequestReasonInputs } from "../RequestReasonInputs";
import { useNewRequestFormContext } from "../../newRequestFormContext";
import { OnBehalfSection } from "./components/OnBehalfSection";
import { RequestTargetsSection } from "./components/RequestTargetsSection";
import { AccessDurationSection } from "./components/AccessDurationSection";
import { MissingActorsModal } from "./components/MissingActorsModal";
import { useHandleNewRequestIntegrationActors, useNewRequestSubmit } from "./summaryStep.hooks";
import { ChooseActorModal } from "./components/ChooseActorModal";
import { CreateRequestModal } from "./components/CreateRequestModal";
import { RemoveAllTargetsModal } from "./components/RemoveAllTargetsModal";

export const SummaryStep: FC<{ onExit: () => void }> = ({ className, innerRef, onExit }) => {
	const { t } = useTranslation("translation", { keyPrefix: "pages.newRequest.summaryStep" });
	const {
		actions: { setFooterAction }
	} = useStepperContext();

	const {
		actorModalsShown,
		chooseActorModal,
		isIntegrationActorsValid,
		missingActorsIntegrations,
		missingActorsModal,
		multipleActorsIntegrations,
		multipleActorsTargets,
		showMissingAndChoiceModals
	} = useHandleNewRequestIntegrationActors();

	const {
		state: { isFormValid }
	} = useNewRequestFormContext();

	const createRequestModal = useIsOpenState();
	const removeAllTargetsModal = useIsOpenState();
	const { onSubmit: apiNewRequestSubmit, error, success } = useNewRequestSubmit();

	const integrationActorsAreValid = useMemo(() => isIntegrationActorsValid(), [isIntegrationActorsValid]);

	const onSubmit = useCallback(() => {
		if (!integrationActorsAreValid && !actorModalsShown) {
			showMissingAndChoiceModals();
			return;
		}
		if (!isFormValid || !integrationActorsAreValid) return;
		createRequestModal.open();
		apiNewRequestSubmit();
	}, [
		actorModalsShown,
		apiNewRequestSubmit,
		createRequestModal,
		isFormValid,
		integrationActorsAreValid,
		showMissingAndChoiceModals
	]);

	const submitButton = useMemo(() => {
		let disabled = !isFormValid;
		let tooltip = undefined;
		if (actorModalsShown) {
			disabled = !(isFormValid && integrationActorsAreValid);
			tooltip = integrationActorsAreValid ? undefined : t("invalidSubmitTooltip");
		}
		return (
			<Button
				variant="primary"
				size="large"
				suffix={<GrantedIcon size={24} />}
				onClick={onSubmit}
				disabled={disabled}
				tooltip={tooltip}>
				{t("submitButtonLabel")}
			</Button>
		);
	}, [actorModalsShown, integrationActorsAreValid, isFormValid, onSubmit, t]);

	useEffect(() => {
		setFooterAction("complete", submitButton);
		return () => setFooterAction("complete", undefined);
	}, [setFooterAction, submitButton]);

	return (
		<PageTitleContent innerRef={innerRef} className={className} noTransition>
			<PageTitleContent.Header>
				<PageTitleContent.Header.Top>
					<PageTitleContent.Header.Actions>
						<IconButton size="large" onClick={onExit}>
							<CloseIcon />
						</IconButton>
					</PageTitleContent.Header.Actions>
				</PageTitleContent.Header.Top>
				<PageTitleContent.Header.Bottom>
					<PageTitleContent.Header.Title title={t("title")} />
				</PageTitleContent.Header.Bottom>
			</PageTitleContent.Header>
			<PageTitleContent.Body>
				<MissingActorsModal
					close={missingActorsModal.close}
					isOpen={missingActorsModal.isOpen}
					missingActorIntegrations={missingActorsIntegrations}
				/>
				<ChooseActorModal
					multipleActorIntegration={
						multipleActorsIntegrations?.find(integration => chooseActorModal.modalToShow === integration.id) || null
					}
					isOpen={chooseActorModal.isOpen}
					close={chooseActorModal.onClose}
				/>
				<CreateRequestModal
					close={createRequestModal.close}
					isOpen={createRequestModal.isOpen}
					retry={onSubmit}
					error={error}
					success={success}
				/>
				<RemoveAllTargetsModal isOpen={removeAllTargetsModal.isOpen} close={removeAllTargetsModal.close} />
				<OnBehalfSection />
				<RequestTargetsSection
					onExit={removeAllTargetsModal.open}
					chooseActor={actorModalsShown ? chooseActorModal.open : undefined}
					multipleActorsTargets={multipleActorsTargets}
				/>
				<AccessDurationSection />
				<RequestReasonInputs />
			</PageTitleContent.Body>
		</PageTitleContent>
	);
};
