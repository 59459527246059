import classNames from "classnames";
import { Tooltip } from "components/ui/Tooltip/Tooltip";
import React from "react";
import { RoleLabel } from "components/common/RoleLabel";
import { useTranslation } from "react-i18next";
import { getTicketPermissionStatusIcon } from "utils/ticketPermissionType";
import { useStyles } from "./styles";
import type { IntegrationResourceRoleModel } from "models/IntegrationResourceRoleModel";
import type { TTicketPermissionStatus } from "models/TicketPermissionModel";
import type { TTicketStatus } from "models/TicketModel";

interface IProps {
	role: IntegrationResourceRoleModel | null;
	status: TTicketPermissionStatus;
	ticketStatus: TTicketStatus;
}

export const TicketPermissionWithStatus: FC<IProps> = ({ status, ticketStatus, role, className }) => {
	const classes = useStyles();
	const { t } = useTranslation();
	const permissionStatus = ticketStatus === "rejected" ? "ticketDeclined" : status;

	const [StatusIcon] = [getTicketPermissionStatusIcon(status, ticketStatus)];

	return (
		<div className={classNames(classes.content, className)}>
			{role && <RoleLabel option={role} showPermissions withImage className={classes.roleLabel} />}
			{StatusIcon && (
				<Tooltip content={t(`common.ticketSidebar.permissionStatus.${permissionStatus}`)}>
					<StatusIcon className={classes.icon} />
				</Tooltip>
			)}
		</div>
	);
};
