import { List } from "immutable";
import { notEmpty } from "./comparison";

export type TQsFilter = { toQueryString: () => string };

export interface IPaginationData {
	perPage: number;
	totalPages: number;
	totalResults: number;
	page: number;
}

interface IPaginationApiResponse {
	pagination: IPaginationData;
	result: Record<string, unknown>[];
}

export interface IPaginationResponse<T> {
	pagination: IPaginationData;
	result: List<T>;
}

type TItemParser<T> = (item: Record<string, unknown>) => T | undefined | null;
type TFetchItems = () => Promise<{ data: Record<string, unknown>[] | IPaginationApiResponse }>;

function isPaginationResponse(
	responseData: Record<string, unknown>[] | IPaginationApiResponse
): responseData is IPaginationApiResponse {
	return !Array.isArray(responseData) && Object.keys(responseData).includes("pagination");
}

export async function withPagination<T>(
	itemParser: TItemParser<T>,
	fetchItems: TFetchItems
): Promise<IPaginationResponse<T>> {
	const { data } = await fetchItems();

	if (isPaginationResponse(data)) {
		return {
			pagination: data.pagination,
			result: List(data.result.map(item => itemParser(item))).filter(notEmpty)
		};
	} else {
		return {
			pagination: {
				perPage: data.length,
				page: 1,
				totalPages: 1,
				totalResults: data.length
			},
			result: List(data.map(item => itemParser(item)).filter(notEmpty))
		};
	}
}
