import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Set, Map } from "immutable";
import { Section } from "components/ui/Section";
import { useNewRequestFormContext } from "components/pages/NewRequestPage/newRequestFormContext";
import { Typography } from "components/ui/New/Typography";
import { Chip } from "components/ui/New/Chip";
import {
	useNewRequestBundles,
	useNewRequestIntegrationResourceRoles,
	useNewRequestIntegrationResources,
	useNewRequestIntegrations
} from "components/pages/NewRequestPage/newRequestDataContext";
import { LoadingDots } from "components/ui/LoadingDots";
import { ResourceSelection } from "components/common/ResourceSelection";
import { BundleCard } from "components/common/Cards/BundleCard";
import { IntegrationCard } from "components/common/Cards/IntegrationCard";
import classNames from "classnames";
import { TRequestTarget } from "components/pages/NewRequestPage/types";
import { sortByName } from "utils/sortUtils";
import { Require } from "utils/types";
import { IconButton } from "components/ui/IconButton";
import { ExpandIcon } from "components/ui/Icons/ExpandIcon";
import { useBreakpoints } from "hooks/useBreakpoints";
import { useStepperContext } from "components/common/Stepper/stepperContext";
import { LoadingSpinner } from "components/ui/LoadingSpinner";
import { useInfiniteScroll } from "hooks/useInfiniteScroll";
import { BundleModel } from "models/BundleModel";
import { useOnMount } from "hooks/useOnMount";
import { BREAKPOINT_TO_PER_ROW, useStyles } from "./styles";
import type { IntegrationResourceRoleModel } from "models/IntegrationResourceRoleModel";
import type { IntegrationModel } from "models/IntegrationModel";

const TRANSLATION_KEY_PREFIX = "pages.newRequest.selectRolesStep.availableTargetsSection";

const NoTargetsState: FC<{ loading?: boolean }> = ({ loading = false }) => {
	const { t } = useTranslation("translation", { keyPrefix: TRANSLATION_KEY_PREFIX });
	const classes = useStyles();

	return (
		<div className={classes.emptyState}>
			{loading ? <LoadingDots center /> : <Typography variant="text_sm_reg">{t("noApps")}</Typography>}
		</div>
	);
};

const BundleTargetCard: FC<{
	bundle: Require<BundleModel, "bundleItems">;
	selected: boolean;
	toggleSelection: () => void;
	order?: number;
}> = ({ bundle, selected, toggleSelection, order }) => {
	const classes = useStyles({ order });

	const uniqueResources = useMemo(() => {
		return bundle.bundleItems.reduce((acc, bundleItem) => {
			return acc.add(bundleItem.integrationResourceRole.integrationResourceId);
		}, Set<string>());
	}, [bundle.bundleItems]);

	return (
		<BundleCard
			size="large"
			bundle={bundle}
			selected={selected}
			className={classes.gridItem}
			onClick={toggleSelection}
			resourcesCount={uniqueResources.size}
			rolesCount={bundle.bundleItems.size}
		/>
	);
};

const MemoizedBundleTargetCard = React.memo(BundleTargetCard);

const ExpandedIntegrationCard: FC<{ integration: IntegrationModel; toggleIsOpen: () => void }> = ({
	integration,
	toggleIsOpen
}) => {
	const {
		state: { receiverUser, requestTargets },
		actions: { addTarget, removeTarget }
	} = useNewRequestFormContext();
	const { data: resources, fetch: fetchResources } = useNewRequestIntegrationResources();
	const { data: roles, fetch: fetchRoles } = useNewRequestIntegrationResourceRoles();
	const cardRef = useRef<HTMLDivElement>(null);
	const receiverUserId = receiverUser?.id;

	const selectedRoleIds = useMemo(() => {
		return requestTargets.reduce((acc, target) => {
			if (target.type === "role" && target.integrationId === integration.id) {
				let currentRoles = acc.get(target.resourceId) || Set<string>();
				currentRoles = currentRoles.add(target.id);
				return acc.set(target.resourceId, currentRoles);
			}
			return acc;
		}, Map<string, Set<string>>());
	}, [integration.id, requestTargets]);

	useEffect(() => {
		if (!receiverUserId) return;
		fetchResources({ integrationId: integration.id, userId: receiverUserId });
	}, [fetchResources, integration.id, receiverUserId]);

	const asRoleOptions = useMemo(() => {
		return roles.reduce((acc, rolesData, resourceId) => {
			return acc.set(resourceId, rolesData.integrationResourceRoles.valueSeq().toArray());
		}, Map<string, IntegrationResourceRoleModel[]>());
	}, [roles]);

	const resourcesSearch = useCallback(
		async (search: string) => {
			if (!receiverUserId) return;
			await fetchResources({ integrationId: integration.id, userId: receiverUserId, search });
		},
		[fetchResources, integration.id, receiverUserId]
	);

	const rolesSearch = useCallback(
		async (resourceId: string, search?: string) => {
			if (!receiverUserId) return;
			await fetchRoles({ userId: receiverUserId, integrationResourceId: resourceId, search });
		},
		[fetchRoles, receiverUserId]
	);

	const toggleTarget = useCallback(
		(resourceId: string, roleId: string) => {
			const fullTarget = roles.get(resourceId)?.integrationResourceRoles.get(roleId) as
				| Require<IntegrationResourceRoleModel, "integrationResource">
				| undefined;
			if (!fullTarget) return;
			const target: TRequestTarget = {
				id: roleId,
				integrationId: integration.id,
				resourceId,
				type: "role",
				grantMethodId: null,
				fullTarget
			};
			if (requestTargets.some(currentTarget => currentTarget.id === target.id)) {
				removeTarget(target.id);
			} else {
				addTarget(target);
			}
		},
		[addTarget, integration.id, removeTarget, requestTargets, roles]
	);

	const integrationResources = useMemo(() => {
		return resources.get(integration.id)?.integrationResources.valueSeq().toArray() || [];
	}, [integration.id, resources]);

	useOnMount(() => {
		cardRef.current?.scrollIntoView({ behavior: "smooth", block: "start" });
	});

	if (!asRoleOptions) return null;
	return (
		<ResourceSelection
			innerRef={cardRef}
			integration={integration}
			onCollapse={toggleIsOpen}
			onIntegrationResourceSearch={resourcesSearch}
			onResourceRoleSearch={rolesSearch}
			onSelectRole={toggleTarget}
			resources={integrationResources}
			roleOptions={asRoleOptions}
			selectedRoleIds={selectedRoleIds}
		/>
	);
};

const IntegrationTargetCard: FC<{
	integration: IntegrationModel;
	isOpen: boolean;
	isInRowAlone: boolean;
	order?: number;
	selected: boolean;
	selectedResources?: number;
	selectedRoles?: number;
	toggleIsOpen: () => void;
}> = ({ integration, selected, isOpen, toggleIsOpen, order, selectedResources, selectedRoles, isInRowAlone }) => {
	const classes = useStyles({ order: isInRowAlone && isOpen ? (order || 0) - 1 : order });
	const resourcesCount = useMemo(() => {
		if (selectedResources && integration.resourcesCount) {
			return `${selectedResources}/${integration.resourcesCount}`;
		}
		return integration.resourcesCount || 0;
	}, [integration.resourcesCount, selectedResources]);

	const integrationCard = useMemo(
		() => (
			<IntegrationCard
				integration={integration}
				onClick={toggleIsOpen}
				resourcesCount={resourcesCount}
				rolesCount={selectedRoles}
				selected={selected}
				size="large"
				topActions={
					<IconButton size="medium" onClick={toggleIsOpen}>
						<ExpandIcon />
					</IconButton>
				}
			/>
		),
		[integration, resourcesCount, selected, selectedRoles, toggleIsOpen]
	);

	const expandedIntegrationCard = useMemo(
		() => <ExpandedIntegrationCard integration={integration} toggleIsOpen={toggleIsOpen} />,
		[integration, toggleIsOpen]
	);

	return (
		<>
			<div
				className={classNames(classes.gridItem, {
					[classes.ghostGridItem]: isOpen,
					[classes.notDisplayRow]: isInRowAlone && isOpen
				})}>
				{integrationCard}
			</div>
			{isOpen ? (
				<div className={classNames(classes.gridItem, classes.expandedGridItem)}>{expandedIntegrationCard}</div>
			) : null}
		</>
	);
};

export const AvailableTargetsSection: FC = ({ className, innerRef }) => {
	const { t } = useTranslation("translation", { keyPrefix: TRANSLATION_KEY_PREFIX });
	const {
		state: { receiverUser, requestTargets },
		actions: { addTarget, removeTarget }
	} = useNewRequestFormContext();
	const {
		allData: allIntegrationsData,
		fetch: fetchIntegrations,
		loadingState: integrationsLoadingState
	} = useNewRequestIntegrations();
	const { allData: allBundlesData, fetch: fetchBundles, loadingState: bundlesLoadingState } = useNewRequestBundles();
	const [openIntegrationId, setOpenIntegrationId] = useState<string | null>(null);
	const {
		state: { stepperContainerRef }
	} = useStepperContext();
	const lastFetchedPage = useRef<number>(1);
	const classes = useStyles();
	const { currentBp } = useBreakpoints();

	const totalAmount = useMemo(() => {
		return allIntegrationsData.totalAmount + allBundlesData.totalAmount;
	}, [allBundlesData.totalAmount, allIntegrationsData.totalAmount]);

	const isLoading = useMemo(() => {
		return integrationsLoadingState === "Loading" || bundlesLoadingState === "Loading";
	}, [bundlesLoadingState, integrationsLoadingState]);

	const title = useMemo(() => {
		return (
			<>
				<Typography variant="body_sb">{t("title")}</Typography>
				<Chip size="small">{totalAmount}</Chip>
			</>
		);
	}, [totalAmount, t]);

	useEffect(() => {
		if (!receiverUser) return;
		fetchIntegrations({ userId: receiverUser.id });
		fetchBundles({ userId: receiverUser.id });
	}, [fetchBundles, fetchIntegrations, receiverUser]);

	const emptyState = useMemo(() => {
		if (totalAmount === 0) <NoTargetsState />;
		return null;
	}, [totalAmount]);

	const getSelectedResourcesAndRoles = useCallback(
		(id: string, type: "role" | "bundle") => {
			const { isSelected, selectedResources, selectedRoles } = requestTargets.reduce(
				(acc, target) => {
					const isSelected = type === "role" && target.type === "role" ? target.integrationId === id : target.id === id;
					acc.isSelected = acc.isSelected || isSelected;
					if (isSelected && target.type === "role") {
						acc.selectedResources = acc.selectedResources.add(target.resourceId);
						acc.selectedRoles = acc.selectedRoles.add(target.id);
					}
					return acc;
				},
				{
					isSelected: false,
					selectedResources: Set<string>(),
					selectedRoles: Set<string>()
				}
			);
			if (!isSelected || type === "bundle") return { isSelected };
			return { isSelected, selectedResources: selectedResources.size, selectedRoles: selectedRoles.size };
		},
		[requestTargets]
	);

	const toggleBundleTarget = useCallback(
		(bundleId: string) => {
			return () => {
				const target: TRequestTarget = {
					id: bundleId,
					type: "bundle",
					fullTarget: allBundlesData.bundles.get(bundleId)!
				};
				if (requestTargets.some(currentTarget => currentTarget.id === target.id)) {
					removeTarget(target.id);
				} else {
					addTarget(target);
				}
			};
		},
		[addTarget, allBundlesData.bundles, removeTarget, requestTargets]
	);

	const sortedAvailableTargets = useMemo(() => {
		const bundles = allBundlesData.bundles.valueSeq().toArray();
		const integrations = allIntegrationsData.integrations.valueSeq().toArray();
		const unitedTargets = ([] as (Require<BundleModel, "bundleItems"> | IntegrationModel)[])
			.concat(bundles)
			.concat(integrations);
		return sortByName(unitedTargets);
	}, [allBundlesData.bundles, allIntegrationsData.integrations]);

	const cards = useMemo(() => {
		const perRow = BREAKPOINT_TO_PER_ROW.get(currentBp.valueOf());
		const hasOneInRow = sortedAvailableTargets.length % (perRow || 0) === 1;
		return sortedAvailableTargets.map((availableTarget, index) => {
			const order = perRow ? Math.floor(index / perRow) : 0;
			const isInRowAlone = hasOneInRow && index === sortedAvailableTargets.length - 1;
			const { isSelected, selectedResources, selectedRoles } = getSelectedResourcesAndRoles(
				availableTarget.id,
				availableTarget instanceof BundleModel ? "bundle" : "role"
			);
			if ("bundleItems" in availableTarget) {
				const toggleBundle = toggleBundleTarget(availableTarget.id);
				return (
					<MemoizedBundleTargetCard
						bundle={availableTarget}
						key={availableTarget.id}
						order={order}
						selected={isSelected}
						toggleSelection={toggleBundle}
					/>
				);
			}
			const toggleOpenIntegration = () => {
				setOpenIntegrationId(openIntegrationId === availableTarget.id ? null : availableTarget.id);
			};
			return (
				<IntegrationTargetCard
					integration={availableTarget}
					isOpen={openIntegrationId === availableTarget.id}
					key={availableTarget.id}
					order={order}
					isInRowAlone={isInRowAlone}
					selected={isSelected}
					selectedResources={selectedResources}
					selectedRoles={selectedRoles}
					toggleIsOpen={toggleOpenIntegration}
				/>
			);
		});
	}, [sortedAvailableTargets, currentBp, getSelectedResourcesAndRoles, openIntegrationId, toggleBundleTarget]);

	const fetchNextPage = useCallback(async () => {
		if (!receiverUser) return;
		const nextPage = lastFetchedPage.current + 1;
		const options = { userId: receiverUser.id, page: nextPage };
		await Promise.all([fetchIntegrations(options), fetchBundles(options)]);
		lastFetchedPage.current = nextPage;
	}, [fetchBundles, fetchIntegrations, receiverUser]);

	useInfiniteScroll({
		scrollableRef: stepperContainerRef,
		canFetch: sortedAvailableTargets.length < totalAmount,
		fetch: fetchNextPage
	});

	return (
		<Section className={className} innerRef={innerRef} title={title} noDivider>
			<div className={classes.gridContainer}>{emptyState ?? cards}</div>
			{!emptyState && isLoading ? <LoadingSpinner /> : null}
		</Section>
	);
};
