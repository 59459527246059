import { List } from "components/ui/List";
import { LoadingDots } from "components/ui/LoadingDots";
import { useApprovalAlgorithms } from "hooks/useApprovalAlgorithms";
import { useIntegrationResource } from "hooks/useIntegrationResource";
import { useIntegrationResourceRole } from "hooks/useIntegrationResourceRole";
import { useIntegrations } from "hooks/useIntegrations";
import { useUsers } from "hooks/useUsers";
import { boldComponent } from "i18n";
import { IntegrationResourceRoleAuditLogModel } from "models/auditLogs";
import { IIntegrationResourceRoleAuditLogDiffData } from "models/auditLogs/IntegrationResourceRoleAuditLogModel";
import { IntegrationResourceRoleModel } from "models/IntegrationResourceRoleModel";
import React, { useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";
import { getAuditLogDataValues, TDiffObject } from "utils/auditLogUtils";
import { AuditLogUser } from "../AuditLogUser";
import { useStyles } from "./styles";
import type { TTicketDuration } from "utils/durationsOptions";
import type { TAuditLogContentComponent } from "./AuditLogContent.types";

const TRANSLATION_PREFIX = "pages.auditLog.auditLogList.integrationResourceRole";

type TTranslationTypes =
	| "RoleCreatedManually"
	| "RoleUpdatedAllowAsGrantMethod.allow"
	| "RoleUpdatedAllowAsGrantMethod.prevent"
	| "RoleUpdatedAllowRequests.allow"
	| "RoleUpdatedAllowRequests.prevent"
	| "RoleUpdatedName.same"
	| "RoleUpdatedName.different"
	| "RoleUpdatedWorkflow.changed"
	| "RoleUpdatedWorkflow.added"
	| "RoleUpdatedWorkflow.removed"
	| "RoleDeletedManually"
	| "RoleUpdatedOverrideAllowedDurations.disabled"
	| "RoleUpdatedOverrideAllowedDurations.enabled"
	| "RoleUpdatedOverrideAllowedDurations.changed";

const getEventTranslationPath = (
	auditLog: IntegrationResourceRoleAuditLogModel,
	integrationResourceRole: IntegrationResourceRoleModel | null
): TTranslationTypes[] => {
	const action = auditLog.action;
	if (action === "RoleUpdatedName") {
		if (!auditLog.data?.name) return [];
		const { to } = auditLog.data.name as { to?: string };
		return [`RoleUpdatedName.${integrationResourceRole?.name === to && to ? "same" : "different"}` as const];
	}
	if (action === "RoleUpdatedWorkflow") {
		if (!auditLog.data?.approvalAlgorithmId) return [];
		const { from, to } = auditLog.data.approvalAlgorithmId as TDiffObject<string>;
		if (from) return [`RoleUpdatedWorkflow.${to ? "changed" : "removed"}` as const];
		return ["RoleUpdatedWorkflow.added" as const];
	}
	if (action === "RoleUpdatedAllowAsGrantMethod") {
		if (!auditLog.data?.allowAsGrantMethod) return [];
		const { to } = auditLog.data.allowAsGrantMethod as TDiffObject<boolean>;
		return [`RoleUpdatedAllowAsGrantMethod.${to ? "allow" : "prevent"}` as const];
	}
	if (action === "RoleUpdatedAllowRequests") {
		if (!auditLog.data?.allowsRequests) return [];
		const { to } = auditLog.data.allowsRequests as TDiffObject<boolean>;
		return [`RoleUpdatedAllowRequests.${to ? "allow" : "prevent"}` as const];
	}
	if (action === "RoleUpdatedOverrideAllowedDurations") {
		if (!auditLog.data) return [];
		const { to, from } = auditLog.data.allowedDurations as { to?: number[] | null; from?: number[] | null };
		if (!to?.length) return ["RoleUpdatedOverrideAllowedDurations.disabled" as const];
		if (!from?.length) return ["RoleUpdatedOverrideAllowedDurations.enabled" as const];
		return ["RoleUpdatedOverrideAllowedDurations.changed" as const];
	}
	return [action];
};

const Content: TAuditLogContentComponent<IntegrationResourceRoleAuditLogModel> = ({ auditLog, logUser }) => {
	const { t } = useTranslation();
	const classes = useStyles();
	const { role } = useIntegrationResourceRole(auditLog.integrationResourceRoleId, true);
	const integrations = useIntegrations(true);
	const approvalAlgorithms = useApprovalAlgorithms();
	const { resource } = useIntegrationResource(auditLog.integrationResourceRole!.integrationResourceId, false, true);
	const users = useUsers();

	const integration = useMemo(
		() => (integrations && auditLog.integrationId ? integrations.get(auditLog.integrationId) : null),
		[auditLog.integrationId, integrations]
	);

	const values = useMemo(() => {
		if (!role) return {};
		const data = auditLog.data || ({} as IIntegrationResourceRoleAuditLogDiffData);

		return getAuditLogDataValues(data, [
			{
				field: "name",
				valueField: "roleName",
				valueConvertor: (value: unknown) => value as string | undefined,
				currentValue: role.name
			},
			{
				field: "approvalAlgorithmId",
				valueField: "approvalAlgorithmName",
				valueConvertor: (value: unknown) => approvalAlgorithms?.get(value as string)?.name || ""
			},
			{
				field: "allowedDurations",
				valueField: "allowedDurations",
				valueConvertor: (value: unknown) => {
					if (!value) return;
					const parsedDurations = [] as string[];
					for (const duration of value as number[]) {
						parsedDurations.push(t(`common.durations.${duration as TTicketDuration}`));
					}
					return parsedDurations.join(", ");
				}
			}
		]);
	}, [approvalAlgorithms, auditLog.data, role, t]);

	const props = useMemo(
		() => ({
			values: {
				...values,
				integrationName: integration?.name,
				resourceName: resource?.displayName
			},
			components: {
				bold: boldComponent,
				user: <AuditLogUser user={logUser} className={classes.userText} />
			}
		}),
		[classes.userText, integration?.name, logUser, resource?.displayName, values]
	);

	const [title, ...details] = useMemo(() => {
		if (!role) return [];
		const paths = getEventTranslationPath(auditLog, role);
		return paths.map(key => `${TRANSLATION_PREFIX}.${key}` as const);
	}, [auditLog, role]);

	return users && role ? (
		<>
			<Trans t={t} {...props} i18nKey={title} />
			{details && (
				<List
					items={details.map(key => (
						<Trans t={t} key={key} i18nKey={key} {...props} />
					))}
				/>
			)}
		</>
	) : (
		<LoadingDots />
	);
};

export const IntegrationResourceRoleAuditLogContent = React.memo(Content);
