import { useCallback, useMemo, useState } from "react";
import uniq from "lodash/uniq";
import { ASC, TSortOrder } from "hooks/usePagination";

export type TSortState = {
	sortOrder: TSortOrder;
	sortFields: string[];
	setSortState: (field: string, order?: TSortOrder) => void;
	isDirty: boolean;
};

export const useSortState = ({
	defaultSortField,
	defaultSortOrder,
	secondaryField
}: {
	defaultSortField: string;
	defaultSortOrder: TSortOrder;
	secondaryField?: string;
}): TSortState => {
	const [sort, setSort] = useState<{ order: TSortOrder; field: string }>({
		order: defaultSortOrder,
		field: defaultSortField
	});
	const [isDirty, setIsDirty] = useState(false);

	const setSortState = useCallback((field: string, order?: TSortOrder) => {
		setSort({ field, order: order ?? ASC });
		setIsDirty(true);
	}, []);
	const sortFields = useMemo(
		() => (secondaryField ? uniq([sort.field, secondaryField]) : [sort.field]),
		[sort.field, secondaryField]
	);
	return { sortOrder: sort.order, sortFields, setSortState, isDirty };
};
