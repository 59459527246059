import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	chip: {
		"&.standard": {
			lineHeight: "1.5em",
			padding: "0.43rem 0.86rem",
			"&.prefix": {
				gap: "0.57rem",
				paddingLeft: "0.57rem"
			},
			"&.suffix": {
				gap: "0.57rem",
				paddingRight: "0.57rem"
			}
		},
		"&.small": {
			lineHeight: "1.4em",
			padding: "0.25rem 0.43rem",
			"&.prefix": {
				gap: "0.285rem",
				paddingLeft: "0.285rem"
			},
			"&.suffix": {
				gap: "0.285rem",
				paddingRight: "0.285rem"
			}
		},
		"&.tiny": {
			lineHeight: "1.4em",
			padding: "0.1rem 0.3rem",
			"&.prefix": {
				gap: "0.2rem",
				paddingLeft: "0.2rem"
			},
			"&.suffix": {
				gap: "0.2rem",
				paddingRight: "0.2rem"
			}
		},
		"&.noBorder": {
			border: "none"
		},
		"&.prefix": {
			display: "grid",
			gridTemplateColumns: "auto 1fr"
		},
		"&.prefix.suffix": {
			display: "grid",
			gridTemplateColumns: "auto 1fr auto"
		},
		"&.suffix": {
			display: "grid",
			gridTemplateColumns: "1fr auto"
		},
		"&.stretch": {
			display: "flex",
			justifyContent: "space-between",
			width: "100%"
		},
		"&.visual": {
			"&:hover": {
				backgroundColor: "transparent"
			},
			backgroundColor: "transparent",
			border: "none"
		},
		"&:hover": {
			"&.clickable": {
				cursor: "pointer"
			},
			backgroundColor: "var(--color-grey-background)"
		},
		alignItems: "center",
		backgroundColor: "var(--color-white)",
		border: "1px solid var(--color-grey-light)",
		borderRadius: "var(--border-radius-semi-rounded)",
		boxSizing: "border-box",
		cursor: "default",
		display: "inline-flex",
		flexDirection: "row",
		justifyContent: "center",
		minWidth: "fit-content",
		overflow: "hidden",
		whiteSpace: "normal",
		width: "fit-content"
	},
	content: {
		alignItems: "center",
		display: "flex",
		flexDirection: "row"
	},
	multiLine: {
		flexWrap: "wrap",
		overflow: "hidden",
		whiteSpace: "normal",
		wordBreak: "break-word"
	},
	removeIcon: {
		"&:hover": {
			backgroundColor: "var(--color-grey-light)"
		},
		backgroundColor: "transparent",
		borderRadius: "var(--border-radius-round)",
		color: "var(--color-grey-dark)",
		transition: "background-color 0.2s",
		willChange: "background-color"
	}
});
