import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Typography } from "components/ui/Typography";
import { TicketModel } from "models/TicketModel";
import { useTicketApprovers } from "hooks/useTicketApprovers";
import classNames from "classnames";
import { CloseCircleIcon } from "components/ui/Icons/CloseCircleIcon";
import { DoneCircleIcon } from "components/ui/Icons/DoneCircleIcon";
import { TicketApprovalRequestModel } from "models/TicketApprovalRequestModel";
import { ApprovalFlowStep } from "./ApprovalFlowStep";
import { useStyles } from "./styles";

export type TDirection = "vertical" | "horizontal";

interface IProps {
	ticket: TicketModel;
	withStatus: boolean;
	adminAction?: boolean;
	direction?: TDirection;
	noBackground?: boolean;
	noBorder?: boolean;
	noModal?: boolean;
}

const TRANSLATION_PREFIX = "common.approvalFlowSimulation";
type TStatusType = "approved" | "adminApproved" | "declined" | "adminDeclined";

export const ApprovalFlowSimulation: FC<IProps> = ({
	ticket,
	withStatus,
	direction = "vertical",
	noBorder = false,
	noBackground = false,
	adminAction = false,
	noModal
}) => {
	const classes = useStyles();
	const { t } = useTranslation();

	const { getApprovalStatus, getRespondersInGroup } = useTicketApprovers(ticket);
	const workflow = useMemo(
		() =>
			ticket.approvalRequests
				?.sortBy(request => request.sortOrder)
				.reduce((result, request) => {
					if (!result.at(request.sortOrder - 1)) {
						result[request.sortOrder - 1] = [];
					}
					result[request.sortOrder - 1].push(request);
					return result;
				}, [] as TicketApprovalRequestModel[][]) || null,
		[ticket.approvalRequests]
	);
	const vertical = direction === "vertical";

	const [StatusIcon, status] = useMemo(() => {
		let status: TStatusType = "approved";

		const approvalRequestStatuses = workflow?.flatMap(step => step.map(request => request.status));

		if (approvalRequestStatuses) {
			if (approvalRequestStatuses.find(step => step === "declined")) {
				status = adminAction ? "adminDeclined" : "declined";
				return [CloseCircleIcon, status];
			}

			if (adminAction || approvalRequestStatuses.every(step => step === "approved")) {
				status = adminAction ? "adminApproved" : "approved";
				return [DoneCircleIcon, status];
			}
		}
		return [DoneCircleIcon, status];
	}, [adminAction, workflow]);

	if (!workflow) return null;

	return (
		<div
			className={classNames(classes.container, vertical ? classes.vertical : classes.horizontal, {
				[classes.noBorder]: noBorder,
				[classes.noBackground]: noBackground
			})}>
			<Typography className={classes.title}>{t("common.approvalFlowSimulation.approvalFlow")}</Typography>
			<div className={classNames(classes.approvalFlowContainer, vertical ? classes.vertical : classes.horizontal)}>
				{workflow.map((step, sortOrder) => (
					<ApprovalFlowStep
						key={sortOrder}
						stepRequests={step}
						withStatus={withStatus}
						getApprovalStatus={getApprovalStatus}
						getRespondersInGroup={getRespondersInGroup}
						direction={direction}
						noModal={noModal}
					/>
				))}
				<div className={classes.approvedContainer}>
					<div className={classes.approved}>
						<StatusIcon />
						<Typography variant="small" className={classes.approvedText}>
							{t(`${TRANSLATION_PREFIX}.${status}`)}
						</Typography>
					</div>
				</div>
			</div>
		</div>
	);
};
