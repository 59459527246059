import React, { useCallback, useMemo } from "react";
import { TicketModel } from "models/TicketModel";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { useRedirectModalContext } from "context/redirectModalContext";
import { ExtraOptionsButton, TExtraOptions, TRequestAction, TSelectClickableOption } from "../ExtraOptionsButton";
import { RedirectTicketModal } from "../RedirectTicketModal";
import { useStyles } from "./styles";
import type { Placement } from "@popperjs/core";

interface IProps {
	ticket: TicketModel;
	disabled?: boolean;
	position?: Placement;
	optionClassName?: string;
	size?: "small" | "medium" | "large";
}

const TRANSLATION_PREFIX = "common.extraOptions";

// in order the redirect will work you need to have a redirectModal rendered in that page
export const TicketExtraOptionsButton: FC<IProps> = ({
	ticket,
	className,
	size = "small",
	disabled = false,
	optionClassName,
	position
}) => {
	const { t } = useTranslation();
	const classes = useStyles();
	const navigate = useNavigate();
	const {
		actions: { open, setTicket }
	} = useRedirectModalContext();

	const openRedirectModal = useCallback(() => {
		setTicket(ticket);
		open();
	}, [ticket, open, setTicket]);

	const reopenTicket = useCallback(() => {
		navigate(`/request?originalTicket=${ticket.id}`);
	}, [navigate, ticket]);

	const extraOptions = useMemo<TExtraOptions<TicketModel>>(() => {
		const options = new Map<TRequestAction, TSelectClickableOption<TicketModel>>();
		options.set("redirect", {
			value: "redirect",
			label: t(`${TRANSLATION_PREFIX}.redirect`),
			onClick: openRedirectModal
		});

		options.set("reopen", {
			value: "reopen",
			label: t(`${TRANSLATION_PREFIX}.reopen`),
			onClick: reopenTicket
		});

		return options;
	}, [t, openRedirectModal, reopenTicket]);

	return (
		<>
			<RedirectTicketModal />
			<ExtraOptionsButton
				item={ticket}
				position={position}
				extraOptions={extraOptions}
				disabled={disabled}
				size={size}
				optionClassName={optionClassName}
				className={classNames(className, classes.extraOptionsButton)}
			/>
		</>
	);
};
