import React, { useCallback, useMemo, useState } from "react";
import { List } from "immutable";
import { Typography } from "components/ui/Typography";
import { useTranslation } from "react-i18next";
import { CommonAccordion } from "components/common/CommonAccordion";
import { IntegrationMaintainerModel } from "models/IntegrationMaintainerModel";
import { IntegrationResourceMaintainerModel } from "models/IntegrationResourceMaintainerModel";
import { useUsers } from "hooks/useUsers";
import { useDirectoryGroups } from "hooks/useDirectoryGroups";
import { LoadingDots } from "components/ui/LoadingDots";
import { Table } from "components/ui/Table";
import { IntegrationModel } from "models/IntegrationModel";
import { Button } from "components/ui/Button";
import { AddIcon } from "components/ui/Icons/AddIcon";
import { useOpenGlobalErrorModal } from "hooks/useGlobalError";
import { HelpBlock } from "components/ui/Block";
import { MaintainersIcon } from "components/ui/Icons/MaintainersIcon";
import { Maintainer, TMaintainer } from "./components/Maintainer";
import { useStyles } from "./styles";
import type { DirectoryGroupModel } from "models/DirectoryGroupModel";
import type { IntegrationResourceModel } from "models/IntegrationResourceModel";
import type { UserModel } from "models/UserModel";

interface IProps<T> {
	autoAssigned?: boolean;
	entity: T extends IntegrationMaintainerModel
		? IntegrationModel
		: T extends IntegrationResourceMaintainerModel
			? IntegrationResourceModel
			: never;
	maintainers: List<T> | null;
	onUpdate: (maintainers: List<T>) => Promise<void>;
}

export function MaintainersBlock<T extends TMaintainer>(props: TProps<IProps<T>>) {
	const { entity, maintainers, onUpdate } = props;
	const [expanded, setExpanded] = useState(false);
	const { t } = useTranslation();
	const classes = useStyles();
	const [addMaintainerVisible, setAddMaintainerVisible] = useState(false);
	const users = useUsers();
	const directoryGroups = useDirectoryGroups();
	const openGlobalErrorModal = useOpenGlobalErrorModal();

	const loading = !users || !directoryGroups;
	const showAddMaintainer = useCallback(
		(event: React.MouseEvent<HTMLButtonElement>) => {
			event.stopPropagation();
			if (!expanded) {
				setExpanded(true);
			}
			setAddMaintainerVisible(true);
		},
		[expanded]
	);
	const hideAddMaintainer = useCallback(() => setAddMaintainerVisible(false), []);

	const onRemove = useCallback(
		async (maintainer: TMaintainer) => {
			try {
				if (!maintainers) return;
				await onUpdate(maintainers.filter(({ id }) => id !== maintainer.id));
			} catch (err) {
				openGlobalErrorModal(err as Error);
			}
		},
		[maintainers, onUpdate, openGlobalErrorModal]
	);

	const onAdd = useCallback(
		async (maintainer: UserModel | DirectoryGroupModel) => {
			const newMaintainer =
				entity instanceof IntegrationModel
					? IntegrationMaintainerModel.fromEntity(maintainer, entity.id)
					: IntegrationResourceMaintainerModel.fromEntity(maintainer, entity.id);
			try {
				await onUpdate((maintainers || List<T>()).push(newMaintainer as T));
				hideAddMaintainer();
			} catch (err) {
				openGlobalErrorModal(err as Error);
			}
		},
		[entity, hideAddMaintainer, maintainers, onUpdate, openGlobalErrorModal]
	);

	const cantBeAdded = useMemo(() => {
		if (!maintainers) return [];
		return maintainers.map(({ entityId }) => entityId).toArray();
	}, [maintainers]);

	const maintainersIsEmpty = !maintainers || maintainers.size === 0;

	return (
		<CommonAccordion
			title={
				<div className={classes.titleContainer}>
					<Typography variant="h3" prefixIcon={<MaintainersIcon />}>
						{t("pages.integration.maintainers")}
					</Typography>

					<Button variant="secondary" size="small" prefix={<AddIcon />} onClick={showAddMaintainer}>
						{t("buttons.add")}
					</Button>
				</div>
			}
			expanded={expanded}
			onChange={setExpanded}>
			{props.autoAssigned ? (
				<HelpBlock className={classes.autoAssignHelpBlock}>
					<Typography>{t("pages.integrationResource.maintainersAutoAssigned")}</Typography>{" "}
				</HelpBlock>
			) : null}
			{loading ? (
				<LoadingDots center />
			) : (
				<>
					{maintainersIsEmpty && <HelpBlock>{t("common.maintainersBlock.noMaintainersNotice")}</HelpBlock>}
					<Table gridColumns="1fr 15rem">
						<Table.Row>
							<Table.Header>
								<Typography>{t("common.maintainersBlock.maintainer")}</Typography>
							</Table.Header>
							<Table.Header></Table.Header>
						</Table.Row>
						{addMaintainerVisible && (
							<Maintainer
								cancel={hideAddMaintainer}
								disabledIds={cantBeAdded}
								onSubmit={onAdd}
								users={users}
								directoryGroups={directoryGroups}
							/>
						)}
						{!maintainersIsEmpty &&
							maintainers.map(maintainer => (
								<Maintainer
									key={maintainer.id || maintainer.entityId}
									maintainer={maintainer}
									onRemove={onRemove}
									users={users}
									directoryGroups={directoryGroups}
								/>
							))}
					</Table>
				</>
			)}
		</CommonAccordion>
	);
}
