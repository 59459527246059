import { useUsersContext } from "context/usersContext";
import { useMemo } from "react";

export const useCustomUser = (id?: string, full = false) => {
	const {
		state: { users, fullUsersState },
		actions: { loadUser }
	} = useUsersContext();

	const user = useMemo(() => {
		if (!id || !users) return null;
		if (full) {
			if (!fullUsersState) return null;
			const userState = fullUsersState.get(id);
			if (!userState) {
				loadUser(id);
				return null;
			}
			if (userState.get("hadError") || userState.get("loading")) return null;
			return userState.get("data") || null;
		}
		return users.get(id) || null;
	}, [full, fullUsersState, id, loadUser, users]);

	return user;
};
