import { useMemo } from "react";
import { useApprovalFlowsWebhooksMap } from "hooks/useApprovalFlowsWebhooksMap";
import { useDirectoryGroups } from "./useDirectoryGroups";
import { useOnCallIntegrationSchedules } from "./useOnCallIntegrationSchedules";
import { useUsersList } from "./useUsers";
import type { TApprovalFlowEntityType } from "models/ApprovalFlowEntityModel";
import type { IEntityOption } from "utils/entityOptionType";
import type { List, Seq } from "immutable";

const convertToEntitiesArray = <K extends TApprovalFlowEntityType, T extends IEntityOption<K>>(
	entities?: List<T> | Seq.Indexed<T>
) => entities?.map(entity => entity.toEntityOption()).toArray();

export const useEntities = () => {
	const usersList = useUsersList();
	const directoryGroupsList = useDirectoryGroups();
	const onCallIntegrationSchedulesMap = useOnCallIntegrationSchedules();
	const approvalFlowsWebhooksMap = useApprovalFlowsWebhooksMap();

	const approvalFlowsWebhooks = useMemo(
		() => convertToEntitiesArray(approvalFlowsWebhooksMap?.valueSeq()),
		[approvalFlowsWebhooksMap]
	);
	const onCallIntegrationSchedules = useMemo(
		() => convertToEntitiesArray(onCallIntegrationSchedulesMap?.valueSeq()),
		[onCallIntegrationSchedulesMap]
	);
	const sortedGroups = useMemo(
		() => convertToEntitiesArray(directoryGroupsList?.sortBy(group => group.name)),
		[directoryGroupsList]
	);
	const users = useMemo(() => convertToEntitiesArray(usersList), [usersList]);

	const entities = useMemo(() => {
		if (!users || !sortedGroups || !onCallIntegrationSchedules || !approvalFlowsWebhooks) {
			return null;
		}

		return { users, approvalFlowsWebhooks, onCallIntegrationSchedules, directoryGroups: sortedGroups };
	}, [approvalFlowsWebhooks, onCallIntegrationSchedules, sortedGroups, users]);

	return entities;
};
