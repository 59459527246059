import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { PageTitleContent } from "components/templates/PageTitleContentTemplate";
import { Button } from "components/ui/Button";
import { InfoIcon } from "components/ui/Icons/InfoIcon";
import { ExportIcon } from "components/ui/Icons/ExportIcon";
import { AddIcon } from "components/ui/Icons/AddIcon";
import { useLocation } from "react-router-dom";
import { WorkflowsWebhooksTableSection } from "./components/WorkflowsWebhooksTableSection";
import { useStyles } from "./styles";

const DOCS_URL = "https://docs.entitle.io/reference/introduction-1";

export const WorkflowsWebhooksPage: FC = ({ className, innerRef }) => {
	const { t } = useTranslation();
	const location = useLocation();
	const classes = useStyles();
	const [create, setCreate] = useState(false);

	const breadcrumbs = useMemo(
		() => [
			{ title: t("pages.workflows.title"), url: location.pathname.split("/").slice(0, -1).join("/") },
			{ title: t("common.approvalAlgorithm.approvalByWebhook") }
		],
		[location.pathname, t]
	);

	const openCreate = useCallback(() => {
		setCreate(true);
	}, []);

	const closeCreate = useCallback(() => {
		setCreate(false);
	}, []);

	const openGuidelines = useCallback(() => {
		window.open(DOCS_URL, "_blank");
	}, []);

	return (
		<PageTitleContent innerRef={innerRef} className={className}>
			<PageTitleContent.Header>
				<PageTitleContent.Header.Top breadcrumbs={breadcrumbs} />
				<PageTitleContent.Header.Bottom>
					<PageTitleContent.Header.Title
						title={t("pages.workflowsWebhooks.title")}
						subtitle={t("pages.workflowsWebhooks.subTitle")}
					/>
					<PageTitleContent.Header.Actions>
						<Button
							size="medium"
							variant="secondary"
							prefix={<InfoIcon />}
							suffix={<ExportIcon />}
							onClick={openGuidelines}>
							{t("pages.workflowsWebhooks.actions.guidelines")}
						</Button>
						<Button size="medium" prefix={<AddIcon />} onClick={openCreate} disabled={create}>
							{t("pages.workflowsWebhooks.actions.add")}
						</Button>
					</PageTitleContent.Header.Actions>
				</PageTitleContent.Header.Bottom>
			</PageTitleContent.Header>
			<PageTitleContent.Body className={classes.contentContainer}>
				<WorkflowsWebhooksTableSection create={create} openCreate={openCreate} closeCreate={closeCreate} />
			</PageTitleContent.Body>
		</PageTitleContent>
	);
};
