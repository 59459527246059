import classNames from "classnames";
import { useUsers } from "hooks/useUsers";
import { UserModel } from "models/UserModel";
import React, { useMemo } from "react";
import { useStyles } from "./styles";

interface IAvatarProps {
	size?: "small" | "medium" | "large";
	fullName?: string;
	imageUrl?: string;
	initials?: string;
	user?: UserModel;
	userId?: string;
}

export const Avatar: FC<IAvatarProps> = props => {
	const { className, size = "small", innerRef, userId, user: userProp, ...userProps } = props;
	const users = useUsers();
	const user = useMemo(() => userProp || (userId ? users?.get(userId) : null), [users, userProp, userId]);
	const classes = useStyles();
	const text = useMemo(() => getText(user ? { fullName: user.fullName } : userProps), [user, userProps]);
	const style = useMemo(
		() => getStyle(user && user.imageUrl ? { imageUrl: user.imageUrl } : userProps),
		[user, userProps]
	);
	return (
		<div
			ref={innerRef as React.Ref<HTMLDivElement>}
			className={classNames(classes.avatar, size, className)}
			style={style}>
			{!style?.backgroundImage && text}
		</div>
	);
};

const getStyle = (props: Partial<IAvatarProps>): { backgroundImage?: string } => {
	const { imageUrl } = props;
	if (imageUrl) {
		return {
			backgroundImage: `url(${imageUrl})`
		};
	}
	return {};
};

const getText = (props: Partial<IAvatarProps>): string => {
	const { fullName, initials } = props;
	if (fullName) {
		return getInitials(fullName);
	}
	if (initials) {
		return initials;
	}
	return "";
};

const getInitials = (fullName: string): string => {
	const parts = fullName.split(" ", 2);
	return parts.map(part => part?.[0]?.toLocaleUpperCase()).join("");
};
