import React, { useCallback, useEffect, useMemo, useState } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { Set, Map } from "immutable";
import { IconPrefix } from "components/ui/IconPrefix";
import { IconButton } from "components/ui/IconButton";
import { ExpandIcon } from "components/ui/Icons/ExpandIcon";
import { Typography } from "components/ui/New/Typography";
import { ResourcesIcon } from "components/ui/Icons/ResourcesIcon";
import { RoleIcon } from "components/ui/Icons/RoleIcon";
import { IInputState, Input } from "components/ui/Input";
import { LoadingSpinner } from "components/ui/LoadingSpinner";
import { ResourceCard } from "../ResourceCard";
import { getIntegrationIcon } from "../IntegrationImage";
import { useStyles } from "./styles";
import type { IntegrationModel } from "models/IntegrationModel";
import type { IntegrationResourceModel } from "models/IntegrationResourceModel";
import type { IntegrationResourceRoleModel } from "models/IntegrationResourceRoleModel";

type TProps = {
	integration: IntegrationModel;
	onIntegrationResourceSearch: (search: string) => void;
	onResourceRoleSearch: (resourceId: string, search?: string) => void;
	onSelectRole: (resourceId: string, roleId: string) => void;
	resources?: IntegrationResourceModel[];
	roleOptions: Map<string, IntegrationResourceRoleModel[]>; // Map of resource id -> role options
	selectedRoleIds: Map<string, Set<string>>; // Map of resource id -> selected role ids
	onCollapse?: () => void;
};

type TSelectionResourceCardProps = Omit<TProps, "integration" | "resources" | "onIntegrationResourceSearch"> & {
	expandedResources: Set<string>;
	resource: IntegrationResourceModel;
	toggleResourceExpand: (resourceId: string) => void;
};

const SelectableResourceCard: FC<TSelectionResourceCardProps> = ({
	expandedResources,
	onResourceRoleSearch,
	onSelectRole,
	resource,
	roleOptions,
	selectedRoleIds,
	toggleResourceExpand
}) => {
	const isExpanded = expandedResources.has(resource.id);
	const toggleExpand = useCallback(() => toggleResourceExpand(resource.id), [resource.id, toggleResourceExpand]);
	const onSearch = useCallback(
		(search: string) => onResourceRoleSearch(resource.id, search),
		[onResourceRoleSearch, resource.id]
	);
	const onSelect = useCallback(
		(roleId: string) => {
			if (!resource.multirole) {
				const alreadySelected = selectedRoleIds.get(resource.id);
				if (alreadySelected && alreadySelected.size > 0 && !alreadySelected.has(roleId)) {
					alreadySelected.forEach(selectedRoleId => onSelectRole(resource.id, selectedRoleId));
				}
			}
			onSelectRole(resource.id, roleId);
		},
		[onSelectRole, resource.id, resource.multirole, selectedRoleIds]
	);
	const resourceRoleOptions = roleOptions.get(resource.id) || [];
	const selectedRoles = selectedRoleIds.get(resource.id) || Set();
	const totalRoles = resource.rolesCount || 0;

	return isExpanded ? (
		<ResourceCard
			expanded
			multiRole={resource.multirole}
			onClick={toggleExpand}
			onSearch={onSearch}
			onSelect={onSelect}
			resource={resource}
			roleOptions={resourceRoleOptions}
			selectedRoleIds={selectedRoles}
			totalRoles={totalRoles}
		/>
	) : (
		<ResourceCard resource={resource} onClick={toggleExpand} totalRoles={totalRoles} selectedRoleIds={selectedRoles} />
	);
};

export const ResourceSelection: FC<TProps> = ({
	className,
	innerRef,
	integration,
	onIntegrationResourceSearch,
	onResourceRoleSearch,
	onSelectRole,
	resources,
	roleOptions,
	selectedRoleIds,
	onCollapse
}) => {
	const { t } = useTranslation("translation");
	const classes = useStyles();
	const [expandedResources, setExpandedResources] = useState<Set<string>>(Set());
	const [isExpansionDirty, setIsExpansionDirty] = useState(false);
	const [isSearchDirty, setIsSearchDirty] = useState(false);

	const toggleResourceExpand = useCallback(
		(resourceId: string) => {
			const current = expandedResources.has(resourceId);
			setIsExpansionDirty(true);
			setExpandedResources(current ? expandedResources.remove(resourceId) : expandedResources.add(resourceId));
			if (!current) {
				onResourceRoleSearch(resourceId);
			}
		},
		[expandedResources, onResourceRoleSearch]
	);

	useEffect(() => {
		if (resources && resources.length === 1 && !isSearchDirty) {
			const resourceId = resources[0].id;
			onResourceRoleSearch(resourceId);
			setExpandedResources(Set(resourceId));
		}
	}, [resources, isSearchDirty, onResourceRoleSearch]);

	useEffect(() => {
		if (isExpansionDirty || !resources || !selectedRoleIds) return;
		const shouldBeOpened = resources.filter(resource => {
			const selectedRoles = selectedRoleIds.get(resource.id);
			return selectedRoles && selectedRoles.size > 0;
		});
		setExpandedResources(Set(shouldBeOpened.map(resource => resource.id)));
	}, [isExpansionDirty, resources, selectedRoleIds]);

	const totalSelectedRoles = useMemo(() => {
		return selectedRoleIds.reduce((acc, selectedRoles) => acc + selectedRoles.size, 0);
	}, [selectedRoleIds]);

	const onInputStateChange = useCallback((state: IInputState) => {
		setIsSearchDirty(state.dirty);
	}, []);

	return (
		<div className={classNames(classes.container, className)} ref={innerRef}>
			<div className={classes.containerHeader}>
				<div className={classNames(classes.headerTop, { [classes.clickable]: !!onCollapse })} onClick={onCollapse}>
					<div className={classes.headerTopTitleDescription}>
						<IconPrefix size="huge" semibold Icon={getIntegrationIcon(integration)} content={integration.name} />
					</div>
					<div className={classes.headerTopActions}>
						<IconButton size="medium" onClick={onCollapse}>
							<ExpandIcon />
						</IconButton>
					</div>
				</div>
				<div className={classes.headerBottom}>
					<div className={classes.headerBottomInfo}>
						<div className={classes.headerBottomInfoCountLine}>
							<IconPrefix
								size="small"
								Icon={ResourcesIcon}
								content={t("entities.plural.IntegrationResource", { context: "capital" })}
							/>
							<Typography variant="text_title_sb">{integration.resourcesCount || 0}</Typography>
						</div>
						<div className={classes.headerBottomInfoCountLine}>
							<IconPrefix size="small" Icon={RoleIcon} content={t("common.resourceSelection.selectedRoles")} />
							<Typography variant="text_title_sb">{totalSelectedRoles}</Typography>
						</div>
					</div>
					<div className={classes.headerBottomActions}>
						<Input
							className={classes.searchInput}
							onStateChange={onInputStateChange}
							onValueChange={onIntegrationResourceSearch}
							placeholder={t("common.resourceSelection.searchResources")}
							small
						/>
					</div>
				</div>
			</div>
			<div className={classes.containerBody}>
				{resources ? (
					resources.map(resource => (
						<SelectableResourceCard
							expandedResources={expandedResources}
							key={resource.id}
							onResourceRoleSearch={onResourceRoleSearch}
							onSelectRole={onSelectRole}
							resource={resource}
							roleOptions={roleOptions}
							selectedRoleIds={selectedRoleIds}
							toggleResourceExpand={toggleResourceExpand}
						/>
					))
				) : (
					<LoadingSpinner />
				)}
			</div>
		</div>
	);
};
