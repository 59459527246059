import classNames from "classnames";
import { CloseIcon } from "components/ui/Icons/CloseIcon";
import { Typography } from "components/ui/Typography";
import { useUsers } from "hooks/useUsers";
import { useMultipleUsers } from "hooks/useMultipleUsers";
import { TicketModel } from "models/TicketModel";
import React, { useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";
import { getApprovalIconAndText } from "utils/ticketStatus";
import difference from "lodash/difference";
import { List, Map } from "immutable";
import { UserTooltip } from "components/common/UserTooltip";
import { useDirectoryGroups } from "hooks/useDirectoryGroups";
import { DirectoryGroupTooltip } from "components/common/DirectoryGroupTooltip/DirectoryGroupTooltip";
import { DURATION_OPTIONS } from "utils/durationsOptions";
import { TicketPermissionModel, TTicketPermissionType } from "models/TicketPermissionModel";
import { TicketingIntegrationTicketChip } from "components/common/TicketingIntegrationTicketChip";
import { InfoIcon } from "components/ui/Icons/InfoIcon";
import { Tooltip } from "components/ui/Tooltip";
import { TicketPermissionWithStatus } from "../TicketPermissionWithStatus";
import { useStyles } from "./styles";
import type { TicketApprovalEntityModel } from "models/TicketApprovalEntityModel";

interface IProps {
	onClose: () => void;
	ticket: TicketModel;
}

const ticketPermissionsData = (ticket: TicketModel) => {
	return ticket.ticketPermissions?.reduce((acc, permission: TicketPermissionModel) => {
		const { type } = permission;
		return acc.update(type, current =>
			(current || []).concat([
				<TicketPermissionWithStatus
					key={permission.id}
					status={permission.status}
					role={permission.role}
					ticketStatus={ticket.status}
				/>
			])
		);
	}, Map<TTicketPermissionType, JSX.Element[]>());
};

export const TicketSidebarHeader: FC<IProps> = ({ ticket, className, id, innerRef, onClose }) => {
	const classes = useStyles();
	const { t } = useTranslation();
	const users = useUsers();

	const receiver = useMemo(() => users?.get(ticket.receiverId), [ticket.receiverId, users]);
	const duration = useMemo(() => {
		if (DURATION_OPTIONS.includes(ticket.duration)) return t(`common.durations.${ticket.duration}`);
		return t("common.durations.unknown");
	}, [t, ticket.duration]);

	const title = useMemo(() => {
		if (!ticket) return null;
		const translationOptions: { target?: string } = {
			target: ticket.targetDisplayName
		};
		return (
			<Trans
				t={t}
				i18nKey={`common.ticketSidebar.target.${ticket.targetType}`}
				values={translationOptions}
				components={{ bold: <b /> }}
			/>
		);
	}, [t, ticket]);

	const ticketData = useMemo(
		() => (
			<Trans
				t={t}
				i18nKey={"common.ticketSidebar.ticketData"}
				values={{ receiver: receiver?.fullName, duration }}
				components={{ bold: <b /> }}
			/>
		),
		[duration, receiver, t]
	);

	const permissionsData = useMemo(() => ticketPermissionsData(ticket), [ticket]);
	const prerequisiteTicketPermissionsData = useMemo(() => permissionsData?.get("prerequisite"), [permissionsData]);
	const regularTicketPermissionsData = useMemo(() => permissionsData?.get("regular"), [permissionsData]);

	const [Icon, text] = useMemo(() => getApprovalIconAndText(ticket.status, t), [t, ticket]);

	const activeRequest = useMemo(
		() => ticket.approvalRequests?.find(req => req.active && req.status === "pending"),
		[ticket.approvalRequests]
	);
	const alreadyApproved = useMemo(
		() => ticket.ticketApprovalResponses?.map(response => response.userId).toArray() || [],
		[ticket.ticketApprovalResponses]
	);
	const pendingApproverEntities = useMemo(
		() =>
			activeRequest?.approvalEntities?.filter(
				entity => difference(entity.approverIds.toArray(), alreadyApproved).length !== 0
			) || List<TicketApprovalEntityModel>(),
		[activeRequest?.approvalEntities, alreadyApproved]
	);

	const pendingApproverUserIds = useMemo(
		() =>
			pendingApproverEntities
				.filter(({ type }) => type !== "DirectoryGroup")
				.flatMap(({ approverIds }) => approverIds)
				.toSet()
				.toList(),
		[pendingApproverEntities]
	);

	const directoryGroups = useDirectoryGroups();

	const { usersList: pendingApproversUsers } = useMultipleUsers(pendingApproverUserIds);

	const pendingToDirectoryGroups = pendingApproverEntities
		.filter(({ type }) => type === "DirectoryGroup")
		.map(group =>
			directoryGroups?.find(dg => (group.identifier ? group.identifier === dg.id : dg.name === group.displayName))
		);

	const pendingApproverGroups = pendingToDirectoryGroups.map(group => ({
		type: "group" as const,
		value: group?.name,
		email: group?.email,
		groupId: group?.id
	}));

	const pendingApprovers = useMemo(
		() =>
			pendingApproversUsers
				.map(user => ({ type: "user" as const, value: user }))
				.concat(pendingApproverGroups)
				.toArray(),
		[pendingApproverGroups, pendingApproversUsers]
	);

	if (!ticket) return null;

	return (
		<div id={id} ref={innerRef} className={classNames(classes.header, className)}>
			<div className={classes.headerTop}>
				<div className={classes.headerTopLeft}>
					<Typography variant="small" className={classes.headerTopTicketNumber}>
						{ticket.number}
					</Typography>
					<Typography variant="small" className={classes.headerTopTicketCreationDate}>
						{t("dateTime.date", { date: ticket.createdAt })}
					</Typography>
				</div>
				<CloseIcon onClick={onClose} className={classes.headerTopRightIcon} />
			</div>
			<div className={classes.headerStatus}>
				<Icon />
				{pendingApprovers.length && ticket.status !== "cancelled" ? (
					<div className={classes.pendingApprovers}>
						{t("common.ticketSidebar.pendingApproval")}
						{pendingApprovers.map((approver, index) => {
							const withSeparator = index + 1 !== pendingApprovers.length;
							if (approver.type === "group") {
								return (
									<DirectoryGroupTooltip
										key={approver.value || approver.email || index}
										groupName={approver.value || ""}
										groupEmail={approver.email || undefined}
										groupId={approver.groupId}
									/>
								);
							}
							return (
								<UserTooltip key={approver.value.id} user={approver.value}>
									{approver.value.fullName}
									{withSeparator ? "," : null}
								</UserTooltip>
							);
						})}
					</div>
				) : (
					text
				)}
			</div>
			<Typography variant="h3" component="div" className={classes.headerTitle}>
				{title}
			</Typography>
			<Typography component="div">
				{ticketData}
				{ticket.ticketingIntegrationTicket && (
					<TicketingIntegrationTicketChip
						className={classes.ticketingIntegrationTicketChip}
						label={
							<>
								<Typography variant="tiny">{t("common.ticketSidebar.expirationLinkedTo")}</Typography>
								<Tooltip content={t("common.ticketSidebar.expirationLinkedToInfo")}>
									<InfoIcon />
								</Tooltip>
							</>
						}
						ticketingIntegrationTicket={ticket.ticketingIntegrationTicket}
					/>
				)}
			</Typography>
			{regularTicketPermissionsData}
			{(prerequisiteTicketPermissionsData?.length || 0) > 0 && (
				<div className={classes.ticketPermissionsData}>
					<Typography variant="tiny">{t("common.ticketSidebar.prerequisiteRoles")}</Typography>
					{prerequisiteTicketPermissionsData}
				</div>
			)}
		</div>
	);
};
