import { Record } from "immutable";
import type { TSources } from "utils/sourceIcons";

type TCompanyAuditLogAction =
	| "OrganizationIdpSyncGroupsFailed"
	| "OrganizationIdpSyncUsersFailed"
	| "OrganizationPolicyPermissionsUpdatesToBePerformed"
	| "OrganizationPolicyPermissionsUpdatesMissingAccounts"
	| "OrganizationIdpSyncGroupsSucceeded"
	| "OrganizationIdpSyncUsersSucceeded"
	| "OrganizationHrmsSyncSucceeded"
	| "OrganizationHrmsSyncFailed";

export interface ICompanyAuditLogData {
	errorMessage?: string;
	permissions?: {
		added: {
			integrationName?: string;
			resourceName?: string;
			roleName?: string;
			number?: number;
		}[];
		removed: { integrationName?: string; resourceName?: string; roleName?: string }[];
	};
	receiverUserId?: string;
	userMissingActorsData?: { integrationName: string; rolesMissingUsersCount: number }[];
	idpApplicationName?: TSources;
	idpId?: string;
	idpName?: string;
}

const defaults = {
	action: "OrganizationIdpSyncGroupsFailed" as TCompanyAuditLogAction,
	userId: "",
	id: "",
	createdAt: new Date(0),
	data: null as ICompanyAuditLogData | null
};

export class CompanyAuditLogModel extends Record<typeof defaults>(defaults) {
	static readonly type = "company";

	static fromServerData(data: unknown) {
		const { id, createdAt, data: auditLogData, action, userId } = data as typeof defaults;

		return new CompanyAuditLogModel({
			id,
			createdAt: new Date(createdAt),
			data: auditLogData,
			action,
			userId
		});
	}
}
