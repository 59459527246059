import { useOpenGlobalErrorModal } from "hooks/useGlobalError";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { openCenteredPopup } from "utils/openCenteredPopup";
import { OktaLogo } from "components/ui/systemLogos/OktaLogo";
import { BaseLoginButton } from "../BaseLoginButton";

export const OktaLoginButton: FC = () => {
	const { t } = useTranslation();

	const openGlobalErrorModal = useOpenGlobalErrorModal();

	const login = useCallback(() => {
		const popupWindow = openCenteredPopup({
			url: "/okta",
			title: t("pages.signIn.sso.okta.signInWith")
		});

		if (!popupWindow) {
			openGlobalErrorModal(new Error(t("errors.popup.open")));
		}
	}, [t, openGlobalErrorModal]);

	return <BaseLoginButton onClick={login} text={t("pages.signIn.sso.okta.signInWith")} Logo={OktaLogo} />;
};
