import React, { MouseEventHandler, ReactNode, useMemo } from "react";
import classNames from "classnames";
import { TTypographyVariant, Typography } from "components/ui/New/Typography";
import { LoadingSpinner } from "components/ui/LoadingSpinner";
import { useTooltip } from "hooks/useTooltip";
import { createPortal } from "react-dom";
import { useOnClickOutside } from "hooks/useOnClickOutside";
import { ChevronDownIcon } from "../Icons/ChevronDownIcon";
import { useStyles } from "./styles";
import type { Placement } from "@popperjs/core";

type TDropdownButtonVariant = "primary" | "secondary";
type TDropdownButtonSize = "small" | "medium" | "large";

type TDropdownButtonProps = {
	disabled?: boolean;
	dropdown: ReactNode;
	loading?: boolean;
	onClick?: MouseEventHandler;
	onClose: () => void;
	open: boolean;
	position?: Placement;
	prefix?: ReactNode;
	size?: TDropdownButtonSize;
	suffix?: ReactNode;
	variant?: TDropdownButtonVariant;
};

const useButtonClasses = (props: {
	active?: boolean;
	className?: string;
	disabled?: boolean;
	loading?: boolean;
	size: TDropdownButtonSize;
	variant: TDropdownButtonVariant;
}) => {
	const { active, size, variant, loading, disabled, className } = props;
	const classes = useStyles();
	const sizeClassName = useMemo(() => {
		switch (size) {
			case "large":
				return classes.large;
			case "medium":
				return classes.medium;
			case "small":
				return classes.small;
		}
	}, [classes.large, classes.medium, classes.small, size]);

	const variantClassName = useMemo(() => {
		switch (variant) {
			case "primary":
				return classes.primary;
			case "secondary":
				return classes.secondary;
		}
	}, [classes.primary, classes.secondary, variant]);

	const buttonClassName = useMemo(() => {
		return classNames(
			classes.button,
			sizeClassName,
			variantClassName,
			{ [classes.disabled]: disabled, [classes.loading]: loading, [classes.active]: active },
			className
		);
	}, [active, className, classes, disabled, loading, sizeClassName, variantClassName]);

	return { buttonClassName, sizeClassName, variantClassName };
};

export const DropdownButton: FC<TDropdownButtonProps> = props => {
	const {
		children,
		className,
		disabled = false,
		dropdown,
		loading = false,
		onClick,
		onClose,
		open,
		position = "auto",
		prefix,
		size = "large",
		suffix = <ChevronDownIcon size={size} />,
		variant = "primary"
	} = props;
	const classes = useStyles();

	const { buttonClassName } = useButtonClasses({ className, disabled, size, variant, active: open });

	const textVariant: TTypographyVariant = useMemo(() => {
		switch (size) {
			case "large":
				return "title_med";
			case "medium":
				return "text_title_sb";
			case "small":
				return "text_sm_sb";
		}
	}, [size]);

	const content = useMemo(() => {
		return (
			<>
				{prefix ? <div className={classes.prefixOrSuffix}>{prefix}</div> : null}
				<Typography noWrap className={classes.innerText} variant={textVariant}>
					{children}
				</Typography>
				{loading || suffix ? (
					<div className={classes.prefixOrSuffix}>{loading ? <LoadingSpinner inline /> : suffix || null}</div>
				) : null}
			</>
		);
	}, [children, classes.innerText, classes.prefixOrSuffix, loading, prefix, suffix, textVariant]);

	const tooltipOptions = useMemo(() => ({ visible: open, placement: position }), [position, open]);
	const { visible, setTooltipRef, tooltipProps, setTriggerRef, tooltipRef } = useTooltip(tooltipOptions);

	useOnClickOutside(tooltipRef || undefined, onClose);

	const dropdownPortal = useMemo(() => {
		return createPortal(
			<div ref={setTooltipRef} {...tooltipProps}>
				{dropdown}
			</div>,
			document.body
		);
	}, [dropdown, setTooltipRef, tooltipProps]);

	return (
		<React.Fragment>
			<button
				className={buttonClassName}
				ref={setTriggerRef}
				onClick={!disabled && !loading ? onClick : undefined}
				type="button">
				{content}
			</button>
			{visible ? dropdownPortal : null}
		</React.Fragment>
	);
};
