import { DebouncedFunc } from "lodash";
import debounce from "lodash/debounce";
import { useCallback, useEffect, useMemo, useState } from "react";
import { ignoreAbortError } from "utils/ignoreAbortError";

const WAIT_MS = 50;

export type TSearch<T> = (query: string) => Promise<T[]>;
type TSearchOptions = (query: string | null) => Promise<void>;

export const useSearchOptions = <T>(
	search: TSearch<T>,
	value: T | T[] | null,
	withDebounce = false,
	initialQuery = null
): [T[] | null, TSearchOptions | DebouncedFunc<TSearchOptions>, boolean, string | null] => {
	const [query, setQuery] = useState<string | null>(initialQuery);
	const [loading, setLoading] = useState(false);
	const [searchOptions, setSearchOptions] = useState<T[] | null>(null);

	const onInputChange = useCallback(async (newQuery: string | null) => {
		setQuery(newQuery);
	}, []);

	// Memoize the debounce function if needed
	const searchHandler = useMemo(
		() => (withDebounce ? debounce(onInputChange, WAIT_MS) : onInputChange),
		[withDebounce, onInputChange]
	);

	useEffect(() => {
		if (query !== null) {
			setLoading(true);
			ignoreAbortError(search(query), result => {
				setSearchOptions(result);
				setLoading(false);
			});
		}
	}, [query, search]);

	useEffect(() => {
		setSearchOptions(null);
		searchHandler("");
	}, [searchHandler]);

	return [searchOptions, searchHandler, loading, query];
};
