// eslint-disable-next-line spaced-comment
/// <reference types="@welldone-software/why-did-you-render" />
import React from "react";

// Make sure to only include the library in development
if (process.env.NODE_ENV === "development") {
	try {
		// eslint-disable-next-line @typescript-eslint/no-var-requires
		const whyDidYouRender = require("@welldone-software/why-did-you-render");
		whyDidYouRender(React, {
			trackAllPureComponents: false
		});
	} catch (e) {
		console.warn("Could not load why-did-you-render");
		console.warn("For extra information: ", e);
	}
}
