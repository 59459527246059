import React from "react";
import { Chip } from "components/ui/Chip";
import { LoadingDots } from "components/ui/LoadingDots";
import { useIntegrations } from "hooks/useIntegrations";
import { Typography } from "components/ui/Typography";
import { IntegrationResourceRoleModel } from "models/IntegrationResourceRoleModel";
import classNames from "classnames";
import { useStyles } from "./styles";
import type { IntegrationModel } from "models/IntegrationModel";

const RoleItem: FC<{ role: IntegrationResourceRoleModel; integration?: IntegrationModel }> = ({
	role,
	integration
}) => {
	const classes = useStyles();

	return (
		<Chip
			size="tiny"
			prefixIcon={integration?.imageUrl ? <img className={classes.image} src={integration.imageUrl} /> : undefined}>
			<div className={classes.roleChip}>
				<Typography variant="tiny" className={classNames(classes.roleTitle, { deleted: role.isDeleted })}>
					{role.name}
				</Typography>
				<Typography variant="tiny">
					{integration?.name ? `${integration.name} - ` : ""}
					{role!.integrationResource!.displayName}
				</Typography>
			</div>
		</Chip>
	);
};

export const RolesList: FC<{ roles: IntegrationResourceRoleModel[] }> = ({ roles }) => {
	const integrations = useIntegrations(true);

	const getRoleIntegration = (role: IntegrationResourceRoleModel) =>
		integrations && role?.integrationResource ? integrations?.get(role.integrationResource.integrationId) : undefined;
	return integrations ? (
		<>
			{roles.map(role => (
				<RoleItem key={role.id} role={role} integration={getRoleIntegration(role)} />
			))}
		</>
	) : (
		<LoadingDots center />
	);
};
