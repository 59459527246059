import { createUseStyles } from "react-jss";

export const RESIZER_WIDTH_PX = 16;
const TRANSITION_DURATION = "300ms";

export const useStyles = createUseStyles({
	sidebar: {
		backgroundColor: "var(--color-white)",
		display: "flex",
		flexShrink: 0,
		height: "100%",
		"& > div": {
			height: "100%"
		},
		pointerEvents: "all",
		position: "absolute",
		transition: `linear ${TRANSITION_DURATION} right`,
		zIndex: 1,
		overflowY: "auto",
		borderRadius: "var(--border-radius-rounder) 0 0 0"
	},
	resizer: {
		width: `${RESIZER_WIDTH_PX}px`,
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		background: "var(--color-purple-100)",
		"& > div": {
			cursor: "ew-resize"
		}
	},
	content: {
		flex: 1,
		height: "100%"
	},
	sidebarContainer: {
		"&$open": {
			"& > $dimmer": {
				opacity: 0.5,
				pointerEvents: "all"
			},
			"& > $sidebar": {
				right: 0
			}
		},
		height: "100%",
		left: 0,
		overflow: "hidden",
		"&:not($open)": { pointerEvents: "none" },
		position: "absolute",
		width: "100%",
		top: 0
	},
	dimmer: {
		backgroundColor: "black",
		height: "100%",
		left: 0,
		opacity: 0,
		pointerEvents: "none",
		position: "absolute",
		top: 0,
		transition: `linear ${TRANSITION_DURATION} opacity`,
		width: "100%",
		zIndex: 0
	},
	open: {}
});
