import { createUseStyles } from "react-jss";

const WIDTH = "45rem";
const MAX_WIDTH = "100vh";
const TRANSITION_DURATION = "300ms";

export const useStyles = createUseStyles({
	actionsContainer: {
		"& $approveButton": {
			"&:active": {
				color: "var(--color-green-dark)"
			},
			"&:hover": {
				backgroundColor: "var(--color-green-10)"
			},
			color: "var(--color-green-dark)"
		},
		"& $approveButton, $declineButton": {
			"&:hover": {
				borderColor: "var(--color-grey-light)",
				cursor: "pointer"
			},
			alignItems: "center",
			borderColor: "var(--color-grey-light)",
			display: "inline-flex",
			height: "2rem",
			justifyContent: "space-around"
		},
		"& $declineButton": {
			"&:active": {
				color: "var(--color-red-dark)"
			},
			"&:hover": {
				backgroundColor: "var(--color-red-10)"
			},
			color: "var(--color-red-dark)"
		},
		alignItems: "center",
		display: "flex",
		gap: "0.5rem"
	},
	approveButton: {},
	closeIcon: {
		"&:hover": {
			color: "var(--color-grey-dark)"
		},
		color: "var(--color-grey)",
		fontSize: "1.5rem",
		right: "2rem",
		top: "2rem",
		position: "absolute"
	},
	commentsContainer: {
		display: "flex",
		flexDirection: "column",
		gap: "1rem",
		margin: "0 auto",
		maxWidth: "80%",
		padding: "1rem"
	},
	container: {
		"&$open": {
			"& > $dimmer": {
				opacity: 0.5,
				pointerEvents: "all"
			},
			"& > $sidebar": {
				right: 0
			}
		},
		height: "100%",
		left: 0,
		overflow: "hidden",
		pointerEvents: "none",
		position: "absolute",
		top: 0,
		width: "100%"
	},
	declineButton: {},
	dimmer: {
		backgroundColor: "black",
		height: "100%",
		left: 0,
		opacity: 0,
		pointerEvents: "none",
		position: "absolute",
		top: 0,
		transition: `linear ${TRANSITION_DURATION} opacity`,
		width: "100%",
		zIndex: 0
	},
	emptyTicketContainer: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		paddingTop: "3rem"
	},
	header: {
		alignItems: "center",
		display: "flex",
		justifyContent: "space-between",
		minHeight: "6rem",
		padding: "0.5rem 2rem",
		whiteSpace: "pre-wrap"
	},
	loader: {
		alignSelf: "center",
		justifySelf: "center"
	},
	notFound: {
		alignSelf: "center",
		justifySelf: "center"
	},
	open: {},
	sidebar: {
		backgroundColor: "var(--color-white)",
		borderLeft: "1px solid var(--color-grey-light)",
		display: "flex",
		flexDirection: "column",
		height: "100%",
		pointerEvents: "all",
		position: "absolute",
		right: `max(-${WIDTH}, -${MAX_WIDTH})`,
		transition: `linear ${TRANSITION_DURATION} right`,
		width: `min(${WIDTH}, ${MAX_WIDTH})`,
		zIndex: 1,
		overflowY: "auto",
		borderRadius: "var(--border-radius-rounder) 0 0 0"
	},
	tabs: {
		backgroundColor: "var(--color-grey-background)"
	},
	ticketNumber: {},
	title: {
		fontWeight: "var(--typography-font-weight-regular)",
		"& $ticketNumber": {
			color: "var(--color-grey-dark)",
			fontWeight: "var(--typography-font-weight-light)"
		}
	}
});
