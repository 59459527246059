import classNames from "classnames";
import { WorkflowsIcon } from "components/ui/Icons/WorkflowsIcon";
import { Typography } from "components/ui/Typography";
import { IntegrationModel } from "models/IntegrationModel";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { ResourceHeader } from "components/common/ResourceHeader";
import { UserWithEmail } from "components/common/UserWithEmail";
import { LoadingSpinner } from "components/ui/LoadingSpinner";
import { Separator } from "components/ui/Separator";
import { useSingleUser } from "hooks/useSingleUser";
import { useStyles } from "./styles";

interface IProps {
	integration: IntegrationModel;
}

export const IntegrationCard: FC<IProps> = ({ className, integration }) => {
	const classes = useStyles();
	const { t } = useTranslation();
	const { user: integrationOwner, isLoading: integrationOwnerLoading } = useSingleUser(integration?.ownerId, false);

	return (
		<div className={classNames(classes.card, className)}>
			<Link to={`/integrations/${integration.id}`} className={classes.content}>
				{integration.ownerId && (
					<div className={classNames(classes.cell, classes.summaryCell)}>
						<ResourceHeader
							name={integration.name}
							imageUrl={integration.imageUrl}
							ownerId={integration.ownerId}
							childrenCount={integration.resourcesCount || 0}
							resourceType="integration"
						/>
					</div>
				)}
				<Separator />
				{integrationOwnerLoading ? (
					<LoadingSpinner className={classes.spinner} size="extra-small" />
				) : (
					integrationOwner && (
						<div className={classNames(classes.cell)}>
							<Typography relative>
								<UserWithEmail user={integrationOwner} />
							</Typography>
						</div>
					)
				)}
				<Separator />
				<div className={classNames(classes.cell)}>
					{integration.defaultApprovalAlgorithm ? (
						<Typography component="div" className={classes.defaultApprovalAlgorithm}>
							<WorkflowsIcon />
							{integration.defaultApprovalAlgorithm.name}
						</Typography>
					) : (
						<Typography>{t("pages.integrations.noApprovalAlgorithm")}</Typography>
					)}
				</div>
			</Link>
		</div>
	);
};
