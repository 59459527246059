import { useNewTicketFormContext } from "components/pages/NewTicketPage/newTicketContext";
import { useBundles } from "hooks/useBundles";
import { useUsers } from "hooks/useUsers";
import { useMemo } from "react";
import { List } from "immutable";
import type { TFullBundle, TFullRole, TNewTicketOption, TPrePopulatedFormData } from "../types";

export const useOriginalTicketData = (): TPrePopulatedFormData | null => {
	const {
		state: { originalTicket }
	} = useNewTicketFormContext();

	const users = useUsers();
	const bundles = useBundles();

	return useMemo((): TPrePopulatedFormData | null => {
		if (!originalTicket || !users || !bundles) return null;

		const items: TNewTicketOption[] = [];

		if (originalTicket.targetType === "bundle") {
			const bundle = bundles.get(originalTicket?.bundleId || "") as TFullBundle;
			bundle && items.push({ type: "bundle", value: bundle });
		} else {
			originalTicket?.ticketPermissions?.forEach(({ integrationResourceRole }) => {
				items.push({ type: "role", value: integrationResourceRole as TFullRole });
			});
		}

		return {
			ticketingIntegrationTicketId: originalTicket.ticketingIntegrationTicket?.id,
			justification: originalTicket.justification,
			duration: originalTicket.duration,
			receiverUser: users.get(originalTicket.receiverId),
			targets: List(items)
		};
	}, [originalTicket, users, bundles]);
};
