import classNames from "classnames";
import React from "react";
import { useStyles } from "./styles";

export const OptionWithIcon: FC<{ imageUrl?: string | null; imageClassName?: string }> = ({
	imageClassName,
	imageUrl,
	children,
	className
}) => {
	const classes = useStyles();
	if (!imageUrl) return <div className={classes.text}>{children}</div>;
	return (
		<div className={classNames(classes.container, className)}>
			<img src={imageUrl} className={classNames(classes.image, imageClassName)} />
			<div className={classes.text}>{children}</div>
		</div>
	);
};
