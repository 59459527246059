import React, { useMemo } from "react";
import classNames from "classnames";
import { TicketModel } from "models/TicketModel";
import { dayjs } from "utils/dayjs";
import { WarningIcon } from "components/ui/Icons/WarningIcon";
import { RequestDateAndNumber } from "components/common/RequestDateAndNumber";
import {
	AdminDeniedStatus,
	CancelledStatus,
	DeniedStatus,
	ExpiredStatus,
	FailedStatus,
	GrantedStatus,
	PendingApprovalStatus,
	PendingManualFixStatus,
	PermissionInProgressStatus
} from "components/common/RequestStatuses";
import { useStyles } from "./styles";

type TProps = {
	actions?: React.ReactNode;
	request: TicketModel;
	showStatus?: boolean;
	toApprove?: boolean;
};

const OVERTIME_DAYS = 3;

export const RequestSummary: FC<TProps> = ({
	actions,
	className,
	innerRef,
	request,
	showStatus = false,
	toApprove = false
}) => {
	const classes = useStyles();

	const overtimeIcon = useMemo(() => {
		if (!toApprove) return null;
		const requestCreationDate = dayjs(request.createdAt);
		const isOvertime = requestCreationDate.add(OVERTIME_DAYS, "day").isBefore(dayjs());
		if (!isOvertime) return null;
		return <WarningIcon />;
	}, [request.createdAt, toApprove]);

	const status = useMemo(() => {
		if (!showStatus) return null;
		switch (request.status) {
			case "approved":
			case "granted":
				return <GrantedStatus size="huge" />;
			case "cancelled":
				return <CancelledStatus size="huge" />;
			case "failed":
				return <FailedStatus size="huge" />;
			case "rejected":
				return request.ticketApprovalResponses?.some(response => response.type === "adminDecline") ? (
					<AdminDeniedStatus size="huge" />
				) : (
					<DeniedStatus size="huge" />
				);
			case "revoked":
				// TODO: add manually revoke check, for now we don't have this status
				return <ExpiredStatus size="huge" />;
			case "waitingForApproval":
				return <PendingApprovalStatus size="huge" />;
			case "waitingForIT":
				return <PendingManualFixStatus size="huge" />;
			case "permissionInProgress":
				return <PermissionInProgressStatus size="huge" />;
			default:
				return null;
		}
	}, [showStatus, request]);

	return (
		<div className={classNames(classes.container, className)} ref={innerRef}>
			<RequestDateAndNumber prefix={overtimeIcon} date={request.createdAt} number={request.number} />
			<div className={classes.statusContainer}>{status}</div>
			<div className={classes.actionsContainer}>{actions}</div>
		</div>
	);
};
