import constate from "constate";
import { useCallback, useMemo, useState } from "react";
import { List, Map } from "immutable";
import { IPaginatedSearchOptions } from "utils/searchUtils";
import { getIntegrationResourceRoles } from "api/integrationResourceRoles";
import { getIntegrationResources } from "api/integrationResources";
import { useIntegrations } from "hooks/useIntegrations";
import type { ITreeNode } from "components/pages/AccessReviewTemplatePage/components/TreeNode";
import type { IPaginationData } from "../utils/pagination";
import type { TFilterType } from "models/AccessReviewTemplateModel";
import type { AccessReviewTemplateResourceRuleModel } from "models/AccessReviewTemplateResourceRuleModel";
import type { AccessReviewTemplateIntegrationRuleModel } from "models/AccessReviewTemplateIntegrationRuleModel";

type TRootRule = { filterType: TFilterType; integrationIds: List<string> };
type TIntegrationRule = { integrationId: string; filterType: TFilterType; resourceIds: List<string> };
type TIntegrationResourceRule = {
	integrationId: string;
	integrationResourceId: string;
	filterType: TFilterType;
	roleIds: List<string>;
};

type TSelectionState = { isExcluded: boolean; visualState: "partial" | "all" | "none" };

const getIsExcludedFromRule = (filterType: TFilterType, childIds: List<string>, childId: string) => {
	if (filterType === "exclude") {
		return childIds.includes(childId);
	}
	return !childIds.includes(childId);
};

const getSelectionState = (
	path: string[],
	rootRule: TRootRule,
	integrationRules: Map<string, TIntegrationRule>,
	resourceRules: Map<string, Map<string, TIntegrationResourceRule>>
): TSelectionState => {
	if (path.length === 0) {
		return {
			isExcluded: rootRule.filterType === "include",
			visualState:
				rootRule.integrationIds.count() || integrationRules.count() || resourceRules.count()
					? "partial"
					: rootRule.filterType === "exclude"
						? "all"
						: "none"
		};
	}

	const integrationId = path[0];
	const integrationRule = integrationRules.get(integrationId);
	const integrationResourceRules = resourceRules.get(integrationId);
	const isIntegrationExcluded = getIsExcludedFromRule(rootRule.filterType, rootRule.integrationIds, integrationId);

	if (path.length === 1) {
		if (integrationRule || integrationResourceRules?.count()) {
			return { isExcluded: isIntegrationExcluded, visualState: "partial" };
		}
		return { isExcluded: isIntegrationExcluded, visualState: isIntegrationExcluded ? "none" : "all" };
	}

	const resourceId = path[1];
	const resourceRule = integrationResourceRules?.get(resourceId);
	let isResourceExcluded = false;

	if (integrationRule) {
		isResourceExcluded = getIsExcludedFromRule(integrationRule.filterType, integrationRule.resourceIds, resourceId);
	} else {
		isResourceExcluded = isIntegrationExcluded;
	}

	if (path.length === 2) {
		if (resourceRule) {
			return { isExcluded: isResourceExcluded, visualState: "partial" };
		}
		return { isExcluded: isResourceExcluded, visualState: isResourceExcluded ? "none" : "all" };
	}

	const roleId = path[2];
	let isRoleExcluded = false;

	if (resourceRule) {
		isRoleExcluded = getIsExcludedFromRule(resourceRule.filterType, resourceRule.roleIds, roleId);
	} else {
		isRoleExcluded = isResourceExcluded;
	}

	if (path.length === 3) {
		return { isExcluded: isRoleExcluded, visualState: isRoleExcluded ? "none" : "all" };
	}

	return { isExcluded: true, visualState: "none" };
};

const useAccessReviewTemplate = () => {
	const [rootRule, setRootRule] = useState({
		filterType: "exclude" as TFilterType,
		integrationIds: List<string>()
	});

	const [integrationRules, setIntegrationRules] = useState(Map<string, TIntegrationRule>());
	const [resourceRules, setResourceRules] = useState(Map<string, Map<string, TIntegrationResourceRule>>());

	const setInitialState = useCallback(
		(
			filterType: TFilterType,
			integrationIds: string[],
			integrationRuleModels: List<AccessReviewTemplateIntegrationRuleModel>,
			resourceRuleModels: List<AccessReviewTemplateResourceRuleModel>
		) => {
			setRootRule({ filterType, integrationIds: List(integrationIds) });
			setIntegrationRules(Map(integrationRuleModels.map(rule => [rule.integrationId, rule])));
			setResourceRules(
				resourceRuleModels.reduce((final, rule) => {
					if (!final.has(rule.integrationId)) {
						final.set(rule.integrationId, Map<string, TIntegrationResourceRule>());
					}
					return final.setIn([rule.integrationId, rule.integrationResourceId], rule);
				}, Map<string, Map<string, TIntegrationResourceRule>>())
			);
		},
		[]
	);

	const selectIntegration = useCallback((integrationId: string, totalChildrenArray: number[]) => {
		setRootRule(current => {
			const integrationIndex = current.integrationIds.findIndex(id => integrationId === id);
			if (integrationIndex > -1) {
				if (totalChildrenArray.at(0) === 1) {
					return {
						filterType: current.filterType === "exclude" ? "include" : "exclude",
						integrationIds: List<string>()
					};
				} else {
					return {
						filterType: current.filterType,
						integrationIds: current.integrationIds.remove(integrationIndex)
					};
				}
			} else {
				if (current.integrationIds.count() + 1 === totalChildrenArray.at(0)) {
					return {
						filterType: current.filterType === "exclude" ? "include" : "exclude",
						integrationIds: List<string>()
					};
				} else {
					return {
						filterType: current.filterType,
						integrationIds: current.integrationIds.push(integrationId)
					};
				}
			}
		});
		setIntegrationRules(current => current.delete(integrationId));
	}, []);

	const selectResource = useCallback(
		(integrationId: string, resourceId: string, totalChildrenArray: number[]) => {
			if (!integrationRules.has(integrationId)) {
				if (totalChildrenArray.at(1) === 1) {
					selectIntegration(integrationId, totalChildrenArray);
				} else {
					const integrationSelectionState = getSelectionState(
						[integrationId],
						rootRule,
						integrationRules,
						resourceRules
					);
					setIntegrationRules(
						integrationRules.set(integrationId, {
							integrationId,
							filterType: integrationSelectionState.isExcluded ? "include" : "exclude",
							resourceIds: List([resourceId])
						})
					);
				}
			} else {
				const integrationRule = integrationRules.get(integrationId)!;
				const currentResourceIds = integrationRule.resourceIds;
				const resourceIndex = currentResourceIds.findIndex(id => resourceId === id);

				if (resourceIndex > -1) {
					if (integrationRule.resourceIds.count() === 1) {
						setIntegrationRules(integrationRules.delete(integrationId));
					} else {
						setIntegrationRules(
							integrationRules.setIn([integrationId, "resourceIds"], currentResourceIds.remove(resourceIndex))
						);
					}
				} else {
					if (currentResourceIds.count() + 1 === totalChildrenArray.at(1)) {
						selectIntegration(integrationId, totalChildrenArray);
					} else {
						setIntegrationRules(
							integrationRules.setIn([integrationId, "resourceIds"], currentResourceIds.push(resourceId))
						);
					}
				}
			}
			setResourceRules(current => {
				const integrationResourceRules = current.get(integrationId);
				if (!integrationResourceRules || !integrationResourceRules.has(resourceId)) return current;

				if (integrationResourceRules.count() === 1) {
					return current.delete(integrationId);
				} else {
					return current.deleteIn([integrationId, resourceId]);
				}
			});
		},
		[integrationRules, resourceRules, rootRule, selectIntegration]
	);

	const selectRole = useCallback(
		(integrationId: string, integrationResourceId: string, roleId: string, totalChildrenArray: number[]) => {
			if (!resourceRules.get(integrationId)?.has(integrationResourceId)) {
				if (totalChildrenArray.at(2) === 1) {
					selectResource(integrationId, integrationResourceId, totalChildrenArray);
				} else {
					const resourceSelectionState = getSelectionState(
						[integrationId, integrationResourceId],
						rootRule,
						integrationRules,
						resourceRules
					);

					setResourceRules(
						resourceRules.setIn([integrationId, integrationResourceId], {
							integrationId,
							integrationResourceId,
							filterType: resourceSelectionState.isExcluded ? "include" : "exclude",
							roleIds: List([roleId])
						})
					);
				}
			} else {
				const integrationResourceRules = resourceRules.get(integrationId)!;
				const resourceRule = integrationResourceRules.get(integrationResourceId)!;
				const currentRoleIds = resourceRule.roleIds;
				const roleIndex = currentRoleIds.findIndex(id => roleId === id);
				if (roleIndex > -1) {
					if (currentRoleIds.count() === 1) {
						if (integrationResourceRules.count() === 1) {
							setResourceRules(resourceRules.delete(integrationId));
						} else {
							setResourceRules(resourceRules.deleteIn([integrationId, integrationResourceId]));
						}
					} else {
						setResourceRules(
							resourceRules.setIn([integrationId, integrationResourceId, "roleIds"], currentRoleIds.remove(roleIndex))
						);
					}
				} else {
					if (currentRoleIds.count() + 1 === totalChildrenArray.at(2)) {
						selectResource(integrationId, integrationResourceId, totalChildrenArray);
					} else {
						setResourceRules(
							resourceRules.setIn([integrationId, integrationResourceId, "roleIds"], currentRoleIds.push(roleId))
						);
					}
				}
			}
		},
		[integrationRules, resourceRules, rootRule, selectResource]
	);

	const selectNode = useCallback(
		(path: string[], totalChildrenArray: number[]) => {
			if (path.length === 3) {
				selectRole(path[0], path[1], path[2], totalChildrenArray);
			} else if (path.length === 2) {
				selectResource(path[0], path[1], totalChildrenArray);
			} else if (path.length === 1) {
				selectIntegration(path[0], totalChildrenArray);
				setResourceRules(current => current.delete(path[0]));
			} else {
				setRootRule(current => ({
					integrationIds: List<string>(),
					filterType: current.filterType === "exclude" ? "include" : "exclude"
				}));
				setIntegrationRules(Map<string, TIntegrationRule>());
				setResourceRules(Map<string, Map<string, TIntegrationResourceRule>>());
			}
		},
		[selectIntegration, selectResource, selectRole]
	);

	const searchRoles = useCallback(async (path: string[], paginationOptions: IPaginatedSearchOptions) => {
		const integrationId = path[0];
		const resourceId = path[1];

		const { pagination, result } = await getIntegrationResourceRoles(resourceId, paginationOptions);
		return {
			pagination,
			result: result.map(({ id: roleId, name }) => {
				return {
					path: [integrationId, resourceId, roleId],
					id: `${integrationId}.${resourceId}.${roleId}`,
					label: name,
					isLeaf: true,
					searchable: true,
					totalChildren: 0
				};
			}) as List<ITreeNode>
		};
	}, []);

	const searchResources = useCallback(async (path: string[], paginationOptions: IPaginatedSearchOptions) => {
		const integrationId = path[0];

		const { pagination, result } = await getIntegrationResources(integrationId, paginationOptions);
		return {
			pagination,
			result: result.map(({ id: resourceId, displayName, rolesCount }) => ({
				path: [integrationId, resourceId],
				id: `${integrationId}.${resourceId}`,
				label: displayName,
				isLeaf: false,
				searchable: true,
				totalChildren: rolesCount
			})) as List<ITreeNode>
		};
	}, []);

	const integrationMap = useIntegrations();
	const integrations = useMemo(() => List(integrationMap?.values() || []), [integrationMap]);

	const searchIntegrations = useCallback(
		async (paginationOptions: IPaginatedSearchOptions) => {
			return {
				pagination: {
					perPage: integrations.count(),
					page: 1,
					totalPages: 1,
					totalResults: integrations.count()
				} as IPaginationData,
				result: integrations.map(({ id: integrationId, name, resourcesCount }) => ({
					path: [integrationId],
					id: integrationId,
					label: name,
					isLeaf: false,
					totalChildren: resourcesCount
				})) as List<ITreeNode>
			};
		},
		[integrations]
	);

	const searchNodeChildren = useCallback(
		async (path: string[], paginationOptions: IPaginatedSearchOptions) => {
			if (path.length === 2) {
				return searchRoles(path, paginationOptions);
			} else if (path.length === 1) {
				return searchResources(path, paginationOptions);
			} else {
				return searchIntegrations(paginationOptions);
			}
		},
		[searchIntegrations, searchResources, searchRoles]
	);

	const getNodeSelectionState = useCallback(
		(path: string[]) => getSelectionState(path, rootRule, integrationRules, resourceRules),
		[integrationRules, resourceRules, rootRule]
	);

	return {
		state: {
			integrationsFilterType: rootRule.filterType,
			integrationIds: rootRule.integrationIds,
			integrationRules,
			resourceRules
		},
		actions: { selectNode, getNodeSelectionState, searchNodeChildren, setInitialState }
	};
};

export const [AccessReviewTemplateProvider, useAccessReviewTemplateContext, useNode] = constate(
	useAccessReviewTemplate,
	value => value,
	({ actions: { selectNode, searchNodeChildren, getNodeSelectionState } }) => ({
		selectNode,
		getNodeSelectionState,
		searchNodeChildren
	})
);
