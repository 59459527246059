import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	content: {
		alignItems: "center",
		display: "flex",
		gap: "1rem",
		width: "100%"
	},
	icon: {
		fontSize: "var(--icon-medium-size)"
	},
	roleLabel: {
		overflow: "hidden",
		whiteSpace: "nowrap",
		textOverflow: "ellipsis"
	}
});
