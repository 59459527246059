import { useMemo, useEffect, useCallback } from "react";
import { Map, List } from "immutable";
import { useNewRequestFormContext } from "components/pages/NewRequestPage/newRequestFormContext";
import {
	useNewRequestBundles,
	useNewRequestGrantMethods,
	useNewRequestIntegrationResourceRoles,
	useNewRequestIntegrationResources,
	useNewRequestIntegrations
} from "components/pages/NewRequestPage/newRequestDataContext";
import { IntegrationResourceRoleModel } from "models/IntegrationResourceRoleModel";
import type { IntegrationModel } from "models/IntegrationModel";
import type { BundleModel } from "models/BundleModel";
import type { TCartItem } from "components/common/RequestCart";

export const useAutoSelectGrantMethods = () => {
	const {
		state: { requestTargets, receiverUser },
		actions: { updateTarget }
	} = useNewRequestFormContext();
	const { data: grantMethodsData, fetch: fetchGrantMethods } = useNewRequestGrantMethods();

	// Fetch missing grant methods
	useEffect(() => {
		if (!receiverUser) return;
		requestTargets.forEach(target => {
			if (target.type === "role" && !target.grantMethodId && !grantMethodsData.has(target.id)) {
				fetchGrantMethods({ roleId: target.id, userId: receiverUser.id });
			}
		});
	}, [fetchGrantMethods, grantMethodsData, receiverUser, requestTargets]);

	// Set grant methods where missing
	useEffect(() => {
		requestTargets.forEach(target => {
			if (target.type === "role" && !target.grantMethodId && grantMethodsData.has(target.id)) {
				const roleGrantMethods = grantMethodsData.get(target.id)!.grantMethods;
				const defaultGrantMethod = roleGrantMethods.get(target.id) || roleGrantMethods.first();
				if (!defaultGrantMethod) {
					console.warn(`No grant method found for role ${target.id}`);
					return;
				}
				updateTarget(target.id, { grantMethodId: defaultGrantMethod.id });
			}
		});
	}, [grantMethodsData, requestTargets, updateTarget]);
};

const useFetchMissingTargets = () => {
	const {
		state: { requestTargets, receiverUser }
	} = useNewRequestFormContext();
	const { allData: bundlesData, fetch: fetchBundles } = useNewRequestBundles();
	const { allData: integrationsData, fetch: fetchIntegrations } = useNewRequestIntegrations();
	const { allData: resources, fetch: fetchResources } = useNewRequestIntegrationResources();
	const { allData: roles, fetch: fetchRoles } = useNewRequestIntegrationResourceRoles();

	// Fetch missing data
	useEffect(() => {
		if (!receiverUser) return;
		requestTargets.forEach(target => {
			if (target.type === "bundle" && !bundlesData.bundles.has(target.id)) {
				fetchBundles({ userId: receiverUser.id });
			} else if (target.type === "role") {
				if (!integrationsData.integrations.has(target.integrationId)) {
					fetchIntegrations({ userId: receiverUser.id });
				}
				if (!resources.has(target.integrationId)) {
					fetchResources({ integrationId: target.integrationId, userId: receiverUser.id });
				}
				if (!roles.has(target.resourceId)) {
					fetchRoles({ integrationResourceId: target.resourceId, userId: receiverUser.id });
				}
			}
		});
	}, [
		bundlesData,
		fetchBundles,
		fetchIntegrations,
		fetchResources,
		fetchRoles,
		integrationsData,
		receiverUser,
		requestTargets,
		resources,
		roles
	]);

	return useMemo(
		() => ({ bundlesData, integrationsData, resources, roles }),
		[bundlesData, integrationsData, resources, roles]
	);
};

export const useRequestCartData = () => {
	const {
		state: { requestTargets, receiverUser },
		actions: { updateTarget, removeTarget }
	} = useNewRequestFormContext();
	const { data: grantMethodsData, fetch: fetchGrantMethods } = useNewRequestGrantMethods();
	const { bundlesData, integrationsData, resources, roles } = useFetchMissingTargets();

	const cart = useMemo(() => {
		return requestTargets.reduce((acc, target) => {
			if (target.type === "bundle") {
				const bundle = bundlesData.bundles.get(target.id);
				if (!bundle) return acc;

				return acc.set(target.id, bundle);
			}
			const integration = integrationsData.integrations.get(target.integrationId);
			if (!integration) return acc;
			return acc.set(target.integrationId, integration);
		}, Map<string, IntegrationModel | BundleModel>());
	}, [requestTargets, bundlesData, integrationsData]);

	const cartItems: Map<string, List<TCartItem>> = useMemo(() => {
		return requestTargets.reduce((acc, target) => {
			if (target.type === "bundle") {
				const bundle = bundlesData.bundles.get(target.id);
				if (!bundle) return acc;
				const cartItems = bundle?.bundleItems.reduce((acc, item) => {
					const role = item.integrationResourceRole;
					const resource = item.integrationResourceRole.integrationResource;
					if (!role || !resource) return acc;
					return acc.push({ resource, role, grantMethodRoleId: null });
				}, List<TCartItem>());
				return acc.set(target.id, cartItems);
			}
			const integration = integrationsData.integrations.get(target.integrationId);
			const resource = resources.get(target.integrationId)?.integrationResources.get(target.resourceId);
			const role = roles.get(target.resourceId)?.integrationResourceRoles.get(target.id);
			if (!integration || !resource || !role) return acc;

			const grantMethodRoleId = target.grantMethodId || null;
			const cartItem: TCartItem = { resource, role, grantMethodRoleId };
			const currentIntegrationList = acc.get(target.integrationId) || List();
			return acc.set(target.integrationId, currentIntegrationList.push(cartItem));
		}, Map<string, List<TCartItem>>());
	}, [requestTargets, integrationsData.integrations, resources, roles, bundlesData.bundles]);

	const changeGrantMethod = useCallback(
		(roleId: string, grantMethodRoleId: string) => {
			updateTarget(roleId, { grantMethodId: grantMethodRoleId });
		},
		[updateTarget]
	);

	const getFetchRoleGrantMethods = useCallback(
		(roleId: string) => {
			return (page: number) => {
				if (!receiverUser) return Promise.resolve();
				return fetchGrantMethods({ roleId, userId: receiverUser.id, page });
			};
		},
		[fetchGrantMethods, receiverUser]
	);

	const getRoleTotalGrantMethods = useCallback(
		(roleId: string) => {
			if (!grantMethodsData.has(roleId)) return 0;
			return grantMethodsData.get(roleId)!.totalAmount;
		},
		[grantMethodsData]
	);

	const removeAppFromCart = useCallback(
		(appId: string) => {
			requestTargets.forEach(target => {
				if (
					(target.type === "bundle" && target.id === appId) ||
					(target.type === "role" && target.integrationId === appId)
				) {
					removeTarget(target.id);
				}
			});
		},
		[removeTarget, requestTargets]
	);

	const removeResource = useCallback(
		(resourceId: string) => {
			requestTargets.forEach(target => {
				if (target.type === "role" && target.resourceId === resourceId) {
					removeTarget(target.id);
				}
			});
		},
		[removeTarget, requestTargets]
	);

	const removeRole = useCallback(
		(roleId: string) => {
			removeTarget(roleId);
		},
		[removeTarget]
	);

	const roleToGrantMethods = useMemo(() => {
		return grantMethodsData.reduce((acc, queryData, roleId) => {
			return acc.set(roleId, queryData.grantMethods.valueSeq().toList());
		}, Map<string, List<IntegrationResourceRoleModel>>());
	}, [grantMethodsData]);

	return useMemo(
		() => ({
			cart,
			cartItems,
			changeGrantMethod,
			getFetchRoleGrantMethods,
			getRoleTotalGrantMethods,
			removeAppFromCart,
			removeResource,
			removeRole,
			roleToGrantMethods
		}),
		[
			cart,
			cartItems,
			changeGrantMethod,
			getFetchRoleGrantMethods,
			getRoleTotalGrantMethods,
			removeAppFromCart,
			removeResource,
			removeRole,
			roleToGrantMethods
		]
	);
};
