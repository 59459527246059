import { Map } from "immutable";
import { ApprovalFlowsWebhookModel } from "models/ApprovalFlowsWebhookModel";
import { apiReq } from "utils/apiReq";

export interface ICreateApprovalFlowsWebhookParams {
	headers: Record<string, string>;
	url: string;
	name: string;
	additionalParams?: Record<string, unknown>;
}
export type TUpdateApprovalFlowsWebhookParams = Partial<ICreateApprovalFlowsWebhookParams> & { enabled?: true };

export const getApprovalFlowsWebhooks = async () => {
	const { data } = await apiReq("GET", "/v1/approvalFlowsWebhooks");
	const parsedData = data as Record<string, unknown>[];

	return Map<string, ApprovalFlowsWebhookModel>(
		parsedData.map(webhook => [webhook.id as string, ApprovalFlowsWebhookModel.fromServerData(webhook)])
	);
};

export const createApprovalFlowsWebhook = async (params: ICreateApprovalFlowsWebhookParams) => {
	const { data } = await apiReq("POST", "/v1/approvalFlowsWebhooks", params);

	return ApprovalFlowsWebhookModel.fromServerData(data);
};

export const deleteApprovalFlowsWebhook = async (id: string) => {
	const { data } = await apiReq("DELETE", `/v1/approvalFlowsWebhooks/${id}`);

	return data.ok;
};

export const updateApprovalFlowsWebhook = async (
	id: string,
	params: Partial<ICreateApprovalFlowsWebhookParams> | { enabled: true }
) => {
	const { data } = await apiReq("PUT", `/v1/approvalFlowsWebhooks/${id}`, params);

	return ApprovalFlowsWebhookModel.fromServerData(data);
};
