import { EventEmitter } from "events";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { PageTemplate } from "components/templates/PageTemplate";
import { Button } from "components/ui/Button";
import { useUser } from "hooks/useUser";
import { exportTicketsToCSV, getTickets, TSearchTicketsParams } from "api/tickets";
import { TicketModel } from "models/TicketModel";
import { DESC, usePagination } from "hooks/usePagination";
import { useLoadingState } from "hooks/useLoadingState";
import { useTicketUpdatesContext } from "context/ticketUpdatesContext";
import { ErrorModal } from "components/ui/ErrorModal";
import useErrorModalState from "hooks/useErrorModalState";
import { TicketFiltersModal } from "components/common/TicketFilters";
import { FilterIcon } from "components/ui/Icons/FilterIcon";
import { ExportIcon } from "components/ui/Icons/ExportIcon";
import { IPaginatedSearchOptions } from "utils/searchUtils";
import { SortTableProvider, useSortTableContext } from "context/sortingTableContext";
import { RequestDetails } from "components/common/RequestDetails";
import { RequestsLogList } from "./RequestsLogList";
import { useStyles } from "./styles";

const PER_PAGE = 30;

const RequestLogPageContent: FC = ({ className }) => {
	const { t } = useTranslation();
	const classes = useStyles();
	const emitter = useMemo(() => new EventEmitter(), []);
	const { user } = useUser();
	const [filtersOpen, setFiltersOpen] = useState(false);

	const { isLoading, withLoader } = useLoadingState();
	const [ticketFilters, setTicketFilters] = useState<TSearchTicketsParams>({});
	const searchTickets = useCallback(
		(paginationOptions: IPaginatedSearchOptions) => getTickets(paginationOptions, ticketFilters),
		[ticketFilters]
	);
	const exportTickets = useCallback(() => exportTicketsToCSV(ticketFilters), [ticketFilters]);
	const {
		state: { sortFields, sortOrder }
	} = useSortTableContext();
	const {
		getPage,
		totalResults,
		itemsForVirtualTable: tickets,
		setItem
	} = usePagination(searchTickets, PER_PAGE, sortOrder, sortFields);
	const { subscribeTicketUpdates, unsubscribeTicketUpdates } = useTicketUpdatesContext();

	const {
		errorModalSetError,
		errorModalClose: closeErrorModal,
		errorModalError,
		errorModalIsOpen
	} = useErrorModalState();

	const fetchPage = useCallback(
		(page: number) => {
			withLoader(getPage(page));
		},
		[getPage, withLoader]
	);

	const onTicketUpdated = useCallback(
		(ticket: TicketModel) => {
			setItem(ticket);
		},
		[setItem]
	);

	useEffect(() => {
		subscribeTicketUpdates && subscribeTicketUpdates("requestsLog", onTicketUpdated);
		return () => {
			unsubscribeTicketUpdates("requestsLog");
		};
	}, [onTicketUpdated, subscribeTicketUpdates, unsubscribeTicketUpdates]);

	const openFiltersModal = useCallback(() => setFiltersOpen(true), []);
	const closeFiltersModal = useCallback(() => setFiltersOpen(false), []);

	return (
		<PageTemplate className={classNames(classes.requestsLogPage, className)}>
			<RequestDetails resetEventEmitter={emitter} />
			<ErrorModal error={errorModalError} isOpen={errorModalIsOpen} onClose={closeErrorModal} />
			<TicketFiltersModal
				isOpen={filtersOpen}
				onClose={closeFiltersModal}
				onFiltersChange={setTicketFilters}
				ticketFilters={ticketFilters}
			/>
			<PageTemplate.Title className={classes.headerContainer}>
				{t("pages.requestsLog.title")}
				<div className={classes.listActions}>
					{user?.isAdmin && (
						<Button size="medium" variant="secondary" onClick={exportTickets} prefix={<ExportIcon />}>
							{t("buttons.export")}
						</Button>
					)}
					<Button size="medium" variant="secondary" onClick={openFiltersModal} prefix={<FilterIcon />}>
						{t("buttons.filter")}
					</Button>
				</div>
			</PageTemplate.Title>
			<PageTemplate.Content>
				<div className={classes.tableContainer}>
					<RequestsLogList
						getPage={fetchPage}
						perPage={PER_PAGE}
						isLoading={isLoading}
						tickets={tickets}
						totalTickets={totalResults}
						errorModalSetError={errorModalSetError}
					/>
				</div>
			</PageTemplate.Content>
		</PageTemplate>
	);
};
export const RequestsLogPage: FC = props => (
	<SortTableProvider defaultSortField="ticketNumber" defaultSortOrder={DESC} secondaryField="ticketNumber">
		<RequestLogPageContent {...props} />
	</SortTableProvider>
);
