import { createUseStyles } from "react-jss";

export const ROLE_BAR_ROLE_MIN_WIDTH_PX = 164;

export const useStyles = createUseStyles({
	container: {
		alignItems: "center",
		display: "flex",
		gap: "8px",
		justifyContent: "space-between",
		flexGrow: 1,
		minWidth: `${ROLE_BAR_ROLE_MIN_WIDTH_PX}px`
	},
	innerContainer: {
		alignItems: "center",
		display: "flex",
		gap: "8px",
		minWidth: 0
	},
	tooltipContainer: {
		display: "flex",
		flexDirection: "column",
		gap: "20px",
		padding: "12px"
	},
	indirectHeader: {
		alignItems: "center",
		display: "flex",
		gap: "var(--spacing-x2, 8px)"
	}
});
