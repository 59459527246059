import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Table } from "components/ui/Table";
import { Typography } from "components/ui/New/Typography";
import { Section } from "components/ui/Section";
import { Chip } from "components/ui/Chip";
import { useApprovalFlowsWebhooksMap } from "hooks/useApprovalFlowsWebhooksMap";
import { useLoadingState } from "hooks/useLoadingState";
import { LoadingOverlay } from "components/ui/LoadingOverlay";
import { ConnectedWorkflowsModal } from "../ConnectedWorkflowsModal";
import { WorkflowsWebhookRow } from "./WorkflowWebhooksTableRow";
import { useStyles } from "./styles";
import type { ApprovalFlowsWebhookModel } from "models/ApprovalFlowsWebhookModel";
import type { List } from "immutable";

const WorkflowsWebhooksTableHeaders = React.memo(function TableHeaders() {
	const { t } = useTranslation("translation", { keyPrefix: "pages.workflowsWebhooks.table.headers" });
	return (
		<Table.Row>
			<Table.Header>
				<Typography variant="body_sb">{t("name")}</Typography>
			</Table.Header>
			<Table.Header>
				<Typography variant="body_sb">{t("url")}</Typography>
			</Table.Header>
			<Table.Header>
				<Typography variant="body_sb">{t("headers")}</Typography>
			</Table.Header>
			<Table.Header>
				<Typography variant="body_sb">{t("additionalParams")}</Typography>
			</Table.Header>
			<Table.Header></Table.Header>
		</Table.Row>
	);
});

const WorkflowsWebhooksTableSectionTitle = ({ size }: { size: number }) => {
	const { t } = useTranslation("translation", { keyPrefix: "pages.workflowsWebhooks.table" });
	return (
		<>
			<Typography variant="body_sb" noWrap>
				{t("sectionTitle")}
			</Typography>
			<Chip size="tiny">{size}</Chip>
		</>
	);
};

export const WorkflowsWebhooksTableSection: FC<{
	create: boolean;
	openCreate: () => void;
	closeCreate: () => void;
}> = ({ create, closeCreate, openCreate }) => {
	const [connectedWorkflowIds, setConnectedWorkflowIds] = useState<List<string>>();
	const webhooks = useApprovalFlowsWebhooksMap();
	const title = useMemo(() => <WorkflowsWebhooksTableSectionTitle size={webhooks?.size ?? 0} />, [webhooks?.size]);
	const { isLoading, withLoader } = useLoadingState();
	const classes = useStyles();

	useEffect(() => {
		if (webhooks?.size === 0) {
			openCreate();
		}
	}, [isLoading, openCreate, webhooks]);

	const openWebhookConnectedWorkflowsModal = useCallback((webhook: ApprovalFlowsWebhookModel) => {
		setConnectedWorkflowIds(webhook.approvalAlgorithmIds);
	}, []);

	const closeWebhookConnectedWorkflowsModal = useCallback(() => {
		setConnectedWorkflowIds(undefined);
	}, []);

	return (
		<Section fullHeight title={title} className={classes.tableSection}>
			<ConnectedWorkflowsModal workflowIds={connectedWorkflowIds} closeModal={closeWebhookConnectedWorkflowsModal} />
			<div className={classes.tableContainer}>
				<Table gridColumns="4fr 5fr 4fr 4fr 3fr" loading={isLoading} className={classes.table}>
					<LoadingOverlay isOpen={isLoading} />
					<WorkflowsWebhooksTableHeaders />
					{create && <WorkflowsWebhookRow create closeCreate={closeCreate} withLoader={withLoader} />}
					{webhooks
						?.valueSeq()
						.map(webhook => (
							<WorkflowsWebhookRow
								key={webhook.id}
								openWebhookConnectedWorkflowsModal={openWebhookConnectedWorkflowsModal}
								webhook={webhook}
								withLoader={withLoader}
							/>
						))
						.toArray()}
				</Table>
			</div>
		</Section>
	);
};
