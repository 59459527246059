import { createUseStyles } from "react-jss";
import { BP_MEDIA_QUERIES } from "utils/breakpoints";

export const BREAKPOINT_TO_PER_ROW = new Map([
	[6, 2],
	[5, 2],
	[4, 3],
	[3, 4],
	[2, 5],
	[1, 6]
]);

export const useStyles = createUseStyles({
	gridContainer: {
		display: "grid",
		gap: "var(--spacing-x4)",
		gridTemplateColumns: `repeat(${BREAKPOINT_TO_PER_ROW.get(5)}, 1fr)`,
		width: "100%",
		[BP_MEDIA_QUERIES.FOURTH]: {
			gridTemplateColumns: `repeat(${BREAKPOINT_TO_PER_ROW.get(4)}, 1fr)`
		},
		[BP_MEDIA_QUERIES.THIRD]: {
			gridTemplateColumns: `repeat(${BREAKPOINT_TO_PER_ROW.get(3)}, 1fr)`
		},
		[BP_MEDIA_QUERIES.SECOND]: {
			gridTemplateColumns: `repeat(${BREAKPOINT_TO_PER_ROW.get(2)}, 1fr)`
		},
		[BP_MEDIA_QUERIES.FIRST]: {
			gridTemplateColumns: `repeat(${BREAKPOINT_TO_PER_ROW.get(1)}, 1fr)`
		},
		"& > *": {
			alignSelf: "start"
		}
	},
	gridItem: {
		minWidth: 0,
		order: (props?: { order?: number }) => props?.order || 0,
		"&$expandedGridItem": {
			gridColumn: "1 / -1",
			order: (props?: { order?: number }) => (props?.order || 0) + 1
		}
	},
	ghostGridItem: { visibility: "hidden" },
	expandedGridItem: {},
	notDisplayRow: { display: "none" },
	emptyState: {
		alignItems: "center",
		display: "flex",
		gridColumn: "1 / -1",
		height: "240px",
		justifyContent: "center"
	}
});
