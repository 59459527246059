import constate from "constate";
import { Map } from "immutable";
import { TicketModel } from "models/TicketModel";
import { useCallback, useState } from "react";

type TTicketUpdater = (ticket: TicketModel) => void;

const useTicketNotifications = () => {
	const [ticketUpdaters, setTicketUpdaters] = useState(Map<string, TTicketUpdater>());

	const subscribeTicketUpdates = useCallback((name: string, updater: TTicketUpdater) => {
		setTicketUpdaters(curr => curr.set(name, updater));
	}, []);

	const unsubscribeTicketUpdates = useCallback((name: string) => {
		setTicketUpdaters(curr => curr.remove(name));
	}, []);

	const notifyTicketUpdate = useCallback(
		(ticket: TicketModel | null) => {
			if (ticket) {
				ticketUpdaters.valueSeq().forEach(updater => updater && updater(ticket));
			}
		},
		[ticketUpdaters]
	);

	return {
		notifyTicketUpdate,
		unsubscribeTicketUpdates,
		subscribeTicketUpdates
	};
};

export const [TicketUpdatesProvider, useTicketUpdatesContext] = constate(useTicketNotifications);
