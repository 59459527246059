import constate from "constate";
import { Map, Record } from "immutable";
import { useCallback, useMemo, useState } from "react";
import { getUser, getUsers } from "api/users";
import { UserModel } from "models/UserModel";
import { useOpenGlobalErrorModal } from "hooks/useGlobalError";
import { useFetchedState } from "hooks/useFetchedState";

type UserState = { loading: boolean; data: UserModel | null; hadError: boolean };
const UserStateRecord = Record<UserState>({ loading: false, data: null, hadError: false });

const useUsers = () => {
	const { data: users, setData: setUsers, loadData: loadUsers } = useFetchedState(getUsers);
	const [fullUsersState, setFullUsersState] = useState(Map<string, Record<UserState>>());
	const openGlobalErrorModal = useOpenGlobalErrorModal();

	const setUser = useCallback(
		(user: UserModel) => {
			setFullUsersState(current =>
				current.set(user.id, (current.get(user.id) || UserStateRecord()).set("loading", false).set("data", user))
			);
			setUsers(current => current?.set(user.id, user) || current);
		},
		[setUsers]
	);

	const loadUser: (id: string) => Promise<UserModel | null> = useCallback(
		async (id: string) => {
			try {
				const state = fullUsersState.get(id) || UserStateRecord();
				if (!state.get("loading")) {
					setFullUsersState(current => current.set(id, state.set("loading", true)));
					const fullUser = await getUser(id);
					setUser(fullUser);
					return fullUser;
				}
				return state.get("data");
			} catch (err) {
				openGlobalErrorModal(err as Error);
				setFullUsersState(current => current.set(id, current.get(id)!.set("loading", false).set("hadError", true)));
				return null;
			}
		},
		[openGlobalErrorModal, fullUsersState, setUser]
	);

	const fullUsers: Map<string, UserModel> = useMemo(
		() => fullUsersState.map(value => value.get("data")).filter(value => value) as Map<string, UserModel>,
		[fullUsersState]
	);

	return { state: { users, fullUsers, fullUsersState }, actions: { loadUsers, loadUser, setUser } };
};

export const [UsersProvider, useUsersContext] = constate(useUsers);
