import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	columnLayout: {
		width: "100%",
		height: "100%",
		top: "0",
		left: "0",
		display: "flex",
		flexDirection: "column",
		overflow: "hidden",
		contain: "strict",
		position: "absolute"
	},
	rowLayout: {
		width: "100%",
		height: "100%",
		display: "flex",
		flexDirection: "row",
		overflow: "hidden",
		contain: "strict",
		backgroundColor: "var(--nav-background-color)"
	},
	overlays: {
		zIndex: "200",
		position: "absolute",
		top: "0",
		left: "0",
		width: "100%",
		height: "100%",
		pointerEvents: "none",
		"&:empty": {
			display: "none"
		},
		"& > *": {
			pointerEvents: "initial"
		}
	},
	sideNav: {
		flexShrink: "0"
	},
	pages: {
		backgroundColor: "var(--color-white)",
		zIndex: "50",
		width: "100%",
		position: "relative",
		overflow: "hidden",
		contain: "strict",
		flexShrink: "1",
		flexGrow: "0",
		willChange: "width",
		"$sideNav + &": {
			borderRadius: "12px 0 0 0"
		}
	}
});
