import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	statusTooltip: {
		justifyContent: "center",
		display: "flex",
		alignItems: "center",
		gap: "4px"
	}
});
