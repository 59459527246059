import { createUseStyles } from "react-jss";

type TStyleProps = {
	color?: string;
	size?: number;
};

const getSize = ({ size }: TStyleProps) => {
	return size ? `${size}px` : "1em";
};

export const useStyles = createUseStyles({
	icon: {
		color: ({ color }: TStyleProps) => color || "currentColor",
		fill: "currentColor",
		height: "1em",
		width: "1em",
		minWidth: "1em",
		fontSize: "var(--icon-medium-size)",
		display: "inline-flex",
		verticalAlign: "text-top",
		"& > svg": {
			height: "100%",
			width: "100%"
		},
		"&$customSize": {
			height: getSize,
			width: getSize,
			fontSize: getSize
		},
		"&$small": {
			fontSize: "var(--icon-small-size)"
		},
		"&$medium": {
			fontSize: "var(--icon-medium-size)"
		},
		"&$large": {
			fontSize: "var(--icon-large-size)"
		},
		"&$xl": {
			fontSize: "var(--icon-xl-size)"
		},
		"&$border": {
			borderRadius: "var(--icon-logo-border-radius)",
			border: "var(--icon-logo-border)"
		},
		"&$clickable": {
			cursor: "pointer",
			transition: "opacity 0.1s, transform 0.1s",

			"&:hover": {
				opacity: 0.6,
				transform: "scale(1.1)"
			}
		},
		"&$disabled": {
			pointerEvents: "none",
			color: "var(--color-grey-disabled)"
		}
	},
	small: {},
	medium: {},
	large: {},
	xl: {},
	customSize: {},
	border: {},
	clickable: {},
	disabled: {}
});
