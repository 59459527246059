import classNames from "classnames";
import React, { useCallback, useMemo, useState } from "react";
import { TicketExtraOptionsButton } from "components/common/TicketExtraOptionsButton";
import { Avatar } from "components/common/Avatar";
import { respondToTicket, adminRespondToTicket } from "api/tickets";
import { Button } from "components/ui/Button";
import { GrantedIcon } from "components/ui/Icons/GrantedIcon";
import { CloseIcon } from "components/ui/Icons/CloseIcon";
import { TextAreaInput } from "components/ui/TextAreaInput";
import { useLoadingState } from "hooks/useLoadingState";
import { TicketModel } from "models/TicketModel";
import { useTranslation } from "react-i18next";
import { usePageContext } from "context/pageContext";
import { useUser } from "hooks/useUser";
import { useOpenGlobalErrorModal } from "hooks/useGlobalError";
import { IsNullError } from "utils/errors/isNullError";
import { removeRedundantSpaces } from "utils/removeRedundantSpaces";
import { getCommentValidators } from "utils/validationUtils";
import { useStyles } from "./styles";

interface IProps {
	addComment: (comment: string) => Promise<void>;
	ticket: TicketModel;
	reloadTicket: () => Promise<TicketModel | null>;
}

export const AddTicketComment: FC<IProps> = ({ addComment, ticket, reloadTicket }) => {
	const { t } = useTranslation();
	const classes = useStyles();
	const [comment, setComment] = useState("");
	const { user } = useUser();
	const { scrollableId: pageId } = usePageContext();
	const { isLoading: respondIsLoading, withLoader: withRespondLoader } = useLoadingState();
	const { isLoading: postIsLoading, withLoader: withPostLoading } = useLoadingState();

	const openGlobalErrorModal = useOpenGlobalErrorModal();

	const allowAdminApprove = useMemo(() => {
		return user?.isAdmin && pageId === "RequestsLog";
	}, [pageId, user]);

	const isApprover = useMemo(() => {
		if (!user?.id) {
			return false;
		} else {
			if (!ticket) {
				return false;
			}
			if (!ticket.approvalRequests) {
				openGlobalErrorModal(
					IsNullError.from({
						location: "isApprover",
						parentObject: {
							name: "ticket",
							value: ticket.toJS()
						},
						requestedProperty: "approvalRequests"
					})
				);
				return false;
			}
			return (
				!ticket.ticketApprovalResponses?.some(response => response.userId === user.id) &&
				ticket.approvalRequests.some(
					request => request.approvalEntities?.some(entity => entity.approverIds.includes(user.id)) || false
				)
			);
		}
	}, [user?.id, ticket, openGlobalErrorModal]);

	const hasActions = useMemo(
		() => ticket && (isApprover || allowAdminApprove) && ticket.status === "waitingForApproval",
		[isApprover, ticket, allowAdminApprove]
	);

	const updateApprovalStatus = useCallback(
		async (approved: boolean) => {
			if (!hasActions) return;
			const respondAction = allowAdminApprove ? adminRespondToTicket : respondToTicket;
			await withRespondLoader(respondAction(ticket.id, approved));
			await reloadTicket();
		},
		[hasActions, allowAdminApprove, withRespondLoader, ticket.id, reloadTicket]
	);

	const withComment = useMemo(() => comment.trim().length > 0, [comment]);
	const commentValidators = useMemo(() => getCommentValidators(t("common.addComment.comment")), [t]);

	const isValid = useMemo(() => {
		return !commentValidators.some(validate => validate(comment));
	}, [comment, commentValidators]);

	const postComment = useCallback(async () => {
		if (!isValid) return;
		await withPostLoading(addComment(removeRedundantSpaces(comment)));
		setComment("");
	}, [addComment, comment, isValid, withPostLoading]);

	const setApproved = useCallback(async () => {
		updateApprovalStatus(true);
		if (withComment) {
			postComment();
		}
	}, [postComment, updateApprovalStatus, withComment]);

	const setDeclined = useCallback(async () => {
		updateApprovalStatus(false);
		if (withComment) {
			postComment();
		}
	}, [postComment, updateApprovalStatus, withComment]);

	return (
		<div className={classes.container}>
			<Avatar size="large" user={user || undefined} />
			<div className={classes.body}>
				<div>
					<TextAreaInput
						autoResize
						rounded
						className={classes.textArea}
						inputClassName={classes.textareaInput}
						onValueChange={setComment}
						placeholder={t("common.addComment.placeholder")}
						value={comment}
						validators={commentValidators}
					/>
				</div>
				<div className={classes.actions}>
					<Button
						size="medium"
						className={classNames(classes.postButton, classes.actionsButton)}
						loading={postIsLoading}
						disabled={postIsLoading || !isValid}
						onClick={postComment}>
						{t("buttons.post")}
					</Button>
					{hasActions && (
						<>
							<Button
								variant="secondary"
								size="medium"
								loading={respondIsLoading || postIsLoading}
								disabled={respondIsLoading || postIsLoading}
								className={classNames(classes.actionsApprove, classes.actionsButton)}
								onClick={setApproved}
								prefix={<GrantedIcon />}
								tooltip={allowAdminApprove ? t("common.ticketActions.adminApprove") : undefined}>
								{t("common.ticketSidebar.approve")}
								{withComment && ` & ${t("buttons.post")}`}
							</Button>
							<Button
								variant="secondary"
								size="medium"
								loading={respondIsLoading || postIsLoading}
								disabled={respondIsLoading || postIsLoading}
								className={classNames(classes.actionsDecline, classes.actionsButton)}
								onClick={setDeclined}
								prefix={<CloseIcon />}
								tooltip={allowAdminApprove ? t("common.ticketActions.adminDecline") : undefined}>
								{t("common.ticketSidebar.decline")}
								{withComment && ` & ${t("buttons.post")}`}
							</Button>
							{isApprover && <TicketExtraOptionsButton ticket={ticket} size="medium" />}
						</>
					)}
				</div>
			</div>
		</div>
	);
};
