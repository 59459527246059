import classNames from "classnames";
import React, { useCallback } from "react";
import { TVariant, Typography } from "components/ui/Typography";
import { RemoveIcon } from "components/ui/Icons/RemoveIcon";
import { Tooltip } from "components/ui/Tooltip";
import { GrantedIcon } from "../Icons/GrantedIcon";
import { useStyles } from "./styles";

type TDescriptiveCheckbox =
	| { label: React.ReactNode; labelVariant?: TVariant; description?: string }
	| { label?: never; description?: never; labelVariant?: never };

type TCheckboxWithValue<T = undefined> =
	| { onClick: (event: React.MouseEvent, value: T) => void; value: T }
	| { onClick: (event: React.MouseEvent) => void; value?: never };

interface ICheckboxInputProps {
	allSelected?: boolean;
	disabled?: boolean;
	partialSelected?: boolean;
	selected?: boolean;
	tooltipText?: string;
	noMargin?: boolean;
}

const SelectAll: FC = ({ innerRef }) => {
	const classes = useStyles();
	return (
		<div
			ref={innerRef as React.Ref<HTMLDivElement>}
			className={classNames(classes.checkboxFrame, classes.partialSelected)}>
			<RemoveIcon className={classNames(classes.minusSelected)} />
		</div>
	);
};

export type TCheckboxProps<T> = TProps<ICheckboxInputProps & TDescriptiveCheckbox & TCheckboxWithValue<T>>;

export function Checkbox<T>(props: TCheckboxProps<T>) {
	const {
		allSelected,
		className,
		description,
		disabled,
		id,
		innerRef,
		label,
		labelVariant,
		noMargin = false,
		onClick,
		partialSelected,
		selected,
		tooltipText,
		value
	} = props;
	const classes = useStyles({ noMargin });
	const clickHandler = useCallback(
		(event: React.MouseEvent) => {
			event.stopPropagation();
			if (!disabled && onClick) {
				if (value) {
					onClick(event, value);
				} else {
					(onClick as (event: React.MouseEvent) => void)(event);
				}
			}
		},
		[disabled, onClick, value]
	);

	return (
		<Tooltip content={tooltipText} placement="top-start">
			<Typography
				variant={labelVariant}
				innerRef={innerRef}
				component="div"
				className={classNames(classes.checkboxInput, className, { [classes.disabled]: disabled })}
				id={id}
				onClick={clickHandler}>
				{partialSelected ? (
					<SelectAll />
				) : (
					<div
						className={classNames(classes.checkboxFrame, {
							[classes.selected]: selected,
							[classes.allSelected]: allSelected,
							[classes.disabled]: disabled,
							[classes.withDescription]: !!description
						})}>
						<GrantedIcon
							className={classNames(classes.checkboxFill, {
								[classes.selected]: selected,
								[classes.allSelected]: allSelected,
								[classes.disabled]: disabled
							})}
						/>
					</div>
				)}
				{label || description ? (
					<div className={classes.descriptionContainer}>
						<div>{label}</div>
						<Typography className={classes.description} relative variant="small">
							{description}
						</Typography>
					</div>
				) : null}
			</Typography>
		</Tooltip>
	);
}
