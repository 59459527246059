import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	collapsedValues: {
		alignItems: "baseline",
		display: "flex",
		flexDirection: "column",
		gap: "0.25rem"
	},
	prefix: {
		display: "flex",
		flexWrap: "wrap",
		width: "100%",
		flex: "1 1 auto",
		gap: "0.25rem"
	},
	prefixChip: {
		fontSize: "var(--typography-small-font-size)",
		overflow: "hidden",
		textOverflow: "ellipsis",
		whiteSpace: "nowrap"
	},
	collapsed: {
		maxHeight: "20rem",
		overflowY: "auto",
		display: "flex",
		flexWrap: "wrap",
		width: "20rem",
		flexDirection: "row"
	}
});
