import React, { useMemo } from "react";
import { Tooltip } from "components/ui/Tooltip";
import { Chip } from "components/ui/Chip";
import { useStyles } from "./styles";

interface IBaseProps {
	content: string;
	className?: string;
	noBorder?: boolean;
	onClick?: (event: React.MouseEvent) => void;
	onRemove?: () => void;
	stretch?: boolean;
	icon?: JSX.Element;
}

type TProps = IBaseProps & ({ icon?: JSX.Element; imageUrl?: never } | { imageUrl?: string; icon?: never });

export const MultiSelectChip: FC<TProps> = ({
	noBorder,
	onRemove,
	stretch,
	onClick,
	className,
	content,
	icon,
	imageUrl
}) => {
	const classes = useStyles();

	const prefixIcon = useMemo(() => {
		if (imageUrl) return <img src={imageUrl} className={classes.image} />;
		if (icon) return icon;
		return;
	}, [imageUrl, icon, classes.image]);

	return (
		<Tooltip content={content}>
			<Chip
				contentClassName={classes.chipContent}
				className={className}
				size="small"
				onDelete={onRemove}
				noBorder={noBorder}
				prefixIcon={prefixIcon}
				stretch={stretch}
				onClick={onClick}>
				<div className={classes.chipContentText}>{content}</div>
			</Chip>
		</Tooltip>
	);
};
