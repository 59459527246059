import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	hyphens: {
		MozHyphens: "auto",
		WebkitHyphens: "auto",
		msHyphens: "auto",
		hyphens: "auto"
	},
	typography: {
		display: "inline-block",
		extend: "hyphens",
		fontFamily: "Poppins, sans-serif",
		fontStyle: "normal",
		overflowWrap: "break-word",
		textTransform: "none",
		whiteSpace: "initial",
		wordBreak: "break-word",
		wordWrap: "break-word",

		"&$noWrap": {
			overflow: "hidden",
			textOverflow: "ellipsis",
			whiteSpace: "nowrap"
		},
		"&$multiLine": {
			whiteSpace: "pre-wrap"
		},
		"&$relative$bodyReg": {
			fontWeight: "inherit",
			letterSpacing: "inherit",
			lineHeight: "inherit"
		}
	},
	headerSb: {
		fontSize: "var(--typography-font-size-header)",
		fontWeight: "var(--typography-font-weight-semibold)",
		"&$relative": {
			fontSize: "var(--typography-font-size-relative-header)"
		}
	},
	h1Lit: {
		fontSize: "var(--typography-font-size-h1)",
		fontWeight: "var(--typography-font-weight-light)",
		"&$relative": {
			fontSize: "var(--typography-font-size-relative-h1)"
		}
	},
	h2Sb: {
		fontSize: "var(--typography-font-size-h2)",
		fontWeight: "var(--typography-font-weight-semibold)",
		"&$relative": {
			fontSize: "var(--typography-font-size-relative-h2)"
		}
	},
	titleMed: {
		fontSize: "var(--typography-font-size-title)",
		fontWeight: "var(--typography-font-weight-medium)",
		"&$relative": {
			fontSize: "var(--typography-font-size-relative-title)"
		}
	},
	titleSb: {
		fontSize: "var(--typography-font-size-title)",
		fontWeight: "var(--typography-font-weight-semibold)",
		"&$relative": {
			fontSize: "var(--typography-font-size-relative-title)"
		}
	},
	bodyReg: {
		fontSize: "var(--typography-font-size-body)",
		fontWeight: "var(--typography-font-weight-regular)",
		"&$relative": {
			fontSize: "var(--typography-font-size-relative-body)"
		}
	},
	bodyMed: {
		fontSize: "var(--typography-font-size-body)",
		fontWeight: "var(--typography-font-weight-medium)",
		"&$relative": {
			fontSize: "var(--typography-font-size-relative-body)"
		}
	},
	bodySb: {
		fontSize: "var(--typography-font-size-body)",
		fontWeight: "var(--typography-font-weight-semibold)",
		"&$relative": {
			fontSize: "var(--typography-font-size-relative-body)"
		}
	},
	textReg: {
		fontSize: "var(--typography-font-size-text)",
		fontWeight: "var(--typography-font-weight-regular)",
		"&$relative": {
			fontSize: "var(--typography-font-size-relative-text)"
		}
	},
	textTitleSb: {
		fontSize: "var(--typography-font-size-text)",
		fontWeight: "var(--typography-font-weight-semibold)",
		"&$relative": {
			fontSize: "var(--typography-font-size-relative-text)"
		}
	},
	textSmReg: {
		fontSize: "var(--typography-font-size-small)",
		fontWeight: "var(--typography-font-weight-regular)",
		"&$relative": {
			fontSize: "var(--typography-font-size-relative-small)"
		}
	},
	textSmSb: {
		fontSize: "var(--typography-font-size-small)",
		fontWeight: "var(--typography-font-weight-semibold)",
		"&$relative": {
			fontSize: "var(--typography-font-size-relative-small)"
		}
	},
	textTnyReg: {
		fontSize: "var(--typography-font-size-tiny)",
		fontWeight: "var(--typography-font-weight-regular)",
		"&$relative": {
			fontSize: "var(--typography-font-size-relative-tiny)"
		}
	},
	textTnySb: {
		fontSize: "var(--typography-font-size-tiny)",
		fontWeight: "var(--typography-font-weight-semibold)",
		"&$relative": {
			fontSize: "var(--typography-font-size-relative-tiny)"
		}
	},
	noWrap: {},
	multiLine: {},
	relative: {}
});
