const styles = {
	"@keyframes move-from-side-in": {
		"0%": {
			transform: "translateX(100%)"
		},

		"100%": {
			transform: "translateX(0%)"
		}
	},

	"@keyframes move-from-side-out": {
		"0%": {
			transform: "translateX(0%)"
		},

		"100%": {
			opacity: "0",
			transform: "translateX(-100%)"
		}
	},
	transitionable: {
		"&.animate": {
			'&[data-animation="move-from-side"]': {
				"&.in": {
					animation: "var(--animation-duration) var(--transition-easing) forwards $move-from-side-in",
					zIndex: "10"
				},

				"&.out": {
					animation: "var(--animation-duration) var(--transition-easing) forwards $move-from-side-out",
					zIndex: "5"
				},

				"&.reverse": {
					"&.in": {
						animation:
							"var(--animation-duration) var(--transition-easing-reversed) forwards reverse $move-from-side-out"
					},

					"&.out": {
						animation: "var(--animation-duration) var(--transition-easing-reversed) forwards reverse $move-from-side-in"
					}
				}
			}
		}
	}
};

export { styles };
