import React, { useCallback, useMemo } from "react";
import { List, Map as ImmutableMap } from "immutable";
import { RoleVertexModel } from "models/IdentityGraph/RoleVertexModel";
import {
	IntegrationCollapseVertex,
	IntegrationVertex,
	IntegrationExpandableVertex,
	TMinimalRoleWithResource
} from "components/pages/IdentityGraphPage/components/IdentityGraph/components/Vertices/IntegrationVertex";
import { useIdentityGraphContext } from "context/identityGraphContext";
import { useCollectiveColumn } from "./columnsHooks";
import type { TVertexEntityRole } from "models/IdentityGraph/VertexModel";

type TProps = {
	refSetter: (ids: string[]) => (element: unknown) => void;
	vertices: ImmutableMap<string, List<RoleVertexModel>>;
};

const mapEntityToMinimalRole = (entity: TVertexEntityRole, id: string): TMinimalRoleWithResource => {
	return {
		id,
		roleId: entity.roleId,
		name: entity.roleName,
		integrationResource: {
			displayName: entity.resourceName,
			integrationId: entity.integrationId,
			resourceId: entity.resourceId
		}
	};
};

export const IntegrationsColumn: FC<TProps> = ({ refSetter, vertices }) => {
	const {
		decrease,
		extraCollectives,
		increase,
		showCollapse,
		showedCollectives,
		maxEntities,
		clearVerticesLinesFromMap,
		finalMaxEntities
	} = useCollectiveColumn(vertices);

	const {
		actions: { isResourceRoleSelected }
	} = useIdentityGraphContext();

	const showedIntegrationEntities = useMemo(
		() =>
			showedCollectives
				?.mapEntries(([integrationId, vertices]) => {
					return [integrationId, vertices.map(vertex => mapEntityToMinimalRole(vertex.entity, vertex.id)).toArray()];
				})
				.toArray(),
		[showedCollectives]
	);
	const [extraIntegrationIds, extraRoleIds] = useMemo(() => {
		if (!extraCollectives?.size) return [null, null];
		return extraCollectives.reduce(
			(acc, vertices, integrationId) => {
				acc[0].push(integrationId);
				acc[1].push(...vertices.map(vertex => vertex.id));
				return acc;
			},
			[[], []] as [string[], string[]]
		);
	}, [extraCollectives]);

	const getIsSelected = useCallback(
		(roles: TMinimalRoleWithResource[], integrationId: string) => {
			const [resourceIds, roleIds] = roles.reduce(
				(acc, role) => {
					acc[0].push(role.integrationResource.resourceId);
					acc[1].push(role.roleId || role.id);
					return acc;
				},
				[[], []] as [string[], string[]]
			);
			return isResourceRoleSelected({ resourceIds, roleIds, integrationId });
		},
		[isResourceRoleSelected]
	);

	if (!vertices || !showedIntegrationEntities) return null;
	return (
		<>
			{showedIntegrationEntities.map(([integrationId, roles]) => (
				<IntegrationVertex
					key={integrationId}
					roles={roles}
					refSetter={refSetter}
					onResize={clearVerticesLinesFromMap}
					selected={getIsSelected(roles, integrationId)}
				/>
			))}
			{extraIntegrationIds?.length ? (
				<IntegrationExpandableVertex
					key={extraIntegrationIds.join(",")}
					integrationAmount={extraIntegrationIds.length}
					onExpand={increase}
					onCollapse={maxEntities > finalMaxEntities ? decrease : undefined}
					roleIds={extraRoleIds}
					refSetter={refSetter}
				/>
			) : null}
			{showCollapse ? <IntegrationCollapseVertex onCollapse={decrease} /> : null}
		</>
	);
};
