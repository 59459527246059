import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useApprovalAlgorithms } from "hooks/useApprovalAlgorithms";
import { ApprovalAlgorithmModel } from "models/ApprovalAlgorithmModel";
import { Select, ISelectProps } from "components/ui/Select";
import classNames from "classnames";
import { sortByName } from "utils/sortUtils";

interface IProps {
	onChange: (value: ApprovalAlgorithmModel) => void | Promise<void>;
	selected: ApprovalAlgorithmModel | null;
	inputClassName?: string;
	optionClassName?: string;
	showLabel?: boolean;
	noBorder?: boolean;
	selectInputProps?: Partial<TProps<ISelectProps<ApprovalAlgorithmModel>>>;
}

const getOptionLabel = (option: ApprovalAlgorithmModel) => option.name;
const equality = (option: ApprovalAlgorithmModel, selected: ApprovalAlgorithmModel) => option.id === selected.id;

export const ApprovalAlgorithmsSelectInput: FC<IProps> = ({
	onChange,
	selected,
	className,
	inputClassName,
	selectInputProps,
	showLabel = true,
	noBorder = false
}) => {
	const approvalAlgorithms = useApprovalAlgorithms();
	const { t } = useTranslation();

	const approvalAlgorithmsArray = useMemo(
		() => approvalAlgorithms?.toIndexedSeq().toArray() || [],
		[approvalAlgorithms]
	);

	const handleChange = useCallback(
		(value: ApprovalAlgorithmModel | null) => {
			if (value) {
				onChange(value);
			}
		},
		[onChange]
	);

	const loading = useMemo(() => !approvalAlgorithms, [approvalAlgorithms]);
	return (
		<Select
			{...selectInputProps}
			className={classNames(className, inputClassName)}
			disabled={loading}
			fullWidth
			getOptionLabel={getOptionLabel}
			isOptionEqualToValue={equality}
			label={showLabel ? t("pages.bundles.bundleEditor.approvalAlgorithm") : undefined}
			loading={loading}
			onChange={handleChange}
			options={approvalAlgorithmsArray}
			placeholder={t("pages.bundles.bundleEditor.placeholders.selectApprovalAlgorithm")}
			value={selected}
			sort={sortByName}
			required
			noBorder={noBorder}
		/>
	);
};
