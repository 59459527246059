import { useEffect, RefObject } from "react";

export function useOnClickOutside(
	ref: RefObject<HTMLElement> | HTMLElement | undefined,
	callback: (event: MouseEvent | TouchEvent) => void
) {
	useEffect(() => {
		const listener = (event: MouseEvent | TouchEvent) => {
			// Do nothing if clicking ref's element or descendent elements
			if (!ref || !callback) return;
			if (isRef(ref)) {
				if (!ref.current || ref.current.contains(event.target as Node)) {
					return;
				}
			} else {
				if (ref.contains(event.target as Node)) return;
			}

			callback(event);
		};

		document.addEventListener("mousedown", listener);
		document.addEventListener("touchstart", listener);

		return () => {
			document.removeEventListener("mousedown", listener);
			document.removeEventListener("touchstart", listener);
		};
	}, [ref, callback]);
}

const isRef = (value: unknown): value is RefObject<HTMLElement> => {
	return value instanceof Object && "current" in value;
};
