import { Map } from "immutable";

export const toMapBy = <T, K>(collection: Iterable<T>, byKey: (value: T) => K) => {
	let map = Map<K, T>();
	for (const value of collection) {
		const key = byKey(value);
		map = map.set(key, value);
	}
	return map;
};

export const toGroupMapBy = <T, K>(collection: Iterable<T>, toKey: (value: T) => K) => {
	let map = Map<K, T[]>();
	for (const value of collection) {
		const key = toKey(value);
		if (!map.has(key)) {
			map = map.set(key, []);
		}
		map.get(key)!.push(value);
	}
	return map;
};
