import {
	ApproversCell,
	ResourceCell,
	RequestedForCell,
	CreationTimeCell,
	DurationCell,
	TicketNumberCell,
	StatusCell
} from "components/common/TicketsListComponents";
import { TicketModel, TTicketStatus } from "models/TicketModel";
import React, { useEffect, useMemo, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { PaginatedVirtualTable, type TColumn } from "components/ui/VirtualTable";
import classNames from "classnames";
import { CloseIcon } from "components/ui/Icons/CloseIcon";
import { GrantedIcon } from "components/ui/Icons/GrantedIcon";
import { adminRespondToTicket, retryTicket } from "api/tickets";
import { LoadingDots } from "components/ui/LoadingDots";
import { useLoadingState } from "hooks/useLoadingState";
import { useTicketUpdatesContext } from "context/ticketUpdatesContext";
import { useNavigate } from "react-router-dom";
import { RefreshIcon } from "components/ui/Icons/RefreshIcon";
import { SortableVirtualTableHeader } from "components/common/SortableTableHeader";
import { IconButton } from "components/ui/IconButton";
import { useStyles } from "./styles";

interface IProps {
	tickets: (TicketModel | undefined)[] | null;
	totalTickets: number;
	getPage: (page: number) => Promise<void> | void;
	isLoading: boolean;
	errorModalSetError: (error: Error) => void;
	perPage: number;
}

const isTicketFailed = (ticketStatus: TTicketStatus) => ticketStatus === "waitingForIT" || ticketStatus === "failed";
const canRetryTicket = (ticket: TicketModel) =>
	ticket.ticketPermissions?.some(({ integrationResourceRole }) =>
		Boolean(integrationResourceRole && !integrationResourceRole.isDeleted)
	) || false;

export const RequestsLogList: FC<IProps> = ({
	tickets,
	getPage,
	isLoading,
	errorModalSetError,
	totalTickets,
	perPage
}) => {
	const { t } = useTranslation();
	const classes = useStyles();
	const navigate = useNavigate();

	useEffect(() => {
		if (tickets === null && !isLoading) {
			getPage(1);
		}
	}, [tickets, isLoading, getPage]);

	const columns: TColumn<TicketModel>[] = useMemo(() => {
		return [
			{
				renderCell: row => <TicketNumberCell data={row} />,
				header: <SortableVirtualTableHeader title={t("common.ticketList.ticketNumber")} field="ticketNumber" />,
				key: "number",
				width: 80
			},
			{
				renderCell: row => <ResourceCell data={row} />,
				header: <SortableVirtualTableHeader title={t("common.ticketList.resourceLabel")} />,
				key: "resource",
				width: "minmax(320px, 20fr)"
			},
			{
				renderCell: row => <DurationCell data={row} />,
				header: <SortableVirtualTableHeader title={t("common.ticketList.duration")} field="duration" />,
				key: "duration",
				width: 220
			},
			{
				renderCell: row => <RequestedForCell data={row} />,
				header: <SortableVirtualTableHeader title={t("common.ticketList.requestedFor")} field="receiver" />,
				key: "requestedFor",
				width: "minmax(192px, 12fr)"
			},
			{
				renderCell: row => <CreationTimeCell data={row} />,
				header: <SortableVirtualTableHeader title={t("common.ticketList.creationTime")} field="creationTime" />,
				key: "creationTime",
				width: 160
			},
			{
				renderCell: row => <ApproversCell data={row} />,
				header: <SortableVirtualTableHeader title={t("common.ticketList.approversLabel")} />,
				key: "approvers",
				width: "minmax(192px, 12fr)"
			},
			{
				renderCell: row => <StatusCell data={row} />,
				header: <SortableVirtualTableHeader title={t("common.ticketList.statusLabel")} field="status" />,
				key: "status",
				width: 96
			},
			{
				renderCell: row => <AdminActionsCell data={row} errorModalSetError={errorModalSetError} />,
				accessor: "id",
				key: "adminActions",
				width: 136
			}
		] as TColumn<TicketModel>[];
	}, [errorModalSetError, t]);

	const onClick = useCallback(
		(data: TicketModel) => {
			navigate(`?ticketId=${data.id}`);
		},
		[navigate]
	);

	return tickets ? (
		<PaginatedVirtualTable
			fetchPage={getPage}
			perPage={perPage}
			columns={columns}
			rows={tickets}
			totalRows={totalTickets}
			onRowClicked={onClick}
		/>
	) : (
		<LoadingDots className={classes.loader} />
	);
};

const AdminActionsCell: FC<{ data: TicketModel; errorModalSetError: (error: Error) => void }> = ({
	data,
	errorModalSetError
}) => {
	const classes = useStyles();
	const { t } = useTranslation();
	const { isLoading: respondIsLoading, withLoader: withRespondLoader } = useLoadingState();
	const { notifyTicketUpdate } = useTicketUpdatesContext();

	const setTicketStatus = useCallback(
		async (status: boolean) => {
			try {
				if (data?.id) {
					const updatedTicket = await withRespondLoader(adminRespondToTicket(data.id, status));
					notifyTicketUpdate(updatedTicket);
				}
			} catch (e) {
				errorModalSetError(e as Error);
			}
		},
		[data?.id, errorModalSetError, notifyTicketUpdate, withRespondLoader]
	);

	const setApproved = useCallback(
		(event: React.MouseEvent) => {
			event.stopPropagation();
			setTicketStatus(true);
		},
		[setTicketStatus]
	);

	const retryCurrentTicket = useCallback(
		async (event: React.MouseEvent) => {
			event.stopPropagation();
			try {
				if (data.id && isTicketFailed(data.status)) {
					const updatedTicket = await withRespondLoader(retryTicket(data.id));
					notifyTicketUpdate(updatedTicket);
				}
			} catch (e) {
				errorModalSetError(e as Error);
			}
		},
		[data.id, data.status, errorModalSetError, notifyTicketUpdate, withRespondLoader]
	);

	const setDeclined = useCallback(
		(event: React.MouseEvent) => {
			event.stopPropagation();
			setTicketStatus(false);
		},
		[setTicketStatus]
	);

	return (
		<div className={classes.actionButtons}>
			{data.status === "waitingForApproval" && (
				<>
					<IconButton
						variant="secondary"
						size="small"
						disabled={respondIsLoading}
						className={classNames(classes.approveButton, classes.actionButton)}
						onClick={setApproved}
						tooltip={t("common.ticketActions.adminApprove")}>
						<GrantedIcon />
					</IconButton>
					<IconButton
						variant="secondary"
						size="small"
						disabled={respondIsLoading}
						className={classNames(classes.declineButton, classes.actionButton)}
						onClick={setDeclined}
						tooltip={t("common.ticketActions.adminDecline")}>
						<CloseIcon />
					</IconButton>
				</>
			)}
			{isTicketFailed(data.status) && canRetryTicket(data) && (
				<IconButton
					variant="secondary"
					size="small"
					disabled={respondIsLoading}
					className={classes.actionButton}
					onClick={retryCurrentTicket}
					tooltip={t("buttons.retry")}>
					<RefreshIcon />
				</IconButton>
			)}
		</div>
	);
};
