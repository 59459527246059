import React, { useCallback, useMemo } from "react";
import { matchSorter } from "match-sorter";
import { useDirectoryGroups } from "hooks/useDirectoryGroups";
import { useTranslation } from "react-i18next";
import { TextWithDirectoryIcon } from "components/common/TextWithDirectoryIcon";
import { IRenderChipParams, MultipleSelect } from "components/ui/MultipleSelect";
import { DirectoryGroupModel } from "models/DirectoryGroupModel";
import { Typography } from "components/ui/Typography";
import { EmailTooltip } from "components/common/EmailTooltip";
import { DirectoryGroup } from "components/common/DirectoryGroup";
import { Chip } from "components/ui/Chip";
import { getNameAndIcon } from "utils/directoryGroups";
import { useRankedSort } from "hooks/useRankedSort";
import { useStyles } from "./styles";

const EMPTY_LABEL_TRANSLATION = "flow.anyUser";

interface IProps {
	chipClassName?: string;
	disabled?: boolean;
	placeholder?: string;
	fullWidth?: boolean;
	label?: string;
	onChange: (groupIds: string[] | null) => void;
	values: string[];
}

const getOptionLabel = ({ name }: DirectoryGroupModel) => name;

const renderChip = ({
	noBorder,
	option,
	onRemove,
	componentKey: key,
	stretch,
	onClick
}: IRenderChipParams<DirectoryGroupModel>) => (
	<EmailTooltip key={key} email={option.email || undefined} isDeleted={option.isDeleted}>
		<Chip onDelete={onRemove} noBorder={noBorder} stretch={stretch} size="small" onClick={onClick} multiLine>
			<DirectoryGroup value={option.name} isDeleted={option.isDeleted} />
		</Chip>
	</EmailTooltip>
);

const renderOptionBase = (option: DirectoryGroupModel, className: string) => (
	<div className={className}>
		{option && (
			<>
				<TextWithDirectoryIcon value={option.name} isDeleted={option.isDeleted} />
				<Typography variant="tiny">{option.email}</Typography>
			</>
		)}
	</div>
);

const SORT_OPTIONS = {
	keys: [
		{
			key: (item: DirectoryGroupModel) => getNameAndIcon(item.name ?? "").name,
			threshold: matchSorter.rankings.MATCHES
		}
	]
};

export const GroupsSelectInput: FC<IProps> = ({
	values,
	onChange,
	className,
	label,
	disabled,
	fullWidth,
	placeholder
}) => {
	const directoryGroups = useDirectoryGroups();
	const { sort, onInputChange } = useRankedSort<DirectoryGroupModel>(SORT_OPTIONS);
	const classes = useStyles();
	const { t } = useTranslation();

	const options = useMemo(
		() => directoryGroups?.filter(group => !group.isDeleted || values?.includes(group.id)).toArray() || [],
		[directoryGroups, values]
	);

	const handleChange = useCallback(
		(newValues: DirectoryGroupModel[] | null) => {
			onChange(newValues ? newValues.map(({ id }) => id) : null);
		},
		[onChange]
	);

	const selectedOptions = useMemo(() => {
		return options?.filter(option => values.includes(option.id)) || [];
	}, [options, values]);

	const loading = !options;

	const renderOption = useCallback(
		(option: DirectoryGroupModel) => renderOptionBase(option, classes.option),
		[classes]
	);

	const currentPlaceholder = useMemo(() => {
		if (values.length) return;
		return placeholder ? placeholder : t(EMPTY_LABEL_TRANSLATION);
	}, [placeholder, t, values.length]);

	return (
		<MultipleSelect
			fullWidth={fullWidth}
			className={className}
			disabled={loading || disabled}
			onInputChange={onInputChange}
			getOptionLabel={getOptionLabel}
			label={label}
			loading={loading}
			onChange={handleChange}
			options={options}
			placeholder={currentPlaceholder}
			renderChip={renderChip}
			renderOption={renderOption}
			sort={sort}
			value={selectedOptions}
		/>
	);
};
