import React, { useCallback, useMemo } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { IntegrationModel } from "models/IntegrationModel";
import { Select } from "components/ui/Select";
import { sortByName } from "utils/sortUtils";
import { OptionWithIcon } from "components/common/OptionWithIcon";
import { useApplications } from "hooks/useApplications";
import { useUserEntitlementsIntegrations } from "../../myPermissionsHooks";
import { useStyles } from "./styles";

type TProps = {
	disabled?: boolean;
	onChange: (integrationId: string | null) => void;
	value: string | null;
};

const isEqual = (option: IntegrationModel, value: IntegrationModel) => option.id === value.id;
const getLabel = (option: IntegrationModel) => option.name;
const renderOption = (option: IntegrationModel) => (
	<OptionWithIcon imageUrl={option.imageUrl}>{option.name}</OptionWithIcon>
);

export const IntegrationsSelect: FC<TProps> = ({
	className,
	disabled,
	innerRef,
	onChange: propOnChange,
	value: propValue
}) => {
	const { t } = useTranslation();
	const classes = useStyles();
	const { integrations, isLoading } = useUserEntitlementsIntegrations();
	const applications = useApplications();
	const options = useMemo(
		() =>
			integrations
				? integrations
						.valueSeq()
						.toArray()
						.map(integration => {
							return integration.set(
								"imageUrl",
								(integration.imageUrl ?? applications?.get(integration.applicationId || "")?.imageUrl) || null
							);
						})
				: [],
		[applications, integrations]
	);

	const onChange = useCallback(
		(value: IntegrationModel | null) => {
			propOnChange(value?.id ?? null);
		},
		[propOnChange]
	);

	const value = useMemo(() => {
		return integrations?.get(propValue || "") ?? null;
	}, [integrations, propValue]);

	return (
		<Select
			small
			className={classNames(classes.container, className)}
			disabled={disabled}
			getOptionLabel={getLabel}
			innerRef={innerRef}
			isOptionEqualToValue={isEqual}
			label={t("common.integrationSelectInput.label")}
			limit={20}
			loading={isLoading}
			onChange={onChange}
			options={options}
			placeholder={t("common.integrationSelectInput.placeholder")}
			renderLabel={renderOption}
			renderOption={renderOption}
			sort={sortByName}
			value={value}
		/>
	);
};
