import React, { useMemo } from "react";
import classNames from "classnames";
import { DirectoryGroup } from "components/common/DirectoryGroup";
import { EmailTooltip } from "components/common/EmailTooltip";
import { OnCallIntegrationScheduleLabel } from "components/common/OnCallIntegrationScheduleLabel";
import { List } from "components/ui/List";
import { IEntity } from "utils/types";
import { useStyles } from "./styles";

const EntityItem: FC<{ entity: string | IEntity }> = ({ entity }) => {
	// Directory group is the default component for every entity except for strings and on call schedules
	if (typeof entity === "string") {
		return <DirectoryGroup value={entity} />;
	}
	if (entity.type === "directoryGroup") {
		return (
			<EmailTooltip email={entity.tooltip} name={entity.name} isDeleted={entity.isDeleted}>
				<DirectoryGroup value={entity.name} isDeleted={entity.isDeleted} icon={entity.prefixIcon} />
			</EmailTooltip>
		);
	}
	if (entity.type === "onCallIntegrationSchedule") {
		return (
			<OnCallIntegrationScheduleLabel
				value={entity.name}
				onCallType={entity.onCallScheduleType}
				isDeleted={entity.isDeleted}
			/>
		);
	}
	return <DirectoryGroup value={entity.name} icon={entity.prefixIcon} isDeleted={entity.isDeleted} />;
};

const ArrayEntity: FC<{ entities: string[] | IEntity[] }> = ({ entities }) => {
	const classes = useStyles();

	const items = useMemo(
		() =>
			entities?.map(entity => (
				<EntityItem key={typeof entity === "string" ? entity : `${entity.name}-${entity.tooltip}`} entity={entity} />
			)),
		[entities]
	);
	if (!entities || entities.length === 0) return null;
	if (items.length > 1) return <List items={items} className={classes.entityList} />;
	return <>{items[0]}</>;
};

export const ApprovalAlgorithmEntity: FC<{
	value: string[] | string | IEntity | IEntity[];
}> = ({ value, className }) => {
	const classes = useStyles();
	return (
		<div className={classNames(classes.entityContainer, className)}>
			{Array.isArray(value) ? <ArrayEntity entities={value} /> : <EntityItem entity={value} />}
		</div>
	);
};
