import { useCallback, useEffect, useState } from "react";
import { Map } from "immutable";
import {
	fetchPermission,
	createPermission,
	createClause,
	removePermissionRole,
	setPermissionRoleDefault,
	removePermission,
	createPermissionRole,
	removePermissionClause
} from "api/prerequisitePermissions";
import { PrerequisitePermissionModel } from "models/PrerequisitePermissionModel";
import { PrerequisitePermissionClauseModel } from "models/PrerequisitePermissionClauseModel";
import { toMapBy } from "utils/toMapBy";
import type { TAssetTypes, TClauseCreation, TPrerequisitePermissionCreation } from "utils/prerequisitePermissions";

export const usePrerequisitePermission = (id: string | null) => {
	const [loadingState, setLoadingState] = useState<"loading" | "loaded" | "error" | null>(null);
	const [prerequisitePermission, setPrerequisitePermission] = useState<PrerequisitePermissionModel | null>(null);
	const [prerequisitePermissionClauses, setPrerequisitePermissionClauses] = useState<Map<
		string,
		PrerequisitePermissionClauseModel
	> | null>(null);

	const loadPrerequisitePermission = useCallback(async () => {
		if (id) {
			const permission = await fetchPermission(id);
			setPrerequisitePermission(permission);
			setPrerequisitePermissionClauses(toMapBy(permission.prerequisitePermissionClauses, ({ id }) => id));
		}
	}, [id]);

	const createPrerequisitePermission = useCallback(
		async (data: TPrerequisitePermissionCreation, assetId: string, assetType: TAssetTypes) => {
			const createdPermission = await createPermission(data, assetId, assetType);
			setPrerequisitePermission(createdPermission);
			setPrerequisitePermissionClauses(toMapBy(createdPermission.prerequisitePermissionClauses, ({ id }) => id));
			return createdPermission;
		},
		[]
	);

	const deletePrerequisitePermission = useCallback(
		async (assetId: string, assetType: TAssetTypes) => {
			if (id) {
				await removePermission(id, assetId, assetType);
				setPrerequisitePermission(null);
				setPrerequisitePermissionClauses(null);
			}
		},
		[id]
	);

	const createPrerequisiteClause = useCallback(
		async (data: TClauseCreation) => {
			const createdClause = await createClause(data, id!);
			setPrerequisitePermissionClauses(current => current?.set(createdClause.id, createdClause) || current);
		},
		[id]
	);

	const deletePrerequisiteClause = useCallback(async (prerequisitePermissionClauseId: string) => {
		await removePermissionClause(prerequisitePermissionClauseId);
		setPrerequisitePermissionClauses(current => current?.delete(prerequisitePermissionClauseId) || current);
	}, []);

	const createPrerequisiteRole = useCallback(async (integrationResourceRoleId: string, clauseId: string) => {
		const updatedClause = await createPermissionRole(integrationResourceRoleId, clauseId);
		setPrerequisitePermissionClauses(current => current?.set(updatedClause.id, updatedClause) || current);
	}, []);

	const setPrerequisiteRoleAsDefault = useCallback(async (prerequisiteRoleId: string, prerequisiteClauseId: string) => {
		const updatedClause = await setPermissionRoleDefault(prerequisiteRoleId, prerequisiteClauseId);
		setPrerequisitePermissionClauses(current => current?.set(updatedClause.id, updatedClause) || current);
	}, []);

	const deletePrerequisiteRole = useCallback(
		async (prerequisitePermissionRoleId: string) => {
			const deletedRole = await removePermissionRole(prerequisitePermissionRoleId);
			const clause = prerequisitePermissionClauses?.get(deletedRole.prerequisitePermissionClauseId);
			if (clause) {
				const updatedRoles = clause.prerequisitePermissionRoles?.filter(role => role.id !== deletedRole.id);
				setPrerequisitePermissionClauses(
					current => current?.setIn([clause.id, "prerequisitePermissionRoles"], updatedRoles) || current
				);
			}
		},
		[prerequisitePermissionClauses]
	);

	useEffect(() => {
		if (!id) setPrerequisitePermission(null);
	}, [id]);

	useEffect(() => {
		(async () => {
			if (!prerequisitePermission && id && !loadingState) {
				setLoadingState("loading");
				try {
					await loadPrerequisitePermission();
					setLoadingState("loaded");
				} catch (e) {
					setLoadingState("error");
					throw e;
				}
			}
		})();
	}, [id, loadPrerequisitePermission, loadingState, prerequisitePermission]);

	return {
		prerequisitePermission,
		prerequisitePermissionClauses,
		loading: loadingState === "loading" || (!prerequisitePermission && id),
		loadPrerequisitePermission,
		createPrerequisitePermission,
		createPrerequisiteClause,
		createPrerequisiteRole,
		updatePrerequisiteRole: setPrerequisiteRoleAsDefault,
		deletePrerequisitePermission,
		deletePrerequisiteClause,
		deletePrerequisiteRole
	};
};
