import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { CloseIcon } from "components/ui/Icons/CloseIcon";
import { DoneAllIcon } from "components/ui/Icons/DoneAllIcon";
import { Typography } from "components/ui/Typography";
import { EntitiesSelectInput } from "components/pages/WorkflowsPage/components/EditWorkflow/components/EntitiesSelectInput";
import { ApprovalFlowEntityModel } from "models/ApprovalFlowEntityModel";
import { TEntityOption } from "utils/entityOptionType";
import { NotificationIcon } from "components/ui/Icons/NotificationIcon";
import { AnyOrAllToggle } from "../../AnyOrAllToggle";
import { FlowBlock } from "../../FlowBlock";
import { useStyles } from "./styles";

interface IProps {
	approvalEntities: ApprovalFlowEntityModel[];
	canMakeAutomatic?: boolean;
	notifiedEntities: ApprovalFlowEntityModel[];
	onChangeApprovers: (values: TEntityOption[] | null) => void;
	onChangeNotified: (values: TEntityOption[]) => void;
	onChangeOperator: (operator: "and" | "or") => void;
	onRemove?: () => void;
	operator: "and" | "or";
}

export const ShouldBeApprovedEditable: FC<IProps> = ({
	approvalEntities,
	canMakeAutomatic,
	className,
	notifiedEntities,
	onChangeApprovers,
	onChangeNotified,
	onChangeOperator,
	onRemove,
	operator
}) => {
	const { t } = useTranslation();
	const classes = useStyles();
	const label = (
		<div className={classes.title}>
			<span>{t("flow.shouldBeApproved")}</span>
			<div className={classes.titleActions}>
				<AnyOrAllToggle operator={operator} onChange={onChangeOperator} />
				<CloseIcon onClick={onRemove} />
			</div>
		</div>
	);

	const onChangeNotifiedEntities = useCallback(
		(values: TEntityOption[] | null) => onChangeNotified(values || []),
		[onChangeNotified]
	);

	return (
		<FlowBlock label={label} className={className}>
			<div className={classes.entitiesContainer}>
				<DoneAllIcon />
				<Typography className={classes.labelContainer}>{t("flow.by")}</Typography>
				<EntitiesSelectInput
					automaticState={canMakeAutomatic ? "enabled" : "disabled"}
					className={classes.select}
					onChange={onChangeApprovers}
					values={approvalEntities}
				/>
			</div>
			<div className={classes.entitiesContainer}>
				<NotificationIcon />
				<Typography className={classes.labelContainer}>{t("flow.notifyOnApprove")}</Typography>
				<EntitiesSelectInput
					automaticState="none"
					className={classes.select}
					onChange={onChangeNotifiedEntities}
					values={notifiedEntities}
				/>
			</div>
		</FlowBlock>
	);
};
