import { useEffect, useRef, useState } from "react";
import requestRenderFrame from "../utils/requestRenderFrame";

/**
 * @param {boolean} isOpen
 * @param {number} closeUnmountDelay
 * @param {number|null} animationFrameTimeout
 * @returns {[boolean, boolean]} [shouldMount, shouldOpen]
 */
export default function useShouldMount(
	isOpen: boolean,
	closeUnmountDelay: number,
	animationFrameTimeout: number | null = 500
): [boolean, boolean] {
	const [shouldMount, setShouldMount] = useState(isOpen);
	const [shouldOpen, setShouldOpen] = useState(isOpen);
	const firstRenderHappenedRef = useRef(false);

	useEffect(() => {
		if (!firstRenderHappenedRef.current) {
			firstRenderHappenedRef.current = true;
			return () => void 0;
		}

		if (isOpen) {
			setShouldMount(true);

			const renderFrameHandle = requestRenderFrame(() => {
				setShouldOpen(true);
			}, animationFrameTimeout);

			return () => {
				renderFrameHandle.destroy();
			};
		} else {
			setShouldOpen(false);

			const unmountTimeoutHandle = window.setTimeout(() => {
				setShouldMount(false);
			}, closeUnmountDelay);

			return () => {
				window.clearTimeout(unmountTimeoutHandle);
			};
		}
	}, [animationFrameTimeout, closeUnmountDelay, isOpen]);

	return [shouldMount, shouldOpen];
}
