import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	container: {
		alignItems: "center",
		display: "flex",
		flexWrap: "nowrap",
		gap: "4px",
		maxWidth: "100%"
	},
	tooltipContentContainer: {
		display: "flex",
		flexDirection: "column",
		gap: "4px",
		maxHeight: "400px"
	},
	invisible: {
		visibility: "hidden"
	}
});
