import { TRoutes } from "routes/routes";
import { Route } from "routes/route";

export const flattenRoutes = (
	routes: TRoutes,
	basePath = "",
	flattenedRoutes: Route[] = [],
	baseAnimationOrder = 0,
	animationOrderDivider = 1
) => {
	Object.values(routes).forEach(route => {
		flattenedRoutes.push(
			new Route({
				matches: route.matches.map(match => basePath + match),
				Page: route.Page,
				pageId: route.pageId,
				animationOrder: route.animationOrder && baseAnimationOrder + route.animationOrder / animationOrderDivider,
				inAnimation: route.inAnimation,
				outAnimation: route.outAnimation,
				shouldRender: route.shouldRender,
				hideSideNav: route.hideSideNav
			})
		);
		if (route?.subRoutes) {
			route.matches.forEach(match => {
				flattenRoutes(
					route.subRoutes!,
					basePath + match,
					flattenedRoutes,
					route.animationOrder,
					animationOrderDivider * 10
				);
			});
		}
	});

	return flattenedRoutes;
};
