import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { ResourcesIcon } from "components/ui/Icons/ResourcesIcon";
import { IntegrationModel } from "models/IntegrationModel";
import { Typography } from "components/ui/Typography";
import { Table } from "components/ui/Table";
import { IPaginatedSearchOptions } from "utils/searchUtils";
import { getIntegrationResources, TFullIntegrationResourceModel } from "api/integrationResources";
import { ASC, usePagination } from "hooks/usePagination";
import { CommonAccordion } from "components/common/CommonAccordion";
import { Input } from "components/ui/Input";
import { PageSelect } from "components/ui/PageSelect";
import { Button } from "components/ui/Button";
import { AddIcon } from "components/ui/Icons/AddIcon";
import { Separator } from "components/ui/Separator";
import { useAbortController } from "hooks/useAbortController";
import { IPaginationResponse } from "utils/pagination";
import { SortableTableHeader } from "components/common/SortableTableHeader";
import { SortTableProvider, useSortTableContext } from "context/sortingTableContext";
import { useStyles } from "./styles";
import { ResourceRow } from "./ResourceRow";

const PAGE_SIZE = 10;
const TRANSLATION_PREFIX = "pages.integration.resource" as const;

const fields = ["name", "owner", "approvalAlgorithm", "type"];

interface IProps {
	integration: IntegrationModel;
	onAddResource?: () => void;
}

const ResourceBlockContent: FC<IProps> = ({ integration, onAddResource }) => {
	const classes = useStyles();
	const { t } = useTranslation();
	const [query, setQuery] = useState<string>("");

	const {
		state: { sortFields, sortOrder }
	} = useSortTableContext();
	const { abortWrapper } = useAbortController<IPaginationResponse<TFullIntegrationResourceModel>>();

	const fetchResources = useCallback(
		(paginationOptions: IPaginatedSearchOptions) => {
			paginationOptions = query ? { search: { query }, ...paginationOptions } : paginationOptions;
			return abortWrapper(
				async (abortSignal: AbortSignal) =>
					(integration &&
						(await getIntegrationResources(integration.id, paginationOptions, undefined, undefined, abortSignal))) ||
					Promise.resolve([])
			);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[integration, query]
	);

	const { totalPages, currentPageNumber, page, setCurrentPageNumber, isLoading, lastPage, setPartialItem } =
		usePagination(fetchResources, PAGE_SIZE, sortOrder, sortFields, null, false);

	const onInputChange = useCallback((event: { target: { value: string } }) => {
		setQuery(event.target.value);
	}, []);

	const headers: string[] = [
		t(`${TRANSLATION_PREFIX}.name`),
		t(`${TRANSLATION_PREFIX}.owner`),
		t(`${TRANSLATION_PREFIX}.workflow`),
		t(`${TRANSLATION_PREFIX}.type`)
	];

	const handleAddResource = useCallback(
		(event: React.MouseEvent<HTMLButtonElement>) => {
			event.stopPropagation();
			onAddResource && onAddResource();
		},
		[onAddResource]
	);

	return (
		<CommonAccordion
			title={
				<div className={classes.titleContainer}>
					<Typography variant="h3" className={classes.title} prefixIcon={<ResourcesIcon />}>
						{t("pages.integration.resources")}
						<Typography relative variant="small">{`( ${integration.resourcesCount || 0} )`}</Typography>
						{integration.lastResourcesSync && (
							<>
								<Separator className={classes.separator} />
								<Typography relative variant="small" className={classes.lighterText}>
									{t("common.resourceHeader.lastSync", { date: integration.lastResourcesSync })}
								</Typography>
							</>
						)}
					</Typography>
					{onAddResource && (
						<Button variant="secondary" size="small" prefix={<AddIcon />} onClick={handleAddResource}>
							{t("buttons.add")}
						</Button>
					)}
				</div>
			}>
			<Input
				className={classes.searchBar}
				placeholder={t("pages.integration.resource.search.placeholder")}
				value={query}
				onChange={onInputChange}
			/>

			<Table gridColumns="repeat(4, 1fr)" loading={isLoading}>
				<Table.Row>
					{headers.map((title, index) => {
						const field = fields.at(index)!;
						return <SortableTableHeader field={field} key={field} title={title} />;
					})}
				</Table.Row>
				{(isLoading ? lastPage : page)?.map(resource => (
					<ResourceRow resource={resource} key={resource.id} onResourceChanged={setPartialItem} />
				)) || null}
			</Table>
			{totalPages > 1 && (
				<PageSelect
					changePage={setCurrentPageNumber}
					currentPageNumber={currentPageNumber}
					pagesCount={totalPages}
					pagesShownAmount={3}
				/>
			)}
		</CommonAccordion>
	);
};

export const ResourcesBlock: FC<IProps> = props => (
	<SortTableProvider defaultSortField="name" defaultSortOrder={ASC}>
		<ResourceBlockContent {...props} />
	</SortTableProvider>
);
