import React, { useMemo } from "react";
import classNames from "classnames";
import { sourceIcons, TSources } from "utils/sourceIcons";
import { DirectoryGroupModel } from "models/DirectoryGroupModel";
import { Typography } from "components/ui/Typography";
import { requirePropertyOf } from "utils/security";
import { useStyles } from "./styles";

const sourceRegex = new RegExp(/\[([A-z]+)\] (.*)/);

interface IProps {
	directoryGroup: DirectoryGroupModel;
	wrap?: boolean;
}

export const DirectoryGroupWithEmail: FC<IProps> = ({ directoryGroup, wrap = false, className, innerRef }) => {
	const classes = useStyles();

	const { name, Icon } = useMemo(() => {
		const match = sourceRegex.exec(directoryGroup.name);
		if (!match) {
			return {
				name: directoryGroup.name,
				icon: null
			};
		}

		const iconString = match[1] as TSources;
		const name = match[2];
		const Icon = requirePropertyOf(sourceIcons, iconString);

		return {
			name,
			Icon
		};
	}, [directoryGroup]);

	return (
		<div className={classNames(classes.container, className)} ref={innerRef}>
			{Icon && <Icon className={classes.icon} />}
			<div className={classes.title}>
				<Typography noWrap={!wrap} variant="small">
					{name}
				</Typography>
				<Typography noWrap={!wrap} variant="tiny">
					{directoryGroup.email}
				</Typography>
			</div>
			{directoryGroup.isDeleted && <span className={classes.deleted}>*</span>}
		</div>
	);
};
