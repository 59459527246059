import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	chipContent: {
		maxWidth: "250px"
	},
	chipContentText: {
		whiteSpace: "nowrap",
		textOverflow: "ellipsis",
		overflow: "hidden",
		minWidth: "1px",
		width: "100%",
		display: "inline-block"
	},
	image: {
		height: "1rem",
		width: "1rem"
	}
});
