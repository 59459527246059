import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	actionsContainer: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		width: "calc(100% + 1rem)"
	},
	create: {
		padding: 0
	},
	field: {
		width: "100%"
	},
	fieldContainer: {
		alignItems: "start"
	}
});
