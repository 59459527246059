import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { UserEntitlementModel } from "models/UserEntitlementModel";
import { List } from "immutable";
import { TColumn, VirtualTable } from "components/ui/VirtualTable";
import { useIntegrations } from "hooks/useIntegrations";
import { IntegrationImage } from "components/common/IntegrationImage";
import { TooltipOnOverflow } from "components/ui/New/TooltipOnOverflow";
import { notEmpty } from "utils/comparison";
import uniq from "lodash/uniq";
import { Chip } from "components/ui/Chip";
import { Tooltip } from "components/ui/Tooltip";
import { getTicketExpirationDate } from "utils/ticketExpirationDate";
import { IntegrationIcon } from "components/ui/Icons/IntegrationIcon";
import { Typography } from "components/ui/New/Typography";
import { AccountIcon } from "components/ui/Icons/AccountIcon";
import { ResourcesIcon } from "components/ui/Icons/ResourcesIcon";
import { RoleIcon } from "components/ui/Icons/RoleIcon";
import { ExpiredIcon } from "components/ui/Icons/ExpiredIcon";
import { useStyles } from "./styles";

type TProps = {
	permissions?: List<UserEntitlementModel>;
};

const MAX_REQUESTS_CHIPS = 2;
const MY_PERMISSIONS_TABLE_PREFIX = "pages.myPermissions.myPermissionsTable";
const COLUMNS_WIDTHS = ["180px", "200px", "180px", "minmax(220px, 1fr)", "minmax(220px, 1fr)", "180px", "200px"];

export const MyPermissionsTable: FC<TProps> = ({ className, innerRef, permissions }) => {
	const { t } = useTranslation();
	const classes = useStyles();
	const permissionsArray = useMemo(() => permissions?.toArray() || [], [permissions]);
	const integrations = useIntegrations();
	const navigate = useNavigate();

	const renderRequestNumber = useCallback(
		(entitlement: UserEntitlementModel) => {
			const tickets = uniq(
				(entitlement.ticketPermissions?.toArray() || [])
					.map(ticketPermission => ticketPermission.ticket)
					.filter(notEmpty)
			);
			if (tickets.length === 0) return null;

			const onChipClick = (ticketId: string) => {
				navigate(`?ticketId=${ticketId}`);
			};

			let ticketNumberChips = tickets.map(ticket => (
				<Chip size="tiny" key={ticket.id} onClick={() => onChipClick(ticket.id)}>
					{ticket.number}
				</Chip>
			));

			if (tickets.length > MAX_REQUESTS_CHIPS) {
				const shownChips = ticketNumberChips.slice(0, MAX_REQUESTS_CHIPS - 1);
				const hiddenChips = ticketNumberChips.slice(MAX_REQUESTS_CHIPS - 1);

				ticketNumberChips = [
					...shownChips,
					<Tooltip key="extraRequests" content={<div className={classes.requestsTooltip}>{hiddenChips}</div>}>
						<Chip size="tiny">{hiddenChips.length + "+"}</Chip>
					</Tooltip>
				];
			}

			return <div className={classes.cell}>{ticketNumberChips}</div>;
		},
		[classes.cell, classes.requestsTooltip, navigate]
	);

	const columns = useMemo(
		() =>
			[
				{
					renderCell: row => {
						const integration = integrations?.get(row.role?.integrationResource?.integrationId || "");
						if (!integration) {
							return null;
						}
						return (
							<div className={classes.cell}>
								<IntegrationImage noWrap size="xs" integration={integration} />
								<TooltipOnOverflow textVariant="body_reg" content={integration.name} />
							</div>
						);
					},
					header: (
						<div className={classes.header}>
							<IntegrationIcon size={20} />
							<Typography variant="body_sb">{t(`${MY_PERMISSIONS_TABLE_PREFIX}.columns.integration`)}</Typography>
						</div>
					),
					key: "integration",
					width: COLUMNS_WIDTHS[0]
				},
				{
					renderCell: row => (
						<div className={classes.cell}>
							<TooltipOnOverflow textVariant="body_reg" content={row.actor?.displayName} />
						</div>
					),
					header: (
						<div className={classes.header}>
							<AccountIcon size={20} />
							<Typography variant="body_sb">{t(`${MY_PERMISSIONS_TABLE_PREFIX}.columns.account`)}</Typography>
						</div>
					),
					key: "account",
					width: COLUMNS_WIDTHS[1]
				},
				{
					renderCell: row => (
						<div className={classes.cell}>
							<TooltipOnOverflow textVariant="body_reg" content={row.role?.integrationResource?.type} />
						</div>
					),
					header: (
						<div className={classes.header}>
							<Typography variant="body_sb">{t(`${MY_PERMISSIONS_TABLE_PREFIX}.columns.resourceType`)}</Typography>
						</div>
					),
					key: "resourceType",
					width: COLUMNS_WIDTHS[2]
				},
				{
					renderCell: row => (
						<div className={classes.cell}>
							<TooltipOnOverflow textVariant="body_reg" content={row.role?.integrationResource?.displayName} />
						</div>
					),
					header: (
						<div className={classes.header}>
							<ResourcesIcon size={20} />
							<Typography variant="body_sb">{t(`${MY_PERMISSIONS_TABLE_PREFIX}.columns.resource`)}</Typography>
						</div>
					),
					key: "resource",
					width: COLUMNS_WIDTHS[3]
				},
				{
					renderCell: row => (
						<div className={classes.cell}>
							<TooltipOnOverflow textVariant="body_reg" content={row.role?.name} />
						</div>
					),
					header: (
						<div className={classes.header}>
							<RoleIcon size={20} />
							<Typography variant="body_sb">{t(`${MY_PERMISSIONS_TABLE_PREFIX}.columns.role`)}</Typography>
						</div>
					),
					key: "role",
					width: COLUMNS_WIDTHS[4]
				},
				{
					renderCell: row => {
						const expiresAt = getTicketExpirationDate(row.ticketPermissions);
						return (
							<div className={classes.cell}>
								<TooltipOnOverflow
									textVariant="body_reg"
									content={expiresAt ? t("dateTime.date", { date: expiresAt }) : t("shared.ticketExpiration.never")}
								/>
							</div>
						);
					},
					header: (
						<div className={classes.header}>
							<ExpiredIcon size={20} />
							<Typography variant="body_sb">{t(`${MY_PERMISSIONS_TABLE_PREFIX}.columns.expiration`)}</Typography>
						</div>
					),
					key: "expiration",
					width: COLUMNS_WIDTHS[5]
				},
				{
					renderCell: renderRequestNumber,
					header: (
						<div className={classes.header}>
							<Typography variant="body_sb">{t(`${MY_PERMISSIONS_TABLE_PREFIX}.columns.requestNumber`)}</Typography>
						</div>
					),
					key: "requestNumber",
					width: COLUMNS_WIDTHS[6]
				}
			] as TColumn<UserEntitlementModel>[],
		[classes.cell, classes.header, integrations, t, renderRequestNumber]
	);

	return (
		<VirtualTable
			rows={permissionsArray}
			totalRows={permissionsArray.length}
			columns={columns}
			className={className}
			innerRef={innerRef}
		/>
	);
};
