import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	actions: {
		alignItems: "center",
		display: "flex",
		gap: "0.5rem",
		padding: "0.8rem 0"
	},
	actionsApprove: {
		"&$actionsApprove$actionsApprove": {
			"& svg": {
				color: "var(--color-green-dark)"
			},
			"&:hover": {
				backgroundColor: "var(--color-green-10)"
			},
			color: "var(--color-black)"
		}
	},
	actionsButton: {
		"&$actionsButton$actionsButton": {
			"&:hover": {
				borderColor: "var(--color-grey-light)",
				cursor: "pointer"
			},
			alignItems: "center",
			borderColor: "var(--color-grey-light)",
			display: "inline-flex",
			height: "2rem",
			justifyContent: "space-around"
		}
	},
	actionsDecline: {
		"&$actionsDecline$actionsDecline": {
			"& svg": {
				color: "var(--color-red-dark)"
			},
			"&:hover": {
				backgroundColor: "var(--color-red-10)"
			},
			color: "var(--color-black)"
		}
	},
	body: {
		flexGrow: 1
	},
	container: {
		alignItems: "start",
		display: "flex",
		gap: "0.75rem",
		padding: "1rem"
	},
	postButton: {
		maxWidth: "6.5rem",
		width: "100%"
	},
	textArea: {
		width: "100%"
	},
	textareaInput: {
		maxHeight: "10rem"
	}
});
