import React from "react";
import classNames from "classnames";
import { ResourceDetailsTooltip, type TResourceDetailsTooltipProps } from "components/common/ResourceDetailsTooltip";
import { IconPrefix } from "components/ui/IconPrefix";
import { ResourcesIcon } from "components/ui/Icons/ResourcesIcon";
import { RoleBarTag } from "../RoleBarTag";
import { ROLE_BAR_RESOURCE_MIN_WIDTH_PX, useStyles } from "./styles";

export const ROLE_BAR_RESOURCE_WIDTH = `minmax(${ROLE_BAR_RESOURCE_MIN_WIDTH_PX}px, 1fr)`;

type TProps = TResourceDetailsTooltipProps & {
	tags?: string[];
};

export const RoleBarResource: FC<TProps> = ({ className, innerRef, tags, ...restProps }) => {
	const classes = useStyles();

	return (
		<div className={classNames(classes.container, { [classes.withChips]: !!tags?.length }, className)} ref={innerRef}>
			<ResourceDetailsTooltip {...restProps}>
				<IconPrefix Icon={ResourcesIcon} content={restProps.amount || restProps.name || 0} />
			</ResourceDetailsTooltip>
			{tags ? <RoleBarTag tags={tags} className={classes.chips} /> : null}
		</div>
	);
};
