import React from "react";
import classNames from "classnames";
import { EntitleDarkLogo } from "components/ui/systemLogos/EntitleDarkLogo";
import { Block } from "components/ui/Block";
import useErrorModalState from "hooks/useErrorModalState";
import { ErrorModal } from "components/ui/ErrorModal";
import { GoogleLoginButton } from "../LoginButtons/GoogleLoginButton";
import { OktaLoginButton } from "../LoginButtons/OktaLoginButton";
import { MicrosoftLoginButton } from "../LoginButtons/MicrosoftLoginButton";
import { useStyles } from "./styles";

export const LoginPromptPage: FC = ({ className }) => {
	const { errorModalClose, errorModalError, errorModalIsOpen } = useErrorModalState();
	const classes = useStyles();

	const backgroundImage = `url("/loginLogo")`;
	return (
		<div
			className={classNames(classes.loginPromptPage, className)}
			style={{ backgroundImage, backgroundSize: "cover" }}>
			<div className={classes.overlays}>
				<ErrorModal isOpen={errorModalIsOpen} error={errorModalError} onClose={errorModalClose} />
			</div>
			<div className={classes.loginWrapper}>
				<Block className={classNames(classes.loginBlock)}>
					<div className={classes.entitleLogo}>
						<EntitleDarkLogo className={classes.logo} />
					</div>
					<div className={classes.buttons}>
						<GoogleLoginButton />
						<OktaLoginButton />
						<MicrosoftLoginButton />
					</div>
				</Block>
			</div>
		</div>
	);
};
