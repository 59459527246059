import React from "react";
import camelCase from "lodash/camelCase";
import classNames from "classnames";
import { requirePropertyOf } from "utils/security";
import { useStyles } from "./styles";

export type TTypographyVariant =
	| "header_sb"
	| "h1_lit"
	| "h2_sb"
	| "title_med"
	| "title_sb"
	| "body_reg"
	| "body_med"
	| "body_sb"
	| "text_reg"
	| "text_title_sb"
	| "text_sm_reg"
	| "text_sm_sb"
	| "text_tny_reg"
	| "text_tny_sb";

type CamelCase<S extends string> = S extends `${infer P1}_${infer P2}${infer P3}`
	? `${Lowercase<P1>}${Uppercase<P2>}${CamelCase<P3>}`
	: Lowercase<S>;

const toCamelCaseVariant = <T extends TTypographyVariant>(variant: T): CamelCase<T> =>
	camelCase(variant) as CamelCase<T>;

type TProps = {
	multiLine?: boolean;
	noWrap?: boolean;
	relative?: boolean;
	variant?: TTypographyVariant;
};

export const Typography: FC<TProps> = ({
	children,
	className,
	innerRef,
	multiLine = false,
	noWrap = false,
	relative = false,
	variant = "body_reg"
}) => {
	const classes = useStyles();
	const cssVariant = toCamelCaseVariant(variant);
	const variantClassName = requirePropertyOf(classes, cssVariant);

	return (
		<div
			className={classNames(
				classes.typography,
				variantClassName,
				{ [classes.multiLine]: multiLine, [classes.noWrap]: noWrap, [classes.relative]: relative },
				className
			)}
			ref={innerRef}>
			{children}
		</div>
	);
};
