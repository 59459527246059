import type { UserModel } from "models/UserModel";
import type { TFullTicket } from "./types";
import type { TPageId } from "routes/route";

export const shouldAllowAdminApprove = (isAdmin: boolean, pageId: TPageId | null) =>
	isAdmin && pageId === "RequestsLog";
export const checkUserIsApprover = (ticket: TFullTicket, user: UserModel) =>
	!ticket.ticketApprovalResponses.some(response => response.userId === user.id) &&
	ticket.approvalRequests.some(
		request => request.approvalEntities.some(entity => entity.approverIds.includes(user.id)) || false
	);
