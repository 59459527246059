import { List, Record } from "immutable";
import { removeRedundantSpaces } from "utils/removeRedundantSpaces";
import { ApprovalFlowRequestModel, IApprovalFlowRequestSchema } from "./ApprovalFlowRequestModel";

const defaults = {
	id: "",
	name: null as string | null,
	requests: null as List<ApprovalFlowRequestModel> | null
};

interface IApprovalFlowSchema {
	id: string | null;
	name: string | null;
	requests: IApprovalFlowRequestSchema[] | null;
}
export class ApprovalFlowModel extends Record<typeof defaults>(defaults) {
	static fromServerData(data: unknown) {
		const { id, name, requests } = data as typeof defaults;

		return new ApprovalFlowModel({
			id,
			name,
			requests: requests
				? List(requests.map(request => ApprovalFlowRequestModel.fromServerData(request))).sortBy(r => r.sortOrder)
				: null
		});
	}

	toServerData(): IApprovalFlowSchema {
		const { id, name, requests } = this;
		return {
			id,
			name: name ? removeRedundantSpaces(name) : null,
			requests: requests ? requests.map(request => request.toServerData()).toArray() : null
		};
	}
}
