import { Map } from "immutable";
import { uniq } from "lodash";
import { PolicyModel } from "models/PolicyModel";
import { apiReq } from "utils/apiReq";

export interface ICreatePolicyParams {
	bundleIds: string[];
	directoryGroupIds: string[];
	onCallIntegrationScheduleIds: string[];
	integrationResourceRoleIds: string[];
	sortOrder?: number;
}

export const getPolicies = async () => {
	const { data } = await apiReq("GET", "/v1/policies");
	const parsedData = data as Record<string, unknown>[];

	return Map<string, PolicyModel>(parsedData.map(policy => [policy.id as string, PolicyModel.fromServerData(policy)]));
};

export const createPolicy = async (params: ICreatePolicyParams) => {
	params.bundleIds = uniq(params.bundleIds);
	params.directoryGroupIds = uniq(params.directoryGroupIds);
	params.integrationResourceRoleIds = uniq(params.integrationResourceRoleIds);
	const { data } = await apiReq("POST", "/v1/policies", params);

	return PolicyModel.fromServerData(data.policy as Record<string, unknown>, data.conflicts);
};

export const deletePolicy = async (id: string) => {
	const { data } = await apiReq("DELETE", `/v1/policies/${id}`);

	return data.ok;
};

export const updatePolicy = async (id: string, params: Partial<ICreatePolicyParams>) => {
	const { data } = await apiReq("PUT", `/v1/policies/${id}`, params);

	return PolicyModel.fromServerData(data.policy as Record<string, unknown>, data.conflicts);
};

export const movePolicy = async (id: string, direction: -1 | 1) => {
	await apiReq("PUT", `/v1/policies/${id}/move`, { direction });
};
