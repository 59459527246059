import React from "react";
import classNames from "classnames";
import { Typography, TVariant } from "components/ui/Typography";
import { useStyles } from "./styles";

export const Title: FC<{ noBorder?: boolean; variant?: TVariant; extraMargin?: boolean }> = ({
	children,
	className,
	noBorder,
	variant = "h2",
	extraMargin
}) => {
	const classes = useStyles();
	return (
		<Typography
			variant={variant}
			className={classNames(classes.title, className, {
				[classes.border]: !noBorder,
				[classes.extraMargin]: extraMargin
			})}>
			{children}
		</Typography>
	);
};
