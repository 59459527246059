import React, { useMemo } from "react";
import { List } from "components/ui/List";
import { LoadingDots } from "components/ui/LoadingDots";
import { useApprovalAlgorithms } from "hooks/useApprovalAlgorithms";
import { useBundles } from "hooks/useBundles";
import { useOpenGlobalErrorModal } from "hooks/useGlobalError";
import { boldComponent } from "i18n";
import { BundleAuditLogModel } from "models/auditLogs";
import { IBundleCreatedAuditLogDiffData, IBundleUpdatedAuditLogDiffData } from "models/auditLogs/BundleAuditLogModel";
import { BundleModel } from "models/BundleModel";
import { Trans } from "react-i18next";
import { arrayLengthValueConvertor, getAuditLogDataValues } from "utils/auditLogUtils";
import { IsNullError } from "utils/errors/isNullError";
import { useAllowedDurationsTextConvertor } from "hooks/useAllowedDurationsTextConvertor";
import { AuditLogUser } from "../AuditLogUser";
import { useStyles } from "./styles";
import type { TAuditLogContentComponent } from "./AuditLogContent.types";

const TRANSLATION_PREFIX = "pages.auditLog.auditLogList.bundle.";

type TTranslationTypes =
	| "BundleDeleted"
	| "BundleCreated.title"
	| "BundleCreated.roles.appended"
	| "BundleCreated.tags.appended"
	| "BundleCreated.approvalAlgorithm"
	| "BundleCreated.allowedDurations"
	| "BundleCreated.description"
	| "BundleUpdated.name.same"
	| "BundleUpdated.name.different"
	| "BundleUpdated.category.changed"
	| "BundleUpdated.category.added"
	| "BundleUpdated.category.removed"
	| "BundleUpdated.approvalAlgorithm"
	| "BundleUpdated.allowedDurations.changed"
	| "BundleUpdated.allowedDurations.disabled"
	| "BundleUpdated.allowedDurations.enabled"
	| "BundleUpdated.title"
	| "BundleUpdated.roles.deleted"
	| "BundleUpdated.roles.appended"
	| "BundleUpdated.description.added"
	| "BundleUpdated.description.changed"
	| "BundleUpdated.description.removed"
	| "BundleUpdated.tags.deleted"
	| "BundleUpdated.tags.appended";

const getAuditLogAddedTagsCount = (auditLogData: IBundleCreatedAuditLogDiffData | IBundleUpdatedAuditLogDiffData) => {
	if ("tags" in auditLogData) return auditLogData.tags?.length || 0;
	if ("addedTags" in auditLogData) return auditLogData.addedTags?.length || 0;
	return 0;
};

const translationCountCalculator = new Map<TTranslationTypes, (auditLog: BundleAuditLogModel) => number>([
	["BundleCreated.roles.appended", (auditLog: BundleAuditLogModel) => auditLog.data?.addedResourceRoles?.length || 0],
	[
		"BundleCreated.tags.appended",
		(auditLog: BundleAuditLogModel) => (auditLog.data as IBundleUpdatedAuditLogDiffData)?.addedTags?.length || 0
	],
	["BundleUpdated.roles.appended", (auditLog: BundleAuditLogModel) => auditLog.data?.addedResourceRoles?.length || 0],
	[
		"BundleUpdated.tags.appended",
		(auditLog: BundleAuditLogModel) => (auditLog.data ? getAuditLogAddedTagsCount(auditLog.data) : 0)
	],
	["BundleUpdated.roles.deleted", (auditLog: BundleAuditLogModel) => auditLog.data?.deletedResourceRoles?.length || 0],
	[
		"BundleUpdated.tags.deleted",
		(auditLog: BundleAuditLogModel) => (auditLog.data as IBundleUpdatedAuditLogDiffData)?.deletedTags?.length || 0
	]
]);

const getCount = (auditLog: BundleAuditLogModel, path: TTranslationTypes) =>
	translationCountCalculator.has(path) ? translationCountCalculator.get(path)!(auditLog) : 0;

const getEventTranslationPaths = (auditLog: BundleAuditLogModel, bundle: BundleModel) => {
	const action = auditLog.action;
	if (action === "BundleDeleted") return [action];
	const paths: TTranslationTypes[] = [`${action}.title` as const];

	if (auditLog.data) {
		if (action === "BundleUpdated") {
			if ("toName" in auditLog.data) {
				paths.push(
					auditLog.data.toName === bundle?.name
						? ("BundleUpdated.name.same" as const)
						: ("BundleUpdated.name.different" as const)
				);
			}
			if ("toCategory" in auditLog.data || "fromCategory" in auditLog.data) {
				const { toCategory, fromCategory } = auditLog.data as { toCategory?: string; fromCategory?: string };
				if (fromCategory && toCategory) paths.push("BundleUpdated.category.changed" as const);
				else if (fromCategory && !toCategory) paths.push("BundleUpdated.category.removed" as const);
				else paths.push("BundleUpdated.category.added" as const);
			}
			if ("fromDescription" in auditLog.data || "toDescription" in auditLog.data) {
				const { fromDescription, toDescription } = auditLog.data as {
					fromDescription?: string;
					toDescription?: string;
				};
				if (fromDescription && toDescription) paths.push("BundleUpdated.description.changed");
				else if (fromDescription && !toDescription) paths.push("BundleUpdated.description.removed");
				else if (!fromDescription && toDescription) paths.push("BundleUpdated.description.added");
			}

			if ("addedTags" in auditLog.data) {
				paths.push("BundleUpdated.tags.appended" as const);
			}

			if ("deletedTags" in auditLog.data) {
				paths.push("BundleUpdated.tags.deleted" as const);
			}

			if ("fromAllowedDurations" in auditLog.data || "toAllowedDurations" in auditLog.data) {
				const { fromAllowedDurations, toAllowedDurations } = auditLog.data as {
					toAllowedDurations?: string[];
					fromAllowedDurations?: string[];
				};
				if (fromAllowedDurations && toAllowedDurations) paths.push("BundleUpdated.allowedDurations.changed");
				else if (fromAllowedDurations && !toAllowedDurations) paths.push("BundleUpdated.allowedDurations.disabled");
				else if (!fromAllowedDurations && toAllowedDurations) paths.push("BundleUpdated.allowedDurations.enabled");
			}
		} else if (action === "BundleCreated") {
			if ("description" in auditLog.data && typeof auditLog.data.description === "string") {
				paths.push("BundleCreated.description" as const);
			}
			if ("allowedDurations" in auditLog.data && auditLog.data.allowedDurations?.length) {
				paths.push("BundleCreated.allowedDurations" as const);
			}
		}

		if ("addedResourceRoles" in auditLog.data && Array.isArray(auditLog.data.addedResourceRoles)) {
			paths.push(`${action}.roles.appended` as const);
		}

		if ("deletedResourceRoles" in auditLog.data && Array.isArray(auditLog.data.deletedResourceRoles)) {
			paths.push("BundleUpdated.roles.deleted" as const);
		}

		if ("workflowId" in auditLog.data || "fromWorkflowId" in auditLog.data) {
			paths.push(`${action}.approvalAlgorithm` as const);
		}
	}
	return paths;
};

export const BundleAuditLogContent: TAuditLogContentComponent<BundleAuditLogModel> = ({ auditLog, logUser }) => {
	const bundles = useBundles(true);
	const classes = useStyles();
	const openGlobalErrorModal = useOpenGlobalErrorModal();
	const approvalAlgorithms = useApprovalAlgorithms();
	const { convertAllowedDurationsText } = useAllowedDurationsTextConvertor();

	const bundle = useMemo(() => {
		if (!bundles) return new BundleModel();
		const bundle = bundles.get(auditLog.bundleId);
		if (!bundle) {
			openGlobalErrorModal(
				IsNullError.from({
					location: "bundleAuditLogContentBundle",
					parentObject: {
						name: "bundleAuditLog",
						value: auditLog.toJS()
					},
					requestedProperty: "bundle"
				})
			);
			return new BundleModel();
		}
		return bundle;
	}, [bundles, auditLog, openGlobalErrorModal]);

	const [title, ...changes] = useMemo(() => getEventTranslationPaths(auditLog, bundle), [auditLog, bundle]);

	const values = useMemo(() => {
		if (auditLog.action === "BundleDeleted") return { bundleName: { current: bundle.name } };
		if (auditLog.action === "BundleCreated") {
			const data = auditLog.data || ({} as IBundleCreatedAuditLogDiffData);
			return getAuditLogDataValues(data as IBundleCreatedAuditLogDiffData, [
				{
					field: "name",
					valueField: "bundleName",
					currentValue: bundle.name
				},
				{
					field: "workflowId",
					valueField: "approvalAlgorithmName"
				},
				{
					field: "category",
					valueField: "category",
					valueConvertor: (value: unknown) => (value !== "" ? value : undefined) as string | undefined,
					currentValue: auditLog.bundle?.category
				},
				{
					field: "description",
					valueField: "description",
					currentValue: auditLog.bundle?.description
				},
				{
					field: "tags",
					valueField: "addedTagsCount",
					valueConvertor: arrayLengthValueConvertor
				},
				{
					field: "addedResourceRoles",
					valueField: "addedRolesCount",
					valueConvertor: arrayLengthValueConvertor
				},
				{
					field: "deletedResourceRoles",
					valueField: "deletedRolesCount",
					valueConvertor: arrayLengthValueConvertor
				},
				{
					field: "allowedDurations",
					valueField: "allowedDurations",
					valueConvertor: convertAllowedDurationsText
				}
			]);
		}
		const data = auditLog.data || ({} as IBundleUpdatedAuditLogDiffData);
		return getAuditLogDataValues(data as IBundleUpdatedAuditLogDiffData, [
			{ fromField: "fromName", toField: "toName", valueField: "bundleName", currentValue: bundle.name },
			{ fromField: "fromWorkflowName", toField: "toWorkflowName", valueField: "approvalAlgorithmName" },
			{
				fromField: "fromCategory",
				toField: "toCategory",
				valueField: "category",
				valueConvertor: (value: unknown) => (value !== "" ? value : undefined) as string | undefined,
				currentValue: auditLog.bundle?.category
			},
			{
				fromField: "fromDescription",
				toField: "toDescription",
				valueField: "description",
				currentValue: auditLog.bundle?.description
			},
			{ field: "addedTags", valueField: "addedTagsCount", valueConvertor: arrayLengthValueConvertor },
			{ field: "deletedTags", valueField: "deletedTagsCount", valueConvertor: arrayLengthValueConvertor },
			{ field: "addedResourceRoles", valueField: "addedRolesCount", valueConvertor: arrayLengthValueConvertor },
			{ field: "deletedResourceRoles", valueField: "deletedRolesCount", valueConvertor: arrayLengthValueConvertor },
			{
				fromField: "fromAllowedDurations",
				toField: "toAllowedDurations",
				valueField: "allowedDurations",
				valueConvertor: convertAllowedDurationsText
			}
		]);
	}, [
		auditLog.action,
		auditLog.data,
		auditLog.bundle?.category,
		auditLog.bundle?.description,
		bundle.name,
		convertAllowedDurationsText
	]);

	const props = useMemo(
		() => ({
			values,
			components: {
				bold: boldComponent,
				user: <AuditLogUser user={logUser} className={classes.userText} />
			}
		}),
		[classes.userText, logUser, values]
	);

	return approvalAlgorithms ? (
		<>
			<Trans
				i18nKey={`${TRANSLATION_PREFIX}${title}` as const}
				{...props}
				context={values.category?.new ? "category" : undefined}
			/>
			{changes && (
				<List
					items={changes.map(key => {
						const i18nKey = `${TRANSLATION_PREFIX}${key}` as const;
						return <Trans key={key} i18nKey={i18nKey} {...props} count={getCount(auditLog, key)} />;
					})}
				/>
			)}
		</>
	) : (
		<LoadingDots />
	);
};
