import React, { useCallback } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { Button } from "components/ui/Button";
import { useLoadingState } from "hooks/useLoadingState";
import { useStyles } from "./styles";

type TProps = {
	renew: (requestId: string) => void | Promise<void>;
	requestId: string;
};

export const RequestPastActions: FC<TProps> = ({ renew, className, innerRef, requestId }) => {
	const { t } = useTranslation();
	const classes = useStyles();
	const { withLoader, isLoading } = useLoadingState();
	const handleRenew = useCallback(async () => withLoader(renew(requestId)), [renew, requestId, withLoader]);

	return (
		<div className={classNames(classes.container, className)} ref={innerRef}>
			<Button size="medium" variant="secondary" onClick={handleRenew} loading={isLoading}>
				{t("buttons.renewRequest")}
			</Button>
		</div>
	);
};
