import { useUsersContext } from "context/usersContext";
import { useEffect, useState, useMemo } from "react";

export const useUsers = (withDeleted = true) => {
	const {
		state: { users },
		actions: { loadUsers }
	} = useUsersContext();

	const [loading, setLoading] = useState(false);

	useEffect(() => {
		(async () => {
			if (!users && !loading) {
				setLoading(true);
				await loadUsers();
				setLoading(false);
			}
		})();
	}, [users, loadUsers, loading]);

	const result = useMemo(
		() => (withDeleted ? users : users?.filter(user => user.isDeleted === false) || null),
		[users, withDeleted]
	);

	return result;
};

export const useUsersList = () => {
	const users = useUsers();

	return useMemo(() => users?.toList(), [users]);
};
