import { List, Record } from "immutable";
import { TTicketDuration } from "utils/durationsOptions";
import { IntegrationResourceMaintainerModel } from "./IntegrationResourceMaintainerModel";
import { IntegrationResourceRoleModel } from "./IntegrationResourceRoleModel";
import { IntegrationActorPermissionModel } from "./IntegrationActorPermissionModel";
import { IntegrationModel } from "./IntegrationModel";

const defaults = {
	allowAsGrantMethod: true,
	allowedDurations: null as List<TTicketDuration> | null,
	allowsRequests: false,
	approvalAlgorithmId: "",
	description: null as string | null,
	displayName: "",
	entitlements: null as List<IntegrationActorPermissionModel> | null,
	euid: "",
	givesAccessToRoles: null as List<IntegrationResourceRoleModel> | null,
	id: "",
	integration: null as IntegrationModel | null,
	integrationId: "",
	integrationResourceRoles: null as List<IntegrationResourceRoleModel> | null,
	isDeleted: false,
	maintainers: null as List<IntegrationResourceMaintainerModel> | null,
	multirole: false,
	ownerId: null as string | null,
	prerequisitePermissionId: null as string | null,
	rolesCount: null as number | null,
	tags: null as string[] | null,
	type: "",
	userDefinedDescription: null as string | null,
	userDefinedTags: null as string[] | null
};

export type TIntegrationResourceModel = typeof defaults;

export class IntegrationResourceModel extends Record<TIntegrationResourceModel>(defaults) {
	static fromServerData(data: unknown) {
		const {
			allowAsGrantMethod,
			allowedDurations,
			allowsRequests,
			approvalAlgorithmId,
			description,
			displayName,
			entitlements,
			euid,
			givesAccessToRoles,
			id,
			integration,
			integrationId,
			integrationResourceRoles,
			isDeleted,
			maintainers,
			multirole,
			ownerId,
			prerequisitePermissionId,
			rolesCount,
			tags,
			type,
			userDefinedDescription,
			userDefinedTags
		} = data as typeof defaults;

		return new IntegrationResourceModel({
			allowAsGrantMethod,
			allowedDurations: allowedDurations ? List(allowedDurations) : null,
			allowsRequests,
			approvalAlgorithmId,
			description,
			displayName,
			entitlements: entitlements ? List(entitlements.map(IntegrationActorPermissionModel.fromServerData)) : null,
			euid,
			givesAccessToRoles: givesAccessToRoles
				? List(givesAccessToRoles.map(IntegrationResourceRoleModel.fromServerData))
				: null,
			id,
			integration: integration ? IntegrationModel.fromServerData(integration) : null,
			integrationId,
			integrationResourceRoles: integrationResourceRoles
				? List(integrationResourceRoles.map(IntegrationResourceRoleModel.fromServerData))
				: null,
			isDeleted,
			maintainers: maintainers ? List(maintainers.map(IntegrationResourceMaintainerModel.fromServerData)) : null,
			multirole,
			ownerId,
			prerequisitePermissionId,
			rolesCount: typeof rolesCount === "number" ? Number(rolesCount) : null,
			tags,
			type,
			userDefinedDescription,
			userDefinedTags
		});
	}

	get calculatedTags() {
		return (this.tags || []).concat(this.userDefinedTags || []);
	}

	get calculatedDescription() {
		return this.userDefinedDescription ?? this.description;
	}
}
