import React, { useMemo } from "react";
import { Select } from "components/ui/Select";
import { UserModel } from "models/UserModel";
import { useUsers } from "hooks/useUsers";
import { UserOption } from "components/common/UserOption";
import { sortByFullName } from "utils/sortUtils";
import { UserWithEmail } from "components/common/UserWithEmail";
import { useNewTicketFormContext } from "components/pages/NewTicketPage/newTicketContext";
import { useTranslation } from "react-i18next";
import { Form } from "components/ui/Form";

const OPTIONS_LIMIT = 100;

const getLabel = (user: UserModel) => user?.fullName;
const equality = (option: UserModel, value: UserModel) => option?.id === value?.id;
const renderOption = (option: UserModel) => <UserWithEmail user={option} />;
const renderLabel = (option: UserModel) => <UserOption option={option} noEmail />;

export const BehalfOfField: FC = () => {
	const {
		state: { receiverUser },
		actions: { setReceiverUser }
	} = useNewTicketFormContext();

	const { t } = useTranslation(undefined, { keyPrefix: "pages.newTicket.newTicketForm.onBehalfOfInput" });

	const users = useUsers();

	const options = useMemo(
		() =>
			users
				?.toList()
				?.toArray()
				?.filter(user => user.fullName.trim() !== "")
				?.filter(user => !user.isDeleted) || [],
		[users]
	);

	const loading = !options || options.length === 0;

	return (
		<Form.Field>
			<Select
				disabled={loading}
				fullWidth
				getOptionLabel={getLabel}
				isOptionEqualToValue={equality}
				label={t("label")}
				loading={loading}
				onChange={setReceiverUser}
				options={options}
				limit={OPTIONS_LIMIT}
				sort={sortByFullName}
				renderLabel={renderLabel}
				renderOption={renderOption}
				value={receiverUser || null}
			/>
		</Form.Field>
	);
};
