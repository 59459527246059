import { IsNullError } from "utils/errors/isNullError";
import type { PartialOrNullWithOneRequired } from "utils/types";
import type { TRoleOption, TFormData } from "../types";

const getBundleTarget = (bundle: { id: string }) => {
	return { type: "bundle" as const, id: bundle.id };
};

const getRoleTarget = (target: TRoleOption, grantMethodId?: string) => {
	return { type: "role" as const, id: grantMethodId || target.value.id };
};

export const parseNewTicketFormData = (
	formData: PartialOrNullWithOneRequired<TFormData, "targets" | "grantMethods" | "receiverIntegrationActorIds">,
	withDuration = false
) => {
	if (!formData.duration) {
		throw IsNullError.from({
			location: "parseNewTicketFormData",
			parentObject: { name: "formData", value: formData },
			requestedProperty: "duration"
		});
	}

	if (withDuration && (!formData.justification || formData.justification.length === 0)) {
		throw IsNullError.from({
			location: "parseNewTicketFormData",
			parentObject: { name: "formData", value: formData },
			requestedProperty: "justification"
		});
	}

	const receiverId: string = formData.receiverUser?.id || "";
	const targets = formData.targets.map(target =>
		target.type === "bundle"
			? getBundleTarget(target.value)
			: getRoleTarget(target, formData.grantMethods.get(target.value.id)?.id)
	);

	const receiverIntegrationActorIds = formData.receiverIntegrationActorIds.toJS() as Record<string, string | null>;

	return {
		ticketingIntegrationTicketId: formData.ticketingIntegrationTicketId ?? undefined,
		duration: formData.duration,
		receiverIntegrationActorIds,
		receiverId,
		targets
	};
};
