import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { List, Map } from "immutable";

import { Typography } from "components/ui/New/Typography";
import { ApprovalProcessStep } from "./components/ApprovalProcessStep";

import { useStyles } from "./styles";

import { AdminStep } from "./components/ApprovalProcessStep/AdminStep";
import type { TFullApprovalRequest, TAdminResponse } from "components/common/RequestDetails";
import type { TicketApprovalResponseModel } from "models/TicketApprovalResponseModel";

interface IProps {
	approvalRequests: List<TFullApprovalRequest>;
	approvalResponses: List<TicketApprovalResponseModel>;
}

export const ApprovalProcess = ({ approvalRequests, approvalResponses }: IProps) => {
	const { t } = useTranslation("translation", { keyPrefix: "common.requestDetails.approvalProcess" });
	const classes = useStyles();

	const adminResponse = useMemo(
		() => approvalResponses.find(response => response.type === "adminApprove" || response.type === "adminDecline"),
		[approvalResponses]
	);
	const approved = useMemo(
		() => !adminResponse && approvalRequests.every(request => request.status === "approved"),
		[adminResponse, approvalRequests]
	);
	const responsesByUserId = useMemo(
		() => Map(approvalResponses.map(response => [(response.userId ?? response.webhookId)!, response.type])),
		[approvalResponses]
	);

	const groupedRequestsOrdered = useMemo(
		() =>
			adminResponse
				? []
				: (
						Array.from(approvalRequests.groupBy(request => request.sortOrder).entries()) as [
							number,
							List<TFullApprovalRequest>
						][]
					).sort(([sortOrderA], [sortOrderB]) => sortOrderA - sortOrderB),
		[adminResponse, approvalRequests]
	);

	return (
		<div className={classes.approvalProcessContainer}>
			<Typography variant="text_reg">{t("title")}</Typography>
			<div className={classes.stepsContainer}>
				{adminResponse && <AdminStep adminResponse={adminResponse as TAdminResponse} />}
				{groupedRequestsOrdered.map(([sortOrder, requests]: [number, List<TFullApprovalRequest>]) => (
					<ApprovalProcessStep
						key={sortOrder}
						active={approved}
						approvalRequests={requests}
						responsesByUserId={responsesByUserId}
						sortOrder={sortOrder}
					/>
				))}
			</div>
		</div>
	);
};
