import React, { useCallback, useRef, useState } from "react";
import { List, Map } from "immutable";
import constate from "constate";
import isEqual from "lodash/isEqual";
import hash from "object-hash";
import {
	getIntegrations,
	getIntegrationsResources,
	getIntegrationResourcesRoles,
	getAllowedDurations,
	getGrantMethods,
	search,
	getBundles
} from "api/accessRequestForm";
import { toMapBy } from "utils/toMapBy";
import { useOpenGlobalErrorModal } from "hooks/useGlobalError";
import { IntegrationResourceModel } from "models/IntegrationResourceModel";
import { IntegrationResourceRoleModel } from "models/IntegrationResourceRoleModel";
import { IntegrationModel } from "models/IntegrationModel";
import { BundleModel } from "models/BundleModel";
import { Provider } from "context/Provider";
import type { Require } from "utils/types";
import type { TTicketDuration } from "utils/durationsOptions";
import type { ITargetData } from "api/tickets";
import type { TNewTicketOption } from "../NewTicketPage/components/NewTicketForm/types";

type TLoadingState = "Initial" | "Loading" | "Loaded" | "Error";

type TIntegrationResourceQueryData = {
	integrationResources: Map<string, IntegrationResourceModel>;
	totalAmount: number;
	lastSearch?: string;
};
type TIntegrationResourceMap = Map<string, TIntegrationResourceQueryData>;

type TIntegrationResourceRoleQueryData = {
	integrationResourceRoles: Map<string, IntegrationResourceRoleModel>;
	totalAmount: number;
	lastSearch?: string;
};
type TIntegrationResourceRoleMap = Map<string, TIntegrationResourceRoleQueryData>;

type TWithLastUserId<T> =
	T extends Map<unknown, unknown> ? { lastUserId?: string; map: T } : T & { lastUserId?: string };

type TBundlesMap = {
	bundles: Map<string, Require<BundleModel, "bundleItems">>;
	totalAmount: number;
};
type TIntegrationsMap = { integrations: Map<string, IntegrationModel>; totalAmount: number };

type TGrantMethodsQueryData = { grantMethods: Map<string, IntegrationResourceRoleModel>; totalAmount: number };
type TGrantMethodsMap = Map<string, TGrantMethodsQueryData>;

const useQueryOnRequest = <T extends Record<string, unknown>, R>(
	fetchMethod: (options: T) => Promise<R>
): { fetch: (options: T) => Promise<R | null>; loadingState: TLoadingState } => {
	const lastQuery = useRef<string>();
	const loadingStateRef = useRef<Map<string, TLoadingState>>(Map());
	const [loadingState, setLoadingState] = useState<Map<string, TLoadingState>>(Map());
	const openGlobalErrorModal = useOpenGlobalErrorModal();

	const fetch = useCallback(
		async (options: T) => {
			const hashKey = hash(options);

			if (loadingStateRef.current.get(hashKey) !== "Error") {
				if (
					(!lastQuery.current && !options) ||
					isEqual(lastQuery.current, hashKey) ||
					loadingStateRef.current.get(hashKey) === "Loading"
				)
					return null;
			}

			loadingStateRef.current = loadingStateRef.current.set(hashKey, "Loading");
			setLoadingState(loadingStateRef.current);
			lastQuery.current = hashKey;
			try {
				const result = await fetchMethod(options);
				loadingStateRef.current = loadingStateRef.current.set(hashKey, "Loaded");
				setLoadingState(loadingStateRef.current);
				return result;
			} catch (error) {
				loadingStateRef.current = loadingStateRef.current.set(hashKey, "Error");
				setLoadingState(loadingStateRef.current);
				openGlobalErrorModal(error as Error);
				return null;
			}
		},
		[fetchMethod, openGlobalErrorModal]
	);

	return { fetch, loadingState: loadingState.get(lastQuery.current || "") || "Initial" };
};

const useNewRequestBundlesData = () => {
	const [allBundles, setAllBundles] = useState<TWithLastUserId<TBundlesMap>>({ totalAmount: 0, bundles: Map() });
	const [bundles, setBundles] = useState<TBundlesMap>({ totalAmount: 0, bundles: Map() });
	const { fetch: bundlesFetch, loadingState: bundlesLoadingState } = useQueryOnRequest(getBundles);

	const wrappedFetchBundles = useCallback(
		async (options: { userId: string; page?: number }) => {
			const { userId, page = 1 } = options;
			const bundlesResult = await bundlesFetch({ userId, page });
			if (!bundlesResult) return;
			const mappedBundles = toMapBy(bundlesResult.result, bundle => bundle.id);
			setBundles({ bundles: mappedBundles, totalAmount: bundlesResult.pagination.totalResults });
			setAllBundles(current => {
				if (current.lastUserId && current.lastUserId !== userId) {
					return { bundles: mappedBundles, totalAmount: bundlesResult.pagination.totalResults, lastUserId: userId };
				}
				return {
					bundles: current.bundles.merge(mappedBundles),
					totalAmount: Math.max(current.totalAmount, bundlesResult.pagination.totalResults),
					lastUserId: userId
				};
			});
		},
		[bundlesFetch]
	);

	return {
		allData: allBundles,
		data: bundles,
		loadingState: bundlesLoadingState,
		fetch: wrappedFetchBundles
	};
};

const useNewRequestIntegrationsData = () => {
	const [allIntegrations, setAllIntegrations] = useState<TWithLastUserId<TIntegrationsMap>>({
		totalAmount: 0,
		integrations: Map()
	});
	const [integrations, setIntegrations] = useState<TIntegrationsMap>({ totalAmount: 0, integrations: Map() });
	const { fetch: integrationsFetch, loadingState: integrationsLoadingState } = useQueryOnRequest(getIntegrations);

	const wrappedFetchIntegrations = useCallback(
		async (options: { userId: string; page?: number }) => {
			const { userId, page = 1 } = options;
			const integrationsResult = await integrationsFetch({ userId, page });
			if (!integrationsResult) return;
			const mappedIntegrations = toMapBy(integrationsResult.result, integration => integration.id);
			setIntegrations({ integrations: mappedIntegrations, totalAmount: integrationsResult.pagination.totalResults });
			setAllIntegrations(current => {
				if (current.lastUserId && current.lastUserId !== userId) {
					return {
						integrations: mappedIntegrations,
						totalAmount: integrationsResult.pagination.totalResults,
						lastUserId: userId
					};
				}
				return {
					integrations: current.integrations.merge(mappedIntegrations),
					totalAmount: Math.max(current.totalAmount, integrationsResult.pagination.totalResults),
					lastUserId: userId
				};
			});
		},
		[integrationsFetch]
	);

	return {
		allData: allIntegrations,
		data: integrations,
		loadingState: integrationsLoadingState,
		fetch: wrappedFetchIntegrations
	};
};

const useNewRequestIntegrationResourcesData = () => {
	const [allIntegrationResources, setAllIntegrationResources] = useState<TWithLastUserId<TIntegrationResourceMap>>({
		map: Map()
	});
	const [integrationResources, setIntegrationResources] = useState<TIntegrationResourceMap>(Map());
	const { fetch: resourcesFetch, loadingState: resourceLoadingState } = useQueryOnRequest(getIntegrationsResources);

	const wrappedFetchIntegrationResources = useCallback(
		async (options: { userId: string; integrationId: string; search?: string }) => {
			const { integrationId, search, userId } = options;
			const resourcesResult = await resourcesFetch({ userId, integrationIds: [integrationId], search });
			if (!resourcesResult) return;
			const mappedResources = toMapBy(resourcesResult.result, resource => resource.id);
			setIntegrationResources(current => {
				return current.set(integrationId, {
					integrationResources: mappedResources,
					totalAmount: resourcesResult.pagination.totalResults,
					lastSearch: search
				});
			});
			setAllIntegrationResources(current => {
				if (current.lastUserId && current.lastUserId !== userId) {
					return {
						map: Map([
							[
								integrationId,
								{ integrationResources: mappedResources, totalAmount: resourcesResult.pagination.totalResults }
							]
						]),
						lastUserId: userId
					};
				}
				const currentIntegration = current.map.get(integrationId);
				return {
					map: current.map.set(integrationId, {
						integrationResources: currentIntegration?.integrationResources.merge(mappedResources) || mappedResources,
						totalAmount: Math.max(currentIntegration?.totalAmount || 0, resourcesResult.pagination.totalResults)
					}),
					lastUserId: userId
				};
			});
		},
		[resourcesFetch]
	);

	return {
		allData: allIntegrationResources.map,
		data: integrationResources,
		loadingState: resourceLoadingState,
		fetch: wrappedFetchIntegrationResources
	};
};

const useNewRequestIntegrationResourceRolesData = () => {
	const [allIntegrationResourceRoles, setAllIntegrationResourceRoles] = useState<
		TWithLastUserId<TIntegrationResourceRoleMap>
	>({ map: Map() });
	const [integrationResourceRoles, setIntegrationResourceRoles] = useState<TIntegrationResourceRoleMap>(Map());
	const { fetch: rolesFetch, loadingState: rolesLoadingState } = useQueryOnRequest(getIntegrationResourcesRoles);

	const wrappedFetchIntegrationResourceRoles = useCallback(
		async (options: { userId: string; integrationResourceId: string; search?: string }) => {
			const { integrationResourceId, search, userId } = options;
			const rolesResult = await rolesFetch({
				userId,
				integrationResourceIds: [integrationResourceId],
				search
			});
			if (!rolesResult) return;
			const mappedRoles = toMapBy(rolesResult.result, role => role.id);
			setIntegrationResourceRoles(current => {
				return current.set(integrationResourceId, {
					integrationResourceRoles: mappedRoles,
					totalAmount: rolesResult.pagination.totalResults,
					lastSearch: search
				});
			});
			setAllIntegrationResourceRoles(current => {
				if (current.lastUserId && current.lastUserId !== userId) {
					return {
						map: Map([
							[
								integrationResourceId,
								{ integrationResourceRoles: mappedRoles, totalAmount: rolesResult.pagination.totalResults }
							]
						]),
						lastUserId: userId
					};
				}
				const currentResource = current.map.get(integrationResourceId);
				return {
					map: current.map.set(integrationResourceId, {
						integrationResourceRoles: currentResource?.integrationResourceRoles.merge(mappedRoles) || mappedRoles,
						totalAmount: Math.max(currentResource?.totalAmount || 0, rolesResult.pagination.totalResults)
					}),
					lastUserId: userId
				};
			});
		},
		[rolesFetch]
	);

	return {
		allData: allIntegrationResourceRoles.map,
		data: integrationResourceRoles,
		loadingState: rolesLoadingState,
		fetch: wrappedFetchIntegrationResourceRoles
	};
};

const useNewRequestAllowedDurationsData = () => {
	const [allowedDurations, setAllowedDurations] = useState(List<TTicketDuration>());
	const { fetch: durationsFetch, loadingState: durationsLoadingState } = useQueryOnRequest(getAllowedDurations);

	const wrappedFetchAllowedDurations = useCallback(
		async (options: { targets: ITargetData[]; userId: string }) => {
			const allowedDurationsResult = await durationsFetch(options);
			if (!allowedDurationsResult) return;
			setAllowedDurations(List(allowedDurationsResult));
		},
		[durationsFetch]
	);

	return {
		data: allowedDurations,
		loadingState: durationsLoadingState,
		fetch: wrappedFetchAllowedDurations
	};
};

const useNewRequestGrantMethodsData = () => {
	const [grantMethods, setGrantMethods] = useState<TGrantMethodsMap>(Map());
	const { fetch: grantMethodsFetch, loadingState: grantMethodsLoadingState } = useQueryOnRequest(getGrantMethods);

	const wrappedFetchGrantMethods = useCallback(
		async (options: { userId: string; roleId: string; page?: number }) => {
			const grantMethodsResult = await grantMethodsFetch(options);
			if (!grantMethodsResult) return;
			const mappedGrantMethods = toMapBy(grantMethodsResult.result, grantMethod => grantMethod.id);
			setGrantMethods(current => {
				return current.set(options.roleId, {
					grantMethods: mappedGrantMethods,
					totalAmount: grantMethodsResult.pagination.totalResults
				});
			});
		},
		[grantMethodsFetch]
	);

	return {
		data: grantMethods,
		loadingState: grantMethodsLoadingState,
		fetch: wrappedFetchGrantMethods
	};
};

const useNewRequestSearchResultsData = () => {
	const [searchResults, setSearchResults] = useState<List<TNewTicketOption>>(List());
	const { fetch: searchFetch, loadingState: searchLoadingState } = useQueryOnRequest(search);

	const wrappedFetchSearchResults = useCallback(
		async (options: { userId: string; search: string }) => {
			const searchResult = await searchFetch(options);
			if (!searchResult) return;
			setSearchResults(List(searchResult.result));
		},
		[searchFetch]
	);

	return {
		data: searchResults,
		loadingState: searchLoadingState,
		fetch: wrappedFetchSearchResults
	};
};

const [NewRequestBundlesProvider, useNewRequestBundles] = constate(useNewRequestBundlesData);
const [NewRequestIntegrationsProvider, useNewRequestIntegrations] = constate(useNewRequestIntegrationsData);
const [NewRequestIntegrationResourcesProvider, useNewRequestIntegrationResources] = constate(
	useNewRequestIntegrationResourcesData
);
const [NewRequestIntegrationResourceRolesProvider, useNewRequestIntegrationResourceRoles] = constate(
	useNewRequestIntegrationResourceRolesData
);
const [NewRequestAllowedDurationsProvider, useNewRequestAllowedDurations] = constate(useNewRequestAllowedDurationsData);
const [NewRequestGrantMethodsProvider, useNewRequestGrantMethods] = constate(useNewRequestGrantMethodsData);
const [NewRequestSearchResultsProvider, useNewRequestSearchResults] = constate(useNewRequestSearchResultsData);

const NewRequestDataProvider: FC = ({ children }) => {
	return (
		<Provider
			providers={[
				NewRequestBundlesProvider,
				NewRequestIntegrationsProvider,
				NewRequestIntegrationResourcesProvider,
				NewRequestIntegrationResourceRolesProvider,
				NewRequestAllowedDurationsProvider,
				NewRequestGrantMethodsProvider,
				NewRequestSearchResultsProvider
			]}>
			<>{children}</>
		</Provider>
	);
};

export {
	NewRequestDataProvider,
	useNewRequestBundles,
	useNewRequestIntegrations,
	useNewRequestIntegrationResources,
	useNewRequestIntegrationResourceRoles,
	useNewRequestAllowedDurations,
	useNewRequestGrantMethods,
	useNewRequestSearchResults
};
