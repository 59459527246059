import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	activity: {
		display: "flex",
		flexDirection: "column",
		flexGrow: 1,
		padding: "1.45rem"
	},
	activityTitle: {
		color: "var(--color-black)",
		paddingBottom: "1rem"
	},
	activityTimeline: {
		display: "flex",
		flexDirection: "column",
		flexGrow: 1,
		justifyContent: "space-between"
	},
	activityTimelineList: {
		display: "flex",
		flexDirection: "column"
	},
	ticketingIntegrationTicketChip: {
		display: "inline-block",
		margin: "0 0.1rem"
	},
	auditLogEntity: {
		alignItems: "baseline",
		verticalAlign: "middle"
	},
	activityTimelineAddComment: {}
});
