import React from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { ErrorModal } from "components/ui/ErrorModal";
import { LoadingOverlay } from "components/ui/LoadingOverlay";
import { useUser } from "hooks/useUser";
import { useAuthContext } from "context/authContext";
import { useStyles } from "./styles";

export const LoadingUserInfoPage: FC = ({ className }) => {
	const { loading, error, reloadUser } = useUser();
	const { signOut } = useAuthContext();
	const { t } = useTranslation();
	const classes = useStyles();

	return (
		<div className={classNames(classes.loadingUserInfoPage, className)}>
			<div className={classes.overlays}>
				<LoadingOverlay isOpen={loading} />
				{!loading && (
					<ErrorModal
						isOpen={!!error}
						error={error}
						onRetry={reloadUser}
						onClose={signOut}
						closeText={t("loadingUserInfoPage.signOut")}
					/>
				)}
			</div>
		</div>
	);
};
