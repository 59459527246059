import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	actions: {
		alignItems: "center",
		display: "flex",
		justifyContent: "center"
	},
	creationCell: {
		padding: 0
	},
	actionsCell: {
		display: "flex",
		justifyContent: "center"
	}
});
