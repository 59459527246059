import { List, Record } from "immutable";
import { ApprovalAlgorithmRuleModel, IApprovalAlgorithmRuleSchema } from "models/ApprovalAlgorithmRuleModel";
import { removeRedundantSpaces } from "utils/removeRedundantSpaces";

const defaults = {
	id: "",
	name: "",
	rules: null as List<ApprovalAlgorithmRuleModel> | null,
	isDeleted: false
};

export interface IApprovalAlgorithmSchema {
	id: string | null;
	name: string;
	rules: IApprovalAlgorithmRuleSchema[];
}

export class ApprovalAlgorithmModel extends Record<typeof defaults>(defaults) {
	static fromServerData(data: unknown) {
		const { id, name, rules, isDeleted } = data as typeof defaults;

		return new ApprovalAlgorithmModel({
			id,
			name,
			isDeleted,
			rules: rules ? List(rules.map(rule => ApprovalAlgorithmRuleModel.fromServerData(rule))) : null
		});
	}

	toServerData(): IApprovalAlgorithmSchema {
		const { id, name, rules } = this;
		return {
			id,
			name: removeRedundantSpaces(name),
			rules: rules ? rules.map(rule => rule.toServerData()).toArray() : []
		};
	}
}
