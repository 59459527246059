import React, { useCallback, useMemo } from "react";
import { IntegrationResourceModel } from "models/IntegrationResourceModel";
import { Select } from "components/ui/Select";
import { IPaginatedSearchOptions } from "utils/searchUtils";
import { getIntegrationResources } from "api/integrationResources";
import { useSelectSearchProps } from "hooks/useSelectSearchProps";
import { sortByDisplayName } from "utils/sortUtils";
import { Typography } from "components/ui/Typography";
import { EntityOption } from "../EntityOption";
import { useStyles } from "./styles";

interface IProps {
	disabled?: boolean;
	error?: string;
	fullWidth?: boolean;
	inputLabel: string;
	limit?: number;
	onChange: (value: IntegrationResourceModel | null) => void;
	placeholder?: string;
	resourceTypes?: string[];
	selected: IntegrationResourceModel | null;
	selectedIntegrationId?: string;
	shouldFilter?: (integrationResource: IntegrationResourceModel) => boolean;
	userId?: string;
	required?: boolean;
}

const getOptionLabel = (option: IntegrationResourceModel) => option?.displayName || "";
const equality = (option: IntegrationResourceModel, selected: IntegrationResourceModel) => option.id === selected?.id;

const groupBy = (option: IntegrationResourceModel) => option.type;

const OPTIONS_LIMIT = 100;

const ResourceOption = (resource: IntegrationResourceModel) => {
	const entity = useMemo(() => ({ name: resource.displayName, ...resource.toJSON() }), [resource]);

	return <EntityOption entity={entity} key={resource.id} />;
};

export const ResourceSelectInput: FC<IProps> = ({
	className,
	disabled = false,
	error = "",
	fullWidth = true,
	inputLabel,
	limit = OPTIONS_LIMIT,
	onChange,
	placeholder,
	resourceTypes,
	selected,
	selectedIntegrationId,
	shouldFilter,
	userId,
	required = false
}) => {
	const classes = useStyles();

	const fetchHandler = useCallback(
		async (query: string) => {
			if (selectedIntegrationId && !disabled) {
				const searchQuery: IPaginatedSearchOptions = {
					search: { query },
					pagination: { perPage: limit },
					sort: { fields: ["type", "displayName"], order: "ASC" }
				};

				const { result } = await getIntegrationResources(selectedIntegrationId, searchQuery, resourceTypes, userId);

				return result.toArray();
			}

			return [];
		},
		[disabled, limit, resourceTypes, selectedIntegrationId, userId]
	);
	const { selectProps } = useSelectSearchProps(fetchHandler, selected, true);

	const resources = selectProps.options;

	const filteredResources = useMemo(
		() => (shouldFilter ? resources.filter(resource => shouldFilter(resource)) : resources),
		[shouldFilter, resources]
	);

	const ResourceLabel = (resource: IntegrationResourceModel) => {
		return <Typography className={classes.resourceTitle}>{resource.displayName}</Typography>;
	};

	const errors = useMemo(() => (error ? [error] : []), [error]);

	return (
		<Select
			{...selectProps}
			options={filteredResources}
			className={className}
			disabled={!selectedIntegrationId || disabled}
			fullWidth={fullWidth}
			getOptionLabel={getOptionLabel}
			groupBy={resourceTypes?.length !== 1 ? groupBy : undefined}
			isOptionEqualToValue={equality}
			label={inputLabel}
			limit={limit}
			placeholder={placeholder}
			required={required}
			renderOption={ResourceOption}
			renderLabel={ResourceLabel}
			onChange={onChange}
			filter={null}
			sort={sortByDisplayName}
			value={selected}
			errors={errors}
		/>
	);
};
