import { List } from "components/ui/List";
import { LoadingDots } from "components/ui/LoadingDots";
import { useApprovalAlgorithms } from "hooks/useApprovalAlgorithms";
import { useIntegrationResource } from "hooks/useIntegrationResource";
import { useIntegrations } from "hooks/useIntegrations";
import { useUsers } from "hooks/useUsers";
import { boldComponent } from "i18n";
import { IntegrationResourceAuditLogModel } from "models/auditLogs";
import { IIntegrationResourceAuditLogDiffData } from "models/auditLogs/IntegrationResourceAuditLogModel";
import { IntegrationResourceModel } from "models/IntegrationResourceModel";
import React, { useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";
import { getAuditLogDataValues, TDiffObject } from "utils/auditLogUtils";
import { Typography } from "components/ui/Typography";
import { TC } from "components/common/TicketActivity/utils";
import { AuditLogUser } from "../AuditLogUser";
import { useStyles } from "./styles";
import type { TTicketDuration } from "utils/durationsOptions";
import type { TAuditLogContentComponent } from "./AuditLogContent.types";

const TRANSLATION_PREFIX = "pages.auditLog.auditLogList.integrationResource";

type TTranslationTypes =
	| "ResourceCreatedManually"
	| "ResourceDeletedManually"
	| "ResourceUpdatedName.same"
	| "ResourceUpdatedName.different"
	| "ResourceUpdatedWorkflow.changed"
	| "ResourceUpdatedWorkflow.added"
	| "ResourceUpdatedWorkflow.removed"
	| "ResourceUpdatedOwner"
	| "ResourceUpdatedAllowAsGrantMethod.allow"
	| "ResourceUpdatedAllowAsGrantMethod.prevent"
	| "ResourceUpdatedAllowRequests.allow"
	| "ResourceUpdatedAllowRequests.prevent"
	| "ResourceUpdatedDescription.same"
	| "ResourceUpdatedDescription.different"
	| "ResourceUpdatedDescription.new"
	| "ResourceUpdatedMaintainers"
	| "ResourceUpdatedTags.different"
	| "ResourceUpdatedTags.new"
	| "ResourceUpdatedOverrideAllowedDurations.disabled"
	| "ResourceUpdatedOverrideAllowedDurations.enabled"
	| "ResourceUpdatedOverrideAllowedDurations.changed";

const getEventTranslationPath = (
	auditLog: IntegrationResourceAuditLogModel,
	integrationResource: IntegrationResourceModel | null
): TTranslationTypes[] => {
	const action = auditLog.action;
	if (action === "ResourceUpdatedName") {
		if (!auditLog.data?.displayName) return [];
		const { to } = auditLog.data.displayName as { to?: string };
		return [`ResourceUpdatedName.${integrationResource?.displayName === to && to ? "same" : "different"}` as const];
	}
	if (action === "ResourceUpdatedWorkflow") {
		if (!auditLog.data?.approvalAlgorithmId) return [];
		const { from, to } = auditLog.data.approvalAlgorithmId as TDiffObject<string>;
		if (from) {
			return [`ResourceUpdatedWorkflow.${to ? "changed" : "removed"}` as const];
		}
		return ["ResourceUpdatedWorkflow.added" as const];
	}
	if (action === "ResourceUpdatedAllowAsGrantMethod") {
		if (!auditLog.data?.allowAsGrantMethod) return [];
		const { to } = auditLog.data.allowAsGrantMethod as TDiffObject<boolean>;
		return [`ResourceUpdatedAllowAsGrantMethod.${to ? "allow" : "prevent"}` as const];
	}
	if (action === "ResourceUpdatedAllowRequests") {
		if (!auditLog.data?.allowsRequests) return [];
		const { to } = auditLog.data.allowsRequests as TDiffObject<boolean>;
		return [`ResourceUpdatedAllowRequests.${to ? "allow" : "prevent"}` as const];
	}

	if (action === "ResourceUpdatedDescription") {
		if (!auditLog.data?.userDefinedDescription) return [];
		const { from, to } = auditLog.data.userDefinedDescription as TDiffObject<string>;
		return [`ResourceUpdatedDescription.${from ? (from === to ? "same" : "different") : "new"}` as const];
	}

	if (action === "ResourceUpdatedTags") {
		if (!auditLog.data?.userDefinedTags) return [];
		const { from } = auditLog.data.userDefinedTags as TDiffObject<string>;

		return [`ResourceUpdatedTags.${from ? "different" : "new"}` as const];
	}

	if (action === "ResourceUpdatedOverrideAllowedDurations") {
		if (!auditLog.data) return [];
		const { to, from } = auditLog.data.allowedDurations as { to?: number[] | null; from?: number[] | null };
		if (!to?.length) return ["ResourceUpdatedOverrideAllowedDurations.disabled" as const];
		if (!from?.length) return ["ResourceUpdatedOverrideAllowedDurations.enabled" as const];
		return ["ResourceUpdatedOverrideAllowedDurations.changed" as const];
	}
	return [action];
};

const InheritOwner: FC = () => {
	const { t } = useTranslation();
	const classes = useStyles();
	return <Typography className={classes.userName}>{t("shared.ownerInherit")}</Typography>;
};

const Content: TAuditLogContentComponent<IntegrationResourceAuditLogModel> = ({ auditLog, logUser }) => {
	const { t } = useTranslation();
	const classes = useStyles();
	const approvalAlgorithms = useApprovalAlgorithms();
	const integrations = useIntegrations(true);
	const { resource } = useIntegrationResource(auditLog.integrationResourceId, false, true);
	const users = useUsers();

	const integration = useMemo(
		() => (integrations && auditLog.integrationId ? integrations.get(auditLog.integrationId) : null),
		[auditLog.integrationId, integrations]
	);

	const values = useMemo(() => {
		if (!resource) return {};
		const data = auditLog.data || ({} as IIntegrationResourceAuditLogDiffData);
		return getAuditLogDataValues(data, [
			{
				field: "displayName",
				valueField: "resourceName",
				valueConvertor: (value: unknown) => value as string | undefined,
				currentValue: resource.displayName
			},
			{
				field: "approvalAlgorithmId",
				valueField: "approvalAlgorithmName",
				valueConvertor: (value: unknown) => approvalAlgorithms?.get(value as string)?.name || ""
			},
			{
				field: "owner",
				valueField: "owner"
			},
			{
				field: "allowsRequests",
				valueField: "allowsRequests"
			},
			{
				field: "userDefinedDescription",
				valueField: "userDefinedDescription"
			},
			{
				field: "userDefinedTags",
				valueField: "userDefinedTags"
			},
			{
				field: "allowedDurations",
				valueField: "allowedDurations",
				valueConvertor: (value: unknown) => {
					if (!value) return;
					const parsedDurations = [] as string[];
					for (const duration of value as number[]) {
						parsedDurations.push(t(`common.durations.${duration as TTicketDuration}`));
					}
					return parsedDurations.join(", ");
				}
			}
		]);
	}, [approvalAlgorithms, auditLog.data, resource, t]);

	const oldOwner = useMemo(() => {
		if (!users || !values.owner || values.owner.old === undefined) {
			return <TC />;
		}
		const isInherit = values.owner.old === null;
		const oldOwnerUser = users.get(values.owner.old);
		return oldOwnerUser ? (
			<AuditLogUser user={oldOwnerUser} className={classes.userText} />
		) : isInherit ? (
			<InheritOwner />
		) : (
			<TC />
		);
	}, [classes, users, values.owner]);

	const owner = useMemo(() => {
		if (!users || !values.owner || (values.owner.new === undefined && values.owner.current === undefined))
			return <TC />;
		const valuesOwner = values.owner.new !== undefined ? values.owner.new : values.owner.current;
		const isInherit = valuesOwner === null;
		const ownerUser = users.get(valuesOwner || "");
		return ownerUser ? (
			<AuditLogUser user={ownerUser} className={classes.userText} />
		) : isInherit ? (
			<InheritOwner />
		) : (
			<TC />
		);
	}, [classes, users, values.owner]);

	const props = useMemo(
		() => ({
			values: { ...values, integrationName: integration?.name },
			components: {
				bold: boldComponent,
				user: <AuditLogUser user={logUser} className={classes.userText} />,
				oldOwner,
				owner
			}
		}),
		[classes.userText, integration?.name, logUser, oldOwner, owner, values]
	);

	const [title, ...details] = useMemo(() => {
		if (!resource) return [];
		const paths = getEventTranslationPath(auditLog, resource);
		return paths.map(key => `${TRANSLATION_PREFIX}.${key}` as const);
	}, [auditLog, resource]);

	return users && resource ? (
		<>
			<Trans t={t} {...props} i18nKey={title} />
			{details && (
				<List
					items={details.map(key => (
						<Trans t={t} key={key} i18nKey={key} {...props} />
					))}
				/>
			)}
		</>
	) : (
		<LoadingDots />
	);
};

export const IntegrationResourceAuditLogContent: TAuditLogContentComponent<IntegrationResourceAuditLogModel> =
	React.memo(Content);
