import { Map } from "immutable";
import { clearAuthCookie } from "context/authContext";
import ApiError from "./errors/apiError";
import { logrocketGetSessionUrl, logrocketLog } from "./logrocket";

type HTTPError = { code?: number };
export type TMethod = "GET" | "POST" | "PATCH" | "DELETE" | "PUT";
// eslint-disable-next-line @typescript-eslint/ban-types
export type TBody = Object | string | null;

export type TOptions = {
	expectJson?: boolean;
	status404IsNull?: boolean;
	validateStatusCodes?: boolean;
	pathPrefix?: string;
	abortSignal?: AbortSignal | null;
	multipart?: boolean;
	redirect?: RequestRedirect;
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type TResult = { response: Response; data: null | any };

// eslint-disable-next-line @typescript-eslint/no-explicit-any
let ongoingRequests = Map<string, Promise<{ response: Response; data: null | any }>>();

const createHash = (value: string) => {
	let hash = 0;
	let chr: number;
	for (let i = 0; i < value.length; i++) {
		chr = value.charCodeAt(i);
		hash = (hash << 5) - hash + chr;
		hash |= 0; // Convert to 32bit integer
	}
	return hash;
};

const getRequestKey = (method: TMethod, path: string, body: TBody = null) => {
	let stringBody = "";
	if (body) {
		stringBody = typeof body === "string" ? body : JSON.stringify(body);
	}
	const bodyHash = createHash(stringBody);
	return `${method} ${path} ${bodyHash}`;
};

export async function apiReq(
	method: TMethod,
	path: string,
	body: TBody = null,
	options: TOptions = {}
): Promise<TResult> {
	const requestKey = getRequestKey(method, path, body);

	if (ongoingRequests.has(requestKey)) {
		return ongoingRequests.get(requestKey)!;
	}
	try {
		const promise = executeRequest(method, path, body, options);
		ongoingRequests = ongoingRequests.set(requestKey, promise);
		return promise;
	} catch (e) {
		if (e instanceof ApiError && e.errorId === "fetch.sessionExpired") {
			window.location.reload();
		}
		throw e;
	} finally {
		ongoingRequests = ongoingRequests.delete(requestKey);
	}
}

const LONG_REQUEST_TIME = 10000; // 10 seconds

const logLongRequest = (method: TMethod, headers: Record<string, string>, path: string, body?: string) => {
	const logParams: Record<string, string> = {};
	logParams.location = "apiReq";
	logParams.method = method;
	logParams.headers = Object.entries(headers)
		.map(([key, value]) => `${key}=${value}`)
		.join("\n");
	logParams.path = path;
	if (body) {
		logParams.body = body;
	}
	logrocketLog("Long api request", {
		...logParams
	});
};

const executeRequest = async (
	method: TMethod,
	path: string,
	body: TBody = null,
	{
		expectJson = true,
		status404IsNull = true,
		validateStatusCodes = true,
		pathPrefix = "/api",
		abortSignal = null,
		multipart = false,
		redirect = undefined
	}: TOptions = {}
): Promise<TResult> => {
	const headers = {} as { [key: string]: string };
	const options = { method, headers } as RequestInit;

	if (redirect) {
		options.redirect = redirect;
	}

	if (abortSignal != null) options.signal = abortSignal;

	if (typeof body == "string" || (multipart && body instanceof FormData)) {
		options.body = body;
	} else if (body instanceof Object) {
		options.body = JSON.stringify(body);
		headers["Content-Type"] = "application/json";
	}

	const logrocketSessionUrl = logrocketGetSessionUrl();
	if (logrocketSessionUrl) {
		headers["logrocket-session-url"] = logrocketSessionUrl;
	}

	let response: Response | null = null;
	let data = null;
	const url = pathPrefix + path;
	const longRequestTimeout = setTimeout(() => {
		logLongRequest(method, headers, url, options.body as string | undefined);
	}, LONG_REQUEST_TIME);
	try {
		response = await fetch(url, options);
	} catch (err) {
		if (!(err instanceof Error)) throw new Error();
		if ((err as HTTPError)?.code === DOMException.ABORT_ERR) throw new Error("Request aborted");

		console.error(`Failed to fetch ${method} ${url}. error:`, err);
		throw ApiError.from(err);
	} finally {
		clearTimeout(longRequestTimeout);
	}

	if (expectJson) {
		try {
			data = await response.json();
		} catch (err) {
			if ((err as HTTPError)?.code === DOMException.ABORT_ERR) throw new Error("Request aborted");

			throw new ApiError({
				errorId: response.status === 504 ? "fetch.timeOut" : "fetch.receivedNonJsonResponse",
				statusCode: response.status
			});
		}
	}

	if (!validateStatusCodes || (response.status >= 200 && response.status < 300)) return { data, response };
	else if (response.status === 404 && status404IsNull) {
		throw new ApiError({
			errorId: "resource.notFound"
		});
	} else if (response.status === 401) {
		// unauthorized
		clearAuthCookie();
		throw new ApiError({
			errorId: "fetch.sessionExpired"
		});
	} else if (response.status === 403) {
		// forbidden
		throw new ApiError({
			errorId: "fetch.forbiddenRequest"
		});
	} else if (response.status >= 400) {
		throw ApiError.from(data, {
			statusCode: response.status
		});
	} else {
		throw new ApiError({
			errorId: "fetch.unexpectedStatusCode",
			statusCode: response.status,
			params: {
				statusCode: response.status
			}
		});
	}
};

export const toQuerystring = (queryData: Record<string, unknown>): string => {
	const query = new URLSearchParams();
	Object.entries(queryData).forEach(([key, value]) => {
		if (value === undefined || value === null || value === "") return;
		if (Array.isArray(value)) {
			if (value.length === 0) return;
			query.append(key, value.join(","));
		} else if (typeof value === "object") {
			query.append(key, JSON.stringify(value));
		} else {
			query.append(key, String(value));
		}
	});
	return query.toString();
};
