import React, { useCallback, useMemo } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { Section } from "components/ui/Section";
import { Typography } from "components/ui/New/Typography";
import { Chip } from "components/ui/Chip";
import { Form } from "components/ui/Form";
import { AccountsSelect } from "../AccountsSelect";
import { TMyPermissionsForm, useUserPermissions } from "../../myPermissionsHooks";
import { MyPermissionsTable } from "../MyPermissionsTable/MyPermissionsTable";
import { IntegrationsSelect } from "../IntegrationsSelect";
import { ResourceTypesSelect } from "../ResourceTypesSelect";
import { ResourcesSelect } from "../ResourcesSelect";
import { RolesSelect } from "../RolesSelect";
import { useStyles } from "./styles";

type TFormProps = {
	form: TMyPermissionsForm;
	setFormValue: (key: keyof TMyPermissionsForm, value: string | undefined) => void;
};

const MyPermissionsHeaderForm: FC<TFormProps> = ({ className, innerRef, form, setFormValue }) => {
	const classes = useStyles();

	const setRole = useCallback((roleId: string | null) => setFormValue("roleId", roleId || undefined), [setFormValue]);

	const setResource = useCallback(
		(resourceId: string | null) => {
			setFormValue("resourceId", resourceId || undefined);
			setRole(null);
		},
		[setFormValue, setRole]
	);

	const setResourceType = useCallback(
		(resourceType: string | null) => {
			setFormValue("resourceType", resourceType || undefined);
			setResource(null);
		},
		[setFormValue, setResource]
	);

	const setAccount = useCallback(
		(accountId: string | null) => {
			setFormValue("accountId", accountId || undefined);
			setResourceType(null);
		},
		[setFormValue, setResourceType]
	);

	const setIntegration = useCallback(
		(integrationId: string | null) => {
			setFormValue("integrationId", integrationId || undefined);
			setAccount(null);
		},
		[setFormValue, setAccount]
	);

	return (
		<Form className={classNames(className, classes.headerForm)} innerRef={innerRef}>
			<Form.Field className={classes.field}>
				<IntegrationsSelect className={classes.select} onChange={setIntegration} value={form.integrationId || null} />
			</Form.Field>
			<Form.Field className={classes.field}>
				<AccountsSelect
					className={classes.select}
					disabled={!form.integrationId}
					onChange={setAccount}
					value={form.accountId}
					integrationId={form.integrationId || null}
				/>
			</Form.Field>
			<Form.Field className={classes.field}>
				<ResourceTypesSelect
					className={classes.select}
					disabled={!form.integrationId}
					integrationId={form.integrationId || null}
					onChange={setResourceType}
					value={form.resourceType || null}
				/>
			</Form.Field>
			<Form.Field className={classes.field}>
				<ResourcesSelect
					className={classes.select}
					disabled={!form.integrationId}
					integrationId={form.integrationId || null}
					onChange={setResource}
					resourceType={form.resourceType || null}
					value={form.resourceId}
				/>
			</Form.Field>
			<Form.Field className={classes.field}>
				<RolesSelect
					className={classes.select}
					disabled={!form.resourceId}
					resourceId={form.resourceId || null}
					onChange={setRole}
					value={form.roleId || null}
				/>
			</Form.Field>
		</Form>
	);
};

export const MyPermissionsTableSection: FC = ({ className, innerRef }) => {
	const { t } = useTranslation();
	const classes = useStyles();
	const { permissions, setFormValue, form } = useUserPermissions();

	const title = useMemo(() => {
		return (
			<>
				<Typography variant="body_sb" noWrap>
					{t("pages.myPermissions.myPermissionsTableSection.title")}
				</Typography>
				<Chip size="tiny">{permissions?.size || 0}</Chip>
			</>
		);
	}, [permissions?.size, t]);
	return (
		<Section
			titleActions={<MyPermissionsHeaderForm form={form} setFormValue={setFormValue} />}
			className={classNames(classes.scrollable, className)}
			innerRef={innerRef}
			fullHeight
			title={title}>
			<MyPermissionsTable className={classes.table} permissions={permissions} />
		</Section>
	);
};
