import React, { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { PageTitleContent } from "components/templates/PageTitleContentTemplate";
import { IconButton } from "components/ui/IconButton";
import { CloseIcon } from "components/ui/Icons/CloseIcon";
import { useStepperContext } from "components/common/Stepper/stepperContext";
import { Button } from "components/ui/Button";
import { ArrowRightIcon } from "components/ui/Icons/ArrowRightIcon";
import { useNewRequestAllowedDurations } from "../../newRequestDataContext";
import { useNewRequestFormContext } from "../../newRequestFormContext";
import { RequestOnBehalf } from "./components/RequestOnBehalf";
import { RoleSearchBar } from "./components/RoleSearchBar";
import { SelectedTargetsSection } from "./components/SelectedTargetsSection";
import { AvailableTargetsSection } from "./components/AvailableTargetsSection";

const useShouldSkipDurationStep = () => {
	const {
		state: { requestTargets, receiverUser, duration },
		actions: { changeDuration }
	} = useNewRequestFormContext();
	const { data: durations, fetch: fetchDurations } = useNewRequestAllowedDurations();

	useEffect(() => {
		if (!receiverUser || requestTargets.size === 0) return;
		fetchDurations({
			userId: receiverUser.id,
			targets: requestTargets.toArray().map(target => ({
				id: target.id,
				type: target.type
			}))
		});
	}, [requestTargets, receiverUser, fetchDurations]);

	const shouldSkip = useMemo(() => {
		const canSkip = requestTargets.size > 0 && durations.size === 1;
		if (canSkip && !duration) {
			changeDuration(durations.first()!);
		}
		return canSkip;
	}, [changeDuration, duration, durations, requestTargets.size]);

	return useMemo(
		() => ({
			canContinue: requestTargets.size > 0 && durations.size > 0,
			shouldSkip
		}),
		[durations.size, requestTargets.size, shouldSkip]
	);
};

const SkipButton: FC<{ goToStep: (step: number) => void; canContinue: boolean }> = ({ goToStep, canContinue }) => {
	const { t } = useTranslation();
	const skipDurations = useCallback(() => {
		goToStep(2);
	}, [goToStep]);
	return (
		<Button variant="primary" size="large" suffix={<ArrowRightIcon />} onClick={skipDurations} disabled={!canContinue}>
			{t("buttons.next")}
		</Button>
	);
};

export const SelectRolesStep: FC<{ onExit: () => void }> = ({ className, innerRef, onExit }) => {
	const { t } = useTranslation("translation", { keyPrefix: "pages.newRequest.selectRolesStep" });
	const { canContinue, shouldSkip } = useShouldSkipDurationStep();

	const {
		actions: { setFooterAction, setCanContinue, goToStep }
	} = useStepperContext();

	useEffect(() => {
		setFooterAction("next", shouldSkip ? <SkipButton goToStep={goToStep} canContinue={canContinue} /> : undefined);
		setCanContinue(canContinue);
		return () => {
			setFooterAction("next", undefined);
		};
	}, [canContinue, goToStep, setCanContinue, setFooterAction, shouldSkip]);

	return (
		<PageTitleContent innerRef={innerRef} className={className} noTransition>
			<PageTitleContent.Header>
				<PageTitleContent.Header.Top>
					<PageTitleContent.Header.Actions>
						<IconButton size="large" onClick={onExit}>
							<CloseIcon />
						</IconButton>
					</PageTitleContent.Header.Actions>
				</PageTitleContent.Header.Top>
				<PageTitleContent.Header.Bottom>
					<PageTitleContent.Header.Title title={t("title")} />
					<PageTitleContent.Header.Actions>
						<RequestOnBehalf />
					</PageTitleContent.Header.Actions>
				</PageTitleContent.Header.Bottom>
			</PageTitleContent.Header>
			<PageTitleContent.Body>
				<RoleSearchBar />
				<SelectedTargetsSection />
				<AvailableTargetsSection />
			</PageTitleContent.Body>
		</PageTitleContent>
	);
};
