import { ChevronUpIcon } from "components/ui/Icons/ChevronUpIcon";
import { CloseIcon } from "components/ui/Icons/CloseIcon";
import { LoadingSpinner } from "components/ui/LoadingSpinner";
import React from "react";
import { ChevronDownIcon } from "components/ui/Icons/ChevronDownIcon";
import { basicSort } from "./sortUtils";

const isWithLabel = (object: unknown): object is { label: string } =>
	typeof object === "object" && object !== null && "label" in object;

const isWithValue = (object: unknown): object is { value: string } =>
	typeof object === "object" && object !== null && "value" in object;

const isWithId = (object: unknown): object is { id: string; identifier: string } =>
	typeof object === "object" && object !== null && ("id" in object || "identifier" in object);

export const getLabel = <T,>(option: T) => {
	if (typeof option === "string") {
		return option;
	}
	if (isWithLabel(option)) {
		return option.label;
	}
	throw new Error(
		"could not get label from option, please try to provide the object label property, or add your own `getOptionLabel` function"
	);
};

const isStringArray = (value: unknown[]): value is string[] => {
	return !!value?.[0] && typeof value[0] === "string";
};

export const sortOptions = (options: string[] | { label: string }[] | { value: string }[] | unknown[]) => {
	if (!options.length) return options;
	if (isStringArray(options)) {
		return basicSort(options, []);
	}
	if (isWithLabel(options[0])) {
		return basicSort(options as { label: string }[], ["label"]);
	}
	if (isWithValue(options[0])) {
		return basicSort(options as { value: string }[], ["value"]);
	}
	return null;
};

export const cleanString = (value: string) => value?.trim()?.toLowerCase() ?? "";

export const getOptionKey = <T,>(option: T, getOptionLabel?: (option: T) => string) => {
	if (isWithId(option)) return option.id || option.identifier;
	return getOptionLabel ? getOptionLabel(option) : String(option);
};

export const getGroups = <T,>(options: T[], groupBy: (option: T) => string): Map<string, T[]> => {
	const groups = new Map<string, T[]>();
	options.forEach(option => {
		const group = groupBy(option);
		if (!groups.has(group)) {
			groups.set(group, []);
		}
		groups.get(group)?.push(option);
	});
	return groups;
};

interface ISuffixOptions {
	disabled?: boolean;
	handleClear?: (event: React.MouseEvent) => void;
	handleClose?: () => void;
	handleOpen?: () => void;
	loading?: boolean;
	open?: boolean;
	showClear?: boolean;
	suffixClassName?: string;
	suffixClearClassName?: string;
	suffix?: React.ReactNode;
}

export const getSuffix = (options: ISuffixOptions) => {
	const {
		disabled,
		handleClear,
		handleClose,
		handleOpen,
		loading,
		open,
		showClear,
		suffixClassName,
		suffixClearClassName,
		suffix
	} = options;

	let suffixElement: React.ReactNode = <ChevronDownIcon disabled={disabled} onClick={handleOpen} />;
	if (suffix || suffix === null) {
		suffixElement = suffix;
	}
	if (loading) {
		suffixElement = <LoadingSpinner inline />;
	}
	if (open && suffix !== null) {
		suffixElement = <ChevronUpIcon disabled={disabled} onClick={handleClose} />;
	}

	let clearIcon = null;
	if (showClear && !disabled) {
		clearIcon = <CloseIcon onClick={handleClear} className={suffixClearClassName} />;
	}
	return (
		<div className={suffixClassName}>
			{clearIcon}
			{suffixElement}
		</div>
	);
};

export type TRenderOption<T> = (option: T, index: number, disabled?: boolean) => JSX.Element | null;
