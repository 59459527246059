import { FloatingSelect, IFloatingSelectOption } from "components/common/FloatingSelect";
import React, { useMemo, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { ApprovalAlgorithmModel } from "models/ApprovalAlgorithmModel";
import { WorkflowsIcon } from "components/ui/Icons/WorkflowsIcon";
import { useApprovalAlgorithms } from "hooks/useApprovalAlgorithms";
import { useInlineInputState } from "hooks/useInlineInputState";
import { ChevronDownIcon } from "components/ui/Icons/ChevronDownIcon";
import { useStyles } from "./styles";

const INHERIT_FROM_PARENT_ID = "INHERIT";

type TProps = {
	approvalAlgorithmId: string | null;
	setApprovalAlgorithm: (approvalAlgorithm: ApprovalAlgorithmModel | undefined) => void | Promise<void>;
	inheritTranslationPath: "pages.integration.resource.inherit" | "pages.integration.resource.role.inherit";
};

const sortOptions = (options: IFloatingSelectOption[]) =>
	options.sort((a, b) => (a.value === INHERIT_FROM_PARENT_ID ? -1 : a.label.localeCompare(b.label)));

export const WorkflowInlineSelect: FC<TProps> = ({
	approvalAlgorithmId,
	setApprovalAlgorithm,
	inheritTranslationPath
}) => {
	const { t } = useTranslation();
	const { onClose, onHover, onLeave, onOpen, optionsOpen, showArrow } = useInlineInputState();
	const classes = useStyles({ showArrow });

	const approvalAlgorithms = useApprovalAlgorithms();

	const inheritOption = useMemo(
		() => new ApprovalAlgorithmModel({ id: INHERIT_FROM_PARENT_ID, name: t(inheritTranslationPath) }),
		[inheritTranslationPath, t]
	);
	const approvalAlgorithm = useMemo(
		() => approvalAlgorithms?.find(approvalAlgorithm => approvalAlgorithm.id === approvalAlgorithmId) ?? inheritOption,
		[approvalAlgorithmId, inheritOption, approvalAlgorithms]
	);

	const approvalAlgorithmOptions = useMemo(() => {
		const approvalAlgorithmsArray = approvalAlgorithms?.toList()?.toArray();

		let options = [inheritOption];
		approvalAlgorithmsArray?.length && (options = options.concat(approvalAlgorithmsArray));
		return options.map(approvalAlgorithm => ({
			value: approvalAlgorithm.id,
			label: approvalAlgorithm.name
		}));
	}, [approvalAlgorithms, inheritOption]);

	const renderOption = useCallback(
		(option: { value: string; label: string }, props: { onClick: (event: React.MouseEvent) => void; key: string }) => (
			<div className={classes.approvalAlgorithmOption} {...props}>
				<WorkflowsIcon /> {option.label}
			</div>
		),
		[classes.approvalAlgorithmOption]
	);
	const onChange = useCallback(
		async (approvalAlgorithmId: string) => {
			const newApprovalAlgorithm = approvalAlgorithms?.get(approvalAlgorithmId);
			if (newApprovalAlgorithm || approvalAlgorithmId === INHERIT_FROM_PARENT_ID) {
				await setApprovalAlgorithm(newApprovalAlgorithm);
				onClose();
			}
		},
		[onClose, setApprovalAlgorithm, approvalAlgorithms]
	);
	return (
		<div className={classes.inlineInputContainer}>
			<FloatingSelect
				filter
				sort={sortOptions}
				onClose={onClose}
				onSelect={onChange}
				open={optionsOpen}
				options={approvalAlgorithmOptions}
				optionsClassName={classes.list}
				position="bottom-start"
				placeholder={t("common.floatingSelect.searchWorkflow")}
				renderOption={renderOption}
				className={classes.floatingSelect}>
				<div
					onMouseOver={onHover}
					onMouseLeave={onLeave}
					onClick={onOpen}
					className={classes.inlineInputLabelContainer}>
					<div className={classes.label}>
						<WorkflowsIcon /> {approvalAlgorithm.name}
					</div>
					<ChevronDownIcon className={classes.arrowDownInline} />
				</div>
			</FloatingSelect>
		</div>
	);
};
