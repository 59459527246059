import React, { useCallback } from "react";
import { IntegrationResourceModel } from "models/IntegrationResourceModel";
import { useSelectSearchProps } from "hooks/useSelectSearchProps";
import { IRenderChipParams, MultipleSelect } from "components/ui/MultipleSelect";
import { useIntegrations } from "hooks/useIntegrations";
import { useTranslation } from "react-i18next";
import { getIntegrationsResources } from "api/accessRequestForm";
import { Typography } from "components/ui/Typography";
import { ImageGrid } from "components/common/ImageGrid";
import { MultiSelectChip } from "../../../MultiSelectChip";
import { useStyles } from "./styles";

const LIMIT = 100;

interface IProps {
	setResources: (value: IntegrationResourceModel[] | null) => void;
	resources: IntegrationResourceModel[] | null;
	selectedIntegrationIds: string[];
	selectedResourceTypes: string[] | null;
	userId: string;
}

const getOptionLabel = (option: IntegrationResourceModel) => option.displayName;
const equality = (option: IntegrationResourceModel, value: IntegrationResourceModel) => option.id === value.id;

export const ResourcesInput: FC<IProps> = ({
	setResources,
	resources,
	selectedIntegrationIds,
	selectedResourceTypes,
	userId
}) => {
	const { t } = useTranslation(undefined, { keyPrefix: "pages.newTicket.newTicketForm.resourcesInput" });
	const integrations = useIntegrations();
	const classes = useStyles();

	const fetchResources = useCallback(
		async (query: string) => {
			if (selectedIntegrationIds?.length) {
				const response = await getIntegrationsResources({
					userId,
					integrationIds: selectedIntegrationIds,
					search: query,
					resourceTypes: selectedResourceTypes || undefined,
					perPage: LIMIT
				});
				return response.result.toArray();
			}

			return [];
		},
		[selectedResourceTypes, selectedIntegrationIds, userId]
	);

	const { selectProps } = useSelectSearchProps(fetchResources, resources, true);

	const renderChip = useCallback(
		({ option, componentKey, ...rest }: IRenderChipParams<IntegrationResourceModel>) => {
			const integration = integrations?.get(option.integrationId);
			return (
				<MultiSelectChip
					{...rest}
					key={componentKey}
					content={option.displayName}
					imageUrl={integration?.imageUrl || undefined}
				/>
			);
		},
		[integrations]
	);

	const renderOption = useCallback(
		(option: IntegrationResourceModel) => {
			const integration = integrations?.get(option.integrationId);
			return (
				<div className={classes.resourceOption}>
					<ImageGrid imageGridClass={classes.image} images={integration?.imageUrl ? [integration.imageUrl] : []} />
					<div className={classes.resourceOptionData}>
						<Typography>{option.displayName}</Typography>
						{option.type ? <Typography variant="small">{t("optionType", { type: option.type })}</Typography> : null}
						{integration ? <Typography variant="small">{integration.name}</Typography> : null}
					</div>
				</div>
			);
		},
		[classes.image, classes.resourceOption, classes.resourceOptionData, integrations, t]
	);

	return (
		<MultipleSelect
			{...selectProps}
			disabled={!selectedIntegrationIds?.length}
			fullWidth
			getOptionLabel={getOptionLabel}
			label={t(`label`)}
			limit={LIMIT}
			placeholder={t(`placeholder`)}
			required
			renderOption={renderOption}
			onChange={setResources}
			renderChip={renderChip}
			filter={null}
			sort={null}
			value={resources}
			isOptionEqualToValue={equality}
		/>
	);
};
