import React, { useMemo } from "react";
import classNames from "classnames";
import { Chip } from "components/ui/Chip";
import { Chips } from "components/ui/MultipleSelect/components/Chips";
import { Typography } from "components/ui/Typography";
import { IRenderChipParams } from "components/ui/MultipleSelect";
import { useStyles } from "./styles";

interface IEntityOptionProps {
	entity: {
		name: string;
		description: string | null;
		tags: string[] | null;
		userDefinedDescription?: string | null;
		userDefinedTags?: string[] | null;
	};
	tagsLimit?: number;
	disabled?: boolean;
}

const EntityChip: FC<IRenderChipParams<string>> = ({ option, ...props }) => {
	const classes = useStyles();

	return (
		<Chip {...props} className={classes.entityTagChip} size="tiny">
			<Typography variant="tiny">{option}</Typography>
		</Chip>
	);
};

const EntityLimitChip: FC<IRenderChipParams<string>> = ({ option, ...props }) => {
	const classes = useStyles();

	return (
		<Chip {...props} className={classNames(classes.entityTagChip, classes.entityLimitChip)} size="tiny">
			<Typography variant="tiny">{option}</Typography>
		</Chip>
	);
};

const getOptionLabel = (option: string) => option;

export function EntityOption({ entity, tagsLimit = 6, disabled = false }: IEntityOptionProps) {
	const classes = useStyles();
	const tags = useMemo(
		() => [...(entity.tags || []), ...(entity.userDefinedTags || [])],
		[entity.tags, entity.userDefinedTags]
	);
	const description = entity.userDefinedDescription || entity.description;
	return (
		<Typography>
			<div>{entity.name}</div>
			{description && (
				<div className={classNames(classes.entityDescription, { [classes.disabled]: disabled })}>{description}</div>
			)}
			{tags && (
				<div className={classes.entityTagsContainer}>
					<Chips
						values={tags}
						limit={tagsLimit}
						getOptionLabel={getOptionLabel}
						collapsedValuesClassName={classes.collapsedChipsValues}
						ChipElement={EntityChip}
						LimitChip={EntityLimitChip}
						disableTooltip
					/>
				</div>
			)}
		</Typography>
	);
}
