import React, { useCallback } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { PageTemplate } from "components/templates/PageTemplate";
import { useUser } from "hooks/useUser";
import { LoadingDots } from "components/ui/LoadingDots";
import { IconButton } from "components/ui/IconButton";
import { ArrowLeftIcon } from "components/ui/Icons/ArrowLeftIcon";
import { useNavigate } from "react-router-dom";
import { ProfileForm } from "./components/ChangeProfileForm";
import { useStyles } from "./styles";

export const ProfilePage: FC = ({ className }) => {
	const { t } = useTranslation();
	const classes = useStyles();

	const { user } = useUser();

	const navigate = useNavigate();

	const handleBack = useCallback(() => navigate(-1), [navigate]);

	return (
		<PageTemplate className={classNames(classes.profilePage, className)}>
			<PageTemplate.Title>
				<div className={classes.backButtonContainer}>
					<IconButton size="large" onClick={handleBack}>
						<ArrowLeftIcon />
					</IconButton>
				</div>
				{t("pages.profile.title")}
			</PageTemplate.Title>
			<PageTemplate.Content>{user ? <ProfileForm user={user} /> : <LoadingDots />}</PageTemplate.Content>
		</PageTemplate>
	);
};
