import { List, Record } from "immutable";
import { TicketApprovalEntityModel } from "./TicketApprovalEntityModel";
import { TicketApprovalNotifiedEntityModel } from "./TicketApprovalNotifiedEntityModel";
import { TicketModel } from "./TicketModel";

export type TTicketApprovalRequestStatus = "approved" | "declined" | "pending";

const defaults = {
	active: false,
	approvalEntities: null as List<TicketApprovalEntityModel> | null,
	id: "",
	notifiedEntities: null as List<TicketApprovalNotifiedEntityModel> | null,
	operator: "" as "and" | "or",
	sortOrder: 0,
	status: "" as TTicketApprovalRequestStatus,
	ticket: null as TicketModel | null,
	updatedAt: new Date(0)
};

export class TicketApprovalRequestModel extends Record<typeof defaults>(defaults) {
	static fromServerData(data: unknown): TicketApprovalRequestModel {
		const { active, approvalEntities, id, notifiedEntities, operator, sortOrder, status, ticket, updatedAt } =
			data as typeof defaults;
		return new TicketApprovalRequestModel({
			active,
			approvalEntities: approvalEntities
				? List(approvalEntities.map(entity => TicketApprovalEntityModel.fromServerData(entity)))
				: null,
			id,
			notifiedEntities: notifiedEntities
				? List(notifiedEntities.map(entity => TicketApprovalNotifiedEntityModel.fromServerData(entity)))
				: null,
			operator,
			sortOrder,
			status,
			ticket: ticket ? TicketModel.fromServerData(ticket) : null,
			updatedAt: new Date(updatedAt)
		});
	}
}
