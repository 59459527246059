import React, { useMemo, useState } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { TicketModel } from "models/TicketModel";
import { Require } from "utils/types";
import { useUsers } from "hooks/useUsers";
import { notEmpty } from "utils/comparison";
import { UserNode } from "components/common/Nodes/UserNode";
import { UserCard } from "components/common/UserCard";
import { Tooltip } from "components/ui/Tooltip";
import { TooltipOnOverflow } from "components/ui/New/TooltipOnOverflow";
import { useApprovalFlowsWebhooksMap } from "hooks/useApprovalFlowsWebhooksMap";
import { WebhookChip } from "components/common/WebhookChip";
import { useStyles } from "./styles";

type TUsersProps = {
	userIds: string[];
};

type TRequestProps = {
	request: Require<TicketModel, "approvalRequests">;
};

type TProps = TUsersProps | TRequestProps;

const useApprovers = (props: TProps) => {
	const users = useUsers(true);
	const approvalFlowsWebhooks = useApprovalFlowsWebhooksMap();

	const approvers = useMemo(() => {
		if (!users || !approvalFlowsWebhooks) return { approverUsers: [], approverWebhooks: [] };
		if ("userIds" in props) {
			return { approverUsers: props.userIds.map(userId => users.get(userId)).filter(notEmpty), approverWebhooks: [] };
		}

		const activeRequest = props.request.approvalRequests.find(approvalRequest => approvalRequest.active);
		if (!activeRequest || !activeRequest.approvalEntities) return { approverUsers: [], approverWebhooks: [] };

		const approverUsers = activeRequest.approvalEntities
			.flatMap(approvalEntity => approvalEntity.approverIds.toArray())
			.map(approverId => users.get(approverId))
			.filter(notEmpty)
			.toSet()
			.toArray();

		const approverWebhooks = activeRequest.approvalEntities
			.map(entity =>
				entity.type === "Webhook" && entity.identifier ? approvalFlowsWebhooks.get(entity.identifier) : null
			)
			.filter(notEmpty)
			.toSet()
			.toArray();

		return { approverUsers, approverWebhooks };
	}, [approvalFlowsWebhooks, props, users]);

	return approvers;
};

const DEFAULT_SHOWN = 2;

export const RequestApprovers: FC<TProps> = props => {
	const { className, innerRef } = props;
	const [shownApprovers] = useState(DEFAULT_SHOWN);
	const { t } = useTranslation();
	const { approverUsers, approverWebhooks } = useApprovers(props);
	const classes = useStyles();

	const shownApproverNodes = useMemo(() => {
		const approversCount = approverUsers.length + approverWebhooks.length;
		const detailedUserNodes = approverUsers.map(user => <UserCard key={user.id} user={user} />);
		const detailedWebhookNodes = approverWebhooks.map(webhook => (
			<WebhookChip key={webhook.id} displayName={webhook.name} />
		));
		const detailedNodes = [...detailedUserNodes, ...detailedWebhookNodes];
		const maxSlice = approversCount > shownApprovers ? shownApprovers - 1 : shownApprovers;
		const shownDetailedNodes = detailedNodes.slice(0, maxSlice);
		const hiddenDetailedNodes = detailedNodes.slice(maxSlice);
		const tooltipContent =
			hiddenDetailedNodes.length > 0 ? <div className={classes.tooltipContent}>{hiddenDetailedNodes}</div> : null;
		const hiddenApproversCount = approversCount - maxSlice;
		const hiddenUsersNode =
			hiddenApproversCount > 0 ? (
				<Tooltip key="[HIDDEN_USERS_COUNT]" content={tooltipContent} placement="bottom">
					<UserNode content={`+${hiddenApproversCount}`} />
				</Tooltip>
			) : null;
		return [...shownDetailedNodes, hiddenUsersNode];
	}, [approverUsers, approverWebhooks, shownApprovers, classes.tooltipContent]);

	return (
		<div className={classNames(classes.container, className)} ref={innerRef}>
			<TooltipOnOverflow textVariant="text_sm_sb" content={t("common.requestBar.requestApprovers.title")} />
			{shownApproverNodes}
		</div>
	);
};
