import { createPortal } from "react-dom";
import React, { useRef } from "react";
import classNames from "classnames";
import { useSystemOverlays } from "context/overlaysContext";
import { DragIndicatorIcon } from "components/ui/Icons/DragIndicatorIcon";
import { useResizeDrag } from "./useResizeDrag";
import { useStyles } from "./styles";

interface IProps {
	minWidth: number;
	maxWidth: number;
	initialWidth?: number;
	open: boolean;
	closeSidebar?: () => void;
}

const getInitialSize = (minWidth: number, maxWidth: number, initialWidth?: number) => {
	const width = initialWidth ?? document.body.clientWidth * 0.6;
	return Math.max(Math.min(width, maxWidth), minWidth);
};

export const DraggableSidebar: FC<IProps> = ({
	children,
	className,
	minWidth: propMinWidth,
	maxWidth,
	initialWidth,
	open,
	closeSidebar
}) => {
	const systemOverlays = useSystemOverlays();
	const dragRef = useRef<SVGElement | null>(null);
	const minWidth = propMinWidth < maxWidth ? propMinWidth : maxWidth;
	const size = useResizeDrag<SVGElement>(dragRef, getInitialSize(minWidth, maxWidth, initialWidth), maxWidth, minWidth);
	const classes = useStyles();

	return systemOverlays
		? createPortal(
				<div className={classNames(classes.sidebarContainer, { [classes.open]: open })}>
					<div className={classes.dimmer} onClick={closeSidebar} />
					<div className={classes.sidebar} style={{ width: `${size}px`, right: open ? 0 : -1 * size }}>
						<div className={classes.resizer}>
							<DragIndicatorIcon color="var(--color-purple-500)" innerRef={dragRef} />
						</div>
						<div className={classNames(classes.content, className)}>{children}</div>
					</div>
				</div>,
				systemOverlays
			)
		: null;
};
