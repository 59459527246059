const styles = {
	"@keyframes fade-move-from-end-in": {
		"0%": {
			opacity: "0",
			transform: "translateX(10%)"
		},
		"100%": {
			opacity: "1",
			transform: "translateX(0%)"
		}
	},

	"@keyframes fade-move-from-end-out": {
		"0%": {
			opacity: "1",
			transform: "translateX(0%)"
		},
		"100%": {
			opacity: "0",
			transform: "translateX(-10%)"
		}
	},
	transitionable: {
		"&.animate": {
			'&[data-animation="fade-move-from-end"]': {
				"&.in": {
					animation: "var(--animation-duration) var(--transition-easing) forwards $fade-move-from-end-in",
					zIndex: "10"
				},

				"&.out": {
					animation: "var(--animation-duration) var(--transition-easing) forwards $fade-move-from-end-out",
					zIndex: "5"
				},

				"&.reverse": {
					"&.in": {
						animation:
							"var(--animation-duration) var(--transition-easing-reversed) forwards reverse $fade-move-from-end-out"
					},

					"&.out": {
						animation:
							"var(--animation-duration) var(--transition-easing-reversed) forwards reverse $fade-move-from-end-in"
					}
				}
			}
		}
	}
};

export { styles };
