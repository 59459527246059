export type TTimezone = "local" | "global";

const getOffsetHours = () => new Date().getTimezoneOffset() / -60;

const getTimezoneOffset = () => {
	const offsetHours = getOffsetHours();
	let offsetString = offsetHours < 0 ? "-" : "";
	offsetHours < 10 && offsetHours > -10 && (offsetString += "0");

	return offsetString + Math.abs(offsetHours).toString() + ":00:00";
};

export const createDateWithoutTimezone = (date: string) => {
	try {
		const timezonelessDate = new Date();
		const [year, month, day] = date.split("-");
		timezonelessDate.setFullYear(parseInt(year));
		timezonelessDate.setMonth(parseInt(month) - 1);
		timezonelessDate.setDate(parseInt(day));
		timezonelessDate.setMinutes(0);
		return timezonelessDate;
	} catch (e) {
		return new Date(date);
	}
};

export const getTimezoneQuerystring = (timezone: TTimezone) =>
	timezone === "global" ? `timezoneOffset=${getTimezoneOffset()}` : "";

export const getDateString = (date: Date) => date.toISOString().slice(0, 10);
