import { createUseStyles } from "react-jss";

interface IProps {
	fullWidth: boolean;
	slimPadding: boolean;
}

export const useStyles = createUseStyles({
	errorIcon: {
		color: "var(--color-red-dark)"
	},
	filler: {
		flexGrow: 1
	},
	hint: {
		"&.error": {
			color: "var(--color-red-dark)"
		},
		color: "var(--color-grey-dark)",
		display: "block",
		marginTop: "0.55rem"
	},
	input: {
		display: "flex",
		flexDirection: "column",
		width: (props: IProps) => (props.fullWidth ? "100%" : "17rem")
	},
	inputContainer: {
		"&.box": {
			"&.disabled": {
				backgroundColor: "var(--color-grey-background)"
			},
			"&.error": {
				borderColor: "var(--color-red)"
			},
			"&.focused:not($noBackground)": {
				backgroundColor: "var(--color-purple-5)",
				borderColor: "var(--color-purple-underline)"
			},
			"&:hover:not(.disabled):not(.focused):not($noBackground)": {
				backgroundColor: "var(--color-almost-white)",
				borderColor: "var(--color-purple-underline)"
			},
			"&$noBorder": {
				border: "none"
			},
			"&$noBackground": {
				backgroundColor: "transparent"
			},
			border: "1px solid var(--color-grey-light)",
			borderRadius: "var(--border-radius-sharp)",
			display: "flex",
			justifyContent: "space-between"
		},
		"&.line": {
			"&.disabled": {
				backgroundColor: "var(--color-grey-background)",
				borderColor: "rgba(0, 0, 0, 0.25)"
			},
			"&.error": {
				borderColor: "var(--color-red)"
			},
			"&.focused": {
				"&:not($noBackground)": {
					backgroundColor: "var(--color-purple-5)"
				},
				borderColor: "var(--color-purple-underline)"
			},
			"&:hover:not(.disabled):not(.focused)": {
				backgroundColor: "var(--color-almost-white)",
				borderColor: "var(--color-purple-underline)"
			},
			"&$noBorder": {
				border: "none"
			},
			borderBottom: "1px solid var(--color-grey-light)",
			borderRadius: "0"
		},
		"&.inline": {
			border: "none",
			"&.disabled": {
				backgroundColor: "var(--color-grey-background)"
			},
			"&.focused:not($noBackground)": {
				backgroundColor: "var(--color-purple-5)"
			},
			"&:hover:not(.disabled):not(.focused):not($noBackground)": {
				backgroundColor: "var(--color-almost-white)"
			}
		},
		"&.prefix > *:first-child": {
			marginRight: "0.55rem"
		},
		"&.small": {
			fontSize: "var(--typography-small-font-size)",
			lineHeight: "1.2em",
			minHeight: "2.14rem",
			padding: (props: IProps) => (props.slimPadding ? "0.17rem 0.34rem" : "0.6rem 0.72rem")
		},
		alignItems: "center",
		boxSizing: "border-box",
		display: "flex",
		flexDirection: "row",
		fontSize: "var(--typography-standard-font-size)",
		fontWeight: "var(--typography-font-weight-regular)",
		justifyContent: "center",
		lineHeight: "1.4em",
		padding: (props: IProps) => (props.slimPadding ? "0.2rem 0.4rem" : "0.7rem 0.85rem"),
		transitionDuration: "0.2s",
		transitionProperty: "background-color, border-color",
		transitionTimingFunction: "ease-in-out",
		width: "100%",
		willChange: "background-color",
		"&$noPadding": {
			padding: "0"
		},
		cursor: "text"
	},
	inputHTMLComponent: {
		"&:disabled": {
			color: "var(--color-grey-dark)"
		},
		"&.hide": {
			width: "0"
		},
		"&.small": {
			fontSize: "var(--typography-small-font-size)"
		},
		backgroundColor: "transparent",
		border: "none",
		fontFamily: "inherit",
		height: "100%",
		outline: "none",
		width: "100%"
	},
	label: {
		color: "var(--color-grey-dark)",
		display: "block",
		marginBottom: "0.45rem"
	},
	noBorder: {},
	noPadding: {},
	noBackground: {}
});
