import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { TIntegrationResourceRole } from "api/integrationResourceRoles";
import { Button } from "components/ui/Button";
import { Separator } from "components/ui/Separator";
import useErrorModalState from "hooks/useErrorModalState";
import { ErrorModal } from "components/ui/ErrorModal";
import useIsOpenState from "hooks/useIsOpenState";
import { List } from "immutable";
import { useCompany } from "hooks/useCompany";
import { IntegrationResourceRoleModel } from "models/IntegrationResourceRoleModel";
import { AreYouSureModal } from "components/common/AreYouSureModal";
import { ResourceHeader } from "components/common/ResourceHeader";
import { IntegrationResourceModel } from "models/IntegrationResourceModel";
import { IntegrationModel } from "models/IntegrationModel";
import { useStyles } from "./styles";
import { RoleSettingsPopup } from "./RoleSettingsPopup";
import type { TTicketDuration } from "utils/durationsOptions";
import type { TRoleFormData } from "./RoleSettingsPopup/useSettingsPopupState";

interface IProps {
	manual: boolean;
	virtual: boolean;
	enableOverrideDurations?: boolean;
	integrationResourceRole: IntegrationResourceRoleModel;
	integrationResource: IntegrationResourceModel;
	integration: IntegrationModel;
	onDeleteButtonClick?: () => Promise<void>;
	onRename?: (name: string) => Promise<void>;
	onSave: (data: Partial<TIntegrationResourceRole>) => Promise<void>;
}

export const RoleHeaderBlock: FC<IProps> = ({
	manual,
	virtual,
	integrationResourceRole,
	integrationResource,
	integration,
	onDeleteButtonClick,
	onSave: propOnSave
}) => {
	const classes = useStyles();
	const { t } = useTranslation();
	const company = useCompany();
	const {
		name,
		allowsRequests,
		allowAsGrantMethod,
		allowedDurations: roleAllowedDurations
	} = integrationResourceRole.toObject();

	const [roleName, setRoleName] = useState(name);

	const allowedDurations = useMemo(() => {
		return (
			roleAllowedDurations ||
			integrationResource?.allowedDurations ||
			integration?.allowedDurations ||
			company?.allowedDurations ||
			List<TTicketDuration>()
		);
	}, [company, integration, integrationResource, roleAllowedDurations]);

	const { errorModalSetError, errorModalIsOpen, errorModalError, errorModalRetry, errorModalClose } =
		useErrorModalState();

	const areYouSureModal = useIsOpenState();

	const onUpdateRole = useCallback(
		async (roleData: Partial<TIntegrationResourceRole & { id: string }>) => {
			try {
				await propOnSave(roleData);
			} catch (e) {
				errorModalSetError(e as Error);
			}
		},
		[errorModalSetError, propOnSave]
	);

	const onSave = useCallback(
		async (formData: TRoleFormData) => {
			await propOnSave({
				id: integrationResourceRole.id,
				name: formData.name,
				allowAsGrantMethod: formData.allowAsGrantMethod,
				allowsRequests: formData.allowsRequests,
				allowedDurations: formData.allowedDurations || null
			});
		},
		[integrationResourceRole, propOnSave]
	);

	const onRoleRename = useCallback(
		async (newName: string) => {
			setRoleName(newName);
			await onUpdateRole({ id: integrationResourceRole.id, name: newName });
		},
		[integrationResourceRole.id, onUpdateRole]
	);

	const onDelete = useCallback(() => {
		if (integrationResourceRole.id) {
			areYouSureModal.open();
		}
	}, [areYouSureModal, integrationResourceRole]);

	const initialData = useMemo(
		() => ({
			allowAsGrantMethod: virtual || allowAsGrantMethod,
			allowedDurations,
			overrideAllowedDurations: !!integrationResourceRole.allowedDurations,
			allowsRequests,
			name: roleName
		}),
		[allowAsGrantMethod, allowedDurations, allowsRequests, integrationResourceRole.allowedDurations, roleName, virtual]
	);

	useEffect(() => {
		setRoleName(integrationResourceRole.name);
	}, [integrationResourceRole.name]);

	return (
		<div className={classes.header}>
			<div className={classes.roleHeaderBlock}>
				<div className={classes.roleHeaderSection}>
					<ResourceHeader
						name={roleName}
						onRename={manual || virtual ? onRoleRename : undefined}
						resourceType="role"
						requestsDisabled={!allowsRequests}
					/>
				</div>
				<div className={classes.roleHeaderBlock}>
					{onDeleteButtonClick && (
						<AreYouSureModal
							isOpen={areYouSureModal.isOpen}
							onClose={areYouSureModal.close}
							onAction={onDeleteButtonClick}
						/>
					)}
					<ErrorModal
						isOpen={errorModalIsOpen}
						error={errorModalError}
						onRetry={errorModalRetry}
						onClose={errorModalClose}
					/>
					<RoleSettingsPopup
						manual={manual}
						virtual={virtual}
						integrationResource={integrationResource || undefined}
						onSave={onSave}
						integration={integration}
						initialFormData={initialData}
					/>
					{onDeleteButtonClick && (
						<>
							<Separator />
							<Button size="small" variant="text" onClick={onDelete}>
								{t("buttons.delete")}
							</Button>
						</>
					)}
				</div>
			</div>
		</div>
	);
};
