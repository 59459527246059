import { createUseStyles } from "react-jss";
import { BP_MEDIA_QUERIES } from "utils/breakpoints";

export const useStyles = createUseStyles({
	container: {
		display: "flex",
		alignItems: "center"
	},
	linkButton: {
		maxWidth: "120px",
		[BP_MEDIA_QUERIES.FIFTH]: {
			maxWidth: "140px"
		}
	},
	textPadding: {
		padding: "2px 8px"
	}
});
