import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	iconButton: {
		alignItems: "center",
		border: "none",
		display: "flex",
		flexShrink: 0,
		justifyContent: "center",
		padding: "4px",
		transitionDuration: "0.3s",
		transitionProperty: "background-color, border-color, color",
		transitionTimingFunction: "ease-out",
		"&$disabled": {
			transition: "none",
			pointerEvents: "none"
		},
		"&$loading": {
			pointerEvents: "none"
		},
		"&:hover": {
			cursor: "pointer"
		}
	},
	huge: {
		borderRadius: "6px",
		fontSize: "32px"
	},
	large: {
		borderRadius: "4px",
		fontSize: "20px"
	},
	medium: {
		borderRadius: "4px",
		fontSize: "16px"
	},
	small: {
		borderRadius: "4px",
		fontSize: "12px"
	},
	tiny: {
		borderRadius: "4px",
		fontSize: "8px"
	},
	primary: {
		backgroundColor: "var(--icon-button-primary-background-color-regular)",
		color: "var(--icon-button-primary-color)",
		"&:hover": {
			backgroundColor: "var(--icon-button-primary-background-color-hover)"
		},
		"&:active": {
			backgroundColor: "var(--icon-button-primary-background-color-pressed)"
		},
		"&$disabled": {
			color: "var(--icon-button-primary-color-disabled)"
		}
	},
	secondary: {
		backgroundColor: "var(--icon-button-secondary-background-color-regular)",
		border: "1px solid var(--icon-button-secondary-border-color)",
		color: "var(--icon-button-secondary-color)",
		"&:hover": {
			backgroundColor: "var(--icon-button-secondary-background-color-hover)"
		},
		"&:active": {
			backgroundColor: "var(--icon-button-secondary-background-color-pressed)"
		},
		"&$disabled": {
			color: "var(--icon-button-secondary-color-disabled)"
		}
	},
	reverse: {
		backgroundColor: "var(--icon-button-reverse-background-color-regular)",
		color: "var(--icon-button-reverse-color)",
		"&:hover": {
			backgroundColor: "var(--icon-button-reverse-background-color-hover)"
		},
		"&:active": {
			backgroundColor: "var(--icon-button-reverse-background-color-pressed)"
		},
		"&$disabled": {
			color: "var(--icon-button-reverse-color-disabled)"
		}
	},
	disabled: {},
	loading: {}
});
