import React, { useCallback, useEffect, useMemo, useState } from "react";
import classNames from "classnames";
import { useResizeDetector } from "react-resize-detector";
import { Chip } from "../New/Chip";
import { Tooltip } from "../Tooltip";
import { ChevronDownIcon } from "../Icons/ChevronDownIcon";
import { useStyles } from "./styles";

type TProps = {
	chips: React.ReactNode[];
	children?: never;
	size?: "small" | "regular" | "large";
};

export const ChipContainer: FC<TProps> = ({ className, chips, size }) => {
	const classes = useStyles();
	const [displayedChipsCount, setDisplayedChipsCount] = useState(0);
	const [doneCalculating, setDoneCalculating] = useState(false);
	const [deferredDone, setDeferredDone] = useState(false);

	const reset = useCallback(() => {
		setDisplayedChipsCount(0);
		setDoneCalculating(false);
	}, []);

	const { ref } = useResizeDetector({ onResize: reset, refreshMode: "debounce", refreshRate: 100 });

	const wrappedChips = useMemo(() => {
		return chips.map((chip, index) => {
			if (typeof chip === "string") {
				return (
					<Chip key={index} size={size}>
						{chip}
					</Chip>
				);
			}
			return chip;
		});
	}, [chips, size]);

	const displayedChips = useMemo(() => {
		return wrappedChips.slice(0, displayedChipsCount);
	}, [displayedChipsCount, wrappedChips]);

	const undisplayedChips = useMemo(() => {
		return wrappedChips.slice(displayedChipsCount);
	}, [displayedChipsCount, wrappedChips]);

	const tooltipChip = useMemo(() => {
		if (undisplayedChips.length === 0) return null;
		return (
			<Tooltip content={<div className={classes.tooltipContentContainer}>{undisplayedChips.map(chip => chip)}</div>}>
				<Chip size={size} suffix={<ChevronDownIcon />}>
					{undisplayedChips.length}+
				</Chip>
			</Tooltip>
		);
	}, [classes.tooltipContentContainer, size, undisplayedChips]);

	useEffect(() => {
		if (displayedChipsCount > chips.length) {
			setDoneCalculating(true);
			return;
		}
		if (doneCalculating) return;
		const currentRef = ref.current;
		if (!currentRef) return;
		if (currentRef.clientWidth >= currentRef.scrollWidth) {
			setDisplayedChipsCount(prev => prev + 1);
		} else {
			setDisplayedChipsCount(prev => prev - 1);
			setDoneCalculating(true);
		}
	}, [chips.length, displayedChipsCount, doneCalculating, ref]);

	useEffect(() => {
		if (!doneCalculating) {
			setDeferredDone(false);
			return;
		}

		const timeout = setTimeout(() => setDeferredDone(true), 500);
		return () => clearTimeout(timeout);
	}, [doneCalculating]);

	if (chips.length === 0) return null;

	return (
		<div className={classNames(classes.container, { [classes.invisible]: !deferredDone }, className)} ref={ref}>
			{displayedChips}
			{tooltipChip}
		</div>
	);
};
