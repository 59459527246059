import React, { useCallback, useMemo, useRef } from "react";
import classNames from "classnames";
import { List, Set } from "immutable";
import { ArrowLeftIcon } from "components/ui/Icons/ArrowLeftIcon";
import { ArrowRightIcon } from "components/ui/Icons/ArrowRightIcon";
import { IconButton } from "components/ui/IconButton";
import { CloseIcon } from "components/ui/Icons/CloseIcon";
import { IntegrationModel } from "models/IntegrationModel";
import { BundleModel } from "models/BundleModel";
import { BundleCard } from "components/common/Cards/BundleCard";
import { IntegrationCard } from "components/common/Cards/IntegrationCard";
import { useStyles } from "./styles";

import type { IntegrationResourceRoleModel } from "models/IntegrationResourceRoleModel";
import type { TCardProps } from "components/common/Cards/Card";
import type { TCartItem } from "../../RequestCart";

type TCartHeaderItemProps = {
	model: IntegrationModel | BundleModel;
	selected: boolean;
	onClick: (id: string) => void;
	roles: List<IntegrationResourceRoleModel>;
	// integrationId or bundleId
	removeAppFromCart: (id: string) => void;
};

const RequestCartHeaderItem: FC<TCartHeaderItemProps> = ({
	className,
	model,
	onClick,
	removeAppFromCart,
	roles,
	selected
}) => {
	const ref = useRef<HTMLDivElement>(null);
	const distinctResourcesCount = useMemo(() => {
		return roles.reduce((acc, role) => acc.add(role.integrationResourceId), Set<string>()).size;
	}, [roles]);

	const wrappedClick = useCallback(() => {
		ref.current?.scrollIntoView({ behavior: "smooth", block: "nearest" });
		onClick(model.id);
	}, [model.id, onClick]);

	const onRemove = useCallback(() => {
		removeAppFromCart(model.id);
	}, [model.id, removeAppFromCart]);

	const cardProps: TProps<Omit<TCardProps, "header" | "content">> = useMemo(
		() => ({
			size: "small",
			selected: !selected,
			expanded: selected,
			onClick: wrappedClick,
			resourcesCount: distinctResourcesCount,
			rolesCount: roles.size,
			fixedWidth: true,
			className,
			innerRef: ref,
			topActions: (
				<IconButton size="small" onClick={onRemove}>
					<CloseIcon />
				</IconButton>
			)
		}),
		[selected, wrappedClick, distinctResourcesCount, roles.size, className, onRemove]
	);

	return model instanceof BundleModel ? (
		<BundleCard bundle={model} {...cardProps} />
	) : (
		<IntegrationCard integration={model} {...cardProps} />
	);
};

type TRequestCartHeaderProps = {
	selectedApp?: string;
	setSelectedApp: (id: string) => void;
	cartApps: List<BundleModel | IntegrationModel>;
	getCartItems: (id: string) => List<TCartItem>;
	removeAppFromCart: (id: string) => void;
};

export const RequestCartHeader: FC<TRequestCartHeaderProps> = ({
	cartApps,
	className,
	getCartItems,
	innerRef,
	removeAppFromCart,
	selectedApp,
	setSelectedApp
}) => {
	const classes = useStyles();
	const headersRef = useRef<HTMLDivElement>(null);

	const onArrowClick = useCallback(
		(direction: "left" | "right") => {
			if (selectedApp?.length) return;
			headersRef.current?.scrollBy({
				left: direction === "left" ? -200 : 200,
				behavior: "smooth"
			});
		},
		[selectedApp]
	);

	const onArrowClickLeft = useCallback(() => onArrowClick("left"), [onArrowClick]);
	const onArrowClickRight = useCallback(() => onArrowClick("right"), [onArrowClick]);

	const cartHeaders = useMemo(() => {
		const models = cartApps.toArray();
		return models.map(model => {
			const selected = selectedApp === model.id;
			const cartItems = getCartItems(model.id);
			const roles = cartItems.map(item => item.role);
			return (
				<RequestCartHeaderItem
					className={selected ? classes.expandHeader : undefined}
					key={model.id}
					model={model}
					onClick={setSelectedApp}
					removeAppFromCart={removeAppFromCart}
					roles={roles}
					selected={selected}
				/>
			);
		});
	}, [cartApps, getCartItems, classes.expandHeader, removeAppFromCart, selectedApp, setSelectedApp]);

	if (!cartApps.size) return null;
	return (
		<div
			className={classNames(classes.headersContainer, { [classes.hasSelected]: selectedApp?.length, className })}
			ref={innerRef}>
			<div className={classes.arrowContainerLeft} onClick={onArrowClickLeft}>
				<div className={classes.arrowLeft}>
					<ArrowLeftIcon size={20} />
				</div>
			</div>
			<div className={classes.arrowContainerRight} onClick={onArrowClickRight}>
				<div className={classes.arrowRight}>
					<ArrowRightIcon size={20} />
				</div>
			</div>
			<div className={classes.headers} ref={headersRef}>
				{cartHeaders}
			</div>
		</div>
	);
};
