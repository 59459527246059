import React from "react";
import classNames from "classnames";
import { DocumentIcon } from "components/ui/Icons/DocumentIcon";
import { Typography } from "components/ui/New/Typography";
import { useTranslation } from "react-i18next";
import { useStyles } from "./styles";

const TRANSLATION_PREFIX = "pages.accessReview.dashboard.loading" as const;
export const LoadingMessage: FC = ({ className }) => {
	const classes = useStyles();
	const { t } = useTranslation();

	return (
		<div className={classNames(classes.container, className)}>
			<DocumentIcon color="var(--color-blue-500)" size={48} className={classes.icon} />
			<Typography variant="title_sb" className={classes.headerText}>
				{t(`${TRANSLATION_PREFIX}.title`)}
			</Typography>
			<Typography className={classes.message} variant="body_reg">
				{t(`${TRANSLATION_PREFIX}.message`)}
			</Typography>
		</div>
	);
};
