import React, { useMemo } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { GrantedIcon } from "components/ui/Icons/GrantedIcon";
import { PendingIcon } from "components/ui/Icons/PendingIcon";
import { Typography } from "components/ui/New/Typography";
import { NoAccessCircleIcon } from "components/ui/Icons/NoAccessCircleIcon";
import {
	FAILURE_STATUSES,
	SUCCESS_STATUSES,
	TICKET_STATUS_ICON
} from "components/common/RequestDetails/ticketStatusIcons";
import { InfoIcon } from "components/ui/Icons/InfoIcon";
import { useStyles } from "./styles";
import type { TTicketStatus } from "models/TicketModel";
import type { TFullTicket } from "../../types";

const ICON_SIZE_PX = 24;

interface IProps {
	ticket: TFullTicket;
}

const TICKET_STATUS_TO_EXTRA_DETAILS = new Set<TTicketStatus>(["failed", "waitingForIT"]);

const ExtraDetails: FC<{ status: TTicketStatus }> | null = React.memo(function ExtraDetailsComponent({ status }) {
	const classes = useStyles();
	const { t } = useTranslation("translation", { keyPrefix: "common.requestDetails.generalInfo.status.extraDetails" });

	return TICKET_STATUS_TO_EXTRA_DETAILS.has(status) ? (
		<div className={classes.extraDetails}>
			<InfoIcon />
			<Typography variant="text_sm_reg">{t(status as "failed" | "waitingForIT")}</Typography>
		</div>
	) : null;
});

const RequestDetailsStatusComponent: FC<IProps> = ({ ticket }) => {
	const classes = useStyles();
	const { t } = useTranslation("translation", { keyPrefix: "common.requestDetails" });

	const Icon = TICKET_STATUS_ICON.get(ticket.status)!;
	const titleIcon = useMemo(() => {
		if (SUCCESS_STATUSES.has(ticket.status)) return <GrantedIcon size="medium" />;
		if (FAILURE_STATUSES.has(ticket.status)) return <NoAccessCircleIcon size="medium" />;

		return <PendingIcon size="medium" />;
	}, [ticket.status]);

	return (
		<>
			<div className={classes.statusTitle}>
				<Typography variant="text_sm_sb">{t("generalInfo.status.title")}</Typography>
				{titleIcon}
			</div>
			<div
				className={classNames(classes.statusContainer, {
					[classes.expiredStatus]: ticket.status === "revoked",
					[classes.failureStatus]: FAILURE_STATUSES.has(ticket.status),
					[classes.successStatus]: SUCCESS_STATUSES.has(ticket.status)
				})}>
				<Icon size={ICON_SIZE_PX} />
				<Typography variant="text_sm_sb">{t(`requestStatuses.${ticket.status}`)}</Typography>
			</div>
			<ExtraDetails status={ticket.status} />
		</>
	);
};

export const RequestDetailsStatus = React.memo(RequestDetailsStatusComponent);
