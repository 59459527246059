import { Chip } from "components/ui/Chip";
import { WorkflowsIcon } from "components/ui/Icons/WorkflowsIcon";
import { DeleteIcon } from "components/ui/Icons/DeleteIcon";
import { EditIcon } from "components/ui/Icons/EditIcon";
import { Typography } from "components/ui/Typography";
import { useBundles } from "hooks/useBundles";
import { BundleItemModel } from "models/BundleItemModel";
import { BundleModel } from "models/BundleModel";
import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Tooltip } from "components/ui/Tooltip";
import { useIntegrations } from "hooks/useIntegrations";
import { IconButton } from "components/ui/IconButton";
import { Chips } from "components/ui/MultipleSelect/components/Chips";
import { TooltipOnOverflow } from "components/ui/TooltipOnOverflow";
import { WarningIcon } from "components/ui/Icons/WarningIcon";
import { type TColumn, VirtualTable } from "components/ui/VirtualTable";
import { Block } from "components/ui/Block";
import { Input } from "components/ui/Input";
import { useStyles } from "./styles";

interface IProps {
	onEdit?: ((bundle: BundleModel) => void) | null;
	onRemove?: ((bundle: BundleModel) => void) | null;
}

const BundleInfo = ({ bundle }: { bundle: BundleModel }) => {
	const classes = useStyles();
	const { t } = useTranslation();

	return (
		<Typography className={classes.bundle}>
			<div className={classes.primaryData}>
				<Typography noWrap className={classes.maxWidth}>
					{bundle.name}
				</Typography>
				{bundle.category && (
					<div>
						<Typography variant="tiny" noWrap className={classes.maxWidth}>
							{t("pages.bundles.category")}: {bundle.category}
						</Typography>
					</div>
				)}

				{bundle.description && (
					<div className={classes.bundleDescription}>
						<Typography variant="small">{bundle.description}</Typography>
					</div>
				)}
			</div>
			{bundle?.tags && (
				<div className={classes.bundleTagsContainer}>
					<Chips
						values={bundle?.tags}
						limit={3}
						getOptionLabel={opt => opt}
						size="tiny"
						ChipElement={({ option }) => (
							<Chip className={classes.bundleTagChip} size="tiny">
								<Typography variant="tiny" className={classes.tag} noWrap>
									{option}
								</Typography>
							</Chip>
						)}
						tooltipClassName={classes.tooltip}
						collapsedValuesClassName={classes.collapsedValues}
					/>
				</div>
			)}
		</Typography>
	);
};

const BundlesTable = ({ bundles, onEdit, onRemove }: { bundles: BundleModel[] } & IProps) => {
	const { t } = useTranslation();
	const classes = useStyles();

	const ActionCell = useMemo(() => {
		return actionCellConstructor(onEdit, onRemove);
	}, [onEdit, onRemove]);

	const columns: TColumn<BundleModel>[] = useMemo(
		() =>
			[
				{
					renderCell: row => (
						<div className={classes.cell}>
							<BundleInfo bundle={row} />
						</div>
					),
					header: t("pages.bundles.name"),
					key: "name",
					width: "minmax(180px, 3fr)"
				},
				{
					renderCell: row => (
						<div className={classes.bundleItems}>
							{row.bundleItems?.map((bundleItem: BundleItemModel) => (
								<Chip key={bundleItem.id} prefixIcon={<ApplicationIcon bundleItem={bundleItem} />}>
									<Typography variant="small" className={classes.resourceName}>
										{bundleItem.integrationResourceRole?.integrationResource?.displayName}
									</Typography>
									<Typography variant="tiny" className={classes.roleName}>
										({bundleItem?.integrationResourceRole.name})
									</Typography>
								</Chip>
							))}
							{!row.bundleItems || !row.bundleItems.size ? (
								<Typography variant="small" className={classes.noResourcesWarning}>
									<WarningIcon />
									<TooltipOnOverflow content={t("pages.bundles.noConnectedResources")} />
								</Typography>
							) : null}
						</div>
					),
					header: t("pages.bundles.resourcesAndRoles"),
					key: "resourcesAndRoles",
					width: "minmax(530px, 9fr)"
				},
				{
					renderCell: row => (
						<Typography component="div" className={classes.approvalAlgorithm}>
							<WorkflowsIcon className={classes.workflowIcon} />{" "}
							<TooltipOnOverflow content={row.approvalAlgorithm?.name} />
						</Typography>
					),
					header: t("pages.bundles.approvalAlgorithm"),
					key: "approvalAlgorithm",
					width: "minmax(150px, 4fr)"
				},
				{
					renderCell: row => <ActionCell data={row} />,
					key: "actions",
					width: "120px"
				}
			] as TColumn<BundleModel>[],
		[ActionCell, classes, t]
	);

	return <VirtualTable rows={bundles} totalRows={bundles.length} columns={columns} />;
};

export const BundleList: FC<IProps> = ({ onEdit, onRemove }) => {
	const [query, setQuery] = useState("");
	const { t } = useTranslation();
	const classes = useStyles();
	const bundles = useBundles();

	const isBundleMatchQuery = useCallback(
		(bundle: BundleModel) => {
			const lowerCasedQuery = query.toLowerCase();
			return (
				bundle.name.toLowerCase().includes(lowerCasedQuery) ||
				bundle.description?.toLowerCase().includes(lowerCasedQuery) ||
				bundle.category?.toLowerCase().includes(lowerCasedQuery) ||
				bundle.tags?.some(tag => tag.toLowerCase() === lowerCasedQuery) ||
				bundle.approvalAlgorithm?.name?.toLowerCase().includes(lowerCasedQuery) ||
				bundle.bundleItems?.some(
					bundleItem =>
						bundleItem.integrationResourceRole.integrationResource?.displayName
							.toLowerCase()
							.includes(lowerCasedQuery) ||
						bundleItem.integrationResourceRole.name.toLowerCase().includes(lowerCasedQuery)
				)
			);
		},
		[query]
	);

	const bundleList = useMemo(
		() =>
			bundles
				?.toList()
				.filter(isBundleMatchQuery)
				.sortBy(bundle => bundle.name)
				.toArray(),
		[bundles, isBundleMatchQuery]
	);

	if (!bundleList) return null;

	return (
		<Block className={classes.bundlesListContainer}>
			<Input
				className={classes.bundlesListInput}
				placeholder={t("pages.users.search")}
				onValueChange={setQuery}
				value={query}
			/>
			<BundlesTable bundles={bundleList} onEdit={onEdit} onRemove={onRemove} />
		</Block>
	);
};

const ApplicationIcon: FC<{ bundleItem: BundleItemModel }> = ({ bundleItem }) => {
	const classes = useStyles();
	const integrations = useIntegrations();
	const integration = integrations?.get(bundleItem?.integrationResourceRole.integrationResource?.integrationId || "");

	const imageUrl = integration?.imageUrl;
	const applicationName = integration?.application?.name;

	if (!imageUrl) return null;
	return <img className={classes.applicationIcon} src={imageUrl} alt={applicationName || "Application"} />;
};

const actionCellConstructor = (
	onEdit?: ((bundle: BundleModel) => void) | null,
	onRemove?: ((bundle: BundleModel) => void) | null
): FC<{ data: BundleModel }> => {
	const Cell: FC<{ data: BundleModel }> = ({ data }) => {
		const classes = useStyles();
		const { t } = useTranslation();
		const handleEditClick = useCallback(() => onEdit && onEdit(data), [data]);
		const handleRemoveClick = useCallback(() => onRemove && onRemove(data), [data]);
		return (
			<div className={classes.actions}>
				{onEdit && (
					<Tooltip content={t("buttons.edit")}>
						<IconButton size="medium" onClick={handleEditClick} className={classes.actionsButton}>
							<EditIcon />
						</IconButton>
					</Tooltip>
				)}
				{onRemove && (
					<Tooltip content={t("buttons.delete")}>
						<IconButton size="medium" onClick={handleRemoveClick} className={classes.actionsButton}>
							<DeleteIcon />
						</IconButton>
					</Tooltip>
				)}
			</div>
		);
	};
	return Cell;
};
