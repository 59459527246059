import { IntegrationResourceModel } from "models/IntegrationResourceModel";
import { apiReq } from "utils/apiReq";
import { getPaginatedSearchQueryString, IPaginatedSearchOptions } from "utils/searchUtils";
import { IPaginationResponse, withPagination } from "utils/pagination";
import { List } from "immutable";
import { TTicketDuration } from "../utils/durationsOptions";
import type { Require } from "utils/types";

export type TFullIntegrationResourceModel = Require<IntegrationResourceModel, "rolesCount">;

export async function getIntegrationResources(
	integrationId: string,
	paginatedSearchOptions?: IPaginatedSearchOptions,
	resourceTypes?: string[],
	userId?: string,
	abortSignal: AbortSignal | null = null
): Promise<IPaginationResponse<TFullIntegrationResourceModel>> {
	const searchQs = getPaginatedSearchQueryString(paginatedSearchOptions);
	const userIdQs = userId ? `userId=${userId}` : "";
	const resourceTypesQs = resourceTypes?.map(type => `resourceTypes=${type}`) || [];
	const querystring = `?${[userIdQs, searchQs, ...resourceTypesQs].filter(Boolean).join("&")}`;

	return withPagination(
		(resource: Record<string, unknown>) =>
			IntegrationResourceModel.fromServerData(resource) as TFullIntegrationResourceModel,
		() => apiReq("GET", `/v1/integrations/${integrationId}/resources${querystring}`, null, { abortSignal })
	);
}

export async function getIntegrationResource(
	integrationResourceId: string,
	full = false,
	withDeleted = false
): Promise<TFullIntegrationResourceModel> {
	const { data } = await apiReq(
		"GET",
		`/v1/integrationResources/${integrationResourceId}?full=${full}&withDeleted=${withDeleted}`
	);
	return IntegrationResourceModel.fromServerData(data) as TFullIntegrationResourceModel;
}

interface IIntegrationResourceMaintainer {
	entityId: string;
	entityType: "user" | "directoryGroup";
}

export type TIntegrationResourceBody = {
	allowAsGrantMethod?: boolean;
	allowedDurations?: List<TTicketDuration> | null;
	allowsRequests?: boolean;
	approvalAlgorithmId?: string | null;
	description?: string | null;
	displayName: string;
	id?: string;
	integrationId: string;
	maintainers: IIntegrationResourceMaintainer[];
	multirole: boolean;
	ownerUserId?: string | null;
	tags?: string[] | null;
	type?: string;
};

export type TIntegrationResourceCreateBody = TIntegrationResourceBody & {
	roles?: { name: string; virtualizedRoleId: string }[];
};

export async function createIntegrationResource(
	integrationResource: TIntegrationResourceCreateBody
): Promise<IntegrationResourceModel> {
	const { data } = await apiReq("POST", "/v1/integrationResources", integrationResource);

	return IntegrationResourceModel.fromServerData(data);
}

export async function editIntegrationResource(
	integrationResource: Partial<TIntegrationResourceBody>
): Promise<IntegrationResourceModel> {
	const { data } = await apiReq("PUT", `/v1/integrationResources/${integrationResource.id}`, integrationResource);

	return IntegrationResourceModel.fromServerData(data);
}

export async function deleteIntegrationResource(id: string): Promise<IntegrationResourceModel> {
	const { data } = await apiReq("DELETE", `/v1/integrationResources/${id}`);

	return IntegrationResourceModel.fromServerData(data);
}

export async function addResourcePermission(id: string, roleId: string): Promise<TFullIntegrationResourceModel> {
	const url = `/v1/integrationResources/${id}/addResourcePermission?roleId=${roleId}`;
	const { data } = await apiReq("POST", url);

	return IntegrationResourceModel.fromServerData(data) as TFullIntegrationResourceModel;
}

export async function deleteResourcePermission(id: string, roleId: string): Promise<TFullIntegrationResourceModel> {
	const url = `/v1/integrationResources/${id}/deleteResourcePermission?roleId=${roleId}`;
	const { data } = await apiReq("DELETE", url);

	return IntegrationResourceModel.fromServerData(data) as TFullIntegrationResourceModel;
}
