import React, { useMemo } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { IconButton } from "components/ui/IconButton";
import { CloseIcon } from "components/ui/Icons/CloseIcon";
import { ChipContainer } from "components/ui/ChipContainer";
import { Chip } from "components/ui/New/Chip";
import { ResourcesIcon } from "components/ui/Icons/ResourcesIcon";
import { Typography } from "components/ui/New/Typography";
import { RoleIcon } from "components/ui/Icons/RoleIcon";
import { ResourceDetailsTooltip } from "components/common/ResourceDetailsTooltip";
import { useStyles } from "../styles";
import type { IntegrationResourceModel } from "models/IntegrationResourceModel";

type TResourceCardHeaderProps = {
	resource: IntegrationResourceModel;
	onRemoveResource?: () => void;
	onClick?: (event: React.MouseEvent) => void;
};

export const ResourceCardHeader: FC<TResourceCardHeaderProps> = ({ resource, onRemoveResource, onClick }) => {
	const classes = useStyles();

	const topAction = useMemo(() => {
		if (!onRemoveResource) return null;
		return (
			<IconButton variant="reverse" size="small" onClick={onRemoveResource}>
				<CloseIcon />
			</IconButton>
		);
	}, [onRemoveResource]);

	const [tags, hasChips] = useMemo(() => {
		const tags = resource.calculatedTags;
		return [tags, tags.length > 0];
	}, [resource]);

	return (
		<div className={classNames(classes.header, { [classes.clickable]: !!onClick })} onClick={onClick}>
			<div className={classes.headerActions}>{topAction}</div>
			<div className={classes.headerResource}>
				<div className={classes.headerResourceName}>
					<ResourcesIcon size={20} />
					<ResourceDetailsTooltip
						euid={resource.euid}
						name={resource.displayName}
						description={resource.calculatedDescription}
						type={resource.type}>
						<Typography variant="body_sb" noWrap={hasChips} className={classNames({ [classes.clamp]: !hasChips })}>
							{resource.displayName}
						</Typography>
					</ResourceDetailsTooltip>
				</div>
				<ChipContainer className={classes.chipContainer} chips={tags} size="small" />
			</div>
		</div>
	);
};

const RolesTitle: FC<{ chipContent: string | number }> = ({ chipContent }) => {
	const { t } = useTranslation();
	const classes = useStyles();
	return (
		<div className={classes.rolesTitleContainer}>
			<div className={classes.rolesTitle}>
				<RoleIcon size={16} />
				<Typography variant="body_sb">
					{t("entities.plural.IntegrationResourceRole", { context: "capital" })}
				</Typography>
			</div>
			<Chip size="small">{chipContent}</Chip>
		</div>
	);
};

const memoizedRolesTitle = React.memo(RolesTitle);
export { memoizedRolesTitle as RolesTitle };
