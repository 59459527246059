import { createUseStyles } from "react-jss";
import { BP_MEDIA_QUERIES } from "utils/breakpoints";

export const useStyles = createUseStyles({
	cartItemsContainer: {
		background: "var(--color-blue-200)",
		borderRadius: "0 0px 40px 40px",
		padding: "var(--spacing-x6) 0 var(--spacing-x4) 0",
		maxHeight: "644px",
		overflow: "hidden",
		width: "100%",
		"&$scrollable": {
			paddingBottom: 0,
			"& > $cartItems": {
				maxHeight: "620px"
			}
		}
	},
	cartItems: {
		display: "grid",
		gap: "var(--spacing-x4)",
		gridTemplateColumns: "repeat(2, 1fr)",
		padding: "0 var(--spacing-x4)",
		maxHeight: "604px",
		overflowY: "auto",
		width: "100%",
		[BP_MEDIA_QUERIES.FOURTH]: {
			gridTemplateColumns: "repeat(3, 1fr)"
		},
		[BP_MEDIA_QUERIES.THIRD]: {
			gridTemplateColumns: "repeat(4, 1fr)"
		},
		[BP_MEDIA_QUERIES.SECOND]: {
			gridTemplateColumns: "repeat(5, 1fr)"
		},
		[BP_MEDIA_QUERIES.FIRST]: {
			gridTemplateColumns: "repeat(6, 1fr)"
		},
		"& > *": {
			alignSelf: "start"
		}
	},
	bundlesRoles: {
		alignItems: "center",
		background: "var(--color-blue-200)",
		borderRadius: "0 0px 40px 40px",
		color: "var(--color-grey-600)",
		display: "flex",
		minHeight: "140px",
		paddingLeft: "var(--spacing-x12)",
		paddingTop: "var(--spacing-x4)",
		width: "100%"
	},
	emptyCart: {
		alignItems: "center",
		backgroundColor: "var(--color-purple-50)",
		color: "var(--color-grey-600)",
		display: "flex",
		justifyContent: "center",
		minHeight: "140px",
		height: "100%",
		width: "100%"
	},
	scrollable: {}
});
