import { Map, Record } from "immutable";
import { TicketModel } from "../TicketModel";

export type TTicketAuditLogAction =
	| "AccessRequestApproved"
	| "AccessRequestApproverApproved"
	| "AccessRequestApproverDeclined"
	| "AccessRequestAutomaticApproved"
	| "AccessRequestCreated"
	| "AccessRequestForwarded"
	| "AccessRequestPassedApprovalFlowStep"
	| "AccessRequestPermissionAlreadyExist"
	| "AccessRequestPermissionExpiredRevokedFailure"
	| "AccessRequestPermissionExpiredRevokedSuccess"
	| "AccessRequestPermissionGrantedFailure"
	| "AccessRequestPermissionGrantedSuccess"
	| "AccessRequestPermissionRetryGrant"
	| "AccessRequestPermissionRetryRevert"
	| "AccessRequestPermissionRetryRevoke"
	| "AccessRequestPermissionReverted"
	| "AccessRequestPermissionTryGrant"
	| "AccessRequestPermissionTryRevert"
	| "AccessRequestPermissionTryRevoke"
	| "AccessRequestPermissionWontRevoke"
	| "AccessRequestRedirectedByApprover"
	| "AccessRequestRedirectedToAdminDisabledWebhook"
	| "AccessRequestRedirectedToAdminEmptyGroup"
	| "AccessRequestRedirectedToAdminNoManager"
	| "AccessRequestRedirectedToAdminNoTeamMembers"
	| "AccessRequestStatusChanged"
	| "AccessRequestTaskCreated"
	| "AccessRequest3rdPartyTicketClosed";

const defaults = {
	createdAt: new Date(0),
	data: null as Map<string, unknown> | null,
	action: "AccessRequestCreated" as TTicketAuditLogAction,
	id: "",
	ticket: null as TicketModel | null,
	ticketId: "",
	userId: ""
};

export class TicketAuditLogModel extends Record<typeof defaults>(defaults) {
	static readonly type = "ticket";

	static fromServerData(data: unknown) {
		const { id, createdAt, data: _data, action, ticket, ticketId, userId } = data as typeof defaults;

		return new TicketAuditLogModel({
			id,
			createdAt: new Date(createdAt),
			data: _data && Map(_data),
			action,
			ticket: ticket && TicketModel.fromServerData(ticket),
			ticketId,
			userId
		});
	}
}
