import React, { useCallback, useEffect, useMemo, useState } from "react";
import sortBy from "lodash/sortBy";
import { RoleLabel } from "components/common/RoleLabel";
import { Select } from "components/ui/Select";
import { useSelectSearchProps } from "hooks/useSelectSearchProps";
import { getGrantMethods } from "api/accessRequestForm";
import { useTranslation } from "react-i18next";
import { useIntegrations } from "hooks/useIntegrations";
import { Form } from "components/ui/Form";
import { useNewTicketFormContext } from "components/pages/NewTicketPage/newTicketContext";
import type { IntegrationResourceRoleModel } from "models/IntegrationResourceRoleModel";
import type { TFullRole } from "../../types";
import type { Require } from "utils/types";

const LIMIT = 100;
interface IProps {
	parent: Require<IntegrationResourceRoleModel, "integrationResource">;
}

const TRANSLATION_PREFIX = "pages.newTicket.newTicketForm.grantMethodInput";

const equality = (option: IntegrationResourceRoleModel, selected: IntegrationResourceRoleModel) =>
	option.id === selected.id;

export const GrantMethodField: FC<IProps> = ({ parent }) => {
	const { t } = useTranslation();
	const integrations = useIntegrations();
	const [hasOptions, setHasOptions] = useState(false);

	const {
		state: {
			receiverUser,
			formState: { grantMethods }
		},
		actions: {
			formActions: { setGrantMethod, getIsItemConflicted }
		}
	} = useNewTicketFormContext();

	const userId = receiverUser?.id;

	const selectedRole = useMemo(() => grantMethods.get(parent.id) || null, [grantMethods, parent.id]);
	const setSelectedRole = useCallback(
		(value: IntegrationResourceRoleModel | null) => setGrantMethod(parent.id, value),
		[parent.id, setGrantMethod]
	);

	const fetchRoles = useCallback(
		async (query: string) => {
			if (!userId) return [];

			const response = await getGrantMethods({
				roleId: parent.id,
				userId,
				search: query,
				perPage: LIMIT
			});
			return response.result.toArray();
		},
		[parent.id, userId]
	);

	const { selectProps, query } = useSelectSearchProps(fetchRoles, selectedRole, true);

	useEffect(() => {
		if (!hasOptions && selectProps.options.length) {
			setHasOptions(true);
		}
	}, [hasOptions, selectProps.options.length]);

	const roles = selectProps.options;

	const isSingleOption = useMemo(() => roles?.length === 1 && query === "", [roles, query]);
	const firstOptionIsParent = useMemo(() => roles.at(0)?.id === parent.id, [roles, parent.id]);

	useEffect(() => {
		if (selectProps.loading) return;

		if (!selectedRole) {
			let newGrantMethod = roles.find(role => parent.id === role.id);
			if (!newGrantMethod) {
				if (isSingleOption) {
					newGrantMethod = roles.at(0);
				} else if (!roles.length) {
					newGrantMethod = parent;
				}
			}

			setSelectedRole(newGrantMethod ?? null);
		}
	}, [
		parent.id,
		isSingleOption,
		selectProps.loading,
		setSelectedRole,
		roles,
		selectedRole,
		userId,
		parent,
		firstOptionIsParent
	]);

	const renderOption = useCallback(
		(option: IntegrationResourceRoleModel) => (
			<RoleLabel option={option} query={query ?? ""} withDirectToParent={parent.id} />
		),
		[parent.id, query]
	);

	const renderLabel = useCallback(
		(option: IntegrationResourceRoleModel) => (
			<RoleLabel option={option} query={query ?? ""} withDirectToParent={parent.id} />
		),
		[parent.id, query]
	);

	const getOptionLabel = useCallback(
		(option: IntegrationResourceRoleModel) => {
			if (option.id === parent.id) return t("shared.direct");
			return `${option.integrationResource?.displayName} - ${option.name}`;
		},
		[parent.id, t]
	);

	const sort = useCallback(
		(options: IntegrationResourceRoleModel[]) =>
			sortBy(
				options,
				({ id }) => (id === parent.id ? 0 : 1),
				({ integrationResource }) => integrations?.get(integrationResource?.integrationId || "")?.name,
				({ integrationResource }) => integrationResource?.displayName,
				({ name }) => name
			),
		[integrations, parent.id]
	);

	const isItemConflicted = useMemo(() => getIsItemConflicted(parent.id), [getIsItemConflicted, parent.id]);

	const isOptionDisabled = useCallback(
		(option: IntegrationResourceRoleModel) => {
			return isItemConflicted({ type: "role", value: option as TFullRole });
		},
		[isItemConflicted]
	);

	return (
		<>
			{hasOptions && (!isSingleOption || !firstOptionIsParent) ? (
				<Form.Field>
					<Select
						{...selectProps}
						disabled={isSingleOption}
						filter={null}
						fullWidth
						getOptionLabel={getOptionLabel}
						isOptionEqualToValue={equality}
						label={t(`${TRANSLATION_PREFIX}.label`, {
							roleName: parent.name,
							resourceName: parent.integrationResource.displayName
						})}
						placeholder={t(`${TRANSLATION_PREFIX}.placeholder`)}
						onChange={setSelectedRole}
						sort={sort}
						renderLabel={renderLabel}
						renderOption={renderOption}
						required
						limit={LIMIT}
						value={selectedRole || null}
						shouldDisableOption={isOptionDisabled}
					/>
				</Form.Field>
			) : null}
		</>
	);
};
