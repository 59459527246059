import { TApiTokenDuration } from "./tokenDurations";
import { TTicketDuration } from "./durationsOptions";

export const toNumber = (value: TApiTokenDuration | TTicketDuration | number | string | null): number => {
	if (typeof value === "number") {
		return value;
	}
	if (typeof value === "string") {
		return parseInt(value, 10);
	}
	return -1;
};
