import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	container: {
		alignItems: "flex-start",
		display: "flex",
		flexDirection: "column",
		gap: "8px",
		justifyContent: "flex-start",
		minWidth: "160px",
		maxWidth: "220px",
		flexGrow: 1,
		flexShrink: 1
	},
	image: {
		height: "32px",
		width: "32px",
		borderRadius: "50%"
	},
	tooltipContent: {
		display: "flex",
		flexDirection: "column",
		gap: "8px"
	}
});
