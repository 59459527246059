import { List, Record } from "immutable";
import { IntegrationActorModel } from "./IntegrationActorModel";
import { IntegrationResourceRoleModel } from "./IntegrationResourceRoleModel";
import { TicketPermissionModel } from "./TicketPermissionModel";

export type TRevokeStatus = "manualRevokeRequested" | "notRevoking" | "pendingRevoke";

const defaults = {
	id: "",
	revokeStatus: "notRevoking" as TRevokeStatus,
	integrationActor: null as IntegrationActorModel | null,
	integrationResourceRole: null as IntegrationResourceRoleModel | null,
	deprecatedAt: null as Date | null,
	ticketPermissions: null as List<TicketPermissionModel> | null
};

export class IntegrationActorPermissionModel extends Record<typeof defaults>(defaults) {
	static fromServerData(data: unknown): IntegrationActorPermissionModel {
		const { id, integrationActor, integrationResourceRole, deprecatedAt, revokeStatus, ticketPermissions } =
			data as typeof defaults;
		return new IntegrationActorPermissionModel({
			id,
			revokeStatus,
			integrationActor: integrationActor ? IntegrationActorModel.fromServerData(integrationActor) : null,
			integrationResourceRole: integrationResourceRole
				? IntegrationResourceRoleModel.fromServerData(integrationResourceRole)
				: null,
			ticketPermissions: ticketPermissions
				? List(ticketPermissions.map(ticketPermission => TicketPermissionModel.fromServerData(ticketPermission)))
				: null,
			deprecatedAt: deprecatedAt ? new Date(deprecatedAt) : null
		});
	}
}
