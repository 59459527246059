import classNames from "classnames";
import React, { useMemo } from "react";
import { Chip } from "components/ui/Chip";
import { LoadingDots } from "components/ui/LoadingDots";
import { Typography } from "components/ui/Typography";
import { useBundles } from "hooks/useBundles";
import { BundleIcon } from "components/ui/Icons/BundleIcon";
import { Unknown } from "components/ui/Unknown";
import { useStyles } from "./styles";
import type { BundleModel } from "models/BundleModel";

const BundleItem: FC<{ bundle?: BundleModel }> = ({ bundle }) => {
	const classes = useStyles();

	return (
		<Chip size="tiny" prefixIcon={<BundleIcon />}>
			{bundle ? (
				<div className={classes.bundleChip}>
					<Typography variant="tiny" className={classNames(classes.roleTitle, { deleted: bundle.isDeleted })}>
						{bundle.name}
						{bundle.category ? ` - ${bundle.category}` : ""}
					</Typography>
					<Typography variant="tiny">{bundle.description}</Typography>
				</div>
			) : (
				<Unknown />
			)}
		</Chip>
	);
};

export const BundlesList: FC<{ bundleIds: string[] }> = ({ bundleIds }) => {
	const bundles = useBundles(true);
	const policyBundles = useMemo(() => (bundles ? bundleIds.map(id => bundles.get(id)) : null), [bundles, bundleIds]);

	return policyBundles ? (
		<>
			{policyBundles.map((bundle, index) => (
				<BundleItem key={bundle?.id ?? index} bundle={bundle} />
			))}
		</>
	) : (
		<LoadingDots center />
	);
};
