import React from "react";
import omitBy from "lodash/omitBy";
import isNil from "lodash/isNil";
import { TicketAuditLogModel, TTicketAuditLogAction } from "models/auditLogs/TicketAuditLogModel";
import { TicketCommentModel } from "models/TicketCommentModel";
import { UserModel } from "models/UserModel";
import { useTranslation, Trans, TransProps } from "react-i18next";
import { IntegrationModel } from "models/IntegrationModel";
import { List, Map } from "immutable";
import { useUser } from "hooks/useUser";
import { TicketApprovalEntityModel } from "models/TicketApprovalEntityModel";
import { TicketingIntegrationTicketModel } from "models/TicketingIntegrationTicketModel";
import { getTicketAuditLogRoleData } from "components/pages/AuditLogsPage/components/AuditLogContent/TicketAuditLogContent";
import { useIntegrations } from "hooks/useIntegrations";
import { ApproverTypeUser } from "components/common/ApproverTypeUser";
import { TicketingIntegrationTicketChip } from "components/common/TicketingIntegrationTicketChip";
import { DirectoryGroupTooltip } from "components/common/DirectoryGroupTooltip";
import { TicketStatusWithIcon } from "components/common/TIcketStatusWithIcon";
import { ViewErrorButton } from "components/common/ViewErrorButton";
import { useStyles } from "./styles";
import type { ParseKeys, TFunction } from "i18next";
import type { TFullTicket } from "components/common/RequestDetails";

const TICKET_AUDIT_LOGS_WITH_POSSESSIVE_FORM = [
	"AccessRequestPassedApprovalFlowStep",
	"AccessRequestPermissionExpiredRevokedSuccess",
	"AccessRequestPermissionReverted",
	"AccessRequestPermissionWontRevoke"
];

export interface ICommentActivity {
	cantDelete?: boolean;
	data: TicketCommentModel;
	type: "comment";
}

export interface IAuditLogActivity {
	data: TicketAuditLogModel;
	type: "auditLog";
}

export interface IAuditLogAndCommentActivity {
	cantDelete?: boolean;
	data: {
		auditLog: TicketAuditLogModel;
		comment: TicketCommentModel;
	};
	type: "auditLogAndComment";
}

export type TActivity = ICommentActivity | IAuditLogActivity | IAuditLogAndCommentActivity;

type TReasonWontRevoked = "permissionExistsForLongerTime" | "permissionDoesNotExist";

const sortByCreatedAt = (
	a: { createdAt: string | Date },
	b: { createdAt: string | Date },
	order: "desc" | "asc" = "asc"
) => {
	if (order === "desc") {
		return new Date(b.createdAt).valueOf() - new Date(a.createdAt).valueOf();
	}
	return new Date(a.createdAt).valueOf() - new Date(b.createdAt).valueOf();
};

// Trans requires a self closing component, so we use this one to wrap everything
const TransContent: FC<{ content?: React.ReactNode | null }> = ({ content }) => {
	return <>{content || null}</>;
};
const TC = TransContent;

// this function will extract audit logs and comments to a single array sorted by creation time
export const getActivities = (ticket: TFullTicket): TActivity[] => {
	const sortedAuditLogs = ticket.auditLogs.sort(sortByCreatedAt).toArray() || [];
	const sortedComments = ticket.comments.sort(sortByCreatedAt).toArray() || [];
	if (sortedAuditLogs.length === 0 && sortedComments.length === 0) return [];

	const firstAuditLogIndex = sortedAuditLogs.findIndex(auditLog => auditLog.action === "AccessRequestCreated");
	const [firstAuditLog] = sortedAuditLogs.splice(firstAuditLogIndex, 1);
	// since we are sorting by createdAt, the first comment (justification) will be the oldest one
	const firstCommentIndex = sortedComments.findIndex(comment => !comment.isDeleted);
	const [firstComment] = sortedComments.splice(firstCommentIndex, 1);
	const firstActivity: TActivity = {
		type: "auditLogAndComment",
		data: { auditLog: firstAuditLog, comment: firstComment },
		cantDelete: true
	};
	const mappedLogs: IAuditLogActivity[] = sortedAuditLogs.map(auditLog => ({ type: "auditLog", data: auditLog }));
	const mappedComments: ICommentActivity[] = sortedComments.map(comment => ({ type: "comment", data: comment }));
	const activities: (ICommentActivity | IAuditLogActivity)[] = [...mappedLogs, ...mappedComments].sort((a, b) =>
		sortByCreatedAt(a.data, b.data)
	);

	return [firstActivity, ...activities];
};

const ACTIVITY_ITEM_T_PREFIX = "common.ticketActivity.ticketActivityItem.";

export const TextUser: FC<{ user: UserModel; possessiveForm?: boolean; entity?: TicketApprovalEntityModel }> = ({
	user,
	possessiveForm,
	entity,
	className
}) => {
	const { t } = useTranslation();
	const { user: currentUser } = useUser();
	if (!user) return null;

	let content = user.fullName;

	if (currentUser && currentUser.id === user.id) {
		content = possessiveForm ? t(`${ACTIVITY_ITEM_T_PREFIX}your`) : t(`${ACTIVITY_ITEM_T_PREFIX}you`);
	} else if (possessiveForm) {
		content += t(`${ACTIVITY_ITEM_T_PREFIX}possessive`);
	}

	return (
		<ApproverTypeUser noWrap bold content={content} ticketApprovalEntity={entity} user={user} className={className} />
	);
};

function getManualActionFromData(log: TicketAuditLogModel, users: Map<string, UserModel>) {
	const isManual = !!log.data?.get("isManual");
	// the user who did the manual action
	const manualActionUserId = isManual ? (log.data?.get("userManualActionId") as string) : null;

	const manualUser =
		manualActionUserId && users.has(manualActionUserId) ? <TextUser user={users.get(manualActionUserId)!} /> : <TC />;

	return { isManual, manualUser };
}

function getRedirectFromData(
	log: TicketAuditLogModel,
	users: Map<string, UserModel>,
	t: TFunction,
	approvalEntities?: List<TicketApprovalEntityModel>
) {
	const redirectUserId = log.data?.get("redirectTo") as string | undefined;

	const redirectUser = redirectUserId ? users.get(redirectUserId) : null;

	const redirectTarget = redirectUser ? (
		<TextUser user={redirectUser} />
	) : (
		<TC content={t(`${ACTIVITY_ITEM_T_PREFIX}unknownUser`)} />
	);

	const redirectByEntity = redirectUserId
		? approvalEntities?.find(entity => entity.approvers.some(approver => approver.userIds.includes(redirectUserId)))
		: undefined;

	return { redirectTarget, redirectByEntity };
}

function getForwardFromData(log: TicketAuditLogModel, users: Map<string, UserModel>, t: TFunction) {
	const forwardedFromUserId = log.data?.get("from") as string | undefined;
	const forwardedToUserId = log.data?.get("to") as string | undefined;

	const forwardedFromUser = forwardedFromUserId ? users.get(forwardedFromUserId) : null;
	const forwardedToUser = forwardedToUserId ? users.get(forwardedToUserId) : null;

	const forwardedFrom = forwardedFromUser ? (
		<TextUser user={forwardedFromUser} />
	) : (
		<TC content={t(`${ACTIVITY_ITEM_T_PREFIX}unknownUser`)} />
	);
	const forwardedTo = forwardedToUser ? (
		<TextUser user={forwardedToUser} />
	) : (
		<TC content={t(`${ACTIVITY_ITEM_T_PREFIX}unknownUser`)} />
	);

	return { forwardedFrom, forwardedTo };
}

function getUserFromData(
	log: TicketAuditLogModel,
	users: Map<string, UserModel>,
	translationPath: TTranslationTypes,
	redirectByEntity?: TicketApprovalEntityModel,
	approvalEntities?: List<TicketApprovalEntityModel>
) {
	const userAction =
		translationPath.startsWith("AccessRequestApproverApproved") ||
		translationPath.startsWith("AccessRequestApproverDeclined");
	const logUser = log.userId ? users?.get(log.userId) : null;

	let user = logUser ? <TextUser user={logUser} /> : <TC />;

	const approverEntity =
		log.userId && userAction
			? approvalEntities?.find(entity => entity.approvers.some(approver => approver.userIds.includes(log.userId)))
			: null;

	if (log.userId) {
		if (redirectByEntity && redirectByEntity.type !== "User")
			user = logUser ? <TextUser user={logUser} entity={redirectByEntity} /> : <TC />;
		else if (approverEntity && approverEntity.type !== "User")
			user = logUser ? <TextUser user={logUser} entity={approverEntity} /> : <TC />;
	}
	return user;
}

type TTranslationTypes =
	| Exclude<
			TTicketAuditLogAction,
			| "AccessRequestPermissionExpiredRevokedSuccess"
			| "AccessRequestPermissionGrantedSuccess"
			| "AccessRequestApproverApproved"
			| "AccessRequestApproverDeclined"
			| "AccessRequestTaskCreated"
	  >
	| "AccessRequestPermissionExpiredRevokedSuccess.manual"
	| "AccessRequestPermissionExpiredRevokedSuccess.normal"
	| "AccessRequestPermissionGrantedSuccess.manual"
	| "AccessRequestPermissionGrantedSuccess.normal"
	| "AccessRequestApproverApproved.normal"
	| "AccessRequestApproverDeclined.normal"
	| "AccessRequestApproverApproved.admin"
	| "AccessRequestApproverDeclined.admin"
	| "AccessRequestApproverApproved.webhook"
	| "AccessRequestApproverDeclined.webhook"
	| "AccessRequestTaskCreated.revert"
	| "AccessRequestTaskCreated.normal";

function getTranslationPath(log: TicketAuditLogModel, manualEvent: boolean): TTranslationTypes {
	const action = log.action;
	const adminResponse = Boolean(log.data?.get("admin"));

	if (action === "AccessRequestApproverApproved") {
		if (adminResponse) return "AccessRequestApproverApproved.admin";
		if (!log.userId && log.data?.has("webhookName")) return "AccessRequestApproverApproved.webhook";
		return "AccessRequestApproverApproved.normal";
	}

	if (action === "AccessRequestApproverDeclined") {
		if (adminResponse) return "AccessRequestApproverDeclined.admin";
		if (!log.userId && log.data?.has("webhookName")) return "AccessRequestApproverDeclined.webhook";
		return "AccessRequestApproverDeclined.normal";
	}

	if (action === "AccessRequestPermissionGrantedSuccess") {
		if (manualEvent) return "AccessRequestPermissionGrantedSuccess.manual";
		return "AccessRequestPermissionGrantedSuccess.normal";
	}
	if (action === "AccessRequestPermissionExpiredRevokedSuccess") {
		if (manualEvent) return "AccessRequestPermissionExpiredRevokedSuccess.manual";
		return "AccessRequestPermissionExpiredRevokedSuccess.normal";
	}

	if (action === "AccessRequestTaskCreated") {
		if (log.data?.get("taskType") === "revert") return "AccessRequestTaskCreated.revert";
		return "AccessRequestTaskCreated.normal";
	}

	return action;
}

function getExpiredRoleData(log: TicketAuditLogModel, t: TFunction) {
	const expiredRoleName = log.data?.get("expiredRoleName") || t(`${ACTIVITY_ITEM_T_PREFIX}unknownRole`);

	return { expiredRoleName };
}

function getReasonNotRevoked(log: TicketAuditLogModel, t: TFunction) {
	const reason = log.data?.get("reason");
	if (reason) {
		return t(`common.wontRevokeReason.${reason as TReasonWontRevoked}`);
	}
	return t(`common.wontRevokeReason.permissionDoesNotExist`);
}

function getTaskFromData(log: TicketAuditLogModel, users: Map<string, UserModel>, t: TFunction) {
	// get the task type
	const taskType = log.data?.get("taskType") || t(`${ACTIVITY_ITEM_T_PREFIX}unknownTaskType`);

	// get the assigned user
	const assignedUserId = log.data?.get("assignedUserId") as string | undefined;
	const assignedUser = assignedUserId ? users?.get(assignedUserId) : null;
	const taskAssignedUser = assignedUser ? (
		<TextUser user={assignedUser} />
	) : (
		<TC content={t(`${ACTIVITY_ITEM_T_PREFIX}unknownUser`)} />
	);

	return { taskType, taskAssignedUser };
}

export const AuditLogContent = ({
	log,
	ticket,
	users
}: {
	log: TicketAuditLogModel;
	ticket: TFullTicket;
	users: Map<string, UserModel>;
}): JSX.Element => {
	const classes = useStyles();
	const { t } = useTranslation();
	const integrations = useIntegrations(true);
	const props = getTransProps(log, ticket, users, t, integrations ?? undefined, classes.auditLogEntity);

	return <Trans t={t} {...props} />;
};

const ThirdPartyTicketChip: FC<{ ticketingIntegrationTicket: TicketingIntegrationTicketModel }> = ({
	ticketingIntegrationTicket
}) => {
	const classes = useStyles();

	return (
		<TicketingIntegrationTicketChip
			className={classes.ticketingIntegrationTicketChip}
			ticketingIntegrationTicket={ticketingIntegrationTicket}
		/>
	);
};

const getTransProps = (
	log: TicketAuditLogModel,
	ticket: TFullTicket,
	users: Map<string, UserModel>,
	t: TFunction,
	integrations?: Map<string, IntegrationModel>,
	entityClassName?: string
): TransProps<ParseKeys> => {
	const receiverUser = users.get(ticket.receiverId);
	const receiver = receiverUser ? (
		<TextUser
			user={receiverUser}
			possessiveForm={!!log.action && TICKET_AUDIT_LOGS_WITH_POSSESSIVE_FORM.includes(log.action)}
		/>
	) : (
		<TC />
	);

	const emptyGroupName = log.data?.get("groupName") as string;

	const emptyGroup = emptyGroupName ? (
		<DirectoryGroupTooltip className={entityClassName} groupName={emptyGroupName} />
	) : (
		<TC content={t(`${ACTIVITY_ITEM_T_PREFIX}unknownGroup`)} />
	);

	const { isManual, manualUser } = getManualActionFromData(log, users);

	const manualEvent = isManual && !!manualUser;
	const translationPath = getTranslationPath(log, manualEvent);

	const approvalEntities = ticket.approvalRequests.flatMap(
		approvalRequest => approvalRequest.approvalEntities || List<TicketApprovalEntityModel>()
	);

	const { redirectTarget, redirectByEntity } = getRedirectFromData(log, users, t, approvalEntities);

	const { taskType, taskAssignedUser } = getTaskFromData(log, users, t);

	const user = getUserFromData(log, users, translationPath, redirectByEntity, approvalEntities);

	const status = log.data?.get("status") as string | undefined;
	let statusWithIcon = <TC />;
	if (status) {
		statusWithIcon = <TicketStatusWithIcon status={status} />;
	}

	const error = log.data?.get("errorMessage") as string | undefined;
	let viewError = <TC />;
	if (error) {
		viewError = <ViewErrorButton error={error} />;
	}

	const reasonWontRevoke = getReasonNotRevoked(log, t);

	const { integrationName, resourceName, roleName } = getTicketAuditLogRoleData(log, t);

	const { expiredRoleName } = getExpiredRoleData(log, t);

	const { forwardedFrom, forwardedTo } = getForwardFromData(log, users, t);

	const thirdPartyTicket = ticket.ticketingIntegrationTicket ? (
		<ThirdPartyTicketChip ticketingIntegrationTicket={ticket.ticketingIntegrationTicket} />
	) : (
		<TC />
	);

	// Empty components in Trans when needed will throw unidentified error,
	// by omitting nulls and undefined it will instead just show the component name ('<user />')
	const components = omitBy(
		{
			receiver,
			user,
			redirectTarget,
			manualUser,
			emptyGroup,
			statusWithIcon,
			taskAssignedUser,
			forwardedFrom,
			forwardedTo,
			viewError,
			thirdPartyTicket,
			bold: <b />
		},
		isNil
	) as Record<string, JSX.Element>;
	const values = {
		integrationName,
		resourceName,
		roleName,
		expiredRoleName,
		reasonWontRevoke,
		taskType
	};

	if (!translationPath) return { i18nKey: "shared.emptyString" };
	if (translationPath === "AccessRequestCreated")
		return integrations ? getCreatedParts(ticket, log, t, users, integrations) : { i18nKey: "shared.loading" };

	return { i18nKey: `${ACTIVITY_ITEM_T_PREFIX}auditLog.${translationPath}` as const, components, values };
};

function getCreatedParts(
	ticket: TFullTicket,
	auditLog: TicketAuditLogModel,
	t: TFunction,
	users: Map<string, UserModel>,
	integrations: Map<string, IntegrationModel>
): TransProps<ParseKeys> {
	const ticketIntegrationResourceRole = ticket.ticketPermissions.first()?.role;
	let translationValues;
	if (ticket.targetType === "bundle") {
		translationValues = { bundleName: ticket.targetDisplayName };
	} else {
		const { integrationName, resourceName, roleName } = getTicketAuditLogRoleData(auditLog, t);
		translationValues = {
			resourceName: ticketIntegrationResourceRole?.integrationResource.displayName || resourceName,
			roleName: ticket.ticketPermissions.first()?.role.name || roleName,
			integrationName:
				integrations.get(ticketIntegrationResourceRole?.integrationResource.integrationId || "")?.name ||
				integrationName
		};
	}

	const differentUser = ticket.receiverId !== ticket.creatorId;
	const isBundle = ticket.targetType === "bundle";
	const createdPrefix = `${ACTIVITY_ITEM_T_PREFIX}auditLog.AccessRequestCreated` as const;
	let key: ParseKeys;
	if (differentUser && isBundle) {
		key = `${createdPrefix}.bundleDifferentUser` as const;
	} else if (differentUser) {
		key = `${createdPrefix}.resourceDifferentUser` as const;
	} else if (isBundle) {
		key = `${createdPrefix}.bundleSameUser` as const;
	} else {
		key = `${createdPrefix}.resourceSameUser` as const;
	}

	return {
		i18nKey: key,
		values: translationValues,
		components: {
			bold: <b />,
			creator: users?.has(ticket.creatorId) ? <TextUser user={users.get(ticket.creatorId)!} /> : <TC />,
			receiver: users?.has(ticket.receiverId) ? <TextUser user={users.get(ticket.receiverId)!} /> : <TC />
		}
	};
}

export const RECEIVER_ACTIONS: TTicketAuditLogAction[] = [
	"AccessRequestApproved",
	"AccessRequestAutomaticApproved",
	"AccessRequestForwarded",
	"AccessRequestPassedApprovalFlowStep",
	"AccessRequestPermissionAlreadyExist",
	"AccessRequestPermissionExpiredRevokedFailure",
	"AccessRequestPermissionExpiredRevokedSuccess",
	"AccessRequestPermissionGrantedFailure",
	"AccessRequestPermissionGrantedSuccess",
	"AccessRequestPermissionRetryGrant",
	"AccessRequestPermissionRetryRevoke",
	"AccessRequestPermissionRetryRevoke",
	"AccessRequestPermissionReverted",
	"AccessRequestPermissionTryGrant",
	"AccessRequestPermissionTryRevert",
	"AccessRequestPermissionTryRevoke",
	"AccessRequestPermissionWontRevoke",
	"AccessRequestRedirectedToAdminDisabledWebhook",
	"AccessRequestRedirectedToAdminEmptyGroup",
	"AccessRequestRedirectedToAdminNoManager",
	"AccessRequestRedirectedToAdminNoTeamMembers",
	"AccessRequestStatusChanged",
	"AccessRequestTaskCreated"
];
