import classNames from "classnames";
import { Tooltip } from "components/ui/Tooltip";
import { useDirectoryGroups } from "hooks/useDirectoryGroups";
import React from "react";
import { TextWithDirectoryIcon } from "../TextWithDirectoryIcon";
import { useStyles } from "./styles";

interface IProps {
	groupName: string;
	groupEmail?: string;
	withSeparator?: string;
	isDeleted?: boolean;
	// group id might not be provided if the name came from audit log
	groupId?: string;
}

export const DirectoryGroupTooltip: FC<IProps> = ({
	groupEmail,
	groupName,
	withSeparator,
	isDeleted,
	groupId,
	className
}) => {
	const classes = useStyles();
	const directoryGroups = useDirectoryGroups();

	let emailValue = groupEmail;
	if (!emailValue) {
		emailValue = directoryGroups?.find(dg => (groupId ? dg.id === groupId : dg.name === groupName))?.email || undefined;
	}
	const directoryGroup = (
		<TextWithDirectoryIcon
			key={groupName}
			value={groupName || ""}
			className={classNames(classes.directoryGroup, className)}
			separator={withSeparator ? "," : undefined}
			iconClassName={classes.directoryGroupIcon}
			isDeleted={isDeleted}
		/>
	);
	return <Tooltip content={emailValue}>{directoryGroup}</Tooltip>;
};
