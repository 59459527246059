import { Map } from "immutable";
import { useEffect, useMemo } from "react";
import { useApprovalAlgorithmsContext } from "context/approvalAlgorithmsContext";
import { ApprovalAlgorithmModel } from "models/ApprovalAlgorithmModel";

export const useApprovalAlgorithms = (withDeleted = false) => {
	const {
		state: { approvalAlgorithms },
		actions: { loadApprovalAlgorithms }
	} = useApprovalAlgorithmsContext();

	useEffect(() => {
		loadApprovalAlgorithms();
	}, [loadApprovalAlgorithms]);

	const filteredApprovalAlgorithms: Map<string, ApprovalAlgorithmModel> | null = useMemo(() => {
		return withDeleted || !approvalAlgorithms
			? approvalAlgorithms
			: approvalAlgorithms.filter(approvalAlgorithm => !approvalAlgorithm.isDeleted);
	}, [approvalAlgorithms, withDeleted]);

	return filteredApprovalAlgorithms;
};
