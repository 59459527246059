import { useUsersList } from "hooks/useUsers";
import { List } from "immutable";
import { UserModel } from "models/UserModel";
import { useState, useMemo, useCallback } from "react";

export const useUsersTable = () => {
	const [searchTerm, setSearchTerm] = useState("");
	const users = useUsersList();
	const loading = useMemo(() => !users, [users]);

	const filteredUsers = useMemo(
		() =>
			users
				?.filter(({ isDeleted }) => !isDeleted)
				.filter(
					user =>
						user.fullName.toLowerCase().includes(searchTerm.toLowerCase()) ||
						user.email.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase())
				) || List<UserModel>(),
		[users, searchTerm]
	);

	const onSearchUsers = useCallback((value: string) => {
		setSearchTerm(value);
	}, []);

	const displayedUsers = useMemo(() => filteredUsers.toArray(), [filteredUsers]);

	return {
		onSearchUsers,
		searchTerm,
		displayedUsers,
		loading
	};
};
