import React, { useCallback, useMemo } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { BundleModel } from "models/BundleModel";
import { useBundles } from "hooks/useBundles";
import { LoadingDots } from "components/ui/LoadingDots";
import { IconButton } from "components/ui/IconButton";
import { DeleteIcon } from "components/ui/Icons/DeleteIcon";
import { BundleSelect } from "./components/BundleSelect";
import { useStyles } from "./styles";

export const BundlesList: FC<{
	bundles: string[];
	newInputOpen: boolean;
	closeNewInput: () => void;
	editBundle: (bundleId: string, index: number) => void;
	removeBundle: (id: string) => void;
}> = ({ bundles, className, removeBundle, editBundle, newInputOpen, closeNewInput }) => {
	const classes = useStyles();

	const allBundles = useBundles(true);
	const bundlesArray = useMemo(() => (allBundles ? Array.from(allBundles.values()) : null), [allBundles]);

	const removeBundleHandler = useCallback(
		(bundleId?: string) => {
			if (bundleId) {
				removeBundle(bundleId);
			}
		},
		[removeBundle]
	);
	const addNewBundleHandler = useCallback(
		(bundleId: string, bundleIndex: number) => {
			editBundle(bundleId, bundleIndex);
		},
		[editBundle]
	);

	const shouldDisableOption = useCallback(
		(bundle: BundleModel) => bundles?.some(existingBundleId => existingBundleId === bundle.id) || bundle.isDeleted,
		[bundles]
	);

	const filterBundle = useCallback((bundle: BundleModel) => {
		const bundleHasNoVirtualizedRoles =
			bundle.bundleItems?.every(item => !item.integrationResourceRole.virtualizedRoleId) ?? false;
		return bundleHasNoVirtualizedRoles && !bundle.isDeleted;
	}, []);

	return (
		<div className={classNames(className, classes.bundlesListContainer)}>
			{bundlesArray && bundles ? (
				bundles.map((bundleId, index) => (
					<BundleListItem
						key={`${bundleId}-${index}`}
						bundlesArray={bundlesArray}
						bundleId={bundleId}
						index={index}
						editBundle={editBundle}
						removeBundle={removeBundleHandler}
						shouldDisable={shouldDisableOption}
						filter={filterBundle}
					/>
				))
			) : (
				<LoadingDots center />
			)}
			{bundlesArray && newInputOpen ? (
				<BundleListItem
					bundlesArray={bundlesArray}
					editBundle={addNewBundleHandler}
					removeBundle={closeNewInput}
					index={bundles.length}
					shouldDisable={shouldDisableOption}
					filter={filterBundle}
				/>
			) : null}
		</div>
	);
};

const BundleListItem: FC<{
	bundlesArray: BundleModel[];
	bundleId?: string;
	index: number;
	editBundle: (bundleId: string, index: number) => void;
	removeBundle: (id?: string) => void;
	shouldDisable?: (bundle: BundleModel) => boolean;
	filter?: (bundle: BundleModel) => boolean;
}> = ({ index, bundleId, bundlesArray, editBundle, removeBundle, shouldDisable, filter }) => {
	const classes = useStyles();
	const { t } = useTranslation();

	const editBundleHandler = useCallback(
		(newBundle: BundleModel | null) => (newBundle ? editBundle(newBundle.id, index) : undefined),
		[editBundle, index]
	);

	const removeBundleHandler = useCallback(() => removeBundle(bundleId), [removeBundle, bundleId]);
	const value = useMemo(() => bundlesArray.find(({ id }) => id === bundleId) ?? null, [bundleId, bundlesArray]);

	const shouldDisableOption = useCallback(
		(bundle: BundleModel) => {
			if (bundle.id === value?.id) return false;
			return shouldDisable ? shouldDisable(bundle) : false;
		},
		[shouldDisable, value]
	);

	return (
		<div className={classes.bundleRow}>
			<BundleSelect
				className={classes.bundleSelectInput}
				bundles={bundlesArray}
				label={t("pages.policies.form.bundle")}
				onChange={editBundleHandler}
				value={value}
				shouldDisableOption={shouldDisableOption}
				filter={filter}
				disabled={value?.isDeleted}
			/>
			<IconButton size="large" onClick={removeBundleHandler}>
				<DeleteIcon />
			</IconButton>
		</div>
	);
};
