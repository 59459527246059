import React, { useMemo } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { Typography } from "components/ui/New/Typography";
import { IconButton } from "components/ui/IconButton";
import { TicketingIntegrationSelect } from "components/common/TicketingIntegrationSelect";
import { InfoIcon } from "components/ui/Icons/InfoIcon";
import { useCompany } from "hooks/useCompany";
import { TextAreaInput } from "components/ui/TextAreaInput";
import { useNewRequestFormContext } from "../../newRequestFormContext";
import { useStyles } from "./styles";

export const RequestReasonInputs: FC = ({ className, innerRef }) => {
	const { t } = useTranslation("translation", { keyPrefix: "pages.newRequest.requestReasonInputs" });
	const classes = useStyles();

	const {
		state: { ticketingIntegrationTicketId, justification },
		actions: { changeTicketingIntegrationTicketId, changeJustification }
	} = useNewRequestFormContext();

	const company = useCompany();

	const enableThirdPartyTickets = useMemo(
		() => (company?.integratedToTicketingIntegrations && company.integratedToTicketingIntegrations.size > 0) || false,
		[company]
	);

	return (
		<div className={classNames(classes.container, className)} ref={innerRef}>
			<div className={classes.fieldContainer}>
				<Typography variant="body_sb">{t("justificationInput.label")}</Typography>
				<TextAreaInput
					fullWidth
					onValueChange={changeJustification}
					placeholder={t("justificationInput.placeholder")}
					value={justification}
				/>
			</div>
			{enableThirdPartyTickets ? (
				<div className={classes.fieldContainer}>
					<div className={classes.thirdPartyTicketLabel}>
						<Typography variant="body_sb">{t("thirdPartyTicket.label")}</Typography>
						<IconButton
							size="medium"
							tooltip={<Typography variant="text_reg">{t("thirdPartyTicket.infoTooltip")}</Typography>}
							tooltipClassName={classes.tooltip}>
							<InfoIcon />
						</IconButton>
					</div>
					<TicketingIntegrationSelect
						placeholder={t("thirdPartyTicket.placeholder")}
						value={ticketingIntegrationTicketId}
						onChange={changeTicketingIntegrationTicketId}
					/>
				</div>
			) : null}
		</div>
	);
};
