import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	container: {
		display: "flex",
		flexDirection: "column",
		gap: "12px",
		"&:last-child $divider": {
			display: "none"
		},
		"&$full": {
			height: "100%",
			minWidth: "100%"
		}
	},
	header: {
		display: "flex",
		flexDirection: "column"
	},
	title: {
		alignItems: "flex-end",
		display: "flex",
		gap: "40px",
		justifyContent: "space-between",
		minWidth: "100%"
	},
	titleLeft: {
		alignItems: "center",
		display: "flex",
		flexWrap: "nowrap",
		gap: "8px"
	},
	titleRight: {
		alignItems: "center",
		display: "flex",
		flexGrow: 1,
		gap: "8px",
		justifyContent: "flex-end"
	},
	content: {
		height: "auto",
		maxHeight: "0px",
		transition: "max-height 1s ease-in-out",
		willChange: "max-height",
		overflow: "hidden",
		"&$expanded": {
			maxHeight: "100%"
		},
		"&$full": {
			height: "100%",
			minWidth: "100%",
			width: "100%"
		}
	},
	expanded: {},
	divider: {
		display: "flex",
		paddingTop: "28px"
	},
	full: {}
});
