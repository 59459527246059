import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { UserOutlineIcon } from "components/ui/Icons/UserOutlineIcon";
import { useWorkflowEditorContext } from "context/workflowEditorContext";
import { GroupsSelectInput } from "components/common/GroupsSelectInput";
import { DurationSelectInput } from "components/pages/WorkflowsPage/components/EditWorkflow/components/DurationSelectInput";
import { TApprovalAlgorithmRuleDuration } from "utils/durationsOptions";
import { PendingIcon } from "components/ui/Icons/PendingIcon";
import { Typography } from "components/ui/Typography";
import { OnCallIntegrationSchedulesSelectInput } from "components/pages/WorkflowsPage/components/EditWorkflow/components/OnCallIntegrationSchedulesSelectInput";
import { useCompany } from "hooks/useCompany";
import { CalendarIcon } from "components/ui/Icons/CalendarIcon";
import { FlowBlock } from "../../FlowBlock";
import { useStyles } from "./styles";

interface IProps {
	index: number;
}

export const AccessRequestEditable: FC<IProps> = ({ index, className }) => {
	const { t } = useTranslation();
	const classes = useStyles();
	const {
		actions: { updateRule, getRuleFlowOrShowError }
	} = useWorkflowEditorContext();
	const company = useCompany();
	const rule = getRuleFlowOrShowError(index)?.[0];
	const directoryGroupIds: string[] = useMemo(() => rule?.directoryGroupIds || [], [rule]);
	const onCallIntegrationScheduleIds: string[] = useMemo(() => rule?.onCallIntegrationScheduleIds || [], [rule]);
	const duration = rule?.duration || null;

	const onGroupChange = useCallback(
		(newDirectoryGroupIds: string[] | null) => {
			if (newDirectoryGroupIds) {
				return updateRule(index, { directoryGroupIds: newDirectoryGroupIds });
			}
			if (!newDirectoryGroupIds) {
				return updateRule(index, []);
			}
		},
		[index, updateRule]
	);

	const onScheduleChange = useCallback(
		(newScheduleIds: string[] | null) => {
			if (newScheduleIds) {
				return updateRule(index, {
					onCallIntegrationScheduleIds: newScheduleIds,
					anyOnCallIntegrationSchedules: false
				});
			}
			if (!newScheduleIds) {
				return updateRule(index, []);
			}
		},
		[index, updateRule]
	);

	const onAnySchedulesChange = useCallback(() => {
		return updateRule(index, {
			onCallIntegrationScheduleIds: [],
			anyOnCallIntegrationSchedules: true
		});
	}, [index, updateRule]);

	const onDurationChange = useCallback(
		(newDuration: TApprovalAlgorithmRuleDuration) => {
			updateRule(index, { duration: newDuration });
		},
		[index, updateRule]
	);

	if (!rule) {
		return null;
	}

	return (
		<FlowBlock label={t("flow.accessRequest")} className={className}>
			<div className={classes.accessRequestContainer}>
				<div className={classes.fieldTitle}>
					<UserOutlineIcon />
					{t("flow.inGroup")}
				</div>
				<GroupsSelectInput className={classes.input} onChange={onGroupChange} values={directoryGroupIds} />
			</div>
			<div className={classes.accessRequestContainer}>
				<div className={classes.fieldTitle}>
					<PendingIcon />
					{t("flow.underDuration")}
				</div>
				<DurationSelectInput className={classes.input} value={duration} onChange={onDurationChange} />
			</div>
			{company?.integratedToOnCalls?.size ? (
				<div className={classes.accessRequestContainer}>
					<div className={classes.fieldTitle}>
						<CalendarIcon />
						<Typography relative>{t("flow.onCallIntegrationSchedule")}</Typography>
					</div>
					<OnCallIntegrationSchedulesSelectInput
						className={classes.input}
						isAnySchedules={rule?.anyOnCallIntegrationSchedules}
						onChangeScheduleIds={onScheduleChange}
						onChangeToAnySchedules={onAnySchedulesChange}
						values={onCallIntegrationScheduleIds}
					/>
				</div>
			) : null}
		</FlowBlock>
	);
};
