import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	divider: {
		minHeight: 0,
		minWidth: 0,
		height: (props: { strokeWidth: number }) => props.strokeWidth + "px",
		width: (props: { strokeWidth: number }) => props.strokeWidth + "px"
	},
	horizontal: {
		"&$full": { width: "100%" },
		width: "auto"
	},
	vertical: {
		"&$full": { height: "100%" },
		height: "auto"
	},
	full: {}
});
