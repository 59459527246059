export const compiledClientConfig: {
	allowShortPermissionDurations: boolean;
	appEnv: string;
	googleLoginClientId: string;
	integrationOAuthUrl: string;
	isProd: boolean;
	logrocketClientId: string;
	microsoftLoginClientId: string;
	slackClientId: string;
	teamsClientId: string;
	teamsRedirectUrl: string;
	upToDateCheckInterval: number;
	// @ts-ignore
} = window.clientEnv;
