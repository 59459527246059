import React, { useCallback } from "react";
import { MultipleSelect } from "components/ui/MultipleSelect";
import { useTranslation } from "react-i18next";
import { getIntegrationResourceTypes } from "api/accessRequestForm";
import { useSelectSearchProps } from "hooks/useSelectSearchProps";

const LIMIT = 100;

interface IProps {
	integrationIds: string[];
	setResourceTypes: (value: string[] | null) => void;
	userId: string;
	resourceTypes: string[] | null;
}

export const ResourceTypesInput: FC<IProps> = ({ integrationIds, userId, setResourceTypes, resourceTypes }) => {
	const { t } = useTranslation(undefined, { keyPrefix: "pages.newTicket.newTicketForm.resourceTypesInput" });

	const fetchResourceTypes = useCallback(
		async (query: string) => {
			if (!integrationIds.length) return [];
			const response = await getIntegrationResourceTypes({ integrationIds, userId, search: query, perPage: LIMIT });
			return response.result.toArray();
		},
		[integrationIds, userId]
	);
	const { selectProps } = useSelectSearchProps(fetchResourceTypes, resourceTypes, true);

	return (
		<MultipleSelect
			{...selectProps}
			disabled={!integrationIds.length}
			fullWidth
			onChange={setResourceTypes}
			label={t("label")}
			placeholder={t("placeholder")}
			value={resourceTypes}
			filter={null}
			sort={null}
			limit={LIMIT}
		/>
	);
};
