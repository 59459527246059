import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { OptionsListActionPanelProps, Select } from "components/ui/Select";
import { Input } from "components/ui/Input";
import { TextAreaInput } from "components/ui/TextAreaInput";
import { Button } from "components/ui/Button";
import { DocumentIcon } from "components/ui/Icons/DocumentIcon";
import { AddIcon } from "components/ui/Icons/AddIcon";
import { useAccessReviewTemplatesContext } from "context/accessReviewTemplatesContext";
import { useOpenGlobalErrorModal } from "hooks/useGlobalError";
import { DESCRIPTION_MAX_LENGTH } from "utils/accessReview";
import { ImmediateRevokeCheckbox } from "components/common/ImmediateRevokeCheckbox";
import { useCompany } from "hooks/useCompany";
import useIsOpenState from "hooks/useIsOpenState";
import { TitleBody } from "components/ui/TitleBody";
import { Tooltip } from "components/ui/Tooltip";
import { IconButton } from "components/ui/IconButton";
import { CloseIcon } from "components/ui/Icons/CloseIcon";
import { useStyles } from "./styles";

const getKey = ({ id }: { id: string }) => {
	return id;
};

const getLabel = ({ name }: { name: string }) => {
	return name;
};

type TAccessReviewPopupProps = {
	onCreateAccessReview: (
		name: string,
		description: string,
		immediateRevoke: boolean,
		templateId?: string
	) => Promise<unknown>;
};

const PopupContent: FC<TAccessReviewPopupProps & { close: () => void }> = ({ close, onCreateAccessReview }) => {
	const classes = useStyles();
	const { t } = useTranslation();
	const company = useCompany();
	const navigate = useNavigate();
	const {
		actions: { loadAccessReviewTemplates },
		state: { accessReviewTemplates }
	} = useAccessReviewTemplatesContext();

	const openGlobalErrorModal = useOpenGlobalErrorModal();

	const disableImmediateRevokeOverride = !!(company && company.forceAccessReviewsImmediateRevoke);

	const [templatesLoading, setTemplatesLoading] = useState(false);
	const [selectedTemplate, setSelectedTemplate] = useState<{
		id: string;
		name: string;
		description: string;
		immediateRevoke: boolean;
	} | null>(null);
	const [name, setName] = useState("");
	const [description, setDescription] = useState("");
	const [immediateRevoke, setImmediateRevoke] = useState(
		disableImmediateRevokeOverride ? true : selectedTemplate?.immediateRevoke ?? false
	);

	useEffect(() => {
		if (selectedTemplate) {
			setName(selectedTemplate.name);
			setDescription(selectedTemplate.description);
			setImmediateRevoke(disableImmediateRevokeOverride ? true : selectedTemplate.immediateRevoke);
		}
	}, [disableImmediateRevokeOverride, selectedTemplate]);

	useEffect(() => {
		if (!accessReviewTemplates) {
			setTemplatesLoading(true);
			loadAccessReviewTemplates().finally(() => setTemplatesLoading(false));
		}
	}, [accessReviewTemplates, loadAccessReviewTemplates]);

	const validateName = useCallback(
		(newName: string) => {
			if (!newName || newName.length === 0) {
				return t("common.textValidation.HelpNotEmptyField", { field: "name" });
			}
			if (newName.length > 100) {
				return t("common.textValidation.HelpMaximumValueText", { field: "name", maxValue: 100 });
			}
			return null;
		},
		[t]
	);

	const handleCreateAccessReview = useCallback(async () => {
		if (validateName(name)) return;
		try {
			setSelectedTemplate(null);
			setName("");
			setDescription("");
			setImmediateRevoke(true);
			await onCreateAccessReview(name, description, immediateRevoke, selectedTemplate?.id);
			close();
		} catch (err) {
			close();
			openGlobalErrorModal(err as Error);
		}
	}, [
		close,
		onCreateAccessReview,
		description,
		immediateRevoke,
		name,
		openGlobalErrorModal,
		selectedTemplate,
		validateName
	]);

	const goToTemplates = useCallback(() => {
		navigate("templates/create");
		close();
	}, [close, navigate]);

	const templateActionsPanel = useMemo<OptionsListActionPanelProps>(() => {
		const content = (
			<Button variant="text" size="medium" prefix={<AddIcon />} onClick={goToTemplates}>
				{t("common.select.createNew")}
			</Button>
		);
		return { content, position: "bottom" };
	}, [goToTemplates, t]);

	const templateOptions = useMemo(
		() =>
			accessReviewTemplates
				?.map(({ id, name, description, immediateRevoke }) => ({ id, name, description, immediateRevoke }))
				.toArray() || [],
		[accessReviewTemplates]
	);

	return (
		<div className={classes.content}>
			<div className={classes.actions}>
				<IconButton size="large" onClick={close}>
					<CloseIcon />
				</IconButton>
			</div>
			<TitleBody title={t("pages.accessReview.new")} />

			<Select
				fullWidth
				label={t("pages.accessReview.selectTemplate")}
				loading={templatesLoading}
				onChange={setSelectedTemplate}
				options={templateOptions}
				value={selectedTemplate}
				placeholder={t("pages.accessReview.noTemplate")}
				getOptionKey={getKey}
				getOptionLabel={getLabel}
				optionsListActionPanel={templateActionsPanel}
			/>
			<Input
				fullWidth
				isRequired
				label={t("shared.Name")}
				onValueChange={setName}
				validators={[validateName]}
				value={name}
			/>
			<TextAreaInput
				label={t("common.accessReview.description")}
				maxLength={DESCRIPTION_MAX_LENGTH}
				onValueChange={setDescription}
				value={description}
			/>
			<ImmediateRevokeCheckbox
				value={immediateRevoke}
				onChange={setImmediateRevoke}
				disabled={disableImmediateRevokeOverride}
			/>
			<div className={classes.actions}>
				<Button size="medium" prefix={<DocumentIcon />} disabled={!name} onClick={handleCreateAccessReview}>
					{t("pages.accessReview.createAccessReview")}
				</Button>
			</div>
		</div>
	);
};

export const NewAccessReviewPopup: FC<TAccessReviewPopupProps> = ({ onCreateAccessReview }) => {
	const { t } = useTranslation();
	const { close, isOpen, open } = useIsOpenState();

	return (
		<Tooltip
			visible={isOpen}
			content={<PopupContent onCreateAccessReview={onCreateAccessReview} close={close} />}
			placement="bottom-start"
			clean>
			<Button size="medium" onClick={isOpen ? close : open}>
				{t("pages.accessReview.new")}
			</Button>
		</Tooltip>
	);
};
