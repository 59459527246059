import React, { useCallback, useRef, useState } from "react";
import { EntitleTextLogo } from "components/ui/systemLogos/EntitleTextLogo";
import { UserCircleIcon } from "components/ui/Icons/UserCircleIcon";
import { useUser } from "hooks/useUser";
import { useOnClickOutside } from "hooks/useOnClickOutside";
import { SAME_WIDTH_MODIFIER, useTooltip } from "hooks/useTooltip";
import { SignOutIcon } from "components/ui/Icons/SignOutIcon";
import { useAuthContext } from "context/authContext";
import { routes } from "routes/routes";
import { useNavigate, Link } from "react-router-dom";
import { Avatar } from "components/common/Avatar";
import { useTranslation } from "react-i18next";
import { useIsBeta } from "context/betaContext";
import { NavbarButton } from "./components/NavbarButton";
import { useStyles } from "./styles";
import { SelectionOption } from "./components/SelectionOption";

export const TopNav: FC = () => {
	const classes = useStyles();
	const { t } = useTranslation();
	const isBeta = useIsBeta();

	const { user } = useUser();

	const userActionsRef = useRef<HTMLDivElement>(null);

	const [isUserActionsModalOpen, setIsUserActionsModalOpen] = useState(false);
	const { visible, setTooltipRef, getTooltipProps, setTriggerRef } = useTooltip({
		visible: isUserActionsModalOpen,
		offset: [0, 6],
		placement: "bottom-start",
		popperOptions: {
			modifiers: [SAME_WIDTH_MODIFIER]
		}
	});

	const { signOut } = useAuthContext();
	const navigate = useNavigate();

	const closeModal = useCallback(() => setIsUserActionsModalOpen(false), []);
	useOnClickOutside(userActionsRef || undefined, closeModal);

	const handleClick = useCallback(() => {
		setIsUserActionsModalOpen(state => !state);
	}, []);

	const navigateToProfile = useCallback(() => {
		closeModal();
		navigate(routes.profile.main);
	}, [closeModal, navigate]);

	const handleSignOut = useCallback(() => {
		closeModal();
		signOut();
	}, [closeModal, signOut]);

	const userButtonPrefix = user?.imageUrl ? (
		<Avatar user={user} className={classes.userIcon} />
	) : (
		<UserCircleIcon className={classes.userIcon} />
	);

	return (
		<div className={classes.container}>
			<Link to={isBeta ? routes.requests.main : routes.myRequests.main}>
				<EntitleTextLogo className={classes.entitleLogo} />
			</Link>
			<div className={classes.userContainer} ref={userActionsRef}>
				<NavbarButton innerRef={setTriggerRef} onClick={handleClick} prefix={userButtonPrefix} active={visible}>
					{user!.fullName}
				</NavbarButton>
				{visible && (
					<div ref={setTooltipRef} {...getTooltipProps()} className={classes.userActionsModal}>
						<SelectionOption onClick={navigateToProfile} prefix={<UserCircleIcon />}>
							{t("navigation.profile")}
						</SelectionOption>
						<SelectionOption onClick={handleSignOut} prefix={<SignOutIcon />}>
							{t("navigation.signOut")}
						</SelectionOption>
					</div>
				)}
			</div>
		</div>
	);
};
