import React, { useCallback } from "react";
import { TicketingIntegrationTicketModel } from "models/TicketingIntegrationTicketModel";
import { getTicketingIntegrationLogoByType } from "utils/ticketingIntegrationLogos";
import { Chip } from "components/ui/Chip";
import { ExportIcon } from "components/ui/Icons/ExportIcon";
import { TooltipOnOverflow } from "components/ui/TooltipOnOverflow";
import { useStyles } from "./styles";

export const TicketingIntegrationTicketChip: FC<{
	ticketingIntegrationTicket: TicketingIntegrationTicketModel;
	label?: JSX.Element;
}> = ({ ticketingIntegrationTicket, className, label = null }) => {
	const classes = useStyles();
	const TicketingSystemIcon = getTicketingIntegrationLogoByType(ticketingIntegrationTicket.ticketingIntegration)!;

	const openTicketUrl = useCallback(() => {
		window.open(ticketingIntegrationTicket.url, "_blank");
	}, [ticketingIntegrationTicket]);

	return (
		<div className={className}>
			{label && label}
			<Chip
				prefixIcon={<TicketingSystemIcon />}
				className={classes.ticketingIntegrationTicketChip}
				contentClassName={classes.ticketingIntegrationTicketChipContent}
				suffixIcon={<ExportIcon onClick={openTicketUrl} />}>
				<TooltipOnOverflow content={`${ticketingIntegrationTicket.projectName} - ${ticketingIntegrationTicket.name}`} />
			</Chip>
		</div>
	);
};
