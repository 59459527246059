import classNames from "classnames";
import { Typography } from "components/ui/Typography";
import { TicketCommentModel } from "models/TicketCommentModel";
import { TicketModel } from "models/TicketModel";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { AddTicketComment } from "./components/AddTicketComment";
import { TicketActivityItem } from "./components/TicketActivityItem";
import { useStyles } from "./styles";
import { getActivities } from "./utils";

interface IProps {
	addComment: (comment: string) => Promise<void>;
	ticket: TicketModel;
	updateComment: (comment: TicketCommentModel) => void;
	reloadTicket: () => Promise<TicketModel | null>;
}

export const TicketActivity: FC<IProps> = ({
	addComment,
	className,
	id,
	innerRef,
	ticket,
	updateComment,
	reloadTicket
}) => {
	const classes = useStyles();
	const { t } = useTranslation();
	const activities = useMemo(() => {
		if (!ticket) return null;
		return getActivities(ticket);
	}, [ticket]);
	if (!ticket) return null;
	return (
		<div className={classNames(classes.activity, className)} id={id} ref={innerRef}>
			<Typography variant="sectionTitle" className={classes.activityTitle}>
				{t("common.ticketActivity.title")}
			</Typography>
			<div className={classes.activityTimeline}>
				<div className={classes.activityTimelineList}>
					{activities?.map((activity, index) => (
						<TicketActivityItem
							updateComment={updateComment}
							key={index}
							activity={activity}
							ticket={ticket}
							showLine={index + 1 < activities.length}
						/>
					))}
				</div>
				<div className={classes.activityTimelineAddComment}>
					<AddTicketComment addComment={addComment} reloadTicket={reloadTicket} ticket={ticket} />
				</div>
			</div>
		</div>
	);
};
