import { ViewErrorButton } from "components/common/ViewErrorButton";
import { List } from "components/ui/List";
import { useUsers } from "hooks/useUsers";
import { boldComponent } from "i18n";
import { CompanyAuditLogModel } from "models/auditLogs";
import React, { useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";
import { sourceIcons } from "utils/sourceIcons";
import { Typography } from "components/ui/Typography";
import { TC } from "components/common/TicketActivity/utils";
import { AuditLogUser } from "../AuditLogUser";
import { useStyles } from "./styles";
import type { TFunction } from "i18next";
import type { TAuditLogContentComponent } from "./AuditLogContent.types";

type TRoleData = {
	roleName?: string;
	resourceName?: string;
	integrationName?: string;
	number?: number;
};

type TUpdateInfo = {
	added?: TRoleData[];
	removed?: TRoleData[];
};

type TMissingActorsData = {
	integrationName: string;
	rolesMissingUsersCount: number;
};

const TRANSLATION_PREFIX = "pages.auditLog.auditLogList.company.";

function getPermissionsChangedComponent(changedPermission: TRoleData[], type: "added" | "removed", t: TFunction) {
	const action = type === "added" ? "granted" : "revoked";
	return changedPermission.map(permission => (
		<Trans
			t={t}
			values={{
				roleName: permission.roleName,
				resourceName: permission.resourceName,
				integrationName: permission.integrationName,
				policyNumber: type === "added" ? permission.number : undefined
			}}
			components={{ bold: boldComponent }}
			i18nKey={`${TRANSLATION_PREFIX}permissionsChanged.${action}` as const}
			key={permission.roleName}
		/>
	));
}

function getPermissionsChangedList(auditLog: CompanyAuditLogModel, t: TFunction) {
	let permissionsData: JSX.Element[] = [];

	if (auditLog.action === "OrganizationPolicyPermissionsUpdatesToBePerformed") {
		const permissions = auditLog.data?.permissions as TUpdateInfo;

		if (permissions.added?.length) {
			permissionsData = permissionsData.concat(getPermissionsChangedComponent(permissions.added, "added", t));
		}

		if (permissions.removed?.length) {
			permissionsData = permissionsData.concat(getPermissionsChangedComponent(permissions.removed, "removed", t));
		}
	}

	return <List items={permissionsData} />;
}

export const CompanyAuditLogContent: TAuditLogContentComponent<CompanyAuditLogModel> = ({ auditLog, logUser }) => {
	const { t } = useTranslation();
	const classes = useStyles();
	const users = useUsers();

	const receiverUser = useMemo(() => {
		const userId =
			auditLog.action === "OrganizationPolicyPermissionsUpdatesToBePerformed"
				? auditLog.data?.receiverUserId
				: undefined;
		const user = userId ? users?.get(userId) : undefined;
		return user ? <AuditLogUser user={user} className={classes.userText} /> : <TC content={t("common.unknown.user")} />;
	}, [auditLog.data, auditLog.action, classes.userText, t, users]);

	const permissionsData = getPermissionsChangedList(auditLog, t);

	const props = useMemo(() => {
		// user missing actors
		const userId =
			auditLog.action === "OrganizationPolicyPermissionsUpdatesMissingAccounts"
				? auditLog.data?.receiverUserId
				: undefined;
		const user = userId ? users?.get(userId) : undefined;
		const userWithMissingActor = <AuditLogUser user={user} /> ?? t("common.unknown.user");

		// missing actors roles list
		const missingActorsData =
			auditLog.action === "OrganizationPolicyPermissionsUpdatesMissingAccounts"
				? (auditLog.data?.userMissingActorsData as TMissingActorsData[])
				: undefined;

		const missingActorRolesList = missingActorsData ? (
			<List
				items={missingActorsData?.map(missingActorData => (
					<Trans
						t={t}
						values={{
							integrationName: missingActorData.integrationName,
							rolesMissingUsersCount: missingActorData.rolesMissingUsersCount
						}}
						i18nKey={`${TRANSLATION_PREFIX}OrganizationPolicyPermissionsUpdatesMissingAccountsData` as const}
						components={{ bold: boldComponent }}
						key={missingActorData.integrationName}
					/>
				))}
			/>
		) : (
			<TC />
		);
		let directoryConfiguration: JSX.Element;
		if (auditLog.data?.idpApplicationName && auditLog.data.idpName) {
			const CompanyIcon = sourceIcons[auditLog.data.idpApplicationName]!;
			directoryConfiguration = (
				<div className={classes.directoryConfigurationContainer}>
					<CompanyIcon className={classes.companyIcon} />
					<Typography className={classes.directoryName}>{auditLog.data.idpName}</Typography>
				</div>
			);
		} else {
			directoryConfiguration = <TC />;
		}

		return {
			components: {
				receiverUser,
				permissionsData,
				bold: boldComponent,
				user: <AuditLogUser user={logUser} className={classes.userText} />,
				viewError: auditLog.data?.errorMessage ? <ViewErrorButton error={auditLog.data.errorMessage!} /> : <TC />,
				userWithMissingActor,
				missingActorRolesList,
				directoryConfiguration
			},
			i18nKey: `${TRANSLATION_PREFIX}${auditLog.action!}` as const
		};
	}, [
		auditLog.data,
		auditLog.action,
		classes.userText,
		classes.directoryName,
		classes.companyIcon,
		classes.directoryConfigurationContainer,
		logUser,
		permissionsData,
		receiverUser,
		t,
		users
	]);

	return (
		<div>
			<Trans t={t} {...props} />
		</div>
	);
};
