import { ErrorObject } from "ajv";
import classNames from "classnames";
import React, { useCallback, useEffect, useMemo, useRef, useState, FocusEvent } from "react";
import { TextAreaInput } from "components/ui/TextAreaInput";
import { useTranslation } from "react-i18next";
import { generateAjv } from "utils/ajvUtils";
import { useStyles } from "./styles";
import type { TJsonSchema } from "utils/types";

interface IProps {
	actions?: JSX.Element;
	autofocus?: boolean;
	disabled?: boolean;
	errors?: string[];
	inputClassName?: string;
	isRequired?: boolean;
	label?: string;
	onChange?: (data: Record<string, unknown>) => void;
	onBlur?: (data: { e: FocusEvent<HTMLTextAreaElement, Element>; value: Record<string, unknown> }) => void;
	onError?: (errors: Partial<ErrorObject>[] | null) => void;
	textAreaClassName?: string;
	privateField?: boolean;
	readonly?: boolean;
	validationSchema?: TJsonSchema;
	value?: Record<string, unknown> | null;
	fullWidth?: boolean;
}

export const JSONTextArea: FC<IProps> = ({
	actions = undefined,
	autofocus,
	className,
	disabled = false,
	errors,
	inputClassName,
	label,
	onChange: userOnChange,
	onError: userOnError,
	onBlur,
	privateField = false,
	readonly,
	fullWidth,
	validationSchema,
	value: userValue,
	textAreaClassName,
	isRequired = false
}) => {
	const { t } = useTranslation();
	const [value, setValue] = useState<string>(JSON.stringify(userValue || {}, null, 2));
	const ajv = useMemo(() => generateAjv(), []);
	const classes = useStyles();
	const textArea = useRef<HTMLTextAreaElement>(null);

	const onJsonError = useCallback(
		(error: Error) => {
			if (userOnError && (error as Error)?.name === "SyntaxError") {
				userOnError([{ keyword: "syntax", message: t("common.invalidJson") }]);
			}
		},
		[t, userOnError]
	);

	const onChange = useCallback(
		(newValue: string) => {
			setValue(newValue);
			try {
				const json = JSON.parse(newValue);

				if (userOnChange) {
					userOnChange(json);
				}
				if (userOnError) {
					if (validationSchema) {
						try {
							const valid = ajv.validate(validationSchema, json);
							userOnError(valid ? null : ajv.errors || null);
						} catch (err) {
							throw new SyntaxError("failed to validate JSON");
						}
					} else {
						userOnError(null);
					}
				}
			} catch (error) {
				onJsonError(error as Error);
			}
		},
		[userOnChange, userOnError, validationSchema, ajv, onJsonError]
	);

	useEffect(() => {
		if (userValue) {
			setValue(JSON.stringify(userValue, null, 2));
		}
	}, [userValue]);

	const format = useCallback(
		(e: FocusEvent<HTMLTextAreaElement, Element>) => {
			try {
				const json = JSON.parse(value);
				const newValue = JSON.stringify(json, null, 2);
				setValue(newValue);
				if (onBlur) {
					onBlur({ e, value: json });
				}
			} catch (error) {
				onJsonError(error as Error);
			}
		},
		[onBlur, value, onJsonError]
	);

	return (
		<TextAreaInput
			actions={actions}
			autoFocus={autofocus}
			className={className}
			disabled={disabled}
			errors={errors}
			inputRef={textArea}
			label={label}
			onBlur={format}
			onValueChange={onChange}
			fullWidth={fullWidth}
			privateField={privateField}
			readOnly={readonly}
			spellCheck={false}
			textAreaClassName={textAreaClassName ? classNames(classes.textArea, textAreaClassName) : classes.textArea}
			value={value}
			isRequired={isRequired}
			inputClassName={inputClassName}
		/>
	);
};
