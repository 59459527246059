import React, { useEffect, useMemo } from "react";
import { Route, useLocation } from "react-router-dom";
import { routes, TRoutes } from "routes/routes";
import { Route as IRoute } from "routes/route";
import { SwitchTransitions } from "components/ui/SwitchTransitions";
import useAnimationResolver, { TResolveMatch, TResolveOrderMatch } from "hooks/useAnimationResolver";
import { flattenRoutes } from "utils/router/utils";
import { useCompany } from "hooks/useCompany";
import { useIsBeta } from "context/betaContext";
import { useSideNavContext } from "context/sideNavContext";
import type { Require } from "utils/types";

import type { UserModel } from "models/UserModel";

const basePathAdder = (basePath: string) => (path: string) => basePath + path;

const routeHasAnimationOrder = (route: IRoute): route is Require<IRoute, "animationOrder"> =>
	typeof route.animationOrder === "number";

const calculateRoutesCustomAnimations = (
	routes: TRoutes,
	resolveMatches: (TResolveMatch | TResolveOrderMatch)[] = [],
	basePath = ""
) => {
	Object.values(routes).forEach(route => {
		const addBasePath = basePathAdder(basePath);

		const targets = Object.values(routes)
			.filter(possibleRoute => possibleRoute !== route)
			.map(({ matchesWithChildren }) => matchesWithChildren)
			.flat()
			.map(addBasePath);

		if (route.inAnimation) {
			resolveMatches.push({
				from: targets,
				to: route.matches.map(addBasePath),
				animation: route.inAnimation.name,
				reverse: route.inAnimation.reverse
			});
		}

		if (route.outAnimation) {
			resolveMatches.push({
				from: route.matches.map(addBasePath),
				to: targets,
				animation: route.outAnimation.name,
				reverse: route.outAnimation.reverse
			});
		}

		if (route.subRoutes) {
			const { subRoutes } = route;
			route.matches.forEach(match => {
				calculateRoutesCustomAnimations(subRoutes, resolveMatches, match);
			});

			const subRoutesTargets = Object.values(route.subRoutes)
				.filter(possibleRoute => possibleRoute !== route)
				.map(({ matches }) => matches)
				.flat()
				.map(subRouteMatch => route.matches.map(addBasePath).map(match => match + subRouteMatch))
				.flat();

			resolveMatches.push({
				from: subRoutesTargets,
				to: route.matches.map(addBasePath),
				animation: "fade-move-from-center",
				reverse: false
			});

			resolveMatches.push({
				to: subRoutesTargets,
				from: route.matches.map(addBasePath),
				animation: "fade-move-from-center",
				reverse: true
			});

			resolveMatches.push({
				order: Object.values(route.subRoutes)
					.filter(routeHasAnimationOrder)
					.sort((a, b) => a.animationOrder - b.animationOrder)
					.map(({ matches: subRouteMatches }) =>
						route.matches.map(match => subRouteMatches.map(basePathAdder(match))).flat()
					),
				animation: "move-from-side"
			});
		}
	});

	return resolveMatches;
};

const PageWrapper: FC<{ route: IRoute }> = ({ route }) => {
	const { Page, hideSideNav } = route;
	const {
		actions: { setHideSideNav }
	} = useSideNavContext();
	const location = useLocation();

	// we need location here to trigger the use effect anytime the location changes
	// this is because the animations can cause the page not to rerender if switched back and forth fast enough
	useEffect(() => {
		setHideSideNav(hideSideNav);
	}, [setHideSideNav, hideSideNav, location]);

	return <Page />;
};

export function PageRouter({ user }: { user: UserModel }) {
	const location = useLocation();
	const company = useCompany();
	const isBeta = useIsBeta();

	const flatRoutes = useMemo(() => flattenRoutes(routes), []);

	const resolvedMatches: (TResolveMatch | TResolveOrderMatch)[] = useMemo(
		() => [
			...calculateRoutesCustomAnimations(routes),
			{
				order: flatRoutes
					.filter(routeHasAnimationOrder)
					.sort((a, b) => a.animationOrder - b.animationOrder)
					.map(({ matches }) => matches),
				animation: "fade-move-from-bottom"
			},
			{
				animation: "fade"
			}
		],
		[flatRoutes]
	);

	const { animation, reverse } = useAnimationResolver(resolvedMatches, { currentLocation: location });

	return (
		<SwitchTransitions animation={animation} reverse={reverse}>
			{flatRoutes
				.filter(({ shouldRender }) => shouldRender({ user, company: company ?? undefined, isBeta }))
				.flatMap(route =>
					route.matches.map(path => (
						<Route path={path} key={route.main + path} element={<PageWrapper route={route} />} />
					))
				)}
		</SwitchTransitions>
	);
}
