import React, { useCallback, useMemo } from "react";
import classNames from "classnames";
import { UserModel } from "models/UserModel";
import { IRenderChipParams, MultipleSelect } from "components/ui/MultipleSelect";
import { UserOption } from "components/common/UserOption";
import { Chip } from "components/ui/Chip";
import { sortByFullName } from "utils/sortUtils";
import { UserWithEmail } from "components/common/UserWithEmail";

interface IProps {
	fullWidth?: boolean;
	label: string;
	onChange: (value: string[]) => void;
	placeholder?: string;
	renderChip?: (params: IRenderChipParams<UserModel>) => JSX.Element;
	users: UserModel[];
	value: string[];
}

const mapIdToUser = (users: UserModel[], ids: string[]) => {
	return ids.map(id => users.find(user => user.id === id)).filter((item): item is UserModel => Boolean(item));
};

const getLabel = (user: UserModel) => user?.fullName;
const equality = (option: UserModel, value: UserModel) => option?.id === value?.id;
const renderChip = (params: IRenderChipParams<UserModel>) => {
	return (
		<Chip
			onDelete={params.onRemove}
			key={params.componentKey}
			noBorder={!!params.noBorder}
			onClick={params.onClick}
			stretch={params.stretch}>
			<UserOption option={params.option} noEmail />
		</Chip>
	);
};

const renderOption = (option: UserModel) => <UserWithEmail user={option} />;

export const UsersSelectInput: FC<IProps> = ({
	className,
	fullWidth = true,
	label,
	onChange: propsOnChange,
	renderChip: propsRenderChip,
	placeholder,
	users,
	value
}) => {
	const valueArray = useMemo(() => mapIdToUser(users, value), [users, value]);

	const onChange = useCallback(
		(value: UserModel[] | null) => propsOnChange(value?.map(({ id }) => id) || []),
		[propsOnChange]
	);

	return (
		<MultipleSelect
			className={classNames(className)}
			fullWidth={fullWidth}
			getOptionLabel={getLabel}
			isOptionEqualToValue={equality}
			label={label}
			onChange={onChange}
			options={users || []}
			placeholder={placeholder}
			renderChip={propsRenderChip ?? renderChip}
			renderOption={renderOption}
			sort={sortByFullName}
			value={valueArray}
		/>
	);
};
