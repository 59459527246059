import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	actions: {
		alignItems: "center",
		display: "flex",
		fontSize: "1.25rem",
		gap: "1rem",
		height: "100%",
		justifyContent: "center"
	},
	actionsButton: {
		"&$actionsButton$actionsButton": {
			padding: "0.5rem",
			color: "var(--color-black)",
			"&:hover": {
				backgroundColor: "var(--color-grey-light)"
			}
		}
	},
	bundleTagsContainer: {
		display: "flex",
		alignItems: "center",
		flexDirection: "row",
		flexWrap: "wrap",
		gap: "0.25rem",
		width: "100%"
	},
	bundleTagChip: {
		display: "inline-block",
		"&:hover": {
			backgroundColor: "var(--color-white)"
		}
	},
	tag: {
		maxWidth: "10em"
	},
	collapsedValues: {
		"&$collapsedValues$collapsedValues": {
			display: "block",
			maxHeight: "20rem",
			overflowY: "auto",
			width: "100%",
			textAlign: "center",
			"& $bundleTagChip": {
				margin: "0 1px"
			}
		}
	},
	tooltip: {
		maxWidth: "20rem"
	},
	bundle: {
		width: "100%"
	},
	primaryData: {
		marginBottom: "0.25rem"
	},
	maxWidth: {
		maxWidth: "100%"
	},
	bundleDescription: {
		color: "var(--color-grey-dark)",
		wordBreak: "break-word",
		hyphens: "auto",
		MozHyphens: "auto",
		WebkitHyphens: "auto",
		msHyphens: "auto"
	},
	applicationIcon: {
		alignSelf: "center",
		height: "1.25em",
		width: "1.25em"
	},
	approvalAlgorithm: {
		alignItems: "center",
		display: "flex",
		flexDirection: "row",
		gap: "0.5rem",
		height: "100%",
		overflow: "auto"
	},
	bundleItems: {
		alignItems: "center",
		display: "flex",
		flexDirection: "row",
		flexWrap: "wrap",
		gap: "0.5rem",
		height: "100%"
	},
	bundlesListContainer: {
		padding: "2rem",
		gap: "2rem",
		display: "flex",
		flexDirection: "column",
		height: "100%"
	},
	bundlesListInput: {
		width: "33%"
	},
	cell: {
		alignItems: "center",
		display: "flex",
		flexDirection: "row",
		height: "100%",
		width: "100%"
	},
	noResourcesWarning: {
		alignItems: "center",
		color: "var(--color-red)",
		display: "flex",
		flexDirection: "row",
		height: "100%",
		gap: "0.5rem"
	},
	resourceName: {
		fontWeight: "var(--typography-font-weight-bold)",
		marginRight: "0.3rem"
	},
	roleName: {
		alignSelf: "flex-end",
		fontWeight: "var(--typography-font-weight-bold)"
	},
	workflowIcon: {
		fontSize: "1.25em"
	}
});
