import { apiReq } from "utils/apiReq";
import { BundleModel } from "models/BundleModel";
import { Map } from "immutable";

export async function getBundles(userId?: string): Promise<Map<string, BundleModel>> {
	const qs = userId ? `?userId=${userId}` : "";
	const { data } = await apiReq("GET", `/v1/bundles${qs}`);

	return Map<string, BundleModel>(
		data.map((bundle: unknown) => BundleModel.fromServerData(bundle)).map((bundle: BundleModel) => [bundle.id, bundle])
	);
}

// Unused
export async function getBundle(id: string): Promise<BundleModel> {
	const { data } = await apiReq("GET", `/v1/bundles/${id}`);
	return BundleModel.fromServerData(data);
}

export const createBundle = async (bundle: BundleModel) => {
	const schema = bundle.toServerData();
	const { id: _, ...body } = schema;
	const { data: newBundle } = await apiReq("POST", "/v1/bundles", body);

	return BundleModel.fromServerData(newBundle);
};

export const updateBundle = async (bundle: BundleModel) => {
	const schema = bundle.toServerData();
	const { id, ...body } = schema;
	const { data: updatedBundle } = await apiReq("PUT", `/v1/bundles/${id}`, body);

	return BundleModel.fromServerData(updatedBundle);
};

export const deleteBundle = async (bundle: BundleModel) => {
	const { id } = bundle;
	return apiReq("DELETE", `/v1/bundles/${id}`);
};
