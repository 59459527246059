import { getAuditLogsDates, getPaginatedAuditLogsByDate } from "api/auditLogs";
import constate from "constate";
import { useIntegrationsContext } from "context/integrationsContext";
import { useLoadingState } from "hooks/useLoadingState";
import { DESC, usePagination } from "hooks/usePagination";
import { useCallback, useEffect, useMemo, useState } from "react";
import type { IPaginatedSearchOptions } from "utils/searchUtils";
import type { TTimezone } from "utils/systemAuditLogTimezone";

export const getDateOptionLabel = (option: Date) =>
	option.toLocaleDateString(undefined, { year: "numeric", month: "2-digit", day: "2-digit" });

const useAuditLogs = () => {
	const [selectedDate, setSelectedDate] = useState<Date | null>(null);
	const [dates, setDates] = useState<Date[] | undefined>(undefined);
	const { isLoading: isLoadingAuditLogs, withLoader: withAuditLogsLoader } = useLoadingState();
	const { isLoading: isLoadingDates, withLoader: withDatesLoader } = useLoadingState();
	const [timezone, setCurrentTimezone] = useState<"local" | "global">("local");

	const {
		state: { isLoading: isLoadingIntegrations },
		actions: { loadIntegrations }
	} = useIntegrationsContext();

	const fetchDateAuditLogs = useCallback(
		(paginationOptions: IPaginatedSearchOptions) => {
			return getPaginatedAuditLogsByDate(selectedDate!, timezone, paginationOptions);
		},
		[selectedDate, timezone]
	);

	const {
		getPage,
		totalPages,
		lastPageNumber,
		totalResults,
		clearData,
		items: auditLogsList
	} = usePagination(fetchDateAuditLogs, 100, DESC);

	const auditLogs = useMemo(() => auditLogsList?.toArray() ?? [], [auditLogsList]);

	const getNextPage = useCallback(async () => {
		if (!isLoadingAuditLogs) {
			await withAuditLogsLoader(getPage(lastPageNumber + 1));
		}
	}, [getPage, isLoadingAuditLogs, lastPageNumber, withAuditLogsLoader]);

	const selectDate = useCallback(
		async (date: Date | null) => {
			if (date) {
				setSelectedDate(date);
				clearData();
			}
		},
		[clearData]
	);

	const fetchDates = useCallback(async () => {
		const fetch = async () => {
			const newDates = await getAuditLogsDates(timezone);
			const date = selectedDate && newDates.find(date => getDateOptionLabel(date) === getDateOptionLabel(selectedDate));
			selectDate(date || newDates[0]);
			setDates(newDates);
		};
		await withDatesLoader(fetch());
	}, [selectDate, selectedDate, timezone, withDatesLoader]);

	const setTimezone = useCallback((value: TTimezone) => {
		setCurrentTimezone(value);
		setDates(undefined);
	}, []);

	useEffect(() => {
		!dates && !isLoadingDates && fetchDates();
	}, [dates, fetchDates, isLoadingDates]);

	useEffect(() => {
		loadIntegrations();
	}, [loadIntegrations]);

	const pagination = useMemo(
		() => ({ lastPageNumber, totalAuditLogs: totalResults, totalPages }),
		[lastPageNumber, totalPages, totalResults]
	);

	const store = useMemo(
		() => ({
			state: {
				auditLogs,
				pagination,
				timezone,
				dates,
				selectedDate,
				isLoadingIntegrations,
				isLoadingDates,
				isLoadingAuditLogs
			},
			actions: { selectDate, getNextPage, setTimezone }
		}),
		[
			auditLogs,
			pagination,
			timezone,
			dates,
			selectedDate,
			isLoadingIntegrations,
			isLoadingDates,
			isLoadingAuditLogs,
			selectDate,
			getNextPage,
			setTimezone
		]
	);

	return store;
};

export const [AuditLogsProvider, useAuditLogsContext, useTimezone] = constate(
	useAuditLogs,
	value => value,
	({ state }) => state.timezone
);
