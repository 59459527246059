import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	disabled: {
		opacity: 0.4
	},
	webhookChip: {
		"&$green": {
			background: "var(--color-green-400)",
			color: "var(--color-white)"
		},
		"&$red": {
			background: "var(--color-red-700)",
			color: "var(--color-white)"
		}
	},
	green: {},
	red: {}
});
