import { createUseStyles } from "react-jss";

export const REQUEST_BAR_HEIGHT = 160;

export const useStyles = createUseStyles({
	container: {
		alignItems: "flex-start",
		backgroundColor: "var(--color-purple-50)",
		borderRadius: "16px",
		display: "grid",
		flexShrink: 0,
		gap: "24px",
		gridTemplateColumns: "minmax(160px, 220px) auto auto 1fr 196px auto",
		height: `${REQUEST_BAR_HEIGHT}px`,
		justifyContent: "stretch",
		minWidth: "fit-content",
		fallbacks: {
			minWidth: "-moz-fit-content"
		},
		overflowY: "hidden",
		padding: "16px",
		position: "relative",
		"&:hover": {
			backgroundColor: "var(--color-purple-100)"
		},
		"&$clickable:active": {
			backgroundColor: "var(--color-purple-200)"
		},
		"&$selected": {
			backgroundColor: "var(--color-purple-200)"
		}
	},
	grow: {
		flexGrow: 1,
		flexShrink: 1
	},
	approversJustification: {
		display: "grid",
		gap: "24px",
		"&$withApprovers": {
			gridTemplateColumns: "minmax(180px, 1fr) minmax(160px, 220px)"
		},
		"&$longJustification": {
			gridTemplateColumns: "minmax(364px, 1fr)"
		},
		"&$shortJustification": {
			gridTemplateColumns: "minmax(180px, 1fr)"
		}
	},
	clickable: {
		"&:hover": {
			cursor: "pointer"
		}
	},
	withApprovers: {},
	longJustification: {},
	shortJustification: {},
	selected: {}
});
