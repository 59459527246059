import React from "react";
import { Chip } from "components/ui/New/Chip";
import { WebhookIcon } from "components/ui/Icons/WebhookIcon";
import classNames from "classnames";
import { useStyles } from "./styles";

export type TWebhookChipColor = "green" | "red" | "default";

interface IProps {
	displayName: string;
	color?: TWebhookChipColor;
}

const WebhookChipComponent: FC<IProps> = ({ color = "default", displayName }) => {
	const classes = useStyles();

	return (
		<Chip
			className={classNames(classes.webhookChip, {
				[classes.green]: color === "green",
				[classes.red]: color === "red"
			})}
			prefix={<WebhookIcon size={16} />}>
			{displayName}
		</Chip>
	);
};

export const WebhookChip = React.memo(WebhookChipComponent);
