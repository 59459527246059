import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useBundles } from "hooks/useBundles";
import { useIntegrations } from "hooks/useIntegrations";
import { IntegrationModel } from "models/IntegrationModel";
import { MultipleSelect } from "components/ui/MultipleSelect";
import { Select, TTargetValue } from "components/ui/Select";
import { getIntegrationResources } from "api/integrationResources";
import { Map } from "immutable";
import { getIntegrationResourceRoles } from "api/integrationResourceRoles";
import { notEmpty } from "utils/comparison";
import { IntegrationSelectInput } from "components/common/IntegrationSelectInput";
import { getArrayValue, IFilterProps } from "../../";

const TRANSLATION_PREFIX = "common.ticketFilters";
const OPTIONS_LIMIT = 100;
const CHIPS_LIMIT = 5;
type TMultipleSelectOption = { value: string; label: string };

const equality = (a: TMultipleSelectOption, b: TMultipleSelectOption) => a.value === b.value;
const groupResourcesBy = (resource: { group: string }) => resource.group;

export const TargetFilter: FC<IFilterProps> = ({ onFiltersChange, ticketFilters }) => {
	const { t } = useTranslation();
	const bundles = useBundles();
	const integrations = useIntegrations();
	const [resourceInput, setResourceInput] = useState("");
	const [resourceOptions, setResourceOptions] = useState<Map<
		string,
		{ value: string; label: string; group: string }
	> | null>(null);
	const [rolesInput, setRolesInput] = useState("");
	const [roleOptions, setRolesOptions] = useState<Map<string, TMultipleSelectOption> | null>(null);

	const target = ticketFilters.target;

	const bundle = useMemo(
		() => (bundles ? getArrayValue(target?.bundle, value => bundles.get(value)!.name) : []),
		[bundles, target]
	);

	const integration = (target?.integration && integrations && integrations.get(target.integration)) || null;
	const resource = (target?.resource && resourceOptions && resourceOptions.get(target.resource)) || null;
	const roles = useMemo(
		() =>
			roleOptions
				? getArrayValue(target?.role)
						.map(roleId => roleOptions.get(roleId))
						.filter(notEmpty)
				: [],
		[roleOptions, target?.role]
	);

	useEffect(() => {
		async function fetchData(integrationId: string) {
			const { result } = await getIntegrationResources(integrationId, {
				pagination: { perPage: OPTIONS_LIMIT },
				search: { query: resourceInput }
			});
			setResourceOptions(
				Map(result.map(({ id, displayName, type }) => [id, { value: id, label: displayName, group: type }]))
			);
		}
		if (target?.integration) fetchData(target?.integration);
	}, [resourceInput, target?.integration]);

	useEffect(() => {
		async function fetchData(resourceId: string) {
			const { result } = await getIntegrationResourceRoles(resourceId, {
				pagination: { perPage: OPTIONS_LIMIT },
				search: { query: rolesInput }
			});
			setRolesOptions(Map(result.map(({ id, name }) => [id, { value: id, label: name }])));
		}
		if (target?.resource) fetchData(target?.resource);
	}, [rolesInput, target?.resource]);

	const bundleOptions = useMemo(
		() =>
			bundles
				?.toList()
				.map(({ id, name }) => ({ value: id, label: name }))
				.toArray() || [],
		[bundles]
	);
	const resourceOptionsArray = useMemo(() => resourceOptions?.toList().toArray() || [], [resourceOptions]);
	const roleOptionsArray = useMemo(() => roleOptions?.toList().toArray() || [], [roleOptions]);

	const changeBundle = useCallback(
		(bundle: TMultipleSelectOption[] | null) => {
			onFiltersChange(current => ({
				...current,
				target: { ...current.target, bundle: bundle?.map(({ value }) => value) || [] }
			}));
		},
		[onFiltersChange]
	);
	const changeIntegration = useCallback(
		(value: IntegrationModel | null) => {
			onFiltersChange(current => ({
				...current,
				target: { ...current.target, integration: value?.id, resource: undefined, role: undefined }
			}));
		},
		[onFiltersChange]
	);
	const changeResource = useCallback(
		(resource: { value: string; label: string; group: string } | null) => {
			onFiltersChange(current => ({
				...current,
				target: { ...current.target, resource: resource?.value, role: undefined }
			}));
		},
		[onFiltersChange]
	);
	const changeRoles = useCallback(
		(roles: TMultipleSelectOption[] | null) => {
			onFiltersChange(current => ({
				...current,
				target: { ...current.target, role: roles?.map(({ value }) => value) }
			}));
		},
		[onFiltersChange]
	);

	const changeResourceInput = useCallback(
		(event: TTargetValue | React.ChangeEvent<HTMLInputElement>) => setResourceInput(event.target.value),
		[]
	);
	const changeRolesInput = useCallback(
		(event: TTargetValue | React.ChangeEvent<HTMLInputElement>) => setRolesInput(event.target.value),
		[]
	);

	return (
		<div>
			<MultipleSelect
				options={bundleOptions}
				label={t(`${TRANSLATION_PREFIX}.target.bundle`)}
				value={bundle}
				onChange={changeBundle}
				isOptionEqualToValue={equality}
				fullWidth
				chipsLimit={CHIPS_LIMIT}
			/>
			<div>
				<IntegrationSelectInput
					label={t(`${TRANSLATION_PREFIX}.target.integration`)}
					value={integration}
					options={integrations}
					onChange={changeIntegration}
				/>
				<Select
					options={resourceOptionsArray}
					loading={!resourceOptions && !!target?.integration}
					disabled={!target?.integration}
					value={resource}
					fullWidth
					label={t(`${TRANSLATION_PREFIX}.target.resource`)}
					groupBy={groupResourcesBy}
					onChange={changeResource}
					onInputChange={changeResourceInput}
					inputValue={resourceInput}
				/>
				<MultipleSelect
					options={roleOptionsArray}
					loading={!roleOptions && !!target?.resource}
					disabled={!target?.resource}
					value={roles}
					fullWidth
					label={t(`${TRANSLATION_PREFIX}.target.role`)}
					onChange={changeRoles}
					onInputChange={changeRolesInput}
					inputValue={rolesInput}
					chipsLimit={CHIPS_LIMIT}
				/>
			</div>
		</div>
	);
};
