import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { useLoadingState } from "hooks/useLoadingState";
import { AsteriskIcon } from "components/ui/Icons/AsteriskIcon";
import { Chip } from "components/ui/Chip";
import { Tooltip } from "components/ui/Tooltip";
import { Typography } from "components/ui/Typography";
import { useApplications } from "hooks/useApplications";
import { useIntegrations } from "hooks/useIntegrations";
import { PrerequisitePermissionRoleModel } from "models/PrerequisitePermissionRoleModel";
import { CloseIcon } from "components/ui/Icons/CloseIcon";
import { LogoAvatar } from "components/common/LogoAvatar";
import { IconButton } from "components/ui/IconButton";
import { useStyles } from "./styles";

interface IProps {
	role: PrerequisitePermissionRoleModel;
	removable: boolean;
	onUpdate: (integrationResourceRoleId: string, clauseId: string) => Promise<void>;
	onRemove: (prerequisitePermissionRoleId: string) => Promise<void>;
}

export const PrerequisitePermissionRole: FC<IProps> = ({ role, removable, onUpdate, onRemove }) => {
	const classes = useStyles();
	const { t } = useTranslation();
	const integrations = useIntegrations(true);
	const applications = useApplications();
	const isDefault = role.defaultOption;
	const { isLoading: isRemoveLoading, withLoader: withRemoveLoader } = useLoadingState();
	const { isLoading: isDefaultLoading, withLoader: withDefaultLoader } = useLoadingState();

	const integration = useMemo(() => {
		const integrationId = role.integrationResourceRole?.integrationResource?.integrationId;
		if (integrationId && integrations) return integrations.get(integrationId);
		return null;
	}, [integrations, role]);

	const imageUrl = useMemo(
		() =>
			integration?.imageUrl ||
			applications?.get(integration?.applicationId || "")?.imageUrl ||
			applications?.find(application => application.adapterless)?.imageUrl,
		[applications, integration]
	);

	const setAsDefault = useCallback(async () => {
		!isDefault && (await withDefaultLoader(onUpdate(role.id, role.prerequisitePermissionClauseId)));
	}, [isDefault, onUpdate, role, withDefaultLoader]);

	const removeRole = useCallback(async () => {
		removable && !isDefault && (await withRemoveLoader(onRemove(role.id)));
	}, [removable, isDefault, withRemoveLoader, onRemove, role]);

	return (
		<div className={classes.roleContainer}>
			<Tooltip
				content={t(`prerequisitePermissions.roles.${role.defaultOption ? "default" : "setAsDefault"}`)}
				trigger="hover">
				<span>
					<IconButton
						size="small"
						className={classNames(classes.defaultButton, { [classes.selected]: role.defaultOption })}
						onClick={!role.defaultOption ? setAsDefault : undefined}
						loading={isDefaultLoading}>
						<AsteriskIcon />
					</IconButton>
				</span>
			</Tooltip>
			<Chip
				className={classes.chip}
				prefixIcon={
					<LogoAvatar size="medium" noBorder>
						<img src={imageUrl} />
					</LogoAvatar>
				}
				suffixIcon={
					removable ? (
						<Tooltip
							content={
								isDefault ? t("prerequisitePermissions.roles.notRemovable") : t("prerequisitePermissions.roles.remove")
							}>
							<span>
								<IconButton
									size="small"
									className={classes.removeButton}
									onClick={removeRole}
									disabled={isDefault}
									loading={isRemoveLoading}>
									<CloseIcon />
								</IconButton>
							</span>
						</Tooltip>
					) : undefined
				}>
				<div className={classes.displayName}>
					<Typography variant="small">{role.integrationResourceRole.name}</Typography>
					<Typography variant="small">
						{role.integrationResourceRole.integrationResource?.displayName} - {integration?.name}
					</Typography>
				</div>
			</Chip>
		</div>
	);
};
