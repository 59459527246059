import React, { useCallback, useMemo } from "react";
import { PageTemplate } from "components/templates/PageTemplate";
import { useTranslation } from "react-i18next";
import { Block } from "components/ui/Block";
import { Typography } from "components/ui/Typography";
import { UserModel } from "models/UserModel";
import { TColumn, VirtualTable } from "components/ui/VirtualTable";
import { UserWithEmail } from "components/common/UserWithEmail";
import { Input } from "components/ui/Input";
import { LoadingDots } from "components/ui/LoadingDots";
import { useNavigate } from "react-router-dom";
import { useStyles } from "./styles";
import { useUsersTable } from "./useUsersTable";

export const UsersPage: FC = ({ className }) => {
	const classes = useStyles();
	const { t } = useTranslation();
	const navigate = useNavigate();

	const { displayedUsers, loading, searchTerm, onSearchUsers } = useUsersTable();

	const columns: TColumn<UserModel>[] = useMemo(
		() => [
			{
				header: t("pages.users.columns.name"),
				key: "email",
				renderCell: row => (
					<div className={classes.cell}>
						<UserWithEmail user={row} />
					</div>
				),
				width: "1fr"
			},
			{
				header: t("pages.users.columns.role"),
				key: "role",
				renderCell: row => (
					<div className={classes.cell}>
						<Typography variant="small">{t(`shared.roles.${row.role}`)}</Typography>
					</div>
				),
				width: "1fr"
			}
		],
		[classes.cell, t]
	);

	const onClick = useCallback(
		(data: UserModel) => {
			navigate(`/users/${data.id}`);
		},
		[navigate]
	);

	return (
		<PageTemplate className={className}>
			<PageTemplate.Title>{t("pages.users.title")}</PageTemplate.Title>
			<PageTemplate.Content>
				{loading ? (
					<LoadingDots center />
				) : (
					<Block className={classes.container}>
						<Input
							className={classes.input}
							placeholder={t("pages.users.search")}
							onValueChange={onSearchUsers}
							value={searchTerm}
						/>
						<div className={classes.usersTableContainer}>
							<VirtualTable
								className={classes.table}
								rows={displayedUsers}
								totalRows={displayedUsers.length}
								columns={columns}
								sideBorders={false}
								onRowClicked={onClick}
							/>
						</div>
					</Block>
				)}
			</PageTemplate.Content>
		</PageTemplate>
	);
};
