import type { TSortOrder } from "hooks/usePagination";
import type { TQsFilter } from "./pagination";

interface ISearchOptions {
	query?: string;
}

interface ISortOptions {
	fields?: string[] | string;
	order?: TSortOrder;
}

interface IPaginationOptions {
	page?: number;
	perPage?: number;
}

export interface IPaginatedSearchOptions {
	search?: ISearchOptions;
	pagination?: IPaginationOptions;
	sort?: ISortOptions;
	filters?: TQsFilter;
}

export const getPaginatedSearchQueryString = (options?: IPaginatedSearchOptions) => {
	let qsFields: [string, string | number][] = [];
	if (!options) {
		return "";
	}
	const { search, pagination, sort, filters } = options;
	if (search && search.query) {
		// Need to change this field in main-api
		qsFields.push(["search", encodeURIComponent(search.query)]);
	}
	if (pagination && pagination.perPage) {
		qsFields.push(["perPage", pagination.perPage]);
		if (pagination.page) qsFields.push(["page", pagination.page]);
	}
	if (sort) {
		if (sort?.order) qsFields.push(["sortOrder", sort.order]);
		if (sort?.fields) {
			const addedFields =
				typeof sort.fields === "string"
					? [["sortFields", sort.fields] as [string, string]]
					: sort.fields.map(field => ["sortFields", field] as [string, string]);
			qsFields = qsFields.concat(addedFields);
		}
	}

	const filtersQs = filters && filters.toQueryString ? `&${filters.toQueryString()}` : "";
	const qs = qsFields.map(([key, value]) => `${key}=${value}`).join("&");

	return qs + (qs && filtersQs ? "&" + filtersQs : "");
};
