import { useUsersContext } from "context/usersContext";
import { useEffect, useMemo } from "react";

export const useSingleUser = (id?: string | null, full = true) => {
	const {
		state: { fullUsersState, users },
		actions: { loadUser }
	} = useUsersContext();

	const fullUserState = useMemo(() => (id ? fullUsersState.get(id) || null : null), [fullUsersState, id]);
	const loading = fullUserState?.get("loading") || false;
	const hadError = fullUserState?.get("hadError") || false;

	const user = useMemo(() => {
		if (!id) return null;
		if (!full) return users?.get(id) || null;
		return fullUserState && fullUserState.get("data");
	}, [fullUserState, full, users, id]);

	useEffect(() => {
		if (!id || user || loading || hadError) {
			return;
		}

		loadUser(id);
	}, [loadUser, id, loading, user, hadError]);

	return { user: user, isLoading: loading, hadError };
};
