import React, { ReactNode, useCallback, useMemo } from "react";
import { matchSorter } from "match-sorter";
import { useTranslation } from "react-i18next";
import { useEntities } from "hooks/useEntities";
import { TextWithDirectoryIcon } from "components/common/TextWithDirectoryIcon";
import { useCompany } from "hooks/useCompany";
import { IRenderChipParams, MultipleSelect } from "components/ui/MultipleSelect";
import { Typography } from "components/ui/Typography";
import { Avatar } from "components/common/Avatar";
import { EmailTooltip } from "components/common/EmailTooltip";
import { DirectoryGroup } from "components/common/DirectoryGroup";
import { TFunction } from "i18next";
import { Chip } from "components/ui/Chip";
import { OnCallIntegrationScheduleLabel } from "components/common/OnCallIntegrationScheduleLabel";
import { getNameAndIcon } from "utils/directoryGroups";
import { useRankedSort } from "hooks/useRankedSort";
import { TEntityOption } from "utils/entityOptionType";
import { ApprovalFlowEntityModel } from "models/ApprovalFlowEntityModel";
import { WebhookIcon } from "components/ui/Icons/WebhookIcon";
import { useStyles } from "./styles";

const TRANSLATION_PREFIX = "pages.workflows.workflowEditor.entitiesSelectInput.";
const SINGLE_ENTITY_TYPES = new Set([
	"Automatic",
	"DirectManager",
	"IntegrationMaintainer",
	"IntegrationOwner",
	"ResourceMaintainer",
	"ResourceOwner",
	"TeamMember"
]);

interface IProps {
	values: ApprovalFlowEntityModel[];
	onChange: (values: TEntityOption[] | null) => void;
	automaticState?: "none" | "disabled" | "enabled";
}

const getOptionLabel = ({ displayName }: TEntityOption) => displayName || "";

const OnCallIntegrationChip = ({
	noBorder,
	onClick,
	onRemove,
	option,
	stretch
}: Pick<IRenderChipParams<TEntityOption>, "noBorder" | "onClick" | "onRemove" | "option" | "stretch">) => {
	const { t } = useTranslation();

	return (
		<Chip onDelete={onRemove} noBorder={noBorder} stretch={stretch} size="small" onClick={onClick} multiLine>
			<OnCallIntegrationScheduleLabel
				value={option.displayName || t("common.approvalFlow.deletedOnCallEntitySchedule")}
				onCallType={option.onCallScheduleType || undefined}
				isDeleted={option.isDeleted}
			/>
		</Chip>
	);
};

const renderChip = (props: IRenderChipParams<TEntityOption>) => {
	if (props.option.type === "OnCallIntegrationSchedule") {
		return (
			<OnCallIntegrationChip
				key={props.componentKey}
				option={props.option}
				noBorder={props.noBorder}
				onClick={props.onClick}
				onRemove={props.onRemove}
				stretch={props.stretch}
			/>
		);
	}

	let icon: JSX.Element | undefined;
	if (props.option.type === "User" && props.option.identifier) {
		icon = <Avatar userId={props.option.identifier} />;
	} else if (props.option.type === "Webhook") {
		icon = <WebhookIcon />;
	}

	return (
		<EmailTooltip key={props.componentKey} email={props.option.email || undefined} isDeleted={props.option.isDeleted}>
			<Chip
				onDelete={props.onRemove}
				noBorder={Boolean(props.noBorder)}
				stretch={props.stretch}
				size="small"
				onClick={props.onClick}
				multiLine>
				<DirectoryGroup value={props.option.displayName || ""} icon={icon} isDeleted={props.option.isDeleted} />
			</Chip>
		</EmailTooltip>
	);
};

const renderOptionBase = (option: TEntityOption, className: string, nameClassName: string, t: TFunction) => {
	let optionPrefix: ReactNode | null = null;
	if (option.type === "OnCallIntegrationSchedule" && option.onCallScheduleType) {
		optionPrefix = (
			<OnCallIntegrationScheduleLabel value={option.displayName || ""} onCallType={option.onCallScheduleType} />
		);
	} else if (option.type === "User" && option.identifier) {
		optionPrefix = <Avatar userId={option.identifier} />;
	} else if (option.type === "Webhook") {
		optionPrefix = <WebhookIcon />;
	}

	const email = option.email;
	const deletedText = option.isDeleted ? ` (${t("entities.deletedEntity", { context: "capital" })})` : "";

	return (
		<div className={className}>
			<div className={nameClassName}>
				{optionPrefix}
				<TextWithDirectoryIcon value={option.displayName || ""} isDeleted={option.isDeleted} />
			</div>
			{email ? <Typography variant="tiny">{`${email}${deletedText}`}</Typography> : null}
		</div>
	);
};

const equality = (a: TEntityOption, b: TEntityOption) => {
	if (!a || !b) {
		return false;
	}
	if (a.identifier && b.identifier) {
		return a.identifier === b.identifier;
	} else {
		return a.type === b.type && a.displayName === b.displayName;
	}
};

const SORT_OPTIONS = {
	keys: [
		{
			key: (item: TEntityOption) => getNameAndIcon(item.displayName ?? "").name,
			threshold: matchSorter.rankings.MATCHES
		},
		(item: TEntityOption) => item.type
	]
};

export const EntitiesSelectInput: FC<IProps> = ({ values: propValues, onChange, className, automaticState }) => {
	const remoteEntities = useEntities();
	const classes = useStyles();
	const { t } = useTranslation();
	const { sort, onInputChange } = useRankedSort<TEntityOption>(SORT_OPTIONS);

	const company = useCompany();

	const teamEntities: TEntityOption[] = useMemo(() => {
		if (company?.integratedToHRs.size) {
			const directManager = t(`${TRANSLATION_PREFIX}entityTypes.DirectManager`);
			const teamMember = t(`${TRANSLATION_PREFIX}entityTypes.TeamMember`);

			return [
				{ displayName: directManager, identifier: "DirectManager", type: "DirectManager" },
				{ displayName: teamMember, identifier: "TeamMember", type: "TeamMember" }
			];
		}
		return [];
	}, [company, t]);

	const baseEntities = useMemo(() => {
		const automatic = t(`${TRANSLATION_PREFIX}entityTypes.Automatic`);
		const integrationMaintainer = t(`${TRANSLATION_PREFIX}entityTypes.IntegrationMaintainer`);
		const integrationOwner = t(`${TRANSLATION_PREFIX}entityTypes.IntegrationOwner`);
		const resourceMaintainer = t(`${TRANSLATION_PREFIX}entityTypes.ResourceMaintainer`);
		const resourceOwner = t(`${TRANSLATION_PREFIX}entityTypes.ResourceOwner`);

		const flowEntities: TEntityOption[] = [
			{ displayName: integrationOwner, identifier: "IntegrationOwner", type: "IntegrationOwner" },
			{ displayName: resourceOwner, identifier: "ResourceOwner", type: "ResourceOwner" },
			{ displayName: integrationMaintainer, identifier: "IntegrationMaintainer", type: "IntegrationMaintainer" },
			{ displayName: resourceMaintainer, identifier: "ResourceMaintainer", type: "ResourceMaintainer" }
		];

		if (automaticState !== "none") {
			flowEntities.push({ displayName: automatic, identifier: "Automatic", type: "Automatic" });
		}
		return flowEntities;
	}, [t, automaticState]);

	const approvalFlowEntities = useMemo(() => {
		if (!remoteEntities) return null;

		const flowEntities: TEntityOption[] = baseEntities.concat(
			remoteEntities.onCallIntegrationSchedules,
			remoteEntities.approvalFlowsWebhooks,
			teamEntities,
			remoteEntities.users,
			remoteEntities.directoryGroups
		);

		return flowEntities;
	}, [remoteEntities, baseEntities, teamEntities]);

	const values = useMemo(
		() =>
			(propValues
				?.map(value => approvalFlowEntities?.find(entity => entity.identifier === value.identifier))
				.filter(Boolean) as TEntityOption[]) || [],
		[approvalFlowEntities, propValues]
	);

	const options = useMemo(
		() => approvalFlowEntities?.filter(entity => !entity.isDeleted || values.includes(entity)) || [],
		[approvalFlowEntities, values]
	);

	const handleChange = useCallback(
		(entities: TEntityOption[] | null) => {
			onChange(entities);
		},
		[onChange]
	);

	const loading = !approvalFlowEntities;

	const groupBy = useCallback(
		({ type }: TEntityOption) => (!SINGLE_ENTITY_TYPES.has(type) ? t(`${TRANSLATION_PREFIX}entityTypes.${type}`) : ""),
		[t]
	);

	const isOptionDisabled: (option: TEntityOption) => boolean = useCallback(
		({ type, isDeleted }) => {
			if (automaticState === "none") return false;
			if (isDeleted) return true;
			return type === "Automatic" && automaticState === "disabled";
		},
		[automaticState]
	);

	const renderOption = useCallback(
		(option: TEntityOption) => renderOptionBase(option, classes.option, classes.name, t),
		[classes.name, classes.option, t]
	);

	const placeholder = useMemo(
		() =>
			automaticState === "none" ? t(`${TRANSLATION_PREFIX}label.notified`) : t(`${TRANSLATION_PREFIX}label.approvers`),
		[t, automaticState]
	);

	return (
		<MultipleSelect
			onInputChange={onInputChange}
			className={className}
			disabled={loading}
			getOptionLabel={getOptionLabel}
			groupBy={groupBy}
			isOptionDisabled={isOptionDisabled}
			isOptionEqualToValue={equality}
			loading={loading}
			onChange={handleChange}
			options={options}
			sort={sort}
			placeholder={placeholder}
			renderChip={renderChip}
			renderOption={renderOption}
			value={values}
		/>
	);
};
