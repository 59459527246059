import { useCallback, useEffect, useMemo, useState } from "react";
import { matchPath, useLocation } from "react-router-dom";
import { Map as ImmutableMap } from "immutable";
import constate from "constate";
import isNil from "lodash/isNil";
import { routes } from "routes/routes";
import { flattenRoutes } from "utils/router/utils";
import type { TPageId } from "routes/route";

const usePage = () => {
	const [subPage, setSubPage] = useState<boolean>(false);
	const [scrollableId, setScrollableId] = useState<TPageId | null>(null);
	const location = useLocation();
	const flattenedRoutes = useMemo(() => flattenRoutes(routes), []);
	const [pageLastState, setPageLastState] = useState(ImmutableMap<TPageId, Record<string, unknown>>());

	useEffect(() => {
		const match = flattenedRoutes.find(route => {
			const matches = Array.isArray(route.matches) ? route.matches : [route.matches];
			return matches.some(path => !isNil(matchPath(path, location.pathname)));
		});
		setScrollableId(match?.pageId || null);
	}, [flattenedRoutes, location.pathname]);

	const setLastState = useCallback((pageId: TPageId, state: Record<string, unknown>) => {
		setPageLastState(prevState => prevState.set(pageId, state));
	}, []);

	const updateLastState = useCallback((pageId: TPageId, state: Record<string, unknown>) => {
		setPageLastState(prevState => prevState.set(pageId, { ...prevState.get(pageId), ...state }));
	}, []);

	return { scrollableId, subPage, setSubPage, setLastState, updateLastState, pageLastState };
};

export const [PageProvider, usePageContext] = constate(usePage);
