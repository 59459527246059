import orderBy from "lodash/orderBy";
import { requirePropertyOf } from "./security";

type TSortable<T, K extends keyof T> = T[K] extends string ? T : never;
type TSortOrder = "asc" | "desc";

export const basicSort = <T, K extends keyof T>(
	options: TSortable<T, K>[],
	keys: K[],
	sortOrder: TSortOrder[] | null = null
) => {
	const keysLower = keys.map(
		key => (option: TSortable<T, K>) => (requirePropertyOf(option, key) as string).toLowerCase()
	);
	return orderBy(options, keysLower, sortOrder || keys.map(() => "asc"));
};

export const sortByName = <T extends { name: string }>(options: TSortable<T, "name">[]) => {
	return basicSort(options, ["name"]);
};

export const sortByFullName = <T extends { fullName: string }>(options: TSortable<T, "fullName">[]) => {
	return basicSort(options, ["fullName"]);
};

export const sortByDisplayName = <T extends { displayName: string }>(options: TSortable<T, "displayName">[]) => {
	return basicSort(options, ["displayName"]);
};
