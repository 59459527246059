import React, { useEffect, useState } from "react";
import { LoadingSpinner } from "components/ui/LoadingSpinner";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { generateCLIAccessToken } from "api/personalAccessTokens";
import { Typography } from "components/ui/Typography";
import { useStyles } from "./styles";

export const CLITokenGenerationPage = () => {
	const classes = useStyles();
	const [queryParams] = useSearchParams();
	const [error, setError] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");
	const { t } = useTranslation();
	useEffect(() => {
		const generateToken = async () => {
			const portParam = queryParams.get("port");
			const securedParam = queryParams.get("secured");

			if (!portParam || isNaN(parseInt(portParam))) {
				setError(true);
				setErrorMessage(t("pages.cliTokenGeneration.invalidPort"));
				return;
			}
			const port = parseInt(portParam);
			try {
				await generateCLIAccessToken(port, securedParam);
			} catch (e) {
				setError(true);
				setErrorMessage((e as Error).message);
			}
		};
		generateToken();
	}, [queryParams, t]);

	return (
		<div className={classes.pageContainer}>
			{error ? <Typography variant="standard">{errorMessage}</Typography> : <LoadingSpinner />}
		</div>
	);
};
