import { useCallback } from "react";
import constate from "constate";
import { getDirectoryGroups } from "api/directoryGroups";
import { useFetchedState } from "hooks/useFetchedState";

const useDirectoryGroups = () => {
	const fetchCallback = useCallback(() => getDirectoryGroups(true), []);
	const { data: directoryGroups, loadData: loadDirectoryGroups } = useFetchedState(fetchCallback);

	return { state: { directoryGroups }, actions: { loadDirectoryGroups } };
};

export const [DirectoryGroupsProvider, useDirectoryGroupsContext] = constate(useDirectoryGroups);
