import classNames from "classnames";
import { Typography } from "components/ui/Typography";
import React from "react";
import { useStyles } from "./styles";

interface IProps {
	bold?: boolean;
	content: string;
	isDeleted?: boolean;
	prefixIcon?: JSX.Element;
	noWrap?: boolean;
}

export const Entity: FC<IProps> = ({
	bold,
	className,
	content,
	id,
	innerRef,
	noWrap,
	isDeleted = false,
	prefixIcon = null
}) => {
	const classes = useStyles();
	return (
		<div id={id} ref={innerRef} className={classNames(classes.entity, className)}>
			{prefixIcon}
			<Typography
				relative
				noWrap={noWrap}
				component="span"
				className={classNames(classes.content, { deleted: isDeleted, [classes.bold]: bold })}>
				{content}
			</Typography>
		</div>
	);
};
