import React, { useMemo } from "react";
import classNames from "classnames";
import { useStyles } from "./styles";

type TBaseProps = {
	color?: string;
	width?: number;
	full?: boolean;
};

type TProps = TBaseProps & ({ vertical: true } | { horizontal: true });

export const Divider: FC<TProps> = props => {
	const { className, innerRef, color = "var(--color-grey-500)", width = 1, full = false } = props;
	const isHorizontal = "horizontal" in props;
	const classes = useStyles({ strokeWidth: width });
	const line = useMemo(() => {
		if (isHorizontal) return <line x1="0%" y1="0%" x2="100%" y2="0%" />;
		return <line x1="0%" y1="0%" x2="0%" y2="100%" />;
	}, [isHorizontal]);

	return (
		<svg
			className={classNames(
				classes.divider,
				{
					[classes.horizontal]: isHorizontal,
					[classes.vertical]: !isHorizontal,
					[classes.full]: full
				},
				className
			)}
			ref={innerRef}
			strokeWidth={width}
			stroke={color}>
			{line}
		</svg>
	);
};
