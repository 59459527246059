import React from "react";
import classNames from "classnames";
import { TooltipOnOverflow } from "components/ui/TooltipOnOverflow";
import { useStyles } from "./styles";

type TProps = {
	content: React.ReactNode;
	actions?: React.ReactNode;
};

export const ResourceNode: FC<TProps> = ({ actions, className, content, innerRef }) => {
	const classes = useStyles();
	const isStringContent = typeof content === "string";
	const hasActions = !!actions;
	const contentElement = isStringContent ? <TooltipOnOverflow textVariant="standard" content={content} /> : content;
	return (
		<div className={classNames(classes.container, className)} ref={innerRef}>
			<div className={classes.contentContainer}>
				{hasActions ? (
					<div className={classes.contentWithActions}>
						<div className={classes.alignedContent}>{contentElement}</div>
						<div className={classes.alignedContent}>{actions}</div>
					</div>
				) : (
					<div className={classes.alignedContent}>
						{isStringContent ? <TooltipOnOverflow textVariant="standard" content={content} /> : content}
					</div>
				)}
			</div>
		</div>
	);
};
