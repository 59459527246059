import { apiReq } from "utils/apiReq";
import { getPaginatedSearchQueryString, IPaginatedSearchOptions } from "utils/searchUtils";
import { IntegrationResourceRoleModel } from "models/IntegrationResourceRoleModel";
import { IPaginationResponse, withPagination } from "utils/pagination";
import { List } from "immutable";
import { TTicketDuration } from "utils/durationsOptions";

export type TIntegrationResourceRole = {
	id?: string;
	name?: string;
	integrationResourceId: string;
	approvalAlgorithmId?: string | null;
	allowAsGrantMethod?: boolean;
	virtualizedRoleId?: string;
	allowedDurations?: List<TTicketDuration> | null;
	allowsRequests?: boolean;
};

export async function getIntegrationResourceRoles(
	integrationResourceId: string,
	paginatedSearchOptions: IPaginatedSearchOptions = {},
	allowsRequests = true,
	userId?: string
): Promise<IPaginationResponse<IntegrationResourceRoleModel>> {
	let qs = getPaginatedSearchQueryString(paginatedSearchOptions);
	if (userId) {
		qs += qs ? "&" : "";
		qs += `userId=${userId}`;
	}
	qs += qs ? "&" : "";
	qs += `allowsRequests=${allowsRequests}`;
	return withPagination(
		(role: Record<string, unknown>) => IntegrationResourceRoleModel.fromServerData(role),
		() => apiReq("GET", `/v1/integrationResources/${integrationResourceId}/roles?${qs}`)
	);
}

export async function getIntegrationResourceRole(
	integrationResourceRoleId: string,
	withDeleted = false
): Promise<IntegrationResourceRoleModel> {
	const { data } = await apiReq(
		"GET",
		`/v1/integrationResourceRoles/${integrationResourceRoleId}?withDeleted=${withDeleted}`
	);
	return IntegrationResourceRoleModel.fromServerData(data);
}

export async function multiGetIntegrationResourceRoles(ids: string[], withDeleted = false) {
	const idsQuery = ids.map(id => `ids=${id}`).join("&");
	const { data } = await apiReq("GET", `/v1/integrationResourceRoles/multiGet?withDeleted=${withDeleted}&${idsQuery}`);
	const parsedData = data as Record<string, unknown>[];
	return parsedData.map(IntegrationResourceRoleModel.fromServerData);
}

export async function createIntegrationResourceRole(
	integrationResourceRole: TIntegrationResourceRole
): Promise<IntegrationResourceRoleModel> {
	const { data } = await apiReq("POST", `/v1/integrationResourceRoles`, integrationResourceRole);
	return IntegrationResourceRoleModel.fromServerData(data);
}

export async function editIntegrationResourceRole(
	integrationResourceRole: Partial<TIntegrationResourceRole>
): Promise<IntegrationResourceRoleModel> {
	const { data } = await apiReq(
		"PUT",
		`/v1/integrationResourceRoles/${integrationResourceRole.id}`,
		integrationResourceRole
	);
	return IntegrationResourceRoleModel.fromServerData(data);
}

export async function deleteIntegrationResourceRole(
	integrationResourceRoleId: string
): Promise<IntegrationResourceRoleModel> {
	const { data } = await apiReq("DELETE", `/v1/integrationResourceRoles/${integrationResourceRoleId}`);
	return IntegrationResourceRoleModel.fromServerData(data);
}
