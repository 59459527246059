import React, { useCallback } from "react";
import { RadioInput } from "components/ui/RadioInput";
import { Typography } from "components/ui/Typography";
import classNames from "classnames";
import { useControlled } from "hooks/useControlled";
import { useStyles } from "./styles";

interface IRadioInputGroupProps<T> {
	onChange?: (value: T) => void;
	options: { value: T; label: string; disabled?: boolean }[];
	selectedValue?: T;
	small?: boolean;
	title?: string;
}
export function RadioInputGroup<T>({
	className,
	onChange,
	options,
	selectedValue,
	small,
	title
}: TProps<IRadioInputGroupProps<T>>) {
	const classes = useStyles();
	const [radioClickedValue, setRadioClickedValue] = useControlled<T>({
		controlled: selectedValue || null,
		default: undefined
	});

	const onClick = useCallback(
		(radioButtonValue: T) => {
			if (!options.find(option => option.value === selectedValue)?.disabled && selectedValue !== radioButtonValue) {
				setRadioClickedValue(radioButtonValue);
				if (onChange) {
					onChange(radioButtonValue);
				}
			}
		},
		[onChange, options, selectedValue, setRadioClickedValue]
	);

	return (
		<div className={classNames(classes.radioGroup, className)}>
			{title?.length && (
				<Typography variant="h3" className={classes.title}>
					{title}
				</Typography>
			)}
			{options.map(option => (
				<RadioInput
					disabled={option.disabled}
					key={typeof option.value === "string" ? option.value : option.label}
					label={option.label}
					onClick={onClick}
					selected={option.value === radioClickedValue}
					small={small}
					value={option.value}
				/>
			))}
		</div>
	);
}
