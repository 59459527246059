import { List } from "immutable";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import {
	deleteIntegrationResource,
	editIntegrationResource,
	TIntegrationResourceBody,
	addResourcePermission as apiAddResourcePermission,
	deleteResourcePermission as apiDeleteResourcePermission
} from "api/integrationResources";
import { ApprovalAlgorithmBlock, INHERIT_FROM_PARENT_ID } from "components/common/ApprovalAlgorithmBlock";
import { PageTemplate } from "components/templates/PageTemplate";
import { useIntegrationResource } from "hooks/useIntegrationResource";
import { useIntegrations } from "hooks/useIntegrations";
import { LoadingSpinner } from "components/ui/LoadingSpinner";
import { useUsers } from "hooks/useUsers";
import useIsOpenState from "hooks/useIsOpenState";
import { AreYouSureModal } from "components/common/AreYouSureModal";
import { useIntegrationsContext } from "context/integrationsContext";
import { MaintainersBlock } from "components/common/MaintainersBlock";
import { Button } from "components/ui/Button";
import { AddIcon } from "components/ui/Icons/AddIcon";
import { useLoadingState } from "hooks/useLoadingState";
import { PrerequisitePermissionsBlock } from "components/common/PrerequisitePermissionsBlock";
import { Breadcrumbs } from "components/common/Breadcrumbs";
import { RequestDetails } from "components/common/RequestDetails";
import { ResourceHeaderBlock } from "./components/ResourceHeaderBlock";
import { EntitlementsTable } from "./components/EntitlementsTable";
import { GivesAccessTable } from "./components/GivesAccessTable";
import { HasAccessFromTable } from "./components/HasAccessFromTable";
import { useStyles } from "./styles";
import { RolesBlock } from "./components/RolesBlock/RolesBlock";
import { AddResourcePermissionModal } from "./components/AddResourcePermissionModal";
import { getEntitlementsByUsers } from "./utils";
import type { IntegrationResourceMaintainerModel } from "models/IntegrationResourceMaintainerModel";
import type { IntegrationResourceRoleModel } from "models/IntegrationResourceRoleModel";

type TAreYouSureModalMode = "delete" | "hideResource";

export const IntegrationResourcePage: FC = ({ className }) => {
	const params = useParams<{ integrationResourceId: string }>();
	const {
		addResourcePermission,
		deleteResourcePermission,
		loadResource,
		resource: integrationResource
	} = useIntegrationResource(params?.integrationResourceId || "", true);
	const integrations = useIntegrations();
	const {
		actions: { loadIntegration }
	} = useIntegrationsContext();
	const users = useUsers();
	const [prerequisitePermissionId, setPrerequisitePermissionId] = useState<string | null>(
		integrationResource?.prerequisitePermissionId || null
	);

	const areYouSureModal = useIsOpenState();
	const addResourcePermissionModal = useIsOpenState();
	const [addResourcePermissionModalState, setAddResourcePermissionModalState] = useState<"to" | "from">("to");
	const { withLoader: withLoadAddResourcePermission, isLoading: addResourcePermissionIsLoading } = useLoadingState();
	const [areYouSureModalMode, setAreYouSureModalMode] = useState<TAreYouSureModalMode | null>(null);

	const classes = useStyles();
	const { t } = useTranslation();
	const navigate = useNavigate();

	const closeModal = useCallback(() => {
		setAreYouSureModalMode(null);
		areYouSureModal.close();
	}, [areYouSureModal]);

	const areYouSureModalProps = useMemo(() => {
		if (!integrationResource || areYouSureModalMode === "delete") return undefined;
		const prefix = integrationResource.allowsRequests ? "hide" : "show";
		return {
			content: t(`pages.integration.${prefix}ResourceConfirm`),
			actionLabel: t(`pages.integration.${prefix}Resource`)
		};
	}, [areYouSureModalMode, integrationResource, t]);

	const integration = useMemo(() => {
		if (integrationResource && integrations) {
			return integrations.get(integrationResource.integrationId);
		}
		return null;
	}, [integrationResource, integrations]);

	const approvalAlgorithmId = useMemo(() => {
		return integrationResource?.approvalAlgorithmId || integration?.defaultApprovalAlgorithm?.id || null;
	}, [integrationResource, integration]);

	const entitlementsByUsers = useMemo(() => {
		if (integrationResource) {
			return getEntitlementsByUsers(integrationResource);
		}
		return null;
	}, [integrationResource]);

	const owner = users?.get(integrationResource?.ownerId || "") || null;

	const onEditResource = useCallback(
		async (resource: Partial<TIntegrationResourceBody>) => {
			if (!params?.integrationResourceId) {
				return;
			}
			await editIntegrationResource({ id: params.integrationResourceId, ...resource });
			loadResource();
		},
		[loadResource, params.integrationResourceId]
	);

	const onDeleteResource = useCallback(async () => {
		if (integrationResource) {
			await deleteIntegrationResource(integrationResource.id);
			loadIntegration(integrationResource.integrationId);
		}
		closeModal();
		if (!integration) {
			return;
		}
		navigate(`/integrations/${integration.id}`);
	}, [integrationResource, closeModal, integration, navigate, loadIntegration]);

	const onHideResourceToggle = useCallback(async () => {
		if (integrationResource) {
			await onEditResource({ allowsRequests: !integrationResource.allowsRequests });
			loadIntegration(integrationResource.integrationId);
		}
		closeModal();
	}, [closeModal, integrationResource, loadIntegration, onEditResource]);

	const areYouSureModalActionsMap = useMemo(
		() =>
			new Map<TAreYouSureModalMode, () => Promise<void>>([
				["delete", onDeleteResource],
				["hideResource", onHideResourceToggle]
			]),
		[onDeleteResource, onHideResourceToggle]
	);

	const onApprovalAlgorithmChange = useCallback(
		(id: string) => onEditResource({ approvalAlgorithmId: id === INHERIT_FROM_PARENT_ID ? null : id }),
		[onEditResource]
	);

	const onRename = useCallback((displayName: string) => onEditResource({ displayName }), [onEditResource]);

	const openAreYouSureModal = useCallback(
		(mode: TAreYouSureModalMode) => {
			setAreYouSureModalMode(mode);
			areYouSureModal.open();
		},
		[areYouSureModal]
	);

	const openDeleteModal = useCallback(() => openAreYouSureModal("delete"), [openAreYouSureModal]);

	const onRolesChange = useCallback(() => loadResource(), [loadResource]);

	const updateMaintainers = useCallback(
		async (maintainers: List<IntegrationResourceMaintainerModel>) => {
			if (!integrationResource) return;
			const mappedMaintainers = maintainers
				.map(({ entityId, type }) => ({
					entityId,
					entityType: type
				}))
				.toArray();
			await onEditResource({
				id: integrationResource.id,
				maintainers: mappedMaintainers
			});
		},
		[integrationResource, onEditResource]
	);

	const openAddResourcePermissionModal = useCallback(
		(event: React.SyntheticEvent, newState: "to" | "from") => {
			event.stopPropagation();
			addResourcePermissionModal.close();
			setAddResourcePermissionModalState(newState);
			addResourcePermissionModal.open();
		},
		[addResourcePermissionModal]
	);

	const addAccess = useCallback(
		async (roleId: string, resourceId: string) => {
			if (!integrationResource) return;
			if (resourceId === integrationResource.id) {
				await withLoadAddResourcePermission(addResourcePermission(roleId));
			} else {
				await withLoadAddResourcePermission(apiAddResourcePermission(resourceId, roleId));
			}
			loadResource();
		},
		[integrationResource, withLoadAddResourcePermission, addResourcePermission, loadResource]
	);

	const removeAccess = useCallback(
		async (roleId: string, resourceId: string) => {
			if (!integrationResource) return;
			await apiDeleteResourcePermission(resourceId, roleId);
			loadResource();
		},
		[integrationResource, loadResource]
	);

	const givesAccessTable = useMemo(() => {
		if (!integrationResource || !integration || integration.virtual) return null;
		if (!integration.manual) {
			if (!integrationResource.givesAccessToRoles || integrationResource.givesAccessToRoles.size === 0) return null;
			return <GivesAccessTable entitlesRoles={integrationResource.givesAccessToRoles} />;
		}
		const openGivesAccessToModal = (event: React.SyntheticEvent) => {
			openAddResourcePermissionModal(event, "to");
		};
		return (
			<GivesAccessTable
				entitlesRoles={integrationResource.givesAccessToRoles || List<IntegrationResourceRoleModel>()}
				manual
				actions={
					<Button onClick={openGivesAccessToModal} prefix={<AddIcon />} variant="secondary" size="small">
						{t("buttons.add")}
					</Button>
				}
				onDelete={deleteResourcePermission}
			/>
		);
	}, [openAddResourcePermissionModal, integrationResource, integration, t, deleteResourcePermission]);

	const hasAccessFromTable = useMemo(() => {
		if (!integrationResource || !integration || integration.virtual) return null;
		if (!integration.manual) {
			if (!integrationResource.integrationResourceRoles || integrationResource.integrationResourceRoles.size === 0) {
				return null;
			}
			return (
				<HasAccessFromTable
					integrationResource={integrationResource}
					integrationResourceRoles={integrationResource.integrationResourceRoles}
					resourceName={integrationResource.displayName}
				/>
			);
		}
		const openHasAccessFromModal = (event: React.SyntheticEvent) => {
			openAddResourcePermissionModal(event, "from");
		};
		return (
			<HasAccessFromTable
				integrationResourceRoles={integrationResource.integrationResourceRoles || List<IntegrationResourceRoleModel>()}
				integrationResource={integrationResource}
				manual
				actions={
					<Button onClick={openHasAccessFromModal} prefix={<AddIcon />} variant="secondary" size="small">
						{t("buttons.add")}
					</Button>
				}
				onDelete={removeAccess}
				resourceName={integrationResource.displayName}
			/>
		);
	}, [integrationResource, integration, t, removeAccess, openAddResourcePermissionModal]);

	const headerBlockAdapterlessProps = integration?.adapterless
		? {
				onDeleteButtonClick: openDeleteModal,
				onRename
			}
		: {};

	useEffect(() => {
		if (integrationResource) setPrerequisitePermissionId(integrationResource.prerequisitePermissionId);
	}, [integrationResource]);

	const breadcrumbs = useMemo(() => {
		if (!integration || !integrationResource) return [];
		return [
			{
				title: t("navigation.integrations"),
				url: "/integrations"
			},
			{
				title: integration.name,
				url: `/integrations/${integration.id}`
			},
			{
				title: integrationResource.displayName
			}
		];
	}, [integration, integrationResource, t]);

	return (
		<PageTemplate subPage className={className}>
			<RequestDetails />
			<AreYouSureModal
				{...areYouSureModalProps}
				isOpen={areYouSureModal.isOpen && Boolean(areYouSureModalMode)}
				onClose={closeModal}
				onAction={areYouSureModalActionsMap.get(areYouSureModalMode!)!}
			/>
			<AddResourcePermissionModal
				isOpen={addResourcePermissionModal.isOpen}
				onClose={addResourcePermissionModal.close}
				addResourcePermission={addAccess}
				isLoading={addResourcePermissionIsLoading}
				state={addResourcePermissionModalState}
				originResourceId={integrationResource?.id || ""}
			/>

			<PageTemplate.Title>
				<Breadcrumbs parts={breadcrumbs} />
			</PageTemplate.Title>
			<PageTemplate.Content noBorder noBackground className={classes.page}>
				{integrationResource && integration ? (
					<div className={classes.resourcePage}>
						<ResourceHeaderBlock
							integrationResource={integrationResource}
							adapterless={integration.adapterless}
							onSave={onEditResource}
							owner={owner || null}
							inherited={!integrationResource.ownerId}
							type={integrationResource?.type}
							{...headerBlockAdapterlessProps}
						/>
						<div className={classes.content}>
							{approvalAlgorithmId && (
								<ApprovalAlgorithmBlock
									rounded
									approvalAlgorithmId={approvalAlgorithmId}
									onChangeAlgorithm={onApprovalAlgorithmChange}
									inheritOptionText={t("pages.integration.resource.inherit")}
								/>
							)}
							{givesAccessTable}
							{hasAccessFromTable}
							{entitlementsByUsers && entitlementsByUsers.size > 0 && (
								<EntitlementsTable entitlementsByUsers={entitlementsByUsers} />
							)}
							<RolesBlock
								integrationResource={integrationResource}
								onChange={onRolesChange}
								manual={integration.manual}
								virtual={integration.virtual}
							/>
							{integrationResource && (
								<MaintainersBlock
									autoAssigned={integration.autoAssignRecommendedResourceMaintainers}
									maintainers={integrationResource.maintainers}
									onUpdate={updateMaintainers}
									entity={integrationResource}
								/>
							)}
							{!integration?.virtual && (
								<PrerequisitePermissionsBlock
									prerequisitePermissionId={prerequisitePermissionId}
									afterAction={setPrerequisitePermissionId}
									asset={{
										prohibitedIds: {
											integrationResourceIds: !integrationResource.multirole ? [integrationResource.id] : null
										},
										id: integrationResource.id,
										name: integrationResource.displayName,
										type: "integrationResources"
									}}
								/>
							)}
						</div>
					</div>
				) : (
					<LoadingSpinner className={classes.spinner} />
				)}
			</PageTemplate.Content>
		</PageTemplate>
	);
};
