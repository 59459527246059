import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	container: {
		alignItems: "center",
		display: "flex",
		gap: "8px",
		justifyContent: "flex-end"
	}
});
