import constate from "constate";
import { List } from "immutable";
import { useCallback, useState } from "react";
import {
	createDirectoryConfiguration,
	deleteDirectoryConfiguration,
	getDirectoryConfigurations,
	editDirectoryConfiguration,
	syncDirectory as apiSyncDirectory
} from "api/directoryConfigurations";
import { useOpenGlobalErrorModal } from "hooks/useGlobalError";
import { DirectoryConfigurationModel } from "models/DirectoryConfigurationModel";
import type { TConfigurationCreateBody, TConfigurationUpdateBody } from "utils/directoryConfiguration";

const useDirectoryConfigurations = () => {
	const [directoryConfigurations, setDirectoryConfigurations] = useState<List<DirectoryConfigurationModel> | null>(
		null
	);

	const openGlobalErrorModal = useOpenGlobalErrorModal();

	const loadConfigurations = useCallback(async () => {
		let configurations = List<DirectoryConfigurationModel>();
		try {
			configurations = await getDirectoryConfigurations();
		} catch (err) {
			openGlobalErrorModal(err as Error);
		}
		setDirectoryConfigurations(configurations);
		return configurations;
	}, [openGlobalErrorModal]);

	const getConfigurations = useCallback(async () => {
		if (directoryConfigurations) return directoryConfigurations;
		return loadConfigurations();
	}, [directoryConfigurations, loadConfigurations]);

	const createConfiguration = useCallback(
		async (data: TConfigurationCreateBody) => {
			await getConfigurations();
			const newConfiguration = await createDirectoryConfiguration(data);
			setDirectoryConfigurations(current => (current ? current.push(newConfiguration) : current));
		},
		[getConfigurations]
	);

	const syncDirectory = useCallback(async (id: string) => {
		const updatedDirectory = await apiSyncDirectory(id);
		setDirectoryConfigurations(currentDirectoryConfigurations => {
			const updatedIndex = currentDirectoryConfigurations?.findIndex(configuration => configuration.id === id);
			if (updatedIndex === -1 || updatedIndex === undefined) return currentDirectoryConfigurations;

			return currentDirectoryConfigurations!.update(updatedIndex, () => updatedDirectory);
		});
	}, []);

	const updateConfiguration = useCallback(
		async (id: string, data: TConfigurationUpdateBody) => {
			await getConfigurations();
			const updatedConfig = await editDirectoryConfiguration(id, data);
			setDirectoryConfigurations(current => {
				if (current) {
					const index = current.findIndex(c => c.id === updatedConfig.id);
					if (index !== -1) return current.set(index, updatedConfig);
				}
				return current;
			});
		},
		[getConfigurations]
	);

	const deleteConfiguration = useCallback(
		async (id: string) => {
			await getConfigurations();
			await deleteDirectoryConfiguration(id);
			setDirectoryConfigurations(current => {
				if (!current) {
					return current;
				}
				return current.delete(current.findIndex(config => config.id === id));
			});
		},
		[getConfigurations]
	);

	return {
		state: { directoryConfigurations },
		actions: {
			syncDirectory,
			getConfigurations,
			createConfiguration,
			updateConfiguration,
			deleteConfiguration
		}
	};
};

export const [DirectoryConfigurationsProvider, useDirectoryConfigurationsContext] =
	constate(useDirectoryConfigurations);
