import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	spinner: {
		margin: "3rem auto"
	},
	headerContainer: {
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
		paddingRight: "2.25rem"
	},
	auditLogsPageContent: {
		"&$fullWidth$noPadding": {
			padding: "0.5rem 0 0"
		}
	},
	fullWidth: {
		width: "100%"
	},
	inputsContainer: {
		display: "flex",
		alignItems: "center",
		justifyContent: "end",
		gap: "1rem"
	},
	dateText: {
		alignSelf: "end"
	},
	title: {
		display: "flex",
		gap: "1rem"
	},
	timezoneToggle: {
		flexDirection: "row",
		padding: "unset"
	},
	dateSelectInput: {},
	noPadding: {},
	dateSelectionInputs: {},
	listContainer: { height: "1px", flex: "1" }
});
