import { getI18nErrorTexts, getI18nTextIfExists } from "../i18n";
import { EntitleError, TErrorTexts } from "./entitleError";
import { mapErrorParams, TErrorId } from "./mapApiErrorParams";

export default class ApiError extends EntitleError {
	public readonly errorId?: TErrorId;
	public readonly params?: Record<string, unknown>;
	public readonly statusCode?: number;

	constructor({
		errorId,
		params,
		title,
		message,
		statusCode
	}: {
		errorId?: TErrorId;
		params?: Record<string, unknown>;
		title?: string;
		message?: string;
		statusCode?: number;
	}) {
		if (message == null && errorId != null) {
			const i18nTexts = getI18nErrorTexts(errorId, mapErrorParams(errorId, params || {}));
			if (i18nTexts.message != null) {
				message = i18nTexts.message;
				title = i18nTexts.title || undefined;
			}
		}
		super(message, title);

		this.errorId = errorId;
		this.params = params;
		this.message = message || getI18nTextIfExists("error.unknown.message") || "";
		this.statusCode = statusCode;
	}

	override getTexts(): TErrorTexts {
		let i18nTexts: { title: string | null; message: string | null } = { title: null, message: null };
		if (this.errorId != null) {
			i18nTexts = getI18nErrorTexts(
				this.errorId,
				mapErrorParams(this.errorId, this.params || {}),
				this.message == null
			);
		}

		return {
			title: i18nTexts.title || this.title,
			message: i18nTexts.message || this.message
		};
	}

	static from(
		{
			errorId,
			params,
			title,
			message
		}: {
			errorId?: TErrorId;
			params?: { [key: string]: string | number };
			title?: string;
			message?: string;
		},
		{ statusCode }: { statusCode?: number } = {}
	) {
		if (title != null && message == null) {
			message = title;
			title = undefined;
		}

		return new ApiError({
			errorId,
			params,
			message,
			title,
			statusCode
		});
	}
}

export function getErrorTexts(error: ApiError | Error | null): TErrorTexts {
	if (error == null)
		return {
			title: null,
			message: null
		};

	if (error instanceof ApiError) return error.getTexts();

	return {
		title: null,
		message: error.message
	};
}
